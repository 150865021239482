import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ErrorManagerService } from '../../../shared/services/errorManager.service';
import {of as observableOf } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../shared/services/util.service';
import * as _ from 'lodash';
import { OnePagerTabs } from '../../../types';

@Component({
  selector: 'dna-one-pager-multi',
  templateUrl: './one-pager-multi.component.html',
  styleUrls: ['./one-pager-multi.component.less']
})
export class OnePagerMultiComponent implements OnInit {

  link: string[];
  selectedTab: string;
  public onePagerTabs = OnePagerTabs;

  constructor(
    private ref: ChangeDetectorRef,
    private errorManagerService: ErrorManagerService,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private router: Router
  ) { }

  ngAfterViewInit() { 
    this.ref.detectChanges(); 
  }

  catchError = (err) => {
    this.errorManagerService.catchError(err);
    console.log(err);
    return observableOf();
  };

  ngOnInit() {
    const campaignMultiId = this.route.parent.snapshot.paramMap.get('idCampaignMulti');
    this.link = ['/multi-campaigns', campaignMultiId, 'onepager'];
  }

  onActivate(data) {
    this.selectedTab = this.utilService.getTab(_.last(this.router.url.split("/")));  
  }

}
