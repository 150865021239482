<ngx-loading [show]="showSpinner"></ngx-loading>
<div *ngIf="component">
  <h1 class="mb-4" id="banner-title" translate>
    CONFIGURE_COMPONENT
  </h1>
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" translate>DASHBOARD</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/workflows" translate>LIBRARY_OF_WORKFLOWS</a>
      </li>
      <li class="breadcrumb-item" [ngClass]="{'active': workflow.id == undefined}">
        <a [routerLink]="['/workflows', workflow.id]">
          <dna-translate [fieldToTranslate]="workflow.name"> </dna-translate>
        </a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/workflows', workflow.id, 'editBlock', block.id]">
          <dna-translate [fieldToTranslate]="block.name"> </dna-translate>
        </a>
      </li>
      <li class="breadcrumb-item active">
        {{ component.args.label[currentLanguage] || component.args.label.english || component.type }}</li>
      <li class="breadcrumb-item active" aria-current="page" translate>CONFIGURE</li>
    </ol>
  </nav>

  <div class="actions" id="banner-actions">
    <button *ngIf="!inactive" class="btn btn-primary mb-4" type="submit" (click)="validate()"
      translate>VALIDATE</button>
    <button class="btn btn-default mb-4" type="button" (click)="cancel()"
      translate>{{ inactive ? 'BACK' : 'CANCEL' }}</button>
  </div>
  <div class="block">
    <div *ngIf="inactive && component.isEditable" style="text-align:center; color:red;" translate> WORKFLOW_IS_PUBLISHED
    </div>
    <div class="row">
      <div class="col-md-12">
        <dna-component-config [component]="component" [inactive]="inactive"></dna-component-config>
      </div>
      <label class="col-md-12"> </label>
      <label class="col-md-12"> </label>
      <div class="col-md-12">
        <div>
          <div class="row col-md-12">
            <b translate>TRANSLATE_COMPONENT</b>
          </div>
          <label class="col-md-12"> </label>
          <label class="col-md-12"> </label>
          <table style="width:100%">
            <tr>
              <div class="row">
                <th class="col-sm-2" *ngFor="let language of languages" style="text-align:center">
                  <span translate>{{language}}</span>
                </th>
              </div>
            </tr>
          </table>
          <br>
          <div *ngFor="let translateObject of fieldsOfComponents">
            <div class="row">
              <b class="col-sm-12"> {{translateObject[0].type}} : </b>
            </div>
            <br>
            <div *ngFor="let objt of translateObject">
              <div *ngIf="objt.type == 'String'">
                <div class="row">
                  <label class="col-sm-12" translate>{{objt.translationKey}}</label>
                </div>
                <div class="row">
                  <div class="col-sm-2" *ngFor="let language of languages">
                    <input class="form-control" [(ngModel)]="component.args[objt.fieldName][language]" type="text"
                      name="{{objt.translationKey + '_' + language}}" [disabled]="inactive">
                  </div>
                </div>
              </div>
              <div *ngIf="objt.type == 'Array'">
                <div class="row">
                  <label class="col-sm-12" for="size" translate>{{objt.translationKey}}</label>
                </div>
                <div class="row">
                  <div class="col-sm-2" *ngFor="let language of languages">
                    <tag-input [disable]="inactive" [(ngModel)]="component.args[objt.fieldName][language]"
                      name="{{objt.translationKey + '_' + language}}" [identifyBy]="objt.key" [displayBy]="objt.key"
                      [maxItems]="50">
                    </tag-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
