import { Component, AfterViewInit, ViewChild, ElementRef, ChangeDetectionStrategy, SimpleChanges, ChangeDetectorRef, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dna-scalebar',
  templateUrl: './scalebar.component.html',
  styleUrls: ['./scalebar.component.scss']
})
export class ScalebarComponent implements AfterViewInit {
  @ViewChild('chart', { read: ElementRef }) chart : ElementRef;
  @Input() lang : string;
  @Input() parameters : Array<any>;
  @Input() number : number;
  @Input() min: number = 0;

  items : Array<any>;
  error : boolean = false;
  errorMessage : string;
  constructor(private ref: ChangeDetectorRef){
  }

  ngOnChanges( changes : SimpleChanges ) {
    this.ref.detectChanges();
  } 
  
  ngAfterViewInit() {
    this.ref.checkNoChanges();
  };
  

  private createColors() : Array<string> {
    const min = 100;
    const max = 200;
    const diff = max - min;
    const interval = 
      this.number === 1 ? 0 :
      Math.floor(diff / (this.number - 1));
    const colorsArray = [];

    for (let i = 0; i < this.number; i++) {
      const n = min + interval * i;
      const rgb = `rgb(${n},${n},${n})`;
      colorsArray.unshift(rgb);
    }

    return colorsArray;
  }

  getColors() : Array<string> {
    const threeColors = [
      "#ff8080",
      "#ffd271",
      "#c6f1d6"
    ];
    return this.number !== 3 ? this.createColors() : threeColors; 
  }

  public getSegment = () : Array<string> => {
    let dataArray = [];
    const colors = this.getColors();
    for (let i = 0; i < this.number; i++) {
      dataArray.push(colors[i]);
    };
    return dataArray;
  }

  public getRPosition = (r : number) : string => {
    let scalePosition = r - this.min + 1;
    const max = this.number - this.min + 1;
    if ( scalePosition <= this.min ) scalePosition = this.min;
    if ( scalePosition > max ) scalePosition = max;
    const unit = 100 / (this.number * 2);
    const positionCoeff = (scalePosition * 2) - 1;
    const leftPosition = unit * positionCoeff;
    return `${leftPosition}%`;
  };

};