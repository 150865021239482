import {
  forkJoin as observableForkJoin, of as observableOf,
  Observable,
  of,
  zip,
  forkJoin
} from 'rxjs';

import { mergeMap, merge, share, tap, filter, map } from 'rxjs/operators';
import {
  EventEmitter,
  Injectable
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  Router
} from '@angular/router';

import * as _ from 'lodash';

import {
  CampaignMetadata,
  CampaignStates,
  Category,
  Categories,
  Evaluation,
  LightCampaign,
  SynergyDemand,
  User,
  ViewType,
  EvaluationWrapper,
  ViewTypes,
  Workflow,
  Campaign,
  WorkflowPBI,
  Answer,
  FilterSchedule,
  Image,
  Graph,
  OnePager,
  OnePagerMulti,
  GroupedFormula,
  Hub,
  CampaignInfosGen,
  Target,
  Protocol,
  CampaignProtocol,
  CampaignTarget,
  UserCampaign,
  CampaignParameters,
  CampaignParameter,
  CampaignDisplayMode,
  CampaignAnalyse,
  CampaignWorkflow,
  CampaignWorkflowGraphs,
  CampaignOnePager,
  CampaignFormulas,
  FormulaCampaign,
  CampaignPublication,
  UserInCampaign,
  CampaignUsers,
  CampaignRawData,
  StudyType,
  Method,
  CampaignVisits,
  CampaignVisit,
  CampaignPublicationPut,
  Actiview,
  AttributeData,
  ActiviewCampaign,
  Result,
  ImagePhoto,
  Photo,
  StudyTimepointVolunteer,
  ValuesTimepoint,
  StudyTimepoint,
  Timepoint,
  ImageBlob,
  VolunteerRando, References
} from '../types';
import {
  environment
} from '../../environments/environment';
import {
  SynergyDemandService
} from '../shared/services/synergy-demand.service';
import { HttpRestService } from '../shared/services/httpRest.service';
import { EvaluationService } from './detail/edit-campaign/raw-data/evaluations.service';
import { LocalStorageService } from '../shared/services/localStorage.service';
import { UserService } from '../shared/services/user.service';
import { UtilService } from '../shared/services/util.service';

@Injectable()
export class CampaignService {
  campaign: Campaign = new Campaign();
  serverUrlStudio: string;
  users: User[];
  workflows: Workflow[] = [];
  campaigns: LightCampaign[] = [];
  campaignsSelected: LightCampaign[] = [];
  campaignsSelectedForExport: LightCampaign[] = [];
  formulas: GroupedFormula[] = [];
  breadCrumb: EventEmitter<any> = new EventEmitter();
  public updateTemplate: EventEmitter<any> = new EventEmitter();
  user: User;
  randoVolunteers : any;
  CUSTOMIZE_CARD_MODE: ViewType = ViewTypes.ModeCustomizeCard;

  private storageKeys = {
    Campaign: 'DNA_CAMPAIGN',
    CampaignMulti: 'DNA_CAMPAIGN_MULTI',
    CampaignMetadata: 'DNA_CAMPAIGN_METADATA',
    CampaignsLite: 'DNA_CAMPAIGN_LITE',
    CloneCampaign: 'DNA_CLONE_CAMPAIGN',
    ActiviewMethods: 'DNA_ACTIVIEW_METHODS',
    ActiviewTypes: 'DNA_ACTIVIEW_TYPES',
    Characterizations: 'DNA_Characterizations'
  };

  reportMetadata: WorkflowPBI;
  timepointsData: any;
  timepointsReferences: Timepoint[];
  volunteersId: string[] = [];
  studyTimepoint: StudyTimepoint;
  constructor(
    private http: HttpClient,
    private router: Router,
    private synergyDemandService: SynergyDemandService,
    private httpRestService: HttpRestService,
    private evaluationService: EvaluationService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private utilService: UtilService
  ) {
    this.serverUrlStudio = environment.server_url_studio();
  }

  onChangeTemplateEmit(isTemplate: boolean) {
    this.updateTemplate.emit(isTemplate);
  }

  onUpdateBreadCrumb() {
    return this.breadCrumb;
  }

  updateBreadCrumb(campaignName) {
    this.breadCrumb.emit(campaignName);
  }

  cacheRequest(httpRequest: Observable<any>, cacheRequest: Observable<any>): Observable<any> {
    let http = httpRequest.pipe(share());
    const cacheIsEmpty = cacheRequest.pipe(
      filter(_.isEmpty),
      mergeMap(() => http));

    const cacheNotEmpty = cacheRequest.pipe(
      filter(data => !_.isEmpty(data)))

    const cacheNotEmptyHttp = cacheRequest.pipe(
      filter(data => !_.isEmpty(data)),
      mergeMap(() => http));

    return cacheIsEmpty.pipe(merge(cacheNotEmpty.pipe(merge(cacheNotEmptyHttp))));
  }


  getArcsSystem(campaign: Campaign | CampaignOnePager, panelistName: string): string {
    if (_.get(campaign, 'arcs') == null) {
      delete campaign.arcs;
    }
    for (let arc of _.get(campaign, 'arcs', [])) {
      let panelist = _.get(arc, 'panelists', []).find(p => p.PanelistID === panelistName);
      if (panelist) return arc.arcsSystem;
    }
    return this.getArcsSystemFromHub(campaign.hub);
  }

  getArcsSystemFromHub(hub: Hub) {
    switch (hub) {
      case 'Brazil':
        return 'BR';
      case 'China':
        return 'CH';
      case 'India':
        return 'IN';
      case 'Japan':
        return 'JP';
      case 'US':
        return 'US';
      default:
        return 'FR';
    }
  }

  createCampaign(campaign: Campaign, onePager: OnePager): Observable<Campaign> {
    const body = {
      campaign: campaign,
      onePager: onePager
    }
    return this.http.post<Campaign>(`${environment.apiStudiesUrl}/v1/campaigns/`, body);
  }

  deleteCampaign(id: string): Observable<Campaign> {
    return this.http.delete<Campaign>(`${environment.apiStudiesUrl}/v1/campaigns/${id}`);
  }

  deleteWorkflowWithProject(workflow: Workflow): Observable<Workflow> {
    return this.http.put<Workflow>(`${environment.apiStudiesUrl}/v1/campaigns/workflow/${workflow.id}`, workflow);
  }

  exportEvaluationToExcel(idCampaign: string): Observable<Blob> {
    return this.http.get(`${environment.apiStudiesUrl}/v1/studies/${idCampaign}/evaluations`, {
      responseType: "arraybuffer",
      observe: 'response'
    }).pipe(map((response: any) => {
      return new Blob([response.body], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
    }))
  }

  // Getter & Setter: Campaign from localStorage
  getCampaignFromLocalStorage = (id: string): Campaign => {
    return this.localStorageService.getItem(this.storageKeys.Campaign + id);
  }

  setCampaignFromLocalStorage(campaign: Campaign) {
    this.localStorageService.setItem(this.storageKeys.Campaign + campaign.id, campaign);
  }

  // Getter & Setter: Campaign
  getCampaign = (): Campaign => {
    return this.campaign;
  }

  setCampaign(campaign: Campaign) {
    this.campaign = campaign;
  }

  getCampaignMetadata(): Observable<CampaignMetadata> {
    return this.getHttpMetadata();
    // let cacheRequest = observableOf(this.getMetadataFromLocalStorage());
    // return this.cacheRequest(httpRequest, cacheRequest).pipe(tap(metadata => this.setMetadataFromLocalStorage(metadata)));
  }

  CampaignTemplate(idCampaign: string): Observable<{ isTemplate: boolean }> {
    return this.http.get<{ isTemplate: boolean }>(`${environment.apiStudiesUrl}/v1/campaigns/${idCampaign}/template`);
  }

  getCurrentCampaign(id: string): Observable<Campaign> {
    return this.getHttpCampaign(id);
    //  let httpRequest = this.getHttpCampaign(id);
    //   let cacheRequest = observableOf(this.getCampaignFromLocalStorage(id));
    //   return this.cacheRequest(httpRequest, cacheRequest).pipe(tap(campaign => this.setCampaignFromLocalStorage(campaign)));
  }

  postOnePagerToMilor(idCampaign: string, idOnePager: string, onePager: string, projectTitle: string, recipients: string[], peopleId: string, contributors: string[]) {
    const body = {
      peopleId: peopleId,
      base64ofPDF: onePager,
      projectTitle: projectTitle,
      //   recipients: recipients,
      authors: contributors
    }
    return this.http.post(`${environment.apiApptalkUrl}/v1/milor/${idCampaign}/${idOnePager}`, body);
  }

  getHttpCampaign(id: string): Observable<Campaign> {
    return this.http.get<Campaign>(`${environment.apiStudiesUrl}/v1/campaign/${id}`);
  }

  getHttpCampaigns(): Observable<LightCampaign[]> {
    return this.http.get<LightCampaign[]>(`${environment.apiStudiesUrl}/v1/campaigns`);
  }

  getHttpCampaignsWithFilter(filter: FilterSchedule, saveCampaign: boolean = false, withEvaluations: boolean = true): Observable<Result> {
    return this.http.get<Result>(`${environment.apiStudiesUrl}/v1/campaigns?withEvaluations=${withEvaluations}&${this.utilService.getParams(filter)}`).pipe(
      tap((result: Result) => {
        if (saveCampaign) {
          this.setCampaigns(result.list)
        }
      }));
  }

  exportHttpCampaignsWithFilter(displayName: string, listId: { id: string }[]): Observable<Blob> {
    return this.http.post(`${environment.apiStudiesUrl}/v1/campaigns/export-data-set`, {displayName: displayName, listId: listId}, {
      responseType: "arraybuffer",
      observe: 'response'
    }).pipe(map((response: any) => {
      return new Blob([response.body], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
    }))
  }

  exportConfortPanel(idCampaign: string): Observable<Blob> {
    return this.http.get(`${environment.apiStudiesUrl}/v1/campaigns/${idCampaign}/export-confort`, {
      responseType: "arraybuffer",
      observe: 'response'
    }).pipe(
      map((response: any) => {
        return new Blob([response.body], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
      })
    );
  }

  getHttpMetadata(): Observable<CampaignMetadata> {
    return this.http.get<CampaignMetadata>(`${environment.apiStudiesUrl}/v1/campaigns/metadata`).pipe(tap(metadata => this.setMetadataFromLocalStorage(metadata)));
  }

  // Getter & Setter: Metadata from localStorage
  getMetadataFromLocalStorage(): CampaignMetadata {
    return this.localStorageService.getItem(this.storageKeys.CampaignMetadata);
  }

  setMetadataFromLocalStorage(metadata: CampaignMetadata) {
    this.localStorageService.setItem(this.storageKeys.CampaignMetadata, metadata);
  }

  getEvaluationPerId(idCampaign, idEvaluation): Observable<any> {
    return this.http.get(`${environment.apiStudiesUrl}/v1/campaign/${idCampaign}/evaluations/${idEvaluation}`);
  }

  // Getter & Setter: Metadata report from localStorage
  getReportMetadata(): WorkflowPBI {
    return this.reportMetadata;
  }

  setReportMetadata(metadata: WorkflowPBI) {
    this.reportMetadata = metadata;
  }

  getWorkflowsProjectByStudy(studyType: string): Observable<Workflow[]> {
    return this.http.get<Workflow[]>(this.serverUrlStudio + '/campaigns/workflows/project/' + studyType);
  }

  importExcelEvaluation(campaignId: string, dataFile: any): Observable<Evaluation[]> {
    return this.http.put<Evaluation[]>(`${environment.apiStudiesUrl}/v1/campaign/${campaignId}/importExcel`, dataFile);
  }

  putCampaign = (campaign: Campaign): Observable<Campaign> => {
    return this.http.put<Campaign>(this.serverUrlStudio + '/campaigns/' + campaign.id, campaign).pipe(mergeMap(campaign => this.updateUserCampaign(campaign)));
  }

  /**
   * PUT CAMPAIGN BY SCREEN
   */

  updateCampaignWithSynergy(campaignId: string, synergyDemand: SynergyDemand): Observable<Campaign> {
    return this.http.put<Campaign>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/synergy`, synergyDemand);
  }

  putCampaignState(campaignId: string, state: any): Observable<Campaign> {
    return this.http.patch<Campaign>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}`, state);
  }

  updateWorkflowWithProject(workflow: Workflow): Observable<Workflow> {
    return this.http.patch<Workflow>(this.serverUrlStudio + '/workflows/' + workflow.id, {
      project: workflow.project
    });
  }

  updateWorkspaceInCampaign(campaign: Campaign): Observable<Campaign> {
    return this.http.patch<Campaign>(`${environment.apiStudiesUrl}/v1/campaigns/${campaign.id}`, {
      workspaces: campaign.workspaces
    });
  }

  /* Get Actiview Data */

  getActiviewMethods(): Observable<Method[]> {
    const methods = this.getActiviewMethodsFromLocalStorage();

    if (methods && methods.length && methods.length > 0) {
      return observableOf(methods);
    }

    return this.getHttpActiviewRefs().pipe(
      tap(result => this.setActiviewRefsInLocalStorage(result)),
      map(metadata => metadata.methods)
    );
  }
  /* Get Actiview Data */
  getActiviewRefs(): Observable<References> {
    const methods = this.getActiviewMethodsFromLocalStorage() || [];
    const types = this.getActiviewTypesFromLocalStorage() || [];
    if (methods.length > 0 || types.length > 0) {
      return observableOf();
    }
    return this.getHttpActiviewRefs().pipe(tap((result) => this.setActiviewRefsInLocalStorage(result)));
  }

  getActiviewTypes(): Observable<StudyType[]> {
    const types = this.getActiviewTypesFromLocalStorage();
    if (types && types.length && types.length > 0) {
      return observableOf(types);
    }
    return this.getHttpActiviewRefs().pipe(
      tap(metadata => this.setActiviewRefsInLocalStorage(metadata)),
      map(metadata => metadata.types));
  }

  postActiviewData(idCampaign: string, data: ActiviewCampaign): Observable<Actiview> {
    return this.http.post<Actiview>(`${environment.apiApptalkUrl}/v1/actiview/campaigns/${idCampaign}`, data);
  }

  private getHttpActiviewRefs(): Observable<References> {
    return this.http.get<any>(`${environment.apiApptalkUrl}/v1/actiview/ref/types/methods`);
  }

  private getActiviewMethodsFromLocalStorage(): Method[] {
    return this.localStorageService.getItem(this.storageKeys.ActiviewMethods);
  }

  private setActiviewRefsInLocalStorage(metadata: References) {
    this.localStorageService.setItem(this.storageKeys.ActiviewMethods, metadata.methods);
    this.localStorageService.setItem(this.storageKeys.ActiviewTypes, metadata.types);
  }

  private getActiviewTypesFromLocalStorage(): StudyType[] {
    return this.localStorageService.getItem(this.storageKeys.ActiviewTypes);
  }

  getCharacterizations(): AttributeData[] {
    return this.localStorageService.getItem(this.storageKeys.Characterizations);
  }

  setCharacterizations(characterizations: AttributeData[]) {
    this.localStorageService.setItem(this.storageKeys.Characterizations, characterizations);
  }

  /********** FUNCTIONS **********/

  /**
   * Vérifie que l'utilisateur connecté fait parti des utilisateurs de la campagne
   * @param campaign
   * @param userConnected
   * @return boolean
   */
  getUserAuthorizedforCampaign(accountables: UserInCampaign[], userConnected: User): boolean {
    return !_.isUndefined(accountables.find(a => a.key === userConnected.track));
  }

  /**
   * Vérifie que l'utilisateur connecté fait parti des utilisateurs de lévaluation
   * @param evaluation
   * @param userConnected
   * @return boolean
   */
  getUserAuthorizedforEval(evaluation: Evaluation, userConnected: User): boolean {
    const isAuthorized = evaluation.users.find(a => a.key === userConnected.track);
    return isAuthorized ? true : false;
  }

  displayUsersName(metadata: CampaignMetadata): User[] {
    const users = _.get(metadata, 'users', []);
    return this.setUsersName(users);
  }

  private setUserName = (user: User): User => {
    user.name = this.formatUserName(user.name);
    user.name = _.startCase(user.name).replace(" R I", "")
    return user;
  }

  formatUserName(name) {
    const index = name.indexOf("@");
    return index !== -1 ? name.slice(0, index + 1) : name;
  }

  decodeUserName = (name: string = ""): string => {
    try {
      name = decodeURIComponent(Array.prototype.map.call(atob(name), function (nameEncoded) {
        return '%' + ('00' + nameEncoded.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    } catch (err) {
    }
    return name;
  }

  setUsersName(users: User[]): User[] {
    return users.map(this.setUserName);
  }

  getCategoriesHair(): Category[] {
    return [
      Categories.Additive,
      Categories.Aerosol,
      Categories.BaseCoat,
      Categories.Beads,
      // Categories.CleansingFoamCleanser,
      // Categories.CleansingRinseOff,
      // Categories.CleansingWipeOff,
      Categories.CompetitorProduct,
      Categories.Conditionner,
      Categories.Cream,
      Categories.Device,
      Categories.Diluteur,
      Categories.Dough,
      Categories.Fixative,
      Categories.Gel,
      Categories.Lotion,
      Categories.Mask,
      Categories.MilkyLotion,
      Categories.Mousse,
      Categories.Oil,
      Categories.Oxidizing,
      Categories.Powder,
      Categories.Reducing,
      Categories.Shampoo,
      Categories.Serum,
      Categories.Spray,
      Categories.Thickener,
      Categories.TopCoat,
      Categories.Wax
    ];
  }

  getCategoriesSkin(): Category[] {
    return [
      Categories.CleansingFoamCleanser,
      Categories.CleansingRinseOff,
      Categories.CleansingWipeOff,
      Categories.CompactFoundation,
      Categories.Concealer,
      Categories.Cream,
      Categories.Device,
      Categories.EyebrowLiner,
      Categories.EyeContour,
      Categories.EyeLiner,
      Categories.Fap,
      Categories.Hybrid,
      Categories.LipPencil,
      Categories.LiquidFoundation,
      Categories.Lotion,
      Categories.Mascara,
      Categories.Mask,
      Categories.Oil,
      Categories.Powder,
      Categories.Primer,
      Categories.Ral,
      Categories.Scrub,
      Categories.SheetMask,
      Categories.Serum,
      Categories.Spray
    ];
  }

  getUsersToDisplay(campaign: Campaign) {
    if (this.users && this.users.length > 0) {
      if (campaign.evaluations && campaign.evaluations.length > 0) {
        campaign.evaluations = campaign.evaluations.map(
          (evaluation: Evaluation) => {
            if (evaluation.users && evaluation.users.length > 0) {
              evaluation.users = evaluation.users.map(
                (evaluationUser: {
                  key: string,
                  name?: string
                }) => {
                  evaluationUser.name = this.users.find((user: User) => user.key === evaluationUser.key).name;
                  return evaluationUser;
                }
              );
            }
            return evaluation;
          }
        );
      }
    }
    return campaign;
  }

  goTo(campaign: LightCampaign) {
    campaign.state === CampaignStates.Published ? this.router.navigate(['campaigns', campaign.id, 'edit', 'rawdata']) :
      this.router.navigate(['campaigns', campaign.id, 'edit']);
  }

  setDateToTimestamp(date: Date) {
    return date instanceof Date && !isNaN(date.valueOf()) ? date.getTime() : date;
  }

  unlinkSynergyDemandWithCampaign(synergyDemand: SynergyDemand): Observable<SynergyDemand> {
    synergyDemand.studyId = '';
    return this.synergyDemandService.updateSynergyDemandWorkspaces(synergyDemand).pipe(
      mergeMap(synergy => this.synergyDemandService.patchSynergyDemand(synergy)));
  }

  isTemplatePublishedCampaign(isPublished: boolean, state: CampaignStates) {
    if(state === CampaignStates.Published) { isPublished = true; }
    return isPublished && (state !== CampaignStates.Draft && state !== CampaignStates.Suspended);
  }

  /**
   * CAMPAIGN FORMULA
   */

  /**
   * 21979
   * @param campaignFormula
   * @returns
   */
  isValidCampaignFormula(campaignFormula: FormulaCampaign) {
    if (campaignFormula) {
      const applicationZoneNumber = parseInt(_.get(campaignFormula, 'applicationZone.key', '1'));
      const nbFormulasOrRoutines = _.get(campaignFormula, 'listFormulas', []).length;
      if (nbFormulasOrRoutines > 0) {
        return nbFormulasOrRoutines >= applicationZoneNumber;
      }
      return false;
    }
    return false;
  }

  /**
   * RAW-DATA
   */

  campaignContainsPhotos(campaign: Campaign) {
    for (let wk of campaign.workflows) {
      for (let block of wk.blocks) {
        for (let component of block.components) {
          if (component.type === 'dna-photo') return true;
        }
      }
    }
    return false;
  }

  campaignContainsVideos(campaign: Campaign) {
    for (let wk of campaign.workflows) {
      for (let block of wk.blocks) {
        for (let component of block.components) {
          if (component.type === 'dna-video-capture') return true;
        }
      }
    }
    return false;
  }

  getEvaluations(campaigns: any[]): EvaluationWrapper[] {
    return campaigns.reduce((acc: EvaluationWrapper[], curr: Campaign) => {
      if (curr.evaluations) {
        const completeEval = this.evaluationService.getEvaluationsPerCampaign(curr.id).toPromise();
        curr.evaluations = curr.evaluations.map((e: EvaluationWrapper) => {
          completeEval.then((evals: Evaluation[]) => {
            evals.forEach((evaluation: Evaluation) => {
              if (evaluation.id === e.id) {
                e.dateStart = evaluation.dateStart;
              }
            });
          });
          e.studyId = curr.id;
          e.workflowName = curr.workflows.find((w: Workflow) => w.id === e.questionnaireId).name;
          e.campaignName = curr.name;
          e.formulaName = e.formula.name;
          return e;
        });
        return acc.concat(curr.evaluations);
      } else {
        return acc;
      }
    }, []);
  }

  getExportExcel(campaignId: string) {
    return this.httpRestService.exportVisualizationToExcel(campaignId, 'campaigns');
  }

  downloadImg(campaignName: string, workflows: any, evaluations: Evaluation[], firstFolder: any) {
    let newEvaluations: Observable<any>[] = [];

    evaluations.forEach((evaluation: Evaluation) => {
      newEvaluations.push(this.getEvaluationPerId(evaluation.studyId, evaluation.id));
    });

    return observableForkJoin(newEvaluations).pipe(
      mergeMap((evals) => {
        let observables: Observable<any>[] = [];
        evals.forEach((evaluation: Evaluation) => {
          evaluation.answers.forEach((answer: Answer) => {
            let subFolder = firstFolder.folder(evaluation.formula.name);
            let index = 1;
            if (answer.idComponent == "dna-photo" && answer.records && answer.records.length > 0 && answer.records[0].data && answer.records[0].data.length !== 0 && answer.records[0].data[0].value) {
              observables.push(this.evaluationService.getImage(answer, subFolder, index, evaluation, campaignName, workflows));
              index += 1;
            }
          });
        });
        return observableForkJoin(observables);
      }))
  }

  downloadVideo(campaignName: string, workflows: any[], evaluations: Evaluation[], firstFolder: any) {
    let newEvaluations: Observable<any>[] = [];

    evaluations.forEach((evaluation: Evaluation) => {
      newEvaluations.push(this.getEvaluationPerId(evaluation.studyId, evaluation.id));
    });

    return observableForkJoin(newEvaluations).pipe(
      mergeMap((evals) => {
        let observables: Observable<any>[] = [];
        evals.forEach((evaluation: Evaluation) => {
          evaluation.answers.forEach((answer: Answer) => {
            let subFolder = firstFolder.folder(evaluation.formula.name);
            let index = 1;
            if (answer.idComponent == "dna-video-capture" && answer.records && answer.records.length > 0 && answer.records[0].data && answer.records[0].data.length !== 0 && answer.records[0].data[0].value && answer.records[0].data[0].value !== 'NOT_EXPECTING_ANSWER') {
              observables.push(this.evaluationService.getVideo(campaignName, workflows, evaluation, answer, subFolder, index));
              index += 1;
            }
          });
        });
        return observableForkJoin(observables);
      }))
  }

  fillImagesTab(onePager: OnePager | OnePagerMulti): Image[] {
    return _.get(onePager, 'images', []).map(i => this.getData(i));
  }

  getData(i): Image {
    return this.addToItems(this.getFromMedia(i.url), i);
  }

  addToItems(data: Observable<string>, image: Image): Image {
    return {
      data: data,
      name: image.name,
      url: image.url
    }
  }

  getFromMedia(url: string): Observable<string> {
    return this.http.get<any>(url).pipe(map(object => `${object.type},${object.media}`));
  }

  /**
   * getChartsFromCampaign
   * Service method that handles HTTP request to get all charts
   * associated to a given campaign & a given workflow.
   * @param idCampaign : string
   * @param idWorkflow : string
   * @return Observable<Graph[]> // Returning an observable any caller can subscribe to finally get an array of charts (```Graph```)
   */
  public getChartsFromCampaign(idCampaign: string, idWorkflow: string): Observable<Graph[]> {
    return this.http.get<Result>(`${environment.apiStudiesUrl}/v1/campaigns/${idCampaign}/workflows/${idWorkflow}/charts`).pipe(map(result => result.list));
  };

  /**
   * getChartsData
   * Service method to retreive data for a given chart, filtered by `filters` object
   * @param idCampaign  : string
   * @param idWorkflow  : string
   * @param chart       : Graph // The targeted chart
   * @param filters     : any
   * @return Observable<any> // Returning an observable any caller can subscribe to finally get an data for a given chart
   */
  getChartsData(idCampaign: string, idWorkflow: string, chart: Graph, filters: any): Observable<any> {
    return this.http.post<any>(`${environment.apiStudiesUrl}/v1/campaigns/${idCampaign}/workflows/${idWorkflow}/charts`, {
      graph: chart,
      filters
    });
  };

  getCampaignsLight(filter: FilterSchedule): Observable<Result> {
    return this.getHttpCampaignsWithFilter(filter, false, true);
  }

  getCampaigns() {
    return this.campaigns;
  }

  getCampaignsSelected() {
    return this.campaignsSelected;
  }

  setCampaigns(campaigns: LightCampaign[]) {
    this.campaigns = campaigns;
  }

  setCampaignsSelected(campaignsSelected: LightCampaign[]) {
    this.campaignsSelected = campaignsSelected;
  }

  setCampaignsSelectedForExport(campaignsSelected: LightCampaign[]) {
    this.campaignsSelectedForExport = campaignsSelected;
  }

  resetCampaigns() {
    this.setCampaigns([]);
    this.setFormulas([]);
    this.setWorkflows([]);
  }

  getWorkflows() {
    return this.workflows;
  }

  setWorkflows(workflows: Workflow[]) {
    this.workflows = workflows;
  }

  getFormulas() {
    return this.formulas;
  }

  setFormulas(formulas: GroupedFormula[]) {
    this.formulas = formulas;
  }

  // -------------- New AF campaign get/put -------------------

  // DUPLICATE
  duplicateCampaign(id: string, body: any) {
    return this.http.put<{id: string}>(`${environment.apiStudiesUrl}/v1/campaigns/${id}/duplicate`, body);
  }

  // INFO GEN
  getCampaignInfoGen(id: string): Observable<CampaignInfosGen> {
    return this.http.get<CampaignInfosGen>(`${environment.apiStudiesUrl}/v1/campaigns/${id}/generaleInformation`);
  }

  putCampaignInfoGen(campaignInfoGen: CampaignInfosGen): Observable<CampaignInfosGen> {
    return this.http.put<CampaignInfosGen>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignInfoGen.id}/generaleInformation`, campaignInfoGen);
  }

  // PROTOCOL
  getCampaignProtocol(campaignId: string): Observable<CampaignProtocol> {
    return this.http.get<CampaignProtocol>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/protocol`);
  }

  putCampaignProtocol = (campaignId: string, protocol: Protocol): Observable<string> => {
    return this.http.put<string>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/protocol`, protocol);
  }

  // TARGET
  getCampaignTarget(campaignId: string): Observable<CampaignTarget> {
    return this.http.get<CampaignTarget>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/target`);
  }

  putCampaignTarget = (campaignId: string, target: Target): Observable<string> => {
    return this.http.put<string>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/target`, target);
  }

  // PARAMETERS
  getCampaignParameters(campaignId: string): Observable<CampaignParameters> {
    return this.http.get<CampaignParameters>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/parameters`);
  }

  putCampaignParameters = (campaignId: string, parameters: CampaignParameter, users: UserCampaign, idEvaluationList: string[] = null): Observable<string> => {
    return this.http.put<string>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/parameters`, {
      parameters: parameters,
      users: users,
      idEvaluationList : idEvaluationList
    });
  }
  putCampaignEvaluationAfterPublish = (campaignId: string, usersFormulas:string[]): Observable<any> =>{
    return this.http.put<any>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/addEvaluations`, {
      usersFormulas: usersFormulas,
    });
  }

  createEvaluationAfterPublish(idCampaign:string, idEvaluation:string):Observable<string>{
    return this.http.post<any>(`${environment.apiStudiesUrl}/v1/campaigns/${idCampaign}/evaluations/${idEvaluation}`, {
    });
  }

  // DISPLAY MODE
  getCampaignDisplayMode(campaignId: string): Observable<CampaignDisplayMode> {
    return this.http.get<CampaignDisplayMode>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/displayMode`);
  }

  putCampaignDisplayMode = (campaignId: string, parameters: CampaignParameter, visits: CampaignVisit[]): Observable<Campaign> => {
    return this.http.put<Campaign>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/displayMode`, {
      parameters: parameters,
      visits: visits
    });
  }

  // RAW DATA
  getCampaignRawData(id: string): Observable<CampaignRawData> {
    return this.http.get<CampaignRawData>(`${environment.apiStudiesUrl}/v1/campaigns/${id}/rawData`);
  }

  // ANALYSE
  getCampaignAnalyse(campaignId: string): Observable<CampaignAnalyse> {
    return this.http.get<CampaignAnalyse>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/analyse`);
  }

  // PUBLICATION
  getCampaignPublication(campaignId: string): Observable<CampaignPublication> {
    return this.http.get<CampaignPublication>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/publication`);
  }

  putCampaignPublicationToxAgreement(campaignId: string) {
    return this.http.get<CampaignPublication>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/publication/toxAgreement`);
  }

  putCampaignPublicationRando(campaignId: string, campaignPublication: CampaignPublication): Observable<CampaignPublicationPut> {
    return this.http.put<CampaignPublicationPut>(`${environment.apiAnswersUrl}/v1/campaigns/${campaignId}/publication/rando`, campaignPublication.evaluations);
  }

  publishCampaign(campaignId: string) {
    return this.http.get<Campaign>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/publication/publish`);
  }

  suspendCampaign(campaignId: string) {
    return this.http.get<CampaignPublication>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/publication/suspend`);
  }

  abandonCampaign(campaignId: string) {
    return this.http.get<CampaignPublication>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/publication/abandon`);
  }

  putCampaignPublicationCharac(campaignId: string, characterizations: AttributeData[]) {
    return this.http.put<any>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/publication/characterization`, characterizations);
  }

  // NON ARC VOLUNTEERSs

  deleteNonArcVolunteers(hub: string, idGroupPanelist: string, idCampaign: string): Observable<any> {
    return this.http.put<any>(`${environment.apiStudiesUrl}/v1/panelistGroupsHorsArcs/${hub}/${idGroupPanelist.replace(/\//g, "~")}/campaigns/${idCampaign}`,"");
  }

  // WORKFLOWS
  getCampaignWorkflow(campaignId: string): Observable<CampaignWorkflow> {
    return this.http.get<CampaignWorkflow>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/workflow`);
  }

  putCampaignWorkflow = (campaign: CampaignWorkflow): Observable<{ blocksDeleted: any[], haveBlocksStatusChangedOrDeleted: boolean, message: string, workflows: Workflow[] }> => {
    return this.http.put<{ blocksDeleted: any[], haveBlocksStatusChangedOrDeleted: boolean, message: string, workflows: Workflow[] }>(`${environment.apiStudiesUrl}/v1/campaigns/${campaign.id}/workflow`, campaign);
  }

  deleteCampaignWorkflow = (campaignId: string, idWorkflow: string): Observable<string> => {
    return this.http.put<string>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/workflow/${idWorkflow}/delete`, {});
  }

  // VISITS
  getCampaignVisits(campaignId: string): Observable<CampaignVisits> {
    return this.http.get<CampaignVisits>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/campaignVisits`);
  }

  putCampaignVisits = (idCampaign: string, campaignVisits: CampaignVisits): Observable<{ unableDeleteVisitTab: any[], visits: CampaignVisit[] }> => {
    return this.http.put<{ unableDeleteVisitTab: any[], visits: CampaignVisit[] }>(`${environment.apiStudiesUrl}/v1/campaigns/${idCampaign}/campaignVisits`, { campaignVisits: campaignVisits });
  }

  // GRAPHS
  getCampaignGraphsbyWorkflowId(campaignId: string, workflowId: string): Observable<CampaignWorkflowGraphs> {
    return this.http.get<CampaignWorkflowGraphs>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/workflow/${workflowId}/graphs`);
  }

  putCampaignGraphsbyWorkflowId = (campaignId: string, body: any): Observable<string> => {
    return this.http.put<string>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/graphs`, body);
  }

  putCampaignGraphOptions = (campaignId: string, body: any): Observable<string> => {
    return this.http.put<string>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/graphs/options`, body);
  }

  getActiviewFromCampaign = (campaignId: string): Observable<ActiviewCampaign> => {
    return this.http.get<ActiviewCampaign>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/actiview`);
  }

  // FORMULAS
  getCampaignFormulas(campaignId: string): Observable<CampaignFormulas> {
    return this.http.get<CampaignFormulas>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/formulas`);
  }

  putCampaignFormulas = (campaignId: string, parameters: CampaignParameter, formula: FormulaCampaign, visits: CampaignVisit): Observable<{ formula: FormulaCampaign, errorCouple: string[], errorBench: string[] }> => {
    return this.http.put<{ formula: FormulaCampaign, errorCouple: string[], errorBench: string[] }>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/formulas`, {
      parameters: parameters,
      formula: formula,
      visits: visits
    });
  }

  // USERS
  getCampaignUsers(campaignId: string): Observable<CampaignUsers> {
    return this.http.get<CampaignUsers>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/users`);
  }

  putCampaignUsers = (campaign: CampaignUsers): Observable<{ users: UserCampaign, errorDelete: object }> => {
    return this.http.put<{ users: UserCampaign, errorDelete: object }>(`${environment.apiStudiesUrl}/v1/campaigns/${campaign.id}/users`, campaign);
  }

  // ONE PAGER
  getCampaignOnePager(campaignId: string): Observable<CampaignOnePager> {
    return this.http.get<CampaignOnePager>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/onePager`);
  }

  putCampaignOnePager = (campaignId: string, idOnePager: string): Observable<Campaign> => {
    return this.http.put<Campaign>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/onePager`, { idOnePager: idOnePager });
  }

  // ACTIVIEW DATA 20868
  putCampaignActiviewData = (campaignId: string, body: any): Observable<any> => {
    return this.http.put<any>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/actiview`, body);
  }

  getConfortTime(id: string): Observable<{ confortTimeDisabled: string[], confortComponents: any }> {
    return this.http.get<{ confortTimeDisabled: string[], confortComponents: any }>(`${environment.apiStudiesUrl}/v1/campaigns/${id}/publication/confortTime`);
  }

  putConfortTime(id: string, confortTimeDisabled: string[]) {
    return this.http.put<any>(`${environment.apiStudiesUrl}/v1/campaigns/${id}/publication/confortTime`, { confortTimeDisabled: confortTimeDisabled });
  }



  removeMetadataOnly() {
    this.localStorageService.remove(this.storageKeys.Campaign);
    this.localStorageService.remove(this.storageKeys.CampaignMulti);
    this.localStorageService.remove(this.storageKeys.CampaignMetadata);
    this.localStorageService.remove(this.storageKeys.CampaignsLite);
    this.localStorageService.remove(this.storageKeys.CloneCampaign);
    this.localStorageService.remove(this.storageKeys.ActiviewMethods);
    this.localStorageService.remove(this.storageKeys.ActiviewTypes);
    this.localStorageService.remove(this.storageKeys.Characterizations);
    this.localStorageService.remove("DNA_Url");
    this.localStorageService.remove("DNA_Users");
  }

  updateUserCampaign(campaign: Campaign) {
    let obs = [];
    if (campaign.state === 'PUBLISHED') {
        campaign.users.accountables.map(a => {
            if (a.isActive) {
                // const campaignLight = this.createCampaignLightFromCampaign(campaign);
                obs.push(this.http.put(`${environment.apiStudiesUrl}/v1/users/${a.key}/campaigns/${campaign.id}`, {}));
            }
            else {
              obs.push(this.http.delete(`${environment.apiStudiesUrl}/v1/users/${a.key}/campaigns/${campaign.id}`));
            }
        })
    }
    else {
        campaign.users.accountables.map(a => {
          obs.push(this.http.delete<String>(`${environment.apiStudiesUrl}/v1/users/${a.key}/campaigns/${campaign.id}`));
        })
    }
    return forkJoin(obs).pipe(map(c => campaign));
}
// DNA PHOTOS
  setTimepointsData(dataValues: {[volunteerId:string]: ValuesTimepoint} = {}) {
    this.timepointsData = dataValues;
  }

  getTimepointsDataByVolunteer(volunteerId:string): ValuesTimepoint {
    return this.timepointsData ? this.timepointsData[volunteerId] : null;
  }

  getTimepointsDataBytimepointId(timepointId: string): any {
    const timepointDataResult = {}
    Object.entries(this.timepointsData).forEach(([key, values]) => {
      if (values[timepointId] && values[timepointId].referencesPhoto.length) {
        timepointDataResult[key] = values[timepointId];
      }
    })
    return timepointDataResult;
  }

  setTimepointsReference(timepointsReferences: Timepoint[]){
    this.timepointsReferences = timepointsReferences;
  }

  getTimepointsReferences(){
    return this.timepointsReferences || [];
  }

  setVolunteersId(volunteersId:string[]){
    this.volunteersId = volunteersId;
  }

  getVolunteersId(): string[]{
    return this.volunteersId;
  }

  fetchImageBlob(mediaUUID: string): Observable<Photo>{
    return this.http.get(`${environment.apiStudiesUrl}/v1/medialoaderdnaphotos/${mediaUUID}`).pipe(
      map((imageblob:ImageBlob)=>{
        return {
          photoId:mediaUUID,
          photoData: this.formatBase64(imageblob.media,'photo','jpeg')
        }
      }
    ));

  }
  formatBase64(data: string, type: 'video' | 'photo', extension?: string) {
    if (!extension) {
      extension = type === 'photo' ? 'jpeg' : 'mp4';
    }
    let prefix = 'data:' + type + '/' + extension + ';base64,';
    data = prefix + data;
    return data;
  }

  setRandoVolunteers(randomisationVolunteers: { [volunteerId: string]: VolunteerRando }){
    this.randoVolunteers = randomisationVolunteers;
  }

  getRandoVolunteers(): { [volunteerId: string]: VolunteerRando }{
    return this.randoVolunteers;
  }

  getRandomisationByVolunteer(volunteerId){
    return this.randoVolunteers[volunteerId];
  }

  getCampaignTimepoints(campaignId: string): Observable<StudyTimepointVolunteer> {
    return this.http.get<StudyTimepointVolunteer>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/timepoints`);
  }

  setStudyPartial(study:StudyTimepoint){
    this.studyTimepoint = study;
  }

  getStudyPartial(): StudyTimepoint{
    return this.studyTimepoint;
  }

  deletePhotoReferencesFromCollections(campaignId:string,idEvaluation:string, idInBlock:string, volunteerId: string, photosReferences:string[]): Observable<any>{
    return this.http.patch<any>(`${environment.apiStudiesUrl}/v1/campaigns/${campaignId}/evaluations/${idEvaluation}/idInBlock/${idInBlock}/volunteers/${volunteerId}/deletePhotoReferences`, {photosIdToDelete:photosReferences});
  }

  deletePhotoData(mediaUUID: string) : Observable<any>{
    return this.http.delete(`${environment.apiStudiesUrl}/v1/dnaphotos/${mediaUUID}/delete`);
  }

  associateDataPhotoAndUpload(file, media:string, type:string, idCampaign:string, volunteerId:string, timepointId:string):Observable<any>{
    const body = {
      // file:file,
      media:media,
      type:type,
    }
    return this.http.post<any>(`${environment.apiStudiesUrl}/v1/campaigns/${idCampaign}/timepoints/${timepointId}/volunteerId/${volunteerId}/addphoto`, body);
  }

  isDataPhotoAvailable(mediaUUID:string): Observable<any>{
    return this.http.get<any>(`${environment.apiStudiesUrl}/v1/dnaphotos/${mediaUUID}/checkAvailablePhoto`);
  }
}
