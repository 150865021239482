import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Resp, Translation, TranslationsFiles } from '../../types';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class TranslationsService {
  serverUrlStudio: string;
  translations: Translation[];

  constructor(private http: HttpClient) {
    this.serverUrlStudio = environment.server_url_studio();
  }

  /**
   ******************** DNA 2 ********************
   */

  /**
   *
   * @param languages
   * @param keyword
   * @returns
   */
  getFilteredTranslations(languages, page, limit, keyword?): Observable<Resp> {
    return keyword
      ? this.http.get<Resp>(`${environment.apiSharedUrl}/v1/translations?page=${page}&limit=${limit}&filters=[language[in]=(${languages});value[ct]=${keyword}]`)
      : this.http.get<Resp>(`${environment.apiSharedUrl}/v1/translations?page=${page}&limit=${limit}&filters=[language[in]=(${languages})]`)
  }

  getTranslationsByLanguage = (language: string): Observable<Resp> => {
    this.http.get<Resp>( `${environment.apiSharedUrl}/v1/translations?filters=[language[eq]=${language}]`)
    .subscribe({
      next: (res) => true,
      complete: () => true,
      error: (err) => console.log("lang from server error", err)
    })
    return this.http.get<Resp>( `${environment.apiSharedUrl}/v1/translations?filters=[language[eq]=${language}]`);
  }

  patchTranslation(id, value) {
    return this.http.patch(`${environment.apiSharedUrl}/v1/translations/${id}`, {value});
  }

  postTranslations = (translation: Translation): Observable<TranslationsFiles> => {
    return this.http.post<TranslationsFiles>(`${environment.apiSharedUrl}/v1/translations`, translation);
  }

  getTranslations() {
    return this.translations;
  }

  setTranslations(translations) {
    this.translations = translations;
  }

  formatTranslations(translations): TranslationsFiles {
    let formatedTraductions: TranslationsFiles = new TranslationsFiles();
    _.values(_.groupBy(translations.list, 'language')).map(trads => {
        return trads.map(trad => {
            formatedTraductions[trad.language][trad.key] = trad.value;
        })
    });
    return formatedTraductions;
  }
}
