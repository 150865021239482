import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';

import { ApplicationInsightsService } from '../../shared/services/applicationInsights.service';
import { WorkspaceService } from '../../general/my-profile/workspaces/workspaces.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'dna-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.less']
})

export class WorkflowComponent implements OnInit, AfterViewInit {
  initTime = performance.now();

  constructor(
    private aiService: ApplicationInsightsService,
    private route: ActivatedRoute,
    private workspaceService: WorkspaceService
  ) {}

  ngOnInit() {
    this.workspaceService.getWorkspacesInLocal().subscribe(wk => wk);
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Workflow', '', performance.now() - this.initTime, templateUrl);
  }
}
