<table class="table table-bordered">
  <thead>
  <tr>
    <th>{{ "CAMPAIGNS" | translate }}</th>
    <th>{{ "USER_ACTIVE" | translate }}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let cmu of filteredArray">
    <th>{{ cmu.campaignName }}</th>
    <th>
      <table>
        <thead>
        <tr>
          <th>{{ "VOLUNTEERS" | translate }}</th>
          <th>{{ "ACTIVE" | translate }}</th>
        </tr>
        </thead>
        <tr *ngFor="let v of cmu.volunteers">
          <th>
            <p class="mb-0">
              {{ v?.name }}
            </p>
          </th>
          <th>
            <dna-switch-confirm [isSelected]="v.isActive" (click)="v.isActive = !v.isActive"></dna-switch-confirm>
          </th>
        </tr>
      </table>
    </th>
  </tr>
  </tbody>
</table>
