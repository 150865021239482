
import { UtilService } from './../../shared/services/util.service';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { DNATypes, Menu } from '../../types';
import * as _ from 'lodash';
import { MultiCampaignsService } from '../multi-campaigns.service';
import { tap } from 'rxjs/operators';
import { DNATranslateService } from './../../shared/services/translate.service';


@Component({
  selector: 'dna-campaign-multi-root',
  templateUrl: './campaign-multi-root.component.html',
  styleUrls: ['./campaign-multi-root.component.less']
})
export class CampaignMultiRootComponent implements OnInit {
  sideMenu: Menu[];
  showSpinner: boolean;
  campaignMultiName: string;
  isOnOnePagerImage = false;


  constructor(
    private campaignMultiService: MultiCampaignsService,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private translateService: DNATranslateService
  ) {}

  ngOnInit() {
    this.utilService.isOnOnePagerImage.subscribe(isOnOnePagerImage => this.isOnOnePagerImage = isOnOnePagerImage);
    const campaignMultiId = this.route.snapshot.paramMap.get('idCampaignMulti');
    this.campaignMultiService.hasNameChanged.subscribe(name => this.setCampaignMultiName(name));
    this.showSpinner = false;
    this.translateService.onLangChange().subscribe((translation: any) => {
      this.updateMenu(campaignMultiId)
  });
    this.route.data.pipe(
        tap(() => this.updateMenu(campaignMultiId))
    )
    .subscribe();
  }

  updateMenu(idCampaignMulti: string) {
    this.utilService.createMenu(DNATypes.CampaignsMulti).subscribe(
      (menus: Menu[]) => {
        this.sideMenu = menus.map((menu: Menu) => {
          menu.link = ['/multi-campaigns', idCampaignMulti].concat(menu.link);
          return menu;
        });
      }
    );
  }

  setCampaignMultiName(name: string) {
    this.campaignMultiName = name;
  }

  onActivate(data) {
    this.showSpinner = false;
  }

  updateSpinner() {
    this.showSpinner = true;
  }

  onOnePagerClick() {
    this.showSpinner = true;
  }
}
