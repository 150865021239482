import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Languages, TranslationsFiles } from "../../../../types";

@Component({
  selector: '[dna-translations-row]',
  templateUrl: './translations-row.component.html',
  styleUrls: ['./translations-row.component.less']
})
export class TranslationsRowComponent implements OnInit {

    @Input() translationsFiles: TranslationsFiles;
    @Input() languagesSelected: Languages[];
    @Input() key: string;

    @Output() onTranslationChange: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit() {}

    onChangeTranslation(language: string, key: string) {
        this.onTranslationChange.emit(
            {
                language: language,
                key: key
            }
        );
    }

}