import {Chart} from "../chart.model";
import {BubbleOptions, HelperPayloadInterface} from './bubble-options.model';
import {Table, TransformedHTMLDataInterface} from "../table";
import {TranslateService} from '@ngx-translate/core';

import BubleChartNoneMethod, {BubbleInputInterface, BubbleDataInterface, BubbleAxisInterface} from "./methods/none.method";
import {Descriptor} from '../../../../../../../../types';
import {Annotation, HTMLDOMElement, SVGDOMElement} from "highcharts";

interface InputDataBubbleInterface {
  data: BubbleInputInterface;
  pDescriptors: Array<Descriptor>;
  routines: any;
}

interface squeletonInterface {
  attribute: {
    label: any;
  };
  axis: string,
  values: any[];
}

const mapSettingsKey: Map<string, string> = new Map();

const drawRoutine = ( chart: Highcharts.Chart, routines, scaleMapping: Map<Highcharts.Point, number> ) => {

  chart.series.forEach( s => {
    s.data.forEach( d => {
      const currentColor = routines.find( r => r.name === d.name ).color;
      const element: HTMLDOMElement|SVGDOMElement = d[ "graphic" ].element;
      const parent: HTMLDOMElement|SVGDOMElement = d[ "graphic" ].element;
      const newElement = document.createElementNS( "http://www.w3.org/2000/svg", "path" );
      const currentScale = scaleMapping.get( d );
      const dData: any[] = element.getAttribute( "d" ).split( " " ).map( d => isNaN( Number( d ) ) ? d : Number( d ) );

      if( currentScale > 1 ) {
        // draw routine cercle when overstacked
        dData[ 2 ] += 2 * ( currentScale - 1 );
        dData[ 4 ] += 2 * ( currentScale - 1 );
        dData[ 5 ] += 2 * ( currentScale - 1 );
        dData[ 10 ] += 2 * ( currentScale - 1 );
      }
      newElement.setAttribute( "d", dData.join( " " ) );
      newElement.setAttribute( "fill", "transparent" );
      newElement.setAttribute( "opacity", "1" );
      newElement.setAttribute( "stroke", String( currentColor ) );
      newElement.setAttribute( "stroke-width", "2" );
      newElement.setAttribute( "data-anchor", "custom" );
      parent.appendChild( newElement );
    } );
  } );
};

const removeDraw = ( chart: Highcharts.Chart ) => {
  const customCircle = chart.container.querySelectorAll( "[data-anchor='custom']" );
  customCircle.forEach( t => {
    t.remove();
  } );
};

const observeChange = ( chart: Highcharts.Chart, routines ) => {

  const positions: {
    [ x: number ]: {
      [ y: number ]: {
        [ z: number ]: number;
      };
    };
  } = {};

  const scaleMapping: Map<Highcharts.Point, number> = new Map();

  chart.series[ 0 ].data.forEach( d => {
    if( !positions[ d.x ] ) positions[ d.x ] = {};
    const currentX = positions[ d.x ];

    if( !currentX[ d.y ] ) currentX[ d.y ] = {};
    const currentY = currentX[ d.y ];

    if( !currentY[ d[ "z" ] ] ) currentY[ d[ "z" ] ] = 0;
    const scale = ++currentY[ d[ "z" ] ];
    scaleMapping.set( d, scale );
  } );

  const observer = new MutationObserver( ( ml, obs ) => {
    removeDraw( chart );
    drawRoutine( chart, routines, scaleMapping );
  } );

  const element = chart.series[ 0 ][ "group" ].element;

  observer.observe( element, {
    attributes: true,
    subtree: true
  } );
};

export class Bubble extends Chart {
  protected _baseParameters: Highcharts.Options = {
    chart: {
      type: "bubble",
      plotBorderWidth: 1,
      zooming: {
        type: "xy"
      },
      events: {
        load: ( (): Highcharts.ChartLoadCallbackFunction => {
          const bubble = this;
          return function () {
            observeChange( this, bubble.routines.routines );
            const formulas = this.series[ 0 ].data;
            bubble.buildGraphOptions( {general: bubble.parameters, data: formulas, bubbleData: true} );
            bubble.onOptionChangeAfterLoadSubject.next( bubble.options );
            const ren = this.renderer;
            ren.label;
          };
        } )()
      }
    },
    xAxis: {
      tickInterval: 1,
      gridLineWidth: 0,
      crosshair: true,
      min: -6,
      max: 6,
      title: {
        text: null,
      }
    },
    yAxis: {
      tickInterval: 1,
      startOnTick: false,
      endOnTick: false,
      maxPadding: 0.2,
      gridLineWidth: 0,
      crosshair: true,
      min: -6,
      max: 6,
      title: {
        text: null
      }
    },
    plotOptions: {
      series: {
        pointPlacement: "on",
        dataLabels: {
          enabled: true,
          style: {
            color: "black",
            textOutline: "none",
            fontWeight: "normal",
          },
          y: -10
        }
      },
      line: {
        marker: {
          enabled: true
        }
      },
    },
    tooltip: {
      shared: true,
      useHTML: true
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      layout: "horizontal",
      enabled: true,
      useHTML: true,
      bubbleLegend: {
        enabled: true,
        labels: {}
      }
    }
  };

  private axis: BubbleAxisInterface;

  public static mapSettings: Map<string, string> = mapSettingsKey;

  constructor (
    _parameters?: any,
    _data?: InputDataBubbleInterface,
    _lang?: string,
    _filters?: any,
    private _translaService?: TranslateService
  ) {
    super( _parameters, {..._data, data: _data.data.data}, _lang, _filters );
    this.axis = _data.data.axis;
    if( this.data && this.data.length ) {
      this.tableRawData = this.formatRawData( _data, _lang );
      this.generateParametersAssignment( this.parameters );
      this.generateDataAssignment( this.data );
    }
  };

  private generateParametersAssignment ( parameters ) {
    Object.assign( {...this._baseParameters, parameters} );
    Object.keys( {...this._baseParameters} ).forEach( ( k ) => {
      this.parameters[ k ] = Object.assign( {...this._baseParameters[ k ]}, parameters[ k ] );
    } );
    Object.keys( {...parameters} ).forEach( ( k ) => {
      this.parameters[ k ] = Object.assign( {...this.parameters[ k ]}, parameters[ k ] );
    } );
  };

  protected generateDataAssignment = ( data ) => {
    this.data = data;
    const outputData = this.formatData( data );

    this.parameters.attributes = [];
    this.parameters.series = [ outputData.serie ];
    this.parameters.xAxis.plotLines = outputData.axis.x;
    this.parameters.yAxis.plotLines = outputData.axis.y;
    this.parameters.tooltip.formatter = outputData.tooltipFormatter;
    this.parameters.colorAxis = outputData.colorAxis;
    this.parameters.legend.bubbleLegend.enabled = outputData.withSizeAxis;
    this.parameters.legend.bubbleLegend.labels.formatter = outputData.bubbleLabelFormatter;
    this.parameters.legend.bubbleLegend.ranges = outputData.bubbleLegendsRanges;
  };

  protected formatData = ( data: BubbleDataInterface[] ) => {
    const outputData = BubleChartNoneMethod( data, this.axis, this.routines, this.lang );
    return outputData;
  };

  public buildGraphOptions = ( options: any ) => {
    const general = options.bubbleData ? options.general : options;
    const routinesMapping: Map<string, any> = new Map();

    this.routines.routines.forEach( r => {
      routinesMapping.set( r.name, r );
    } );

    this.options = new BubbleOptions( {
      general: {
        title: general.title,
        subtitle: general.subtitle
      },
      formulas: options.bubbleData ? options.data : [],
      routines: this.routines.routines,
      series: options.bubbleData ? options.data.map( f => {
        return {
          name: f.name,
          value: routinesMapping.has( f.name ) ? routinesMapping.get( f.name ).color : '#000000',
        };
      } ) : [],
    }, this.onOptionsChange );
  };


  private onOptionsChange = ( options: HelperPayloadInterface ) => {
    let colorChanged = false;
    const routineMappings: Map<string, any> = new Map();

    const annotationsMapping: Map<string, Highcharts.Annotation> = new Map();
    this.chart[ 'annotations' ].forEach( ( a: Highcharts.Annotation ) => {
      annotationsMapping.set( a.userOptions.id as string, a );
    } );

    if( this.routines.routines ) {
      this.routines.routines.forEach( r => {
        routineMappings.set( r.name, r );
      } );
    }

    options.specifics.series.forEach( s => {
      if( routineMappings.has( s.name ) ) {
        colorChanged = true;
        routineMappings.get( s.name ).color = s.value;

        const currentAnnotation = annotationsMapping.get( s.name );
        if( currentAnnotation ) {
          const annotationOptions: Annotation[ "userOptions" ] = {
            labelOptions: {
              borderColor: s.value
            }
          };
          currentAnnotation[ 'update' ]( annotationOptions );
        }
      }
    } );

    if( colorChanged ) this.custom.updateRoutines( this.routines.routines.map( r => r ) );

    this.chart.update( {
      title: {
        text: options.general.title.text
      },
      subtitle: {
        text: options.general.subtitle.text
      }
    } );
  };

  protected setParamsToAxis = ( params: any, axis: any ) => Object.assign( axis, params );

  private createTableDataSqueleton = ( axis: BubbleAxisInterface ): squeletonInterface[] => {
    const squeleton: squeletonInterface[] = [];
    for( let [ key, value ] of Object.entries( axis ) ) {
      if( Array.isArray( value ) ) {
      } else {
        const newItem = {
          attribute: {
            label: value.name
          },
          axis: key,
          values: []
        };
        squeleton.push( newItem );
      }
    }
    return squeleton;
  };

  private addDataToTableSqueleton = ( dataMapping: string[], data: BubbleDataInterface[], squeleton: squeletonInterface[] ): void => {
    squeleton.forEach( s => {
      dataMapping.forEach( ( d, dIndex ) => {
        const currentData = data[ dIndex ].data[ s.axis ];

        s.values.push( {
          label: d,
          value: {
            key: currentData,
            label: currentData,
          }
        } );

      } );
    } );
  };

  protected formatRawData = ( data: InputDataBubbleInterface, lang: string ): TransformedHTMLDataInterface => {

    const squeleton = this.createTableDataSqueleton( data.data.axis );
    if( !data.data.data ) return {header: [], body: []};
    const dataArray = data.data.data.map( d => d.name );

    this.addDataToTableSqueleton( dataArray, data.data.data, squeleton );
    const payload = {
      data: squeleton,
      pDescriptors: [],
      routines: {}
    };
    const tableTemp = new Table( {}, payload, lang );
    return tableTemp.parameters.transformedHTMLData;
  };

}