
import {of as observableOf,
  Observable,
  of
} from 'rxjs';
import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';

import * as _ from 'lodash';
import {
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import {
  Descriptor,
  Graph,
  GraphType,
  States,
  Workflow
} from '../../../types';
import {
  DNATranslateService
} from '../../../shared/services/translate.service';
import {
  ErrorManagerService
} from '../../../shared/services/errorManager.service';
import {
  ReferenceTypeService
} from '../../../shared/services/reference-type.service';
import {
  UtilService
} from '../../../shared/services/util.service';
import {
  WorkflowService
} from '../../workflows.service';
import { catchError, tap } from 'rxjs/operators';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-powerbi-workflow-page',
  templateUrl: './powerBIWorkflow.component.html',
  styleUrls: ['./powerBIWorkflow.component.less']
})
export class PowerBIWorkflowComponent implements OnInit, AfterViewInit {

  currentLanguage: string;
  graphs: Graph[];
  originalsGraphs: Graph[];
  typeGraphs: GraphType[] = [];
  workflow: Workflow;
  workflowStates: typeof States = States;
  state: States;
  descriptorsInWorkflow: Descriptor[] = [];
  isWorkflowChanged: boolean = false;
  inCampaign: boolean = false;
  showSpinner: boolean = false;
  initTime = performance.now();

  modalOption: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: "lg"
  };

  constructor(
    private DNATranslate: DNATranslateService,
    private errorManager: ErrorManagerService,
    private referenceTypeService: ReferenceTypeService,
    private aiService: ApplicationInsightsService,
    private route: ActivatedRoute,
    private router: Router,
    private utilService: UtilService,
    private workflowService: WorkflowService
  ) { }

  ngOnInit() {
    this.workflow = this.workflowService.workflow;
    this.state = this.workflow.state;
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.typeGraphs = this.getGraphType();
    this.graphs = this.initGraphs(this.workflow.graphs);
    this.originalsGraphs = _.cloneDeep(this.graphs);

    this.workflowService.getWorkflowComponents(this.workflow.id).pipe(
      tap((result) => this.workflow = this.initComponents(this.workflow, result.list)),
      tap(() => this.descriptorsInWorkflow = this.buildDescriptorsInWorkflow(this.workflow)),
    ).subscribe();
    this.DNATranslate.onLangChange().subscribe((translation: any) => {
      this.currentLanguage = translation.lang;
    });
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Library Edit Graphs', '', performance.now() - this.initTime, templateUrl);
  }

  getGraphType = (): GraphType[] => {
    return this.referenceTypeService.getGraphs();
  }

  onWkChanged(graphs: Graph[]) {
    this.isWorkflowChanged = true;
    this.graphs = graphs;
  }

  initGraphs(graphs: Graph[] = []) {
    return graphs.map(
      (graph: Graph) => {
        if (graph.descriptors && graph.descriptors.length > 0) {
          graph.descriptors = graph.descriptors.map(
            (descriptor: Descriptor) => {
              if (descriptor.isActive === undefined) {
                descriptor.isActive = true;
              }
              return descriptor;
            }
          );
        }
        return graph;
      }
    );
  }

  goBack() {
    this.router.navigate(['workflows', this.workflow.id]);
  }

  onCancel() {
    this.graphs = _.cloneDeep(this.originalsGraphs);
    this.isWorkflowChanged = false;
  }

  save() {
    const finalGraphs = this.utilService.indexingGraphs(this.graphs);
    this.workflowService.updateGraphsInWorkflow(finalGraphs);
    this.router.navigate(['workflows', this.workflow.id]);
  }

  private buildDescriptorsInWorkflow(workflow: Workflow) {
    let descriptors: Descriptor[] = [];
    workflow.blocks.map(b => b.components
      .filter(c => c.type === "dna-input-text" || c.type === "dna-multiple-choice" || c.type === "dna-counter-side" || c.type === "dna-confort")
      .filter(c => !c.args.inactive)
      .map(c => descriptors.push(this.utilService.createDescriptor('', '', workflow.id, workflow.name, b.id, b.name, _.get(b, 'suffix', ''), b.idInQuestionnaire, c))
      )
    )
    return descriptors;
  }

  initComponents(workflow, result) {
    let wk = {...workflow};
    wk.blocks.forEach(b => {
      b.components.forEach((c, index) => {
        const component = result.find(fullComponents => fullComponents.id === c);
        if (component) {
          b.components[index] = {...component}
        }

      })
    })
    return wk;
  }
}
