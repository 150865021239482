
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Workflow } from '../../../../../types';
import { WorkflowService } from '../../../../../workflows/workflows.service';

@Component({
  selector: 'dna-workflow-export',
  templateUrl: './workflow-export.component.html',
  styleUrls: ['./workflow-export.component.css']
})
export class WorkflowExportComponent implements OnInit {
  exportForm: FormGroup;
  isDisplayed: boolean = false;
  originalWorkflows: Workflow[] = [];
  showSpinner: boolean = true;
  workflows: Workflow[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private workflowService: WorkflowService
  ) {
    this.exportForm = this.formBuilder.group({
      workflowToExport: ['', Validators.required],
      workflowJSON: ''
    });
  }

  ngOnInit() {
    this.workflowService.getWorkflows().pipe(
      finalize(() => this.showSpinner = false))
      .subscribe((workflows) => {
        this.workflows = _.cloneDeep(workflows);
        this.originalWorkflows = _.cloneDeep(workflows);
        this.workflows.map((workflow) => workflow.name = _.cloneDeep(workflow.name.english));
      });
  }

  cancel() {
    this.isDisplayed = false;
    this.exportForm.reset();
  }

  onSubmit(form: FormGroup) {
    this.showSpinner = true;
    this.isDisplayed = true;

    this.workflowService.getWorkflow(form.get('workflowToExport').value[0].id).pipe(
      finalize(() => this.showSpinner = false))
      .subscribe((workflow) => {
        workflow = _.omitBy(workflow, (value, key) => key[0] === '_');
        this.exportForm.patchValue({
          workflowJSON: JSON.stringify(workflow, null, '\t')
        });
      });
  }

}
