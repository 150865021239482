import {
  Component,
  Input,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';
import {
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import {
  AttributeData, HubsById, ArcsPanelist, Metier
} from '../../../../../types';
import {
  ErrorManagerService
} from '../../../../../shared/services/errorManager.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { of, zip } from 'rxjs';
import { UtilService } from '../../../../../shared/services/util.service';

@Component({
  selector: 'dna-add-characterization-by-hub',
  templateUrl: './add-caracterization-by-hub.component.html'
})
export class AddCharacterizationByHubComponent implements OnInit {

  @Input() characterizations: { [hub: string]: AttributeData[] } = {};
  @Input() metier: Metier;

  listCharacterizations: { [hub: string]: AttributeData[] } = {};
  spinner: boolean = false;
  allChecked: { [hub: string]: boolean } = {};

  constructor(
    private activeModal: NgbActiveModal,
    private errorManager: ErrorManagerService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.getAttributeDataFromArcs();
  }

  initAttributeDataFromArcs() {
    this.characterizations = {};
    this.getAttributeDataFromArcs();
  }

  getAttributeDataFromArcs() {
    this.spinner = true;
    let caracByHub = [];
    let hubs = Object.values(HubsById);
    //let hubs =  [HubsById.France, HubsById.US, HubsById.Brazil, HubsById.Japan];
    hubs.forEach(hub => {
      let carac$ = this.utilService.getAttributesDataByHub(hub).pipe(
        catchError(err => {
          this.errorManager.displayMessage('UNKNOW_ERROR', 'danger');
          return of([]);
        }),
        tap((c: ArcsPanelist) => this.initCharacterizations(c,  hub)),
      )
      caracByHub.push(carac$)
    })
    zip(...caracByHub).pipe(
      tap(() => this.initGraphCharacterizations(this.characterizations, this.listCharacterizations)),
      finalize(() => this.spinner = false)
    ).subscribe();
  }

  

  initCharacterizations(c: ArcsPanelist, hub) {
    if (c) {
      this.listCharacterizations[hub] = this.utilService.getAttributesData(c, this.metier);
    }
  }

  initGraphCharacterizations(characterizations: { [hub: string]: AttributeData[] }, listCharacterizations: { [hub: string]: AttributeData[] }) {
    Object.keys(listCharacterizations).forEach(hub => {
      _.get(listCharacterizations, hub, []).forEach(lc => {
        if (!_.get(characterizations, hub, []).find(c => c.AttributeID === lc.AttributeID)) {
          let charac = _.cloneDeep(lc);
          charac['isSelected'] = false;
          if (characterizations[hub]) {
            characterizations[hub].push(charac);
          } else {
            characterizations[hub] = [charac]
          }
        }
      });
      _.sortBy(characterizations[hub], ['AttributeName']);
      this.allChecked[hub] = characterizations[hub].every(c => c.isSelected);
    })
  }

  cancel() {
    this.activeModal.dismiss();
  }

  submit() {
    this.activeModal.close(this.characterizations);
  }

  getLengthCharac(hub) {
    let result = []
    let length = this.characterizations[hub].length % 2 === 0 ? this.characterizations[hub].length / 2 : (this.characterizations[hub].length + 1) / 2
    for (let i = 0; i < length; i++) {
      result.push(i)
    }
    return result;
  }

  checkAll(hub) {
    this.characterizations[hub].map(c => c.isSelected = true);
  }

  unCheckAll(hub) {
    this.characterizations[hub].map(c => c.isSelected = false)
  }

  getHubLoop() {
    return Object.keys(this.characterizations);
  }

  getHub(hub) {
    return this.utilService.getHubFromArcs(hub);
  }

}
