<div class="btn-group mb-4" role="group">
  <button class="btn"
    [ngClass]="{'btn-secondary': selectedTab !== onePagerTabs.header, 'btn-primary': selectedTab === onePagerTabs.header }"
    [routerLink]="link.concat(['head'])">{{ 'HEADER' | translate }}</button>
  <button class="btn"
    [ngClass]="{'btn-secondary': selectedTab !== onePagerTabs.context, 'btn-primary': selectedTab === onePagerTabs.context }"
    [routerLink]="link.concat(['context'])">{{ 'CONTEXT' | translate }}</button>
  <button class="btn"
    [ngClass]="{'btn-secondary': selectedTab !== onePagerTabs.targetProtocol, 'btn-primary': selectedTab === onePagerTabs.targetProtocol }"
    [routerLink]="link.concat(['target'])">{{ 'TARGETS_PROTOCOL' | translate }}</button>
  <button class="btn"
    [ngClass]="{'btn-secondary': selectedTab !== onePagerTabs.images, 'btn-primary': selectedTab === onePagerTabs.images }"
    [routerLink]="link.concat(['image'])">{{ 'IMAGES' | translate }}</button>
  <button class="btn"
    [ngClass]="{'btn-secondary': selectedTab !== onePagerTabs.conclusions, 'btn-primary': selectedTab === onePagerTabs.conclusions }"
    [routerLink]="link.concat(['conclusion'])">{{ 'CONCLUSIONS' | translate }}</button>
  <button class="btn"
    [ngClass]="{'btn-secondary': selectedTab !== onePagerTabs.preview, 'btn-primary': selectedTab === onePagerTabs.preview }"
    [routerLink]="link.concat(['preview'])">{{ 'PREVIEW' | translate }}</button>
</div>

<router-outlet (activate)="onActivate($event)"></router-outlet>
