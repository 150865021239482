import {Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbTimepicker, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

@Component({
    selector: 'dna-timepicker',
    templateUrl: './dna-timepicker.component.html'})

export class dnaTimepickerComponent {
    @Input() time: NgbTimeStruct;
    @Input() name: string;
    @Output() onChangeTime: EventEmitter<NgbTimeStruct> = new EventEmitter();
    @ViewChild(NgbTimepicker) picker: NgbTimepicker;

    constructor() { }

    onEvent(newTime) {
        this.replaceNaNPerZero(this.picker.model);
        this.onChangeTime.emit(this.picker.model);
    }

    replaceNaNPerZero(data: NgbTimeStruct) {
        this.replacePropZero(data, 'hour');
        this.replacePropZero(data, 'minute');
        this.replacePropZero(data, 'second');
    }

    replacePropZero(data: NgbTimeStruct, prop: string) {
        if(_.isNaN(data[prop]) || _.isNil(data[prop])) {
            data[prop] = 0;
        }
    }
}
