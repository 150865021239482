import { Directive, HostListener } from '@angular/core'

@Directive({
  selector: '[scrollToTop]'
})
export class ScrollToTopDirective {
    constructor() {
        // When the user scrolls down 200px from the top of the document, show the button
       window.onscroll = () => this.displayScrollButtonAndReduceBanner();
    }

    @HostListener('click')
    clickEvent() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    /********** Scroll button management part **********/
    displayScrollButtonAndReduceBanner() { 
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            if(document.getElementById("btn-go-top")) document.getElementById("btn-go-top").style.display = "block";
            if(document.getElementById("header-inner")) document.getElementById("header-inner").style.height = "70px";
            if(document.getElementById("banner-title")) document.getElementById("banner-title").style.display ="none";
            if(document.getElementById("banner-nav")) document.getElementById("banner-nav").style.top = "75px";
            if(document.getElementById("banner-actions")) document.getElementById("banner-actions").style.top = "65px";
            
        } else {
            if(document.getElementById("btn-go-top")) document.getElementById("btn-go-top").style.display = "none";
            if(document.getElementById("header-inner")) document.getElementById("header-inner").style.height = "163px";
            if(document.getElementById("banner-title")) document.getElementById("banner-title").style.display = "block" ;
            if(document.getElementById("banner-nav")) document.getElementById("banner-nav").style.top = "150px";
            if(document.getElementById("banner-actions")) document.getElementById("banner-actions").style.top = "100px";
        }
        
    }

    

}