<div class="group_checkbox">
  <div class="group_checkbox-header" (click)="onToggle()">
    {{ label }}
  </div>
  <div class="group_checkbox-items_container">
    <div class="group_checkbox-items" [class.group_checkbox-items--open]="open">
      <div class="group_checkbox-items-search">
        <input type="text" class="group_checkbox-items-search-input" placeholder="search" [(ngModel)]="filter" />
      </div>
      <div class="group_checkbox-items-options">
        <label 
        *ngFor="let item of getItems()" 
        class="group_checkbox-items-label"
        [class.group_checkbox-items-label--activated]="item.form.value">
          {{ item.name }}
          <input type="checkbox" [formControl]="item.form" />
        </label>
      </div>
    </div>
  </div>
</div>
