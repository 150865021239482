<div class="container">
  <ngx-loading [show]="showSpinner">
  </ngx-loading>
  <div  *ngIf="!showSpinner && !error" class="ibox float-e-margins">
    <form #userForm="ngForm" name="campaignForm" novalidate>
      <div class="ibox-title">
        <h5 class="text-center" translate>USERS</h5>
        <div *ngIf="campaign.state !== campaignStates.Finished && campaign.state !== campaignStates.Abandoned && isChangedCampaign()" class="d-flex flex-row gap-1">
          <button id="save" class="btn btn-primary" type="button" (click)="save(userForm)" translate>SAVE</button>
          <button id="cancel" class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
        </div>
      </div>
      <div class="ibox-content d-flex flex-column gap-3">
        <div class="form-group">
          <div class="row">
            <label class="col-3 required form-control-label" translate>CAMPAIGN_CONTRIBUTORS  <i class="fa fa-info-circle info-icon"
              aria-hidden="true" 
              ngbTooltip="{{ 'ACTIVIEW_TOOLTIP' | translate }}"
              tooltipClass="tooltip-custom"
              placement="top"></i></label>
            <div class="col-7">
              <tag-input inputClass="tagInputCampaignWf" [disable]="campaign.state === campaignStates.Finished || campaign.state === campaignStates.Abandoned" [(ngModel)]="campaign.users.accountables"
                displayBy="name" identifyBy="key" name="managerToAdd" [onlyFromAutocomplete]="true" (onAdd)="onUserAdded($event)"
                (onRemove)="canIRemoveThisElement($event, 'users.accountables','key')" placeholder="+ {{ 'USER' | translate }}"
                secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: {'object': 'USER' | translate | lowercase} }}">
                <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="users" displayBy="name" identifyBy="key" [minimumTextLength]="3"
                  [showDropdownIfEmpty]="false">
                </tag-input-dropdown>
              </tag-input>
              <div *ngIf="submitted && campaign.users.accountables.length == 0" class="error" translate>REQUIRED</div>
            </div>
            <div class="col-sm-2">
              <div class="add-user">
                <label class="btn label-add-user" for="file-input">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </label>
                <input id="file-input" type="file" (change)="onFileChange($event)" multiple="false" />
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="currentWorkspace.preferences.users.collaborative.isActive" class="form-group paddingTop">
          <div class="row">
            <label class="col-3 form-control-label" translate>COLLABORATIVE_MODE</label>
            <div class="col-9">
              <dna-switch [disabled]="isDisabled" [isSelected]="campaign.users.isCollaborative" (onBooleanChanged)="campaign.users.isCollaborative = !campaign.users.isCollaborative"></dna-switch>
            </div>
            <label class="offset-3 form-control-label" translate>ALLOWS_COLLABORATIVE</label>
          </div>
        </div>
        <div *ngIf="currentWorkspace.preferences.users.volunteers.isActive" class="form-group paddingTop">
          <div class="row">
            <label class="col-3 form-control-label" translate>VOLUNTEERS_MODE</label>
            <div class="col-9">
              <dna-switch [disabled]="isDisabled" [isSelected]="campaign.users.isVolunteer" (onBooleanChanged)="updateCollaborative($event)"></dna-switch>
            </div>
            <label class="offset-3 form-control-label" translate>ALLOWS_VOLUNTARY</label>
          </div>
        </div>
        <div *ngIf="currentWorkspace.preferences?.displayNonArcsVolunteers && campaign.users.isVolunteer" class="form-group paddingTop">
          <div class="row">
            <label class="col-3 form-control-label" translate>HORSARCS_VOLUNTEERS_MODE</label>
            <div class="col-9">
              <dna-switch [disabled]="isDisabled" [isSelected]="campaign.users.isHorsArcsVolunteer" (onBooleanChanged)="toggleHorsArcs($event)"></dna-switch>
            </div>
            <label class="offset-3 form-control-label" translate>ALLOWS_HORSARCS_PANELISTS</label>
          </div>
        </div>
        <ng-container *ngIf="campaign.users.isVolunteer">
          <div class="form-group paddingTop">
            <div class="row">
              <label class="col-3 form-control-label" translate>VOLUNTEERS</label>
              <div class="col-9">
                <tag-input [animationDuration]="{enter: '0ms', leave: '0ms'}" inputClass="tagInputCampaignWf" [removable]="!(campaign.state === campaignStates.Finished || campaign.state === campaignStates.Abandoned || campaign.state === campaignStates.Published || campaign.state === campaignStates.Archived)" [(ngModel)]="campaign.users.volunteers" displayBy="name"
                  identifyBy="name" name="volunteersToAdd"
                  placeholder="+ {{ 'VOLUNTEER' | translate }}" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC_ENTER_PROMPT' | translate: {'object': 'VOLUNTEER' | translate | lowercase} }}">
                </tag-input>
              </div>
            </div>
          </div>
          <div class="form-group paddingTop">
            <div class="row">
              <label class="col-3 form-control-label required" translate>VOLUNTEERS_EVALUATED  <i class="fa fa-info-circle info-icon"
                aria-hidden="true" 
                ngbTooltip="{{ 'ACTIVIEW_TOOLTIP' | translate }}"
                tooltipClass="tooltip-custom"
                placement="top"></i></label>
              <div class="col-9">
                <input class="form-control" [disabled]="campaign.state === campaignStates.Finished || campaign.state === campaignStates.Abandoned" [(ngModel)]="campaign.users.volunteersNumber" name="volunteersNumber"
                  type="number" min="1">
                <div *ngIf="submitted && campaign.users.volunteersNumber === 0" class="error" translate>
                  ERROR_NUMBERS_VOLUNTEERS_REQUIRED
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div *ngIf="campaign.actiview && campaign.actiview.volunteersNumber !== ''" class="form-group">
          <div class="row">
            <div class="offset-3 col-9 form-control-label center" translate>
              {{ "MAX_VOLUNTEERS" | translate }} : {{ campaign.actiview.volunteersNumber }}
            </div>
          </div>
        </div>
        <div *ngIf="campaign.users.isVolunteer && campaign.arcs && campaign.arcs.length > 0" class="form-group">
          <div class="row">
            <div class="offset-3 col-9 form-control-label" translate>
              <table class="table table-hover table-borderless-th dataTable">
                <thead>
                  <tr>
                    <td translate>LABEL_ARCS</td>
                    <td> </td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let arcs of campaign.arcs">
                    <td> {{ arcs.arcsStudy }} </td>
                    <td>
                      <button [disabled]="campaign.state === campaignStates.Finished || campaign.state === campaignStates.Abandoned" class="btn btn-primary btn-sm" type="button" (click)="updateVolunteers(arcs)"
                        translate>REFRESH_VOLUNTEERS</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="currentWorkspace.preferences?.displayNonArcsVolunteers && campaign.users.isVolunteer && horsArcs && horsArcs.length > 0" class="form-group">
          <div class="row">
            <div class="offset-3 col-9 form-control-label" translate>
              <table class="table table-hover table-borderless-th dataTable">
                <thead>
                  <tr>
                    <td translate>LABEL_HORSARCS</td>
                    <td>  </td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let arcs of horsArcs">
                    <td> {{ arcs.groupName }} </td>
                    <td><button [disabled]="campaign.state === campaignStates.Finished || campaign.state === campaignStates.Abandoned" class="btn btn-primary btn-sm" type="button" (click)="updateVolunteersHorsArcs(arcs)"
                      translate>UPDATE_GROUP</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="campaignOriginal !== campaignStates.Draft && campaignOriginal !== campaignStates.Suspended && !campaign.users.isVolunteer" class="form-group paddingTop">
          <div class="row">
            <label class="col-3 form-control-label" translate>DROP_MODE</label>
            <div class="col-9">
              <dna-switch [isSelected]="enableDropMode" (onBooleanChanged)="enableDropMode = !enableDropMode"></dna-switch>
            </div>
          </div>
        </div>
        <div *ngIf="enableDropMode" class="form-group">
          <div class="row">
            <dna-table class="col-12" [data]="campaign.users.accountables" [headers]="userTableHeaders | async" orderedBy="USERS" [reverse]="false"
              [sortFunction]="sort" [title]="'USERS'">
              <ng-template #row let-data="data">
                <tr class="cursor-pointer" dna-drop-user-row [accountable]="data"></tr>
              </ng-template>
            </dna-table>
          </div>
        </div>
        <div *ngIf="campaignOriginal.state && campaignOriginal.state == 'PUBLISHED' && campaignOriginal.users.isVolunteer" class="form-group paddingTop">
          <div class="row">
            <label class="col-3 form-control-label" translate>DROP_MODE_VOLUNTEER</label>
            <div class="col-9">
              <dna-switch [isSelected]="enableDropModeVolunteer" (onBooleanChanged)="enableDropModeVolunteer = !enableDropModeVolunteer"></dna-switch>
            </div>
          </div>
        </div>
        <div *ngIf="enableDropModeVolunteer" class="form-group">
          <div class="row">
            <dna-table class="col-12" [data]="campaign.users.volunteers" [headers]="volunteerTableHeaders | async" orderedBy="VOLUNTEERS" [reverse]="false"
              [sortFunction]="sort" [title]="'VOLUNTEERS'">
              <ng-template #row let-data="data">
                <tr class="cursor-pointer" dna-drop-user-row [volunteer]="data" [evaluations]="campaign.evaluations" ></tr>
              </ng-template>
            </dna-table>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="!showSpinner && error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "ERROR" | translate }}</h5>
        </div>
      </div>
    </div>
    <dna-error-page (onClickReload)="init()"></dna-error-page>
  </div>
</div>
