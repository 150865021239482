<ngx-loading [show]="showSpinner"></ngx-loading>
<div *ngIf="!showSpinner" class="ibox float-e-margins">
  <div class="ibox-title">
    <div class="row">
      <div class="col-12 col-md-4 my-auto">
        <h5>{{ "USERS" | translate }}</h5>
      </div>
    </div>
  </div>
  <div class="ibox-content">
    <div *ngIf="usersOk && campaignsMultiUsers.length > 0">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>{{ "CAMPAIGNS" | translate }}</th>
            <th>{{ "WORKFLOWS" | translate }}</th>
            <th>{{ "CONTRIBUTORS" | translate }}</th>
            <th>{{ "VOLUNTEERS" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cmu of campaignsMultiUsers">
            <th>{{ cmu.campaignName }}</th>
            <td>
              <p class="mb-0" *ngFor="let wflows of cmu.campaignWorkflowsNames">{{ getWorkflowName(wflows) }}</p>
            </td>
            <td>
              <p class="mb-0" *ngFor="let accountable of cmu.accountables">
                {{ accountable?.name }}
              </p>
            </td>
            <td>
              <p class="mb-0" *ngFor="let v of cmu.volunteers">
                {{ v?.name }}
                <svg viewBox="-1 -1 2 2" height="16px" width="16px" style="transform: rotate(-90deg);">
                  <path [attr.d]="utilService.pathProgress(v.progress)" fill="#1ab394"></path><!-- vert -->
                  <path [attr.d]="utilService.pathProgress(1, v.progress)" fill="#d7d7d7"></path><!-- gris -->
                  <path *ngIf="duplicateVolunteers[v?.name]?.length > 1" [attr.d]="utilService.pathProgress(1)" fill="#ffa500"></path><!-- orange -->
                </svg>
                <small style="vertical-align: top">{{ v.progress | percent:'1.0-2' }}</small>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-primary" (click)="changeUserActive()">{{'VOLUNTEERS_DISABLED' | translate}}</button>
    </div>
    <dna-error-page *ngIf="!usersOk && !showSpinner" (onClickReload)="init()"></dna-error-page>
  </div>
</div>
