import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanDeactivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';

import { Observable } from 'rxjs';

import { UserService } from './user.service';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild, CanDeactivate<CanComponentDeactivate> {

    constructor(
        private router: Router,
        private userService: UserService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const user = this.userService.getUser();
        const authorized = user && (!route.data || !route.data.role || this.userService.isAuthorized(route.data.role));
        if (!authorized) {
            this.userService.removeUserLocal();
            this.router.navigate(['connection']);
        }

        return authorized;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    canDeactivate(component: CanComponentDeactivate) {
        return component.canDeactivate ? component.canDeactivate() : true;
    }

}
