import { ChartType, CHART_TYPE } from '../../types';
import { Injectable } from '@angular/core';

@Injectable()
export class ChartTypeService {

    constructor() { }

    BAR: ChartType = CHART_TYPE.BAR;
    BAR_TWO_SIDED: ChartType = CHART_TYPE.BAR_TWO_SIDED;
    HISTOGRAM: ChartType = CHART_TYPE.HISTOGRAM;
    HISTOGRAM_MULTI_DIMENSIONS: ChartType = CHART_TYPE.HISTOGRAM_MULTI_DIMENSIONS;
    HISTOGRAM_MULTI_FORMULAS: ChartType = CHART_TYPE.HISTOGRAM_MULTI_FORMULAS;
    HISTOGRAM_MULTI_USERS: ChartType = CHART_TYPE.HISTOGRAM_MULTI_USERS;
    PIE: ChartType = CHART_TYPE.PIE;
    RADAR: ChartType = CHART_TYPE.RADAR;
    TABLE_COMMENTS: ChartType = CHART_TYPE.TABLE_COMMENTS;
    TABLE_CROSSED_USERS_VALUES: ChartType = CHART_TYPE.TABLE_CROSSED_USERS_VALUES;
    TORNADO: ChartType = CHART_TYPE.TORNADO;

    getChartType(): ChartType[] {
        return [
            this.BAR,
            this.TORNADO,
            this.BAR_TWO_SIDED,
            this.HISTOGRAM,
            this.HISTOGRAM_MULTI_DIMENSIONS,
            this.HISTOGRAM_MULTI_FORMULAS,
            this.HISTOGRAM_MULTI_USERS,
            this.PIE,
            this.RADAR,
            this.TABLE_COMMENTS,
            this.TABLE_CROSSED_USERS_VALUES
        ];
    }
}
