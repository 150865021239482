import { DescriptorGroup } from './../../../../../../types';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
  } from '@angular/core';
  import * as _ from 'lodash';
  
  import {
    DragulaService, DragulaOptions
  } from 'ng2-dragula';
  
  import { Graph } from '../../../../../../types';
  import { UtilService } from '../../../../../../shared/services/util.service';
  import { Observable, Subscription } from 'rxjs';
  
  @Component({
    selector: 'dna-descriptor-multi-table',
    templateUrl: './descriptor-multi-table.component.html'
  })
  export class DescriptorMultiTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() graph: Graph;
    @Input() descriptorGroup: DescriptorGroup[];
    @Input() descriptorAdded: Observable<void>;
    @Input() descriptorGroupAdded: Observable<DescriptorGroup[]>;
    
    @Output() onChange: EventEmitter<DescriptorGroup[]> = new EventEmitter();
  
    keyDragula: string;
    subs = new Subscription();
    descriptorsInGraph: DescriptorGroup[] = [];
    eventsSubscription: Subscription;
    groupEventsSubscription: Subscription;
    

    constructor(
      private dragulaService: DragulaService,
      private utilService: UtilService
    ) { 
      this.subs.add(this.dragulaService.drop(this.keyDragula)
        .subscribe(() => this.onChange.emit(this.descriptorsInGraph)))
    }

    ngOnInit(): void {
      this.buildDesciptorGroups(this.graph);
      this.eventsSubscription = this.descriptorAdded.subscribe(() => this.buildDesciptorGroups(this.graph));
      this.groupEventsSubscription = this.descriptorGroupAdded.subscribe((descriptors) => this.initDescriptorGroup(descriptors));
    }
  
    initDescriptorGroup(descriptors){
      this.descriptorsInGraph = descriptors;
    }

    public ngOnChanges(changes: SimpleChanges): void {
      if (_.get(changes, 'graph.firstChange', false)) {
        this.keyDragula = this.utilService.generateRandomID(4);
        const dOptions: DragulaOptions = {
          moves: function (el: Element, container: Element, handle: Element): boolean {
            return handle.className === "reorder" || handle.className === "fa fa-arrows-v";
          }
        }
        this.dragulaService.createGroup(this.keyDragula, dOptions);
      }
    }
  
    public ngOnDestroy(): void {
      this.dragulaService.destroy(this.keyDragula);
      this.subs.unsubscribe();
      this.onChange.unsubscribe();
      this.eventsSubscription.unsubscribe();
    }
  
    private buildDesciptorGroups(graph: Graph) {
      this.descriptorsInGraph = [];
      if(!_.isEmpty(graph.descriptorsGroupIds)) {
        graph.descriptorsGroupIds.forEach(dGroup => {
          const dg = this.descriptorGroup.find(desc => desc.id === dGroup.id);
          if(dg) {
            this.descriptorsInGraph.push(dg);
          } else {
            this.descriptorsInGraph.push(dGroup);
          }
        });
      }
    }
  
    removeDescriptor(index: number) {
      if(this.descriptorsInGraph[index].isGroup) {
        this.descriptorGroup.splice(this.descriptorGroup.indexOf(this.descriptorsInGraph[index]), 1);
      }
      this.descriptorsInGraph.splice(index, 1);
      this.onChange.emit(this.descriptorsInGraph);
    }
  
  }
  