
import {takeUntil, finalize, tap, catchError, flatMap} from 'rxjs/operators';
import {
  ActivatedRoute,
  ParamMap,
  Router
} from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';
import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, throwError, of } from 'rxjs';

import {
  Campaign,
  CampaignStates,
  ViewGroups,
  ViewType,
  ViewTypes,
  Workflow,
  Workspace,
  CampaignDisplayMode,
  CampaignVisit,
} from '../../../../types';
import {
  CampaignService
} from '../../../campaigns.service';
import {
  ConfigureCustomizeModalComponent
} from '../parameters/configure-customize/configure-customize-modal.component';
import {
  ErrorManagerService
} from '../../../../shared/services/errorManager.service';
import {
  UserService
} from '../../../../shared/services/user.service';
import {
  UtilService
} from '../../../../shared/services/util.service';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-display_mode',
  templateUrl: 'display-mode.component.html',
  styleUrls: ['./display-mode.component.less']
})
export class CampaignDisplayModeComponent implements OnInit, OnDestroy, AfterViewInit {

  _campaignOriginal: CampaignDisplayMode;
  campaign: CampaignDisplayMode;
  currentWorkspace: Workspace = new Workspace();
  description: string;
  isDisabled: boolean;
  showSpinner: boolean = false;
  viewGroups: typeof ViewGroups = ViewGroups;
  viewTypes: typeof ViewTypes = ViewTypes;
  error: boolean = false;
  initTime = performance.now();

  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private campaignService: CampaignService,
    private errorManager: ErrorManagerService,
    private aiService: ApplicationInsightsService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Display Mode', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.error = false;
    this.showSpinner = true;
    this.currentWorkspace = this.userService.getUser().currentWorkspace;
    this.route.parent.paramMap.pipe(
      flatMap((params: ParamMap) => this.campaignService.getCampaignDisplayMode(params.get('idCampaign'))),
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      tap((campaign: CampaignDisplayMode) => this.campaign = campaign),
      tap((campaign: CampaignDisplayMode) => this.description = this.updateDescription(campaign.parameters.viewType)),
      tap((campaign: CampaignDisplayMode) => this.isDisabled = this.utilService.isNotEditable(campaign.state)),
      tap((campaign: CampaignDisplayMode) => this.campaignService.updateBreadCrumb(campaign.name)),
      tap((campaign: CampaignDisplayMode) => this._campaignOriginal = _.cloneDeep(campaign)),
      tap(() => this.showSpinner = false),
      takeUntil(this.destroy$),)
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.utilService.canDeactivate(this.campaign, this._campaignOriginal);
  }

  changeViewGroup(view: ViewGroups) {
    this.campaign.parameters.viewGroup = view;
  }

  clickTypeview(mode: ViewType, description: string) {
    if (!_.has(this.campaign, 'parameters.viewGroup')) {
      this.campaign.parameters.viewGroup = ViewGroups.FormulaGroup;
    }
    this.campaign.parameters.viewType = mode;
    this.description = description;
    this.campaign.workflows = _.cloneDeep(this._campaignOriginal.workflows);
  }

  configureCustomizeMode() {
    if(!_.isEmpty(_.get(this.campaign, 'visits'))) {
      if(_.get(this.campaign, 'workflows', []).length > 1) {
        this.errorManager.displayMessage("ERROR_CUSTMIZED_MODE_MULTIPLE_WORKFLOWS", 'danger', { timeOut: 10000 }, false);
        this.campaign.parameters.viewType = ViewTypes.ModeCard;
      } else {
        this.campaign.visits = this.campaign.visits.map((visit: CampaignVisit) => {
          this.utilService.addDefaultPagesInVisit(visit, false);
          visit.blocks = visit.blocks.filter(b => b.isActive);
          return visit;
        });
        const modal = this.modalService.open(ConfigureCustomizeModalComponent, this.modalOption);
        modal.componentInstance.campaign = this.campaign;
        modal.result.then(
          (campaign: CampaignDisplayMode) => this.campaign = campaign,
          (reason) => { }
        );
      }
    } else {
      this.errorManager.displayMessage("ERROR_ON_OPEN_CUSTMIZED_MODE", 'danger', { timeOut: 10000 }, false);
      this.campaign.parameters.viewType = ViewTypes.ModeCard;
    }
  }


  goTo(campaign: Campaign) {
    if (_.has(campaign, 'metier.id')) {
      this.router.navigate(['campaigns', campaign.id, 'edit', 'target']);
    } else {
      this.router.navigate(['campaigns', campaign.id, 'edit', 'users']);
    }
  }

  isChangedCampaign() {
    return this.utilService.isDifferent(this._campaignOriginal, this.campaign);
  }

  onCancel() {
    this.campaign = _.cloneDeep(this._campaignOriginal);
  }

  onChangeData(value: any, object: any, path: string) {
    object[path] = value;
  }

  save() {
    this.showSpinner = true;

    if (this.campaign.parameters.viewType === ViewTypes.ModeCustomizeCard) {
      this.campaign.visits = this.campaign.visits.map((visit: CampaignVisit) => this.utilService.addDefaultPagesInVisit(visit, false));
    }

    if (this.campaign.parameters.viewType !== ViewTypes.ModeList) {
      delete this.campaign.parameters.viewGroup;
    }

    this.campaignService.putCampaignDisplayMode(this.campaign.id, this.campaign.parameters, this.campaign.visits).pipe(
      tap(() => this.errorManager.displayMessage('ON_SUCCESS_UPDATE')),
      tap((campaign: Campaign) => this.campaign = _.cloneDeep(campaign)),
      tap((campaign: Campaign) => this._campaignOriginal = _.cloneDeep(campaign)),
      takeUntil(this.destroy$),
      finalize(() => this.showSpinner = false),)
      .subscribe(
      (campaign: Campaign) => this.goTo(campaign),
      err => this.errorManager.catchError(err)
      );
  }

  updateDescription(viewType: ViewTypes): string {
    switch (viewType) {
      case ViewTypes.ModeList:
        return 'LABORATOIRE_DESCRIPTION';
      case ViewTypes.ModeCard:
        return 'CARD_DESCRIPTION';
      case ViewTypes.ModeDualCard:
        return 'DUAL_DESCRIPTION';
      default:
        return 'CUSTOMIZE_DESCRIPTION';
    }
  }

}
