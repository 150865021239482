import {
  Component,
  Input,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';
import {
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import {
  AttributeData, HubById, AttributeDataGraph, HubsById, ArcsPanelist, Metier
} from '../../../../../../types';
import {
  ErrorManagerService
} from '../../../../../../shared/services/errorManager.service';
import { CampaignService } from '../../../../../../campaigns/campaigns.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { UtilService } from '../../../../../../shared/services/util.service';

@Component({
  selector: 'dna-add-characterization',
  templateUrl: './add-characterization.component.html'
})
export class AddCharacterizationComponent implements OnInit {

  @Input() characterizations: AttributeDataGraph[] = [];
  @Input() hub: HubById;
  @Input() setDefaultValues : boolean = true;
  @Input() metier: Metier;

  listCharacterizations: AttributeData[] = [];
  spinner: boolean = false;
  allChecked: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private errorManager: ErrorManagerService,
    private campaignService: CampaignService,
    private utilService: UtilService

  ) { }

  ngOnInit() {
    this.listCharacterizations = this.campaignService.getCharacterizations()
    if (_.isEmpty(this.listCharacterizations)) {
      this.getAttributeDataFromArcs();
    } else {
      this.initGraphCharacterizations(this.characterizations, this.listCharacterizations);
      this.spinner = false;
    }

  }

  getAttributeDataFromArcs() {
    this.spinner = true;
    this.utilService.getAttributesDataByHub(this.hub).pipe(
      catchError(err => {
        this.spinner = false;
        this.errorManager.displayMessage('UNKNOW_ERROR', 'danger');
        return of();
      }),
      tap((c: ArcsPanelist) => this.initCharacterizations(c)),
      tap(() => this.initGraphCharacterizations(this.characterizations, this.listCharacterizations)),
      finalize(() => this.spinner = false)
    ).subscribe();
  }

  initCharacterizations(c: ArcsPanelist) {
    const attrData = this.utilService.getAttributesData(c, this.metier);
    this.campaignService.setCharacterizations(attrData)
    this.listCharacterizations = attrData;
  }

  initGraphCharacterizations(characterizations: AttributeData[], listCharacterizations: AttributeData[]) {
    listCharacterizations.forEach(lc => {
      if (!characterizations.find(c => c.AttributeID === lc.AttributeID)) {
        let charac = _.cloneDeep(lc);
        charac['isSelected'] = false;
        characterizations.push(charac);
      }
    })
    if (this.setDefaultValues) this.addDefaultCharacByHub(characterizations);
    _.sortBy(characterizations, ['AttributeName']);
    this.allChecked = characterizations.every(c => c.isSelected);
  }

  addDefaultCharacByHub (characterizations: AttributeData[]) {
    if (characterizations.every(c => !c.isSelected)) {
      switch (this.hub) {
        case HubsById.France: {
          this.addDefaultCharac([765, 1101, 1102, 766, 768, 776, 774], characterizations)
          break;
        }
        case HubsById.US: {
          this.addDefaultCharac([372, 1026, 1025, 364, 366] , characterizations)
          break;
        }
        default: {
          this.addDefaultCharac([765, 1101, 1102, 766, 768, 776, 774], characterizations)
          break;
        }
      }
    }
  }

  addDefaultCharac(characterizationsId: number[], characterizations: AttributeData[]) {
    characterizationsId.forEach(id => {
      let characterization = characterizations.find(c => c.AttributeID === id);
      if (characterization) {
        characterization.isSelected = true;
      }
    })
  }

  cancel() {
    this.activeModal.dismiss();
  }

  submit() {
    if (this.setDefaultValues) this.addDefaultCharacByHub(this.characterizations);
    this.activeModal.close(this.characterizations);
  }

  getLengthCharac() {
    let result = []
    let length = this.characterizations.length % 2 === 0 ? this.characterizations.length / 2 : (this.characterizations.length + 1) / 2
    for (let i = 0; i < length; i++) {
      result.push(i)
    }
    return result;
  }

  checkAll() {
    this.characterizations.map(c => c.isSelected = true);
  }

  unCheckAll() {
    this.characterizations.map(c => c.isSelected = false)
  }

}
