<div class="row">
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>LENGTH</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.length" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'length')" (onRemove)="removeItem(filter, $event, 'length')" name="tag-input-length"
      placeholder="+ {{ 'LENGTH' | translate }}" secondaryPlaceholder="{{ 'LENGTH' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.length" displayBy="value" identifyBy="Text" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>DEGRE_FRISURE</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.degreFrisure" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'degreFrisure')" (onRemove)="removeItem(filter, $event, 'degreFrisure')" name="tag-input-degreFrisure"
      placeholder="+ {{ 'DEGRE_FRISURE' | translate }}" secondaryPlaceholder="{{ 'DEGRE_FRISURE' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.degreFrisure" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>REGULARITE_FRISURE</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.regulariteFrisure" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'regulariteFrisure')" (onRemove)="removeItem(filter, $event, 'regulariteFrisure')" name="tag-input-regulariteFrisure"
      placeholder="+ {{ 'REGULARITE_FRISURE' | translate }}" secondaryPlaceholder="{{ 'REGULARITE_FRISURE' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.regulariteFrisure" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>FRISOTTIS</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.frisottis" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'frisottis')" (onRemove)="removeItem(filter, $event, 'frisottis')" name="tag-input-frisottis"
      placeholder="+ {{ 'FRISOTTIS' | translate }}" secondaryPlaceholder="{{ 'FRISOTTIS' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.frisottis" displayBy="value" identifyBy="Text" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>ALOPECIE_FEMME</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.alopecieFemme" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'alopecieFemme')" (onRemove)="removeItem(filter, $event, 'alopecieFemme')" name="tag-input-alopecieFemme"
      placeholder="+ {{ 'ALOPECIE_FEMME' | translate }}" secondaryPlaceholder="{{ 'ALOPECIE_FEMME' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.alopecieFemme" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>ALOPECIE_HOMME</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.alopecieHomme" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'alopecieHomme')" (onRemove)="removeItem(filter, $event, 'alopecieHomme')" name="tag-input-application-alopecieHomme"
      placeholder="+ {{ 'ALOPECIE_HOMME' | translate }}" secondaryPlaceholder="{{ 'ALOPECIE_HOMME' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.alopecieHomme" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>BASE_NATURELLE</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.baseNaturelle" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'baseNaturelle')" (onRemove)="removeItem(filter, $event, 'baseNaturelle')" name="tag-input-baseNaturelle"
      placeholder="+ {{ 'BASE_NATURELLE' | translate }}" secondaryPlaceholder="{{ 'BASE_NATURELLE' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.baseNaturelle" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>PAS_CHEVEUX_BLANCS</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.cheveuxBlancs" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'cheveuxBlancs')" (onRemove)="removeItem(filter, $event, 'cheveuxBlancs')" name="tag-input-cheveuxBlancs"
      placeholder="+ {{ 'PAS_CHEVEUX_BLANCS' | translate }}" secondaryPlaceholder="{{ 'PAS_CHEVEUX_BLANCS' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.cheveuxBlancs" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>POURCENT_CHEVEUX_BLANCS_DEVANT</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.cheveuxBlancsPourcentDevant" displayBy="value" identifyBy="Text"
      [onlyFromAutocomplete]="true" (onAdd)="addItem(filter, $event, 'cheveuxBlancsPourcentDevant')" (onRemove)="removeItem(filter, $event, 'cheveuxBlancsPourcentDevant')"
      name="tag-input-cheveuxBlancsPourcentDevant" placeholder="+ {{ 'POURCENT_CHEVEUX_BLANCS_DEVANT' | translate }}" secondaryPlaceholder="{{ 'POURCENT_CHEVEUX_BLANCS_DEVANT' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.cheveuxBlancsPourcentDevant" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>POURCENT_CHEVEUX_BLANCS_NUQUE</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.cheveuxBlancsPourcentNuque" displayBy="value" identifyBy="Text"
      [onlyFromAutocomplete]="true" (onAdd)="addItem(filter, $event, 'cheveuxBlancsPourcentNuque')" (onRemove)="removeItem(filter, $event, 'cheveuxBlancsPourcentNuque')"
      name="tag-input-cheveuxBlancsPourcentNuque" placeholder="+ {{ 'POURCENT_CHEVEUX_BLANCS_NUQUE' | translate }}" secondaryPlaceholder="{{ 'POURCENT_CHEVEUX_BLANCS_NUQUE' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.cheveuxBlancsPourcentNuque" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>ABONDANCE_CHEVEUX</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.abondanceCheveux" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'abondanceCheveux')" (onRemove)="removeItem(filter, $event, 'abondanceCheveux')" name="tag-input-abondanceCheveux"
      placeholder="+ {{ 'ABONDANCE_CHEVEUX' | translate }}" secondaryPlaceholder="{{ 'ABONDANCE_CHEVEUX' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.abondanceCheveux" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>SENSIBILISATION</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.sensibilisation" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'sensibilisation')" (onRemove)="removeItem(filter, $event, 'sensibilisation')" name="tag-input-sensibilisation"
      placeholder="+ {{ 'SENSIBILISATION' | translate }}" secondaryPlaceholder="{{ 'SENSIBILISATION' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.sensibilisation" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>GROSSEUR_CHEVEUX</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.grosseur" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'grosseur')" (onRemove)="removeItem(filter, $event, 'grosseur')" name="tag-input-grosseur"
      placeholder="+ {{ 'GROSSEUR_CHEVEUX' | translate }}" secondaryPlaceholder="{{ 'GROSSEUR_CHEVEUX' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.grosseur" displayBy="value" identifyBy="Text" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>DURETE</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.durete" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'durete')" (onRemove)="removeItem(filter, $event, 'durete')" name="tag-input-durete"
      placeholder="+ {{ 'DURETE' | translate }}" secondaryPlaceholder="{{ 'DURETE' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.durete" displayBy="value" identifyBy="Text" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>NATURE_CHEVEUX</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.natureCheveux" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'natureCheveux')" (onRemove)="removeItem(filter, $event, 'natureCheveux')" name="tag-input-natureCheveux"
      placeholder="+ {{ 'NATURE_CHEVEUX' | translate }}" secondaryPlaceholder="{{ 'NATURE_CHEVEUX' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.natureCheveux" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>NATURE_CUIR_CHEVELU</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.natureCuirChevelu" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'natureCuirChevelu')" (onRemove)="removeItem(filter, $event, 'natureCuirChevelu')" name="tag-input-natureCuirChevelu"
      placeholder="+ {{ 'NATURE_CUIR_CHEVELU' | translate }}" secondaryPlaceholder="{{ 'NATURE_CUIR_CHEVELU' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.natureCuirChevelu" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>CHEVEUX_NATURELS</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.cheveuxNaturels" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'cheveuxNaturels')" (onRemove)="removeItem(filter, $event, 'cheveuxNaturels')" name="tag-input-cheveuxNaturels"
      placeholder="+ {{ 'CHEVEUX_NATURELS' | translate }}" secondaryPlaceholder="{{ 'CHEVEUX_NATURELS' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.cheveuxNaturels" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>HAUTEUR_TON_COLORATION</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.hauteurTonColoration" displayBy="value" identifyBy="Text"
      [onlyFromAutocomplete]="true" (onAdd)="addItem(filter, $event, 'hauteurTonColoration')" (onRemove)="removeItem(filter, $event, 'hauteurTonColoration')"
      name="tag-input-hauteurTonColoration" placeholder="+ {{ 'HAUTEUR_TON_COLORATION' | translate }}" secondaryPlaceholder="{{ 'HAUTEUR_TON_COLORATION' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.hauteurTonColoration" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>ROUTINE_SHADES</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.categorieNuance" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'categorieNuance')" (onRemove)="removeItem(filter, $event, 'categorieNuance')" name="tag-input-categorieNuance"
      placeholder="+ {{ 'ROUTINE_SHADES' | translate }}" secondaryPlaceholder="{{ 'ROUTINE_SHADES' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.categorieNuance" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>COSMETIC_EQUILIBRE</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.cosmetiqueEquilibre" displayBy="value" identifyBy="Text"
      [onlyFromAutocomplete]="true" (onAdd)="addItem(filter, $event, 'cosmetiqueEquilibre')" (onRemove)="removeItem(filter, $event, 'cosmetiqueEquilibre')"
      name="tag-input-cosmetiqueEquilibre" placeholder="+ {{ 'COSMETIC_EQUILIBRE' | translate }}" secondaryPlaceholder="{{ 'COSMETIC_EQUILIBRE' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.cosmetiqueEquilibre" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>TINCTORIAL_EQUILIBRE</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.tinctorialEquilibre" displayBy="value" identifyBy="Text"
      [onlyFromAutocomplete]="true" (onAdd)="addItem(filter, $event, 'tinctorialEquilibre')" (onRemove)="removeItem(filter, $event, 'tinctorialEquilibre')"
      name="tag-input-tinctorialEquilibre" placeholder="+ {{ 'TINCTORIAL_EQUILIBRE' | translate }}" secondaryPlaceholder="{{ 'TINCTORIAL_EQUILIBRE' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.tinctorialEquilibre" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>CASSE</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.casse" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'casse')" (onRemove)="removeItem(filter, $event, 'casse')" name="tag-input-casse" placeholder="+ {{ 'CASSE' | translate }}"
      secondaryPlaceholder="{{ 'CASSE' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.casse" displayBy="value" identifyBy="Text" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>BARBE</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.hommePortBarbe" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'hommePortBarbe')" (onRemove)="removeItem(filter, $event, 'hommePortBarbe')" name="tag-input-hommePortBarbe"
      placeholder="+ {{ 'BARBE' | translate }}" secondaryPlaceholder="{{ 'BARBE' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.hommePortBarbe" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
</div>
