import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { SimpleChange } from '@angular/core';

@Component({
    selector: 'dna-password-strength-bar',
    templateUrl: './password-strength-bar.component.html',
    styleUrls: ['./password-strength-bar.component.less']
})

export class PasswordStrengthBarComponent implements OnInit, OnChanges {
    @Input() passwordToCheck: string;
    @Output() onChange: EventEmitter<number> = new EventEmitter();
    bar0: string;
    bar1: string;
    bar2: string;
    bar3: string;
    bar4: string;

    private colors = ['#ed5565', '#f89f59', '#f8d459', '#9ed51f', '#1ab394'];
    private static measureStrength(p) {
        let force = 0;
        let regex = /[$-/:-?{-~!"^_`\[\]]/g; // "

        let lowerLetters = /[a-z]+/.test(p);
        let upperLetters = /[A-Z]+/.test(p);
        let numbers = /[0-9]+/.test(p);
        let symbols = regex.test(p);

        let flags = [lowerLetters, upperLetters, numbers, symbols];

        let passedMatches = 0;
        for (let flag of flags) {
            passedMatches += flag === true ? 1 : 0;
        }

        force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
        force += passedMatches * 10;

        // penality (short password)
        force = (p.length <= 6) ? Math.min(force, 10) : force;

        // penality (poor variety of characters)
        force = (passedMatches === 1) ? Math.min(force, 10) : force;
        force = (passedMatches === 2) ? Math.min(force, 20) : force;
        force = (passedMatches === 3) ? Math.min(force, 30) : force;
        return force;
    }

    constructor() { }

    ngOnInit() { }

    ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
        let password = changes['passwordToCheck'].currentValue;
        this.setBarColors(5, '#DDD');
        if (password) {
            let complexity = this.getColor(PasswordStrengthBarComponent.measureStrength(password));
            this.setBarColors(complexity.idx, complexity.col);
            this.onChange.emit(complexity.idx);
        }
    }

    private getColor(s) {
        let idx = 0;
        if (s <= 40) {
            idx = (s % 10 == 0) ? (s / 10) - 1 : Math.floor(s / 10);
        } else {
            idx = 4;
        }
        return {
            idx: idx + 1,
            col: this.colors[idx]
        };
    }

    private setBarColors(count, col) {
        for (let n = 0; n < count; n++) {
            this['bar' + n] = col;
        }
    }

}
