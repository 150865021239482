<div *ngIf="graph.descriptors.length > 0" class="table-responsive">
  <table class="table table-hover table-borderless-th dataTable">
    <thead>
      <tr>
        <th *ngIf="!isWorkflowPublished && editMode" ></th>
        <th translate>ORDER</th>
        <th translate>DESCRIPTOR</th>
        <th translate>BLOCK</th>
        <th *ngIf="!isWorkflowPublished && editMode" ></th>
      </tr>
    </thead>
    <tbody [dragula]="keyDragula" [(dragulaModel)]="graph.descriptors">
      <tr *ngFor="let descriptor of graph.descriptors; let indexDesc = index">
        <td *ngIf="!isWorkflowPublished && editMode" class="reorder cursor-pointer align-middle">
          <i class="fa fa-arrows-v"></i>
        </td>
        <td class="align-middle">{{ indexDesc + 1 }}</td>
        <td class="align-middle">
          <dna-translate [fieldToTranslate]="descriptor.name"> </dna-translate>{{descriptor?.blockSuffix}}
        </td>
        <td class="align-middle">
          <dna-translate [fieldToTranslate]="descriptor.blockName"> </dna-translate>
        </td>
        <td *ngIf="!isWorkflowPublished && editMode" class="text-center">
          <ng-container *ngIf="canRemoved; else canNotRemoved">
            <button type="button" class="btn btn-danger btn-sm" (click)="removeDescriptor(indexDesc)">
              <i class="fa fa-times"></i>
            </button>
          </ng-container>
          <ng-template #canNotRemoved>
            <div class="row" ngbTooltip="{{ 'DESCRIPTOR_ACTIVATE_DEACTIVATE' | translate }}">
              <dna-switch class="col-12" [isSelected]="descriptor.isActive" (onBooleanChanged)="onSwitchChanged(indexDesc, $event)"></dna-switch>
              <label class="col-12" translate>{{ descriptor.isActive ? "ACTIVE" : "INACTIVE" }}</label>
            </div>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
