<div class="modal-scroll">
  <div class="modal-header">
    <div class="d-flex flex-row justify-content-between w-100">
      <div>
        <h3 class="modal-title">
          <dna-translate [fieldToTranslate]="workflow.name"></dna-translate>
        </h3>
      </div>
      <div class="open-close d-flex flex-row gap-2">
        <button class="btn btn-primary" (click)="openCloseDescriptors(false)" translate>ALL_OPEN</button>
        <button class="ml-2 btn btn-primary" type="submit" (click)="openCloseDescriptors(true)" translate>ALL_CLOSE
        </button>
      </div>
      <div>
        <button class="btn-close" type="button" (click)="cancel()">
          <span class="sr-only">Close</span>
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <ng-container *ngFor="let block of workflow.blocks; let indexBlock = index">
      <button type="button" class="m-1 btn btn-block btn-light w-100" *ngIf="!block.parameters?.inactive"
              attr.aria-controls="{{'collapseBlock_' + indexBlock}}"
              (click)="collapsedBlocksTab[indexBlock] = !collapsedBlocksTab[indexBlock]">
        <div class="row w-100">
          <span class="col-md-4">
            <dna-translate [fieldToTranslate]="block.name"></dna-translate>
            {{ block.suffix }}
          </span>
          <span class="col-md-4">
            {{ "NUMBER_OBJECTS_SELECTED" | translate: {objects: "DESCRIPTORS" | translate | lowercase} }}: {{
                  getNumberSelected(block, descriptorsInWorkflow)
            }}
          </span>
          <div class="col-md-4 d-flex flex-row gap-1 align-items-center justify-content-center"
               (click)="selectAllDescriptors(getDescriptorsInBlock(descriptorsInWorkflow, block.idInQuestionnaire), indexBlock, $event, block); false">
          <span class="fa"
                [ngClass]="{ 'fa-square-o': !selectAll[indexBlock], 'fa-check-square-o': selectAll[indexBlock] }"></span>
            <span translate>SELECT_ALL_DESCRIPTOR</span>
          </div>
        </div>
      </button>
      <div class="row m-1" id="{{'collapseBlock_' + indexBlock}}" [ngbCollapse]="collapsedBlocksTab[indexBlock]">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th style="width: 50%">{{ "NAME" | translate }}</th>
              <th style="width: 50%">{{ "SCALE" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let descriptor of getDescriptorsInBlock(descriptorsInWorkflow, block.idInQuestionnaire); let index = index"
              (click)="addOrRemoveDescriptors(descriptor, indexBlock, block)"
              class="cursor-pointer line-descriptor" [ngClass]="{'selected': isDescriptorSelected(descriptor)}">
              <td>
                <dna-translate [fieldToTranslate]="descriptor.name"></dna-translate>
                {{ block.suffix }}
              </td>
              <td>
                {{ descriptor.scaleDetail }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>

    <ng-container *ngIf="isGroup">
      <div>
        <table class="table table-hover table-borderless-th dataTable">
          <thead>
          <tr>
            <th translate>DESCRIPTOR</th>
            <th translate>BLOCK</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let descriptor of descriptorsInGraph">
            <tr *ngIf="descriptor.isActive">
              <td class="align-middle">
                <dna-translate [fieldToTranslate]="descriptor.name"></dna-translate>
                {{ descriptor.blockSuffix }}
              </td>
              <td class="align-middle">
                <dna-translate [fieldToTranslate]="descriptor.blockName"></dna-translate>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
      <div class="py-3 row">
        <label class="col-md-3 my-auto form-control-label required" translate>GROUP_DESCRIPTORS_NAME</label>
        <div class="col-md-6">
          <input type="text" class="form-control" [(ngModel)]="groupName" required>
        </div>
      </div>
      <div class="py-3 row">
        <label class="col-md-3 my-auto form-control-label required" translate>CALCUL_METHOD</label>
        <div class="col-md-6">
          <select class="form-control" [(ngModel)]="calculationMethodSelected">
            <option *ngFor="let calculMethod of calculationMethods" [ngValue]="calculMethod" translate>{{
                    calculMethod
              }}
            </option>
          </select>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="submit" (click)="submit(descriptorsInGraph)" translate>VALIDATE</button>
  <button class="btn btn-default" type="button" (click)="cancel()" translate>CANCEL</button>
</div>
