
import {finalize, takeUntil, tap} from 'rxjs/operators';
import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';

import * as _ from 'lodash';
import { Subject } from 'rxjs';

import {
  AttributeDetails,
  KeyBoolean,
  TargetHair,
  CampaignTarget
} from '../../../../../types';
import {
  CampaignService
} from '../../../../campaigns.service';
import {
  ErrorManagerService
} from '../../../../../shared/services/errorManager.service';
import { UtilService } from '../../../../../shared/services/util.service';

@Component({
  selector: 'dna-target-hair',
  templateUrl: './target-hair.component.html',
  styleUrls: ['./target-hair.component.less']
})
export class TargetHairComponent implements OnInit, OnDestroy {

  @Input() campaignTarget: CampaignTarget;
  @Input() publishedTemplate: boolean;

  allFormChecked: boolean = true;
  lineSelectedObject: KeyBoolean = {};
  originalLineSelectedObject: KeyBoolean = {};
  originalTarget: TargetHair;
  showSpinner: boolean = false;
  target: TargetHair;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private campaignService: CampaignService,
    private errorManagerService: ErrorManagerService,
    private router: Router,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.target = _.isUndefined(this.campaignTarget.target) ? new TargetHair() : _.cloneDeep(this.campaignTarget.target);
    this.originalTarget = _.cloneDeep(this.target);
    this.initAllLineSelectedObject(this.target);
    this.originalLineSelectedObject = _.cloneDeep(this.lineSelectedObject);
    this.isAllFormChecked(this.lineSelectedObject); 
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onCancel() {
    this.target = _.cloneDeep(this.originalTarget);
    this.lineSelectedObject = _.cloneDeep(this.originalLineSelectedObject);
    this.isAllFormChecked(this.lineSelectedObject);
    // this.campaignService.setCampaignFromLocalStorage(this.campaign);
  }

  initAllLineSelectedObject(target) {
    _.forOwn(target, (value, key) => {
      this.isLineSelected(key, this.lineSelectedObject, value);
    });
  }

  checkAllForm() {
    _.forOwn(this.target, (value, key) => {
      if (_.isArray(value)) {
        value.forEach(val => {
          val.IsValue = true;
        })
      }
      if (_.isArray(value)) this.lineSelectedObject[key] = this.isAllfieldChecked(value);
    });
    this.isAllFormChecked(this.lineSelectedObject);
  }

  uncheckAllForm() {
    _.forOwn(this.target, (value, key) => {
      if (_.isArray(value)) {
        value.forEach(val => {
          val.IsValue = false;
        })
      }
      if (_.isArray(value)) this.lineSelectedObject[key] = this.isAllfieldChecked(value);
    });
    this.isAllFormChecked(this.lineSelectedObject);
  }

  isChangedTarget() {
    return this.utilService.isDifferent(this.target, this.originalTarget);
  }

  onChangeData(array: AttributeDetails[], index: number) {
    array[index].IsValue = !array[index].IsValue;
    let keyOfArray = this.getKeyFromValue(array);
    this.lineSelectedObject[keyOfArray] = this.isAllfieldChecked(array);
    this.isAllFormChecked(this.lineSelectedObject);
  }

  selectUnselectLine(targetElements: AttributeDetails[], lineSelected: boolean) {
    let keyFromValue;
    targetElements.forEach(elt => {
      elt.IsValue = !lineSelected;
    })
    keyFromValue = this.getKeyFromValue(targetElements);
    this.lineSelectedObject[keyFromValue] = !lineSelected;
    this.isAllFormChecked(this.lineSelectedObject);
    return !lineSelected;
  }

  onSubmit() {
    this.showSpinner = true;
    this.campaignService.putCampaignTarget(this.campaignTarget.id, this.target).pipe(
      takeUntil(this.destroy$),
      finalize(() => this.showSpinner = false),)
      .subscribe(
        () => {
          this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE');
          if (!_.get(this.campaignTarget, 'isTemplate', false)) {
            this.router.navigate(['campaigns', this.campaignTarget.id, 'edit', 'users']);
          }
          else {
            this.router.navigate(['campaigns', this.campaignTarget.id, 'edit', 'publish']);
          }
        },
        error => this.errorManagerService.catchError(error)
      );
  }

  private isLineSelected(key, lineSelectedObject, value) {
    return lineSelectedObject[key] = !_.isArray(value) ? false : this.isAllfieldChecked(value);
  }

  private isAllfieldChecked(value: AttributeDetails[]) {
    return value.every(elt => elt.IsValue);
  }

  private isAllFormChecked(lineSelectedObject) {
    this.allFormChecked = true;
    _.forOwn(lineSelectedObject, (value, key) => {
      if (!value && key !== "type") this.allFormChecked = false;
    });
  }

  private getKeyFromValue(array) {
    let keyFromValue;
    _.forOwn(this.target, (value, key) => {
      if (value === array) keyFromValue = key;
    })
    return keyFromValue;
  }

}
