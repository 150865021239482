import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HubsById, HubById, Hub } from '../../../../../types';
import { ReferenceTypeService } from '../../../../../shared/services/reference-type.service';
import { UserService } from '../../../../../shared/services/user.service';
import { HttpRestService } from '../../../../../shared/services/httpRest.service';
import { catchError, tap, takeUntil } from 'rxjs/operators';
import { throwError as observableThrowError, Subject } from 'rxjs';
import { ErrorManagerService } from '../../../../../shared/services/errorManager.service';
import { VolunteerService } from '../../../../../volunteers/volunteers.service';
import { ApplicationInsightsService } from '../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-associate-hors-arcs-modal',
  templateUrl: './associate-hors-arcs-modal.component.html',
  styleUrls: ['./associate-hors-arcs-modal.component.less']
})
export class AssociateHorsArcsModalComponent implements OnInit, AfterViewInit {

  @Input() title: string;
  @Input() studyId: string;
  @Input() groupsNameHorsArcs: string[];
  @Input() studyHub: Hub;

  @Input() horsArcsSystem: HubById;

  @Input() formHorsArcs: any;

  @Input() areVolunteersAdded:boolean

  groupNameHorsArcs: string;
  submitted: boolean = false;
  countries: HubsById[];
  horsArcsIncorrect: boolean = false;
  horsArcsLoading: boolean = false;
  horsArcsExists: boolean = false;
  groupVolunteerToAdd: any;
  groupVolunteers: string[];
  initTime = performance.now();

  constructor(
    private activeModal: NgbActiveModal,
    private referenceType: ReferenceTypeService,
    private userService: UserService,
    private aiService: ApplicationInsightsService,
    private httpRestService: HttpRestService,
    private errorManager: ErrorManagerService,
    private volunteerService: VolunteerService,

  ) { }

  destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnInit() {
    this.countries = this.referenceType.getHubsById();
    this.horsArcsSystem = this.initHorsArcsSystem();
    this.getValuesForForm();
  }

  ngAfterViewInit() {
    this.aiService.logPageView('Associate Hors Arcs Modal', '', performance.now() - this.initTime, 'Modals');
  }

  initHorsArcsSystem(): HubsById {
    const hub: HubById = this.referenceType.getHubByIdFromHub(this.studyHub);
    return hub ? hub : HubsById.France;
  }

  cancel() {
      this.activeModal.dismiss();
  }

  updateHorsArcsInProject() {
    if (this.groupVolunteerToAdd[0]) {
      this.associateInputToList(this.groupVolunteerToAdd[0].groupName)
    }
    else if (this.formHorsArcs) {
      this.associateInputToList(this.formHorsArcs);
    } else {
        this.horsArcsExists = false;
        this.horsArcsIncorrect = true;
    }
  }

  associateInputToList(groupHorsArcs: string)
  {
    const studyId = this.studyId
    this.horsArcsIncorrect = false;
    if (this.groupsNameHorsArcs.includes(groupHorsArcs)) {
      this.horsArcsExists = true;
    } else {
      this.horsArcsLoading = true;
      this.httpRestService.associateGroupPanelistHorsArcs(this.horsArcsSystem, groupHorsArcs, studyId, this.areVolunteersAdded).pipe(
        catchError(err => {
          this.horsArcsIncorrect = true;
          this.horsArcsLoading = false;
          return observableThrowError(err);
        }),
        tap(() => {
          console.log("group info :", groupHorsArcs)
          this.activeModal.close({groupNameHorsArcs: groupHorsArcs});
          this.horsArcsLoading = false;
          this.errorManager.displayMessage('ON_SUCCESS_ASSOCIATE_GROUP');
        }),
        takeUntil(this.destroy$),)
        .subscribe();
    }
  }

  setCountry(country: HubById) {
    this.horsArcsSystem = country;
    this.getValuesForForm();
  }

  getValuesForForm()
  {
    this.groupVolunteerToAdd = [];
    this.formHorsArcs = "";

    this.volunteerService.getVolunteerGroupShort(this.horsArcsSystem).subscribe({
      next: (result) => {
        const list = result.list;
        this.groupVolunteers = this.resultToString(list);
      }
    });
    console.log("groupVolunteer : ", this.groupVolunteers);
  }

  resultToString(valueList):string[]
  {
    let returnValue: string[] = [];

    for (let value of valueList)
    {
       if (value.groupName)
         returnValue.push(value.groupName);
    }

    return returnValue;
  }
}
