import {Component, Input, OnInit} from '@angular/core';
import {CampaignsUsers, CampaignUsers} from '../../../../types';

@Component({
  selector: 'dna-users-config',
  templateUrl: './users-config.component.html',
  styleUrls: ['./users-config.component.less']
})
export class UsersConfigComponent implements OnInit {

  @Input() private _campaignsMultiUsers: CampaignsUsers[];
  private _filteredArray: CampaignsUsers[];

  constructor() {
  }

  ngOnInit() {
  }

  public get filteredArray(): any {
    this._filteredArray = this._campaignsMultiUsers;
    return this._filteredArray.filter(campaignsUsers => campaignsUsers.volunteers.indexOf(campaignsUsers.volunteers[0]) !== -1);
  }

  public get campaignsMultiUsers(): CampaignsUsers[] {
    return this._campaignsMultiUsers;
  }

  public set campaignsMultiUsers(value: CampaignsUsers[]) {
    this._campaignsMultiUsers = value;
  }

}
