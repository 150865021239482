import { Languages } from "../../enums";

const getObjectValueTranslation = (object: any, lang: string): string => {
    switch (typeof object) {
        case 'object':
            if (!object) return "";
            if (object.hasOwnProperty('custom')) return object;
            if (object.hasOwnProperty('user') && object.user.length) return object.user;
            if (object.hasOwnProperty(lang) && object[lang].length) return object[lang];
            return object[Languages.Default];
        default:
            return object;
    };
};


const formatter = (element: any, displayLanguages: Array<string>, showBlock: boolean): string => {

    const header = `<table>`;
    const footer = `</table>`;
    let body: string = ``;

    displayLanguages.map(( lang : string ) => {
        switch (typeof element.value) {
            case 'object':
                if (showBlock && element.value.hasOwnProperty('blockName')) {
                    body = `
                    ${ body}
                    <tr> <td style="text-align: center;"> <b>${ getObjectValueTranslation(element.value.blockName, lang)}</b> </td> </tr><br/>
                 `;
                };
                body = `
                    ${ body}
                    <tr> <td style="text-align: center;"> ${ getObjectValueTranslation(element.value.label, lang)}    </td> </tr>
                `;
                if (element.value.hasOwnProperty('drilled')) {
                    body = `
                        ${ body}
                        <tr> <td style="text-align: center;"> ${ getObjectValueTranslation(element.value.drilled.label, lang)}    </td> </tr>
                    `;
                };
                break;
            default:
                body = ` <tr> <td style="text-align: center;"> ${element.value} </td> </tr>`;
        };
        body = `${ body } <tr><td><span style="color: transparent">|</span></td></tr><br/>`;
    });

    return [
        header,
        body,
        footer
    ].join("");
};

export {
    formatter
};