import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'dna-switch-confirm',
  templateUrl: './switch-confirm.component.html',
  styleUrls: ['./switch-confirm.component.css']
})
export class SwitchConfirmComponent implements OnInit {

  @Input() disabled: boolean = false;
  @Input() isSelected: boolean;

  @Output() onBooleanChanged: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  // onClick() {
  //   if (!this.disabled) {
  //     this.isSelected = !this.isSelected;
  //     this.onBooleanChanged.emit(this.isSelected);
  //   }
  // }

}
