<div class="table-responsive">
  <table class="table table-hover table-borderless-th dataTable">
    <thead>
      <tr>
        <th translate>FORMULA</th>
        <th translate>CODE</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of anonymousCode | keyvalue">
        <td>{{ item.key }}</td>
        <td>
          <input class="form-control" type="text" [disabled]="campaignPublished" [(ngModel)]="item.value" (ngModelChange)="onChangeCode(item.key, $event)" />
        </td>
      </tr>
    </tbody>
  </table>
</div>
