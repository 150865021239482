<td (click)="onClickWk()">
  <dna-translate [fieldToTranslate]="workflow.name"> </dna-translate>
  <span *ngIf="!workflow.name[currentLanguage] && workflow.name.english">
    <i>(english version)</i>
  </span>
</td>
<td (click)="onClickWk()" translate>{{ workflow.state }}</td>
<td (click)="onClickWk()">{{ workflow.hub }}</td>
<td (click)="onClickWk()">{{ workflow.created_on | date:'yyyy-MM-dd' }}</td>
<td (click)="onClickWk()">{{ workflow.updated_on | date:'yyyy-MM-dd' }}</td>
<td>
    <div class="d-flex flex-row gap-2 align-items-center">
      <dna-favorites [id]="workflow.id" type="workflow"></dna-favorites>
      <dna-action-bar class="d-flex w-100 gap-2" [actionsButtons]="actionsButtons | async" (onClick)="onClickButton($event)"></dna-action-bar>
    </div>
</td>
