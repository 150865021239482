<div class="dna-chart-options--actions">
  <a (click)="this.handleOptionsWrapper()" class="dna-chart-options--button">
    <div></div>
    <div></div>
    <div></div>
  </a>
  <button
      dna-button
      [attr.tabindex]="this.wrapperState ? 0 : -1"
      [attr.disabled] = "this.notDisplayable ? '' : null"
      class="button--default"
      (click)="this.handleExcelDownload.emit()"
      >
      <img src="./assets/down-arrow.svg" alt="download-as-excel"/>
    </button>
    <button
      [attr.disabled] = "this.notDisplayable ? '' : null"
      dna-button
      [attr.tabindex]="this.wrapperState ? 0 : -1"
      class="button--default"
      (click)="this.handleDownload.emit()"
      >
      <img src="./assets/camera.svg" alt="download-as-excel"/>
    </button>
</div>
<div [class.dna-chart-options--has-wrapper]="this.wrapperState" class="dna-chart-options">
  <div [class.dna-chart-options__wrapper--open]="this.wrapperState" class="dna-chart-options__wrapper">
    <div class="dna-chart-options__wrapper--header"> <b translate>VIZUALIZATIONS.CHART_OPTIONS</b> </div>
    <div class="dna-chart-options__wrapper--body">
      <form *ngIf="this.form && this.parameters && this.parameters.options" [formGroup]="this.form" (ngSubmit)="onSubmit()">
        <div class="panel-body d-flex flex-column" *ngFor="let formPart of this.objectKeys(this.parameters.options); let i = index;">
          <h3 translate> {{ this.parameters.options[formPart].label }} </h3>
          <div
            [class.form-group-with-checkbox]="option.type === 'checkbox'"
            class="form-group"
            *ngFor="let option of this.parameters.options[formPart].options"
            [formGroupName]="formPart">
            <label class="mb-2" *ngIf="!option.hideLabel" [attr.for]="this.identifier + '_' + option.key" translate> {{ option.label }}</label>
            <div class="" [ngSwitch]="option.type">
              <select
                [formControlName]="option.key"
                [attr.tabindex]="this.wrapperState ? 0 : -1"
                [attr.name]="option.key"
                *ngSwitchCase="'select'">
                <option *ngFor="let opt of option.options" [value]="opt">{{ opt }}</option>
              </select>
              <input
                *ngSwitchCase="'checkbox'"
                class="form-control"
                [attr.tabindex]="this.wrapperState ? 0 : -1"
                [attr.type]="'checkbox'"
                [attr.id]="this.identifier + '_' + option.key"
                [checked]="option.value"
                (click)="this.checkboxUpdate($event,formPart, option)"
                [formControlName]="option.key"
                />
              <input
                *ngSwitchCase="'color'"
                class="form-control"
                [attr.tabindex]="this.wrapperState ? 0 : -1"
                [attr.type]="'color'"
                [formControlName]="option.key"
                [attr.name]="option.key" />
              <dna-group-checkbox
                *ngSwitchCase="'group_checkbox'"
                [label]="option.label"
                [data]="option.value"
                [currentForm]="this.form.controls[formPart].controls[option.key]"
                [closingEvent]="closeSubject.asObservable()"
                >

              </dna-group-checkbox>
              <input
                *ngSwitchCase="'number'"
                type="number"
                [formControlName]="option.key"
                [attr.name]="option.key"
                [attr.tabindex]="this.wrapperState ? 0 : -1"
              />
              <input
                *ngSwitchDefault
                class="form-control"
                [attr.tabindex]="this.wrapperState ? 0 : -1"
                [attr.type]="'text'"
                [formControlName]="option.key"
                [attr.name]="option.key"
                />
            </div>
          </div
          >
        </div>
      </form>
      <form>
        <div class="panel-body">
          <div class="form-group" >
            <h3 translate>VIZUALIZATIONS.ADDITIONAL_OPTIONS</h3>
            <div class="internal_comment-options">
              <div class="internal_comment-options-item">
                <label for="internal_comment_size">font size</label>
                <input type="number" [(ngModel)]="commentFontSize" [ngModelOptions]="{standalone: true}" id="internal_comment_size" />
              </div>
              <div class="internal_comment-options-item">
                <label for="internal_comment_bold">bold</label>
                <input type="checkbox" [(ngModel)]="commentBold" [ngModelOptions]="{standalone: true}" id="internal_comment_bold" />
              </div>
            </div>
            <label for="internal_comment" translate>
              VIZUALIZATIONS.COMMENT
            </label>
            <textarea class="comment-input" id="internal_comment" [(ngModel)]="comment" [ngModelOptions]="{standalone: true}" maxlength="500" rows="4"></textarea>
            <span>{{comment.length}}/500</span>
          </div>
        </div>
      </form>
    </div>
    <div class="dna-chart-options__wrapper--footer">
      <button
        dna-button
        [attr.disabled] = "this.notDisplayable ? '' : null"
        [attr.tabindex]="this.wrapperState ? 0 : -1"
        class="button--link"
        (click)="this.handleExcelDownload.emit()"
        translate>VIZUALIZATIONS.OPTIONS.DOWNLOAD_AS_EXCEL</button>
      <button
        dna-button
        [attr.disabled] = "this.notDisplayable ? '' : null"
        [attr.tabindex]="this.wrapperState ? 0 : -1"
        class="button--link"
        (click)="this.handleDownload.emit()"
        translate>VIZUALIZATIONS.OPTIONS.ADD_TO_ONE_PAGER</button>
      <button
        [attr.tabindex]="this.wrapperState ? 0 : -1"
        dna-button class="button--default"
        (click)="this.cancelForm()"
        translate>VIZUALIZATIONS.OPTIONS.CANCEL</button>
      <button
        [attr.tabindex]="this.wrapperState ? 0 : -1"
        dna-button
        class="button--primary"
        (click)="this.submitForm()"
        translate>VIZUALIZATIONS.OPTIONS.APPLY</button>
    </div>
  </div>
</div>
