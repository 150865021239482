// SKIN
import skinHistogramMultiAttributes from './skin/columncharts/histogram-multi-attributes.config';
import skinBarchartTwoSides         from './skin/barcharts/barchart-two-sides.config';
import skinSpider                   from './skin/spiders/spider.config';
import skinTornado                  from './skin/tornados/tornado.config';
import skinHistogram                from './skin/columncharts/histogram.config';
import skinTableComments            from './skin/tables/table.comments.config';
import skinLinechart                from './skin/linecharts/linechart.config';
import skinLinechartNormalized      from './skin/linecharts/linechart.normalized.config';
import skinTableMSRepart            from  './skin/tables/table.ms-repart.config';
import skinTableColoration          from './hair/tables/table.coloration.config';
import skinTableRawData             from './hair/tables/table.raw-data.config';
import skinTableRawDataCount        from './hair/tables/table.raw-data-count.config';
import skinTableRawDataMedian       from './hair/tables/table.raw-data-median.config';

// HAIR
import hairHistogramMultiAttributes from './hair/columncharts/histogram.multi-attributes.config';
import hairBarchartTwoSides         from './hair/barcharts/barchart.two-sides.config';
import hairBarchart                 from './hair/barcharts/barchart.config';
import hairHistogram                from './hair/columncharts/histogram.config';
import hairScalebar3points          from './hair/scalebars/scalebar.3-points.config';
import hairScalebar5points          from './hair/scalebars/scalebar.5-points.config';
import hairSankey                   from './hair/sankeys/sankey.config';
import hairTableMSRepart            from './hair/tables/table.ms-repart.config';
import hairTableComments            from './hair/tables/table.comments.config';
import hairTableQS                  from './hair/tables/table.qs-3-5-pts.config';
import hairTableColoration          from './hair/tables/table.coloration.config';
import hairTableRawData             from './hair/tables/table.raw-data.config';
import hairTableRawDataCount        from './hair/tables/table.raw-data-count.config';
import hairTableRawDataMedian       from './hair/tables/table.raw-data-median.config';

// COMMONS
import histogramMultiCount          from './commons/columncharts/histogram.multi-count';
import BarChartMSRepart             from './commons/barcharts/barchart.ms-repart.config';
import histogramMultiMedian         from './commons/columncharts/histogram.multi-median';
import linechartWithBench           from './commons/linecharts/linechart-withBench.config';
import bubblechart                  from './commons/bublecharts/bubble.multi';
import kineticChart                 from './commons/kineticcharts/kinetic-median-min-max';
import histogram_confort            from './commons/columncharts/histogram_confort';
import histoConfortPercentage       from './commons/columncharts/histogram_confort_percent'; 
import histogramConfortPreference   from './commons/columncharts/histogram_confort_preferences';

export const chartsConfigs = {

    'hair' : {
        'HistogramMultiAttributs'   : hairHistogramMultiAttributes,
        'HistogramMultiFormulas'    : hairHistogramMultiAttributes,
        'HistogramMultiUsers'       : hairHistogramMultiAttributes,
        'HistogramMultiSomme'       : histogramMultiCount,
        'HistogramMultiMedian'      : histogramMultiMedian,
        'Histogram'                 : hairHistogram,
        'Sankey'                    : hairSankey,
        'BarChart'                  : hairBarchart,
        'BulletScale3'              : hairScalebar3points,
        'BulletScale5'              : hairScalebar5points,
        'TableWithBar'              : hairTableMSRepart,
        'BarTwoSided'               : hairBarchartTwoSides,
        'MatrixComment'             : hairTableComments,
        'Matrix3'                   : hairTableQS,
        'Matrix5'                   : hairTableQS,
        'Peluches'                  : hairTableQS,
        'MatrixCommentAdHoc'        : hairTableComments,
        'TableCrossedUsersValues'   : hairTableComments,
        'ColorationTable'           : hairTableColoration,
        'ColorationTableUS'         : hairTableColoration,
        'RawDataTable'              : hairTableRawData,
        'RawDataCountTable'         : hairTableRawDataCount,
        'RawDataMedianTable'        : hairTableRawDataMedian,
        'RawDataTableHorizontal'    : hairTableRawData,
        'RawDataCountTableHorizontal'  : hairTableRawDataCount,
        'RawDataMedianTableHorizontal'  : hairTableRawDataMedian,
        'Radar'                     : skinSpider,
        'Tornado'                   : skinTornado,
        'LineChart'                 : skinLinechart,
        'LineChartNormalized'       : skinLinechartNormalized,
        'LineChartMulti'            : linechartWithBench,
        'Bubble'                    : bubblechart,
        'ConfortCineticLine'        : kineticChart,
        'ConfortHistogram'          : histogram_confort,
        'PreferencesHistogram'      : histogramConfortPreference,
        'HistoConfortPercentage'    : histoConfortPercentage
    },

    'skin' : {
        'HistogramMultiUsers'       : skinHistogramMultiAttributes,
        'HistogramMultiAttributs'   : skinHistogramMultiAttributes,
        'HistogramMultiFormulas'    : skinHistogramMultiAttributes,
        'HistogramMultiSomme'       : histogramMultiCount,
        'HistogramMultiMedian'      : histogramMultiMedian,
        'Histogram'                 : skinHistogram,
        'Radar'                     : skinSpider,
        'Tornado'                   : skinTornado,
        'LineChart'                 : skinLinechart,
        'LineChartNormalized'       : skinLinechartNormalized,
        'MatrixComment'             : skinTableComments,
        'MatrixCommentAdHoc'        : skinTableComments,
        'BulletScale3'              : hairScalebar3points,
        'BulletScale5'              : hairScalebar5points,
        'BarTwoSided'               : skinBarchartTwoSides,
        'TableWithBar'              : skinTableMSRepart,
        'TableCrossedUsersValues'   : skinTableComments,
        'ColorationTable'           : skinTableColoration,
        'ColorationTableUS'         : skinTableColoration,
        'Peluches'                  : hairTableQS,
        'PeluchesRisks'             : hairTableQS,
        'RawDataTable'              : skinTableRawData,
        'RawDataCountTable'         : skinTableRawDataCount,
        'RawDataMedianTable'        : skinTableRawDataMedian,
        'RawDataTableHorizontal'    : skinTableRawData,
        'RawDataCountTableHorizontal'  : skinTableRawDataCount,
        'RawDataMedianTableHorizontal' : skinTableRawDataMedian,
        'LineChartMulti'            : linechartWithBench,
        'Bubble'                    : bubblechart,
        'ConfortCineticLine'        : kineticChart,
        'ConfortHistogram'          : histogram_confort,
        'PreferencesHistogram'      : histogramConfortPreference,
    },

    'commons' : {
        'BarChartMSRepart'          : BarChartMSRepart,
        'ConfortCineticLine'        : kineticChart,
        'ConfortHistogram'          : histogram_confort,
        'PreferencesHistogram'      : histogramConfortPreference,
        'HistoConfortPercentage'    : histoConfortPercentage
    }
};
