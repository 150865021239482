<dna-layout>
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <app-progress-bar
    *ngIf="showProgressBar"
    [progress]="progressValue"
    [currentFile]="currentFileIndex"
    [totalFiles]="totalFiles"
    [totalExportedStudies]="totalExportedStudies"
    [isError]="isError"
    [errorMessage]="errorMessage"
    [isExportedStudies]="isExportedStudies"
    [isDownloadComplete]="isDownloadComplete">
  </app-progress-bar>
  <h1 class="mb-4" id="banner-title" translate>EXPORT_DATA</h1>
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" translate>DASHBOARD</a>
      </li>
      <li class="breadcrumb-item"><a routerLink="/campaigns">{{ "CAMPAIGNS" | translate }}</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>EXPORT_DATA</li>
    </ol>
  </nav>
  <div *ngIf="isAuthorized('DNA_EXPORT'); else unauthorized" class="block">
    <div class="ibox float-e-margins">
      <div class="ibox-title row noMarginSide">
        <h5 class="col-md-6" translate>SEARCH</h5>
      </div>

      <div id="exportFilter" class="ibox-content">
        <div class="col-12  col-md-10 p-0">
            <dna-search-text class="search" [placeholder]="'CAMPAIGN_NAME'" [data]="filter.name" (onSearch)="filter.name = $event">
            </dna-search-text>
          </div>
        <div class="row row-input-export">
          <label class="col-12 col-md-2 my-auto ">
            {{ "BRIDGE" | translate }}
          </label>
          <div class="col-12 col-md-8">
            <tag-input identifyBy="key" displayBy="value" [(ngModel)]="filter.bridge" name="input-bridge"
              placeholder="+ {{ 'BRIDGE' | translate }}"
              secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC_ENTER_PROMPT' | translate: {'object': 'BRIDGE' | translate | lowercase} }}">
            </tag-input>
          </div>
        </div>
        <div class="row row-input-export">
          <label class="col-12 col-md-2 my-auto ">
            {{ "ORCHESTRA" | translate }}
          </label>
          <div class="col-12 col-md-8">
            <tag-input identifyBy="key" displayBy="value" [(ngModel)]="filter.orchestra"
              name="input-orchestra" placeholder="+ {{ 'ORCHESTRA' | translate }}"
              secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC_ENTER_PROMPT' | translate: {'object': 'ORCHESTRA' | translate | lowercase} }}">
            </tag-input>
          </div>
        </div>
        <div class="row row-input-export">
          <label class="col-12 col-md-2 my-auto ">
            {{ "ACTIVIEW" | translate }}
          </label>
          <div class="col-12 col-md-8">
            <tag-input identifyBy="key" displayBy="value" [(ngModel)]="filter.actiview"
                       name="input-orchestra" placeholder="+ {{ 'ACTIVIEW' | translate }}"
                       secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC_ENTER_PROMPT' | translate: {'object': 'ACTIVIEW' | translate | lowercase} }}">
            </tag-input>
          </div>
        </div>
        <div class="row row-input-export">
          <label class="col-12 col-md-2 my-auto ">
            {{ "WORKFLOW" | translate }}
          </label>
          <div class="col-12 col-md-8">
            <tag-input identifyBy="key" displayBy="value" [(ngModel)]="filter.workflows"
                       name="input-orchestra" placeholder="+ {{ 'WORKFLOW' | translate }}" [onlyFromAutocomplete]="true"
                       secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: {'object': 'WORKFLOW' | translate | lowercase} }}">
              <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="workflows" displayBy="name" identifyBy="id" [minimumTextLength]="1"
                [showDropdownIfEmpty]="false">
              </tag-input-dropdown>
            </tag-input>
          </div>
        </div>
        <div class="row row-input-export">
          <label class="col-12 col-md-2 my-auto ">
            {{ "NUMBER_TIMEPOINTS" | translate }}
          </label>
          <div class="col-12 col-md-8">
            <input class="inputNumber" [(ngModel)]="filter.timepointsNb" name="input-timepointsNb" type="number" [defaultValue]="0" />
          </div>
        </div>
        <div class="row row-input-export">
          <label class="col-12 col-md-2 my-auto ">
            {{ "NAME_VISITS" | translate }}
          </label>
          <div class="col-12 col-md-8">
            <tag-input identifyBy="key" displayBy="value" [(ngModel)]="filter.timepointsName"
              name="input-timepointsName" placeholder="+ {{ 'NAME_VISITS' | translate }}"
              secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC_ENTER_PROMPT' | translate: {'object': 'NAME_VISITS' | translate | lowercase} }}">
            </tag-input>
          </div>
        </div>
        <div class="row row-input-export">
          <label class="col-12 col-md-2 my-auto" translate>HUB</label>
          <div class="col-8 mt-2 d-flex flex-row gap-3">
            <dna-filter-list [array]="hubs" [horizontalAlignment]="true" [filterValue]="filter.hubs">
            </dna-filter-list>
          </div>
        </div>
        <div class="row row-input-export">
          <label class="col-12 col-md-2 my-auto" translate>DATE_START_LABEL</label>
          <div class="col-8 mt-2">
            <dna-filter-date [dateFilterStart]="filter.startDate" [dateFilterEnd]="filter.endDate" (updateDateStart)="updateDate($event, 'startDate')"
                (updateDateEnd)="updateDate($event, 'endDate')">
              </dna-filter-date>
          </div>
        </div>

        <div class="export-button">
          <ng-container *ngIf="campaignsFiltered.length > 0">
            <div class="select-campaign">
              <div class="global-switch">
                <dna-switch title="Select All"
                  [isSelected]="areAllSelected"
                  (onBooleanChanged)="onSelectAllSwitchChanged($event)">
                </dna-switch>
              </div>
              <div class="select-all-label">
                <label translate>SELECT_ALL_CAMPAIGNS</label>
              </div>
            </div>
          </ng-container>
          <div class="export-button-link">
            <a class="btn btn-primary mb-4" (click)="showStudies(filter)">
              <span translate>GET_DATA_EXPORT</span>
            </a>
            <a class="btn btn-primary mb-4" *ngIf="campaignsFiltered.length > 0" (click)="export(filter)">
              <span translate>EXPORT</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="ibox float-e-margins">
      <div class="ibox-title row noMarginSide">
        <h5 class="col-md-6" translate>FILTERS</h5>
      </div>
      <div id="exportFilter" class="ibox-content">
        <div class="row">
        </div>

      </div>
    </div> -->
    <div class="ibox float-e-margins">
      <div class="ibox-title row noMarginSide">
        <h5 class="col-md-6" translate>CAMPAIGNS</h5>
      </div>
      <div id="exportFilter2" class="ibox-content">
        <ng-container *ngIf="campaignsFiltered.length > 0; else emptyCampaignsFiltered">
          <dna-table2 [data]="campaignsFiltered" [headers]="tableHeaders$ | async" [sortable]="true"
                      [numberOfObjectsPerPage]="filter.limit" orderedBy="DATE_MODIFICATION" [totalItems]="totalItems"
                      [pageIndex]="filter.page" [reverse]="true" [sortFunction]="sortExport" [title]="'CAMPAIGNS'"
                      (parametersChanged)="onParametersChanged($event)">
              <ng-template #row let-data="data">
                <tr dna-export-data-set-row [campaign]="data" [allSelected]="areAllSelected || data.isSelected" (onClickSwitch)="onSwitchChanged($event, data)"></tr>
              </ng-template>
          </dna-table2>
        </ng-container>
        <ng-template #emptyCampaignsFiltered>
          <span translate>NO_DATA_EXPORT</span>
        </ng-template>
      </div>
    </div>
  </div>
  <ng-template #unauthorized>
    <div class="block">
      <div class="ibox float-e-margins">
        <div class="ibox-title row noMarginSide">
          <h5 class="col-md-6" translate>UNAUTHORIZED</h5>
        </div>
      </div>
    </div>
  </ng-template>
</dna-layout>
