export enum COMPUTING_METHODS {
    MEDIAN              = 'median',
    DRILLED             = 'drilled',
    MEDIAN_MERGED       = 'median-merged',
    COUNT               = 'count',
    NORMALIZED_MEDIAN   = 'normalized-median',
    NONE                = 'none',
    NONE_MEDIAN         = 'none-median',
    MEDIAN_BENCH_REF    = 'medianRef',
    CONFORT_WITH_PVALUE = 'confortWithPvalue',
    CONFORT_PREFERENCES = 'confortPreferences',
    CONFORT_PERCENT_WITH_CATEGORY = 'confortPercentWithCategory',
}
