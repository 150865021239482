import {Component, Output, EventEmitter, OnInit, Input} from '@angular/core';
import {Menu, DNATypes, CampaignMetadata} from '../../../../../types';
import {UtilService} from '../../../../../shared/services/util.service';
import {ActivatedRoute} from '@angular/router';
import {mergeMap, map, takeUntil, tap} from 'rxjs/operators';
import {
  CampaignService
} from '../../../../campaigns.service';



@Component({
  selector: 'dna-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})

export class MenuComponent implements OnInit {
  @Output() onCollapse = new EventEmitter<boolean>();
  @Input() isMulti = false;

  public collapsed = true;
  sideMenu: Menu[];

  isTemplate

  constructor(
    private utilService: UtilService,
    private route: ActivatedRoute,
    private campaignService: CampaignService,
  ) {
  }


  ngOnInit() {
    let paramMapCampaignId = this.isMulti ? 'idCampaignMulti' : 'idCampaign';
    let menuLink = this.isMulti ? '/multi-campaigns' : '/campaigns';
    let menuType = this.isMulti ? DNATypes.CampaignsMulti : DNATypes.Campaign;
    let menuTemplate = DNATypes.Template;

    if (this.isMulti) {
          paramMapCampaignId = 'idCampaignMulti';
          menuLink = '/multi-campaigns';
          menuType = DNATypes.CampaignsMulti;
        }

    const idCampaign = this.route.snapshot.paramMap.get(paramMapCampaignId);

    this.campaignService.CampaignTemplate(idCampaign).subscribe(data => {
      const isTemplate = data.isTemplate;

      if (isTemplate) {
        this.utilService.createMenu(menuTemplate)
          .subscribe((menus: Menu[]) => {
            this.sideMenu = menus.map((menu: Menu) => {
              menu.link = [menuLink, idCampaign].concat(menu.link);
              return menu;
            });
          });
      }
      else {
        this.utilService.createMenu(menuType)
          .subscribe((menus: Menu[]) => {
            this.sideMenu = menus.map((menu: Menu) => {
              menu.link = [menuLink, idCampaign].concat(menu.link);
              return menu;
            });
          });
      }
    });


  }


  /**
   * _handleWrapperCollapse
   */
  public _handleWrapperCollapse = (actualState: boolean): void => {
    this.collapsed = !actualState;
    this.onCollapse.emit(this.collapsed);
  }


}
