import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { OnePager, OnePagerTabs } from '../../../../types';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';
import * as _ from 'lodash';
import {
  UtilService
} from '../../../../shared/services/util.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dna-one-pager',
  templateUrl: './one-pager.component.html',
  styleUrls: ['./one-pager.component.less']
})

export class OnePagerComponent implements OnInit, AfterViewInit {

  selectedTab: string;
  onePager: OnePager;
  idCampaign: string;
  link: string[];
  initTime = performance.now();

  public onePagerTabs = OnePagerTabs;

  constructor(
    private aiService: ApplicationInsightsService,
    private utilService: UtilService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const idCampaign = this.route.parent.snapshot.paramMap.get('idCampaign');
    this.link = ['/campaigns', idCampaign, 'edit', 'onepager'];
  }

  onActivate(data) {
    this.selectedTab = this.utilService.getTab(_.last(this.router.url.split("/")));
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign OnePager', '', performance.now() - this.initTime, templateUrl);
    this.ref.detectChanges();
  }
}
