
import {
  Observable,
  Subscription,
  throwError as observableThrowError,
  of
} from 'rxjs';

import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  ParamMap,
  Router
} from '@angular/router';
import { catchError, finalize, flatMap, mergeMap, tap } from 'rxjs/operators';

import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import {
  ApplicationInsightsService
} from '../../shared/services/applicationInsights.service';
import {
  ErrorManagerService
} from '../../shared/services/errorManager.service';
import {
  FilterService
} from '../../shared/services/filter.service';
import { ReferenceTypeService } from '../../shared/services/reference-type.service';
import {
  StateService
} from '../../shared/services/state.service';
import {
  DNATranslateService
} from '../../shared/services/translate.service';
import {
  UserService
} from '../../shared/services/user.service';
import {
  UtilService
} from '../../shared/services/util.service';
import {
  ActionBarButton,
  ActionTypes,
  DNATypes,
  Filter,
  Hub,
  Languages,
  Pager,
  TableHeader,
  User,
  Workflow
} from '../../types';
import {
  WorkflowService
} from '../workflows.service';
import { CreateWorkflowModalComponent } from './create-workflow/create-workflow-modal.component';

@Component({
  selector: 'dna-workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['./workflows.component.less']
})

export class WorkflowsComponent implements OnInit, OnDestroy, AfterViewInit {

  currentLanguage: Languages;
  filter: any;
  hubs: Hub[] = [];
  showSpinner = true;
  states: string[] = [];
  subscribeLang: Subscription;
  subscribeParam: Subscription;
  subscribeUser: Subscription;
  user: User;
  workflows: Workflow[];
  isCollapsedWorkflowsFilters = true;
  totalItems = 0;
  initTime = performance.now();

  tableHeaders$: Observable<TableHeader[]>;

  private favoritesSubscription: Subscription;

  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };

  constructor(
    private aiService: ApplicationInsightsService,
    private dnaTranslateService: DNATranslateService,
    private errorManagerService: ErrorManagerService,
    private filterService: FilterService,
    private modalService: NgbModal,
    private referenceTypeService: ReferenceTypeService,
    private route: ActivatedRoute,
    private router: Router,
    private stateService: StateService,
    private userService: UserService,
    private utilService: UtilService,
    private workflowService: WorkflowService,

  ) {}

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Library Workflow Components', '', performance.now() - this.initTime, templateUrl);
  }

  ngOnInit() {
    this.tableHeaders$ = this.utilService.createHeaders(DNATypes.Workflow);
    this.currentLanguage = this.dnaTranslateService.getLanguage();
    this.user = this.userService.getUser();

    this.filter = this.filterService.getFilter(this.userService.getUserHub(this.user.hub)).workflows;
    this.hubs = this.referenceTypeService.getHubs();
    this.states = this.stateService.getVisibleStates(this.stateService.WORKFLOWS);
    this.updateWorkflows(this.filter);

    this.subscribeParam = this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.has('state')) {
        this.filter.states = [params.get('state')];
      }
    });

    this.subscribeLang = this.dnaTranslateService.onLangChange().subscribe((translation: any) => {
      this.currentLanguage = translation.lang;
      this.tableHeaders$ = this.utilService.createHeaders(DNATypes.Workflow);
    });

    this.subscribeUser = this.userService.onUserChanged().subscribe((user: User) => {
      this.user.currentWorkspace = user.currentWorkspace;
      this.updateWorkflows(this.filter);
    });

    this.subscribeToFavoritesChanges();
  }


  ngOnDestroy() {
    this.subscribeLang.unsubscribe();
    this.subscribeParam.unsubscribe();
    this.subscribeUser.unsubscribe();

    if (this.favoritesSubscription) {
      this.favoritesSubscription.unsubscribe();
      this.showSpinner = false;
    }
  }

  deleteWorkflow(workflow: Workflow): Observable<Workflow> {
    this.showSpinner = true;
    return this.workflowService.deleteWorkflow(workflow.id).pipe(
      finalize(() => this.showSpinner = false),
      catchError(err => {
        this.errorManagerService.catchError(err);
        return observableThrowError(err);
      }));
  }

  catchError = (error) => {
    this.showSpinner = false;
    if (error.error === 'name') {
      this.errorManagerService.displayMessage('NAME_ALREADY_TAKEN', 'danger');
    } else {
      this.errorManagerService.displayMessage('ON_ERROR_UPDATE', 'danger');
    }
    return of();
  }

  openModalNewWF(idWorkflowToDuplicate = '') {
    const modalCreateWf = this.modalService.open(CreateWorkflowModalComponent, this.modalOption);
    modalCreateWf.result.then((workflow: Workflow) => {
      this.showSpinner = true;
      const request$ = idWorkflowToDuplicate !== ''
        ? this.workflowService.duplicateWorkflow(idWorkflowToDuplicate, workflow)
        : this.workflowService.createWorkflow(workflow);
      request$.pipe(
        catchError(this.catchError),
        tap((wf: {id: string}) => {
          setTimeout(() => {
            this.router.navigate(['workflows', wf.id]);
          }, 1000);
        })
      ).subscribe();
    }).catch(() => {
      this.showSpinner = false;
      this.router.navigate(['workflows']).then(() => null);
    });
  }

  onClickActionButton(actionButton: ActionBarButton, idWorkflow: string) {
    const workflow = this.workflows.find(wk => wk.id === idWorkflow);
    const workflowName = this.dnaTranslateService.getTranslation(workflow.name, this.currentLanguage);
    switch (actionButton.id) {
      case ActionTypes.Duplicate:
        this.openModalNewWF(idWorkflow);
        break;
      case ActionTypes.Previewing:
        this.router.navigate(['workflows', idWorkflow, 'preview']).then(() => null);
        break;
      case ActionTypes.Delete:
        this.utilService.translateMessageModal('CONFIRM_COMPLETE_DELETE', workflowName, 'THE_WORKFLOW').pipe(
          mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)),
          mergeMap(() => this.deleteWorkflow(workflow)),
          catchError(this.catchError),
          tap(() => this.errorManagerService.displayMessage('ON_SUCCESS_DELETE')),
          flatMap(async () => this.updateWorkflows(this.filter))
        ).subscribe();
        break;
      case ActionTypes.Remove:
        this.utilService.translateMessageModal('CONFIRM_DELETE', workflowName, 'THE_WORKFLOW').pipe(
          mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)),
          mergeMap(() => this.workflowService.removeWorkflow(workflow.id)),
          catchError(this.catchError),
          tap(() => this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE')),
          flatMap(async () => this.updateWorkflows(this.filter))
        ).subscribe();
        break;
      case ActionTypes.PutBack:
        this.utilService.translateMessageModal('CONFIRM_PUT_BACK', workflowName, 'THE_WORKFLOW').pipe(
          mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)),
          mergeMap(() => this.workflowService.suspendWorkflow(workflow.id)),
          catchError(this.catchError),
          tap(() => this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE')),
          flatMap(async () => this.updateWorkflows(this.filter))
        ).subscribe();
        break;
      case ActionTypes.Edit:
        this.showSpinner = true;
        this.router.navigate(['workflows', idWorkflow]).catch(() => {
          this.errorManagerService.displayMessage('ON_ERROR_UPDATE', 'danger');
          this.showSpinner = false;
        });
        break;
    }
  }

  onParametersChanged(event: {pager: Pager, type: string}) {
    _.set(this.filter, 'pageIndex', _.get(event, 'pager.currentPage', 1));
    _.set(this.filter, 'numberOfObjectsPerPage', _.get(event, 'pager.pageSize', 1));
    this.updateWorkflows(this.filter);
  }

  sort = (headerId: string, reverse: boolean) => {
    switch (headerId) {
      case 'NAME':
        _.set(this.filter, 'orderBy', `name.${this.currentLanguage}`);
        break;
      case 'STATE':
        _.set(this.filter, 'orderBy', `state`);
        break;
      case 'HUB':
          _.set(this.filter, 'orderBy', `hub`);
          break;
      case 'DATE_CREATION':
        _.set(this.filter, 'orderBy', 'created_on');
        break;
      case 'DATE_MODIFICATION':
        _.set(this.filter, 'orderBy', 'updated_on');
        break;
      default:
        _.set(this.filter, 'orderBy', 'updated_on');
        break;
    }
    reverse ? _.set(this.filter, 'order', 'ASC') : _.set(this.filter, 'order', 'DESC');
    this.updateWorkflows(this.filter);
  }

  updateData(text: any, type: string) {
    this.filter[type] = text;
    this.searchWorkflows(this.filter);
    _.set(this.filter, 'pageIndex', _.get(event, 'pager.currentPage', 1));
  }

  updateWorkflows(filter: Filter['workflows'] = {
    displayFavoritesOnly: false,
    displayTestsOnly: false,
    hubs: [],
    name: '',
    numberOfObjectsPerPage: 0,
    pageIndex: 0,
    states: [],
    orderBy: '',
    order: ''
  })  {

    this.showSpinner = true;

    const user = this.userService.getUser();
    const favoriteWorkflowIds = user.favorites
      .filter(favorite => favorite.type === 'workflow')
      .map(favorite => favorite.id);

    return this.workflowService.getWorkflows(filter).pipe(
      catchError(error => {
        this.errorManagerService.catchError(error);
        return of([]);
      }),
      tap(response => {
        const workflows = response.list;
        if (filter.displayFavoritesOnly) {
          this.workflowService.getWorkflows({...filter, numberOfObjectsPerPage: 0, pageIndex: 0}).pipe(
            tap(response => {
              const workflows = response.list.filter(workflow => favoriteWorkflowIds.includes(workflow.id));
              const startIndex = (filter.pageIndex - 1) * filter.numberOfObjectsPerPage;
              this.workflows = workflows.slice(startIndex, startIndex + filter.numberOfObjectsPerPage);
              this.totalItems = workflows.length;
            }),
            finalize(() => this.showSpinner = false)
          ).subscribe();
        }
        this.workflows = workflows;
        this.totalItems = response.totalItems;
      }),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  searchWorkflows(filters: Filter['workflows'] = {
    displayFavoritesOnly: false,
    displayTestsOnly: false,
    hubs: [],
    name: '',
    numberOfObjectsPerPage: 0,
    pageIndex: 0,
    states: [],
    orderBy: '',
    order: ''
  })  {

    this.updateWorkflows(filters);
  }

  subscribeToFavoritesChanges() {
    // this.favoritesSubscription = this.userService.favorites$.subscribe(() => {
    //   if (this.filter.displayFavoritesOnly === true) {
    //     this.updateWorkflows(this.filter);
    //     this.showSpinner = false;
    //   }
    // });
  }
}
