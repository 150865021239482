<form #workspaceListForm="ngForm" novalidate>
  <div class="modal-scroll">
    <ngx-loading [show]="showSpinner"></ngx-loading>
    <div class="modal-header" class="text-center">
      <h3 *ngIf="type === state.BLOCK"> {{ 'EDIT_LIST' | translate: {'object': 'BLOCKS' | translate | lowercase} }} </h3>
      <h3 *ngIf="type === state.WORKFLOW"> {{ 'EDIT_LIST' | translate: {'object': 'WORKFLOWS' | translate | lowercase} }} </h3>
      <h3 *ngIf="type === state.CAMPAIGN"> {{ 'EDIT_LIST' | translate: {'object': 'CAMPAIGNS' | translate | lowercase} }} </h3>
    </div>
    <label class="col-sm-24"></label>
    <div class="modal-body">
      <ng-container *ngIf="!showSpinner && !error">
        <div class="brick-board">
          <label class="col-sm-1"> </label>
          <div class="workspace-height right-space col-sm-5">
            <div id="children">
              <div class="children-list">
                <ng-container>
                  <div class="text-center" *ngIf="type === state.BLOCK"> {{'LIST_OF' | translate}} {{ 'BLOCKS' | translate}} </div>
                  <div class="text-center" *ngIf="type === state.WORKFLOW"> {{'LIST_OF' | translate}} {{ 'WORKFLOWS' | translate}} </div>
                  <div class="text-center" *ngIf="type === state.CAMPAIGN"> {{'LIST_OF' | translate}} {{ 'CAMPAIGNS' | translate}} </div>
                  <hr class="separate-line">
                </ng-container>
                <ul class="container" [dragula]='idDragula' [(dragulaModel)]='listObjectToDrag' id="source">
                  <li *ngFor="let itemAll of listObjectToDrag; let indexObject = index" class="draggableComponents block copy-allowed" id="block-{{indexObject}}">
                    <div *ngIf="type !== state.CAMPAIGN && itemAll.name" class="box-item">
                      <label class="pointer">
                        <dna-translate [fieldToTranslate]="itemAll.name"> </dna-translate>
                      </label>
                    </div>
                    <div *ngIf="type === state.CAMPAIGN  && itemAll.name" class="box-item">
                      <label class="pointer"> {{ itemAll.name }} </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="workspace-height col-sm-5">
            <div id="children">
              <div class="children-list">
                <ul class="container dropzoneListWorkspace col-sm-12" [dragula]='idDragula' [(dragulaModel)]='listObjectByWorkspace' id="target">
                  <ng-container>
                    <div class="text-center" translate> {{ editWorkspace.name }} </div>
                    <hr class="separate-line">
                    <div class="text-center" *ngIf="listObjectByWorkspace.length==0">
                      <label translate>PUT_HERE</label>
                    </div>
                  </ng-container>
                  <li class="block" *ngFor="let item of listObjectByWorkspace; let key = index" id="block-{{key}}">
                    <div class="box-item">
                      <label class="col-sm-11" *ngIf="type !== state.CAMPAIGN && item.name">
                        <dna-translate [fieldToTranslate]="item.name"> </dna-translate>
                      </label>
                      <label class="col-sm-11" *ngIf="type === state.CAMPAIGN && item.name"> {{ item.name }} </label>
                      <span (click)='listObjectToDrag.push(item);removeList(key); listObjectByWorkspace.splice(key, 1)' class="pull-right fa fa-trash"></span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="!showSpinner && error" class="ibox float-e-margins">
        <div class="ibox-title">
          <div class="row">
            <div class="col-12 col-md-4 my-auto">
              <h5>{{ "ERROR" | translate }}</h5>
            </div>
          </div>
        </div>
        <dna-error-page (onClickReload)="init()"></dna-error-page>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <dna-save-cancel (onClickSave)="save(workspaceListForm)" type="administration" (onClickCancel)="cancel()"></dna-save-cancel>
  </div>
</form>
