<dna-counter-side-config *ngIf="component.type == 'dna-counter-side'" [component]="component" [inactive]="inactive"></dna-counter-side-config>
<dna-info-text-config *ngIf="component.type == 'dna-info-text'"  [component]="component" [inactive]="inactive"></dna-info-text-config>
<dna-input-text-config *ngIf="component.type == 'dna-input-text'" [component]="component" [inactive]="inactive"></dna-input-text-config>
<dna-photos-config *ngIf="component.type == 'dna-photos'" [component]="component" [inactive]="inactive"></dna-photos-config>
<dna-ranking-config *ngIf="component.type == 'dna-ranking'" [component]="component"  [inactive]="inactive" [submitted]="submitted" (updateError)="sendError($event)">
</dna-ranking-config>
<dna-timer-config *ngIf="component.type == 'dna-timer'" [component]="component"  [inactive]="inactive"  [submitted]="submitted" (updateError)="sendError($event)">
</dna-timer-config>
<dna-multiple-choice-config *ngIf="component.type == 'dna-multiple-choice'" [component]="component" [inactive]="inactive"  [submitted]="submitted" (updateError)="sendError($event)"></dna-multiple-choice-config>
<dna-rich-text-editor-config *ngIf="component.type == 'dna-rich-text'"  [component]="component" [inactive]="inactive"></dna-rich-text-editor-config>
<dna-slider-config *ngIf="component.type == 'dna-confort'"  [component]="component" [inactive]="inactive"></dna-slider-config>
