
import {of as observableOf,  Observable ,  Subscriber } from 'rxjs';

import {mergeMap, merge, filter, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';

import { environment } from '../../environments/environment';
import { Training, Workflow, User, TrainingMetadata, UserInCampaign, ViewType, ViewTypes } from '../types';
import { UtilService } from '../shared/services/util.service';
import { LocalStorageService } from '../shared/services/localStorage.service';

@Injectable()
export class TrainingService {

  serverUrlStudio: string;
  trainings: Training[];
  currentTrainings = {};
  users: User[];
  currentState: string;
  workflows: Workflow[] = [];
  metadata: TrainingMetadata;
  CARD_MODE: ViewType = ViewTypes.ModeCard;
  CARD_SEQUENTIAL_MODE: string = "CONSUMER_SEQUENTIAL";
  CUSTOMIZE_CARD_MODE: ViewType = ViewTypes.ModeCustomizeCard;
  DUAL_CARD_MODE: ViewType = ViewTypes.ModeDualCard;
  DUAL_CARD_SEQUENTIAL_MODE: string = "DUAL_CARD_SEQUENTIAL";
  LIST_MODE: ViewType = ViewTypes.ModeList;

  private storageKeys = {
    Training: 'DNA_TRAINING',
    TrainingsLite: 'DNA_TRAINING_LITE',
    TrainingMetadata: 'DNA_TRAINING_METADATA'
  }

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private localStorageService: LocalStorageService
  ) {
    this.serverUrlStudio = environment.server_url_studio();
  }

  cacheRequest(httpRequest: Observable<any>, cacheRequest: Observable<any>): Observable<any> {
    const cacheIsEmpty = cacheRequest.pipe(
      filter(_.isEmpty),
      mergeMap(() => httpRequest),);

    const cacheNotEmpty = cacheRequest.pipe(
      filter(data => !_.isEmpty(data)),
      merge(httpRequest),);

    return cacheIsEmpty.pipe(merge(cacheNotEmpty));
  }

  createTraining = (training: Training): Observable<Training> => {
    return this.http.post<Training>(this.serverUrlStudio + '/trainings', training);
  }

  deleteTraining(id: string): Observable<Training> {
    return this.http.delete<Training>(this.serverUrlStudio + '/trainings/' + id);
  }

  getAssessmentPerId(idTraining, idAssessment): Observable<any> {
    return this.http.get(this.serverUrlStudio + '/trainings/' + idTraining + '/assessments/' + idAssessment);
  }

  getTrainingsFromLocalStorage(): Training[] {
    return this.localStorageService.getItem(this.storageKeys.TrainingsLite);
  }

  setTrainingsFromLocalStorage(trainings: Training[]) {
    this.localStorageService.setItem(this.storageKeys.TrainingsLite, trainings);
  }

  getCurrentTraining(id: string): Observable<Training> {
    let httpRequest = this.getHttpTraining(id);
    let cacheRequest = observableOf(this.getTrainingFromLocalStorage(id));
    return this.cacheRequest(httpRequest, cacheRequest).pipe(tap(training => this.setTrainingFromLocalStorage(training)));
  }

  getTrainingFromLocalStorage = (id: string): Training => {
    return this.localStorageService.getItem(this.storageKeys.Training + id);
  }

  setTrainingFromLocalStorage(training: Training) {
    this.localStorageService.setItem(this.storageKeys.Training + training.id, training);
  }

  getHttpTraining(id: string): Observable<Training> {
    return this.http.get<Training>(this.serverUrlStudio + '/trainings/' + id);
  }

  getTrainingInService() {
    return this.trainings ? _.cloneDeep(this.trainings) : [];
  }

  getTrainingMetadata(): Observable<TrainingMetadata> {
    let httpRequest = this.getHttpMetadata();
    let cacheRequest = observableOf(this.getMetadataFromLocalStorage());
    return this.cacheRequest(httpRequest, cacheRequest).pipe(tap(metadata => this.setMetadataFromLocalStorage(metadata)));
  }

  getHttpMetadata(): Observable<TrainingMetadata> {
    return this.http.get<TrainingMetadata>(this.serverUrlStudio + '/campaigns/metadata');
  }

  getMetadataFromLocalStorage(): TrainingMetadata {
    return this.localStorageService.getItem(this.storageKeys.TrainingMetadata);
  }

  setMetadataFromLocalStorage(metadata: TrainingMetadata) {
    this.localStorageService.setItem(this.storageKeys.TrainingMetadata, metadata);
  }

  getTrainings(): Observable<Training[]> {
    return this.http.get<Training[]>(this.serverUrlStudio + '/trainings');
  }

  getUsersToDisplay(training: any) {
    if (this.users && this.users.length > 0) {
      if (training.assessments && training.assessments.length > 0) {
        training.assessments = training.assessments.map(assessment => {
          if (assessment.users && assessment.users.length > 0) {
            assessment.users = assessment.users.map(evaluationUser => {
              evaluationUser.name = this.users.find(user => user.key === evaluationUser.key).name;
              return evaluationUser;
            });
          }
          return assessment;
        });
      }
    }
    return training;
  }

  putTraining(training: Training): Observable<Training> {
    return this.http.put<Training>(this.serverUrlStudio + '/trainings/' + training.id, training);
  }

  setTrainingInService(training: Training[]) {
    this.trainings = _.cloneDeep(training);
  }

}
