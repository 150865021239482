import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Campaign } from '../../../../../../types';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-upload-capture-modal',
  templateUrl: './upload-capture-modal.component.html',
  styleUrls: ['./upload-capture-modal.component.less']
})
export class UploadCaptureModalComponent implements OnInit, AfterViewInit {
  @Input() capturePowerBI: string;

  showSpinner: boolean = false;
  initTime = performance.now();

  constructor(
    private activeModal: NgbActiveModal,
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.aiService.logPageView('Upload Capture Modal', '', performance.now() - this.initTime, 'Modals');
  }

  cancel() {
    this.activeModal.dismiss();
  }

  compareID(c1: Campaign, c2: Campaign): boolean {
    return (c1 && c2) ? c1.id === c2.id : false;
  }

  onSubmit(capture: string) {
    this.activeModal.close(capture);
  }

}
