<ngx-loading [show]="showSpinner"></ngx-loading>
<div>
  <dna-images-one-pager
    [images]="images"
    [pages]="pages"
    [takeCapture]="takeCapture"
    [events]="eventsSubject.asObservable()"
    (onImagesChanged)="onImagesChanged($event)"
    (onUpdatedGrid)="onUpdatedGrid($event)"
    (onCapturesDone)="onCapturesDone($event)"
    (onCapturesError)="onCapturesError($event)"
    (onAutomaticFillPages)="save()"
    (onAutoSave)="save()">
  </dna-images-one-pager>
</div>
