import {AnalyseMultiRootComponent} from './detail/analyse-multi/analyse-multi-root.component';

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Routes, RouterModule} from '@angular/router';
import {
  AuthGuardService
} from '../shared/services/authenticationGuard.service';
import {
  MultiCampaignsComponent
} from './list/multi-campaigns.component';
import {CreateCampaignMultiModalComponent} from './list/create-campaign-multi/create-campaign-multi-modal.component';


import {
  SharedModule
} from '../shared/shared.module';
import {MultiCampaignsRowComponent} from './list/multi-campaigns-row/multi-campaigns-row.component';
import {GeneralInformationMultiComponent} from './detail/general-info-multi/general-information-multi.component';
import {CampaignMultiRootComponent} from './root/campaign-multi-root.component';
import {WorkflowsMultiComponent} from './detail/workflows-multi/workflows-multi.component';
import {ProtocolMultiComponent} from './detail/protocol-multi/protocol-multi.component';
import {TargetMultiComponent} from './detail/target-multi/target-multi.component';
import {FormulasMultiComponent} from './detail/formulas-multi/formulas-multi.component';
import {UsersMultiComponent} from './detail/users-multi/users-multi.component';
import {StudiesMultiComponent} from './detail/studies-multi/studies-multi.component';
import {NgbdModalConfirmMultiStudyName} from './detail/studies-multi/confirm-name-modal/confirm-name-modal.component';
import {OnePagerMultiComponent} from './detail/one-pager-multi/one-pager-multi.component';
import {OnePagerMultiHeadComponent} from './detail/one-pager-multi/one-pager-multi-head/one-pager-multi-head.component';
import {
  OnePagerMultiTargetProtocolComponent
} from './detail/one-pager-multi/one-pager-multi-target-protocol/one-pager-multi-target-protocol.component';
import {OnePagerImagesComponent} from '../shared/one-pager/one-pager-images/one-pager-images.component';
import {OnePagerConclusionsComponent} from '../shared/one-pager/one-pager-conclusions/one-pager-conclusions.component';
import {OnePagerMultiPreviewComponent} from './detail/one-pager-multi/one-pager-multi-preview/one-pager-multi-preview.component';
import {OnePagerContextComponent} from '../shared/one-pager/one-pager-context/one-pager-context.component';
import {OnePagerMultiResolver} from './detail/one-pager-multi/one-pager-multi.resolver';
import {DescriptorMultiComponent} from './detail/descriptors-multi/listDescriptors/descriptors-multi.component';
import {DescriptorMultiRootComponent} from './detail/descriptors-multi/descriptors-multi-root.component';
import {DescriptorMultiEditComponent} from './detail/descriptors-multi/editDescriptor/descriptor-multi-edit.component';
import {DescriptorsRowComponent} from './detail/descriptors-multi/editDescriptor/descriptors-row/descriptors-row.component';
import {
  ModalAutoCompleteDescriptor
} from './detail/descriptors-multi/listDescriptors/modalAutoComplete/modalAutoCompleteDescriptor.component';
import {AnalyseMultiService} from './detail/analyse-multi/analyse-multi.services';
import {AnalyseMultiReportComponent} from './detail/analyse-multi/reports/report-multi.component';
import {AnalyseMultiChartsComponent} from './detail/analyse-multi/editGraphs/charts-multi.component';
import {GraphicsModule} from '../campaigns/detail/edit-campaign/analyse/reports/chart-module';
import {CampaignsModule} from '../campaigns/campaigns.module';
import {UsersConfigComponent} from './detail/users-multi/users-config/users-config.component';

const appRoutes: Routes = [
  {
    path: 'multi-campaigns',
    component: MultiCampaignsComponent,
    canActivate: [AuthGuardService],
    data: {
      role: 'DNA_SCHEDULE'
    }
  },
  {
    path: 'multi-campaigns/:idCampaignMulti/analyse/reports',
    canActivate: [AuthGuardService],
    component: AnalyseMultiReportComponent,
    data: {
      role: 'DNA_SCHEDULE'
    }
  },
  {
    path: 'multi-campaigns/:idCampaignMulti',
    component: CampaignMultiRootComponent,
    canActivate: [AuthGuardService],
    data: {
      role: 'DNA_SCHEDULE'
    },
    children: [
      {
        path: '',
        redirectTo: 'generalInformation',
        pathMatch: 'full'
      },
      {
        path: 'generalInformation',
        component: GeneralInformationMultiComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'protocol',
        component: ProtocolMultiComponent,
      },
      {
        path: 'target',
        component: TargetMultiComponent,
      },
      {
        path: 'formulas',
        component: FormulasMultiComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'users',
        component: UsersMultiComponent,
      },
      {
        path: 'studies',
        component: StudiesMultiComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'workflows',
        component: WorkflowsMultiComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'analyse/editCharts',
        component: AnalyseMultiRootComponent,
        children: [
          {
            path: '',
            redirectTo: 'edit',
            pathMatch: 'full'
          },
          {
            path: 'edit',
            component: AnalyseMultiChartsComponent,
            canDeactivate: [AuthGuardService]
          }
        ]
      },
      {
        path: 'descriptors',
        component: DescriptorMultiRootComponent,
        children: [
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          },
          {
            path: 'list',
            component: DescriptorMultiComponent,
            canDeactivate: [AuthGuardService]
          },
          {
            path: ':idDescriptor/detail',
            component: DescriptorMultiEditComponent,
            canDeactivate: [AuthGuardService]
          }
        ]
      },
      {
        path: 'onepager',
        component: OnePagerMultiComponent,
        resolve: {
          onePager: OnePagerMultiResolver
        },
        children: [
          {
            path: '',
            redirectTo: 'head',
            pathMatch: 'full'
          },
          {
            path: 'head',
            component: OnePagerMultiHeadComponent,
            canDeactivate: [AuthGuardService]
          },
          {
            path: 'context',
            component: OnePagerContextComponent,
            canDeactivate: [AuthGuardService]
          },
          {
            path: 'target',
            component: OnePagerMultiTargetProtocolComponent,
            canDeactivate: [AuthGuardService]
          },
          {
            path: 'image',
            component: OnePagerImagesComponent,
            canDeactivate: [AuthGuardService]
          },
          {
            path: 'conclusion',
            component: OnePagerConclusionsComponent,
            canDeactivate: [AuthGuardService]
          },
          {
            path: 'preview',
            component: OnePagerMultiPreviewComponent,
            canDeactivate: [AuthGuardService]
          }
        ]
      }
    ]
  }
];

@NgModule({
  declarations: [
    MultiCampaignsComponent,
    MultiCampaignsRowComponent,
    CreateCampaignMultiModalComponent,
    DescriptorsRowComponent,
    GeneralInformationMultiComponent,
    WorkflowsMultiComponent,
    DescriptorMultiRootComponent,
    AnalyseMultiReportComponent,
    CampaignMultiRootComponent,
    StudiesMultiComponent,
    DescriptorMultiEditComponent,
    DescriptorMultiComponent,
    AnalyseMultiRootComponent,
    ModalAutoCompleteDescriptor,
    ProtocolMultiComponent,
    TargetMultiComponent,
    AnalyseMultiChartsComponent,
    FormulasMultiComponent,
    UsersMultiComponent,
    NgbdModalConfirmMultiStudyName,
    TargetMultiComponent,
    OnePagerMultiComponent,
    OnePagerMultiHeadComponent,
    OnePagerMultiTargetProtocolComponent,
    OnePagerMultiPreviewComponent,
    UsersConfigComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(appRoutes),
    GraphicsModule,
    SharedModule,
    CampaignsModule
  ],
  providers: [OnePagerMultiResolver, AnalyseMultiService]

})
export class MultiCampaignsModule {
}
