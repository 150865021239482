import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Metiers, TypeMetier } from '../../../types';

@Component({
  selector: 'dna-filters-protocol',
  templateUrl: './filters-protocol.component.html',
  styleUrls: ['./filters-protocol.component.less']
})
export class FiltersProtocolComponent implements OnInit {

  @Input() filter: any;
  @Input() metierName: TypeMetier;

  @Output() onUpdateFilterProtocol: EventEmitter<any> = new EventEmitter();

  metiers: typeof Metiers = Metiers;

  constructor() { }

  ngOnInit() {
  }

  updateCampaignsList(filter: any) {
    this.onUpdateFilterProtocol.emit(filter);
  }

}
