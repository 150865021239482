import { forkJoin as observableForkJoin, Observable } from 'rxjs';

import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import {
  AttributeData,
  FicheCaracterisation,
  Metiers,
  OnePager,
  Panelist,
  PDFTranslation,
  SextiFormula,
  TypeApplicationMode,
  TypeDryingType,
  TypeMetier,
  UserInCampaign,
  SimComp,
  RoutinesTab,
  QuantitiesByVolunteer,

} from '../../types';


import { DNATranslateService } from './translate.service';

import * as htmlToPdfmake from 'html-to-pdfmake';
import { CommonUtilsPDF } from '../commonUtilsPDF/commonUtilsPDF';

@Injectable()
export class GeneratePDFService {

  formsCaracterisation: FicheCaracterisation[] = [];
  formsCaracterisationHaveBeenSet = false;
  metiers: typeof Metiers = Metiers;
  panelists: Panelist[] = [];
  valuesFromAttributeDetails: string[][];
  currentLanguage: string;
  pdfReportUserByCol: boolean;
  private _commonUtilsPDF: CommonUtilsPDF;

  constructor(private dnaTranslateService: DNATranslateService) {
    this._commonUtilsPDF = new CommonUtilsPDF();
  }

  convertToString(array): string {
    return array.map(data => data.name).join(', ');
  }

  getContent(
    pdfReportUserByCol: boolean,
    metierName: TypeMetier,
    onePager: OnePager,
    routinesTab: RoutinesTab[],
    valuesFromRoutinesTranslations: object[],
    translations: PDFTranslation,
    imagesCapturesTab,
    displayProductsTable,
    formsCaracterisation,
    couples,
    visitsByVolunteer,
    confortTab,
    numberVolunteers,
    formulasName,
    // quantitiesByVolunteer: QuantitiesByVolunteer[]
  ): any[] {
    this.pdfReportUserByCol = pdfReportUserByCol;
    const experts: string[] = onePager.identification.expert.map((ex: UserInCampaign, index) => (index > 0 ? ', ' : '').concat(ex.name ? `${ex.name}` : `${ex}`));
    const manager: string[] = onePager.identification.manager.map((ex: UserInCampaign, index) => (index > 0 ? ', ' : '').concat(ex.name ? `${ex.name}` : `${ex}`));
    const reference: SextiFormula[] = onePager.formulas.reference;
    const tested: SextiFormula[] = onePager.formulas.tested;
    this.currentLanguage = this.dnaTranslateService.getLanguage();

    let content = [];
    content.push(
      {
        margin: [0, 0, 0, 10],
        table: {
          widths: [90, '*', 90, '*'],
          heights: 10,
          body: [
            [
              {
                text: translations.projectName,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: onePager.identification.projectName,
                style: ['response', 'textColor']
              },
              {
                text: translations.orchestra,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: onePager.identification.orchestra,
                style: ['response', 'textColor']
              }
            ],
            [
              {
                text: translations.bridge,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: onePager.identification.bridge,
                style: ['response', 'textColor']
              },
              {
                text: translations.manager,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: manager,
                style: ['response', 'textColor']
              }
            ],
            [
              {
                text: translations.actiview,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: onePager.identification.actiview,
                style: ['response', 'textColor']
              },
              {
                text: translations.expert,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: experts,
                style: ['response', 'textColor']
              },
            ],
            [
              {
                text: translations.hub,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {

                text: onePager.identification.hub,
                style: ['response', 'textColor']
              },
              {
                text: translations.laboratory,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: onePager.identification.laboratory,
                style: ['response', 'textColor']
              }
            ],
            [
              {
                text: translations.fieldwork,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: onePager.identification.fieldwork,
                style: ['response', 'textColor']
              },
              {
                text: translations.onePagerDate,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: onePager.identification.studyDate,
                style: ['response', 'textColor']
              }
            ]
          ]
        },
        layout: {
          hLineWidth: function (i, node) {
            return 1;
          },
          vLineWidth: function (i, node) {
            return 1;
          }
        }
      }
    );

    const targetProtocol = [];
    onePager.targetAssignment.forEach(
      item => {
        targetProtocol.push(
          [
            {
              text: _.replace(translations.numberOf, new RegExp('{{ element }}', 'g'), translations.substrate.toLowerCase()),
              fillColor: '#d6d8db',
              style: ['tableHeader', 'textColor']
            },
            {
              text: numberVolunteers + '/' + item.volunteersNumber,
              style: ['response', 'textColor']
            }
          ],
          [
            {
              text: translations.targets,
              fillColor: '#d6d8db',
              style: ['tableHeader', 'textColor']
            },
            {
              text: item.target,
              style: ['response', 'textColor']
            }
          ]
        );
      }
    );

    content.push(
      {
        margin: [0, 10, 0, 0],
        table: {
          widths: [90, '*'],
          heights: 10,
          body: [
            [
              {
                text: translations.onePagerContext,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              this.formatRichText(onePager.context)
            ],
            [
              {
                text: translations.objective,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              this.formatRichText(onePager.objectives)
            ],
            [
              {
                text: translations.kpa,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                columns: [
                  {
                    table: {
                      heights: 25,
                      widths: [80, '*'],
                      body: [
                        [
                          {
                            text: 'KPA',
                            fillColor: '#ED75F7',
                            style: ['tableHeader', 'textColor']
                          },
                          {
                            text: onePager.kpa.attributes,
                            style: ['response', 'textColor']
                          }
                        ],
                        [
                          {
                            text: 'Category attributes',
                            fillColor: '#ECE8D4',
                            style: ['tableHeader', 'textColor']
                          },
                          {
                            text: onePager.kpa.categories,
                            style: ['response', 'textColor']
                          }
                        ],
                        [
                          {
                            text: 'Must haves',
                            fillColor: '#C1C0BE',
                            style: ['tableHeader', 'textColor']
                          },
                          {
                            text: onePager.kpa.mustHaves,
                            style: ['response', 'textColor']
                          }
                        ]
                      ]
                    },
                    layout: 'noBorders',
                    margin: [0, 0, 0, 0]
                  }
                ]
              }
            ],
            ...targetProtocol,
            [
              {
                text: translations.protocol,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              this.formatRichText(onePager.protocol)
            ]
          ]
        },
        layout: {
          hLineWidth: function (i, node) {
            return 1;
          },
          vLineWidth: function (i, node) {
            return 1;
          }
        }
      }
    );

    const testedProducts = [];
    tested.forEach(
      (tes: SextiFormula) => {
        testedProducts.push(
          [
            {
              text: (`${tes.routineLabel}` !== '' && !_.isUndefined(tes.routineLabel)) ? `${tes.routineName} - ${tes.routineLabel}` : `${tes.routineName}`,
              style: ['response', 'textColor']
            },
            {
              text: `${tes.visitName}`,
              style: ['response', 'textColor']
            },
            {
              text: `${tes.formulaName}`,
              style: ['response', 'textColor']
            },
            {
              text: `${tes.productName}`,
              style: ['response', 'textColor']
            },
            {
              text: `${tes.lot || ''}`,
              style: ['response', 'textColor']
            },
            {
              text: `${tes.socle || ''}`,
              style: ['response', 'textColor']
            },
          ]
        );
      }
    );
    const couplesTab = [];
    couples.filter(couple => couple.isActive).forEach(couple => {
      const lab = couple.lab.reduce(
        (acc: string, curr: SimComp) => curr.name ? (acc.length > 0 ? `${acc}, ${curr.name}` : curr.name) : acc,
        ''
      );
      couplesTab.push(
        [{
          text: lab,
          style: ['response', 'textColor']
        },
        {
          text: couple.bench.name,
          style: ['response', 'textColor']
        }
        ]
      );
    });

    const versusBareSkinTab = [];
    formulasName.forEach(formulaName => {
      versusBareSkinTab.push(
        [{
          text: formulaName,
          style: ['response', 'textColor']
        },
        {
          text: translations.versusBareSkin,
          style: ['response', 'textColor']
        }
        ]
      );
    });
    const referenceProducts = [];
    reference.forEach(
      (ref: SextiFormula) => {
        referenceProducts.push(
          [
            {
              text: (`${ref.routineLabel}` !== '' && !_.isUndefined(ref.routineLabel)) ? `${ref.routineName} - ${ref.routineLabel}` : `${ref.routineName}`,
              style: ['response', 'textColor']
            },
            {
              text: `${ref.visitName}`,
              style: ['response', 'textColor']
            },
            {
              text: `${ref.formulaName}`,
              style: ['response', 'textColor']
            },
            {
              text: `${ref.productName}`,
              style: ['response', 'textColor']
            },
            {
              text: `${ref.lot || ''}`,
              style: ['response', 'textColor']
            },
            {
              text: `${ref.socle || ''}`,
              style: ['response', 'textColor']
            },
          ]
        );
      }
    );

    const tabTested = {
      widths: ['*', 'auto', 'auto', '*', 'auto', 'auto'],
      body: [
        [
          {
            colSpan: 6,
            alignment: 'center',
            text: translations.onePagerTested,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          }, {}, {}, {}, {}, {}
        ],
        [
          {
            text: translations.routine,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          },
          {
            text: translations.visit,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          },
          {
            text: translations.formula,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          },
          {
            text: translations.routineProductsNames,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          },
          {
            text: translations.routineBatch,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          },
          {
            text: translations.socle,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          }
        ],
        ...testedProducts
      ]
    };

    const tabReference = {
      widths: ['*', 'auto', 'auto', '*', 'auto', 'auto'],
      body: [
        [
          {
            colSpan: 6,
            alignment: 'center',
            text: translations.onePagerReference,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          }, {}, {}, {}, {}, {}
        ],
        [
          {
            text: translations.routine,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          },
          {
            text: translations.visit,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          },
          {
            text: translations.formula,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          },
          {
            text: translations.routineProductsNames,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          },
          {
            text: translations.routineBatch,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          },
          {
            text: translations.socle,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          }
        ],
        ...referenceProducts
      ]
    };

    const tabCouples = {
      widths: ['auto', 'auto'],
      body: [
        [
          {
            colSpan: 2,
            alignment: 'center',
            text: translations.studiedCouples,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          }, {}
        ],
        [
          {
            text: translations.onePagerTested,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          },
          {
            text: translations.onePagerReference,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          }
        ],
        ...couplesTab
      ]
    };
    const tabVersusBareSkin = {
      widths: ['auto', 'auto'],
      body: [
        [
          {
            colSpan: 2,
            alignment: 'center',
            text: translations.studiedCouples,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          }, {}
        ],
        [
          {
            text: translations.onePagerTested,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          },
          {
            text: translations.onePagerReference,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          }
        ],
        ...versusBareSkinTab
      ]
    };
    if(!displayProductsTable){
      content.push(
        {
          margin: [0, 20, 1, 20],
          table: tabVersusBareSkin
        }
      )
    }
    if (displayProductsTable) {
      if(tested && tested.length === 0 && referenceProducts && referenceProducts.length === 0){
        content.push(
          {
            margin: [0, 20, 1, 20],
            table: tabCouples
          }
        )
      }
      if(tested && tested.length > 0){
        content.push(
          {
            margin: [0, 20, 0, 10],
            table: tabTested
          }
        );
      }
      if(referenceProducts && referenceProducts.length > 0){
        content.push(
          {
            margin: [0, 10, 0, 20],
            table: tabReference
          }
        );
      }

    }

    content.push(
      {
        table: {
          widths: [90, '*'],
          heights: 10,
          body: [
            [
              {
                text: translations.conclusions,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              this.formatRichText(onePager.conclusion)
            ],
            [
              {
                text: translations.otherStrengths,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              this.formatRichText(onePager.otherStrengths)
            ],
            [
              {
                text: translations.onePagerVigilance,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              this.formatRichText(onePager.vigilance)
            ],
            [
              {
                text: translations.onePagerRecommandation,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              this.formatRichText(onePager.recommendation)
            ]
          ]
        },
        layout: {
          hLineWidth: function (i, node) {
            return 1;
          },
          vLineWidth: function (i, node) {
            return 1;
          }
        }
      }
    );

    if (!_.isEmpty(routinesTab)) {
      content.push({
        pageBreak: 'before',
        pageOrientation: 'landscape',
        text: translations.routines,
      });


      let rows;
      routinesTab.forEach((routine: any) => {
        rows = [];
        const tabRoutines = routine.routineDatas;
        const routinesInactiveColumns = this.getRoutineTableHead(tabRoutines);
        const routinesActiveColumns = _.keys(tabRoutines[0]).filter(key => !routinesInactiveColumns.includes(key));
        const routineHead = [];

        if (routinesActiveColumns.includes('visit')) {
          routineHead.push({
            text: translations.routineVisit,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          });
        }
        if (routinesActiveColumns.includes('orderName')) {
          routineHead.push({
            text: translations.order,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          });
        }
        if (routinesActiveColumns.includes('shades')) {
          routineHead.push({
            text: translations.routineShades,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          });
        }
        if (routinesActiveColumns.includes('evaluation')) {
          routineHead.push({
            text: translations.evaluation,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          });
        }
        if (routinesActiveColumns.includes('stepName')) {
          routineHead.push({
            text: translations.steps,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          });
        }
        if (routinesActiveColumns.includes('productName')) {
          routineHead.push({
            text: translations.routineProductsNames,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          });
        }
        if (routinesActiveColumns.includes('categorie')) {
          routineHead.push({
            text: translations.categories,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          });
        }
        if (routinesActiveColumns.includes('formulaName')) {
          routineHead.push({
            text: translations.formula,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          });
        }
        if (routinesActiveColumns.includes('lot')) {
          routineHead.push({
            text: translations.routineBatch,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          });
        }
        if (routinesActiveColumns.includes('quantity')) {
          routineHead.push({
            text: translations.routineQuantities,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          });
        }
        if (routinesActiveColumns.includes('quantitiesByVolunteer')) {
          routineHead.push({
            text: translations.routineQuantitiesByVolunteer,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          });
        }
        if (routinesActiveColumns.includes('applicationMode')) {
          routineHead.push({
            text: translations.applicationModes,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          });
        }
        if (routinesActiveColumns.includes('pauseTime')) {
          routineHead.push({
            text: translations.routinePauseTime,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          });
        }
        if (routinesActiveColumns.includes('initialPauseTime')) {
          routineHead.push({
            text: translations.routinePauseTime,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          });
        }
        rows.push(routineHead);


        switch (metierName) {
          case this.metiers.Hair: {
            if (routinesActiveColumns.includes('applyTo')) {
              rows[0].push({
                text: translations.routineApplyTo,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            if (routinesActiveColumns.includes('dryingTypes')) {
              rows[0].push({
                text: translations.dryingTypes,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            break;
          }
          case this.metiers.Skin: {
            if (routinesActiveColumns.includes('timeDrying')) {
              rows[0].push({
                text: translations.timeDrying,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            break;
          }
        }

        tabRoutines.forEach((tr, indexTr) => {
          const nbr = indexTr + 1;
          const routineValues = [];
          if (routinesActiveColumns.includes('visit')) {
            routineValues.push({
              text: tr.visit,
              style: ['response', 'textColor']
            });
          }
          if (routinesActiveColumns.includes('orderName')) {
            routineValues.push({
              text: tr.orderName,
              style: ['response', 'textColor']
            });
          }
          if (routinesActiveColumns.includes('shades')) {
            routineValues.push({
              text: tr.shades,
              style: ['response', 'textColor']
            });
          }
          if (routinesActiveColumns.includes('evaluation')) {
            routineValues.push({
              text: tr.evaluation,
              style: ['response', 'textColor']
            });
          }
          if (routinesActiveColumns.includes('stepName')) {
            routineValues.push({
              text: tr.stepName,
              style: ['response', 'textColor']
            });
          }
          if (routinesActiveColumns.includes('productName')) {
            routineValues.push({
              text: tr.productName,
              style: ['response', 'textColor']
            });
          }
          if (routinesActiveColumns.includes('categorie')) {
            routineValues.push({
              text: function () {
                if (valuesFromRoutinesTranslations) {
                  const translation = valuesFromRoutinesTranslations.find((v: object) => v['key'] === tr.categorie);
                  if (translation) {
                    return translation['value'];
                  }
                } else {
                  return '';
                }
              }(),
              style: ['response', 'textColor']
            });
          }
          if (routinesActiveColumns.includes('formulaName')) {
            routineValues.push({
              text: tr.formulaName === 'NO_FORMULA' ? translations.noFormula : tr.formulaName,
              style: ['response', 'textColor']
            });
          }
          if (routinesActiveColumns.includes('lot')) {
            routineValues.push({
              text: tr.lot,
              style: ['response', 'textColor']
            });
          }
          if (routinesActiveColumns.includes('quantity')) {
            routineValues.push({
              text: tr.quantity,
              style: ['response', 'textColor']
            });
          }
          if (routinesActiveColumns.includes('quantitiesByVolunteer')) {
            let text = '';
            tr.quantitiesByVolunteer.forEach((elt, index) => {
              if (elt.quantity || elt.quantity !== "") {
                text = index === tr.quantitiesByVolunteer.length - 1
                  ? text = text.concat(elt.volunteerName + ' : ' + elt.quantity)
                  : text.concat(elt.volunteerName + ' : ' + elt.quantity) + '\n';
              }
            });
            routineValues.push({
              text: text,
              style: ['response', 'textColor']
            });
          }
          if (routinesActiveColumns.includes('applicationMode')) {
            routineValues.push({
              text: function () {
                if (valuesFromRoutinesTranslations) {
                  let applicationModeTranslations = _.get(tr, 'applicationMode', []);
                  if (!_.isEmpty(translations)) {
                    applicationModeTranslations = applicationModeTranslations.map((applicator: TypeApplicationMode) => valuesFromRoutinesTranslations.find((v: object) => v['key'] === applicator.key));
                  }
                  return applicationModeTranslations.length > 0
                    ? applicationModeTranslations.map((translation: object) => translation['value']).join(', ')
                    : '';
                } else {
                  return '';
                }
              }(),
              style: ['response', 'textColor']
            });
          }
          if (routinesActiveColumns.includes('pauseTime')) {
            let pauseTime = '';
            tr.pauseTime.forEach((elt, index) => {
              if (elt.isStarted) {
                const timeFormat = !_.isUndefined(_.get(elt, 'pauseTime.hour')) ? `${elt.pauseTime.hour} : ${elt.pauseTime.minute} : ${elt.pauseTime.second}` : elt.pauseTime;
                pauseTime = index === tr.pauseTime.length - 1
                  ? pauseTime = pauseTime.concat(elt.volunteerName + ' : ' + timeFormat)
                  : pauseTime = pauseTime.concat(elt.volunteerName + ' : ' + timeFormat + '\n');
              }
            });
            routineValues.push({
              text: pauseTime,
              style: ['response', 'textColor']
            });
          }
          if (routinesActiveColumns.includes('initialPauseTime')) {
            let initialPauseTimeStrings = [];
            tr.quantitiesByVolunteer.forEach(volunteer => {
                if (volunteer.pauseTime) {
                    const { hour, minute, second } = volunteer.pauseTime;

                    const hourString = `${hour || 0}`;
                    const minuteString = `${minute || 0}`;
                    const secondString = `${second || 0}`;

                    const timeString = [hourString, minuteString, secondString].join(':');
                    initialPauseTimeStrings.push(`${volunteer.volunteerName} : ${timeString}`);
                  }
            });
            routineValues.push({
                text: initialPauseTimeStrings.join('\n'),
                style: ['response', 'textColor']
            });
        }


          rows.push(routineValues);

          switch (metierName) {
            case this.metiers.Hair: {
              if (routinesActiveColumns.includes('applyTo')) {
                rows[nbr].push({
                  text: function () {
                    if (valuesFromRoutinesTranslations) {
                      const translationApplyTo = valuesFromRoutinesTranslations.find((v) => v['key'] === tr.applyTo);
                      return translationApplyTo ? translationApplyTo['value'] : '';
                    } else {
                      return '';
                    }
                  }(),
                  style: ['response', 'textColor']
                });
              }
              if (routinesActiveColumns.includes('dryingTypes')) {
                rows[nbr].push({
                  text: function () {
                    if (valuesFromRoutinesTranslations) {
                      const translations = _.get(tr, 'dryingTypes', [])
                        .map((dryingType: TypeDryingType) => valuesFromRoutinesTranslations.find((v: object) => v['key'] === dryingType.key));

                      return translations.length > 0
                        ? translations.map((translation: object) => translation['value']).join(', ')
                        : '';
                    } else {
                      return '';
                    }
                  }(),
                  style: ['response', 'textColor']
                });
              }
              break;
            }
            case this.metiers.Skin: {
              if (routinesActiveColumns.includes('timeDrying')) {
                rows[nbr].push({
                  text: tr.timeDrying,
                  style: ['response', 'textColor']
                });
              }
              break;
            }
          }
        });

        const contentTableRoutineWidth = [];
        const bodyRoutine = [];

        routinesActiveColumns.forEach((key, index) => {
          if (key === 'pauseTime') {
            contentTableRoutineWidth.push(105);
          } else {
            contentTableRoutineWidth.push('auto');
          }
          if (index === 0) {
            bodyRoutine.push(
              {
                colSpan: routinesActiveColumns.length,
                alignment: 'center',
                text: function () {
                  return _.isEmpty(routine.label) ? `${routine.name}` : `${routine.name} - ${routine.label}`;
                }(),
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              }
            );
          } else {
            bodyRoutine.push({});
          }
        });
        content.push({
          margin: [0, 10, 0, 10],
          table: {
            widths: contentTableRoutineWidth,
            heights: 10,
            body: [
              bodyRoutine,
              ...rows
            ]
          }
        });
      });
    }

    if (!_.isEmpty(visitsByVolunteer)) {
      content.push({
        pageBreak: 'before',
        pageOrientation: 'portrait',
        text: translations.visitVolTitle,
      });
      const visitsRows = [];
      const contentTableVisitsWidth = !_.has(visitsByVolunteer[0], 'workflowName') ? ['auto'] : ['auto', 'auto'];
      const visitsHeader = !_.has(visitsByVolunteer[0], 'workflowName') ? [{
        text: translations.volunteer,
        fillColor: '#d6d8db',
        style: ['tableHeader', 'textColor']
      }]
        : [{
          text: translations.workflow,
          fillColor: '#d6d8db',
          style: ['tableHeader', 'textColor']
        },
        {
          text: translations.volunteer,
          fillColor: '#d6d8db',
          style: ['tableHeader', 'textColor']
        },
        ];
      visitsByVolunteer[0].visits.forEach(visit => {
        contentTableVisitsWidth.push('auto');
        visitsHeader.push({
          text: `${translations.visit} : ${visit.name}`,
          fillColor: '#d6d8db',
          style: ['tableHeader', 'textColor']
        });
      });
      visitsRows.push(visitsHeader);
      visitsByVolunteer.forEach(value => {
        const visitValues = !_.has(value, 'workflowName') ? [{
          text: value.volunteerName,
          fillColor: '#d6d8db',
          style: ['tableHeader', 'textColor']
        }]
          : [{
            text: value.workflowName,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          },
          {
            text: value.volunteerName,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          }];
        value.visits.forEach(visit => {
          visitValues.push({
            text: visit.startDate === 'NOT_STARTED' ? translations.notStarted : new Date(visit.startDate).toLocaleDateString(),
            fillColor: 'white',
            style: ['response', 'textColor']
          });
        });
        visitsRows.push(visitValues);
      });

      content.push({
        margin: [0, 20, 0, 20],
        table: {
          widths: contentTableVisitsWidth,
          heights: 10,
          body: [
            ...visitsRows
          ]
        }
      });
    }

    if (!_.isEmpty(confortTab)) {
      const contentConfort = [];
      const header = [{
        text: '',
        fillColor: '#d6d8db',
        style: ['tableHeader']
      },
      {
        text: 'Test',
        fillColor: '#d6d8db',
        style: ['tableHeader', 'textColor']
      },
      {
        text: 'Valeur de p',
        fillColor: '#d6d8db',
        style: ['tableHeader', 'textColor']
      }
      ];
      contentConfort.push(header);
      confortTab.forEach((c, index) => {
        const row = [{
          text: index < 4 ? translations[c.name] : c.name,
          fillColor: '#d6d8db',
          style: ['tableHeaderConfort', 'textColor']
        },
        {
          text: c.test,
          fillColor: 'white',
          style: ['response', 'textColor']
        },
        {
          text: c.pvalue,
          fillColor: 'white',
          style: this.getPValueStyle(c.pvalue)
        }
        ];
        contentConfort.push(row);
        if (index === 3) {
          contentConfort.push([{
            text: translations.confortCumul,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          },
          {
            text: 'Test',
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          },
          {
            text: 'Valeur de p',
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          }
          ]);
        }
      });
    }


    imagesCapturesTab.forEach((i) => {
      content.push({
        image: i.data,
        pageBreak: 'before',
        pageOrientation: i.orientation.id === 0 ? 'landscape' : 'portrait',
        fit: i.orientation.id === 0 ? [800, 800] : [590, 1300],
        alignment: 'center'
      });
    });

    if (onePager.images.find(el => el.url.endsWith('noodleClassification'))){
      content.push({
        text: 'Legend',
        margin: [0, 10, 0, 5],
        style: ['tableHeader', 'textColor'],
        decoration: 'underline'
      },
    {
      margin: [0, 10, 0, 20],
      table: {
        widths: ['20%', '30%', '30%', '20%'],
        body: [
          [
            {
              text: 'Mean',
              fillColor: '#d6d8db',
              style: ['tableHeader', 'textColor']
            },
            {
              text: 'New Classification',
              fillColor: '#d6d8db',
              style: ['tableHeader', 'textColor']
            },
            {
              text: 'Conclusion',
              fillColor: '#d6d8db',
              style: ['tableHeader', 'textColor']
            },
            {
              text: 'Recommendation',
              fillColor: '#d6d8db',
              style: ['tableHeader', 'textColor']
            }
          ],
          [
            {
              text: '0-1.49',
              style: ['response', 'textColor']
            },
            {
              text: '',
              fillColor: '#f7dc6f',
              style: ['response']
            },
            {
              text: 'Noodle level of Formula XXX is Low to Medium.\nModels showed 0-1-2 grade of noodle, which is mostly considered as "not so annoying to sometimes annoying" (based on consumer test).',
              style: ['response', 'textColor']
            },
            {
              text: 'Go to Evaluation plan next step considering there is a residual risk due to sample size or specific usage condition or change of formula',
              style: ['response', 'textColor']
            }
          ],
          [
            {
              text: '1.50+',
              style: ['response', 'textColor']
            },
            {
              text: '',
              fillColor: '#e74c3c',
              style: ['response']
            },
            {
              text: 'Noodle level of Formula XXX is High.\nModels showed 2-3 grade of noodle, which is mostly considered as annoying.',
              style: ['response', 'textColor']
            },
            {
              text: 'To be reformulated',
              style: ['response', 'textColor']
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function () {
          return 1;
        },
        vLineWidth: function () {
          return 1;
        },
        hLineColor: function () {
          return '#d6d8db';
        },
        vLineColor: function () {
          return '#d6d8db';
        }
      }
    }
  )
    }


    if (!Array.isArray(formsCaracterisation)) {
      console.error('formsCaracterisation is not an array:', formsCaracterisation);
  }


    if (!_.isEmpty(formsCaracterisation) && formsCaracterisation.some(fc => !_.isEmpty(fc.AttributeData))) {
      const maxColAllowed = 6;
      const maxRowsBeforePageBreak = 11;
      const divide = _.chunk(formsCaracterisation, maxColAllowed);
      let shouldBreak = false;
      // For each group, split recursively the array based on the length of each value traversed
      divide.forEach(panelListGroup => {
        const panelListTable = this.buildPanelListTable(panelListGroup, translations, maxColAllowed, this.pdfReportUserByCol);
        if (panelListTable.length) {
          const sum = [];
          const recursSplit = (arrayToSplit) => {
            const split = [];
            arrayToSplit.forEach((value, index) => {
              if (value.length >= maxColAllowed) {
                split.push([...value.slice(0, maxColAllowed + 1)]);
                arrayToSplit[index].splice(0, maxColAllowed + 1);
                shouldBreak = false;
              } else {
                split.push([...value]);
                shouldBreak = true;
              }
            });
            sum.push(split);
            if (!shouldBreak) {
              recursSplit(arrayToSplit);
            }
          };
          recursSplit(panelListTable);
          sum.forEach((table, index) => {
            content.push(
              {
                pageBreak: sum[0].length > maxRowsBeforePageBreak || index === 0 ? 'before' : '',
                pageOrientation: 'landscape',
                text: ''
              },
              {
                margin: [0, 0, 0, 20],
                table: {
                  heights: 10,
                  body: table
                }
              }
            );
          });
        }
      });
    }

    if (!_.isEmpty(confortTab)) {
      content.push({
        pageBreak: 'before',
        pageOrientation: 'portrait',
        text: translations.confortTitle
      });
      const contentConfortByTime = [];
      contentConfortByTime.push([
        {
          text: "",
          fillColor: '#d6d8db',
          style: ['tableHeader']
        },
        {
          colSpan: 2,
          alignment: 'center',
          text: translations.confortCumul,
          fillColor: '#d6d8db',
          style: ['tableHeader', 'textColor']
        }, {},
        {
          colSpan: 2,
          alignment: 'center',
          text: translations.confortEch,
          fillColor: '#d6d8db',
          style: ['tableHeader', 'textColor']
        }, {},
        {
          colSpan: 2,
          alignment: 'center',
          text: translations.confortDem,
          fillColor: '#d6d8db',
          style: ['tableHeader', 'textColor']
        }, {},
        {
          colSpan: 2,
          alignment: 'center',
          text: translations.confortPic,
          fillColor: '#d6d8db',
          style: ['tableHeader', 'textColor']
        }, {}
      ]);

      contentConfortByTime.push([{
        text: "",
        fillColor: '#d6d8db',
        style: ['tableHeader']
      }, {
        text: 'Test',
        fillColor: '#d6d8db',
        style: ['tableHeader', 'textColor']
      },
      {
        text: translations.pvalue,
        fillColor: '#d6d8db',
        style: ['tableHeader', 'textColor']
      }, {
        text: 'Test',
        fillColor: '#d6d8db',
        style: ['tableHeader', 'textColor']
      },
      {
        text: translations.pvalue,
        fillColor: '#d6d8db',
        style: ['tableHeader', 'textColor']
      }, {
        text: 'Test',
        fillColor: '#d6d8db',
        style: ['tableHeader', 'textColor']
      },
      {
        text: translations.pvalue,
        fillColor: '#d6d8db',
        style: ['tableHeader', 'textColor']
      }, {
        text: 'Test',
        fillColor: '#d6d8db',
        style: ['tableHeader', 'textColor']
      },
      {
        text: translations.pvalue,
        fillColor: '#d6d8db',
        style: ['tableHeader', 'textColor']
      }]);
      const nbCriteria = 4;
      confortTab.forEach(elementConfort => {
        const row = [];
        const elementConfortName = translations[elementConfort.name] || elementConfort.name
        row.push({
          text: elementConfortName[0].toUpperCase() + elementConfortName.slice(1) ,
          fillColor: '#d6d8db',
          style: ['tableHeaderConfort', 'textColor']
        })
        if (elementConfort.name !== 'confortEry') {
          for (let i = 0; i < nbCriteria; i++) {
            if (elementConfort.values[i]) {
              row.push(
                {
                  text: elementConfort.values[i].test,
                  fillColor: 'white',
                  style: ['response', 'textColor']
                },
                {
                  text: elementConfort.values[i].pvalue,
                  fillColor: 'white',
                  style: this.getPValueStyle(elementConfort.values[i].pvalue)
                }
              )

            } else {
              row.push(
                {
                  colSpan: 2,
                  text: '',
                  fillColor: '#d6d8db',
                }, {}
              )
            }
          }
        } else {
          row.push(
            {
              text: elementConfort.test,
              fillColor: 'white',
              style: ['response', 'textColor']
            },
            {
              text: elementConfort.pvalue,
              fillColor: 'white',
              style: this.getPValueStyle(elementConfort.pvalue)
            },
            {
              colSpan: 6,
              text: '',
              fillColor: '#d6d8db',
            }, {}, {}, {}, {}, {}
          )
        }
        contentConfortByTime.push(row);
      });

      content.push({
        margin: [0, -1, 0, 20],
        table: {
          widths: ['40%', '5%', '10%', '5%', '10%', '5%', '10%', '5%', '10%'],
          heights: 10,
          body: [
            ...contentConfortByTime
          ]
        }
      });
      const legend = [
        {
          text: translations.confortLegendBold,
          style: ['response']
        },
        {
          text: translations.confortLegendItalic,
          style: ['response', 'textItalic', 'textColor']
        },
        { text: "" },
        {
          text: translations.confortLegendP,
          style: ['response', 'textColor']
        },
        {
          text: translations.confortLegendNP,
          style: ['response', 'textColor']
        }
      ];
      content = content.concat(legend);
    }

    return content;
  }

  getStyles(): any {
    return {
      appendices: {
        fontSize: 24,
        alignment: 'center',
        margin: [0, 20, 0, 10]
      },
      name: {
        fontSize: 12,
        margin: [0, 0, 0, 10]
      },
      order: {
        margin: [10, 0, 0, 10]
      },
      step: {
        margin: [15, 0, 0, 10]
      },
      visit: {
        margin: [5, 0, 0, 10]
      },
      response: {
        fontSize: 8,
        alignment: 'justify'
      },
      tableHeader: {
        fontSize: 8,
        bold: true,
        margin: [2, 0, 0, 0]
      },
      tableHeaderConfort: {
        fontSize: 8,
        margin: [2, 0, 0, 0]
      },
      textColor: {
        color: '#676a6c'
      },
      textBold: {
        bold: true
      },
      textItalic: {
        italics: true
      },
      title: {
        fontSize: 26,
        margin: [0, 0, 0, 5]
      },
      subTitle: {
        fontSize: 16,
        bold: true,
        margin: [0, 20, 0, 15]
      },
      routineTitle: {
        fontSize: 14
      }
    };
  }

  getTranslation(text: any): Observable<string> {
    return typeof text === 'string'
      ? this.dnaTranslateService.translateMessage(text)
      : this.dnaTranslateService.translateMessage(text.sentence, { element: text.type });
  }

  getTranslations(pdfTranslations?: PDFTranslation): Observable<PDFTranslation> {
    const messages = pdfTranslations ? pdfTranslations : new PDFTranslation();
    const observables: Observable<string>[] = [];
    let translation$: Observable<string>;

    _.forOwn(messages, (value, key) => {
      translation$ = this.getTranslation(value).pipe(tap((trad: string) => messages[key] = trad));
      observables.push(translation$);
    });

    return observableForkJoin(observables).pipe(map(() => messages));
  }

  getRoutineTableHead(tableRoutine: any[]) {
    let routinetableHead = [];
    tableRoutine.forEach((row, index) => {
      routinetableHead = index === 0 ? this.getKeysRoutine(row) : _.intersection(this.getKeysRoutine(row), routinetableHead);
    });
    return routinetableHead;
  }

  getKeysRoutine(row) {
    return _.keys(_.pickBy(row, (value, key) => _.isUndefined(value)
      // || (key === 'quantity' && _.every(value, ['isStarted', false])) // TODO : à revoir si utilité de la ligne, si l'utilisateur n'a pas commencé la routine, il n'y a pas de quantité
      // || (key === 'quantitiesByVolunteer' && _.every(value, ['isStarted', false]))
      || (key === 'pauseTime' && (_.isEmpty(value) || value === 0 || (_.get(value, 'hour') ===
        0 && _.get(value, 'minute') === 0 && _.get(value, 'second') === 0)))
      || (key !== 'pauseTime' && _.isNumber(value) && value === 0)
      || (key !== 'pauseTime' && key !== 'initialPauseTime' && !_.isNumber(value) && _.get(value, 'length', 0) === 0)
    ));
  }

  /**
   * Construction du tableau des pannelistes
   * filtre du tableau sur les lignes vides
   * @param formsCharacterisation
   * @param translations
   * @param maxCol
   * @param pdfReportUserByCol
   */

  buildPanelListTable(formsCharacterisation, translations, maxCol: number, pdfReportUserByCol): any[] {
    let tab = [];
    const header = [];
    const rows = [];

    if (pdfReportUserByCol) {
        // Construire l'en-tête avec les numéros des volontaires
        header.push({
            text: translations.attributes,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
        });
        formsCharacterisation.forEach((attribute) => {
            header.push({
                text: attribute.panelistNumber,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
            });
        })
        tab.push(header);

        // Construire les lignes avec les données d'attribut
        const maxAttributeDataLength = Math.max(...formsCharacterisation.map(attr => attr.AttributeData.length));
        for (let i = 0; i < maxAttributeDataLength; i++) {
            const dataRow = [];
            dataRow.push({
              text: formsCharacterisation[0].AttributeData[i] ? formsCharacterisation[0].AttributeData[i].AttributeName : '',
              fillColor: '#d6d8db',
              style: ['response', 'textColor']
            });
            formsCharacterisation.forEach((attribute) => {
                dataRow.push({
                    text: this._commonUtilsPDF.dataValueHandler(attribute.AttributeData[i]),
                    style: ['response', 'textColor']
                });
            });
            tab.push(dataRow);
        }
    } else {
        header.push({
            text: translations.attributes,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
        });
        const attributeRow = {};
        formsCharacterisation.forEach((attribute) => {
            attribute.AttributeData.forEach((data) => {
                if (!attributeRow[data.AttributeID]) {
                    attributeRow[data.AttributeID] = [{
                        text: data.AttributeName,
                        fillColor: '#d6d8db',
                        style: ['response', 'textColor']
                    }];
                }
                const textData = this._commonUtilsPDF.dataValueHandler(data);
                attributeRow[data.AttributeID].push({
                    text: textData,
                    style: ['response', 'textColor']
                });
            });
            header.push({
                text: attribute.panelistNumber,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
            });
            rows.push(attributeRow);
        });
        tab.push(header);
        // Pushing each data line one after the other
        for (const value of Object.entries(attributeRow)) {
            tab.push(value[1]);
        }
        this.filterEmptyRowsByColumns(tab);
    }
    return tab;
}


  /**
 * Méthode pour supprimer du tableau les lignes pour lesquelles
 * il n'y a aucune réponse => mode volontaire par ligne
 * @param pannelistTab
 */
  filterEmptyRowsByLines(pannelistTab, translations) {
    const volunteerTab = _.cloneDeep(pannelistTab);
    volunteerTab.shift();
    if (pannelistTab.length) {
      const nbCharac = pannelistTab[0].length;
      for (let i = nbCharac - 1; i > -1; i--) {
        if (volunteerTab.every(p => p[i].text === '')) {
          pannelistTab.forEach(p => {
            p.splice(i, 1);
          })
        }
      }
    }
    return pannelistTab[0].length === 1 && pannelistTab[0][0].text === translations.attributes ? [] : pannelistTab;
  }

  /**
   * Méthode pour supprimer du tableau les lignes pour lesquelles
   * il n'y a aucune réponse => mode volontaire par colonnes
   * @param pannelistTab
   */
  filterEmptyRowsByColumns(pannelistTab) {
    _.remove(pannelistTab, function (ligne) {
      return ligne.every(function (element, index) {
        return index !== 0
          ? element.text === ''
          : true;
      });
    });
  }

  /**
   * La conclusion (en html) est converti en un objet compatible avec pdfMake
   * @param conclusion
   * @return formatedConclusion: un tableau d'objets avec des texts/images et leur style
   */
  formatRichText(richText: string): any[] {
    let formatedRichText = [];
    if (!_.isNil(richText)) {
      if (!richText.startsWith('<')) {
        richText = '<p>' + richText + '</p>';
      }
      formatedRichText = htmlToPdfmake(richText);
      formatedRichText.map((obj) => {
        this.formatStyleRichtext(obj);
        if (_.has(obj, 'text') && Array.isArray(obj.text)) {
          obj.text.map(o => this.formatStyleRichtext(o));
        }
      });
    }
    return formatedRichText;
  }

  formatStyleRichtext(obj) {
    // font size
    if (_.get(obj, 'style', []).includes('ql-size-small')) {
      _.set(obj, 'fontSize', 6);
    } else if (_.get(obj, 'style', []).includes('ql-size-large')) {
      _.set(obj, 'fontSize', 10);
    } else if (_.get(obj, 'style', []).includes('ql-size-huge')) {
      _.set(obj, 'fontSize', 12);
    } else {
      _.set(obj, 'fontSize', 8);
    }
    // marge
    if (_.get(obj, 'style', []).includes('html-p')) {
      _.set(obj, 'margin', [0, 0, 0, 0]);
    }
    // color
    const color = _.get(obj, 'color', '');
    if (color === '#000000' || color === '' || color === 'black') {
      _.set(obj, 'color', '#676a6c');
    }
    // alignement
    if (_.get(obj, 'style', []).includes('ql-align-right')) {
      _.set(obj, 'alignment', 'right');
    }
    if (_.get(obj, 'style', []).includes('ql-align-center')) {
      _.set(obj, 'alignment', 'center');
    }
  }

  /********** GETTERS & SETTERS **********/

  getFormsCaracterisation(): FicheCaracterisation[] {
    return _.cloneDeep(this.formsCaracterisation);
  }

  setFormsCaracterisation(formsCaracterisation: FicheCaracterisation[]) {
    this.formsCaracterisation = _.cloneDeep(formsCaracterisation);
  }

  getPanelists(): Panelist[] {
    return _.cloneDeep(this.panelists);
  }

  setPanelists(panelists: Panelist[]) {
    this.panelists = _.cloneDeep(panelists);
  }

  getValuesFromAttributeDetails(): string[][] {
    return _.cloneDeep(this.valuesFromAttributeDetails);
  }

  setValuesFromAttributeDetails(valuesFromAttributeDetails: string[][]) {
    this.valuesFromAttributeDetails = _.cloneDeep(valuesFromAttributeDetails);
  }

  getPValueStyle(pValue) {
    if (pValue < 0.05) {
      return ['response'];
    } else if (pValue < 0.1) {
      return ['response', 'textColor', 'textItalic'];
    } else {
      return ['response', 'textColor'];
    }
  }

  formatPvalue(pvalue) {
    if (pvalue && pvalue.toFixed) {
      pvalue = pvalue.toFixed(4);
      return pvalue < 0.0001 ? "<0.0001" : pvalue.toString();
    }
    return "<0.0001"
  }
}
