import { Languages } from "../../enums";

const getObjectValueTranslation = (object: any, lang: string): string => {
    switch (typeof object) {
        case 'object':
            if (object.hasOwnProperty('custom')) return object;
            else if (object.hasOwnProperty('user') && object.user.length) return object.user;
            else if (object.hasOwnProperty(lang) && object[lang].length) return object[lang];
            else return object[Languages.Default];
        default:
            return object;
    };
};


const formatter = (element: any, displayLanguages: Array<string>, showBlock: boolean): string => {
    const header = `<div>`;
    const footer = `</div>`;
    let body = ``;

    displayLanguages.map(( lang: string ) => {
        switch (typeof element.value) {
            case 'object':
                if (showBlock && element.value.hasOwnProperty('blockName')) {
                    body = `${body}<b>${getObjectValueTranslation(element.value.blockName, lang).replace(/\s+/g, '&nbsp')}</b></br>`;
                }
                if (element.value.hasOwnProperty('attribute')) {
                    body = `${body}<div style="font-style: italic;">${getObjectValueTranslation(element.value.attribute, lang)}</div></br>`;
                }
                body = `${body}<div>${getObjectValueTranslation(element.value.label, lang)}</div></br>`;
                if (element.value.hasOwnProperty('drilled')) {
                    body = `${body}<div>${getObjectValueTranslation(element.value.drilled.label, lang)}</div>`;
                }
                break;
            default:
                body = `<div>${element.value}</div>`;
        }
        body = `${body}<span style="color: transparent">|</span>`;
    });

    return [
        header,
        body,
        footer
    ].join('');
};

export {
    formatter
};
