import { Component, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FilterService } from '../../services';
import { FilterType } from '../../interfaces';
import { Languages } from '../../enums';

@Component({
  selector: '[dna-filters]',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent {
  @Input() filters: any;
  @Input() lang: string;
  @Input() loading: boolean;

  @Output() onSubmit = new EventEmitter<any>();
  @Output() onCollapse = new EventEmitter<boolean>();

  public form: FormGroup;
  public collapsed: boolean = true;
  public Languages = Languages;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('filters') && changes.filters.currentValue && Object.keys(changes.filters.currentValue).length) {
      delete this.form;
      this.form = this.updateForm(this.filters);
    }
  };

  /**
   * updateForm
   * Method to generate new form group for input filters
   * @param filters : any; Reference to the current filters input object;
   * @returns new form group to assign 
  */
  private updateForm = (filters: any): FormGroup => FilterService.toFormGroup(filters);

  /**
   * _handleWrapFilterCategory
   * Method to collapse/expand a specific form category
   * @param keyForm : string
   */
  public _handleWrapFilterCategory = (keyForm: string, object: any) => object[keyForm].collapsed = !object[keyForm].collapsed;

  /**
   * _objectKeys
   * Method to get all keys composing given object
   * @param object : object
   * @return Array<string>
   */
  public _objectKeys = (object: object): Array<string> => Object.keys(object);

  /**
   * _onSubmit
   * Method to handle form submission and turn current form object into 
   * the intended right format.
   */
  public _onSubmit = (): void => {
    Object.keys(this.form.value).forEach((key: string) => {
      this.filters[key].options.forEach((option: FilterType) => {
        option.hasChanged = option.isApplied !== this.form.value[key][option.key];
        option.isApplied = this.form.value[key][option.key];
      })
    });
    const paylaod = FilterService.formatToInitial(this.filters);
    this.onSubmit.emit(paylaod);
  };

  /**
   * reset filters
   */
  public _onInitFilters = (): void => {
    Object.keys(this.form.value).forEach((key: string) => {
      this.checkAllFilters(key, true);
    });
  };
  
  /**
   * _handleWrapperCollapse
   */
  public _handleWrapperCollapse = (actualState: boolean): void => {
    this.collapsed = !actualState;
    this.onCollapse.emit(this.collapsed);
  };

  /**
   * checkAllFilters
   */
  public checkAllFilters = (formpart, value) => {
    Object.keys(this.form.get(formpart)['controls'])
      .map((control: any) => {
        const payload = {};
        payload[control] = value;
        this.form.get(formpart).patchValue(payload);
      })
  };

  /**
   * checkEveryChecked 
   */
  public checkEveryObject = (formpart: any): boolean => Object.values(this.form.get(formpart).value).every((object: any) => object);

  getSelectedFilters(key) {
    let nb = 0;
    Object.keys(this.form.value[key]).forEach(k => {
      if (this.form.value[key][k]) {
        nb++;
      } 
    })
    return nb;
  }

}