<div class="row">
  <div class="form-group col-9">
    <div class="row">
      <label class="col-12 col-md-4 my-auto col-form-label">
        {{ "GROUP_VOLUNTEERS" | translate }}
      </label>
      <div class="col-12 col-md-8 input-group">
        <div class="input-group-prepend" ngbDropdown>
          <button class="form-control btn btn-outline-secondary" type="button" ngbDropdownToggle>
            {{ countryOS }}
          </button>
          <div ngbDropdownMenu>
            <ng-container *ngFor="let country of countriesIn">
              <a ngbDropdownItem (click)="setCountryOS(country)" translate>{{ country }}</a>
            </ng-container>
          </div>
        </div>
        <!--input class="form-control" [(ngModel)]="osNumber" type="text" name="osNumber"-->
        <tag-input style="height: 35px" class="form-control form-control-input" secondaryPlaceholder="{{'GROUP_NAME_LABEL' | translate}}"
            displayBy="groupName" identifyBy="groupName" name="groupVolunteerToAdd" [onlyFromAutocomplete]="false"
            [(ngModel)]="groupVolunteerToAdd" maxItems=1 [(inputText)]="formHorsArcs">
                <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="groupVolunteers" displayBy="groupName" identifyBy="groupName" [minimumTextLength]="1"
                  [showDropdownIfEmpty]="false" limitItemsTo=5>
                </tag-input-dropdown>
              </tag-input>
        <div class="input-group-append">
          <button class="form-control btn btn-outline-secondary" type="button" (click)="openModalEditGroupVolunteer()"
             translate>GROUP_VOLUNTEERS_EDIT</button>
        </div>
      </div>
      <div *ngIf="osIncorrect" class="offset-1 text-center invalid-feedback" style="display: block">
        <p translate> INCORRECT_VOLUNTEER_HORS_ARCS_NUMBER </p>
      </div>
    </div>
  </div>
</div>
