import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TranslationsService } from '../services/translations.service'
import { Inject } from '@angular/core';
import { map } from 'rxjs/operators';


export class CustomTranslateLoader implements TranslateLoader {
    constructor(@Inject('TRANSLATIONSSERVICES') private translationsService:TranslationsService) {
    }

    getTranslation(lang: string): Observable<any> {
        return this.translationsService.getTranslationsByLanguage(lang).pipe(
            map(translations => { return this.translationsService.formatTranslations(translations)[lang] })
        )
    }

}