<div class="container">
  <div class="d-flex flex-row gap-1 float-end">
    <button *ngIf="!isChanged" id="init" class="btn btn-primary mb-4" type="button" (click)="reinitForm()"
      translate>RESET</button>
    <ng-container *ngIf="isChanged">
      <button id="save" class="btn btn-primary mb-4 ml-1" type="button" (click)="save(targetForm)"
        translate>SAVE</button>
      <button id="cancel" class="btn btn-default mb-4 ml-1" type="button" (click)="onCancel()" translate>CANCEL</button>
    </ng-container>
  </div>
  <form class="pt-3" [formGroup]="targetForm">
    <div class="pt-5 form-group" formArrayName="targetAssignment">
      <div class="row">
        <div class="col-4">
          <h3>{{ 'NUMBER_OF' | translate: { element: substrate | translate | lowercase } }}</h3>
        </div>
        <div class="col-7">
          <h3 translate>TARGETS</h3>
        </div>
        <div class="col-1 item-add">
          <button class="btn-add" type="button" (click)="addItem()">
            <i class="fa fa-plus-circle fa-2x" aria-hidden="true"> </i>
          </button>
        </div>
      </div>
      <ng-container *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
        <div class="row" [ngClass]="{ 'mt-2': i > 0 }">
          <div class="col-4">
            <input class="form-control" type="number" formControlName="volunteersNumber" name="input-volunteers" min="0"
              placeholder="{{ 'NUMBER_OF' | translate: { element: substrate | translate | lowercase } }}">
          </div>
          <div class="col-7">
            <textarea class="form-control" formControlName="target" name="textarea-target" rows="4"
              placeholder="{{ 'ENTER_FEM' | translate: { element: 'TARGET' | translate | lowercase } }}"></textarea>
          </div>
          <div *ngIf="items.length > 1" class="col-1 item-remove">
            <button class="btn-remove" type="button" (click)="removeItem(i)">
              <i class="fa fa-times fa-lg" aria-hidden="true"> </i>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="pt-3 form-group">
      <h3 translate>PROTOCOL</h3>
        <rich-text-wrapper spellcheck="false" formControlName="protocol" placeholder="{{ 'ENTER_TEXTAREA' | translate }}"></rich-text-wrapper>
      <div  class="mt-5 mb-3">
        <table class="table-bordered couple-table">
          <tr>
            <td class="table-secondary td-padding" colspan="2">
              <span translate>STUDIED_COUPLES</span>
            </td>
          </tr>
          <tr>
            <td class="td-padding">
              <span translate>ONE_PAGER_TESTED</span>
            </td>
            <td class="td-padding">
              <span translate>ONE_PAGER_REFERENCE</span>
            </td>
          </tr>
          <ng-container *ngIf="comparison">
            <tr *ngFor="let couple of couples">
              <td class="td-padding">
                <ng-container *ngFor="let lab of couple.lab let indexLab = index">
                    {{ indexLab === 0 ? "" : ", " }}{{ lab.name }}
                </ng-container>
              </td>
              <td class="td-padding" >{{ couple.bench.name }}</td>
            </tr>
          </ng-container>

          <ng-container *ngIf="!comparison">
            <tr *ngFor="let formulaName of formulasName">
              <td class="td-padding">
                <ng-container>
                  {{ formulaName }}
                </ng-container>

              </td>
              <td class="td-padding">{{ 'VERSUS_BARE_SKIN' | translate }}</td>
            </tr>
          </ng-container>

        </table>
      </div>
      <div
        *ngIf="displayProductsTable && onePager.formulas && onePager.formulas.tested && onePager.formulas.tested.length > 0"
        class="row" formGroupName="formulas">
        <div class="table-responsive col-12 col-md-6" formArrayName="tested">
          <table class="table table-bordered">
            <tr>
              <th colspan="6" class="table-primary protocol-th">
                <span translate>ONE_PAGER_TESTED</span>
              </th>
            </tr>
            <tr>
              <th class="table-secondary" scope="col" translate>ROUTINE</th>
              <th class="table-secondary" scope="col" translate>ROUTINE_VISIT</th>
              <th class="table-secondary" translate>FORMULA</th>
              <th class="table-secondary" translate>ROUTINE_BATCH</th>
              <th class="table-secondary" translate>SOCLE</th>
            </tr>
            <tr *ngFor="let tested of onePager.formulas.tested; let i = index" [formGroupName]="i">
              <td>{{ tested.routineName }}
                <ng-container *ngIf="tested.routineLabel"> - {{ tested.routineLabel }}</ng-container>
              </td>
              <td>{{ tested.visitName }}</td>
              <td>{{ tested.formulaName }} </td>
              <td>
                <input class="form-control" type="text" formControlName="lot" name="tested-lot"
                  (keyup)="changeValue(tested, $event, 'tested', 'lot')">
              </td>
              <td>
                <input class="form-control" type="text" formControlName="socle" name="tested-socle"
                  (keyup)="changeValue(tested, $event, 'tested', 'socle')">
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="comparison" class="table-responsive col-12 col-md-6" formArrayName="reference">
          <table class="table table-bordered">
            <tr>
              <th colspan="5" class="table-primary protocol-th">
                <span translate>ONE_PAGER_REFERENCE</span>
              </th>
            </tr>
            <tr>
              <th class="table-secondary" translate>ROUTINE</th>
              <th class="table-secondary" translate>ROUTINE_VISIT</th>
              <th class="table-secondary" translate>FORMULA</th>
              <th class="table-secondary" translate>ROUTINE_BATCH</th>
              <th class="table-secondary" translate>SOCLE</th>
            </tr>
            <tr *ngFor="let reference of onePager.formulas.reference; let i = index" [formGroupName]="i">
              <td>{{ reference.routineName }}
                <ng-container *ngIf="reference.routineLabel"> - {{ reference.routineLabel }}</ng-container>
              </td>
              <td>{{ reference.visitName }}</td>
              <td>{{ reference.formulaName }}</td>
              <td>
                <input class="form-control" type="text" formControlName="lot" name="reference-lot"
                  (keyup)="changeValue(reference, $event, 'reference', 'lot')">
              </td>
              <td>
                <input class="form-control" type="text" formControlName="socle" name="reference-socle"
                  (keyup)="changeValue(reference, $event, 'reference', 'socle')">
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </form>
</div>
