import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dna-counter',
    templateUrl: './dnaCounter.component.html'
})

export class dnaCounterComponent implements OnInit {
    @Input() text: string;
	@Input() count: number;
    
    constructor() { }

    ngOnInit() { }
}
