<div class="container">
  <h2>{{ 'VOLUNTEERS_MANAGEMENT' | translate }}</h2>
  <form [formGroup]="volunteersForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="country">{{ 'COUNTRY' | translate }}</label>
      <div class="input-group">
        <div class="input-group-prepend" ngbDropdown>
          <button class="form-control btn btn-outline-secondary" type="button" ngbDropdownToggle
            [disabled]="isCountrySelectionDisabled">
            {{ selectedCountry || 'COUNTRY' | translate }}
          </button>
          <div ngbDropdownMenu>
            <ng-container *ngFor="let country of countries">
              <a ngbDropdownItem (click)="selectCountry(country)">{{ country }}</a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="volunteerList">{{ 'VOLUNTEERS_LIST' | translate }}</label>
      <tag-input placeholder="+ {{ 'ADD_VOLUNTEER' | translate }}"
        secondaryPlaceholder="+ {{ 'ADD_VOLUNTEER' | translate }}" formControlName="volunteerList"
        [onTextChangeDebounce]="500" [onlyFromAutocomplete]="true" displayBy="panelistNumber"
        identifyBy="panelistNumber" [editable]="true" [addOnBlur]="false" [addOnPaste]="false">
        <tag-input-dropdown [autocompleteObservable]="requestAutocompleteItems" displayBy="panelistNumber"
          identifyBy="panelistNumber" [minimumTextLength]="3" [showDropdownIfEmpty]="true">
        </tag-input-dropdown>
      </tag-input>
    </div>
    <div class="form-check">
      <input id="extraction" class="form-check-input" formControlName="extraction" type="checkbox" />
      <label class="form-check-label" for="extraction">{{ 'EXTRACTION' | translate }}</label>
    </div>
    <div class="form-check">
      <input id="deletion" class="form-check-input" formControlName="deletion" type="checkbox" disabled />
      <label class="form-check-label" for="deletion">{{ 'DELETION' | translate }}</label>
    </div>
    <button class="btn btn-primary" type="submit" [disabled]="isSubmitDisabled">{{ 'VALIDATE' | translate }}</button>
  </form>

  <!-- Loader Element -->
  <div *ngIf="loading" class="loader-overlay">
    <div class="loader-container">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">{{ 'LOADING' | translate }}</span>
      </div>
    </div>
  </div>
</div>