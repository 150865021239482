import { Component, Input, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import { SingleInvertedBarchartComponent } from '../graphics/single-inverted-barchart/single-inverted-barchart.component';
import { IncreaseDecreaseSelectionComponent } from '@app/campaigns/detail/edit-campaign/analyse/reports/chart-module/components';
import { Table } from '../../models';

@Component({
  selector: 'dna-table-graph',
  templateUrl: './table-graph.component.html',
  styleUrls: ['./table-graph.component.scss']
})
export class TableGraphComponent implements AfterViewInit {
  @ViewChild('tableGraph', { read : ViewContainerRef }) tableGraph: ViewContainerRef;
  @Input() data: any;
  @Input() 'table-instance': Table;


  private cm = {
    'SingleInvertedBarchartComponent': SingleInvertedBarchartComponent,
    'IncreaseDecreaseSelectionComponent': IncreaseDecreaseSelectionComponent
  };

  constructor() {}

  ngAfterViewInit(): void {
    this.loadComponent();
  }

  loadComponent() {
    this.tableGraph.clear();

    const componentRef = this.tableGraph.createComponent(this.cm[this.data.component]);
    (<any>componentRef.instance).parameters = {...this.data.parameters, configurations : this['table-instance'].parameters.yAxis, other : this['table-instance'].parameters.plotOptions };
    (<any>componentRef.instance).onOptionsChange = this['table-instance'].onOptionChangeSubject;
  }
}
