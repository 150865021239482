import { Input, EventEmitter, Output, Component } from '@angular/core';

@Component({
  selector: 'dna-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.less']
})
export class CheckboxComponent {

  @Input() cssClass = 'icheckbox_square-green';
  @Input() disabled: boolean;
  @Input() isChecked: boolean;
  @Input() sideLabel = 'right';
  @Input() text: string;

  @Output() onBooleanChanged: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  onClick() {
    if (!this.disabled) {
      this.onBooleanChanged.emit();
    }
  }
}
