import { DNATranslateService } from './../services/translate.service';
import {
  Pager,
  TableHeader,
  Buttons
} from '@app/types';
import {
  UtilService
} from '../services/util.service';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

import * as _ from 'lodash';

@Component({
  selector: 'dna-table2',
  templateUrl: './table2.component.html',
  styleUrls: ['./table2.component.less']
})
export class Table2Component implements OnInit, OnChanges {
  @Input() headers: TableHeader[] = [];

  @Input() buttons: Buttons[] = [];

  @Input() title: string = "";

  @Input() data: any[] = [];

  @Input() pageIndex: number;

  @Input() pagination: boolean = true;

  @Input() numberOfObjectsPerPage: number = 10;

  @Input() sortFunction: (headerId: string, reverse: boolean) => any[];

  @ContentChild(TemplateRef) templateVariable: TemplateRef < any > ;

  @Input() orderedBy: string;

  @Input() reverse: boolean;

  @Input() totalItems: number;

  @Input() sortable: boolean = true;

  @Output() onSort: EventEmitter < {
    id: string,
    reverse: boolean
  } > = new EventEmitter();

  @Output() parametersChanged: EventEmitter < any > = new EventEmitter();

  @Output() rowClicked: EventEmitter < string > = new EventEmitter();

  @Output() buttonAction: EventEmitter < string > = new EventEmitter();

  pagedData: any[] = [];

  pager: Pager = new Pager();

  constructor(
    public utilService: UtilService,
    public translateService: DNATranslateService
  ) {}

  ngOnInit() {
    this.updatePager();
  }

  onRowClicked(id: string) {
    this.rowClicked.emit(id);
  }

  onButtonAction(buttonId: string) {
    this.buttonAction.emit(buttonId);
  }

  onSortByChanged(header: TableHeader) {
    if(this.sortable) {
      this.reverse = this.orderedBy !== header.id ? false : !this.reverse;
      if (header.sortable) {
        this.onSort.emit({
          id: header.id,
          reverse: this.reverse
        })
        this.orderedBy = header.id;
        this.sortFunction(this.orderedBy, this.reverse);
        this.setPage(this.pager.currentPage);
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.data) {
      this.data = [];
    }
    if (!this.headers) {
      this.headers = [];
    }
    if (changes.data && changes.data.currentValue) {
      // if(this.sortable) {
      //   this.sortFunction(this.data, this.orderedBy, this.reverse);
      // }
      this.updatePager();
      let page = (this.pageIndex > 0 && this.pageIndex <= this.pager.totalPage) ? this.pageIndex : 1;
      this.pager.currentPage = page;
      this.pager.pageSize = this.numberOfObjectsPerPage;
      this.updatePager();
    }
  }

  updatePager() {
    this.pager.totalItems = this.totalItems;
    if(!this.pagination) {
      this.pager.pageSize = this.data.length;
    }
    this.pager.totalPage = Math.ceil(this.pager.totalItems / this.pager.pageSize);
    if (this.pager.totalPage < 5 || this.pager.currentPage < 4) {
      this.pager.pages = _.range(1, Math.min(6, this.pager.totalPage + 1));
    } else {
      this.pager.pages = _.range(this.pager.currentPage - 2, Math.min(this.pager.currentPage + 3, this.pager.totalPage + 1));
    }
    // si pas d'élément trouvé, on affiche de 0 à 0 sur 0 résultats
    this.pager.startIndex = this.data.length !== 0 ? (this.pager.currentPage - 1) * this.pager.pageSize + 1 : 0;
    this.pager.endIndex = Math.min((this.pager.currentPage) * this.pager.pageSize, this.pager.totalItems);
    this.pagedData = this.data.slice(0, this.pager.endIndex);
  }

  setPage(page: number) {
    if (page > 0 && page <= this.pager.totalPage) {
      this.pager.previousPage = _.cloneDeep(this.pager.currentPage);
      this.pager.currentPage = page;
      this.updatePager();
    }
  }

  itemsPerPage(page) {
    this.pager.previousPage = _.cloneDeep(this.pager.currentPage);
    this.pager.currentPage = page;
    this.updatePager();
    this.parametersChanged.emit({pager: this.pager, type: 'itemsPerPage'});
  }

  nextPage(page) {
    this.pager.previousPage = _.cloneDeep(this.pager.currentPage);
    this.pager.currentPage = page;
    this.updatePager();
    this.parametersChanged.emit({pager: this.pager, type: 'nextPage'});
  }

  previousPage(page) {
    if (this.pager.currentPage > 1) {
      this.pager.previousPage = _.cloneDeep(this.pager.currentPage);
      this.pager.currentPage = page;
      this.updatePager();
      this.parametersChanged.emit({pager: this.pager, type: 'previousPage'});
    }
  }

  indexPage(page) {
    this.pager.previousPage = _.cloneDeep(this.pager.currentPage);
    this.pager.currentPage = page;
    this.updatePager();
    this.parametersChanged.emit({pager: this.pager, type: 'indexPage'});
  }

  firstPage(page) {
    this.pager.previousPage = _.cloneDeep(this.pager.currentPage);
    this.pager.currentPage = page;
    this.updatePager();
    this.parametersChanged.emit({pager: this.pager, type: 'firstPage'});
  }

  lastPage(page) {
    this.pager.previousPage = _.cloneDeep(this.pager.currentPage);
    this.pager.currentPage = page;
    this.updatePager();
    this.parametersChanged.emit({pager: this.pager, type: 'lastPage'});
  }

  isFontAwesomeIcon(icon: string): boolean {
    return icon.startsWith('fa');
  }
}
