<ngx-loading [show]="showSpinner"></ngx-loading>
<div class="ibox float-e-margins">
  <div class="ibox-title">
    <div class="row">
      <div class="col-12 col-md-4 my-auto">
        <h5>{{ "STUDIES" | translate }}</h5>
      </div>
    </div>
  </div>
  <div class="ibox-content">
    <div *ngIf="!error && !showSpinner">
      <h3 translate>STUDIES_IN_MULTI</h3>
      <table class="table">
        <thead class="thead-light">
          <tr>
            <th scope="col" translate>NAME</th>
            <th scope="col" translate>WORKFLOWS</th>
            <th scope="col" translate>FORMULAS</th>
            <th scope="col" translate>PROJECTS.PROJECT_BRIDGE</th>
            <th scope="col" translate>PROJECTS.PROJECT_ARCS_STUDY</th>
            <th scope="col" translate>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let study of multiCampaign.studies'>
            <td>{{study.name}}</td>
            <td>{{study.workflows}}</td>
            <td>{{study.formulas}}</td>
            <td>{{study.synergy?.requestNumber}}</td>
            <td>{{study.arcs}}</td>
            <td>
              <i ngbTooltip="{{ 'DELETE' | translate }}" class="fa fa-times fa-lg" (click)="removeStudy(study)"
                aria-hidden="true"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <dna-error-page *ngIf="error" (onClickReload)="loadStudies(idMultiCampaign)"></dna-error-page>
  </div>
</div>
<div class="ibox float-e-margins" style="position: relative;">
  <div class="ibox-title">
    <!--
    <ngx-loading [show]="showSpinnerCampaigns" [config]="{fullScreenBackdrop: false}">
    </ngx-loading>
    -->
    <div *ngIf="!error && !showSpinner">
      <h3 translate>ADD_STUDY</h3>
      <div class="row">
        <div class="col-6">
          <dna-search-text class="search" [data]="filter.name" (onSearch)="filter.name = $event">
          </dna-search-text>
        </div>
        <div class="col-6">
          <a class="btn btn-primary mb-4 search-btn" (click)="searchCampaign()">
            <span translate>SEARCH</span>
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="!error && !showSpinner">
      <h3 translate>STUDIES</h3>
      <p *ngIf="campaigns.length == 0" translate>NO_RESULTS_SEARCH_FIELD</p>
      <table class="table" *ngIf="!errorCampaigns && !showSpinner && campaigns.length > 0">
        <thead class="thead-light">
          <tr>
            <th scope="col" translate>NAME</th>
            <th scope="col" translate>WORKFLOWS</th>
            <th scope="col" translate>FORMULAS</th>
            <th scope="col" translate>PROJECTS.PROJECT_BRIDGE</th>
            <th scope="col" translate>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let study of campaigns'>
            <td>{{study.name}}</td>
            <td>{{study.workflows}}</td>
            <td>{{study.formulas}}</td>
            <td>{{study.synergy}}</td>
            <div *ngIf="study.isRoutine===multiCampaign.isRoutineMulti">
              <td>
                <i ngbTooltip="{{ 'ADD' | translate }}" class="fa fa-plus fa-lg" (click)="addStudyToMulti(study)"
                  aria-hidden="true"></i>
              </td>
            </div>
            <div *ngIf="study.isRoutine!==multiCampaign.isRoutineMulti">
              <td>
                <i ngbTooltip="{{ 'ERROR_ROUTINE_MULTI' | translate }}" class="fa fa-exclamation-triangle fa-lg" aria-hidden="true"></i>
              </td>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
