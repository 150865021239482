import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dna-error-page',
  templateUrl: './dna-error-page.component.html',
  styleUrls: ['./dna-error-page.component.less']
})
export class DnaErrorPageComponent implements OnInit {

  @Output() onClickReload = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  reload() {
    this.onClickReload.emit();
  }
}
