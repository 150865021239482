import { ReferenceTypeService } from './../../../../../../shared/services/reference-type.service';

import {from as observableFrom, of as observableOf,
  Observable,
  throwError,
  of
} from 'rxjs';

import {map, toArray, tap, finalize, mergeMap, flatMap, catchError} from 'rxjs/operators';
import {
  ActivatedRoute,
  ParamMap,
  Router
} from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';
import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import {
  CampaignStates,
  DataVizualisation,
  Descriptor,
  Graph,
  GraphType,
  Workflow,
  CampaignWorkflowGraphs,
  WorkflowPBI
} from '../../../../../../types';
import {
  CampaignService
} from '../../../../../campaigns.service';
import {
  DNATranslateService
} from '../../../../../../shared/services/translate.service';
import {
  ErrorManagerService
} from '../../../../../../shared/services/errorManager.service';
import {
  UtilService
} from '../../../../../../shared/services/util.service';
import {
  WorkflowService
} from '../../../../../../workflows/workflows.service';
import { PowerBIModalAddDescriptorComponent } from '../add-descriptor/power-bi-modal-add-descriptor.component';
import { ApplicationInsightsService } from '../../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-power-bi-page',
  templateUrl: './power-bi-page.component.html',
  styleUrls: ['./power-bi-page.component.less']
})
export class PowerBIPageComponent implements OnInit, AfterViewInit {

  campaign: CampaignWorkflowGraphs;
  campaignStates: typeof CampaignStates = CampaignStates;
  currentLanguage: string;
  dataVizualisation: DataVizualisation;
  graphs: Graph[];
  originalsGraphs: Graph[];
  isWorkflowChanged: boolean = false;
  showSpinner: boolean = true;
  typeGraphs: GraphType[] = [];
  workflow: Workflow;
  descriptorsInWorkflow: Descriptor[] = [];
  idWorkflow: string;
  error: boolean = false;
  inCampaign: boolean = true;
  initTime = performance.now();

  modalOption: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: "lg"
  };

  constructor(
    private campaignService: CampaignService,
    private DNATranslate: DNATranslateService,
    private modalService: NgbModal,
    private aiService: ApplicationInsightsService,
    private errorManager: ErrorManagerService,
    private route: ActivatedRoute,
    private referenceTypeService: ReferenceTypeService,
    private router: Router,
    private utilService: UtilService,
    private workflowService: WorkflowService
  ) {}

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Analyse Power BI', '', performance.now() - this.initTime, templateUrl);
  }

  private init() {
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.typeGraphs = this.getGraphType();
    this.route.paramMap.pipe(
      tap((params: ParamMap) => this.idWorkflow = params.get("idWorkflow")),
      mergeMap(() => this.route.parent.paramMap),
      flatMap((params: ParamMap) => this.campaignService.getCampaignGraphsbyWorkflowId(params.get("idCampaign"), this.idWorkflow)),
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      tap((campaign: CampaignWorkflowGraphs) => this.campaign = campaign),
      tap((campaign: CampaignWorkflowGraphs) => this.campaignService.updateBreadCrumb(campaign.name)),
      mergeMap(() => this.getWorkflow(this.idWorkflow)),
      tap((workflow: Workflow) => this.workflow = workflow),
      tap((workflow: Workflow) => this.dataVizualisation = workflow.dataVizualisation ? _.cloneDeep(workflow.dataVizualisation) : new DataVizualisation()),
      tap((workflow: Workflow) => this.graphs = this.initGraphs(workflow.graphs)),
      tap(() => this.descriptorsInWorkflow = this.buildDescriptorsInWorkflow(this.campaign, this.workflow)),
      tap(() => this.originalsGraphs = _.cloneDeep(this.graphs)),
      tap(() => this.showSpinner = false)
      ).subscribe();

    this.DNATranslate.onLangChange().subscribe((translation: any) => {
      this.currentLanguage = translation.lang;
    });
  }


  catchError(err: any): Observable < any > {
    this.showSpinner = false;
    console.log("error", err);
    this.errorManager.displayMessage("ON_ERROR_UPDATE", "danger");
    return observableOf(err);
  }

  getGraphType = (): GraphType[] => {
    return this.referenceTypeService.getGraphs();
  }

  private buildDescriptorsInWorkflow(campaign: CampaignWorkflowGraphs, workflow: Workflow) {
    let descriptors: Descriptor[] = [];
    workflow.blocks.map(b => b.components
      .filter(c => c.type === "dna-input-text" || c.type === "dna-multiple-choice" || c.type === "dna-counter-side" || c.type === "dna-confort")
      .filter(c => !c.args.inactive)
      .map(c => descriptors.push(this.utilService.createDescriptor(campaign.id, campaign.name, workflow.id, workflow.name, b.id, b.name, _.get(b, 'suffix', ''), b.idInQuestionnaire, c))
      )
    )
    return descriptors;
  }

  getWorkflow = (workflowId: string): Observable < Workflow > => {
    const workflow = this.campaign.workflows.find((wk: Workflow) => wk.id === workflowId);
    if (workflow.blocks) {
      return observableOf(workflow);
    }
    return this.workflowService.getWorkflow(workflowId);
  }

  initGraphs(graphs: Graph[] = []) {
    return graphs.map(
      (graph: Graph) => {
        if (graph.descriptors && graph.descriptors.length > 0) {
          graph.descriptors = graph.descriptors.map(
            (descriptor: Descriptor) => {
              if (descriptor.isActive === undefined) {
                descriptor.isActive = true;
              }
              return descriptor;
            }
          )
        }
        return graph;
      }
    );
  }

  onBack() {
    const workflowPBI = this.getWorkflowPBI(this.campaign, this.workflow);
    this.campaignService.setReportMetadata(workflowPBI);
    this.router.navigate(['campaigns', this.campaign.id, 'edit', 'analyse', 'reports']);
  }

  onCancel() {
    this.dataVizualisation = this.workflow.dataVizualisation ? _.cloneDeep(this.workflow.dataVizualisation) : new DataVizualisation();
    this.graphs = _.cloneDeep(this.originalsGraphs);
    this.isWorkflowChanged = false;
  }

  setTranslationGraph = (graph: Graph): Observable < Graph > => {
    return this.DNATranslate.getTradInAllLanguages(graph.type.name).pipe(
      tap(translations => graph.type.translations = translations),
      map(() => graph),);
  }

  save() {
    this.showSpinner = true;
    this.workflow.dataVizualisation = _.cloneDeep(this.dataVizualisation);
    this.workflow.graphs = this.utilService.indexingGraphs(this.graphs);
    this.campaign.workflows[0] = this.workflow;
    observableFrom(this.workflow.graphs).pipe(
      mergeMap(this.setTranslationGraph),
      toArray(),
      mergeMap(() => this.campaignService.putCampaignGraphsbyWorkflowId(this.campaign.id, {graphs: this.graphs, workflowId: this.workflow.id})),
      tap((result) => {
        if (_.isEmpty(_.get(result, 'qsError', []))) {
          this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
          this.originalsGraphs = _.cloneDeep(this.graphs);
          this.isWorkflowChanged = false;
        } else {
          const graphesQSError = _.get(result, 'qsError', []).reduce((acc, curr, index) => {
            if (index === 0) {
              return "#" + curr;
            } else {
              return _.get(result, 'qsError', []).length > 1 && _.get(result, 'qsError', []).length === (index + 1)
               ? acc + " et #" + curr
               : acc + ", #" + curr;
            }
          }, "")
          this.DNATranslate.translateMessage("ON_ERROR_QS_SCALE", { graphesQSError: graphesQSError}).subscribe((translated) => {
            this.errorManager.displayMessage(translated, 'warning', {timeOut:10000}, false);
          });
        }
      }),
      finalize(() => {
        this.showSpinner = false;
      }))
      .subscribe();
  }

  getWorkflowPBI(campaign: CampaignWorkflowGraphs, workflow): WorkflowPBI {
     return {
          name: workflow.name,
          id: workflow.id,
          idCampaign: [campaign.id],
          nameCampaign: [campaign.name],
          dataVizualisation: workflow.dataVizualisation
        }
  }

}
