import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: '[dna-loader]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dna-loader.component.html',
  styleUrls: ['./dna-loader.component.scss']
})
export class DnaLoaderComponent {
  constructor() {}
};
