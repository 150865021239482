export default {
    "type": "tornado",
    "configs": {
        "compute": {
            "method" : "median",
            "key" : "label"
        },
        "title": {
            "text": "",
            "margin": 50
        },
        "subtitle" : {
            "text" : ""
        },
        "yAxis": {
            "min": -6,
            "max": 6,
            "plotBands" : [
                {
                    "index" : 0,
                    "color": 'rgba(0,0,0,0)',
                    "from": -6,
                    "to": 0,
                    "label": {
                        "text": 'VIZUALIZATIONS.TORNADO.INCREASE',
                        "align": 'center',
                        "y": -10
                    },
                    "rule" : "<=0",
                    "translationKey" : "VIZUALIZATIONS.TORNADO.INCREASE",
                    "key" : "increase"
                },
                {
                    "index" : 1,
                    "color": 'rgba(0,0,0,0)',
                    "from": 0,
                    "to": 6,
                    "label": {
                        "text": 'VIZUALIZATIONS.TORNADO.DECREASE',
                        "align": 'center',
                        "y": -10
                    },
                    "rule" : ">=0",
                    "translationKey" : "VIZUALIZATIONS.TORNADO.DECREASE",
                    "key" : "decrease"
                }
            ]
        },
        "plotOptions" : {
            "attributes_blocks": {
                "enabled": false
            },
            "default_language" : {
                "enabled" : false
            }
        }
    }
}