import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Translatable, User } from '../../types';
import { UserService } from '../services/user.service';
import { DNATranslateService } from '../services/translate.service';

@Component({
    selector: 'dna-translate',
    templateUrl: './dnaTranslate.component.html'
})

export class TranslateComponent implements OnInit {
    @Input() fieldToTranslate: Translatable;
    fieldTranslated: string;

    constructor(private userService: UserService,
        private translateService: DNATranslateService) { }

    ngOnInit() {
        this.userService.onUserChanged().subscribe((user: User) => {
            this.fieldTranslated = this.translateField(user.language)
        });
    }

    ngOnChanges(object: SimpleChanges) {
        let currentLanguage = this.translateService.getLanguage();
        this.fieldTranslated = this.translateField(currentLanguage);
    }

    translateField(currentLanguage: string): string {
        if(!this.fieldToTranslate) return '';
        return this.fieldToTranslate[currentLanguage] && this.fieldToTranslate[currentLanguage] !== '' ? this.fieldToTranslate[currentLanguage] : this.fieldToTranslate.english;
    }
}
