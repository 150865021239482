
import {finalize} from 'rxjs/operators';
import {
  Component,
  OnInit
} from '@angular/core';

import { Observable } from 'rxjs';

import { User, Workspace } from '../../../../types';
import { UserService } from '../../../../shared/services/user.service';
import {
  WorkspaceService
} from '../../workspaces/workspaces.service';

@Component({
  selector: 'dna-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent implements OnInit {
  objectArray: Array<string> = ['BLOCK', 'WORKFLOW'];
  objectType: string = 'BLOCK';
  showSpinner: boolean = true;
  user: User;
  workspaces: Observable<Workspace[]>;

  constructor(
    private userService: UserService,
    private workspaceService: WorkspaceService
  ) { }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.workspaces = this.workspaceService.getWorkspacesInLocal().pipe(
      finalize(() => this.showSpinner = false))
  }
}
