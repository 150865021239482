import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { WorkflowConfiguration } from '@app/types';

@Component({
    selector: 'dna-workflow-edit-detail',
    templateUrl: './workflow-edit-detail.component.html'
})
export class WorkflowEditDetailComponent implements OnInit {

    @Input() isWorkflowEditable: boolean;
    @Input() workflowConfiguration: WorkflowConfiguration;
    @Output() loopCreated = new EventEmitter();
    @Output() changeDisplay = new EventEmitter();

    display = 'repetitionQuestions';

    constructor() { }

    ngOnInit() {}

    changeTab(tab: string) {
        this.display = tab;
        this.changeDisplay.emit(tab);
    }

    onChangeActiveQuestions(workflow) {
        this.workflowConfiguration = workflow;
    }

    onLoopCreated(event) {
        this.loopCreated.emit(event);
    }

}
