import { PointOptionsObject } from 'highcharts';
import { Translatable } from '../../../../../../../../../types';
import { Chart } from '../../chart.model';
import * as Highcharts from 'highcharts';

const getMedian = (numbers: number[]) => {
    let median = 0;
    let numsLen = numbers.length;
    numbers.sort((a: number, b: number) => a - b);

    if (
        numsLen % 2 === 0 // is even
    ) {
        // average of two middle numbers
        median = (numbers[numsLen / 2 - 1] + numbers[numsLen / 2]) / 2;
    } else { // is odd
        // middle number only
        median = numbers[(numsLen - 1) / 2];
    }
    return median;
}

// INPUT INTERFACES
interface inputDataInterface {
    attribute: inputAttribute
    values: inputValueInterface[];
}

interface inputAttribute {
    blockName: Translatable;
    label: Translatable;
}

interface inputValueInterface {
    attribute: {
        label: string;
    };
    values:inputEndValueInterface[];
}

interface inputEndValueInterface {
    label: number;
    value: {
        key: number;
        label: number;
    }
}

interface CustomSeriesOption extends Highcharts.SeriesLineOptions {
    data?: Array<(number|[(number|string), (number|null)]|null|PointOptionsObject) | CustomPoint>;
}

interface CustomPoint extends Highcharts.PointOptionsObject {
    custom?: {
        data: inputEndValueInterface[];
    }
}

interface CustomHighChartPoint extends Highcharts.Point {
    custom?: {
        data: inputEndValueInterface[];
    }
}

interface RepartitionInterface {
    [x: string] : {
        lower: number;
        equal: number;
        higher: number;
    }
}

// OUTPUT INTERFACES
interface outputDataInterface {
    series: Highcharts.SeriesLineOptions[];
    categories: outputCategoriesInterface[];
}

interface outputCategoriesInterface {
    blockName: Translatable;
    attribute: Translatable;
    label: Translatable;
}

const createHighchartData = (data: inputDataInterface[]): outputDataInterface => {
    const series: Highcharts.SeriesLineOptions[] = [];
    const categories: outputCategoriesInterface[] = [];

    data.forEach(d => {
        const currentCategoryIndex = categories.length;
        const currentCategory: outputCategoriesInterface = {
            blockName: d.attribute.blockName,
            attribute: d.attribute.label,
            label: d.attribute.label
        };

        categories.push(currentCategory);

        d.values.forEach(v => {
            const currentMedian: number = getMedian(v.values.map(vv => vv.value.key));
            let currentSerie: CustomSeriesOption = series.find(s => s.name === v.attribute.label);

            if (!currentSerie) {
                currentSerie = {
                    name: v.attribute.label,
                    type: "line",
                    data: [],
                };
                series.push(currentSerie);
            }

            currentSerie.data.push({
                x: currentCategoryIndex,
                y: currentMedian,
                custom: {
                    data: v.values
                }
            });
        });
    });
    
    return {
        series,
        categories,
    };
}

const orderRoutines = (fullRoutines: any[], series: Highcharts.SeriesLineOptions[]): Highcharts.SeriesLineOptions[] => {
    const temp: Highcharts.SeriesLineOptions[] = [];
    fullRoutines.forEach(r => {
        const selected = series.find(s => {
            return s.name === r.name;
        });
        if(selected) {
            temp.push(selected);
        }
    })

    return temp;
}

const proccessWithBenchAsRef = (benchRoutineName: string, series: Highcharts.SeriesLineOptions[]): Highcharts.SeriesLineOptions[] => {
    let benchSerie: Highcharts.SeriesLineOptions = null;
    const referedSeries: Highcharts.SeriesLineOptions[] = [];

    series.forEach(s => {
        if (s.name === benchRoutineName) benchSerie = s;
        else referedSeries.push(s);
    })

    benchSerie.enableMouseTracking = false;
    benchSerie.marker = {
        enabled: false
    };

    benchSerie.states = {
        inactive: {
            opacity: 1
        }
    };

    referedSeries.push(benchSerie);
    
    return referedSeries;
}

type ColorScale = string | Highcharts.GradientColorObject | Highcharts.PatternObject;
const applyColors = (routineObject: any, firstBench: string, colorScale: ColorScale[], series: Highcharts.SeriesLineOptions[]): void => {
    const firstColor = colorScale.shift();
    let colorIndex = 0;

    series.forEach(s => {
        let color = firstBench === s.name ? firstColor : colorScale[colorIndex++];
        s.color = color;
        const currentRoutine = routineObject.routines.find(r => r.name === s.name);

        if (currentRoutine) {
            currentRoutine.color = color;
        }

    })
}

const createTooltipFormatter = (bench, lang: string): Highcharts.TooltipFormatterCallbackFunction => {
    return function (this) {
      if (!this.point) return false;
      const category = this.point.category;
      const block = Chart.getObjectValueTranslation(category["blockName"], lang);
      const attribut = Chart.getObjectValueTranslation(
        category["attribute"],
        lang
      );

      const samePoint: CustomHighChartPoint[] = [];

      this.series.chart.series.forEach(s => {
        const found: CustomHighChartPoint[] = s.data.filter(d => d.x === this.point.x && d.y === this.point.y);
        if (found.length) {

            if (s.name !== bench) {
                samePoint.push(...found);
            }
        }
      });

      const repartition: RepartitionInterface = {};

      samePoint.forEach(s => {
          const current = repartition[s.series.name] = {
              lower: 0,
              equal: 0,
              higher: 0
          };

          s.custom.data.forEach(v => {
                if (v.value.key < 0) current.lower++;
                else if (v.value.key > 0) current.higher++;
                else current.equal++;
          })

      });

      if(samePoint.length === 0) return false;

      const body = `
        <div style="font-weight: bold; font-style: italic">${block}</div>
        <div style="font-weight: bold; font-style: italic">${attribut}</div>
        <table>
            <thead>
                <tr>
                    ${samePoint
                      .map(
                        (p) => `
                            <td style="color: ${p.color}">${p.series.name} :</td>
                            <td>${p.y}</td>
                        `
                      )
                      .join("")}
                </tr>
            </thead>
            <tbody>
                <tr>
                    ${samePoint
                      .map(
                        (p) => `
                            <td style="color: ${p.color}">${
                          p.series.name
                        } < ${bench}</td>
                            <td style="padding-right: 1em">${
                              repartition[p.series.name].lower
                            }</td>
                        `
                      )
                      .join("")}
                </tr>
                <tr>
                    ${samePoint
                      .map(
                        (p) => `
                            <td style="color: ${p.color}">${
                          p.series.name
                        } = ${bench}</td>
                            <td style="padding-right: 1em">${
                              repartition[p.series.name].equal
                            }</td>
                        `
                      )
                      .join("")}
                </tr>
                <tr>
                    ${samePoint
                      .map(
                        (p) => `
                            <td style="color: ${p.color}">${
                          p.series.name
                        } > ${bench}</td>
                            <td style="padding-right: 1em">${
                              repartition[p.series.name].higher
                            }</td>
                        `
                      )
                      .join("")}
                </tr>
            </tbody>
        <table>
      `;

      return body;
    };
}

const doWork = (data: inputDataInterface[], baseKey: string, lang: string, parameters: any, payload: any, routinesObject: any): void => {

    const simpleRoutines = [];
    // EXPECT ONLY ONE BENCH
    let benchRoutine = null;

    routinesObject.routines.forEach(routine => {
        if (routine.isBench) benchRoutine = routine;
        else simpleRoutines.push(routine);
    });

    const fullRoutines = [...simpleRoutines, benchRoutine];

    const { series, categories } = createHighchartData(data);

    applyColors(routinesObject, benchRoutine.name, [...Chart.colorScale], series);

    const ordered = orderRoutines(fullRoutines, series);
    const refered = proccessWithBenchAsRef(benchRoutine.name, ordered);
    const tooltipFormatter = createTooltipFormatter(benchRoutine.name, lang);

    payload.series = refered;
    payload.categories = categories;
    payload.tooltipFormatter = tooltipFormatter;
};

export {
    doWork,
}