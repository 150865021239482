import { ErrorManagerService } from './../../services/errorManager.service';
import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CampaignsMulti, Language, Languages, Workspace } from '../../../types';
import { WorkspaceService } from '../../../general/my-profile/workspaces/workspaces.service';

import * as _ from 'lodash';
import { DNATranslateService } from '../../services/translate.service';
import { UtilService } from '../../services/util.service';

@Component({
    selector: 'dna-milor-additionalinfosmulti-modal',
    templateUrl: './milorAdditionalInfosMulti.component.html'
})

export class MilorAdditionalInfosMultiComponent implements OnInit {

    @Input() campaign: CampaignsMulti;
    @Input() metier: string;
    @Input() recipients: { display: string, value: string }[] = [];
    @Input() title: string = "";
    @Input() lang: Language = Languages.English;
    @Input() fieldwork: string;
    @Input() orchestraNumber: string;

    private titleTemplate: string = "";
    public workspaceSelected: boolean = false;
    public workspacesSelected: any = {};
    public workspaces: Workspace[] = [];
    public languages: string[];
    public orchestraSelected: boolean = false;

    constructor(
        private activeModal: NgbActiveModal,
        private errorManager: ErrorManagerService,
        private workspaceService: WorkspaceService,
        private translateService: DNATranslateService,
        private utilService: UtilService
    ) { }

    ngOnInit() {
        this.translateService.getLanguages().then((res) => {
            this.languages = res;
        });
        this.titleTemplate = "EV - SEE - {{fieldwork}} - {{studyname}} - {{metier}} - {{orchestra}} Multi-study report - {{lang}}";
        this.workspaceService.getWorkspacesInLocal().subscribe(wk => this.workspaces = wk);
        this.workspaceSelected = false;
    }

    cancel() {
        this.activeModal.dismiss();
    }

    validate(title: string, recipients: { display: string, value: string }[]) {
        if (0 === title.length) {
            this.errorManager.displayMessage("TITLE_EMPTY", 'warning', { timeOut: 8000 });
        }
        else if (recipients.length === 0) {
            this.errorManager.displayMessage("RECIPIENTS_EMPTY_LIST", 'warning', { timeOut: 8000 });
        }
        else {
            this.activeModal.close({ "title": title, "recipients": recipients.map(r => r.value) });
        }
    }

    selectOption(element: any, event: any, content?: any) {
        if (event.stopPropagation) event.stopPropagation();
        switch (element) {
            case 'workspace':
                if (this.workspacesSelected[content.id]) {
                    delete this.workspacesSelected[content.id];
                } else {
                    this.workspacesSelected[content.id] = content.name;
                }
                break;
            case 'hybrid':
                break;
            case 'orchestra': {
                this.orchestraSelected = !this.orchestraSelected;
            }
            default:
                break;
        }

        this.updateTitle();
    }

    hybridStudySelected(isActive: boolean) {
        console.log(isActive);
        this.workspaceSelected = isActive;
    }

    changeLanguage(language: Language) {
        this.lang = language;
        this.updateTitle();
    }

    checkSameOrchestra(orchestraByStudies: { [idEtude: string]: string }[]): boolean {
        if (orchestraByStudies && orchestraByStudies.length) {
            const orchestra = Object.values(orchestraByStudies[0])[0];
            return orchestraByStudies.every((v) => {
                return Object.values(v)[0] === orchestra;
            });
        }
        else {
            return false;
        }
    }
    
    updateTitle() {
        let title = this.titleTemplate;
            title = title.replace("{{fieldwork}}", this.fieldwork);
            title = title.replace("{{studyname}}", this.campaign.name);
            if (this.workspaceSelected && this.workspacesSelected && Object.keys(this.workspacesSelected).length) {
                const metiers = Object.values(this.workspacesSelected).join(' - ');
                title = title.replace("{{metier}}", metiers);
            }
            else {
                title = title.replace("{{metier}}", this.metier);
            }
            title = title.replace("{{orchestra}}", this.orchestraSelected ? this.orchestraNumber : '');
            title = title.replace("{{lang}}", this.utilService.languageToISOCode(this.lang));
        this.title = title;
    }

    isOrchestraEnable() {
        return !_.isEmpty(this.orchestraNumber);
    }
}
