import {Chart} from '../chart.model';
import {BoxPlotOptions} from './boxplot-options.model';
import {Table} from '../table';
export class Boxplot extends Chart {
    protected _baseParameters: any = {
        chart: {
            type: 'boxplot',
            events: {
                load: function ( el ) {
                    this.parameters.series.forEach( ( serie: any ) => {
                        serie.color = el.target.series.find( ( x: any ) => x.name === serie.name ).color;
                    } );
                    this.buildGraphOptions( this.parameters );
                    this.onOptionChangeAfterLoadSubject.next( this.options );
                }.bind( this )
            }
        },
        xAxis: {
            type: 'category',
            crosshair: true
        },
        yAxis: {
            title: {
                enabled: true,
                text: ''
            }
        },
        tooltip: {
            shared: true
        }
    };

    constructor (
        _parameters?: any,
        _data?: Array<any>
    ) {
        super( _parameters, _data );
        this.generateParametersAssignment( this.parameters );
        this.generateDataAssignment( this.data, this.lang );
    };

    private generateParametersAssignment ( parameters ) {
        Object.assign( {...this._baseParameters, parameters} );
        Object.keys( {...this._baseParameters} ).forEach( ( k ) => {
            this.parameters[ k ] = Object.assign( {...this._baseParameters[ k ]}, parameters[ k ] );
        } );
        Object.keys( {...parameters} ).forEach( ( k ) => {
            this.parameters[ k ] = Object.assign( {...this.parameters[ k ]}, parameters[ k ] );
        } );
    };

    protected generateDataAssignment = ( data: any, lang: string ) => {
        this.data = data;
        const payload = this.formatData( data );
        this.parameters.series = payload.series;
        this.parameters.xAxis.categories = payload.categories;
    };

    /**
     * buildGraphOptions
     */
    public buildGraphOptions = ( options: any ) => {
        const {title, subtitle, xAxis, yAxis, series, ...rest} = options;
        this.options = new BoxPlotOptions( {
            general: {title, subtitle},
            xAxis,
            yAxis,
            series
        }, this.onOptionsChange );
    };

    protected setParamsToAxis = ( params: any, axis: any ) => Object.assign( axis, params );

    protected formatData = ( data: Array<any> ): {categories: Array<any>, series: Array<any>;} => {
        const payload = {
            categories: [],
            series: {}
        };
        if( !data ) return {
            categories: [],
            series: []
        };
        data.reduce( ( accumulator: any, data: any ) => {
            if( data.type ) {
                data.values.forEach( ( {label, value}: any ) => {
                    accumulator.series[ data.attribute ] = ( accumulator.series[ data.attribute ] || {name: data.attribute, series: [], type: data.type} );
                    ( accumulator.series[ data.attribute ].data = ( accumulator.series[ data.attribute ].data || [] ) ).push( value );
                } );
            } else {
                data.values.forEach( ( {label, value}: any ) => {
                    accumulator.categories[ label ] = null;
                    accumulator.series[ data.attribute ] = ( accumulator.series[ data.attribute ] || {name: data.attribute, series: []} );
                    ( accumulator.series[ data.attribute ].data = ( accumulator.series[ data.attribute ].data || [] ) ).push( value );
                } );
            }
            return accumulator;
        }, payload );
        return {
            categories: payload.categories,
            series: Object.values( payload.series )
        };
    };

    private onOptionsChange = ( options: any ) => {
        const routinesMapping: Map<string, any> = new Map();
        let gotColorChange = false;

        if( this.routines.routines ) {
            this.routines.routines.forEach( r => {
                routinesMapping.set( r.name, r );
            } );
        }

        let fontOptions: any;

        for( let o in options ) {
            if( o === 'general' ) {
                const {font, ...rest} = options[ o ];
                Object.assign( this.parameters, rest );
                fontOptions = font;
            } else {
                for( let e in options[ o ] ) {
                    if( Array.isArray( options[ o ][ e ] ) ) {
                        options[ o ][ e ].forEach( x => {
                            Object.assign( this.parameters[ e ].find( y => y.name === x.name ), x );
                            if( e === 'series' && routinesMapping.has( x.name ) ) {
                                gotColorChange = true;
                                routinesMapping.get( x.name ).color = x.color;
                            }
                        } );
                    } else {
                        Object.assign( this.parameters[ e ], options[ o ][ e ] );
                    }
                }
            }
        }
        const {yAxis, xAxis, ...parameters} = this.parameters;
        Object.assign( this.parameters, {...parameters} );
        Object.assign( this.parameters.yAxis, yAxis );
        Object.assign( this.parameters.xAxis, xAxis );

        if( gotColorChange ) this.custom.updateRoutines( this.routines.routines.map( x => x ) );

        const chart = this.build();
        if( fontOptions ) this.updateChartFont( chart, fontOptions );
    };

    protected formatRawData = ( data: Array<any>, lang: string ): {header: Array<any>, body: Array<any>;} => {
        const tableTemp = new Table( {}, data, lang );
        //console.log(tableTemp);
        return {
            header: [],
            body: []
        };
    };

    private updateChartFont = ( chart: Highcharts.Chart, option: {size: number, bold: boolean;} ): void => {
        try {
            chart.yAxis[ 0 ].update( {
            labels: {
                ...chart.yAxis[0].userOptions.labels,
                style: {
                fontSize: option.size + 'px',
                fontWeight: option.bold ? 'bold' : 'normal',
                }
            }
            }, true );
    
            chart.xAxis[ 0 ].update( {
            labels: {
                ...chart.xAxis[ 0 ].userOptions.labels,
                style: {
                fontSize: option.size + 'px',
                fontWeight: option.bold ? 'bold' : 'normal'
                }
            }
            }, true );
        } catch( e ) {
            console.error('error updating chart font, boxplot');
        }
    }

};