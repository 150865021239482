<dna-layout>
    <h1 class="mb-4" id="banner-title" translate>MULTI_CAMPAIGNS</h1>
    <nav aria-label="breadcrumb" id="banner-nav">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/dashboard" translate>DASHBOARD</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page" translate>MULTI_CAMPAIGNS</li>
      </ol>
    </nav>
    <div class="block">
      <div class="ibox float-e-margins">
        <div class="ibox-title row cursor-pointer noMarginSide" aria-controls="campaignsMultiFilter" (click)="isCollapsedCampaignsFilter = !isCollapsedCampaignsFilter">
          <h5 class="col-md-6" translate>FILTERS</h5>
          <div class="ibox-tools col-md-6">
            <a class="btn btn-primary btn-xs" (click)="initFilters($event)" translate>INIT_FILTERS</a>
            <a>
              <i *ngIf="isCollapsedCampaignsFilter; else isNotCollapsedCampaignsFilter" class="fa fa-chevron-down"></i>
              <ng-template #isNotCollapsedCampaignsFilter><i class="fa fa-chevron-up"></i></ng-template>
            </a>
          </div>
        </div>
        <div id="campaignsMultiFilter" [ngbCollapse]="isCollapsedCampaignsFilter">
          <div class="ibox-content">
            <ngx-loading [show]="showSpinner"></ngx-loading>
            <div class="row py-2">
              <div class="col-12 col-md-4">
                <dna-search-text #searchTextMultiCampaignName class="search" (onSearch)="updateData($event, 'name')">
                </dna-search-text>
              </div>
              <div class="col-12 col-md-4">
                <dna-search-text #searchTextMultiCampaignSynergy class="search" [placeholder]="'PROJECTS.PROJECT_BRIDGE'" (onSearch)="updateData($event, 'bridge')">
                </dna-search-text>
              </div>
              <div class="col-12 col-md-4">
                <dna-search-text #searchTextMultiCampaignActiview class="search" [placeholder]="'PROJECTS.PROJECT_NUMBER'" (onSearch)="updateData($event, 'actiview')">
                </dna-search-text>
              </div>
            </div>
            <div class="row py-2">
              <div class="col-12 col-md-4">
                <tag-input inputClass="tagInputCampaigns" [disable]="listWorkflows.length === 0" [ngModel]="filter.workflows" (ngModelChange)="updateObject($event, filter, 'workflows')"
                  displayBy="name" identifyBy="id" name="filterWorkflows" [onlyFromAutocomplete]="false" placeholder="+ {{ 'WORKFLOW' | translate }}"
                  [secondaryPlaceholder]="wkLabel">
                  <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="listWorkflows" displayBy="name" identifyBy="name" [minimumTextLength]="1"
                    [showDropdownIfEmpty]="false">
                  </tag-input-dropdown>
                </tag-input>
              </div>
              <div class="col-12 col-md-4">
                <tag-input inputClass="tagInputCampaigns" [ngModel]="filter.formulas" (ngModelChange)="updateObject($event, filter, 'formulas')"
                  displayBy="name" identifyBy="name" name="filterFormulas" [onlyFromAutocomplete]="true" placeholder="+ {{ 'FORMULA' | translate }}"
                  secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_FEM' | translate: { object: 'FORMULA' | translate | lowercase } }}">
                  <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="listFormulas" displayBy="name" identifyBy="name" [minimumTextLength]="1"
                    [showDropdownIfEmpty]="false">
                  </tag-input-dropdown>
                </tag-input>
              </div>
              <div class="col-12 col-md-4">
                <tag-input inputClass="tagInputCampaigns" [ngModel]="filter.users"  (ngModelChange)="updateObject($event, filter, 'users')"
                  displayBy="name" identifyBy="key" name="filterUsers" [onlyFromAutocomplete]="true" placeholder="+ {{ 'USER' | translate }}"
                  secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: {'object': 'USER' | translate | lowercase } }}">
                  <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="listUsers" displayBy="name" identifyBy="key" [showDropdownIfEmpty]="false"></tag-input-dropdown>
                </tag-input>
              </div>
            </div>
            <div class="row py-2">
              <div class="col-12">
                <dna-filter-list [horizontalAlignment]="true" [array]="hubs" [filterValue]="filter.hubs">
                </dna-filter-list>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-lg-6 mb-3 my-lg-auto">
                <dna-filter-date [dateFilterStart]="filter.startDate" [dateFilterEnd]="filter.endDate" (updateDateStart)="updateDate($event, 'startDate')"
                  (updateDateEnd)="updateDate($event, 'endDate')">
                </dna-filter-date>
              </div>
            </div>
            <div class="search-button">
              <a class="btn btn-primary mb-4" (click)="searchCampaignMulti()">
                <span translate>SEARCH</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <dna-table2 [data]="campaignsMulti" [headers]="tableHeaders$ |async" [numberOfObjectsPerPage]="filter.limit" [pageIndex]="filter.page"
      (parametersChanged)="onParametersChanged($event)" [reverse]="true" [sortFunction]="sortMulti" [sortable]="true" [title]="'MULTI_CAMPAIGNS'" [totalItems]="totalItems">
      <ng-template #row let-data="data">
        <tr dna-multi-campaigns-row [campaignMulti]="data" (onClickAction)="onClickActionButton($event, data)"></tr>
      </ng-template>
    </dna-table2>

        <!-- <dna-table [data]="campaignsMulti" [headers]="tableHeaders$ | async" [numberOfObjectsPerPage]="filter.numberOfObjectsPerPage"
          orderedBy="DATE_MODIFICATION" [pageIndex]="filter.pageIndex" [reverse]="true" [sortFunction]="sort" [title]="'MULTI_CAMPAIGNS'"
          (parametersChanged)="onParametersChanged($event)">
          <ng-template #row let-data="data">
            <tr dna-multi-campaigns-row [campaignMulti]="data" (onClickAction)="onClickActionButton($event, data)"></tr>
          </ng-template>
        </dna-table> -->

    </div>
  </dna-layout>
