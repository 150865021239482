import { UtilService } from '@app/shared/services/util.service';
import { DNAComponent } from '@app/types';

import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dna-counter-side-config',
    templateUrl: './counterSideConfiguration.component.html',
    styleUrls: ['./counterSideConfiguration.css']
})

export class CounterSideConfigurationComponent implements OnInit {
    @Input() inactive: boolean;
    @Input() component: DNAComponent;

    constructor(private utilService: UtilService) { }

    ngOnInit() {
    }
}
