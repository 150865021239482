<dna-layout>
  <h1 class="mb-4" id="banner-title" translate>CAMPAIGNS</h1>
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" translate>DASHBOARD</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>CAMPAIGNS</li>
    </ol>
  </nav>
  <div class="actions" id="banner-actions">
    <a class="btn btn-primary mb-4" (click)="openModalNewCampaign()">
      <span translate>CREATE </span>
      <span translate>CAMPAIGN</span>
    </a>
  </div>
  <div class="block">
    <div class="ibox float-e-margins">
      <div class="ibox-title row cursor-pointer noMarginSide" aria-controls="campaignsFilter" (click)="isCollapsedCampaignsFilter = !isCollapsedCampaignsFilter">
        <h5 class="col-md-6" translate>FILTERS</h5>
        <div class="ibox-tools col-md-6">
          <a class="btn btn-primary btn-xs" (click)="resetFilters($event)" translate>INIT_FILTERS</a>
          <a>
            <i *ngIf="isCollapsedCampaignsFilter; else isNotCollapsedCampaignsFilter" class="fa fa-chevron-down"></i>
            <ng-template #isNotCollapsedCampaignsFilter><i class="fa fa-chevron-up"></i></ng-template>
          </a>
        </div>
      </div>
      <div id="campaignsFilter" [ngbCollapse]="isCollapsedCampaignsFilter">
        <div class="ibox-content">
          <ngx-loading [show]="showSpinner"></ngx-loading>
          <div class="row py-2">
            <div class="col-12 col-md-4">
              <dna-search-text #searchTextCampaignName class="search" [placeholder]="'CAMPAIGN_NAME'" (keydown.enter)="searchCampaign()" (onSearch)="updateData($event, 'name')">
              </dna-search-text>
            </div>
            <div class="col-12 col-md-4">
              <dna-search-text #searchTextSynergyNumber  class="search" [placeholder]="'PROJECTS.PROJECT_BRIDGE'" (keydown.enter)="searchCampaign()" (onSearch)="updateData($event, 'bridge')">
              </dna-search-text>
            </div>
            <div class="col-12 col-md-4">
              <dna-search-text #searchTextActiviewNumber  class="search" [placeholder]="'PROJECTS.PROJECT_NUMBER'" (keydown.enter)="searchCampaign()" (onSearch)="updateData($event, 'actiview')">
              </dna-search-text>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-12 col-md-4">
              <tag-input inputClass="tagInputCampaigns" [ngModel]="filter.users"  (ngModelChange)="updateObject($event, filter, 'users')"
                displayBy="name" identifyBy="key" name="filterUsers" [onlyFromAutocomplete]="true" placeholder="+ {{ 'USER' | translate }}"
                secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: {'object': 'USER' | translate | lowercase } }}">
                <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="listUsers" displayBy="name" identifyBy="key" [showDropdownIfEmpty]="false"></tag-input-dropdown>
              </tag-input>
            </div>
            <div class="col-12 col-md-4">
              <tag-input inputClass="tagInputCampaigns" [ngModel]="filter.workflows" (ngModelChange)="updateObject($event, filter, 'workflows')"
                displayBy="name" identifyBy="id" name="filterWorkflows" [onlyFromAutocomplete]="false" placeholder="+ {{ 'WORKFLOW' | translate }}"
                [secondaryPlaceholder]="wkLabel">
                <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="listWorkflows" displayBy="name" identifyBy="id" [minimumTextLength]="1"
                  [showDropdownIfEmpty]="false">
                </tag-input-dropdown>
              </tag-input>
            </div>
            <div class="col-12 col-md-4">
              <tag-input inputClass="tagInputCampaigns" [ngModel]="filter.formulas" (ngModelChange)="updateObject($event, filter, 'formulas')"
                displayBy="name" identifyBy="name" name="filterFormulas" [onlyFromAutocomplete]="true" placeholder="+ {{ 'FORMULA' | translate }}"
                secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_FEM' | translate: { object: 'FORMULA' | translate | lowercase } }}">
                <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="listFormulas" displayBy="name" identifyBy="name" [minimumTextLength]="1"
                  [showDropdownIfEmpty]="false">
                </tag-input-dropdown>
              </tag-input>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-12 col-md-3">
              <dna-filter-list [array]="hubs" [filterValue]="filter.hubs">
              </dna-filter-list>
            </div>
            <div class="col-12 col-md-3">
              <dna-filter-list [array]="states" [filterValue]="filter.states"></dna-filter-list>
            </div>
            <div class="col-12 col-md-3">
              <dna-filter-list [array]="typeCampaigns" [filterValue]="filter.studyType" fieldToDisplay="name"></dna-filter-list>
            </div>
            <div class="col-12 col-md-3">
              <dna-filter-list [array]="typeEvaluations" [filterValue]="filter.typeEvaluation" fieldToDisplay="name"></dna-filter-list>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12 col-lg-6 mb-3 my-lg-auto">
              <dna-filter-date [dateFilterStart]="filter.startDate" [dateFilterEnd]="filter.endDate" (updateDateStart)="updateDate($event, 'startDate')"
                (updateDateEnd)="updateDate($event, 'endDate')">
              </dna-filter-date>
            </div>
            <div class="col-12 col-lg-6 my-auto">
              <div class="row">
                <label class="col-9 control-label" translate>DISPLAY_ONLY_TEMPLATE</label>
                <dna-switch class="col-3" [isSelected]="filter.displayTemplate" (onBooleanChanged)="searchTemplate()">
                </dna-switch>
              </div>
              <div class="row">
                <label class="col-9 control-label" translate>ONLY_DISPLAY_FAVORITE</label>
                <dna-switch class="col-3" [isSelected]="filter.displayFavoritesOnly" (onBooleanChanged)="filter.displayFavoritesOnly = !filter.displayFavoritesOnly">
                </dna-switch>
              </div>
              <div class="row">
                <label class="col-9 control-label" translate>DISPLAY_ONLY_OWN</label>
                <dna-switch class="col-3" [isSelected]="filter.byUser" (onBooleanChanged)="filter.byUser = !filter.byUser"></dna-switch>
              </div>
            </div>
          </div>
          <div class="search-button">
            <a class="btn btn-primary mb-4" (click)="searchCampaign()">
              <span translate>SEARCH</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="metierName" class="ibox float-e-margins">
      <div class="ibox-title row cursor-pointer noMarginSide" aria-controls="protocolFilter" (click)="isCollapsedCampaignsFilterProtocol = !isCollapsedCampaignsFilterProtocol">
        <h5 class="col-md-6" translate>PROTOCOL</h5>
        <div class="ibox-tools col-md-6">
          <a *ngIf="isChangedFilterProtocol" class="btn btn-primary btn-xs" (click)="clearFilters('protocol', $event)" translate>CLEAR_FILTERS</a>
          <a>
            <i *ngIf="isCollapsedCampaignsFilterProtocol; else isNotCollapsedCampaignsFilterProtocol" class="fa fa-chevron-down"></i>
            <ng-template #isNotCollapsedCampaignsFilterProtocol><i class="fa fa-chevron-up"></i></ng-template>
          </a>
        </div>
      </div>
      <div id="protocolFilter" [ngbCollapse]="isCollapsedCampaignsFilterProtocol">
        <dna-filters-protocol *ngIf="!isCollapsedCampaignsFilterProtocol" [filter]="filter.protocol" [metierName]="metierName" (onUpdateFilterProtocol)="updateFilterProtocol($event)"></dna-filters-protocol>
        <div class="search-button">
          <a class="btn btn-primary mb-4" (click)="searchCampaign()">
            <span translate>SEARCH</span>
          </a>
        </div>
      </div>
     </div>

    <div *ngIf="metierName" class="ibox float-e-margins">
      <div class="ibox-title row cursor-pointer noMarginSide" aria-controls="targetFilter" (click)="isCollapsedCampaignsFilterTarget = !isCollapsedCampaignsFilterTarget">
        <h5 class="col-md-6" translate>TARGET</h5>
        <div class="ibox-tools col-md-6">
          <a *ngIf="isChangedFilterTarget" class="btn btn-primary btn-xs" (click)="clearFilters('target', $event)" translate>CLEAR_FILTERS</a>
          <a>
            <i *ngIf="isCollapsedCampaignsFilterTarget; else isNotCollapsedCampaignsFilterTarget" class="fa fa-chevron-down"></i>
            <ng-template #isNotCollapsedCampaignsFilterTarget><i class="fa fa-chevron-up"></i></ng-template>
          </a>
        </div>
      </div>
      <div id="targetFilter" [ngbCollapse]="isCollapsedCampaignsFilterTarget">
        <dna-filters-target *ngIf="!isCollapsedCampaignsFilterTarget" [filter]="filter.target" [metierName]="metierName" (onUpdateFilterTarget)="updateFilterTarget($event)"></dna-filters-target>
        <div class="search-button">
          <a class="btn btn-primary mb-4" (click)="searchCampaign()">
            <span translate>SEARCH</span>
          </a>
        </div>
      </div>
    </div>

    <div *ngIf="campaignsSelected.length > 0" class="ibox float-e-margins">
      <div class="ibox-title">
        <div class="ibox-tools">
          <button [ngbTooltip]="!campaignsCompatible ? ('ERROR_ROUTINE_CAMPAIGN' | translate) : ''" class="btn btn-primary btn-sm" [disabled]="!campaignsCompatible" (click)="analyse(campaignsSelected)" translate>ANALYSE</button>
        </div>
      </div>
      <div class="ibox-content table-responsive">
        <table class="table table-hover table-borderless-th dataTable">
          <thead>
            <tr>
              <th *ngFor="let header of tableHeaders$ | async">
                <i *ngIf="header.icon" [ngClass]="header.icon"></i> {{ header.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-template ngFor let-campaign [ngForOf]="campaignsSelected">
              <tr dna-campaigns-row [campaign]="campaign" [users]="listUsers" (onClickAction)="onClickActionButton($event, campaign.id)"
                (onClickSwitch)="onSwitchChanged($event, campaign)" (onSend)="onSendDatalakeData($event)" [ngClass]="{'is-not-routine': !campaignsCompatible && campaign.parameters.isRoutine === false}"></tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>

    <ng-container *ngIf="listUsers">
      <!-- <dna-table [data]="campaignsFiltered" [headers]="tableHeaders$ | async" [numberOfObjectsPerPage]="filter.numberOfObjectsPerPage"
        orderedBy="DATE_MODIFICATION" [pageIndex]="filter.pageIndex" [reverse]="true" [sortFunction]="sort" [title]="'CAMPAIGNS'"
        (parametersChanged)="onParametersChanged($event)">
        <ng-template #row let-data="data">
          <tr dna-campaigns-row [campaign]="data" [users]="listUsers" (onClickAction)="onClickActionButton($event, data.id)" (onClickSwitch)="onSwitchChanged($event, data)"
            (onSend)="onSendDatalakeData($event)"></tr>
        </ng-template>
      </dna-table> -->

      <dna-table2
        [data]="campaignsFiltered"
        [headers]="tableHeaders$ |async"
        [numberOfObjectsPerPage]="filter.limit"
        [pageIndex]="filter.page"
        (parametersChanged)="onParametersChanged($event)"
        [reverse]="true"
        [sortFunction]="sortContent"
        [sortable]="true"
        [title]="'CAMPAIGNS'"
        [totalItems]="totalItems"
      >
      <ng-template #row let-data="data">
        <tr dna-campaigns-row [campaign]="data" [users]="listUsers" (onClickAction)="onClickActionButton($event, data.id)" (onClickSwitch)="onSwitchChanged($event, data)"
        (onSend)="onSendDatalakeData($event)"></tr>
      </ng-template>
    </dna-table2>
    </ng-container>

  </div>
</dna-layout>
