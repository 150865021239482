
import {finalize} from 'rxjs/operators';
import {
  Component,
  Input,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';
import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import {
  Answer,
  Evaluation,
  RecordData
} from './../../../../../../types';
import {
  ErrorManagerService
} from '../../../../../../shared/services/errorManager.service';
import {
  EvaluationService
} from '../../evaluations.service';
import {
  ModalEditAnswerComponent
} from '../edit-answer/modal-edit-answer.component';

@Component({
  selector: '[dna-detail-evaluation-row]',
  templateUrl: './detail-evaluation-row.component.html',
  styleUrls: ['./detail-evaluation-row.component.less']
})
export class DetailEvaluationRowComponent implements OnInit {

  @Input() answer: Answer;
  @Input() currentLanguage: string;
  @Input() evaluation: Evaluation;
  @Input() idCampaign: string;

  datas: RecordData[];
  showSpinner: boolean = false;

  user: {
    key: string,
    name?: string
  };

  modalOption: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: "lg"
  };

  constructor(
    private errorManagerService: ErrorManagerService,
    private evaluationService: EvaluationService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.datas = _.get(this.answer, 'records[0].data', []);
    this.user = this.evaluation.users.find(
      (u: {
        key: string,
        name?: string
      }) => u.key === this.answer.peopleKey
    );
  }

  openModal(answerIndex: number) {
    const modal = this.modalService.open(ModalEditAnswerComponent, this.modalOption);
    modal.componentInstance.answer = this.evaluation.answers[answerIndex];
    modal.componentInstance.currentLanguage = this.currentLanguage;
    modal.result.then(
      (answerUpated) => {
        this.showSpinner = true;
        this.evaluation.answers[answerIndex] = answerUpated;

        this.evaluationService.updateEvaluationBlocks(this.idCampaign, this.evaluation).pipe(
          finalize(() => this.showSpinner = false))
          .subscribe(success => console.log(),
          error => this.errorManagerService.displayMessage(error, "danger"));
      },
      (reason) => { }
    );
  }

}
