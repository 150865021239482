import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, tap } from 'rxjs/operators';

import { Hub } from '../../types';
import { ReferenceTypeService } from '../services/reference-type.service';
import * as _ from 'lodash';
import { BlockService } from '../../blocks/blocks.service';
import { WorkflowService } from '../../workflows/workflows.service';

@Component({
  selector: 'dna-edit-name-and-hub',
  templateUrl: './edit-name-and-hub.component.html',
  styleUrls: ['./edit-name-and-hub.component.less']
})
export class EditNameAndHubComponent implements OnInit, OnChanges {

  @Input() data: any;
  @Input() languages: string[];

  @Output() onChanges: EventEmitter<any> = new EventEmitter();

  hubs: Hub[] = [];
  editForm: FormGroup;

  constructor(
    private referenceTypeService: ReferenceTypeService,
    private blockService: BlockService,
    private workflowService: WorkflowService
  ) { }

  ngOnInit() {
    this.hubs = this.referenceTypeService.getHubs();
    this.initForm(this.data);
    this.initValueChanges();
    this.blockService.updateComponentEditName.subscribe((block) => {
      this.initForm(block);
      this.initValueChanges();
    });
    this.workflowService.updateComponentEditName.subscribe((workflow) => {
      this.initForm(workflow);
      this.initValueChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue !== changes.data.previousValue) {}
  }

  initValueChanges() {
    this.editForm.valueChanges.pipe(
      tap(() => Object.assign(this.data, this.editForm.value)),
      debounceTime(1000),
      tap(() => this.onChanges.emit(this.editForm.value))
    ).subscribe();
  }

  private initForm(data) {
    this.editForm = new FormGroup({
      name: new FormGroup({
        english: new FormControl(data.name.english),
        french: new FormControl(data.name.french),
        japanese: new FormControl(data.name.japanese),
        chinese: new FormControl(data.name.chinese),
        portuguese: new FormControl(data.name.portuguese),
        spanish: new FormControl(data.name.spanish),
      }),
      description: new FormGroup({
        english: new FormControl(data.description.english),
        french: new FormControl(data.description.french),
        japanese: new FormControl(data.description.japanese),
        chinese: new FormControl(data.description.chinese),
        portuguese: new FormControl(data.description.portuguese),
        spanish: new FormControl(data.description.spanish),
      }),
      hub: new FormControl(data.hub)
    });
  }

}
