
import {from as observableFrom, of as observableOf,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { UserService } from '../services/user.service';

@Injectable()
export class PowerBIService {
    serverUrlStudio: string;

    constructor(
        private http: HttpClient,
        private userService: UserService
    ) {
        this.serverUrlStudio = environment.server_url_studio();
    }

    /**
     * Authentication requests
     */
    getAppAuthenticationToken(): Observable<any> {
        return this.http.get<any>(this.serverUrlStudio + '/admin/applicationtoken');
    }

    getUserAuthenticationToken(): Observable<any> {
        return this.http.get<any>(this.serverUrlStudio + '/users/' + this.userService.getUser().track + '/accesstoken');
    }

    /**
     * Get header for requests
     */
    getPowerBIHeader(): Promise<HttpHeaders> {
        return new Promise((resolve: any, reject: any) => {
            let header = new HttpHeaders().append('Accept', '');
            this.getUserAuthenticationToken().subscribe(
                data => {
                    header = header.append('Authorization', 'Bearer ' + data.accessToken);
                    resolve(header);
                },
                error => {
                    let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IndVTG1ZZnNxZFF1V3RWXy1oeFZ0REpKWk00USIsImtpZCI6IndVTG1ZZnNxZFF1V3RWXy1oeFZ0REpKWk00USJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvZTRlMWFiZDktZWFjNy00YTcxLWFiNTItZGE1Yzk5OGFhN2JhLyIsImlhdCI6MTU0NDQ1MDQ1OCwibmJmIjoxNTQ0NDUwNDU4LCJleHAiOjE1NDQ0NTQzNTgsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiI0MlJnWUJEdkNoTnRTSFhTS0xqMGJyWEIzQVBQODlzeU1sZGt4dXlhL09qT1JsN1dNMklBIiwiYW1yIjpbIndpYSJdLCJhcHBpZCI6Ijg3MWMwMTBmLTVlNjEtNGZiMS04M2FjLTk4NjEwYTdlOTExMCIsImFwcGlkYWNyIjoiMiIsImZhbWlseV9uYW1lIjoiTFkiLCJnaXZlbl9uYW1lIjoiSGVucnkiLCJpbl9jb3JwIjoidHJ1ZSIsImlwYWRkciI6IjE2NS4yMjUuNzYuMjAyIiwibmFtZSI6IkxZIEhlbnJ5IC0gQ0FQRklPYmplY3RpZiIsIm9pZCI6ImMxN2NiYmY0LTdjYTQtNDI5Yy1hNzg4LTNkMDI1ZDI3M2YwNyIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS0xNzA4NTM3NzY4LTE0NTA5NjA5MjItNjgyMDAzMzMwLTIwNzI2NCIsInB1aWQiOiIxMDAzQkZGREE3Q0UyNTEyIiwic2NwIjoidXNlcl9pbXBlcnNvbmF0aW9uIiwic3ViIjoiMTJ2eVJsVklRYURSdFJwY2ZTRXVsS0lRMmdsR0FBNVBNYm9OSkl2T1JyQSIsInRpZCI6ImU0ZTFhYmQ5LWVhYzctNGE3MS1hYjUyLWRhNWM5OThhYTdiYSIsInVuaXF1ZV9uYW1lIjoiaGVucnkubHlAcmQubG9yZWFsLmNvbSIsInVwbiI6ImhlbnJ5Lmx5QHJkLmxvcmVhbC5jb20iLCJ1dGkiOiJfbnJmVDVNRERVV1A4T2x0ejFUaEFBIiwidmVyIjoiMS4wIn0.CbjFSvWJHuyG-da5YagGH-DLRwRCWXDyC02873IsDnF7Jqg1ZA0Vnny83qdKbUiwj8NwOZY5uciv9Y9Lb_ujSscjYl8iD9uhBBY4-DXfsaPbtv1mv45Um6PrYHYw3Z3lllTdHjWCldIfI4Q1ELq4tPmFQ85VYGMNOuzgSjqg451hlb6N6IYKT2rc_dxjd0aLuOyVKTvJ_NHFjnv2iw_nMtoLyeDd-kO_f-B4MD24EJm0gOR4Ot403jefwJaBK7EhwpVRniy34hdybrNJ-YGZAm6Rosmr2qdHcb2EaGFqIhHIr2_qSogImkyIGIuRZL6vTT7mI_jbTGm2zHbPoEvlrw';
                    header = header.append('Authorization', 'Bearer ' + token);
                    resolve(header);
                }
            );
        });
    }

    /**
     * Request from and to PowerBI
     */
    getDashboards(idGroup:string): Observable<any> {
        if (!idGroup) return observableOf({value:[]});
        return observableFrom(this.getPowerBIHeader()).pipe(mergeMap(
            headers => {
                return this.http.get<any>('https://api.powerbi.com/v1.0/myorg/groups/' + idGroup + '/dashboards', {
                    headers: headers
                });
            }));
    }

    // For test: d52011d2-a3e7-41b6-8cfb-e8ee8cc039dd
    getReportById(idReport: string): Observable<any> {
        return observableFrom(this.getPowerBIHeader()).pipe(mergeMap(
            headers => {
                return this.http.get<any>('https://app.powerbi.com/v1.0/myorg/groups/56eda58f-4ce1-4ece-bb2f-21bf76bd0e3a/reports/' + idReport, {
                    headers: headers
                });
            }));
    }

    getReports(idGroup:string): Observable<any> {
        if (!idGroup) return observableOf({value:[]});
        return observableFrom(this.getPowerBIHeader()).pipe(mergeMap(
            headers => {
                return this.http.get<any>('https://api.powerbi.com/v1.0/myorg/groups/' + idGroup + '/reports', {
                    headers: headers
                });
            }));
    }

    getWorkspaces(): Observable<any> {
        return observableFrom(this.getPowerBIHeader()).pipe(mergeMap(
            headers => {
                return this.http.get<any>('https://api.powerbi.com/v1.0/myorg/groups', {
                    headers: headers
                });
            }));
    }

    getTilesFromDashboard(idGroup:string, idDashboard: string): Observable<any> {
        if (!idGroup) return observableOf({value:[]});
        return observableFrom(this.getPowerBIHeader()).pipe(mergeMap(
            headers => {
                return this.http.get<any>('https://api.powerbi.com/v1.0/myorg/groups/' + idGroup + '/dashboards/' + idDashboard + '/tiles', {
                    headers: headers
                });
            }));
    }

}