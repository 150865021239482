import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ChartOption } from '../../interfaces';

interface itemInterface {
  name: string;
  form: AbstractControl;
}

@Component({
  selector: 'dna-group-checkbox',
  templateUrl: './group-checkbox.component.html',
  styleUrls: ['./group-checkbox.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupCheckboxComponent implements OnInit {

  @Input() label: any;
  @Input() data: ChartOption[];
  @Input() currentForm: FormGroup;
  @Input() closingEvent: Observable<void>;

  public open: boolean = false;
  private closingSubscription: Subscription;
  public filter: string = "";

  constructor() { }

  ngOnInit() {
    this.bindEvent(this.data);
    this.closingSubscription = this.closingEvent.subscribe(() => {
      this.open = false;
    });
  }

  bindEvent(data: ChartOption[]) {
    for(let [key, current] of Object.entries(this.currentForm.controls)) {
      const currentPoint = data.find(d => d.key === key);
      current.valueChanges.subscribe((newValue) => {
        if(currentPoint && currentPoint.events && currentPoint.events.change) {
          currentPoint.events.change(newValue);
        }
      })
    }
  }

  onToggle() {
    this.open = !this.open;
  }

  ngOnDestroy() {
    this.closingSubscription.unsubscribe();
  }

  getItems() {
    const regex = new RegExp(`.*${this.filter.trim()}.*`, 'i');
    const items: itemInterface[] = [];

    for(const [key, form] of Object.entries(this.currentForm.controls)) {
      if (regex.test(key)) {
        items.push({
          name: key,
          form: form
        })
      }
    }

    return items;
  }
}
