import { Translatable } from '../../../../../../../../../types';
import { Chart } from '../../chart.model';
import { ColumnChart } from '../columnchart.model';
import * as Highcharts from 'highcharts';

const getMedian = (numbers) => {
    let median = 0;
    let numsLen = numbers.length;
    numbers.sort((a: number, b: number) => a - b);

    if (
        numsLen % 2 === 0 // is even
    ) {
        // average of two middle numbers
        median = (numbers[numsLen / 2 - 1] + numbers[numsLen / 2]) / 2;
    } else { // is odd
        // middle number only
        median = numbers[(numsLen - 1) / 2];
    }
    return median;
}

// INPUT INTERFACES
interface inputDataInterface {
    attribute: inputAttribute
    values: inputValueInterface[];
}

interface inputAttribute {
    blockName: Translatable;
    label: Translatable;
}

interface inputValueInterface {
    attribute: {
        label: string;
    };
    values:inputEndValueInterface[];
}

interface inputEndValueInterface {
    label: number;
    value: {
        key: number;
        label: number;
    }
}

// OUTPUT INTERFACES
interface outpoutData {
    series: Highcharts.SeriesColumnOptions[];
    categories: outputCategories[];
    maxY: number;
}

interface outputCategories {
    blockName: Translatable;
    attribute: Translatable;
    label: string
}

// HELPER INTERFACES
interface PerCatSerieInterface {
    category: number;
    first: Highcharts.Point;
    values: number[];
    firstValue: number;
    firstName: string;
    secondName: string;
}

const createSeriesDatas = (data: inputValueInterface[] ,series: Highcharts.SeriesColumnOptions[], currentCategoryIndex: number): number => {
    let maxY = 0;
    data.forEach(d => {
        let currentSeries = series.find(s => s.name === d.attribute.label);
        // CREATE SERIES IF NOT EXIST
        if(!currentSeries) {
            currentSeries = {
                name: d.attribute.label,
                type: "column",
                data: [],
                events: {
                    click: null
                }
            };

            series.push(currentSeries);
        }
        // Get Median
        let currentValue = getMedian(d.values.map(sd => sd.value.key));

        // FILTER NON NUMERIC VALUE ADD POINT INTO SERIE
        if( !isNaN(currentValue) && currentValue as unknown as string !== "") currentSeries.data.push({
            x: currentCategoryIndex,
            y: currentValue,
        });

        maxY = Math.max(maxY, currentValue);
    })

    return maxY;
}

const createHighchartData = (data: inputDataInterface[]): outpoutData => {
    const series: Highcharts.SeriesColumnOptions[] = [];
    const categories: outputCategories[] = [];
    let maxY = 0;
    data.forEach(d => {
        const currentCategoryIndex = categories.length;
        categories.push({
            blockName: d.attribute.blockName,
            attribute: d.attribute.label,
            label: ""
        });
        const currentMaxY = createSeriesDatas(d.values, series, currentCategoryIndex);
        maxY = Math.max(currentMaxY, maxY);
    });

    return {
        series,
        categories,
        maxY
    };
}

const orderRoutines = (fullRoutines: any[], series: any[]) => {
    const temp = [];
    const tempSeries = series.map(s => s);

    fullRoutines.forEach(r => {
        const selectedIndex = tempSeries.findIndex(s => s.name === r.name);
        if (selectedIndex !== -1) {
            temp.push(tempSeries.splice(selectedIndex, 1)[0]);
        }
    });

    return temp.concat(tempSeries) ;
}

const applyBenchOnClick = (bench: string[], series: any[], routinesObject: any, chart: any) => {
    const benchFound = [];

    series.forEach(s => {
        if (bench.includes(s.name)) {
            benchFound.push(s);
        }
    });

    const perCatSeries: PerCatSerieInterface[] = [];

    let show = true;
    let firstFound = false;
    const rest: Highcharts.Series[] = [];
    let firstRoutines = null;
    let secondRoutines = null;

    const firstFunction = function() {
        chart.custom.updateRoutines(secondRoutines);
        perCatSeries.forEach(cat => {
            cat.first.update({
                y: getMedian(cat.values),
                name: cat.secondName
            }, false);
            cat.first.series.update({
                type: "column",
                name: cat.secondName
            }, false);
        });
        rest.forEach(s => {
            s.update({
                showInLegend: false,
                type: "column",
                visible: false
            }, false);
        });
    };

    const secondFunction = function() {
        chart.custom.updateRoutines(firstRoutines);
        perCatSeries.forEach(cat => {
            cat.first.update({
                y: cat.firstValue,
            }, false);
            cat.first.series.update({
                type: "column",
                name: cat.firstName
            }, false)
        });
        rest.forEach(s => {
            s.update({
                type: "column",
                visible: true,
                showInLegend: true
            }, false)
        });
    };

    const initFunction = function() {
        if (!firstRoutines) {
            firstRoutines = routinesObject.routines;
            secondRoutines = [];
            const temp = [];
            firstRoutines.forEach(r => {
                if (!r.isBench) {
                    secondRoutines.push(r);
                } else {
                    temp.push(r);
                }
            });

            const groupedRoutine = temp.reduce((routine, current) => {
                if(!routine) {
                    routine = {
                        ...current,
                        name: current.name.split("'")[0],
                        formula: []
                    }
                }

                current.formula.forEach(f => {
                    if (!routine.formula.find(rf => rf.name === f.name)) {
                        routine.formula.push(f);
                    }
                })

                return routine;
            }, null);

            secondRoutines.push(groupedRoutine);
        }

        const chart: Highcharts.Chart = this.chart
        if (perCatSeries.length === 0) {
            chart.series.forEach(s => {
                if (bench.includes(s.name)) {
                    s.data.forEach((d: Highcharts.Point) => {
                        let currentPerCat = perCatSeries.find(p => p.category === d.x);
                        if (!currentPerCat) {
                            currentPerCat = {
                                category: d.x,
                                first: d,
                                values: [d.y],
                                firstValue: d.y,
                                firstName: s.name,
                                secondName: s.name.split("'")[0],
                            }
                            perCatSeries.push(currentPerCat);
                        } else {
                            currentPerCat.values.push(d.y);
                        }
                    });
                    if(!firstFound) {
                       firstFound = true; 
                    } else {
                        rest.push(s);
                    }
                }
            })
        }

        if (show) {
            firstFunction();
            show = false;
        } else {
            secondFunction();
            show = true;
        }
        chart.legend.
        chart.redraw()
    };

    benchFound.forEach((b: Highcharts.SeriesOptionsType) => {
        b.events.click = initFunction;
        //b.cursor= "pointer";
    });
}

type ColorScale = string | Highcharts.GradientColorObject | Highcharts.PatternObject;
const applyColors = (routineObject: any, firstBench: string, colorScale: ColorScale[], series: Highcharts.SeriesColumnOptions[]): void => {
    const firstColor = colorScale.shift();
    let colorIndex = 0;

    series.forEach(s => {
        let color = firstBench === s.name ? firstColor : colorScale[colorIndex++];
        s.color = color;
        const currentRoutine = routineObject.routines.find(r => r.name === s.name);

        if (currentRoutine) {
            currentRoutine.color = color;
        }

    })
}

const createYAxisLabelFormatterFactory = ( parameters: any[] ) => {
    const labelMapping: Map<number, Translatable> = new Map();

    parameters.forEach( p => {
        if( p.label && p.key && !isNaN( p.key ) ) {
            labelMapping.set( p.key, p.label );
        }
    } );

    const getLang = ( object: any, path: string ) => {
        const pathArray = path.split( "." );
        let temp = object;

        while( pathArray.length ) {
            const currentPath = pathArray.shift();
            if (temp[currentPath]) temp = temp[currentPath];
            else return undefined;
        }

        const goodType = [ 'string', 'number' ];
        return goodType.includes( typeof temp ) ? temp : undefined;
    }
    return ( objectReference: any, path: string ) => {
        return (yAxisPoint: any): string => {
            const lang = getLang( objectReference, path );

            if( lang && labelMapping.has(yAxisPoint.value) ) {
                const currentTranslation = labelMapping.get( yAxisPoint.value );
                const currentLabel = currentTranslation[ lang ] || currentTranslation[ 'english' ] || yAxisPoint.value;
                return currentLabel;
            } else {
                return yAxisPoint.value;
            }
        }
    }

}

const getMinFromParameters = ( parameters: any[] ) => {
    let min = Infinity;
    parameters.forEach( p => {
        if( p.key < min ) {
            min = p.key;
        }
    } );
    return min;
}

const doWork = ( data: Array<any>, baseKey: string, lang: string, parameters: any, payload: any, routinesObject: any, chart: ColumnChart ): void => {
    const categoriesMaxAxisY = parameters.length ? Math.max(...parameters.map(p => p.key)) : null;

    const simpleRoutines = [];
    const benchRoutines = [];

    routinesObject.routines.forEach(routine => {
        if (routine.isBench) benchRoutines.push(routine);
        else simpleRoutines.push(routine);
    });

    const fullRoutines = [...simpleRoutines, ...benchRoutines];
    const benchIndex = benchRoutines.map(bench => bench.name);

    const {series, categories, maxY} = createHighchartData(data);
    applyColors(routinesObject, benchIndex[0], [...Chart.colorScale], series);

    const ordered = orderRoutines(fullRoutines, series);

    applyBenchOnClick(benchIndex, ordered, routinesObject, chart);

    payload.series = ordered;
    payload.categories = categories;
    payload.options.maxY = !isNaN( categoriesMaxAxisY ) ? categoriesMaxAxisY : maxY;
    payload.options.minY = getMinFromParameters( parameters );
    payload.options.yAxisLabelFormatterFactory = createYAxisLabelFormatterFactory( parameters );
};

export {
    doWork
};
