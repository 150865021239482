import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DragulaModule } from 'ng2-dragula';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { WorkspaceModalComponent } from './detail/workspaceModal/workspaceModal.component';
import { WorkspaceModalListComponent } from './detail/workspaceModalList/workspaceModalList.component';
import { WorkspacesAsTableComponent } from './list/workspacesAsTable.component';
import { WorkspacesComponent } from './list/workspaces.component';
import { WorkspacePreferencesModalComponent } from './detail/workspacePreferencesModal/workspacePreferencesModal.component';
import { ChartsSettingsComponent } from './detail/chartsSettings/chartsSettings.component';
import { AuthGuardService } from '../../../shared/services/authenticationGuard.service';
import { AddCharacterizationByHubComponent } from './detail/add-caracterization-by-hub/add-caracterization-by-hub.component';


const appRoutes: Routes = [{
    path: 'myprofile/workspaces/chartsSettings/:workspace',
    component: ChartsSettingsComponent,
    canActivate: [AuthGuardService]
  }
];

@NgModule({
    imports: [
        DragulaModule.forRoot(),
        SharedModule,
        ReactiveFormsModule,
        RouterModule.forChild(appRoutes)
    ],
    exports: [
        WorkspaceModalComponent,
        WorkspaceModalListComponent,
        WorkspacePreferencesModalComponent
    ],
    declarations: [
        WorkspaceModalComponent,
        WorkspaceModalListComponent,
        WorkspacesAsTableComponent,
        WorkspacesComponent,
        WorkspacePreferencesModalComponent,
        ChartsSettingsComponent,
        AddCharacterizationByHubComponent
    ],
    providers: [],
})
export class WorkspacesModule { }
