import {forkJoin as observableForkJoin,
  Observable
} from 'rxjs';

import {tap, finalize} from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';

import * as _ from 'lodash';

import {
  Block,
  User,
  Workflow,
  Etags
} from '../../../types';
import {
  BlockService
} from '../../../blocks/blocks.service';
import {
  DNATranslateService
} from '../../../shared/services/translate.service';
import {
  ErrorManagerService
} from '../../../shared/services/errorManager.service';
import {
  StateService
} from '../../../shared/services/state.service';
import {
  UserService
} from '../../../shared/services/user.service';
import {
  UtilService
} from '../../../shared/services/util.service';
import {
  WorkflowService
} from '../../workflows.service';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-block-selection',
  templateUrl: './blockSelection.component.html',
  styleUrls: ['./blockSelection.component.less']
})

export class BlockSelectionComponent implements OnInit, AfterViewInit {
  blocks: Block[] = [];
  currentLanguage: string;
  displayFavoriteOnly: boolean = false;
  myBlocks: Block[] = [];
  idBlocksCheck: string[] = [];
  orderedBy: string;
  reverse: boolean;
  searchText: string = '';
  showSpinner: boolean = false;
  state: StateService;
  isBlockSelected: boolean;
  user: User;
  workflow: Workflow;
  initTime = performance.now();

  constructor(
    private blockService: BlockService,
    private DNATranslate: DNATranslateService,
    private aiService: ApplicationInsightsService,
    private errorManager: ErrorManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private stateService: StateService,
    private userService: UserService,
    private utilService: UtilService,
    private workflowService: WorkflowService
  ) {}

  ngOnInit() {
    this.state = this.stateService;
    this.user = this.userService.getUser();
    this.currentLanguage = this.DNATranslate.getLanguage();

    this.workflow = this.workflowService.workflow;
    this.blocks = this.workflowService.blocks;
    this.myBlocks = _.cloneDeep(this.blocks);

    this.DNATranslate.onLangChange().subscribe((translation: any) => {
      this.currentLanguage = translation.lang;
    });

    this.userService.onUserChanged().subscribe((user: User) => {
      this.user = this.userService.getUser();
    });
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Library Add Blocks', '', performance.now() - this.initTime, templateUrl);
  }

  addBlocksInWorkflow() {
    this.showSpinner = true;
    this.blockService.getBlocksByIds(this.idBlocksCheck).pipe(
      finalize(() => this.showSpinner = false))
      .subscribe(
        result => {
          const indexedBlocks = result.list.map((block, index) => {
            return {
              ...block,
              mandatory: true,
              index: this.workflow.blocks.length + index
            };
          });
          this.workflow.blocks = this.workflow.blocks.concat(indexedBlocks);
          this.workflowService.setWorkflowLocal(this.workflow);
          this.router.navigate(['workflows', this.workflow.id]);
        },
        error => {
          this.showSpinner = false;
          this.errorManager.catchError(error);
        }
      );
  }

  onSortByChanged(sort: any) {
    this.reverse = !this.reverse;
    this.orderedBy = sort;
    this.myBlocks = this.utilService.sortListByType(this.myBlocks, this.orderedBy, this.reverse);
  }

  returnBack() {
    this.router.navigate(['workflows', this.workflow.id]);
  }

  updateDisplayFavorites() {
    this.displayFavoriteOnly = !this.displayFavoriteOnly;
    this.myBlocks = this.utilService.filterObjects(this.blocks, this.displayFavoriteOnly, this.searchText, this.currentLanguage);
  }

  updateListIdBlocks(block: Block) {
    let findIndex = this.idBlocksCheck.findIndex(idBlock => idBlock === block.id);
    if (findIndex >= 0) {
      this.idBlocksCheck.splice(findIndex, 1);
    } else {
      this.idBlocksCheck.push(block.id);
    }
  }

  updateSearchText(text: string) {
    this.searchText = text;
    this.myBlocks = this.utilService.filterObjects(this.blocks, this.displayFavoriteOnly, this.searchText, this.currentLanguage);
  }

  checkIfBlockSelected(currentBlock) {
    return this.idBlocksCheck.some(id => id === currentBlock.id);
  }

}
