import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { OptionType } from '../enums';
import { ChartOption } from '../interfaces';

@Injectable()
export class ChartOptionsControlService {

  constructor(private fb: FormBuilder) { }

  toFormGroup(editableOptions: any ) {
    const form = {};
    Object.keys(editableOptions).forEach(elementKey => {
      const formPayload = {};
      editableOptions[elementKey].options.forEach(element => {

        if(element.type === OptionType.GroupCheckBox) {
          const currentFormGroup = {};
          element.value.forEach((f: ChartOption) => {
            currentFormGroup[f.key] = f.value;
          });
          formPayload[element.key] = this.fb.group(currentFormGroup);
        } else {
          formPayload[element.key] = element.value
        }

      });
      form[elementKey] = this.fb.group(formPayload);
    });
    return this.fb.group(form);
  }

}
