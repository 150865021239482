export default {
  "type": "barchart",
  "configs": {
    "chart": {
      "type": 'bar',
      "height": 100,
      "width": 200,
      "backgroundColor": 'rgba(0,0,0,0)'
    },
    "title": {
      "text": null
    },
    "subtitle": {
      "text": null
    },
    "xAxis": {
      "categories": [],
      "title": {
        "text": null
      },
      "labels": {
        "enabled": false
      }

    },
    "credits": {
      "enabled": false
    },
    "exporting": {
      "enabled": false
    },
    "tooltip" : {
      "enabled" : false
    },
    "yAxis": {
      "title": {
        "text": null
      },
      "labels": {
        formatter: function (el) {
          return Math.abs(parseFloat(el.value))
        }
      },
      "tickInterval": 1,
      "min": -6,
      "max": 6,
      "plotBands": [
        {
          "color": "rgba(239,236,236,0.5)",
          "from": -0.5,
          "to": 0.5,
        }
      ],
      "plotLines": [
        {
          "zIndex": 4,
          "color": '#787777',
          "dashStyle": 'shortdash',
          "width": 1,
          "value": 0.5,
        },
        {
          "zIndex": 4,
          "color": '#787777',
          "dashStyle": 'shortdash',
          "width": 1,
          "value": -0.5,
        }
      ]
    },
    "plotOptions": {
      "column": {
        "dataLabels": {
          "enabled": false,
          "color": 'black',
          formatter: function () {
            return Math.abs(this.y)
          },
          "crop": false,
          "inside": false,
          "style": {
            "fontSize": ".7em"
          }
        }
      },
      "series": {
        "stacking": 'normal'
      },
      "grey_zone": {
        "enabled": false
      }
    }
  }
};