import {
  Observable
} from 'rxjs';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import * as _ from 'lodash';

import {
  StateService
} from '../../../../shared/services/state.service';
import {
  ActionBarButton,
  ActionTypes,
  DNATypes,
  User,
  Workspace
} from '../../../../types';
import {
  UserService
} from '../../../../shared/services/user.service';
import {
  UtilService
} from '../../../../shared/services/util.service';
import { DNATranslateService } from '../../../../shared/services/translate.service';
import {
  Router
} from '@angular/router';

@Component({
  selector: 'dna-workspaces-as-table',
  templateUrl: './workspacesAsTable.component.html'
})

export class WorkspacesAsTableComponent implements OnInit {
  @Input() users: any[];
  @Input() workspaces: any[];
  @Output() onEditModalGeneral = new EventEmitter();
  @Output() onEditModalList = new EventEmitter();
  @Output() onEditPreferencesModal = new EventEmitter();
  @Output() onRemove = new EventEmitter();
  actionsButtons: Observable < ActionBarButton[] > ;
  orderedBy: string;
  reverse: boolean;
  state: any;
  user: User;
  showSpinner: boolean = false;
  subscription: any;

  constructor(
    private stateService: StateService,
    private userService: UserService,
    private utilService: UtilService,
    private dnaTranslateService: DNATranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.state = this.stateService;
    this.user = this.userService.getUser();
    this.actionsButtons = this.utilService.createActionsButtons(DNATypes.Workspace);
    this.subscription = this.dnaTranslateService.onLangChange().subscribe(() => {
      this.actionsButtons = this.utilService.createActionsButtons(DNATypes.Workspace)
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClickActionButton(actionButton: ActionBarButton, idWorkspace: string, workspaces: Workspace[]) {
    switch (actionButton.id) {
      case ActionTypes.Edit:
        this.clickModalGeneral([], [], idWorkspace)
        break;
      case ActionTypes.EditUsersPreferences:
        this.clickModalPreferences(idWorkspace);
        break;
      case ActionTypes.EditListBlocks:
        this.clickModalList(idWorkspace, this.state.BLOCK)
        break;
      case ActionTypes.EditListCampaigns:
        this.clickModalList(idWorkspace, this.state.CAMPAIGN)
        break;
      case ActionTypes.EditListWorkflows:
        this.clickModalList(idWorkspace, this.state.WORKFLOW)
        break;
      case ActionTypes.EditChartsSettings:
          this.router.navigate(['myprofile/workspaces/chartsSettings/', idWorkspace]);
        break;
      case ActionTypes.Remove:
        this.clickRemove(idWorkspace, workspaces);
        break;
    }
  }

  clickModalGeneral(adminsToSend: any[], usersToSend: any[], idWorkspace: string) {
    this.onEditModalGeneral.emit({
      adminsToDisplay: adminsToSend,
      usersToDisplay: usersToSend,
      idWorkspace: idWorkspace
    });
  }

  clickModalList(workspaceToSend: any, stringToSend: string) {
    this.onEditModalList.emit({
      workspace: workspaceToSend,
      type: stringToSend
    });
  }

  clickModalPreferences(idWorkspace: string) {
    this.onEditPreferencesModal.emit(idWorkspace);
  }

  clickRemove(idWorkspace: string, workspaces: Workspace[]) {
    this.onRemove.emit({idWorkspace:idWorkspace, workspaces: workspaces});
  }

  getAdminsToDisplay(arrayAdminsKey: any[]) {
    if (!arrayAdminsKey) {
      arrayAdminsKey = [];
    }
    return arrayAdminsKey.reduce((adminsToDisplay, userKey) => {
      if (this.users) {
        let matchUser = this.users.find((user) => user.key == userKey);
        if (matchUser) {
          adminsToDisplay.push(matchUser);
        }
      }
      return adminsToDisplay;
    }, []);
  }

  getUsersToDisplay(arrayUsersKey: any[]) {
    if (!arrayUsersKey) {
      arrayUsersKey = [];
    }
    return arrayUsersKey.reduce((usersToDisplay, userKey) => {
      if (this.users) {
        let matchUser = _.find(this.users, function (user) {
          return user.key == userKey
        });
        if (matchUser) {
          usersToDisplay.push(matchUser);
        }
      }
      return usersToDisplay;
    }, []);
  }

  stripRow(index: number) {
    if (index % 2 == 0) {
      return {
        'background-color': 'transparent',
      };
    } else {
      return {
        'background-color': '#1ab394',
        'color': '#FFFFFF'
      };
    }
  }

}
