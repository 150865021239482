<td>
    <span class="cursor-pointer">{{ descriptor.campaignName }}</span>
</td>
<td>
    <span class="cursor-pointer">
        <dna-translate [fieldToTranslate]="descriptor.workflowName"></dna-translate>
    </span>
</td>
<td>
    <span class="cursor-pointer">
        <dna-translate [fieldToTranslate]="descriptor.blockName"></dna-translate>
    </span>
</td>
<td>
    <span class="cursor-pointer">
        <dna-translate [fieldToTranslate]="descriptor.name"></dna-translate>{{descriptor.blockSuffix}}
    </span>
</td>
<td>
    <span class="cursor-pointer" [ngbTooltip]="descriptor.scaleDetail">{{ descriptor.scale }}</span>
</td>
<td>
    <span class="cursor-pointer">{{ descriptor.pertinance }}%</span>
</td>
