import { Chart } from '../chart.model';
import { ScalebarOptions } from './scalebar-options';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import * as ScaleBarMedianCompute from './methods/median.method';

import { Descriptor } from '../../../../../../../../types';
import { COMPUTING_METHODS } from '../../enums';
import { Table } from '../table';

const mapSettingsKey: Map<string, string> = new Map();

export class Scalebar extends Chart {
    protected _baseParameters: any = {};
    public onOptionChangeSubject = new Subject<any>();
    public static mapSettings: Map<string, string> = mapSettingsKey;

    constructor(
        _parameters?: any,
        _data?: Array<any>,
        _lang?: string,
        _filters?: any,
        private _translateService?: TranslateService
    ) {
        super(_parameters, _data, _lang, _filters);
        this.tableRawData = this.formatRawData(_data, _lang);
        this.generateParametersAssignment(this.parameters, this.lang);
        this.generateDataAssignment(this.data, this.lang);
    };

    private generateParametersAssignment(parameters: any, lang: string) {
        // Handling title & subtitle translation
        this.updateChartTranslations(parameters, lang);
        Object.assign({ ...this._baseParameters, parameters });
        Object.keys({ ...this._baseParameters }).forEach((k) => {
            this.parameters[k] = Object.assign({ ...this._baseParameters[k] }, parameters[k])
        });
        Object.keys({ ...parameters }).forEach((k) => {
            this.parameters[k] = Object.assign({ ...this.parameters[k] }, parameters[k])
        });
    };

    protected generateDataAssignment = (data: any, lang: string) => {
        this.data = data;
        const method = this.parameters.compute ? this.parameters.compute.method : null;
        const baseKey = this.parameters.compute ? this.parameters.compute.key : 'key';
        const payload = this.formatData(data, method, baseKey, lang, this.parameters, this.descriptors, this.routines);
        this.parameters.series = payload.series || [];
        this.parameters.categories = payload.categories || [];
    };

    public buildGraphOptions = (options: any) => {
        const { title, subtitle, plotOptions, ...rest } = options;
        this.options = new ScalebarOptions({
            general: { title, subtitle },
            plotOptions
        }, this.onOptionsChange);
    };


    protected formatData = (
        data: Array<any>,
        method: string,
        baseKey: string,
        lang: string,
        parameters: any,
        descriptors: Array<Descriptor>,
        routines: Array<any>
    ): { categories: Array<any>, series: Array<any> } => {

        const payload = {
            categories: {},
            series: {}
        };
        switch (method) {
            case null:
                break;
            case COMPUTING_METHODS.MEDIAN:
                ScaleBarMedianCompute.doWork(data, baseKey, lang, parameters, descriptors, payload, routines);
                break;
        };
        return {
            categories: Object.values(payload.categories),
            series: Object.values(payload.series)
        };
    };

    private onOptionsChange = (options: any) => {
        for (let o in options) {
            if (o === 'general') {
                Object.assign(this.parameters, options[o]);
            } else {
                for (let e in options[o]) {
                    if (Array.isArray(options[o][e])) {
                        options[o][e].forEach(x => {
                            Object.assign(this.parameters[e].find(y => y.name === x.name), x)
                        })
                    } else {
                        Object.assign(this.parameters[e], options[o][e]);
                    }
                }
            }
        };

        this.onOptionChangeSubject.next(this.parameters);
        this.updateChartTranslations(this.parameters, this.lang);
    };

    //protected setParamsToAxis: (params: any, axis: any) => void;

    public createTable = (series: Array<any>, categories: Array<any>) => {
        return categories.map((category: any, index: number) => {
            category.data = [];
            const payload = series.reduce((reducer: any, serie: any) => {
                const { data, ...rest } = serie;
                reducer[serie.name] = (reducer[serie.name] || { ...rest, data: [] });
                const foundData = data.filter((d: any) => d.x === index);
                for (let data of foundData) {
                    (reducer[serie.name].data = (reducer[serie.name].data || [])).push(data);
                }
                return reducer;
            }, {});
            category.data = Object.values(payload);
            return category;
        })
    };

    /**
     * updateChartTranslations
     * Method to manage languages changes
     * @param parameters : any : Chart's parameters
     * @param lang : string
    */
    private updateChartTranslations = (parameters: any, lang: string): void => {
        this.updateTableTitleTranslations(lang, parameters);
        this.updateTableSubTitleTranslations(lang, parameters);
    };

    private updateTableTitleTranslations = (lang: string, parameters: any) => {
        parameters.title.text = this.getObjectValueTranslation(parameters.title.translations, lang);
    };

    private updateTableSubTitleTranslations = (lang: string, parameters: any) => {
        parameters.subtitle.text = this.getObjectValueTranslation(parameters.subtitle.translations, lang);
    };

    protected formatRawData = (data: Array<any>, lang: string): { header: Array<any>, body: Array<any> } => {
        try {
            const payload = JSON.parse(JSON.stringify(data['data'])).reduce((accumulator: any, object: any, itemNumber: number) => {
                const payload = object.values.reduce((reducer: any, item: any, index: number) => {
                    item.values.reduce((redacc: any, value: any, idx: number) => {
                        redacc[value.label] = value;
                        return redacc;
                    }, reducer);
                    return reducer;
                }, {});

                const keyList = Object.keys(payload);
                const oPayload = object.values.reduce((reducer: any, item: any, index: number) => {
                    if (item.values.length !== keyList.length) {
                        for (let obj of keyList) {
                            const foundObject = item.values.find((x: any) => { return x.label === obj });
                            if (!foundObject) {
                                item.values.push({
                                    label: payload[obj].label,
                                    value: { label: "", key: "" }
                                });
                            }
                        }
                    }
                    item.values.sort((a, b) => {
                        const labelA = Chart.getObjectValueTranslation(a.label, lang)
                        const labelB = Chart.getObjectValueTranslation(b.label, lang)
                        return labelA.localeCompare(labelB);
                    })
                    
                    return reducer;
                }, object);
                accumulator.push(oPayload);
                return accumulator;
            }, []);

            data['data'] = Object.values(payload.reduce((accumulator: any, object: any, itemNumber: number) => {
                if (object.attribute.hasOwnProperty('blockName')) {
                    const key = object.attribute.blockName.english;
                    accumulator[key] = accumulator[key] || { "attribute": { "label": object.attribute.blockName }, "values": [] };
                    accumulator[key].values.push(object);
                }
                return accumulator;
            }, {}));

            const tableTemp = new Table({}, data, lang);
            return tableTemp.parameters.transformedHTMLData;
        } catch (e) {
            return { header: [], body: [] };
        }
    };
};