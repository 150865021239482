<div class="container">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <div class="ibox float-e-margins">
    <div class="ibox-title">
      <h5 translate> ACTIONS </h5>
    </div>
    <div class="ibox-content">
      <div class="row">
        <div class="col-12 col-md-3">
          <button id="open" class="btn btn-primary" type="button" (click)="openPdf()" translate>OPEN</button>
        </div>
        <label class="col-12 col-md-9 col-form-label" translate> OPEN_PDF </label>
      </div>
      <div class="row">
        <div class="col-12 col-md-3">
          <button id="download" class="btn btn-primary" type="button" (click)="downloadPdf()"
            translate>DOWNLOAD</button>
        </div>
        <label class="col-12 col-md-9 col-form-label" translate> DOWNLOAD_REPORT </label>
      </div>
      <!-- DO NOT REMOVE - PROCESSMAP DEACTIVATED -->
      <div class="row">
        <div class="col-12 col-md-3">
          <button id="milor" class="btn btn-primary" type="button" (click)="sendToMilor()" translate>MILOR_SEND</button>
        </div>
        <label class="col-12 col-md-9 col-form-label" translate> MILOR_SEND_DESC </label>
      </div>
      <div class="row">
        <div class="col-12 col-md-3">
          <button id="milor" [disabled]="onePager.archiveId === undefined" class="btn btn-primary" type="button"
            (click)="goToMilorPlatform()" translate>MILOR_OPEN_DOC</button>
        </div>
        <label class="col-12 col-md-9 col-form-label" translate> MILOR_OPEN_DOC_DESC </label>
      </div>
      <!-- DO NOT REMOVE - PROCESSMAP DEACTIVATED -->
    </div>
  </div>
</div>
