<ng-container *ngIf="!isVolunteer">
  <td>
    {{ accountable.name }}
  </td>
  <td>
    <dna-switch-confirm [isSelected]="this.accountable.isActive" (click)="confirmUpdateUser()"></dna-switch-confirm>
  </td>
</ng-container>
<ng-container *ngIf="isVolunteer">
  <td>
    {{ volunteer.name }}
  </td>
  <td>
    <dna-switch-confirm [isSelected]="this.volunteer.isActive" [disabled]="this.isDisabled" (click)="confirmUpdateUser()"></dna-switch-confirm>
  </td>
</ng-container>

