
import {map} from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

import {
  Observable
} from 'rxjs';

import {
  ActionBarButton,
  ActionTypes,
  Block,
  DNATypes,
  Languages
} from '../../../types';
import {
  UtilService
} from '../../../shared/services/util.service';

@Component({
  selector: '[dna-block-row]',
  templateUrl: './block-row.component.html',
  styleUrls: ['./block-row.component.less']
})
export class BlockRowComponent implements OnChanges {

  @Input() block: Block;
  @Input() currentLanguage: Languages;

  @Output() onClick: EventEmitter<ActionBarButton> = new EventEmitter();
  @Output() onSend: EventEmitter<any> = new EventEmitter();

  actionsButtons$: Observable<ActionBarButton[]>;

  constructor(
    private utilService: UtilService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.block && changes.block.currentValue && changes.block.currentValue.state) {
      this.updateActionsButtons();
    }
  }

  onClickBlock(id: string) {
    this.onClickButton(new ActionBarButton(ActionTypes.Edit, '', ''));
  }

  onClickButton(actionsButton: ActionBarButton) {
    this.onClick.emit(actionsButton);
  }

  onSendDatalakeData(event: any) {
    this.onSend.emit(event);
  }

  updateActionsButtons() {
    this.actionsButtons$ = this.utilService.createActionsButtons(DNATypes.Block).pipe(
      map(this.utilService.filterByArchive(this.block.state)));
  }

}
