<div class="container">
<ngx-loading [show]="showSpinner">
</ngx-loading>
  <div *ngIf="!showSpinner && !error" class="ibox float-e-margins">
    <form novalidate name="campaignForm">
      <div class="ibox-title">
        <h5 class="center" translate> DISPLAY_MODE </h5>
        <p *ngIf="isDisabled" translate> PUBLISHED_CAMPAIGNS_MESSAGE </p>
        <div *ngIf="!isDisabled && isChangedCampaign()" class="d-flex flex-row gap-1">
          <button id="save" class="btn btn-primary" type="button" (click)="save()" translate>SAVE</button>
          <button id="cancel" class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
        </div>
      </div>

      <div class="ibox-content">
        <div class="form-group">
          <div class="row">
            <div class="offset-3 col-9 d-flex flex-row gap-1">
              <button *ngIf="currentWorkspace.preferences.views.listMode.isActive" class="btn btn-primary btn-sm"
                [ngClass]="{'btn-selected': campaign.parameters.viewType === viewTypes.ModeList}"
                [disabled]="isDisabled" type="button"
                (click)="clickTypeview(viewTypes.ModeList, 'LABORATOIRE_DESCRIPTION')" translate>LIST_MODE</button>
              <button *ngIf="currentWorkspace.preferences.views.cardMode.isActive" class="btn btn-primary  btn-sm"
                [ngClass]="{'btn-selected': campaign.parameters.viewType === viewTypes.ModeCard}"
                [disabled]="isDisabled" type="button" (click)="clickTypeview(viewTypes.ModeCard, 'CARD_DESCRIPTION')"
                translate>CARD_MODE</button>
              <button *ngIf="currentWorkspace.preferences.views.dualMode.isActive" class="btn btn-primary  btn-sm"
                [ngClass]="{'btn-selected': campaign.parameters.viewType === viewTypes.ModeDualCard}"
                [disabled]="isDisabled" type="button"
                (click)="clickTypeview(viewTypes.ModeDualCard, 'DUAL_DESCRIPTION')" translate>DUAL_MODE</button>
              <button *ngIf="currentWorkspace.preferences.views.customizeMode.isActive" class="btn btn-primary  btn-sm"
                [ngClass]="{'btn-selected': campaign.parameters.viewType === viewTypes.ModeCustomizeCard}"
                [disabled]="isDisabled" type="button"
                (click)="clickTypeview(viewTypes.ModeCustomizeCard, 'CUSTOMIZE_DESCRIPTION')"
                translate>CUSTOMIZE_MODE</button>
            </div>
            <div class="offset-3 col-9 control-label description">
              <span> {{description | translate}} </span>
            </div>
          </div>
        </div>
        <div class="form-group mt-3" *ngIf="campaign.parameters.viewType === viewTypes.ModeList">
          <div class="row">
            <label class="col-3 control-label">{{'PARAMETER_SELECT_VIEW' | translate}}</label>
            <div class="col-9 d-flex flex-row gap-1">
              <button class="btn btn-primary btn-sm"
                [ngClass]="{'btn-selected': campaign.parameters.viewGroup === viewGroups.FormulaGroup}"
                [disabled]="isDisabled" type="button" (click)="changeViewGroup(viewGroups.FormulaGroup)"
                translate>FORMULA</button>
              <button class="btn btn-primary btn-sm"
                [ngClass]="{'btn-selected': campaign.parameters.viewGroup === viewGroups.QuestionGroup}"
                [disabled]="isDisabled" type="button" (click)="changeViewGroup(viewGroups.QuestionGroup)"
                translate>QUESTION</button>
            </div>
          </div>
        </div>
        <div class="form-group mt-3" *ngIf="campaign.parameters.viewType === viewTypes.ModeCustomizeCard">
          <div class="row">
            <label class="col-3 control-label" translate> MESSAGE_CUSTOMIZE </label>
            <div class="col-9">
              <button class="btn btn-primary  btn-sm" [disabled]="isDisabled" type="button"
                (click)="configureCustomizeMode()" translate>CUSTOMIZE_VIEW</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="!showSpinner && error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "ERROR" | translate }}</h5>
        </div>
      </div>
    </div>
    <dna-error-page (onClickReload)="init()"></dna-error-page>
  </div>
</div>
