import { AfterViewInit, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationInsightsService } from '../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-remove-visits-modal',
  templateUrl: './remove-visits-modal.component.html',
  styleUrls: ['./remove-visits-modal.component.less']
})
export class RemoveVisitsModalComponent implements AfterViewInit {

  @Input() unableDeleteVisitTab: {
    id: string;
    name: string;
  }[];

  initTime = performance.now();

  constructor(
    private aiService: ApplicationInsightsService,
    private activeModal: NgbActiveModal
  ) { }

  ngAfterViewInit() {
    this.aiService.logPageView('Remove Visits Modal', '', performance.now() - this.initTime, 'Modals');
  }

  onClose() {
    this.activeModal.dismiss();
  }

}
