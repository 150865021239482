<div class="dna-chart-scalebar" #scalebar>
  <div class="dna-chart-scalebar__caption">
    {{ this.chart.parameters?.title?.text || ('VIZUALIZATIONS.CHART_TITLE' | translate) }}
  </div>
  <div class="dna-chart-scalebar__caption dna-table-chart-subtitle" *ngIf="this.chart?.parameters?.subtitle?.text">
    {{ this.chart?.parameters?.subtitle?.text }}
  </div>

  <div class="dna-chart-scalebar__items-container">
    <div class="dna-chart-scalebar__header" *ngIf="this.parameters && this.parameters.length"
      [ngStyle]="{'grid-template-columns' : 'repeat(' + this.parameters.length + ', 1fr)' }">
      <span *ngFor="let option of this.parameters">
        {{ option.label | applyTranslation : this.currentLanguage : Languages.Default | uppercase }}</span>
    </div>
    <ng-container *ngFor="let row of this.transformedData">
      <span class="dna-chart-scalebar__item__label" *ngIf="this.displayLanguages.length > 1">
        <span *ngFor="let lang of this.displayLanguages">
          <b *ngIf="this.chart.parameters?.plotOptions?.attributes_blocks?.enabled">
            {{ row.blockName | applyTranslation : lang : Languages.Default }} </b>
          <span> {{ row.label | applyTranslation : lang : Languages.Default }} </span>
        </span>
      </span>
      <span class="dna-chart-scalebar__item__label" *ngIf="this.displayLanguages.length == 1">
        <b *ngIf="this.chart.parameters?.plotOptions?.attributes_blocks?.enabled" class="dna-chart-scalebar__item__label-line">
          {{ row.blockName | applyTranslation : this.displayLanguages[0] : Languages.Default }} </b>
        <span class="dna-chart-scalebar__item__label-line"> {{ row.label | applyTranslation : this.displayLanguages[0] :
          Languages.Default }} </span>
      </span>
      <dna-scalebar [number]="this.parameters.length" [lang]="this.currentLanguage" [parameters]="row.data" [min]="min">
      </dna-scalebar>
    </ng-container>
  </div>
</div>
<div class="chart--no-data" *ngIf="!this.parameters.length"> <span
    translate>VIZUALIZATIONS.NO_DATA_TO_DISPLAY</span>
</div>