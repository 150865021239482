<div class="container">
    <ngx-loading [show]="showSpinner"></ngx-loading>
    <div *ngIf="!showSpinner && !error" class="ibox float-e-margins">
      <div class="ibox-title">
        <h5 class="text-center"><span translate>GESTION_PHOTOS</span></h5>
      </div>
      <div class="ibox-content">
        <div class="form-group">
          <div class="filter-volunteers">
            <span translate>SEARCH_VOLUNTEER_ID</span>
            <input class="input-filter" type="text" [(ngModel)]="volunteerCharacters" (input)="getFilterVolunteer()" placeholder="">
          </div>

          <section class="wrap-info">
            <div class="count-volunteers">
              {{volunteersId.length}} <span translate>VOLUNTEERS</span>
            </div>
            <div class="display-language">
              <span translate>SHOW_DEFAULT_LANGUAGE</span>
              <dna-switch [isSelected]="false" (onBooleanChanged)="addDefaultTranslate($event)">
              </dna-switch>
            </div>
          </section>

        <div class="table-responsive">
          <table class="table table-hover table-borderless-th dataTable">
            <thead>
              <tr class="case-border">
                <th class="case-border center" (click)="onSortByChanged()" [ngClass]="{ 'sorting' : true }" translate>
                  ID_VOLUNTEERS <i [ngClass]=""></i>
                </th>
                <th class="pointer case-border" *ngFor="let timepoint of timepoints" (click)="redirect(timepoint.id)">
                  <div>{{ timepoint.name.visitName }} </div>
                  <hr />
                  <div *ngIf="displayEnglishLanguage(timepoint.name.blockName); else displayMultiTranslateBlockName">
                    <div>{{timepoint.name.blockName[Languages.English]}}</div>
                  </div>
                  <ng-template #displayMultiTranslateBlockName>
                    <div
                      [innerHTML]="timepoint.name.blockName | multiTranslations : [ timepoint.name.blockName, showDefaultLanguage ? Languages.Default : null ] : currentLanguage">
                    </div>
                  </ng-template>
                  <hr />
                  <div
                    *ngIf="displayEnglishLanguage(timepoint.name.componentName); else displayMultiTranslateComponentName">
                    <div>{{timepoint.name.componentName[Languages.English]}}</div>
                  </div>
                  <ng-template #displayMultiTranslateComponentName>
                    <div
                      [innerHTML]="timepoint.name.componentName  | multiTranslations : [ timepoint.name.componentName, showDefaultLanguage ? Languages.Default : null ] : currentLanguage">
                    </div>
                  </ng-template>

                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" *ngFor="let volunteerId of volunteersId">
                <td class="pointer case-border" (click)="goToPageVolunteerPhotos(volunteerId)">
                  {{volunteerId}}
                </td>
                <td class="case-border" *ngFor="let timepoint of timepoints">
                  {{ getValueTimepointByVolunteer(volunteerId, timepoint.id) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</div>
