import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'dna-button-display-only',
    templateUrl: './dnaButtonDisplayOnly.component.html',
    styleUrls: ['./dnaButtonDisplayOnly.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class dnaButtonDisplayOnlyComponent implements OnInit {
    @Input() logo: string;
    @Input() textToDisplay: string;
    @Input() value: boolean;
    @Output() onClick = new EventEmitter();

    constructor() { }

    ngOnInit() { }

    onClickEvent() {
        this.onClick.emit();
    }

}
