interface ConfigInterface {
    type: string;
    configs: ConfigItemInterface;
}

interface ConfigItemInterface extends Highcharts.Options {
    compute: {
        method: string;
    },
    title: {
        text: string;
    },
    subtitle: {
        text: string;
    }
}

const config: ConfigInterface = {
    type: "bubble",
    configs: {
        compute: {
            method: "median",
        },
        title: {
            text: "test bubble"
        },
        subtitle: {
            text: "test bubble sub"
        }
    }
};

export default config;