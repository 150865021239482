import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'dna-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchComponent implements OnInit {

  @Input() disabled: boolean = false;
  @Input() isSelected: boolean;
  @Input() text: string;

  @Output() onBooleanChanged: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  onChange() {
    if (!this.disabled) {
      this.isSelected = !this.isSelected;
      this.onBooleanChanged.emit(this.isSelected);
    }
  }

}
