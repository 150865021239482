<ngx-loading id="modal-loader" show="true" class="hide"></ngx-loading>
<dna-image-layout
  [_captureMode]="captureMode"
  [_format]="format"
  [_initEditor]="initEditor"
  [_page]="page"
  [_landscapeFormat]="sizeLandScape"
  (_onChangeComment)="onChangeComment(page)"
  (_onCommentAdd)="onCommentAdd(page)"
  (_onRemovedItem)="removeItem($event)">
</dna-image-layout>
<dna-image-gallery
  class="col-2"
  [_images]="images"
  [_pages]="pages"
  [_page]="page"
  (_onPageAdd)="onPageAdd($event)"
  (_onImagesChanged)="onImagesChanged(images)"
  (_onUpdatedGrid)="onUpdatedGrid(pages)">
</dna-image-gallery>
