import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { LightCampaign, AnalyseTypes, Workspace, Preference, AnalyseType, User } from '../../../types';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorManagerService } from '../../../shared/services/errorManager.service';
import { UserService } from '../../../shared/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

type DuplicatedCampaignMulti = {
  campaignMultiName: string;
  analyseType: AnalyseType;
}

@Component({
  selector: 'dna-create-campaign-multi',
  templateUrl: './create-campaign-multi-modal.component.html',
  styleUrls: ['../../../shared/modals/modals.component.less', './create-campaign-multi-modal.component.less']
})
export class CreateCampaignMultiModalComponent implements OnInit, AfterViewInit {

  @Input() campaignSelected: LightCampaign[];
  @Input() campaignMultiToDuplicate: DuplicatedCampaignMulti;

  campaignMultiName: string;
  workspace: Workspace = new Workspace();
  destroy$: Subject<boolean> = new Subject<boolean>();
  analyseTypeSelected: AnalyseType;
  public analyseTypes = AnalyseTypes;
  listAnalyseTypes = _.values(this.analyseTypes);
  submitted: boolean;
  user: User;
  initTime = performance.now();

  constructor(
    public activeModal: NgbActiveModal,
    private aiService: ApplicationInsightsService,
    private errorManager: ErrorManagerService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.user = this.userService.getUser();
    if (this.user) {
      this.workspace = this.user.currentWorkspace;
    }
    if (this.campaignMultiToDuplicate) {
      this.analyseTypeSelected = this.campaignMultiToDuplicate.analyseType;
      this.campaignMultiName = this.campaignMultiToDuplicate.campaignMultiName.concat('_duplicate');
      //TODO : ajouter les autres éléments à garder s'il y en a
    } else {
      this.analyseTypeSelected = this.analyseTypes.comparison;
      this.campaignMultiName = this.generateCampaignMultiName(this.campaignSelected);
    }
  }

  ngAfterViewInit() {
    this.aiService.logPageView('Multi Campaigns Create Modal', '', performance.now() - this.initTime, 'Modals');
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  private generateCampaignMultiName(campaigns: LightCampaign[]) {
    return campaigns.map(c => _.get(c, 'synergy.requestNumber'))
      .filter(n => !_.isUndefined(n))
      .reduce((name: string, id, index) => {
          if(index > 0) name += " - ";
          name +=  id;
          return name;
      }, "")
  }

  save(form: NgForm) {
    this.submitted = true;
    if (form.valid) {
      this.activeModal.close({campaignMultiName: this.campaignMultiName, analyseType: this.analyseTypeSelected, workspace: this.workspace});
    } else {
      this.errorManager.displayMessage('ON_ERROR_FORM', 'danger');
    }
  }

}
