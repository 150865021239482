import {
  Evaluation, EvaluationStates
} from '../../types';
import * as _ from 'lodash';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'dna-progress-user',
  templateUrl: './progress-user.component.html',
  styleUrls: ['./progress-user.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressUserComponent implements OnInit, OnChanges {
  @Input() evaluations: Evaluation[];
  @Input() realyEvaluatedUsers: number;
  @Input() onlyStarted: boolean;
  @Input() idWorkflows: string[];

  totalUsers: number;
  usersStarted: number;
  usersFinish: number;
  hasEvaluation: boolean = false;

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateProgress();
  }

  calculateProgress() {
    if (!this.evaluations || this.evaluations.length === 0) {
      this.hasEvaluation = false;
    } else {
      this.hasEvaluation = true;
      this.totalUsers = 0;
      this.usersStarted = 0;
      this.usersFinish = 0;
      let evaluationGroupedByUser = [];
      if (this.evaluations[0].volunteer) {
        evaluationGroupedByUser = _.groupBy(this.evaluations.filter(evaluation => _.get(evaluation, 'volunteer.isActive', true)), 'volunteer.name');
      } else {
        evaluationGroupedByUser = _.groupBy(this.evaluations, 'users[0].key');
      }
      for (let user in evaluationGroupedByUser) {
        this.totalUsers++;
        //bug-23510 - on considère que s'il y a plusieurs workfow différent, il doit y avoir au moins une eval de chaque workflow à 100% 
        if (this.idWorkflows.length > 1) {
          evaluationGroupedByUser[user] = _.groupBy(evaluationGroupedByUser[user], 'questionnaireId');
          if (this.idWorkflows.every(idw => evaluationGroupedByUser[user][idw].some(evaluation =>  _.get(evaluation, 'state', '') === EvaluationStates.Completed || evaluation.progress === 1))) {
            this.usersFinish++;
          } else if (this.idWorkflows.some(idw => evaluationGroupedByUser[user][idw].some(evaluation => evaluation.progress))) {
            this.usersStarted++;
          } 
        } else {
          if (!_.isEmpty(evaluationGroupedByUser[user].filter(evaluation => _.get(evaluation, 'state', '') === EvaluationStates.Completed || evaluation.progress === 1))) {
            this.usersFinish++;
          } else if (!_.isEmpty(evaluationGroupedByUser[user].filter(evaluation => evaluation.progress))) {
            this.usersStarted++;
          }
        }
      }
    }
    if (this.realyEvaluatedUsers && _.isNumber(this.realyEvaluatedUsers)) {
      this.totalUsers = this.realyEvaluatedUsers;
    }
  }
}