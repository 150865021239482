
import {finalize} from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as _ from 'lodash';

import { Block, User, Workspace } from '../../../../../types';
import { BlockService } from '../../../../../blocks/blocks.service';
import { ErrorManagerService } from '../../../../../shared/services/errorManager.service';

@Component({
  selector: 'dna-block-import',
  templateUrl: './block-import.component.html',
  styleUrls: ['./block-import.component.css']
})
export class BlockImportComponent implements OnInit {
  importForm: FormGroup;
  showSpinner: boolean = false;
  @Input() user: User;
  @Input() workspaces: Workspace[];

  constructor(
    private blockService: BlockService,
    private errorManager: ErrorManagerService,
    private formBuilder: FormBuilder
  ) {
    this.importForm = this.formBuilder.group({
      blockToImport: ['', Validators.required],
      workspaces: [this.workspaces, Validators.required]
    });
  }

  ngOnInit() {
  }

  onSubmit(form: FormGroup) {
    try {
      let block: Block = JSON.parse(form.value.blockToImport);
      block = _.omitBy(block, (value, key) => key[0] === '_');
      let workspacesToAdd = form.value.workspaces.map(w => w.id);

      if (!block.components) {
        this.errorManager.displayMessage('ERROR_WRONG_OBJECT', 'danger');
      }
      else if (_.isEmpty(block.components)) {
        this.errorManager.displayMessage('ON_ERROR_EMPTY_BLOCK', 'danger');
      }
      else {
        this.saveBlock(block, workspacesToAdd);
      }
    }
    catch (error) {
      this.errorManager.displayMessage('ERROR_IMPORT_BLOCK', 'danger');
      this.showSpinner = false;
    }
  }

  saveBlock(block: Block, workspaceId: string[]) {
    this.showSpinner = true;
    block.createdBy = { user: this.user.track };
    block.workspaces = workspaceId;

    this.blockService.createBlock(block).pipe(
      finalize(() => this.showSpinner = false))
      .subscribe(() => {
        this.importForm.reset();
        this.errorManager.displayMessage('ON_SUCCESS_CREATE', 'success');
      }, err => {
        if (err.error == "name") {
          this.errorManager.displayMessage('NAME_ALREADY_TAKEN', 'danger')
        } else {
          this.errorManager.catchError(err);
        }
      });
  }

}
