import { Chart } from '../chart.model';
import { SankeyOptions } from './sankey-options.model';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import * as SankeyChartLabelFormatter from './sankey.label.formatter';
import * as SankeyChartTooltipFormatter from './sankey.tooltip.formatter';
import { Languages, COMPUTING_METHODS } from '../../enums';
import * as SankeyMedianCompute from './methods/median.method';
import { Descriptor } from '../../../../../../../../types';
import { Table } from '../table';

const mapSettingsKey: Map<string, string> = new Map();
mapSettingsKey.set("show_attributes_blocks","show_attributes_blocks");
mapSettingsKey.set("show_default_language","show_default_language");

const resizePlotBandLabel = (el) => {
  // Retrieve firstPlotband on graph context. See ./methods/median.method.ts for anchor class
  const currentPLotBand: SVGGraphicsElement = el.target.container.querySelector(".anchor-plotband");
  // Retrieve all labels of plotband on graph context. See ./sankey.label.formatter.ts for anchor class
  const labels: NodeList = el.target.container.querySelectorAll(".anchor-label");
  // Retrieve current plotband width
  const plotBandWidth = currentPLotBand.getBBox().width;
  // And its x position from the document
  const firstPosition = currentPLotBand.getBoundingClientRect().left;
  labels.forEach((e: HTMLElement, index) => {
    // set label's container width with plotband width
    e.parentElement.style.width = plotBandWidth.toString() + "px" ;
    // Retrieve label's container x position from the document
    const currentBoudingXParent = e.parentElement.getBoundingClientRect().left;
    // Retrieve label's container left position from parent
    const currentLeftParent = Number(e.parentElement.style.left.replace("px", ''));
    // Get current label starting x position from document
    const currentBoudingPlotBand = firstPosition + index * plotBandWidth;
    // Get offset between current x and desired x
    const currentOffset = currentBoudingXParent - currentBoudingPlotBand;
    // Get left position
    e.parentElement.style.left = currentLeftParent - currentOffset + 'px';
    // Enable text wrap
    e.parentElement.style.whiteSpace = "normal";
    
  });
}
export class Sankey extends Chart {
  protected _baseParameters: any = {
    chart: {
      type: 'columnrange',
      events: {
        redraw: function (el) {
          resizePlotBandLabel(el);
        },
        load: function (el) {
          resizePlotBandLabel(el)
          this.parameters.series.forEach((serie: any) => {
            try {
              serie.color = el.target.series.find((x: any) => {
                x.name === serie.name && x.hasOwnProperty('userOptions') && x.userOptions.showInLegend
              }).color
            } catch (e) {
              // SERIE WOULDN'T APPEAR
            }
          });
          this.updateChartTranslations(el.target, this.parameters, this.lang);
          this.buildGraphOptions(this.parameters);
          this.onOptionChangeAfterLoadSubject.next(this.options);
        }.bind(this)
      }
    },
    tooltip: {
      //shared: true,
      useHTML: true,
      formatter: function (el: any) {
        const { userOptions } = el.chart;
        if (userOptions.hasOwnProperty('plotOptions') && userOptions.plotOptions.hasOwnProperty('attributes_blocks')) return SankeyChartTooltipFormatter.formatter(this, userOptions.language, userOptions.plotOptions.attributes_blocks.enabled);
        else return SankeyChartTooltipFormatter.formatter(this, userOptions.language, false);
      },
    },

    plotOptions: {
      columnrange: {
        stacking: 'normal',
      }
    },
  };

  private initialData: any;
  public static mapSettings: Map<string, string> = mapSettingsKey;


  constructor(
    _parameters?: any,
    _data?: Array<any>,

    _lang?: string,
    _filters?: any,
    private _translateService?: TranslateService
  ) {
    super(_parameters, _data, _lang, _filters);
    this.tableRawData = this.formatRawData(_data, _lang);
    this.generateParametersAssignment(this.parameters);
    this.generateDataAssignment(this.data, this.lang);
  };

  private generateParametersAssignment(parameters) {
    Object.assign({ ...this._baseParameters, parameters });
    Object.keys({ ...this._baseParameters }).forEach((k) => {
      this.parameters[k] = Object.assign({ ...this._baseParameters[k] }, parameters[k])
    });
    Object.keys({ ...parameters }).forEach((k) => {
      this.parameters[k] = Object.assign({ ...this.parameters[k] }, parameters[k])
    });
  };

  protected generateDataAssignment = (data: any, lang: string) => {
    this.data = data;
    const method = this.parameters.compute ? this.parameters.compute.method : null;
    const baseKey = this.parameters.compute ? this.parameters.compute.key : 'key';
    const payload = this.formatData(JSON.parse(JSON.stringify(data)), method, baseKey, lang, this.descriptors);
    this.parameters.series = [...payload.series, {
      "xAxis": 0,
      "showInLegend": false,
      "type": "line",
      "enableMouseTracking": false,
      "color": "rgba(255,255,255,0)",
      "data": new Array(payload.xAxis.categories.length).fill(0)
    }]
    this.parameters.yAxis = Object.assign(this.parameters.yAxis, payload.yAxis);
    this.parameters.xAxis = payload.xAxis;
    if (this.chart) this.updateChartTranslations(this.chart, this.parameters, this.lang);

  };

  /**
   * buildGraphOptions
   */
  public buildGraphOptions = (options: any) => {
    const { title, subtitle, series, plotOptions, ...rest } = options;
    this.options = new SankeyOptions({
      general: { title, subtitle },
      series,
      plotOptions
    }, this.onOptionsChange);
  };

  protected setParamsToAxis = (params: any, axis: any) => Object.assign(axis, params);

  protected formatData = (data: Array<any>, method: string, baseKey: string, lang: string, descriptors?: Array<Descriptor>): { xAxis: any, yAxis: any, series: Array<any> } => {

    /* let langs: Array<string> = [this.lang];
    if (parameters.hasOwnProperty('plotOptions') && parameters.plotOptions.hasOwnProperty('default_language')) {
      if (parameters.plotOptions.default_language.enabled && langs.indexOf(Languages.Default) === -1) langs.push(Languages.Default);
    } */

    const payload = {
      series: {},
      xAxis: {
        type: 'category',
        visible: true,
        opposite: true,
        categories: { '': '' },
        plotLines: [],
        plotBands: [
          {
            color: 'rgba(0,0,0,0.3)',
            dashStyle: 'dashdot',
            value: 0.5
          }
        ]
      },
      yAxis: {
        plotBands: []
      }
    };

    switch (method) {
      case null:
        break;
      case COMPUTING_METHODS.MEDIAN:
        SankeyMedianCompute.doWork(data, baseKey, lang, descriptors, payload, this.routines);
        break;
    }
    return {
      xAxis: {
        ...payload.xAxis,
        categories: Object.values(payload.xAxis.categories),
      },
      yAxis: payload.yAxis,
      series: Object.values(payload.series)
    }
  };



  private onOptionsChange = ( options: any ) => {

    let fontOptions: any;

    for (let o in options) {
      if (o === 'general') {
        const {font, ...rest} = options[ o ];
        Object.assign( this.parameters, rest );
        fontOptions = font;
      } else {
        for (let e in options[o]) {
          if (Array.isArray(options[o][e])) {
            options[o][e].forEach(x => {
              this.parameters[e]
                .filter((y: any) => y.name === x.name)
                .forEach((y: any) => {
                  Object.assign(y, x);
                })
            })
          } else {
            Object.assign(this.parameters[e], options[o][e]);
          }
        }
      }
    };
    const { yAxis, xAxis, ...parameters } = this.parameters;
    Object.assign(this.parameters, { ...parameters });
    Object.assign(this.parameters.yAxis, yAxis);
    Object.assign(this.parameters.xAxis, xAxis);
    const chart = this.build();
    if( fontOptions ) this.updateChartFont( chart, fontOptions );
    this.updateChartTranslations(chart, this.parameters, this.lang);
  };

  protected median = (array: Array<any>) => {
    const mid = Math.floor(array.length / 2),
      nums = [...array].sort((a, b) => a.value - b.value);
    return array.length % 2 !== 0 ? nums[mid].value : (nums[mid - 1].value + nums[mid].value) / 2;
  };

  /**
   * updateChartTranslations
   * Method to manage languages changes
   * @param chart : Highcharts.Chart : Rendered chart
   * @param parameters : any : Chart's parameters
   * @param lang : string
  */
  private updateChartTranslations = (chart: Highcharts.Chart, parameters: any, lang: string): void => {
    this.updateTitleTranslations(chart, lang, parameters);
    this.updateSubTitleTranslations(chart, lang, parameters);
    this.updateCategoriesTranslations(chart, parameters);
    this.updatePlotlinesAndBandsTranslations(chart, parameters, lang);

    chart.redraw();
  };

  /**
   * updateCategoriesTranslations
   * Method to update categories' translations
   * @param chart : Highcharts.Chart : Rendered chart
   * @param parameters : any : Chart's parameters
  */
  private updateCategoriesTranslations = (chart: Highcharts.Chart, parameters: any): void => {
    chart.xAxis[0].update({
      categories: parameters.xAxis.categories
    });
  };

  /**
   * updatePlotlinesAndBandsTranslations
   * Method to update plot lines and bands translations
   * @param chart : Highcharts.Chart : Rendered chart
  */
  private updatePlotlinesAndBandsTranslations = (chart: Highcharts.Chart, parameters: any, lang: string): void => {
    /*
    ** Update xAxis plotBands labels
    */
    chart.xAxis[0]['plotLinesAndBands'].forEach((band: any, index: number) => {
      if (band.hasOwnProperty('options') && band.options.hasOwnProperty('label') && band.options.label.hasOwnProperty('originalObject')) {
        let showAttributesBlocks: boolean = parameters.hasOwnProperty('plotOptions') && parameters.plotOptions.hasOwnProperty('attributes_blocks') && parameters.plotOptions.attributes_blocks.enabled;
        let langs: Array<string> = [this.lang];
        if (parameters.hasOwnProperty('plotOptions') && parameters.plotOptions.hasOwnProperty('default_language')) {
          if (parameters.plotOptions.default_language.enabled && langs.indexOf(Languages.Default) === -1) langs.push(Languages.Default);
        }
        chart.xAxis[0]['plotLinesAndBands'][index].label.attr({
          text: SankeyChartLabelFormatter.formatter(band.options.label.originalObject, langs, showAttributesBlocks),
          useHTML: true,
        });
      };
    });

    /*
    ** Update yAxis plotBands labels
    */
    chart.yAxis[0]['plotLinesAndBands'].forEach((band: any, index: number) => {
      if (band.hasOwnProperty('options') && band.options.hasOwnProperty('label') && band.options.label.hasOwnProperty('translations')) {
        chart.yAxis[0]['plotLinesAndBands'][index].label.attr({
          text: this.getObjectValueTranslation(band.options.label.translations, lang).toUpperCase()
        });
      };
    });
  };

  protected formatRawData = (data: Array<any>, lang: string): { header: Array<any>, body: Array<any> } => {
    try {
      const payload = JSON.parse(JSON.stringify(data['data'])).reduce((accumulator: any, object: any, itemNumber: number) => {
        const payload = object.values.reduce((reducer: any, item: any, index: number) => {
          item.values.reduce((redacc: any, value: any, idx: number) => {
            redacc[value.label] = value;
            return redacc;
          }, reducer);
          return reducer;
        }, {});

        const keyList = Object.keys(payload);
        const oPayload = object.values.reduce((reducer: any, item: any, index: number) => {
          if (item.values.length !== keyList.length) {
            for (let obj of keyList) {
              const foundObject = item.values.find((x: any) => { return x.label === obj });
              if (!foundObject) {
                item.values.push({
                  label: payload[obj].label,
                  value: { label: "", key: "" }
                });
              }
            }
          }
          item.values.sort((a, b) => {
            const labelA = Chart.getObjectValueTranslation(a.label, lang)
            const labelB = Chart.getObjectValueTranslation(b.label, lang)
            return labelA.localeCompare(labelB);
          })
          return reducer;
        }, object);
        accumulator.push(oPayload);
        return accumulator;
      }, []);

      data['data'] = Object.values(payload.reduce((accumulator: any, object: any, itemNumber: number) => {
        if (object.attribute.hasOwnProperty('blockName')) {
          const key = object.attribute.blockName.english;
          accumulator[key] = accumulator[key] || { "attribute": { "label": object.attribute.blockName }, "values": [] };
          accumulator[key].values.push(object);
        }
        return accumulator;
      }, {}));

      const tableTemp = new Table({}, data, lang);
      return tableTemp.parameters.transformedHTMLData;
    } catch (e) {
      return { header: [], body: [] };
    }
  };


  private updateChartFont = ( chart: Highcharts.Chart, option: {size: number, bold: boolean;} ): void => {
    try {
      chart.yAxis[ 0 ].update( {
        labels: {
          ...chart.yAxis[0].userOptions.labels,
          style: {
            fontSize: option.size + 'px',
            fontWeight: option.bold ? 'bold' : 'normal',
          }
        }
      }, false );
  
      chart.xAxis[ 0 ].update( {
        labels: {
          ...chart.xAxis[ 0 ].userOptions.labels,
          style: {
            fontSize: option.size + 'px',
            fontWeight: option.bold ? 'bold' : 'normal'
          }
        }
      }, false );
    } catch( e ) {
      console.error('error updating chart font, sanket');
    }
  }

};