import { Component, Input } from '@angular/core';

@Component({
  selector: '[dna-chart-tile-skeleton]',
  templateUrl: './chart-tile-skeleton.component.html',
  styleUrls: ['./chart-tile-skeleton.component.scss']
})
export class ChartTileSkeletonComponent {
  @Input() error : any;
  constructor() { }

}
