export class CommonUtilsPDF {
  public dataValueHandler(data): string {
    let textData = '';
    if (data.Value) {
      data.Value.split(',').forEach((val, index) => {
        const textObject = data.AttributeDetails.find(element => element.Code === val);
        if (textObject) {
          if (index !== 0) {
            textData += `, ${textObject.Text}`;
          } else {
            textData += `${textObject.Text}`;
          }
        }
      });
    }
    return textData
  }
}
