<div class="ibox-content">
  <div class="row">
    <div class="col">
      <button class="btn btn-outline-secondary" type="button" *ngIf="campaignVisits.state === campaignStates.Draft || campaignVisits.state === campaignStates.Suspended" (click)="addVisit()">{{ "ADD_VISIT" | translate }}  <i class="fa fa-plus"></i></button>
    </div>
  </div>
</div>
<div class="ibox-content">
  <div *ngFor="let visit of campaignVisits.visits; let indexVisit = index" class="card mb-3">
    <div class="ibox-content">
      <div class="form-group">
        <div class="row col-12">
          <div class="text-center m-auto"><h4>{{ "ROUTINE_VISIT" | translate }} {{visit.index+1}}</h4></div>
          <div class="text-right mb-2">
            <button *ngIf="indexVisit > 0 && (campaignVisits.state === campaignStates.Draft || campaignVisits.state === campaignStates.Suspended)" class="btn btn-danger" type="button" (click)="removeVisit(indexVisit)">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
        <div class="row col-12 p-0">
            <label class="col-2 my-auto form-control-label text-right">
              {{ "VALUE" | translate }}
            </label>
            <div class=" col-3 input-group w-25">
              <input type="number" [disabled]="campaignVisits.state !== campaignStates.Draft && campaignVisits.state !== campaignStates.Suspended" [ngClass]="{ 'is-invalid': onErrorVisitsValues }" min="0" class="form-control" [(ngModel)]="visit.value" (ngModelChange)="onUnitChange(indexVisit, campaignVisits.visits[indexVisit].unit?.name)" [disabled]="indexVisit === 0">
              <div class="input-group-append" ngbDropdown>
                <button class="form-control btn dropdown-button" [disabled]="campaignVisits.state !== campaignStates.Draft && campaignVisits.state !== campaignStates.Suspended" ngbDropdownToggle>{{ campaignVisits.visits[indexVisit].unit?.name || "UNIT" | translate}}</button>
                <div ngbDropdownMenu>
                  <ng-container *ngFor="let unit of units">
                    <a ngbDropdownItem (click)="setVisitUnit(indexVisit, unit)" translate>{{ unit }}</a>
                  </ng-container>
                </div>
              </div>
          </div>
          <label class="col-1 my-auto form-control-label required">
            {{ "NAME" | translate }}
          </label>
          <div class="col-3">
            <input class="form-control" [(ngModel)]="visit.name" type="text" name="name" disabled>
          </div>
          <div class="col-3">
            <button class="btn btn-outline-secondary" *ngIf="campaignVisits.state === campaignStates.Draft || campaignVisits.state === campaignStates.Suspended" type="button" (click)="manageBlocks(visit, indexVisit)">{{ "MANAGE_BLOCKS" | translate }}</button>
          </div>
        </div>
        <div class="row col-12 mt-2">
          <button type="button" class="btn btn-block btn-light" attr.aria-controls="{{'collapseVisit_' + indexVisit}}" (click)="collapsedVisitTab[indexVisit] = !collapsedVisitTab[indexVisit]">
            <h4>{{ "BLOCKS" | translate }}</h4>
          </button>
        </div>
        <div class="row col-12" id="{{'collapseVisit_' + indexVisit}}" [ngbCollapse]="collapsedVisitTab[indexVisit]">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th style="width: 33%">{{ "WORKFLOW" | translate }}</th>
                <th style="width: 33%">{{ "NAME" | translate }}</th>
                <th style="width: 33%">{{ "DESCRIPTION" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let block of visit.blocks; let indexBlock = index">
                <ng-container *ngIf="block.isActive">
                  <td>
                    <dna-translate [fieldToTranslate]="block.workflowName"> </dna-translate>
                  </td>
                  <td>{{blockNameWithSuffix(block, visit)}}</td>
                  <td>
                    <dna-translate [fieldToTranslate]="block.description"> </dna-translate>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
