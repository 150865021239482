<div class="modal-header">
  <h3 class="modal-title">
    <!-- <dna-translate [fieldToTranslate]="workflow.name"> </dna-translate> -->
    {{ "VISITS_UPDATED" | translate }}
  </h3>
</div>
<div class="modal-body">
  {{ "ERROR_BLOCKS_DELETED_VISITS" | translate }}
  <ng-container *ngFor="let blockDeleted of blocksDeleted">
    <li>
      <dna-translate [fieldToTranslate]="blockDeleted.blockName"> </dna-translate>( {{ "ROUTINE_VISIT" | translate }}: 
      {{blockDeleted.visit }}, {{ "WORKFLOW" | translate }}: 
      <dna-translate [fieldToTranslate]="blockDeleted.workflowName"> </dna-translate> ) 
    </li>
  </ng-container>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="cancel()" translate>CLOSE</button>
</div>



<!-- <button class="btn btn-default" type="button" (click)="cancel(objectForm)" translate>CLOSE</button> -->
