
import {of as observableOf,  Observable, Subject } from 'rxjs';

import {catchError, tap, take, takeUntil} from 'rxjs/operators';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { CampaignOnePager, OnePager } from '@app/types';
import { OnePagerService } from '../../services/one-pager.service';
import { ErrorManagerService } from '../../services/errorManager.service';
import { UtilService } from '../../services/util.service';
import { ApplicationInsightsService } from '../../services/applicationInsights.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dna-one-pager-context',
  templateUrl: './one-pager-context.component.html',
  styleUrls: ['./one-pager-context.component.less']
})
export class OnePagerContextComponent implements OnInit, OnDestroy, AfterViewInit {

  campaign: CampaignOnePager;
  onePager: OnePager;
  initialOnePager: OnePager;
  contextForm: FormGroup;
  initialContextForm: FormGroup;
  isChanged = false;
  isCancelAvailable = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  initTime = performance.now();

  constructor(
    private onePagerService: OnePagerService,
    private errorManagerService: ErrorManagerService,
    private aiService: ApplicationInsightsService,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.init();
    this.initialContextForm = _.cloneDeep(this.contextForm);
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign One Pager Context', '', performance.now() - this.initTime, templateUrl);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
}

  private assignFormInOnePager = (form: any) => {
    this.onePager = Object.assign(this.onePager, form);
    this.onePagerService.setOnePager(this.onePager);
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.utilService.canDeactivate(this.isChanged, false);
  }

  catchError = (err) => {
    this.errorManagerService.catchError(err);
    return observableOf();
  }

  formatFormValues(formValues: any) {
    return {
      ...formValues,
      context: this.utilService.htmlToText(formValues.context),
      objectives: this.utilService.htmlToText(formValues.objectives)
    };
  }

  init() {
    this.campaign = this.onePagerService.getCampaign();
    this.onePager = this.onePagerService.getOnePager();
    this.initialOnePager = _.cloneDeep(this.onePager);
    this.initContextForm();

    // Suppression de la sauvegarde auto temporairement le temps de trouver d'où viennent les pertes de données
    this.contextForm.valueChanges.pipe(
      tap(() => {
        if (!_.isEqual(this.formatFormValues(this.initialContextForm.value), this.formatFormValues(this.contextForm.value))) {
          this.isChanged = true;
          this.ref.detectChanges();
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  private initContextForm() {
    this.contextForm = new FormGroup({
      context: new FormControl(_.get(this.onePager, 'context', '')),
      objectives: new FormControl(_.get(this.onePager, 'objectives', '')),
      kpa: new FormGroup({
        attributes: new FormControl(_.get(this.onePager, 'kpa.attributes', [])),
        categories: new FormControl(_.get(this.onePager, 'kpa.categories', '')),
        mustHaves: new FormControl(_.get(this.onePager, 'kpa.mustHaves', ''))
      })
    });
  }

  onCancel() {
    if (!_.isEquel(this.formatFormValues(this.initialContextForm.value), this.formatFormValues(this.contextForm.value))) {
      this.contextForm = _.cloneDeep(this.initialContextForm);
      this.isChanged = false;
    }
  }

  save(contextForm: FormGroup, isAutoSave: boolean) {
    this.assignFormInOnePager(contextForm.value);
    this.updateOnePager(isAutoSave);
  }

  private updateOnePager = (isAutoSave: boolean) => {
    const elementsToUpdate = this.onePagerService.getElementToUpdate(this.initialOnePager, this.onePager);
    if (!Object.keys(elementsToUpdate).length) {
      this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE');
      this.isChanged = false;
    } else {
      if (!isAutoSave) {
        this.errorManagerService.displayMessage('ON_SAVE_PROCESSING', 'info');
      }
      this.onePagerService.updateOnePager(this.onePager.id, elementsToUpdate).pipe(take(1),
          tap(() => this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE')),
          catchError(this.catchError),
          tap((onePager: OnePager) => {
            this.onePager = onePager;
            this.isChanged = false;
            this.onePagerService.setOnePager(this.onePager);
            this.initialContextForm = _.cloneDeep(this.contextForm);
            this.initialOnePager = _.cloneDeep(this.onePager);
            this.ref.detectChanges();
          })
        ).subscribe();
    }

  }


  reinitForm() {
    this.onePagerService.buildOnePagerContext(this.onePager, this.campaign);
    this.init();
    if (!_.isEqual(this.formatFormValues(this.initialContextForm.value), this.formatFormValues(this.contextForm.value))) {
      this.isChanged = true;
      this.isCancelAvailable = false;
      this.ref.detectChanges();
    }
  }


}
