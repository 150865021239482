import { FormulaEvaluation, Timepoint } from "../../../../types";
import { Languages } from '../analyse/reports/chart-module/enums';
const MAX_FILE_NAME_LENGTH = 250;

function sanitizeFileName(name) {
    return name.replace(/[<>:"/\\|?*]/g, ''); // Remove invalid characters 
}

export function generateFileName(requestNumber: string, activityNumber: string, studyName: string, volunteerOrTimepointId: string, formulaLab: FormulaEvaluation = null) {
    let routineLab = null;
    let randoFirstChar = null;
    if(formulaLab){
        const randoLabside = formulaLab.side ? formulaLab.side : null; // ex : RIGTH
        randoFirstChar = randoLabside ? randoLabside.charAt(0) : null // ex: RIGTH => R
        routineLab = formulaLab.treatment ? formulaLab.treatment : 'NONE';
    }
    let fileName = "DNAPhotos_";
    let sanitizedStudyName = sanitizeFileName(studyName);

    let maxLength = MAX_FILE_NAME_LENGTH - (volunteerOrTimepointId.length + 10 + 1 + 4); // 10 for "DNAPhotos_" , 1 for an underscore "_" et 4 for the ".zip" extension

    if (sanitizedStudyName.length > maxLength) {
        sanitizedStudyName = sanitizedStudyName.substring(0, maxLength);
    }
    if (requestNumber && activityNumber) {
        fileName += `${requestNumber}_${activityNumber}`;
    } else if (activityNumber && !requestNumber) {
        fileName += `${activityNumber}`;
    } else {
        let truncatedStudyName = sanitizedStudyName;
        fileName += `${truncatedStudyName}`;
    }

    fileName += `_${volunteerOrTimepointId}`;
    if (randoFirstChar) {
        fileName += `_${routineLab}-${randoFirstChar}`;
    }

    return fileName;
}

export function generateTimepointNameByLanguage(timepoint: Timepoint, currentLanguage: string) {
    return `${timepoint.name.visitName}--${displayDataByLanguage(timepoint.name.blockName, currentLanguage)}--${displayDataByLanguage(timepoint.name.componentName, currentLanguage)}`
}

export function displayDataByLanguage(dataMultiLanguages: any, currentLanguage: string) {
    if (!dataMultiLanguages[currentLanguage]) {
        return dataMultiLanguages[Languages.English]
    }
    return dataMultiLanguages[currentLanguage]
}