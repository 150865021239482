  <ngx-loading [show]="showSpinner">
</ngx-loading>
<div *ngIf="!showSpinner && !error" class="ibox float-e-margins">
  <div class="ibox-content">
    <div class="table-responsive">
      <dna-table2 [data]="feedbacks" [headers]="tableHeaders$ |async" [numberOfObjectsPerPage]="filter.numberOfObjectsPerPage"
      [pageIndex]="filter.pageIndex" [reverse]="true" [sortFunction]="sort" [sortable]="true" [title]="'FEEDBACK'" [totalItems]="totalItems">
      <ng-template #row let-data="data">
        <tr dna-feedback-row [feedback]="data" (removeFeedback)="remove($event)"></tr>
      </ng-template>
    </dna-table2>
    </div>
  </div>
</div>
<div *ngIf="!showSpinner && error" class="ibox float-e-margins">
  <div class="ibox-title">
    <div class="row">
      <div class="col-12 col-md-4 my-auto">
        <h5>{{ "ERROR" | translate }}</h5>
      </div>
    </div>
  </div>
  <dna-error-page (onClickReload)="init()"></dna-error-page>
</div>
