import { Languages } from "../../enums";

const getObjectValueTranslation = (object: any, lang: string): string => {
    switch (typeof object) {
        case 'object':
            if (object.hasOwnProperty('custom')) return object;
            else if (object.hasOwnProperty('user') && object.user.length) return object.user;
            else if (object.hasOwnProperty(lang) && object[lang].length) return object[lang];
            else return object[Languages.Default];
        default:
            return object;
    };
};


const formatter = (element: any, lang: string, showBlock: boolean): string => {
    const attribute = element.x;
    const footer = '</table>';
    let header: string;
    let body : string;
    switch (typeof attribute) {
        case 'object':
            if ( showBlock && attribute.hasOwnProperty('blockName') ) {
                if (attribute.hasOwnProperty('drilled')) header = `<span style="font-size:12px; font-weight: bold">${getObjectValueTranslation(attribute.blockName, lang)}<br /> ${getObjectValueTranslation(attribute.label, lang)} - ${ getObjectValueTranslation(attribute.drilled.label, lang)} </span><table>`;
                else header = `<span style="font-size:12px; font-weight: bold">${getObjectValueTranslation(attribute.blockName, lang)}<br /> ${getObjectValueTranslation(attribute.label, lang)}</span><table>`;
            }
            else {
                if (attribute.hasOwnProperty('drilled')) header = `<span style="font-size:12px; font-weight: bold">${getObjectValueTranslation(attribute.label, lang)} - ${ getObjectValueTranslation(attribute.drilled.label, lang) } () </span><table>`;
                else header = `<span style="font-size:12px; font-weight: bold">${getObjectValueTranslation(attribute.label, lang)}</span><table>`;
            }
            break;
        default:
            header = `<span style="font-size:12px; font-weight: bold">${attribute}</span><table>`;
    }
    if( element.hasOwnProperty('points')) {
        body = element.points.map(({ point, series }) => {
            const mainLine = `<tr style="font-size: 12px"><td style="color:${series.color};padding:0; font-weight: bold;" colspan="2">${series.name}: </td>
                        <td style="padding:0"><b>${point.y}</b></td></tr>`;
            const details: string[] = [];

            if (point.details) {
                point.details.forEach(item => {
                    const label: string|number = getObjectValueTranslation(item.label, lang);
                    const value: string|number = getObjectValueTranslation(item.value.label, lang);

                    if (label !== undefined && label !== null && value !== undefined && value !== null) {
                        details.push(
                            `<tr style="font-size: 11px">
                                <td></td>
                                <td style="color:${series.color};padding:0;font-style:italic;">
                                    ${label}
                                </td>
                                <td></td>
                                <td style="font-style:italic">
                                    ${value}
                                </td>
                            </tr>`
                        );
                    }
                });
            }
            const innerBody = [
                mainLine,
                ...details
            ].filter(x => x).join("");
    
            return innerBody;
        }).join("");
    } else {
        const mainLine = `<tr style="font-size: 12px"><td style="color:${element.color};padding:0; font-weight: bold;" colspan="2">${element.series.name}: </td>
                    <td style="padding:0"><b>${element.y}</b></td></tr>`;
        try {
            const tempBody: string[] = [];
            element.point.details.forEach(detail => {
                const value: string|number = getObjectValueTranslation(detail.value.label, lang);
                if (value !== undefined && value !== null) {
                    tempBody.push(
                        `<tr style="font-size: 11px">
                            <td></td>
                            <td style="padding:0;font-style:italic;">${ detail.label }</td>
                            <td></td>
                            <td style="font-style:italic"> ${value} </td>
                        </tr>`
                    );
                }
            });
            body = tempBody.join("");
        }
        catch(e) {
            body = '';
        }
        body = [
            mainLine,
            body
        ].join("");
    }

    return [
        header,
        body,
        footer
    ].join("");

};

export {
    formatter
};