<div class="card">
  <div class="card-body">
    <div class="card-text d-flex flex-row justify-content-between align-items-center">
      <div>
        <span translate>WHEN_ANSWERING_BLOCK</span>: {{ blockName }}
      </div>
      <div class="text-right">
        <button *ngIf="logicJumpLength > 1" class="btn logic-button" type="button" aria-label="reorder" style="margin-right:15px">
          <span class="fa fa-sort"></span>
        </button>
        <button class="btn logic-button" type="button" aria-label="remove" (click)="onRemove()" style="margin-right:10px">
          <span aria-hidden="true" [ngbTooltip]="'REMOVE_JUMP' | translate">&minus;</span>
        </button>
        <button class="btn logic-button" type="button" aria-label="add" (click)="onAdd()">
          <span [ngbTooltip]="'ADD_JUMP' | translate" aria-hidden="true">&#43;</span>
        </button>
      </div>
    </div>
    <div class="card-text form-row" style="margin-top: 10px">
      <div class="d-flex flex-row align-items-center">
        <div translate class="col-1">IF</div>
        <div class="col-11 form-group">
          <select class="form-control" name="selectedQuestion" [compareWith]="compareQuestion" [(ngModel)]="selectedQuestion" (ngModelChange)="onChange($event)">
            <option *ngFor="let question of previousQuestions" [ngValue]="question">{{ question.libelle }}</option>
          </select>
        </div>
      </div>
      <div class="d-flex flex-row mt-3">
        <div class="col-4 form-group">
          <select class="form-control" name="selectedCondition" [(ngModel)]="logicJump.condition">
            <option *ngFor="let condition of conditions" [ngValue]="condition" translate>{{condition}}</option>
          </select>
        </div>
        <div class="col-8 form-group small-padding">
          <select *ngIf="selectedQuestion && selectedQuestion.answer && selectedQuestion.answer.values.length > 0" class="form-control"
            name="selectAnswer" [compareWith]="compareById" [(ngModel)]="logicJump.answer">
            <option *ngFor="let answer of selectedQuestion.answer.values; let indexQuestion = index" [ngValue]="selectedQuestion.answer.keys[indexQuestion]">{{answer.value}}</option>
          </select>
          <input *ngIf="selectedQuestion && selectedQuestion.answer && selectedQuestion.answer.values.length === 0" class="form-control"
            [(ngModel)]="logicJump.answer">
        </div>
      </div>
    </div>
    <div class="card-text form-row d-flex flex-row align-items-center" style="margin-top: 10px">
      <div translate class="col-2">THEN_JUMP</div>
      <div class="col-10 form-group">
        <select class="form-control" name="selectedJump" [(ngModel)]="logicJump.jumpTo">
          <option *ngFor="let question of nextQuestions" [ngValue]="question.idInQuestionnaire">{{ question.libelle }}</option>
        </select>
      </div>
    </div>
  </div>
</div>
