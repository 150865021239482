import { Languages } from '../types';
import {
  DNATranslateService
} from '../shared/services/translate.service';
import {
  Component,
  Injectable
} from '@angular/core';
import {
  NgbDatepickerI18n, NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { TranslationWidth } from '@angular/common';

const I18N_VALUES = {
  'french': {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
    monthsFullName: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
  },
  'english': {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    monthsFullName: [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November',
      'December'
    ],
  }

};


// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return I18N_VALUES[this.getLang()].weekdays[weekday - 1];

  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}/${date.month}/${date.year}`;
  }

  constructor(private translateService: DNATranslateService) {
    super();
  }

  getLang() {
    let lang = this.translateService.getLanguage();
    if (lang !== Languages.English && lang !== Languages.French) {
      lang = Languages.English;
    }
    return lang;
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.getLang()].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this.getLang()].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return I18N_VALUES[this.getLang()].monthsFullName[month - 1];
  }
}
