import { Injectable } from '@angular/core';


@Injectable()
export class LocalStorageService {

    constructor() { }

    setItem(id: string, item: any) {
        try {
            localStorage.setItem(id, JSON.stringify(item));
          } catch(e) {
              localStorage.clear();
              localStorage.setItem(id, JSON.stringify(item));
            }
    }

    getItem(id: string): any {
      try {
        return JSON.parse(localStorage.getItem(id));
      } catch (e) {
        return null;
      }
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }

}
