import { DNAComponent, ConfortCodes, ConfortComponent } from '../../../types';
import { Component, Input, OnInit } from '@angular/core';
import { DNATranslateService } from '../../../shared/services/translate.service';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
    selector: 'dna-slider-config',
    templateUrl: './sliderConfiguration.component.html',
    styleUrls: ['./sliderConfiguration.css']
})

export class SliderConfigurationComponent implements OnInit {
    @Input() inactive: boolean;
    @Input() component: DNAComponent;

    confortCodes;
    confortComponent;

    constructor(
        private translateService: DNATranslateService
    ) { }

    ngOnInit() {
        this.confortComponent = ConfortComponent;
        this.initComponent(_.get(this.component, 'args.confortComponent', ''));
    }

    changeLabel(code) {
        let confortCode = this.confortCodes.find(c => c.id === code);
        if (confortCode) {
            this.translateService.getTradInAllLanguages(confortCode.name).pipe(
                tap(data => {
                    this.component.args.label.english = data.english;
                    this.component.args.label.french = data.french;
                })
            ).subscribe();
        }
    }

    initComponent(id) {
        let confortComponent = this.confortComponent.find(c => c.id === id);
        this.component.args.confortValues = confortComponent ? confortComponent.values : [];
        this.confortCodes = ConfortCodes.filter(c=> c.idComponent === id);
    }

    changeConfortComponent(id) {
        this.initComponent(id);
        this.component.args.confortCode = "";
        this.component.args.label.english = "";
        this.component.args.label.french = "";
    }
}

