<div class="datatable" *ngIf="displayTable">
  <div id="exportable" class="table-container">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>
            <span translate>WORKFLOW</span>
            <span class="pull-right">
                        <span class="fa fa-caret-up" (click)="changeOrdering(state.WORKFLOWS, true)" [ngClass]="{active: currentSelected == selectionType.wkUp}"></span>
            <span class="fa fa-caret-down" (click)="changeOrdering(state.WORKFLOWS, false)" [ngClass]="{active: currentSelected == selectionType.wkDown}"></span>
            </span>
          </th>
          <th>
            <span translate>FORMULA</span>
            <span class="pull-right">
                        <span class="fa fa-caret-up" (click)="changeOrdering(state.FORMULA, true)" [ngClass]="{active: currentSelected == selectionType.formulaUp}"></span>
            <span class="fa fa-caret-down" (click)="changeOrdering(state.FORMULA, false)" [ngClass]="{active: currentSelected == selectionType.formulaDown}"></span>
            </span>
          </th>
          <th>
            <span translate>BLOCK</span>
          </th>
          <th>
            <span translate>LABEL</span>
            <span class="pull-right">
                        <span class="fa fa-caret-up" (click)="changeOrdering(state.BLOCKS, true)" [ngClass]="{active: currentSelected == selectionType.blockUp}"></span>
            <span class="fa fa-caret-down" (click)="changeOrdering(state.BLOCKS, false)" [ngClass]="{active: currentSelected == selectionType.blockDown}"></span>
            </span>
          </th>
          <th>
            <span translate>TAG</span>
          </th>
          <th>
            <span translate>COMPONENT_TYPE</span>
            <span class="pull-right">
                        <span class="fa fa-caret-up" (click)="changeOrdering(state.COMPONENTS, true)" [ngClass]="{active: currentSelected == selectionType.componentUp}"></span>
            <span class="fa fa-caret-down" (click)="changeOrdering(state.COMPONENTS, false)" [ngClass]="{active: currentSelected == selectionType.componentDown}"></span>
            </span>
          </th>
          <th>
            <span translate>AVERAGE</span>
          </th>

        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let result of sort(data, order)">
          <td data-title="WORKFLOW">{{result.workflow.name}}</td>
          <td data-title="FORMULA">{{result.formula.name}}</td>
          <td data-title="BLOCK">{{result.block.name}}</td>
          <td data-title="LABEL">{{result.component.args.label}}
          </td>
          <td data-title="TAG">{{result.component.args.tag}}</td>
          <td data-title="COMPONENT_TYPE">{{result.component.type}}</td>
          <td data-title="AVERAGE" *ngIf="result.answers.average === 0; else answersAverage">
          </td>
          <ng-template #answersAverage>
            <td data-title="AVERAGE">{{result.answers.average}}</td>
          </ng-template>
        </tr>
      </tbody>

    </table>
  </div>

</div>
