import { Component, OnInit, Input } from '@angular/core';
import { Volunteers } from '../../../../types';
import { VolunteerService } from '../../../../volunteers/volunteers.service';
import { ErrorManagerService } from '../../../../shared/services/errorManager.service';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from "@angular/forms";

@Component({
    selector: 'dna-volunteer',
    templateUrl: './editVolunteerModal.component.html'
})

export class EditModalComponent implements OnInit {
    @Input() volunteer: any;
    volunteerCopy: any;

    constructor(
        private volunteerService: VolunteerService,
        private errorManager: ErrorManagerService,
        private activeModal: NgbActiveModal
    ) { }

    ngOnInit() {
        if (this.volunteer) {
            this.volunteerCopy = new Volunteers;
            this.volunteerCopy.id = this.volunteer.id;
            this.volunteerCopy.nameFirstName = this.volunteer.nameFirstName;
            this.volunteerCopy.adminKey = this.volunteer.adminKey;
        }
    }

    cancel() {
        this.activeModal.dismiss();
    };

    save(form: NgForm) {
        if (form.valid) {
            this.volunteerService.putVolunteer(this.volunteerCopy)
                .subscribe(
                success => {
                    this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
                    this.activeModal.close();
                },
                error => this.errorManager.displayMessage(error)
                )
        } else {
            this.errorManager.displayMessage('ON_ERROR_FORM', "danger");
        }
    }
}
