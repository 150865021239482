
import { catchError, filter, finalize, map, mergeMap, tap } from 'rxjs/operators';
import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  NgForm
} from '@angular/forms';

import * as _ from 'lodash';
import {
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import {
  merge,
  Observable, of
} from 'rxjs';

import {
  ErrorManagerService
} from '../../../../../shared/services/errorManager.service';
import {
  HttpRestService
} from '../../../../../shared/services/httpRest.service';
import {
  Metier,
  User,
  Workspace
} from '../../../../../types';
import {
  PowerBIService
} from '../../../../../shared/powerbi/powerbi.service';
import {
  UserService
} from '../../../../../shared/services/user.service';
import {
  WorkspaceService
} from '../../workspaces.service';

@Component({
  selector: 'dna-workspace',
  templateUrl: './workspaceModal.component.html',
  styleUrls: ['./workspaceModal.component.less']
})

export class WorkspaceModalComponent implements OnInit {

  @Input() adminsToDisplay: any[];
  @Input() users: any;
  @Input() usersToDisplay: any[];
  @Input() idWorkspace: string;
  @Input() workspaces: Workspace[];

  workspace: any;
  metiers: Metier[];
  showSpinner: boolean = false;
  submitted: boolean;
  user: User;
  workspaceCopy: Workspace = new Workspace();
  userIsAdministrator: boolean = false;

  public requestAutocompleteItems = (): Observable<Response> => {
    return this.powerbiService.getWorkspaces().pipe(
      map(data => data.value.map(value => _.omit(value, ['isReadOnly', 'isOnDedicatedCapacity']))));
  };

  constructor(
    private activeModal: NgbActiveModal,
    private errorManagerService: ErrorManagerService,
    private httpRestService: HttpRestService,
    private powerbiService: PowerBIService,
    private userService: UserService,
    private workspaceService: WorkspaceService
  ) { }

  ngOnInit() {
    this.metiers = this.workspaceService.getMetiers();
    this.user = this.userService.getUser();
    this.userIsAdministrator = this.userService.isAuthorized('DNA_ADMIN');
    if (this.idWorkspace) {
      this.workspaceService.getWorkspace(this.idWorkspace).pipe(
        tap(workspace => {
          this.workspace = workspace;
          this.workspaceCopy = _.cloneDeep(this.workspace);
          this.workspaceCopy.administrators = _.cloneDeep(this.getAdminsToDisplay(this.workspace.administrators));
          this.workspaceCopy.users = _.cloneDeep(this.getUsersToDisplay(this.workspace.users));
          if (!this.workspaceCopy.users) {
            this.workspaceCopy.users = [];
          }
          if (!this.workspaceCopy.administrators) {
            this.workspaceCopy.administrators = [];
          }
        })
      ).subscribe();
    }
  }

  cancel() {
    this.activeModal.dismiss();
  }

  byId(a: Metier, b: Metier): boolean {
    return a && b ? a.id === b.id : a === b;
  }

  onAddAdminToUsers(admin: any) {
    let userIsPresent = this.workspaceCopy.users
      .filter((user) => user.key === admin.key).length !== 0 ? true : false;

    if (!userIsPresent) {
      this.workspaceCopy.users.push(admin);
    }
  }

  onRemoveUser(user: any) {
    let userIsAdmin = this.workspaceCopy.administrators
      .filter((admin) => admin.key === user.key).length === 0 ? false : true;

    if (this.user.roles.indexOf('DNA_ADMIN') === -1 && userIsAdmin) {
      this.workspaceCopy.users.push(user);
    }
  }

  save(form: NgForm) {
    let idWorkspace = this.workspaceCopy.id;
    this.submitted = true;
    if (form.valid && this.workspaceCopy.users.length != 0) {
      this.showSpinner = true;
      let nameToCompare = this.workspaceCopy.name;
      let nameHasChanged = this.workspace ? this.workspace.name != nameToCompare : true;
      let workspaceToSend = _.cloneDeep(this.workspaceCopy);
      workspaceToSend.users = _.map(workspaceToSend.users, 'key');
      workspaceToSend.administrators = _.map(workspaceToSend.administrators, 'key');

      this.checkName(nameHasChanged, nameToCompare).pipe(
        filter(result => result),
        mergeMap(() => this.createOrUpdateWorkspace(idWorkspace, workspaceToSend))
      ).subscribe()

    } else {
      this.errorManagerService.displayMessage('ON_ERROR_FORM', "danger");
    }
  }

  getAdminsToDisplay(arrayAdminsKey: any[]) {
    if (!arrayAdminsKey) {
      arrayAdminsKey = [];
    }
    return arrayAdminsKey.reduce((adminsToDisplay, userKey) => {
      if (this.users) {
        let matchUser = this.users.find((user) => user.key == userKey);
        if (matchUser) {
          adminsToDisplay.push(matchUser);
        }
      }
      return adminsToDisplay;
    }, []);
  }

  getUsersToDisplay(arrayUsersKey: any[]) {
    if (!arrayUsersKey) {
      arrayUsersKey = [];
    }
    return arrayUsersKey.reduce((usersToDisplay, userKey) => {
      if (this.users) {
        let matchUser = _.find(this.users, function (user) {
          return user.key == userKey
        });
        if (matchUser) {
          usersToDisplay.push(matchUser);
        }
      }
      return usersToDisplay;
    }, []);
  }

  checkName(hasNameChanged, nameToCompare) {
    return hasNameChanged
      ? this.httpRestService.isUniqueName(nameToCompare, 'workspaces').pipe(
        tap(result => {
          if (!result) {
            this.showSpinner = false;
            this.errorManagerService.displayMessage('NAME_ALREADY_TAKEN', 'danger')
          }
        })
      )
      : of(true)
  }

  createOrUpdateWorkspace(idWorkspace, workspaceToSend) {
    const create$ = this.workspaceService.createWorkspace(workspaceToSend).pipe(
      catchError(error => {
        this.errorManagerService.displayMessage('ON_ERROR_FORM', "danger");//changer message
        console.log(error);
        return of()
      }),
      tap((response: Workspace) => this.workspaces.push(response)),
      tap(() => this.errorManagerService.displayMessage('ON_SUCCESS_CREATE'))
    );

    const update$ = this.workspaceService.putWorkspace(workspaceToSend).pipe(
      catchError(error => {
        this.errorManagerService.displayMessage('ON_ERROR_FORM', "danger");//changer message
        console.log(error);
        return of()
      }),
      tap(() => this.workspaces[this.workspaces.findIndex(w => w.id === workspaceToSend.id)] = workspaceToSend),
      tap(() => this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE'))
    )

    const obs$ = !idWorkspace ? create$ : update$;

    return obs$.pipe(
      tap((response: Workspace) => this.activeModal.close(response)),
      finalize(() => this.showSpinner = false)
    )

  }
}
