
import {of as observableOf, from as observableFrom, forkJoin as observableForkJoin, 
  Observable,
  Subject
} from 'rxjs';

import {map, tap, mergeMap} from 'rxjs/operators';
import {
  EventEmitter,
  Injectable
} from '@angular/core';

import * as _ from 'lodash';
import {
  LangChangeEvent,
  TranslateService
} from '@ngx-translate/core';

import {
  HttpRestService
} from './httpRest.service';
import {
  Language,
  Languages,
  Translatable
} from '../../types';


class TranslatedData {
  lang: string;
  data: string;

  constructor(lang, data) {
    this.lang = lang;
    this.data = data;
  }
}

@Injectable()
export class DNATranslateService {

  languages: string[];
  isLanguageChanges: Subject<boolean> = new Subject();

  constructor(
    private httpRestService: HttpRestService,
    private translateService: TranslateService
  ) {}

  getDateFormat() {
    if (this.getLanguage() === 'french') {
      return 'JJ/MM/AAAA';
    } else {
      return 'yyyy-dd-mm';
    }
  }

  /**
   * Get the current language used by DNA Application
   * @returns string
   */
  getLanguage(): Language {
    return this.translateService.currentLang ? < Language > this.translateService.currentLang : Languages.English;
  }

  getTradInAllLanguages(key: string): Observable < Translatable > {
    let MyObj = new Translatable();
    return observableForkJoin(
        observableFrom(this.getLanguages()).pipe(
        mergeMap(langs => observableFrom(langs)),
        mergeMap(lang => this.getTradInLanguage(lang, key)),
        tap(translatedData => MyObj[translatedData.lang] = translatedData.data ? translatedData.data : MyObj.english),
        map(() => MyObj),)).pipe(
      map(array => array[0]))
  }

  getTradInLanguage(lang: string, key: string): Observable < TranslatedData > {
    return observableOf(this.translateService.translations[lang]).pipe(
      map(d => new TranslatedData(lang, _.get(d, key))));
  }

  getLanguages(): Promise < string[] > {
    return new Promise((resolve, reject) => {
      if (_.isUndefined(this.languages)) {
        this.httpRestService.getLanguages().subscribe(data => {
          this.languages = data.list;
          resolve(data.list);
        });
      } else {
        resolve(this.languages);
      }
    });
  }

  getTranslatablesFieldsOfComponent(type: string): any[] {
    var objectTranslation = [];

    var componentType = {
      type: type
    };

    var translationLabel = this.getTranslationString('label', 'LABEL');
    //  var translationTag = getTranslationString('tag', 'TAG');
    objectTranslation.push(componentType, translationLabel);

    switch (type) {

      case 'dna-toggle':
        var translationOnLabel = this.getTranslationString('onlabel', 'ONLABEL');
        var translationOffLabel = this.getTranslationString('offlabel', 'OFFLABEL');
        objectTranslation.push(translationOnLabel, translationOffLabel);
        break;

      case 'dna-checkbox':
      case 'dna-radio-button':
      case 'dna-radio-list':
      case 'dna-ranking':
        var translationValues = this.getTranslationArray('values', 'value', 'VALUES');
        objectTranslation.push(translationValues);
        break;

      case 'dna-input-text':
        var translationPlaceHolder = this.getTranslationString('placeholder', 'PLACEHOLDER');
        objectTranslation.push(translationPlaceHolder);
        break;

      case 'dna-radio-versus':
      case 'dna-radio-range':
      case 'dna-radio-button-dual':
        var translationDescriptionRight = this.getTranslationString('descriptionRight', 'LABEL_RIGHT');
        var translationDescriptionLeft = this.getTranslationString('descriptionLeft', 'LABEL_LEFT');
        objectTranslation.push(translationDescriptionRight, translationDescriptionLeft);
        break;
      case 'dna-dual-range':
      case 'dna-checkrange':
        var translationDescriptionRight = this.getTranslationString('labelRight', 'LABEL_RIGHT');
        var translationDescriptionLeft = this.getTranslationString('labelLeft', 'LABEL_LEFT');
        objectTranslation.push(translationDescriptionRight, translationDescriptionLeft);
        break;
      case 'dna-multiple-choice':
        var translationDescriptionRight = this.getTranslationString('labelRight', 'LABEL_RIGHT');
        var translationDescriptionLeft = this.getTranslationString('labelLeft', 'LABEL_LEFT');
        var translationCentralValue = this.getTranslationArray('centralValue', 'value', 'CENTRAL_MAIN');
        var translationValues = this.getTranslationArray('values', 'value', 'VALUES');
        var translationValuesLeft = this.getTranslationArray('valuesLeft', 'value', 'LEFT_VALUES');
        var translationValuesRight = this.getTranslationArray('valuesRight', 'value', 'RIGHT_VALUES');
        objectTranslation.push(translationDescriptionRight, translationDescriptionLeft, translationValues, translationCentralValue, translationValuesLeft, translationValuesRight);
        break;
    }

    return objectTranslation;
  }

  getTranslation(data: Translatable = new Translatable(), currentLanguage: Language = Languages.English): any{
    const currentTranslation = _.get(data, currentLanguage,'');
    return currentTranslation.length > 0 ? currentTranslation : _.get(data, 'english','');
  }

  getTranslationArray(fieldName: string, key: string, translationKey: string) {
    var object = {};
    object = {
      fieldName: fieldName,
      type: 'Array',
      key: key,
      translationKey: translationKey
    };
    return object;
  }

  getTranslationString(fieldName: string, translationKey: string): {
    fieldName: string,
    type: string,
    translationKey: string
  } {
    var object: any = {};
    object = {
      fieldName: fieldName,
      type: 'String',
      translationKey: translationKey
    };
    return object;
  }

  onLangChange(): EventEmitter < LangChangeEvent > {
    return this.translateService.onLangChange;
  }

  setLanguage(language: string) {
    return this.translateService.use(language);
  }

  translateMessage(message: string, interpolate ? : Object): Observable < string > {
    return message ? this.translateService.get(message, interpolate) : observableOf('');
  }

  verifTranslationWorkflow(translation: any): boolean {
    translation = translation.english.blocks;
    for (var i in translation) {
      var block = translation[i];
      for (var j in block.components) {
        var component = block.components[j];
        for (var k in component.args) {
          // TO DO: have information about field mandatory and field not mandatory
          if (k != "tag" && k != "cmInputKeys" && k != "centralmain" && k != "placeholder" && k != "firstValToCalculate" && k != "secondValToCalculate" && (_.isUndefined(component.args[k]) || (_.isString(component.args[k]) && _.isEmpty(component.args[k])) || (_.isArray(component.args[k]) && (component.args[k].length == 0 && (k != "activatedFunctionalities" || component.args.recordMode == "live"))))) {
            return false;
          }
        }
      }
    }
    return true;
  }

  updateTranslations(language: string, translations: object) {
    this.translateService.setTranslation(language, translations, true);
  }
}
