<form #dnaRankingForm="ngForm" class='form-horizontal d-flex flex-column gap-3'>
    <div class="form-group">
        <div class="col-sm-2">
            <label class="control-label" translate>LABEL </label>
            <span class="fa fa-info" placement="bottom" ngbTooltip="{{'tooltip_label' | translate}}" container="body"></span>
        </div>
        <div class="col-sm-10">
            <input name="label" type='text'  class='form-control' [(ngModel)]="component.args.label.english" [disabled]="inactive" required>
        </div>
    </div>
    <div class="form-group">
        <div class="col-sm-2">
            <label class="control-label" translate>TAG</label>
            <span class="fa fa-info" placement="bottom" ngbTooltip="{{'tooltip_tag' | translate}}" container="body"></span>
        </div>
        <div class="col-sm-10">
             <input name="tag" type='text' class='form-control' [(ngModel)]="component.args.tag" [disabled]="inactive">
        </div>
    </div>
    <div class="form-group" [ngClass]="{'has-error' : ( component.args.keys.length == 0 && ( submitted || dnaRankingForm.dirty ) ) }">
        <div class="col-sm-2">
            <label for="size" class="control-label" translate>KEYS <dna-mandatory></dna-mandatory> </label>
			<span class="fa fa-info" placement="bottom" ngbTooltip="{{'tooltip_key' | translate}}"></span>
        </div>
        <div class="col-sm-10">
			<tag-input name="keys" identifyBy="id" displayBy="id" [(ngModel)]="component.args.keys" (onAdd)="sendError()" (onRemove)="sendError()" [maxItems]="50" [disable]="inactive">
            </tag-input>
            <div *ngIf="component.args.keys.length == 0 && ( submitted || dnaRankingForm.dirty )"
            class="alert alert-danger" translate>
            REQUIRED
            </div>
        </div>
    </div>
    <div class="form-group" [ngClass]="{'has-error' : ( component.args.values.english.length == 0 && ( submitted || dnaRankingForm.dirty ) ) ||  ( component.args.values.english.length !== component.args.keys.length )}">
        <div class="col-sm-2">
            <label for="size" class="control-label" translate>VALUES <dna-mandatory></dna-mandatory> </label>
            <span class="fa fa-info" placement="bottom" ngbTooltip="{{'tooltip_value' | translate}}"></span>
        </div>
        <div class="col-sm-10">
			<tag-input name="values" identifyBy="value" displayBy="value" [(ngModel)]="component.args.values.english" (onAdd)="sendError()" (onRemove)="sendError()" [maxItems]="50" [disable]="inactive">
            </tag-input>
            <div *ngIf="component.args.values.english.length == 0 && ( submitted || dnaRankingForm.dirty )"
            class="alert alert-danger" translate>
            REQUIRED
            </div>
            <div class="alert alert-danger" *ngIf="component.args.values.english.length !== component.args.keys.length" translate>ERROR_NUMBER_TAGS</div>
        </div>
    </div>
</form>

