
import {map} from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { Observable } from 'rxjs';

import { ActionBarButton, ActionTypes, DNATypes, Languages, Workflow } from '../../../types';
import { UtilService } from '../../../shared/services/util.service';
import * as _ from 'lodash';

@Component({
  selector: '[dna-workflow-row]',
  templateUrl: './workflow-row.component.html',
  styleUrls: ['./workflow-row.component.less']
})
export class WorkflowRowComponent implements OnChanges {

  @Input() currentLanguage: Languages;
  @Input() workflow: Workflow;

  @Output() onClick: EventEmitter<ActionBarButton> = new EventEmitter();

  actionsButtons: Observable<ActionBarButton[]>;

  constructor(
    private utilService: UtilService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (_.get(changes, 'workflow.currentValue.state')) {
      this.updateActionsButtons();
    }
  }

  onClickButton(actionsButton: ActionBarButton) {
    this.onClick.emit(actionsButton);
  }

  onClickWk() {
    this.onClick.emit(new ActionBarButton(ActionTypes.Edit, '', ''));
  }

  updateActionsButtons() {
    this.actionsButtons = this.utilService.createActionsButtons(DNATypes.Workflow).pipe(map(this.utilService.filterByArchive(this.workflow.state)));
  }

}
