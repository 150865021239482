export default {
    "type": "columnchart",
    "configs": {
        "compute": {
            "method" : "median",
            "key" : "label"
        },
        "drilldown": {
            "enabled": true
        },
        "title" : {
            "text" : "",
            "margin": 50
        },
        "subtitle" : {
            "text" : ""
        },
        "chart" : {
            "backgroundColor": 'rgba(0,0,0,0)'
        },
        "yAxis": {
            "min": 0,
            "max": 6
        },
        "plotOptions": {
            "attributes_blocks" : {
                "enabled" : false
            },
            "default_language" : {
                "enabled" : false
            },
            "show_column_zero" : {
                "enabled" : true
            },
            "series": {
                "dataLabels": {
                    "enabled": false
                }
            }
        }
    }
}