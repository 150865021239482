
import { ErrorManagerService } from './../../../../shared/services/errorManager.service';
import { MultiCampaignsService } from './../../../multi-campaigns.service';
import { CampaignsMulti, DescriptorGroup, Graph } from './../../../../types';
import * as _ from 'lodash';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyseMultiService } from '../analyse-multi.services';
import { catchError, map, take, tap, mergeMap, defaultIfEmpty, finalize } from 'rxjs/operators';
import { forkJoin as observableForkJoin, Observable, throwError, of as observableOf } from 'rxjs';
import { DNATranslateService } from './../../../../shared/services/translate.service';
import { UtilService } from './../../../../shared/services/util.service';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-charts-multi-report',
  templateUrl: './charts-multi.component.html',
 // styleUrls: ['./analyse.component.less']
})

export class AnalyseMultiChartsComponent implements OnInit, AfterViewInit {

  campaignMulti: CampaignsMulti;
  campaignMultiUntouched: CampaignsMulti;
  showSpinner: boolean;
  isChanged: boolean = false;
  initTime = performance.now();

  constructor(
    private router: Router,
    private analyseMultiService: AnalyseMultiService,
    private multiCampaignsService: MultiCampaignsService,
    private aiService: ApplicationInsightsService,
    private route: ActivatedRoute,
    private errorManagerService: ErrorManagerService,
    private DNATranslate: DNATranslateService,
    private utilService: UtilService
  ) {
    utilService.isAnalyseGraphEdit.emit(true);
  }

  ngOnInit() {
    this.campaignMulti = this.analyseMultiService.getCampaignMulti();
    if(!this.campaignMulti.graphs) {
      this.campaignMulti.graphs = [];
    }
    this.campaignMultiUntouched = _.cloneDeep(this.campaignMulti);
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Multi Campaigns Details Analyse Edit Charts', '', performance.now() - this.initTime, templateUrl);
  }

  goToReport() {
    this.router.navigate(['multi-campaigns', this.campaignMulti.id, 'analyse','reports'])
  }

  onCancel() {
    this.campaignMulti = _.cloneDeep(this.campaignMultiUntouched);
    this.isChanged = false;
  }

  onWkChanged(graphs: Graph[]) {
    this.isChanged = true;
    this.campaignMulti.graphs = graphs;
  }

  canDeactivate() {
    if(this.isChanged) {
      this.errorManagerService.displayMessage('SAVE_MODIFICATION_BEFORE_CONTINUING', "default");
    } else {
      this.utilService.isAnalyseGraphEdit.emit(false);
    }
    return !this.isChanged;
  }

  setTranslationGraph = (graph: Graph): Observable < Graph > => {
    return this.DNATranslate.getTradInAllLanguages(graph.type.name).pipe(
      tap(translations => graph.type.translations = translations),
      map(() => graph));
  }

  save() {
    this.showSpinner = true;
    let graphs: Observable<Graph>[] = [];
    this.campaignMulti.graphs.map(graph => graphs.push(this.setTranslationGraph(graph)));
    observableForkJoin(graphs).pipe(
      defaultIfEmpty([]),
      mergeMap(graphs => this.multiCampaignsService.putCampaignsMultiGraphs(this.campaignMulti.id, graphs, this.campaignMulti.descriptorsGroups)),
      catchError(err => {
        this.showSpinner = false;
        this.errorManagerService.displayMessage('UNKNOW_ERROR', 'danger');
        return throwError(err);
      }),
      tap((result) => {
        if (_.isEmpty(_.get(result, 'qsError', []))) {
          this.errorManagerService.displayMessage("ON_SUCCESS_UPDATE")
          let buffer:any = result;
          this.campaignMulti.graphs = buffer.graphs;
          this.campaignMultiUntouched = _.cloneDeep(this.campaignMulti);
          this.isChanged = false;
        } else {
          const graphesQSError = _.get(result, 'qsError', []).reduce((acc, curr, index) => {
            if (index === 0) {
              return "#" + curr;
            } else {
              return _.get(result, 'qsError', []).length > 1 && _.get(result, 'qsError', []).length === (index + 1)
               ? acc + " et #" + curr
               : acc + ", #" + curr;
            }
          }, "")
          this.DNATranslate.translateMessage("ON_ERROR_QS_SCALE", { graphesQSError: graphesQSError}).subscribe((translated) => {
            this.errorManagerService.displayMessage(translated, 'warning', {timeOut:10000}, false);
          });
        }
      }),
      finalize(() => this.showSpinner = false),
      take(1)
    ).subscribe();
  }

}
