export default {
    "type" : "linechart",
    "configs" : {
        "compute" : {
            "method" : "median",
            "key" : "label"
        },
        "drilldown": {
            "enabled": true
        },
        "title" : {
            "text" : "",
            "margin": 50
        },
        "subtitle" : {
            "text" : ""
        },
        "yAxis" : {
            "max" : 0,
            "tickInterval" : 1
        },
        "plotOptions" : {
            "attributes_blocks": {
                "enabled": false
            },
            "default_language" : {
                "enabled" : false
            }
        }
    },
}