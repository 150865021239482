  <!--
  <ngx-loading [show]="showSpinner">
</ngx-loading>
-->
<h1 class="mb-4" id="banner-title">
  <span translate>FICHE_ENTRETIEN</span> - {{panelist?.firstName}} {{panelist?.lastName}}
</h1>
<nav aria-label="breadcrumb" id="banner-nav">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/dashboard" translate>DASHBOARD</a>
    </li>
    <li class="breadcrumb-item">
      <a routerLink="/volunteers" translate>VOLUNTEERS</a>
    </li>
    <li class="breadcrumb-item" aria-current="page">
      <a (click)='goToGeneralInfo()'>{{panelist?.panelistNumber}}</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page" translate>
      FICHE_ENTRETIEN
    </li>
  </ol>
</nav>
<div class="d-flex flex-row gap-2" id="banner-actions">
  <a *ngIf="isEditable" class="btn btn-primary text-white" (click)="save()">
    <span translate>SAVE</span>
  </a>
  <a class="btn btn-primary text-white" (click)="goToGeneralInfo()">
    <span translate *ngIf="isEditable; else notEditable">CANCEL</span>
    <ng-template #notEditable><span translate>BACK</span></ng-template>
  </a>
</div>
<div class="block ibox float-e-margins" *ngIf="ficheEntretien">
  <div class="ibox-content">
    <div class="row margin-bottom">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-4">
            <label translate>ORCHESTRA_NUMBER</label>
          </div>
          <div class="col-8">
            <input [disabled]="!isEditable" class="form-control" type="text"
              [(ngModel)]="ficheEntretien.orchestraNumber">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-4">
            <label translate>INITIAL_TECH</label>
          </div>
          <div class="col-8">
            <input [disabled]="!isEditable" class="form-control" type="text" [(ngModel)]="ficheEntretien.initialesTech">
          </div>
        </div>
      </div>
    </div>
    <div class="row margin-bottom">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>BRIDGE</label>
            </div>
            <div class="col-8">
                <input [disabled]="!isEditable" class="form-control" type="text" [(ngModel)]="ficheEntretien.requestNumber">
            </div>
          </div>
        </div>
      </div>
    <div class="row margin-bottom">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-4">
            <label translate>APPLICATION_DATE</label>
          </div>
          <div class="col-8">
            <div class="form-group">
              <div class="input-group">
                <input class="col-11 form-control" [disabled]="!isEditable" [ngModel]="dateApplication"
                  (ngModelChange)="onDateChanged($event)" #dateStart="ngModel" ngbDatepicker #d="ngbDatepicker">
                <button class="input-group-addon" [disabled]="!isEditable" (click)="d.toggle()" type="button">
                  <i class="fa fa-calendar"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row margin-bottom">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-4">
              <label translate>HAIRCUT</label>
            </div>
            <div class="col-8">
              <dna-switch [disabled]="!isEditable" class="col-12" [isSelected]="ficheEntretien.haircut" (onBooleanChanged)="ficheEntretien.haircut = !ficheEntretien.haircut"></dna-switch>
              <label class="col-12" translate>{{ ficheEntretien.haircut ? "YES" : "NO" }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
            <label translate>TYPE_INTERVENTION</label>
          </div>
          <div class="col-8">
            <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
              [(ngModel)]="ficheEntretien.typeIntervention" (ngModelChange)="onTypeInterventionChanged($event)">
              <option *ngFor="let typeIntervention of ficheEntretienOptions.typesIntervention"
                [ngValue]="typeIntervention">{{ typeIntervention.name | translate }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row margin-bottom" *ngIf="ficheEntretien.typeIntervention">
      <label translate>PRODUCTS</label>
    </div>
    <div class="d-flex flex-row" style="margin-bottom: 20px">
      <div *ngFor="let product of ficheEntretien.produits" style="padding-left:15px" (click)="onClickProduct(product)">
        <dna-checkbox [isChecked]="isActiveProduct[product.id]"></dna-checkbox>
        <label class="form-check-label" translate>{{product.name}}</label>
      </div>
    </div>
    <div class="row margin-bottom" *ngIf="isProduct">
      <div class="offset-2 col-2" translate>BATCH_NUMBER</div>
      <div class="col-2" translate>FORMULA</div>
      <div class="col-2" translate>PRODUCT_NAME</div>
      <div class="col-2" translate *ngIf="isColoration()">ROUTINE_SHADES</div>
      <div class="col-2" translate *ngIf="isDefrisage()">STRENGTH</div>
    </div>
    <ng-container *ngFor="let product of ficheEntretien.produits">
      <div class="row margin-bottom" *ngIf="isActiveProduct[product.id]">
        <div class="col-2">
          <label translate>{{product.name}}</label>
        </div>
        <div class="col-2">
          <input [disabled]="!isEditable" class="form-control" placeholder="{{'BATCH_NUMBER' | translate}}" type="text"
            [(ngModel)]="product.batchNumber">
        </div>
        <div class="col-2">
          <input [disabled]="!isEditable" class="form-control" placeholder="{{'FORMULA' | translate}}" type="text"
            [(ngModel)]="product.formulaNumber">
        </div>
        <div class="col-2">
          <input *ngIf="isProductName(product)" [disabled]="!isEditable" class="form-control"
            placeholder="{{'PRODUCT_NAME' | translate}}" type="text" [(ngModel)]="product.productName">
        </div>
        <div class="col-2" *ngIf="isColoration()">
          <input *ngIf="isShade(product)" [disabled]="!isEditable" class="form-control"
            placeholder="{{'ROUTINE_SHADES' | translate}}" type="text" [(ngModel)]="product.shade">
        </div>
        <div class="col-2" *ngIf="isStrength(product)">
          <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
            [(ngModel)]="product.strength">
            <option *ngFor="let strength of ficheEntretienOptions.strengths" [ngValue]="strength">
              {{ strength.name | translate }}</option>
          </select>
        </div>
      </div>
    </ng-container>
    <div class="row margin-top margin-bottom ">
      <div class="col-2">
        <label translate>FEEDBACK</label>
      </div>
      <div class="col-10">
        <textarea [disabled]="!isEditable" class="form-control" rows="2"
          [(ngModel)]="ficheEntretien.comments"></textarea>
      </div>
    </div>
  </div>
</div>
