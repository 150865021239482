import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Assessment } from '../../../../types';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class AssessmentsService {

  serverUrlStudio: string;

  constructor(
    private http: HttpClient
  ) {
    this.serverUrlStudio = environment.server_url_studio();
  }

  updateAssessmentBlocks(trainingId: string, assessment: Assessment): Observable<Assessment> {
    return this.http.put<Assessment>(this.serverUrlStudio + '/trainings/' + trainingId + '/assessments/' + assessment.id + '/blocks', assessment.answers);
  }

}
