import {
  of as observableOf,
  Observable,
} from 'rxjs';
import { mergeMap, tap, flatMap } from 'rxjs/operators';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import {
    HttpClient,
    HttpParams
} from '@angular/common/http';
import {
    EventEmitter,
    Injectable
} from '@angular/core';
import {
  environment
} from '../../environments/environment';
import {
  States,
  Workflow,
  WorkflowMetadata,
  Graph,
  Block,
  DNAComponent,
} from '../types';

import * as _ from 'lodash';
import { BlockService } from '../blocks/blocks.service';
import { UserService } from '../shared/services/user.service';
import { UtilService } from '../shared/services/util.service';

@Injectable()
export class WorkflowService implements Resolve<Workflow> {
  metadata: WorkflowMetadata;
  serverUrlStudio: string;
  workflows: Workflow[] = [];
  workflow: Workflow;
  workflowUntouched: Workflow;
  componentToRead: DNAComponent;
  workflowStates: typeof States = States;
  blocks: Block[] = [];
  blocksToDelete: string[] = [];
  blockToEdit: Block;
  peopleKey: string;
  updateComponentEditName: EventEmitter<Workflow> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private userService: UserService,
    private blockService: BlockService,
  ) {
    this.serverUrlStudio = environment.server_url_studio();
    this.peopleKey = _.get(this.userService.getUser(), 'track', '');
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Workflow> {
    const blocksFilter = {
      states: [States.Published],
      orderBy: 'name.english',
      order: 'ASC',
      numberOfObjectsPerPage: 1000,
    };
    return this.getWorkflow(route.paramMap.get('idWorkflow')).pipe(
      tap(wk => {
        this.workflow = wk;
        this.workflowUntouched = _.cloneDeep(wk);
      }),
      flatMap(() => this.blockService.getBlocks(blocksFilter)),
      tap(blocks => this.blocks = blocks.list),
    );
  }

  setComponentToRead(component: DNAComponent) {
    this.componentToRead = component;
  }

  setWorkflowLocal(wk) {
    this.workflow = wk;
  }

  setBlockToEdit(block: Block) {
    this.blockToEdit = block;
  }

  addBlockToDelete(blockId: string) {
    this.blocksToDelete.push(blockId);
  }

  clearBlocksToDelete() {
    this.blocksToDelete = [];
  }

  setWorkflowBlocks(blocks: Block[]) {
    this.workflow.blocks = blocks;
  }

  getWorkflowUntouched() {
    return _.cloneDeep(this.workflowUntouched);
  }

  setWorkflowUntouched(wk) {
    this.workflowUntouched = _.cloneDeep(wk);
  }

  createWorkflow(workflow: Workflow): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${environment.apiLibrariesUrl}/v1/questionnaires/${this.getIdWorkspace()}?peoplekey=${this.peopleKey}`, workflow);
  }

  duplicateWorkflow(idDuplicate: string, workflow: Workflow): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${environment.apiLibrariesUrl}/v1/questionnaires/${idDuplicate}/duplicate/${this.getIdWorkspace()}?peoplekey=${this.peopleKey}`, workflow);
  }

  deleteWorkflow(id: string): Observable<Workflow> {
    return this.http.delete<Workflow>(`${environment.apiLibrariesUrl}/v1/questionnaires/${id}/${this.getIdWorkspace()}`);
  }

  removeWorkflow(id): Observable<any> {
    return this.http.patch<any>(`${environment.apiLibrariesUrl}/v1/questionnaires/${id}/remove/${this.getIdWorkspace()}`, {});
  }

  getWorkflow(id: string): Observable<Workflow> {
    return this.http.get<Workflow>(`${environment.apiLibrariesUrl}/v1/questionnaires/${id}/${this.getIdWorkspace()}`);
  }

  getWorkflows(filters?: any): Observable<any> {
    let idWorkspace = this.getIdWorkspace();
    if (_.isEmpty(idWorkspace)) {
      idWorkspace = "all";
    }
    if (filters) {
      const filterStates = filters.states.map(s => `'${s}'`).join(',');
      const filterHubs = filters.hubs.map(s => `'${s}'`).join(',');
      const page = _.get(filters, 'pageIndex', 1);
      const limit = _.get(filters, 'numberOfObjectsPerPage', 10);
      const orderBy = _.get(filters, 'orderBy', 'updated_on');
      const order = _.get(filters, 'order', 'DESC');

      if (filters.displayFavoritesOnly) {
        return this.http.get<any>(`${environment.apiLibrariesUrl}/v1/questionnaires/${idWorkspace}`);
      }

      return !_.isEmpty(filters.name)
      ? this.http.get<any>(`${environment.apiLibrariesUrl}/v1/questionnaires/${idWorkspace}?page=${page}&limit=${limit}&filters=[state[in]=(${filterStates});hub[in]=(${filterHubs});name[ct]=${filters.name}]&orderby=${orderBy}&order=${order}`)
      : this.http.get<any>(`${environment.apiLibrariesUrl}/v1/questionnaires/${idWorkspace}?page=${page}&limit=${limit}&filters=[state[in]=(${filterStates});hub[in]=(${filterHubs})]&orderby=${orderBy}&order=${order}`);
    } else {
      return this.http.get<any>(`${environment.apiLibrariesUrl}/v1/questionnaires/${idWorkspace}`)
    }
  }


  getWorkflowsForCampaign(): Observable<any> {
    let idWorkspace = this.getIdWorkspace();
    if (_.isEmpty(idWorkspace)) {
      idWorkspace = "all";
    }
    const filterStates = ['PUBLISHED'].map(s => `'${s}'`).join(',');
    return this.http.get<any>(`${environment.apiLibrariesUrl}/v1/questionnaires/${idWorkspace}?filters=[state[in]=(${filterStates});]`);
  }

  updateWorkspaceInWorkflow(workflow: Workflow): Observable<Workflow> {
    return this.http.patch<Workflow>(this.serverUrlStudio + '/workflows/' + workflow.id, {
      workspaces: workflow.workspaces
    });
  }

  suspendWorkflow(id: string): Observable<Workflow> {
    return this.http.patch<Workflow>(`${environment.apiLibrariesUrl}/v1/questionnaires/${id}/suspend/${this.getIdWorkspace()}`, {});
  }

  publishWorkflow(id: string): Observable<Workflow> {
    return this.http.patch<Workflow>(`${environment.apiLibrariesUrl}/v1/questionnaires/${id}/publish/${this.getIdWorkspace()}`, {});
  }

  getIdWorkspace() {
    return _.get(this.userService.getUser(), 'currentWorkspace.id', '');
  }

  updateName(workflow) {
    this.updateComponentEditName.emit(workflow);
  }

  saveWorkflow(workflow: Workflow, blocksToDelete: string[]): Observable<Workflow> {
    return this.http.patch<Workflow>(`${environment.apiLibrariesUrl}/v1/questionnaires/${workflow.id}/save/${this.getIdWorkspace()}?peoplekey=${this.peopleKey}`, { workflow, blocksToDelete });
  }

  getWorkflowComponents(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiLibrariesUrl}/v1/questionnaires/${id}/components/${this.getIdWorkspace()}`);
  }

  updateBlockInWorkflow(id: string, idBlock: string, idRef: string): Observable<Block> {
    return this.http.patch<Block>(`${environment.apiLibrariesUrl}/v1/questionnaires/${id}/blocks/${idBlock}/${idRef}/${this.getIdWorkspace()}?peoplekey=${this.peopleKey}`, {});
  }

  toggleMandatoryBlock(blockIndex: number) {
    this.workflow.blocks = this.workflow.blocks.map(block => {
      if (block.index === blockIndex) {
        return {
          ...block,
          parameters: {
            ...block.parameters,
            mandatory: !block.parameters.mandatory
          }
        };
      }
      return block;
    });
  }

  updateBlockProperty(blockIndex: number, property: string, value) {
    this.workflow.blocks = this.workflow.blocks.map(block => {
      if (block.index === blockIndex) {
        const blockToUpdate = {...block};
        blockToUpdate[property] = value;
        return blockToUpdate;
      }
      return block;
    });
  }

  updateGraphsInWorkflow(graphs: Graph[]) {
    this.workflow.graphs = graphs;
  }
}
