import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DNATranslateService } from '../../../../../shared/services/translate.service';
import { ApplicationInsightsService } from '../../../../../shared/services/applicationInsights.service';

@Component({
    selector: 'dna-errors-import-excel-modal',
    templateUrl: './errors-import-excel-modal.component.html',
    styleUrls: ['./errors-import-excel-modal.component.less']
})

export class ErrorsImportExcelModalComponent implements OnInit, AfterViewInit {

    @Input() title: string;
    @Input() errorsTab: any[];

    currentLanguage: string;
  initTime = performance.now();

    constructor(
        private activeModal: NgbActiveModal,
        private aiService: ApplicationInsightsService,
        private DNATranslate: DNATranslateService
    ) { }

    ngOnInit() {
        this.currentLanguage = this.DNATranslate.getLanguage();
     }

    ngAfterViewInit() {
      this.aiService.logPageView('Errors Import Excel Modal', '', performance.now() - this.initTime, 'Modals');
    }

    cancel() {
        this.activeModal.dismiss();
    }

    validate() {
        this.activeModal.close();
    }

}
