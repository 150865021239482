<form #dnaInputTextForm="ngForm" class='form-horizontal d-flex flex-column gap-3'>
  <div class="form-group">
    <div class="col-sm-2">
      <label class="control-label" translate>LABEL</label>
      <span class="fa fa-info" placement="bottom" ngbTooltip="{{'tooltip_label' | translate}}" container="body"></span>
    </div>
    <div class="col-sm-10">
      <input name="label" type='text' class='form-control' [(ngModel)]="component.args.label.english" [disabled]="inactive">
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-2">
      <label class="control-label" translate>TAG</label>
      <span class="fa fa-info" placement="bottom" ngbTooltip="{{'tooltip_tag' | translate}}" container="body"></span>
    </div>
    <div class="col-sm-10">
      <input name="tag" type='text' class='form-control' [(ngModel)]="component.args.tag" [disabled]="inactive">
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-2">
      <label for="size" class="control-label" translate>PLACEHOLDER</label>
      <span class="fa fa-info" placement="bottom" ngbTooltip="{{'tooltip_placeholder' | translate}}" container="body"></span>
    </div>
    <div class="col-sm-10">
      <input type='text' class='form-control' name="placeholder" [(ngModel)]="component.args.placeholder.english" [disabled]="inactive">
    </div>
  </div>

  <div class="form-group row">
    <label class="control-label col-sm-3" translate>LEFT_RIGHT_MODE</label>
    <div class="col-sm-1">
      <button class="noButton" [disabled]="inactive" (click)="component.args.leftRightMode = !component.args.leftRightMode">
        <i class="fa icon-resize" [ngClass]="{'fa-square-o': !component.args.leftRightMode, 'fa-check-square-o': component.args.leftRightMode}"></i>
      </button>
    </div>
    <p class="col-sm-8" translate>LEFT_RIGHT_MODE_DESC</p>
  </div>
</form>
