
import {debounceTime, tap, takeUntil, mergeMap} from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { DNATranslateService } from '../../../services/translate.service';
import {
  Filter,
  KeyValue,
  Method,
  TypeApplicationArea,
  TypeApplicationMode,
  TypeMethod,
  TypeMetier,
  ProductsType,
  TypeTest,
  StudyType,
  StudyTypes
} from '../../../../types';
import { ReferenceTypeService } from '../../../services/reference-type.service';
import { UtilService } from '../../../services/util.service';

@Component({
  selector: 'dna-filters-protocol-skin',
  templateUrl: './filters-protocol-skin.component.html',
  styleUrls: ['./filters-protocol-skin.component.less']
})
export class FiltersProtocolSkinComponent implements OnInit, OnChanges, OnDestroy {

  @Input() filter: any;
  @Input() metierName: TypeMetier;

  @Output() onUpdateFilter: EventEmitter<any> = new EventEmitter();

  applicationAreas: TypeApplicationArea[];
  applicationModes: TypeApplicationMode[];
  protocol_methods: TypeMethod[];
  methods: Method[];
  studies: ProductsType[];
  studyTypes: StudyType[];
  tests: TypeTest[];

  destroy$: Subject<boolean> = new Subject<boolean>();
  onSearchChange$: Subject<Filter> = new Subject();

  constructor(
    private DNATranslate: DNATranslateService,
    private referenceTypeService: ReferenceTypeService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.getProtocolObjectsAndSetFilter().pipe(
      takeUntil(this.destroy$))
      .subscribe();

    this.DNATranslate.onLangChange().pipe(
      mergeMap(() => this.getProtocolObjectsAndSetFilter()),
      takeUntil(this.destroy$),)
      .subscribe();

    this.onSearchChange$.pipe(
      debounceTime(500),
      tap(filter => this.updateCampaignsList(filter)),
      takeUntil(this.destroy$),)
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterProtocol && changes.filterProtocol.currentValue) {
      this.filter = changes.filterProtocol.currentValue;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private getProtocolObjectsAndSetFilter() {
    return this.referenceTypeService.getApplicationAreas(this.metierName).pipe(
      tap((applicationAreas: TypeApplicationArea[]) => this.applicationAreas = this.utilService.sortArrayOfKeyValueObjects(applicationAreas)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.filter.applicationAreas)),
      tap((translatedApplicationAreasFilter: KeyValue[]) => this.filter.applicationAreas = translatedApplicationAreasFilter),
      mergeMap(() => this.referenceTypeService.getApplicationModes(this.metierName)),
      tap((applicationModes: TypeApplicationMode[]) => this.applicationModes = this.utilService.sortArrayOfKeyValueObjects(applicationModes)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.filter.applicationModes)),
      tap((translatedApplicatorsFilter: KeyValue[]) => this.filter.applicationModes = translatedApplicatorsFilter),
      mergeMap(() => this.referenceTypeService.getProtocolMethods(this.metierName)),
      tap((protocol_methods: TypeMethod[]) => this.protocol_methods = this.utilService.sortArrayOfKeyValueObjects(protocol_methods)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.filter.protocol_methods)),
      mergeMap(() => this.referenceTypeService.getMethods()),
      tap((methods: Method[]) => this.methods = this.utilService.sortArrayOfKeyValueObjects(methods)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.filter.methods)),
      tap((translatedMethodsFilter: KeyValue[]) => this.filter.methods = translatedMethodsFilter),
      mergeMap(() => this.referenceTypeService.getStudies(this.metierName)),
      tap((studies: ProductsType[]) => this.studies = this.utilService.sortArrayOfKeyValueObjects(studies)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.filter.studies)),
      tap((translatedStudiesFilter: KeyValue[]) => this.filter.studies = translatedStudiesFilter),
      mergeMap(() => this.referenceTypeService.getStudyTypes()),
      tap((studyTypes: StudyType[]) => this.studyTypes = this.utilService.sortArrayOfKeyValueObjects(studyTypes)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.filter.studyTypes)),
      tap((translatedStudyTypesFilter: KeyValue[]) => this.filter.studyTypes = translatedStudyTypesFilter),
      mergeMap(() => this.referenceTypeService.getTests(this.metierName)),
      tap((tests: TypeTest[]) => this.tests = this.utilService.sortArrayOfKeyValueObjects(tests)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.filter.tests)),
      tap((translatedTestsFilter: KeyValue[]) => this.filter.tests = translatedTestsFilter),);
  }

  private translateAndPatchProtocolFormObject(protocolFormObject: KeyValue[]): Observable<KeyValue[]> {
    return this.referenceTypeService.keyValueTranslations(protocolFormObject);
  }

  updateCampaignsList(filter: any) {
    this.onUpdateFilter.emit(filter);
  }

  updateSearchData(text: any, type: string, filter: any, language: string = "english") {
    filter[type] = text;
    this.onSearchChange$.next(filter);
  }

}
