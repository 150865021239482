import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  formatDate(dateString: string): string {
    // Define a mapping for month names to month numbers
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Split the input string by one or more spaces
    const [month, day, year] = dateString.trim().split(/\s+/);

    // Convert the month name to a month number
    const monthIndex = monthNames.indexOf(month);
    if (monthIndex === -1) {
      throw new Error('Invalid month in date string');
    }

    // Create a new Date object (we don't care about time here)
    const date = new Date(Number(year), monthIndex, Number(day));

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date format');
    }

    // Get the day, month, and year from the date object
    const formattedDay = String(date.getDate()).padStart(2, '0');
    const formattedMonth = String(date.getMonth() + 1).padStart(2, '0');
    const formattedYear = date.getFullYear();

    // Format the date as dd/MM/yyyy
    return `${formattedDay}/${formattedMonth}/${formattedYear}`;
  }
}
