<div *ngIf="descriptorsInGraph.length > 0" class="table-responsive">
    <table class="table table-hover table-borderless-th dataTable">
        <thead>
            <tr>
                <th></th>
                <th translate>DESCRIPTOR</th>
                <th translate>BLOCK</th>
                <th translate>SCALE</th>
                <th></th>
            </tr>
        </thead>
        <tbody [dragula]="keyDragula" [(dragulaModel)]="descriptorsInGraph">
            <tr *ngFor="let descriptorGroup of descriptorsInGraph; let indexDesc = index">
                <td class="reorder cursor-pointer align-middle">
                    <i class="fa fa-arrows-v"></i>
                </td>
                <td class="align-middle">
                    <dna-translate [fieldToTranslate]="descriptorGroup.name"> </dna-translate>
                </td>
                <td class="align-middle">
                    <ng-container *ngIf="!descriptorGroup.isGroup">
                        <dna-translate [fieldToTranslate]="descriptorGroup.descriptors[0].blockName"> </dna-translate>
                    </ng-container>
                </td>
                <td>
                    {{ !descriptorGroup.isGroup ? descriptorGroup.descriptors[0].scaleDetail : "" }}
                </td>
                <td class="text-center">
                    <button type="button" class="btn btn-danger btn-sm" (click)="removeDescriptor(indexDesc)">
                        <i class="fa fa-times"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>