<ngx-loading [show]="showSpinner"></ngx-loading>
<ng-container *ngIf="!showSpinner">
  <h1 class="mb-4" translate id="banner-title">
    <span translate>WORKFLOW</span> -
    <dna-translate [fieldToTranslate]="workflow.name"> </dna-translate>
  </h1>
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" translate>DASHBOARD</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/workflows" translate>LIBRARY_OF_WORKFLOWS</a>
      </li>
      <li class="breadcrumb-item active">
        <dna-translate [fieldToTranslate]="workflow.name"> </dna-translate>
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>EDIT</li>
    </ol>
  </nav>
  <div class="d-flex flex-row gap-1 justify-content-end" id="banner-actions">
    <button *ngIf="( isWkChanged && workflow.state !== workflowStates.Published ) || isDatavizChange"
      class="btn btn-primary mb-4" id="SAVE" (click)="saveWorkflow()">
      <span translate>SAVE</span>
    </button>
    <button *ngIf="( isWkChanged && workflow.state !== workflowStates.Published ) || isDatavizChange"
      class="btn btn-primary mb-4" id="CANCEL" (click)="cancelEditWF()">
      <span translate>CANCEL</span>
    </button>
    <button *ngIf="( !isWkChanged && workflow.state !== workflowStates.Published ) && !isDatavizChange"
      class="btn btn-primary mb-4" id="PUBLISH" (click)="publishWF()">
      <span translate>PUBLISH</span>
    </button>
    <button *ngIf="( workflow.state === workflowStates.Published ) && !isDatavizChange" class="btn btn-primary mb-4"
      id="SUSPEND" (click)="suspendWF()">
      <span translate>SUSPEND</span>
    </button>
  </div>
  <div class="block">
    <div *ngIf="workflow.state == workflowStates.Published" style="text-align:center; color:red;" translate>
      WORKFLOW_IS_PUBLISHED </div>
    <div class="ibox float-e-margins">
      <div class="row ibox-title noMarginSide">
        <h5 class="col-md-6">
          <span translate>WORKFLOW </span>
          <span>
            <dna-translate [fieldToTranslate]="workflow.name"> </dna-translate>
          </span>
          <span *ngIf="!workflow.name[currentLanguage] && workflow.name.english">
            <i>(english version)</i>
          </span>
        </h5>
        <div class="ibox-tools col-md-6">
          <a aria-controls="workflowName" (click)="isCollapsedWorkflowName = !isCollapsedWorkflowName">
            <i *ngIf="isCollapsedWorkflowName; else isNotCollapsedWorkflowName" class="fa fa-pencil"></i>
            <ng-template #isNotCollapsedWorkflowName><i class="fa fa-times"></i></ng-template>
          </a>
        </div>
      </div>
      <div id="workflowName" [ngbCollapse]="isCollapsedWorkflowName" class="ibox-content">
        <dna-edit-name-and-hub [data]="workflow" [languages]="languages" (onChanges)="onChange($event)"></dna-edit-name-and-hub>
      </div>
    </div>
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5 translate>WORKFLOW</h5>
        <div class="ibox-tools d-flex flex-row gap-1 justify-content-end">
          <button class="btn btn-xs btn-primary" (click)="editGraph()"
            translate>GRAPH</button>
          <button class="btn btn-xs btn-primary" (click)="preview()" translate>PREVIEWING</button>
          <button *ngIf="workflow.state !== workflowStates.Published && workflow.blocks.length > 0"
            class="btn btn-xs btn-primary" (click)="editBlocks()" translate>EDIT</button>
          <button *ngIf="workflow.state !== workflowStates.Published" class="btn btn-xs btn-primary"
            (click)="selectBlock()" translate>ADD_BLOCKS</button>
        </div>
      </div>
      <div id="ibox-Workflow" class="ibox-content">
        <div class="table-responsive">
          <table class="table table-hover table-borderless-th">
            <thead>
              <tr>
                <th></th>
                <th>#</th>
                <th translate>NAME</th>
                <th translate>DESCRIPTION</th>
                <th translate>INFORMATION</th>
                <th translate>ACTIONS</th>
              </tr>
            </thead>
            <tbody id="target" [dragula]='idDragula' [dragulaModel]='workflow.blocks' (dragulaModelChange)="changeIndexBlocks($event)">
              <tr class="cursor-pointer" *ngFor="let block of workflow.blocks; let indexBlock = index">
                <td>
                  <i class="fa fa-sort"></i>
                </td>
                <td>{{indexBlock + 1}}</td>
                <td (click)="editBlockConfiguration(block)">
                  <span>{{ block.name[currentLanguage] || block.name.english || '-' }}</span>
                  <span *ngIf="!block.name[currentLanguage] && block.name.english">
                    <i>(english version)</i>
                  </span>
                </td>
                <td (click)="editBlockConfiguration(block)">
                  <span>{{ block.description[currentLanguage] || block.description.english || '-' }}</span>
                  <span *ngIf="!block.description[currentLanguage] && block.description.english">
                    <i>(english version)</i>
                  </span>
                </td>
                <td (click)="editBlockConfiguration(block)">
                    <div class="d-flex flex-column">
                      <small *ngIf="block.outOfDate" class="text-warning" translate>
                        <i class="fa fa-exclamation-triangle"></i>BLOCK_UPD</small>
                  <small *ngIf="block.parameters.mandatory" class="text-info" translate>BLOCK_MANDATORY</small>
                      <small *ngIf="!block.id" class="text-warning" translate>UNSAVED_BLOCK</small>
                    </div>
                </td>
                <td>
                    <div class="d-flex flex-row gap-1 align-items-center">
                      <button
                        *ngIf="block.outOfDate && workflow.state !== 'PUBLISHED'"
                        class="btn btn-xs btn-default" (click)="updateBlock(block, indexBlock)"
                        [ngbTooltip]="'UPDATE' | translate">
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                      </button>
                      <a target="_blank" [href]="mobileUrl + '/blockPreview/' + block.id + '/' + user.currentWorkspace.id ">
                        <button [disabled]="!block.id" class="btn btn-xs btn-default" [ngbTooltip]="(block.id ? 'PREVIEWING' : 'UNSAVED_BLOCK_TOOLTIP') | translate">
                          <i class="fa fa-eye" aria-hidden="true"></i>
                        </button>
                      </a>
                      <button *ngIf="workflow.state !== workflowStates.Published && indexBlock < workflow.blocks.length - 1"
                        class="btn btn-xs btn-default" [ngbTooltip]="'LOGIC_JUMP' | translate"
                        (click)="openLogicJumpModal(block)">
                        <i class="fa fa-code-fork" aria-hidden="true"></i>
                      </button>
                      <button *ngIf="workflow.state !== workflowStates.Published" class="btn btn-xs btn-danger"
                        (click)="removeFromList(block)" [ngbTooltip]="'DELETE' | translate">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                      <button class="btn btn-xs btn-default" (click)="addOrRemoveMandatory(block)"
                    [ngbTooltip]="(block.parameters.mandatory) ? ('MANDATORY' | translate) : ('NON_MANDATORY' | translate)">
                      <i class="fa fa-asterisk" aria-hidden="true"
                    [ngClass]="{'asterisk-mandatory' : block.parameters.mandatory}"></i>
                    </button>
                    </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>
