import { DNATranslateService } from './../services/translate.service';
import {
  Pager,
  TableData,
  TableHeader,
  Buttons
} from '../../types';
import {
  UtilService
} from '../services/util.service';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

import * as _ from 'lodash';

import {
  PagerService
} from '../../shared/services/pager.service';

@Component({
  selector: 'dna-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.less']
})
export class TableComponent implements OnInit, OnChanges {
  @Input() headers: TableHeader[] = [];

  @Input() buttons: Buttons[] = [];

  @Input() title: string = "";

  @Input() data: any[] = [];

  @Input() pageIndex: number;

  @Input() pagination: boolean = true;

  @Input() numberOfObjectsPerPage: number = 10;

  @Input() sortFunction: (data: any[], headerId: string, reverse: boolean) => any[];

  @ContentChild(TemplateRef) templateVariable: TemplateRef < any > ;

  @Input() orderedBy: string;

  @Input() reverse: boolean;

  @Output() onSort: EventEmitter < {
    id: string,
    reverse: boolean
  } > = new EventEmitter();

  @Output() parametersChanged: EventEmitter < any > = new EventEmitter();

  @Output() rowClicked: EventEmitter < string > = new EventEmitter();

  @Output() buttonAction: EventEmitter < string > = new EventEmitter();

  pagedData: any[] = [];

  pager: Pager = new Pager();

  constructor(public utilService: UtilService, public translateService: DNATranslateService) {}

  ngOnInit() {
    this.updatePager();
  }

  onRowClicked(id: string) {
    this.rowClicked.emit(id);
  }

  onButtonAction(buttonId: string) {
    this.buttonAction.emit(buttonId);
  }

  onSortByChanged(header: TableHeader) {
    this.reverse = this.orderedBy !== header.id ? false : !this.reverse;
    if (header.sortable) {
      this.onSort.emit({
        id: header.id,
        reverse: this.reverse
      })

      this.orderedBy = header.id;
      this.sortFunction(this.data, this.orderedBy, this.reverse);
      this.setPage(this.pager.currentPage);
    }
  }


  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.data) {
      this.data = [];
    }
    if (!this.headers) {
      this.headers = [];
    }
    if (changes.data && changes.data.currentValue) {
      this.sortFunction(this.data, this.orderedBy, this.reverse);
      this.updatePager();
      let page = (this.pageIndex > 0 && this.pageIndex <= this.pager.totalPage) ? this.pageIndex : 1;
      this.pager.currentPage = page;
      this.pager.pageSize = this.numberOfObjectsPerPage;
      this.updatePager();
    }
  }

  updatePager() {
    this.pager.totalItems = this.data.length;
    if(!this.pagination) {
      this.pager.pageSize = this.data.length;
    }
    this.pager.totalPage = Math.ceil(this.pager.totalItems / this.pager.pageSize);
    if (this.pager.totalPage < 5 || this.pager.currentPage < 4) {
      this.pager.pages = _.range(1, Math.min(6, this.pager.totalPage + 1));
    } else {
      this.pager.pages = _.range(this.pager.currentPage - 2, Math.min(this.pager.currentPage + 3, this.pager.totalPage + 1));
    }
    this.pager.startIndex = Math.min((this.pager.currentPage - 1) * this.pager.pageSize + 1, this.data.length);
    this.pager.endIndex = Math.min((this.pager.currentPage) * this.pager.pageSize, this.pager.totalItems);
    this.pagedData = this.data.slice(this.pager.startIndex - 1, this.pager.endIndex);
  }

  setPage(page: number) {
    if (page > 0 && page <= this.pager.totalPage) {
      this.pager.currentPage = page;
      this.updatePager();
      this.parametersChanged.emit({pageIndex: page, numberOfObjectsPerPage: this.pager.pageSize});
    }
  }
}