import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ActionBarButton, DNAObject } from '../../types';

@Component({
    selector: 'dna-action-bar',
    templateUrl: './dnaActionBar.component.html',
    styleUrls: ['./dnaActionBar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ActionBarComponent implements OnInit {
    @Input() actionsButtons : ActionBarButton[]

    @Output() onClick: EventEmitter<ActionBarButton> = new EventEmitter();

    constructor() { }

    ngOnInit() { }

    onClickButton(actionsButton: ActionBarButton) {
        this.onClick.emit(actionsButton);
    }
}
