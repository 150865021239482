
import {of as observableOf,
  Observable,
  of
} from 'rxjs';

import {catchError, map, mergeMap} from 'rxjs/operators';
import {
  HttpClient
} from '@angular/common/http';
import {
  Injectable
} from '@angular/core';

import * as moment from 'moment';

import {
  Answer,
  Campaign,
  Evaluation,
  Routine2,
  Visit,
  Order,
  RoutineStep,
  Workflow,
  Result
} from '../../../../types';
import {
  HttpRestService
} from '../../../../shared/services/httpRest.service';
import { environment } from '../../../../../environments/environment';


@Injectable()
export class EvaluationService {

  serverUrlStudio: string;

  constructor(
    private http: HttpClient,
    private httpRestService: HttpRestService
  ) {
    this.serverUrlStudio = environment.server_url_studio();
  }

  /********** REQUESTS **********/

  getEvaluationsPerCampaign(id: string = ""): Observable<Evaluation[]> {
    // let query = "";
    // if (id) {
    //   query = "?idCampaign=" + id;
    // }
    return this.http.get<any>(`${environment.apiStudiesUrl}/v1/campaigns/${id}/evaluations`).pipe(
      catchError(err => {
        return of({});
      }),
      map(result => result.list ? result.list : [])
    );
  }

  updateEvaluationBlocks(idCampaign: string, evaluation: Evaluation): Observable<Evaluation> {
    return this.http.put<Evaluation>(this.serverUrlStudio + '/campaigns/' + idCampaign + '/evaluations/' + evaluation.id + '/blocks', evaluation.answers);
  }

  /********** FUNCTIONS **********/

  getImage(answer: Answer, subFolder, index: number, evaluation: Evaluation, campaignName: string, workflows: any[]): Observable<any> {
    return this.httpRestService.downloadLargeDataRecord(answer.records[0].data[0].value, evaluation.id).pipe(mergeMap((largedata: {media: string, type: string, data: string}) => {
      const photo = largedata.data;
      const dateImage = moment(answer.records[0].timestamp).format("YYYYMMDD_HHmmss");
      const component = workflows.find(wk => wk.id === evaluation.questionnaireId).blocks.find(b => b.idInQuestionnaire === answer.idInQuestionnaire).components.find(c => c.idInBlock === answer.idInBlock);
      const tagName = component && component.args.tag === "" ? "noTags" : component.args.tag;
      const extension = largedata.type || 'jpg';

      subFolder.file('photo_' + dateImage + "_" + campaignName + "_" + evaluation.formula.name + "_" + tagName + "_" + index + "." + extension, photo, {
        base64: true
      });
      return observableOf(undefined);
    }));
  }

  getVideo(campaignName: string, workflows: any, evaluation, answer, subFolder, index): Observable<any> {
    return this.httpRestService.downloadLargeDataRecord(answer.records[0].data[0].value, evaluation.id).pipe(mergeMap((largedata: any) => {
      let videoBase64;
      let videoExtension;
      if (answer.records[0].data[0].type) {
        videoBase64 = largedata;
        videoExtension = '.' + answer.records[0].data[0].type;
      } else {
        // Base 64 binary video
        videoBase64 = largedata.binary.split(",")[1];
        // Extension video
        let arrayExtension = largedata.name.split(".");
        videoExtension = "." + largedata.name.split(".")[arrayExtension.length - 1];
      }

      const dateVideo = moment(answer.records[0].data[0].timestamp).format("YYYYMMDD_HHmmss");
      const component = workflows.find(wk => wk.id === evaluation.questionnaireId).blocks.find(b => b.idInQuestionnaire === answer.idInQuestionnaire).components.find(c => c.idInBlock === answer.idInBlock);
      const tagName = component && component.args.tag == "" ? "noTags" : component.args.tag;
      subFolder.file('video_' + dateVideo + "_" + campaignName + "_" + evaluation.formula.name + "_" + tagName + "_" + index + videoExtension, videoBase64, {
        base64: true
      });
      return observableOf(undefined);
    }));
  }
}
