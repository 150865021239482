import { Chart } from "../../chart.model";

const doWork = (data: Array<any>, baseKey : string, lang: string, payload: any, routinesObject: any): any => {
    let routines = routinesObject && routinesObject.hasOwnProperty('routines') && routinesObject.routines && routinesObject.routines.length ? routinesObject.routines.sort((a, b) => b.isBench - a.isBench) : [];
    return data.reduce((accumulator: any, item: any, index: number) => {

         // CHEAT
         if ( !routines.length ) {
            item.values.reduce(( acc : any , object : any, index : number ) => {
                acc[ Chart.getObjectValueTranslation(object.attribute.label, lang) ] =  {
                    name: Chart.getObjectValueTranslation(object.attribute.label, lang),
                    isBench: index === 0,
                    color: Chart.colorScale[index]
                }
                return acc;
            }, {});
        }
        routines = Object.values(routines);
        //

        const keyLabel = [Chart.getObjectValueTranslation(item.attribute.blockName, lang), Chart.getObjectValueTranslation(item.attribute.label, lang)].join('_');
        accumulator.categories[keyLabel] = item.attribute;
        let indexData = 0;
        const aggregatedValues = item.values.reduce((reducer: any, object: any) => {
            object.values.filter(x => x.value.hasOwnProperty(baseKey)).reduce((acc: any, drilledObject: any) => {
                if(drilledObject && drilledObject.value && !isNaN(parseFloat(drilledObject.value[baseKey]))) {
                    acc[indexData] = {};
                    acc[indexData][baseKey] = routines.find((routine: any) => routine.name === object.attribute.label).isBench && Number(drilledObject.value[baseKey]) > 0 ? Number(-1 * Number(drilledObject.value[baseKey])) : Number(drilledObject.value[baseKey])
                    indexData++;
                }
                return acc;
            }, reducer);
            return reducer;
        }, {});

        const aggregatedMedian = median(Object.values(aggregatedValues), baseKey);
        routines.reduce((reducer: any, routine: any ) => {
            let { name, isBench, color, ...rest } = routine;
            reducer[name] = (reducer[name] || { name, isBench, color, data: [] });
            if( aggregatedMedian > 0 && !isBench ) {
                (reducer[name].data = (reducer[name].data || [])).push({
                    y: aggregatedMedian,
                    x: Object.keys(accumulator.categories).indexOf(keyLabel),
                    details: Object.values(aggregatedValues)
                });
            }
            else if ( aggregatedMedian <= 0 && isBench) {
                (reducer[name].data = (reducer[name].data || [])).push({
                    y: aggregatedMedian,
                    x: Object.keys(accumulator.categories).indexOf(keyLabel),
                    details: Object.values(aggregatedValues)
                });
            }
            return reducer;
        }, accumulator.series);
        return accumulator;
    }, payload);
};

const median = (array: Array<any>, key: string) => {
    array = array.filter(x => x.hasOwnProperty(key) && x[key] !== null);
    if (!array.length) return null;
    const mid = Math.floor(array.length / 2),
        nums = [...array].sort((a, b) => a[key] - b[key]);
    return array.length % 2 !== 0 ? nums[mid][key] : (nums[mid - 1][key] + nums[mid][key]) / 2;
};

export {
    doWork
};