import { UtilService } from './../services/util.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { Menu } from '../../types';

@Component({
    selector: 'dna-side-bar-menu',
    templateUrl: './sideBarMenu.component.html',
    styleUrls: ['./sideBarMenu.component.less']
})

export class SideBarMenuComponent implements OnInit {
    @Input() menus: Menu[];
    @Output() updateSpinner = new EventEmitter();
    isOnOnePagerImage: boolean = false;
    isAnalyseGraphEdit: boolean = false;
    @Output() onePagerClicked: EventEmitter<any> = new EventEmitter();

    constructor(private utilService: UtilService) { }

    ngOnInit() {
        this.utilService.isOnOnePagerImage.subscribe(isOnOnePagerImage => this.isOnOnePagerImage = isOnOnePagerImage);
        this.utilService.isAnalyseGraphEdit.subscribe(isAnalyseGraphEdit => this.isAnalyseGraphEdit = isAnalyseGraphEdit);
    }

    onClick(menu) {
        if (menu.label === "Rapport") {
            this.updateSpinner.emit();
        }
        
        if(menu.link[menu.link.length-1] === "onepager") {
            this.onePagerClicked.emit(true);
        }
    }

}