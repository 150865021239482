<ngx-loading [show]="showSpinner"></ngx-loading>
<form [formGroup]="importForm" (ngSubmit)="onSubmit(importForm)">
  <div class="form-group">
    <tag-input formControlName="workspaces" displayBy="name" identifyBy="id" [onlyFromAutocomplete]="true">
      <tag-input-dropdown [appendToBody]="false" [showDropdownIfEmpty]="true" displayBy="name" identifyBy="id" [autocompleteItems]="workspaces">
      </tag-input-dropdown>
    </tag-input>
    <div *ngIf="importForm.controls.workspaces.errors?.required && importForm.controls.workspaces.touched" class="alert alert-danger"
      translate>
      REQUIRED
    </div>
  </div>
  <div class="form-group">
    <label for="import">{{ 'IMPORT_PASTE' | translate: {'object': 'BLOCK' | translate | lowercase} }}:
      <textarea id="import" class="form-control" formControlName="blockToImport"></textarea>
    </label>
  </div>
  <div class="form-group">
    <button class="btn btn-primary" type="submit" [disabled]="importForm.pristine || importForm.invalid" translate>IMPORT</button>
    <button class="btn btn-default" type="reset" translate>CANCEL</button>
  </div>
</form>
