import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { Feedback } from "../../../../types";

@Component({
    selector: '[dna-feedback-row]',
    templateUrl: './feedback-row.component.html',
    styleUrls: ['./feedback-row.component.less']
})

export class FeedbacksRowComponent implements OnInit {

    @Input() feedback: Feedback;
    @Output() removeFeedback: EventEmitter < Feedback > = new EventEmitter();

    ngOnInit(): void {}

    remove(feedback: Feedback) {
        this.removeFeedback.emit(feedback);
    }

}
