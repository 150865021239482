export default {
    "type": "barchart",
    "configs": {
        "chart": {
            "backgroundColor": 'rgba(0,0,0,0)'
        },
        "compute": {
            "method": "median",
            "key" : "key"
        },
        "drilldown": {
            "enabled": true
        },
        "title": {
            "text": "",
            "margin": 50
        },
        "subtitle": {
            "text": ""
        },
        "yAxis": {
            "min": -6,
            "max": 6,
            "plotBands": [
                {
                    "color": "rgba(239,236,236,0.5)",
                    "from": -0.5,
                    "to": 0.5,
                },
            ],
            "plotLines": [
                {
                    "zIndex": 4,
                    "color": '#787777',
                    "dashStyle": 'shortdash',
                    "width": 1,
                    "value": 0.5,
                },
                {
                    "zIndex": 4,
                    "color": '#787777',
                    "dashStyle": 'shortdash',
                    "width": 1,
                    "value": -0.5,
                }
            ]
        },
        "plotOptions": {
            "grey_zone": {
                "enabled": true
            },
            "attributes_blocks": {
                "enabled": true
            },
            "default_language" : {
                "enabled" : false
            }
        }
    }
}