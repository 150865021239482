
import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Scalebar } from '../../models';
import { Languages } from '../../enums';
import { DNATranslateService } from '../../../../../../../../shared/services/translate.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'dna-chart-scalebar',
  templateUrl: './chart-scalebar.component.html',
  styleUrls: ['./chart-scalebar.component.scss'],
  providers: [DNATranslateService]
})
export class ChartScalebarComponent implements OnInit, OnDestroy {
  @ViewChild('scalebar', { read: ElementRef }) scalebar: ElementRef;

  @Input() chart: Scalebar;

  public transformedData : Array<any>;

  public Languages = Languages;
  public currentLanguage: string;
  public displayLanguages: Array<string> = [];
  public parameters : any;
  private unsubsriber$ = new Subject<void>();
  public min: number = 0;


  constructor(
    private translateService: DNATranslateService) {
    this.currentLanguage = this.translateService.getLanguage();

    this.displayLanguages = [this.currentLanguage];
    this.translateService.onLangChange().pipe(
    takeUntil(this.unsubsriber$))
    .subscribe(({ lang }: any) => {
      delete this.displayLanguages;
      this.displayLanguages = [lang];
      if (this.chart.parameters.hasOwnProperty('plotOptions') && this.chart.parameters.plotOptions.hasOwnProperty('default_language')) {
        if (this.chart.parameters.plotOptions.default_language.enabled && this.displayLanguages.indexOf(Languages.Default) === -1) this.displayLanguages.push(Languages.Default);
      }
    });
  }

  ngOnInit() {
    const { series, categories } = this.chart.parameters;
    this.transformedData = this.chart.createTable(series, categories);
    this.parameters = Object.values(this.chart.descriptors.reduce(( accumulator : object, descriptor : any ) => {
      accumulator[descriptor.key] = descriptor;
      return accumulator;
    }, {}));
    this.setMinIndex(this.parameters);
  };

  private setMinIndex(parameters: any[]) {
    let min = Infinity;

    parameters.forEach(p => {
      if (min > p.key) min = p.key;
    });

    this.min = min;
  }

  ngOnDestroy() {
    this.unsubsriber$.next();
    this.unsubsriber$.complete();
  };

  ngAfterViewInit() {
    this.chart.setContainer(this.scalebar.nativeElement);
    this.chart.onOptionChangeSubject.pipe(
    takeUntil(this.unsubsriber$))
    .subscribe({
      next: (options: any) => {
        delete this.displayLanguages;
        this.displayLanguages = [this.currentLanguage];
        if (this.chart.parameters.hasOwnProperty('plotOptions') && this.chart.parameters.plotOptions.hasOwnProperty('default_language')) {
          if (this.chart.parameters.plotOptions.default_language.enabled && this.displayLanguages.indexOf(Languages.Default) === -1) this.displayLanguages.push(Languages.Default);
        }
      }
    })
  };

};
