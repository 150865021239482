import { Chart } from "../../chart.model";

const doWork = (data: Array<any>, baseKey : string, lang: string, parameters: any, descriptors: any, payload: any, routines: Array<any>): any => {
    return data.reduce((accumulator: any, item: any, index: number) => {
        if (item.hasOwnProperty('values') && Array.isArray(item.values)) {
            const keyLabel = [Chart.getObjectValueTranslation(item.attribute.blockName, lang), Chart.getObjectValueTranslation(item.attribute.label, lang)].join('_')
            accumulator.categories[keyLabel] = item.attribute;
            item.values.reduce((reducer: any, object: any) => {
                parameters.yAxis.plotBands
                    .sort((a: any, b: any) => { return a['index'] - b['index'] })
                    .reduce((acc: any, side: any) => {
                        const keySerie = [Chart.getObjectValueTranslation(object.attribute.label, lang), side.key].join('_');
                        acc.series[keySerie] = (acc.series[keySerie] || {
                            name: side.translationKey,
                            data: [],
                            translationKey: side.translationKey,
                            baseLabel: object.attribute.label,
                            stack: object.attribute.label
                        });
                        const pl = Chart.castValuesToNumber(object.values, baseKey)
                            .filter((value: any) => eval([value[baseKey], side.rule].join('')));

                        (acc.series[keySerie].data = (reducer.series[keySerie].data || [])).push({
                            y: median(pl, baseKey),
                            x: Object.keys(acc.categories).indexOf(keyLabel),
                            details: object.values.filter((value: any) => eval([value.value[baseKey], side.rule].join('')))
                        });
                        return acc;
                    }, reducer);
                return reducer;
            }, accumulator)
        }
        return accumulator;
    }, payload);
};

const doTableWork = (categories: any, series: any, baseKey : string, lang: string, payload: any) => {
    payload.header = (payload.header || {});
    payload.body = (payload.body || {});
    payload.header["BLOCK"] = (payload.header['BLOCK'] || { label: "BLOCK" });
    payload.header["ATTRIBUTES"] = (payload.header['ATTRIBUTES'] || { label: "ATTRIBUTES" });

    const pl = categories.reduce((accumulator: any, category: any, index: number) => {
        let currMedian;
        series.reduce((reducer: any, s: any, serieIndex: number) => {
            const keyLabel = [Chart.getObjectValueTranslation(category.blockName, lang), Chart.getObjectValueTranslation(category.label, lang)].join('_')
            reducer.header[s.name] = (reducer.header[s.name] || { label: s.name });
            reducer.body[keyLabel] = (reducer.body[keyLabel] || { data: {} });
            reducer.body[keyLabel].data["BLOCK"] = (reducer.body[keyLabel].data["BLOCK"] || { value: Chart.getObjectValueTranslation(category.blockName, lang) });
            reducer.body[keyLabel].data["ATTRIBUTES"] = (reducer.body[keyLabel].data["ATTRIBUTES"] || { value: Chart.getObjectValueTranslation(category.label, lang) });
            reducer.body[keyLabel].data[s.name] = (reducer.body[keyLabel].data[s.name] || { value: s.data[index].y });
            if (currMedian) {
                reducer.body[keyLabel].data['MEDIAN'] = { value: median([currMedian, { value: s.data[index].y }], 'value') }
            } else {
                currMedian = { value: s.data[index].y }
            }
            return reducer;
        }, accumulator);
        return accumulator;
    }, payload);
    payload.header["MEDIAN"] = (payload.header['MEDIAN'] || { label: "MEDIAN" });
    const header = [Object.values(pl.header)];
    const body = Object.values(Object.values(pl.body)
        .reduce((acc: any, x: any) => {
            acc.push(
                Object.values(x.data).map((y: any) => {
                    return {
                        value: {
                            translations: y.value 
                        }
                    }
                })
            );
            return acc;
        }, []));

    return {
        thead: header,
        tbody: body
    }
};

const median = (array: Array<any>, key: string) => {
    array = array.filter(x => x.hasOwnProperty(key) && x[key] !== null);
    if (!array.length) return null;
    const mid = Math.floor(array.length / 2),
        nums = [...array].sort((a, b) => a[key] - b[key]);
    return array.length % 2 !== 0 ? nums[mid][key] : (nums[mid - 1][key] + nums[mid][key]) / 2;
};

export {
    doWork,
    doTableWork
}