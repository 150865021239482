<form class="form-inline">
<div class="row">
<div class="col">
  <div class="input-group mr-sm-2">
    <input class="form-control" [(ngModel)]="dateFilterStart" (ngModelChange)="clickDateStart();clickDateEnd()" ngbDatepicker
      #d1="ngbDatepicker" name="dp1" placeholder="{{ 'FILTER_DATE_START' | translate }}" required>
    <div class="input-group-addon" (click)="d1.toggle()">
      <i class="fa fa-calendar" aria-hidden="true"></i>
    </div>
  </div>
</div>
<div class="col">
  <div class="input-group">
    <input class="form-control" [(ngModel)]="dateFilterEnd" (ngModelChange)="clickDateStart();clickDateEnd()" ngbDatepicker #d2="ngbDatepicker"
      name="dp2" placeholder="{{ 'FILTER_DATE_END' | translate }}" required>
    <div class="input-group-addon" (click)="d2.toggle()">
      <i class="fa fa-calendar" aria-hidden="true"></i>
    </div>
  </div>
</div>
</div>
</form>

