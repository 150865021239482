import { Chart } from "../../chart.model";

const doWork = (data: Array<any>, baseKey: string, lang: string, parameters: any, payload: any, routinesObject : any) : any => {
    const routines = routinesObject && routinesObject.hasOwnProperty('routines') && routinesObject.routines && routinesObject.routines.length ? routinesObject.routines.sort((a, b) => b.isBench - a.isBench) : [];
    return data.reduce((accumulator: any, item: any, index : number) => {
        if (item.hasOwnProperty('values') && Array.isArray(item.values)) {
            const keyLabel = [Chart.getObjectValueTranslation(item.attribute.blockName, lang), Chart.getObjectValueTranslation(item.attribute.label, lang)].join('_');
            accumulator.categories[keyLabel] = item.attribute;
            
            item.values.reduce(( reducer : {categories : object, series : object}, object : any, idx : number) => {
                const keySerie = Chart.getObjectValueTranslation(object.attribute.label, lang);
                let color;
                let foundRoutine = routines.find((routine : any ) => routine.name == keySerie );
                if (!foundRoutine || !foundRoutine.color) color = Chart.colorScale[idx];
                else color = foundRoutine.color;
                reducer.series[keySerie] = (reducer.series[keySerie] || { name: keySerie, data: [], color });
                (reducer.series[keySerie].data = ( reducer.series[keySerie].data || []) ).push({
                    y       : median(Chart.castValuesToNumber(object.values, baseKey, true), baseKey),
                    x       : Object.keys(accumulator.categories).indexOf(keyLabel),
                    details : object.values
                });
                return reducer;
            }, accumulator);
        }
        return accumulator;
    }, payload);
};

const median = (array: Array<any>, key : string) => {
    array = array.filter(x => x.hasOwnProperty(key) && x[key] !== null);
    if (!array.length) return null;
    const mid = Math.floor(array.length / 2),
        nums = [...array].sort((a, b) => a[key] - b[key]);
    return array.length % 2 !== 0 ? nums[mid][key] : (nums[mid - 1][key] + nums[mid][key]) / 2;
};

export {
    doWork
}