import { HttpRestService } from '../services/httpRest.service';
import { DNAObject } from '../../types';
import { UserService } from '../services/user.service';
import { Component, Input, OnInit ,EventEmitter,Output} from '@angular/core';




@Component({
    selector: 'dna-search-logo',
    templateUrl: './dnaSearchLogo.component.html'
})

export class DnaSearchLogoComponent implements OnInit {
    @Input() data: DNAObject;
    @Input() isDisplayed: DNAObject;
    @Output() onClickDisplay = new EventEmitter();

    constructor() { }

     onClickEvent() {
        this.onClickDisplay.emit();
    }

    
      ngOnInit() { }
      

}
