<div class="container">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <ng-container *ngIf="!showSpinner && !error">
    <div class="pull-right">
      <ng-container *ngIf="isChanged">
        <button id="save" class="btn btn-primary mb-4 " type="button" (click)="onSave(modifiedTranslations)"
          translate>SAVE</button>
        <button id="cancel" class="btn btn-default mb-4" type="button" (click)="onCancel()" translate>CANCEL</button>
      </ng-container>
    </div>
    <div class="ibox float-e-margins">
      <div class="ibox-title row">
        <div class="col-md-6">
          <h5 translate>FILTERS</h5>
        </div>
        <div class="col-md-6 text-end">
          <button class="btn btn-primary" type="button"
            (click)="searchKeyTrad(search, languagesSelected)" translate>SEARCH</button>
        </div>
      </div>
      <div class="ibox-content">
        <dna-filter-list [array]="languages" [horizontalAlignment]="true" [filterValue]="languagesSelected">
        </dna-filter-list>
        <hr>
        <div class="row">
          <div class="col-8 input-group">
            <input class="form-control" [(ngModel)]="search" placeholder="{{ 'TAG' | translate }}" type="text"
              name="search">
          </div>
          <div class="col-2 input-group">
            <span *ngIf="search && search !== ''" class="fa fa-times" placement="top" (click)="deleteSearch()"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox float-e-margins">
      <div class="ibox-content">
        <div class="table-responsive">
          <dna-table2 [data]="keysTraductions" [headers]="tableHeaders" [numberOfObjectsPerPage]="filter.numberOfObjectsPerPage"
            [pageIndex]="filter.pageIndex" [reverse]="true" [sortable]="false" [title]="'TRANSLATIONS'" [totalItems]="totalItems" (parametersChanged)="onParametersChanged($event)">
            <ng-template #row let-data="data">
              <tr dna-translations-row [key]="data" [translationsFiles]="translationsFiles" [languagesSelected]="languagesInTable" (onTranslationChange)="onChangeTranslation($event)"></tr>
            </ng-template>
          </dna-table2>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="!showSpinner && error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "ERROR" | translate }}</h5>
        </div>
      </div>
    </div>
    <dna-error-page (onClickReload)="init()"></dna-error-page>
  </div>
</div>
