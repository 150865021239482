import { Image, ImageBlob } from '../../../types';
import { environment } from '../../../../environments/environment';
import { UtilService } from '../../services/util.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpRestService } from '../../services/httpRest.service';
import * as _ from 'lodash';
import { Observable, throwError, from, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorManagerService } from '../../services/errorManager.service';
import { v4 as uuid } from 'uuid';


@Component({
    selector: 'dna-upload-images',
    templateUrl: './uploadImages.component.html',
    styleUrls: ['./uploadImages.component.less']
})
export class UploadImagesComponent implements OnInit {

    @Output() onUploadImages: EventEmitter<Image[]> = new EventEmitter();
    serverUrlStudio: string;
    showSpinnerImages = false;
    dimensions: { [key: string]: { width: number, height: number } } = {};

    constructor(
        private utilService: UtilService,
        private httpRestService: HttpRestService,
        private errorManagerService: ErrorManagerService
    ) {
        this.serverUrlStudio = environment.server_url_studio();
    }

    ngOnInit() { }

    uploadFiles(event: any) {
        const files = event.target.files;
        if (files) {
            this.showSpinnerImages = true;
            const promises = [];
            _.forEach(files, file => {
                promises.push(this.resizeAndUploadFile(file));
            });
            forkJoin(promises).subscribe((images: Image[]) => {
                this.onUploadImages.emit(images);
                this.showSpinnerImages = false;
            }, err => this.showSpinnerImages = false);
        }
    }

    getImageSize(dataUrl: string): Promise<{ width: number; height: number }> {
      return new Promise((resolve, reject) => {
          const img = document.createElement('img');
          img.onload = () => resolve({ width: img.naturalWidth, height: img.naturalHeight });
          img.onerror = reject;
          img.src = dataUrl;
      });
  }

    resizeAndUploadFile(file): Observable<Image> {
      const newUuid = uuid();
      let array: string[], reader: FileReader;
      reader = new FileReader();
      reader.readAsDataURL(file);
      const promise = new Promise<Image>((resolve, reject) => {
          reader.onload = async (e: any) => {
              try {
                  const dataUrl = e.target.result;
                  const { width, height } = await this.getImageSize(dataUrl);
                  const resizedImage = await this.utilService.resizeImage(file.type, dataUrl);
                  array = resizedImage.split(',');
                  const uploadedImage = await this.uploadMediaFromComputer(file, array[1], array[0], newUuid, width, height).toPromise();
                  resolve(uploadedImage);
              } catch (error) {
                  reject(error);
              }
          };
      });
      return from(promise);
  }

  uploadMediaFromComputer(file, media: string, type: string, uuid: string, width: number, height: number): Observable<Image> {
    return this.httpRestService.uploadMedia(new ImageBlob(media, type), uuid).pipe(
        catchError(this.catchError),
        map((response: { mediaUUID: string }) => `${environment.apiStudiesUrl}/v1/medialoader/${response.mediaUUID}`),
        map((url: string) => this.addToItems({ data: `${type},${media}`, width, height }, { url: url, name: `${file.name}-${new Date().getTime()}` }))
    );
}

    catchError = (error): Observable<any> => {
        this.errorManagerService.catchError(error);
        this.onUploadImages.emit();
        return throwError(error);
    }

    addToItems(dataWithSize: { data: string, width: number, height: number }, image: Image): Image {
      return {
        data: of(dataWithSize.data),
        name: image.name,
        url: image.url,
        title: image.name.substring(0, image.name.indexOf('.')),
        width: dataWithSize.width,
        height: dataWithSize.height
      };
    }
}
