declare let require: any;
import { Injectable, ElementRef, EventEmitter } from '@angular/core';
const domtoimage = require('dom-to-image-more');
import { BarChart, Tornado, Spider, Bubble, Sankey, LineChart, Table, Scalebar, ColumnChart, kineticChart } from '../models';
import { TranslateService } from '@ngx-translate/core';
import { ChartSettings, DNAGraphs } from '../../../../../../../types';

@Injectable()
export class ChartService {
  public static takeCaptures: EventEmitter<any> = new EventEmitter();

  constructor() { }

  exportGraphToPng = (chart: any) => chart.exportChartLocal({ type: 'image/png' });
  exportHTMLToPng = (chart: HTMLElement, name: string) => {
    const copy = chart.cloneNode(true);
    copy['id'] = 'exporting-chart';
    document.body.appendChild(copy);
    const y = document.getElementById('exporting-chart');
    const table = y.querySelector('table');
    const caption = y.querySelector('caption');
    try {
      const legends = y.querySelector('.dna-table-chart--caption').getElementsByTagName('p');
      Array.from(legends).forEach((p) => {
        p.style.fontWeight = 'bold';
        p.style.fontFamily = 'sans-serif';
      })
    } catch (e) { }
    table['style'].width = '100%';
    caption['style'].fontWeight = 'bold';
    caption['style'].fontFamily = 'sans-serif';
    caption['style'].fontSize = '2em';

    domtoimage.toPng(y).then((pngDataUrl) => {
      var link = document.createElement('a');
      link.download = `${name.replace(/ /g, '-').toLowerCase()}.png`;
      link.href = pngDataUrl;
      link.click();
      document.body.removeChild(copy);
    });
  };

  private static getByPath = (obj: any, is: any, value?: any) => {
    if (typeof is == 'string')
      return ChartService.getByPath(obj, is.split('.'), value);
    else if (is.length == 1 && value !== undefined)
      return obj[is[0]] = value;
    else if (is.length == 0)
      return obj;
    else
      return ChartService.getByPath(obj[is[0]], is.slice(1), value);
  }

  private static assignSettings = (_setting: ChartSettings, parameters: any, mapSettingsKey: Map<string, string>) => {
    try {
      Object.keys(DNAGraphs[_setting.id])
        .filter((key: string) => mapSettingsKey.get(key))
        .forEach((key: string) => {
          switch (key) {
            case 'plotBands':
              // Handling particular case for plotBands (e.g. 'grey zone')
              // Maybe, in the future, we'll need to pass some checks...
              // For now, we just assign min/max values to that property
              /* parameters[mapSettingsKey.get(key)][key] = [{ "color": "rgba(239,236,236,0.5)", "from": _setting[key].min, "to": _setting[key].max }];
              if (parameters.hasOwnProperty('compute') && parameters.compute.hasOwnProperty('method') && ['median-merged'].indexOf(parameters.compute.method) !== -1) {
                parameters[mapSettingsKey.get(key)].plotLines = [
                  { "zIndex": 4, "color": '#787777', "dashStyle": 'shortdash', "width": 1, "value": _setting[key].min },
                  { "zIndex": 4, "color": '#787777', "dashStyle": 'shortdash', "width": 1, "value": _setting[key].max }
                ];
              } else {
                parameters[mapSettingsKey.get(key)].plotLines = [
                  { "zIndex": 4, "color": '#787777', "dashStyle": 'shortdash', "width": 1, "value": _setting[key].min },
                  { "zIndex": 4, "color": '#787777', "dashStyle": 'shortdash', "width": 1, "value": _setting[key].max }
                ];
              } */
              break;
            case 'visibilityThreshold':
              parameters.plotOptions = parameters.plotOptions || {};
              parameters.plotOptions = Object.assign(parameters.plotOptions, { "visibilityThreshold": { "value": _setting[key] } });
              break;
            case 'decisionRules': {
              parameters.decisionRules = _setting.decisionRules || {};
              break;
            }
            case "show_default_language": {
              let default_language: any = null;
              try {
                default_language = parameters.plotOptions.default_language;
              } catch (e) {}
              if (default_language && _setting[key] !== undefined) {
                parameters.plotOptions.default_language.enabled = _setting[key];
              }
              break;
            }
            case "show_attributes_blocks": {
              let hasAttributesBlocks = null;
              try {
                hasAttributesBlocks = parameters.plotOptions.attributes_blocks;
              } catch (e) {}
              if(hasAttributesBlocks && _setting[key] !== undefined) {
                parameters.plotOptions.attributes_blocks.enabled = _setting[key];
              }
              break;
            }
            case "show_grey_zone": {
              let hasGreyZone = null;
              try {
                hasGreyZone = parameters.plotOptions.grey_zone;
              } catch (e) {}
              if (hasGreyZone && _setting[key] !== undefined) {
                parameters.plotOptions.grey_zone.enabled = _setting[key];
              }
              break;
            }
            case "show_data_label" : {
              let hasDataLabel = null;
              try {
                hasDataLabel = parameters.plotOptions.series.dataLabels;
              } catch (e) {}
              if(hasDataLabel && _setting[key] !== undefined) {
                parameters.plotOptions.series.dataLabels.enabled = _setting[key];
              }
              break;
            }
            case "show_column_zero": {
              let hasShowZero = null;
              try {
                hasShowZero = parameters.plotOptions.show_column_zero;
              } catch (e) {}
              if(hasShowZero && _setting[key] !== undefined) {
                parameters.plotOptions.show_column_zero.enabled = _setting[key];
              }
              break;
            }
            default:
              parameters[mapSettingsKey.get(key)] = _setting[key] ? Object.assign(parameters[mapSettingsKey.get(key)], _setting[key]) : parameters[mapSettingsKey.get(key)];
              break;
          };
        });
    } catch (e) {}
    return parameters;
  }

  public static instanciateChart = (
    parameters: any,
    settings: ChartSettings,
    data: any,
    type: any,
    filters: any,
    lang: string,
    translateService: TranslateService
  ) => {
    switch (type) {
      case 'barchart':
        parameters = ChartService.assignSettings(settings, parameters, BarChart.mapSettings);
        return new BarChart(parameters, data, lang, filters, translateService);
      case 'columnchart':
        parameters = ChartService.assignSettings(settings, parameters, ColumnChart.mapSettings);
        return new ColumnChart(parameters, data, lang, filters, translateService);
      case 'tornado':
        parameters = ChartService.assignSettings(settings, parameters, Tornado.mapSettings);
        return new Tornado(parameters, data, lang, filters, translateService);
      case 'spider':
        parameters = ChartService.assignSettings(settings, parameters, Spider.mapSettings);
        return new Spider(parameters, data, lang, filters, translateService);
      case 'linechart':
        parameters = ChartService.assignSettings(settings, parameters, LineChart.mapSettings);
        return new LineChart(parameters, data, lang, filters, translateService);
      case 'scalebar':
        parameters = ChartService.assignSettings(settings, parameters, Scalebar.mapSettings);
        return new Scalebar(parameters, data, lang, filters, translateService);
      case 'sankey':
        parameters = ChartService.assignSettings(settings, parameters, Sankey.mapSettings);
        return new Sankey(parameters, data, lang, filters, translateService);
      case 'table':
        parameters = ChartService.assignSettings(settings, parameters, Table.mapSettings);
        return new Table(parameters, data, lang);
      /* case 'boxplot':
        return new Boxplot(parameters, data, lang); */
      case 'bubble':
        parameters = ChartService.assignSettings(settings, parameters, Bubble.mapSettings);
        return new Bubble(parameters, data, lang);
      case 'kinetic':
        parameters = ChartService.assignSettings(settings, parameters, kineticChart.mapSettings);
        return new kineticChart(parameters, data, lang);
      default:
        return null;
    }
  };

  /**
   * exportToExcel
   * @param el ElementRef
   * @param fileName string
   */
  public exportToExcel = (el: ElementRef, fileName: string): void => {

    let downloadLink: any;
    let dataType: string = 'application/vnd.ms-excel;charset=utf-8,%EF%BB%BF';
    const tableHTML = this.generateExcel(el.nativeElement);
    let filename = fileName ? `${fileName}.xls` : 'excel_data.xls';
    downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    //navigator.msSaveOrOpenBlob for IE ?
    //if so, use type assertion
    if ((navigator as any).msSaveOrOpenBlob) {
      var blob = new Blob(['\uFEFF', tableHTML], {
        type: dataType
      });
      (navigator as any).msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
      downloadLink.download = filename;
      downloadLink.click();
    }
  };

  private generateExcel = (tab): string => {
    let tab_text = '<table border="1px" style="font-size:20px" ">';
    const lines = tab.rows.length;

    for (let j = 0; j < lines; j++) {
      tab_text = tab_text + "<tr>" + tab.rows[j].innerHTML + "</tr>";
    }

    tab_text = tab_text + "</table>";
    tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, "");
    tab_text = tab_text.replace(/<img[^>]*>/gi, "");
    tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, "");
    return encodeURIComponent(tab_text);
  };

  /**
   * 20765 Emit event when take multiple captures
   */
  public static onTakeCaptures() {
    this.takeCaptures.emit();
  }

};
