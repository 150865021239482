import { UtilService } from '../../../shared/services/util.service';
import { DNATypes, Volunteers } from './../../../types';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActionBarButton } from '../../../types';
import { Observable } from 'rxjs';

@Component({
  selector: '[dna-volunteer-row]',
  templateUrl: './volunteer-row.component.html',
  styleUrls: ['./volunteer-row.component.css']
})
export class VolunteerRowComponent implements OnInit {
  @Input() volunteer: Volunteers;
  @Output() onClick: EventEmitter < ActionBarButton > = new EventEmitter();
  @Output() onClickVolunteer: EventEmitter < Volunteers > = new EventEmitter();
  actionsButtons: Observable < ActionBarButton[] > ;

  constructor(private utilService: UtilService) { }

  ngOnInit() {
    this.actionsButtons = this.utilService.createActionsButtons(DNATypes.Volunteers);
  }

  onClickButton(actionsButton: ActionBarButton) {
    this.onClick.emit(actionsButton);
  }

  onClickOnVolunteer(volunteer: Volunteers) {
    this.onClickVolunteer.emit(volunteer);
  }

  

}
