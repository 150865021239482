
import { of as observableOf,  Observable } from 'rxjs';

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Block, States, DNAComponent, BlockLinks, JsonPatch, Etags } from '../types';
import { environment } from '../../environments/environment';

import * as _ from 'lodash';
import { UtilService } from '../shared/services/util.service';
import { UserService } from '../shared/services/user.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class BlockService {
    blocks: Block[] = [];
    block: Block;
	componentsToDelete: string[];
    component: DNAComponent;
    indexComponent: number;
    blockUntouched: Block;
    serverUrlStudio: string;
    isBlockChanged = false;
    blockStates: typeof States = States;
    peopleKey: string;
    blockLinks: BlockLinks;
    updateComponentEditName: EventEmitter<Block> = new EventEmitter();

    constructor(
        private http: HttpClient,
        private utilService: UtilService,
        private userService: UserService
    ) {
        this.serverUrlStudio = environment.server_url_studio();
        this.peopleKey = _.get(this.userService.getUser(), 'track', '');
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Block> {
      if (this.block) {
        this.blockUntouched = _.cloneDeep(this.block);
        return observableOf(this.block);
      }
      return this.getBlock(route.paramMap.get('idBlock')).pipe(mergeMap(block => {
        this.block = block;
        this.blockUntouched = _.cloneDeep(block);
        return observableOf(block);
      }));
    }

		addComponentToBlock(component: DNAComponent) {
			this.block.components.push(component);
		}

		addComponentToDelete(componentId: string) {
      if (!this.componentsToDelete) {
        this.componentsToDelete = [];
      }
      this.componentsToDelete.push(componentId);
		}

		clearComponentToDelete() {
      this.componentsToDelete = [];
		}

		setBlockComponents(components: DNAComponent[]) {
			this.block.components = components;
		}

		toggleMandatoryComponent(componentIndex: number) {
			this.block.components = this.block.components.map(component => {
        if (component.index === componentIndex) {
          return {
            ...component,
            mandatory: !component.mandatory
          };
        }
        return component;
      });
		}

		updateComponentFromBlock(component: DNAComponent) {
        this.block.components = this.block.components.map(currentComponent => {
            if (currentComponent.index === component.index) {
                return component;
            }
            return currentComponent;
        });
		}

    setLocalBlock(block) {
        this.block = block;
    }

    getLocalBlock() {
        return this.block;
    }

    setBlockUntouched(block) {
        this.blockUntouched = _.cloneDeep(block);
    }

    getBlockUntouched() {
        return _.cloneDeep(this.blockUntouched);
    }

    createBlock(block: Block): Observable<Block> {
        return this.http.post<Block>(`${environment.apiLibrariesUrl}/v1/blocks/${this.getIdWorkspace()}`, block);
    }

    duplicateBlock(idBlockToDuplicate: string, block: Block): Observable<Block> {
        return this.http.post<Block>(`${environment.apiLibrariesUrl}/v1/blocks/${idBlockToDuplicate}/duplicate/${this.getIdWorkspace()}`, block);
    }

    deleteBlock(id: string): Observable<Block> {
        return this.http.delete<Block>(`${environment.apiLibrariesUrl}/v1/blocks/${id}/${this.getIdWorkspace()}`);
    }

    getBlock(id: string): Observable<Block> {
        return this.http.get<Block>(`${environment.apiLibrariesUrl}/v1/blocks/${id}/${this.getIdWorkspace()}`);
    }

    getBlocks(filters?: any): Observable<any> {
        let idWorkspace = this.getIdWorkspace();
        if (_.isEmpty(idWorkspace)) {
            idWorkspace = 'all';
        }
        if (filters) {
            const filterStates = filters.states.map(s => `'${s}'`).join(',');
            const page = _.get(filters, 'pageIndex', 1);
            const limit =  _.get(filters, 'numberOfObjectsPerPage', 10);
            const orderBy =  _.get(filters, 'orderBy', 'updated_on');
            const order =  _.get(filters, 'order', 'DESC');
            return !_.isEmpty(filters.name)
            ? this.http.get<any>(`${environment.apiLibrariesUrl}/v1/blocks/${idWorkspace}?page=${page}&limit=${limit}&filters=[state[in]=(${filterStates});name[ct]=${filters.name}]&orderby=${orderBy}&order=${order}`)
            : this.http.get<any>(`${environment.apiLibrariesUrl}/v1/blocks/${idWorkspace}?page=${page}&limit=${limit}&filters=[state[in]=(${filterStates})]&orderby=${orderBy}&order=${order}`);
        } else {
            return this.http.get<any>(`${environment.apiLibrariesUrl}/v1/blocks/${idWorkspace}`)
        }
    }

    getBlocksByIds(ids: string[]): Observable<any> {
      return this.http.get<any>(`${environment.apiLibrariesUrl}/v1/blocksByIds?${ids.map(id => `ids=${id}`).join('&')}`);
    }

    getBlocksInLocal(): Block[] {
        return this.blocks;
    }

    publishBlock(idBlock: string): Observable<Block> {
        return this.http.patch<Block>(`${environment.apiLibrariesUrl}/v1/blocks/${idBlock}/publish/${this.getIdWorkspace()}`, {});
    }

    removeBlock(block): Observable<Block> {
        return this.http.patch<Block>(`${environment.apiLibrariesUrl}/v1/blocks/${block.id}/remove/${this.getIdWorkspace()}`, block);
    }

    suspendBlock(idBlock: string): Observable<Block> {
        return this.http.patch<Block>(`${environment.apiLibrariesUrl}/v1/blocks/${idBlock}/suspend/${this.getIdWorkspace()}`, {});
    }

    saveBlock(idBlock, block, componentsToDelete): Observable<Block> {
        return this.http.patch<Block>(`${environment.apiLibrariesUrl}/v1/blocks/${idBlock}/save/${this.getIdWorkspace()}?peoplekey=${this.peopleKey}`, { block, componentsToDelete });
    }

    forceRemoveUnusedComponents(idBlock: string): Observable<any> {
        return this.http.patch<Block>(`${environment.apiLibrariesUrl}/v1/blocks/${idBlock}/components/forceremove`, {});
    }

    // ***********************************************

    setBlocks(blocksNew: Block[]) {
        this.blocks = blocksNew;
    }

    updateWorkspaceInBlock(block: Block): Observable<Block> {
        return this.http.patch<Block>(this.serverUrlStudio + '/blocks/' + block.id, { workspaces: block.workspaces });
    }

    putBlockState(block: Block, state: string): Observable<Block> {
        return this.http.patch<Block>(this.serverUrlStudio + '/blocks/' + block.id, {state: state});
    }

    getIdWorkspace() {
        return _.get(this.userService.getUser(), 'currentWorkspace.id', '');
    }

    updateName(block) {
        this.updateComponentEditName.emit(block);
    }

    getComponents(): Observable<any> {
        return this.http.get<any>(`${environment.apiLibrariesUrl}/v1/components`)
    }
}
