<form [formGroup]="editForm">
  <div class="table-responsive">
    <table class="table table-borderless-th">
      <thead>
        <tr>
          <th translate>LANGUAGE</th>
          <th translate>NAME</th>
          <th translate>DESCRIPTION</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let language of languages">
          <td>
            <label [ngClass]="{ required: language === 'english' }" translate>{{ language }}</label>
          </td>
          <td>
            <input class="form-control" [formControl]="editForm.controls.name.controls[language]"
              [required]="language === 'english'" type="text" name="{{ 'name' + language }}">
          </td>
          <td>
            <textarea class="form-control" [formControl]="editForm.controls.description.controls[language]"
              name="{{ 'description' + language }}"></textarea>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="ibox-title">
    <h5 translate>HUB</h5>
  </div>
  <div class="ibox-content">
    <div class="form-group">
      <div class="row">
        <div class="col-12 col-md-3">
          <select class="form-control" [formControl]="editForm.controls['hub']" name="hubSelection">
            <option *ngFor="let hubToSelect of hubs" [value]="hubToSelect" translate>{{ hubToSelect }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</form>