import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import {
  Block,
  Condition,
  Conditions,
  LogicJump,
  Question
} from '../../../../types';

import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-logic-jump-block',
  templateUrl: './logic-jump-block.component.html',
  styleUrls: ['./logic-jump-block.component.css']
})

export class LogicJumpBlockComponent implements OnInit, AfterViewInit {

  @Input() blockName: string = "";
  @Input() logicJump: LogicJump = new LogicJump();
  @Input() logicJumpLength: number = 0;
  @Input() nextQuestions: Question[] = [];
  @Input() previousQuestions: Question[] = [];

  @Output() onAddJump: EventEmitter<string> = new EventEmitter<string>();
  @Output() onRemoveJump: EventEmitter<string> = new EventEmitter<string>();

  conditions: Condition[] = [
    Conditions.EQUAL,
    Conditions.NOT_EQUAL
  ];
  selectedQuestion: Question;
  initTime = performance.now();

  constructor(
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService
  ) { }

  ngOnInit() {

    if (this.previousQuestions.length > 0) {
      let currentQuestion = this.previousQuestions.find(question => this.compareQuestion(question, new Question('', this.logicJump.idInQuestionnaire, this.logicJump.idInBlock)))
      if (!currentQuestion) currentQuestion = this.previousQuestions[this.previousQuestions.length - 1];
      this.selectedQuestion = currentQuestion;
    }

    this.initLogicJump();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Library Logic Jump Block', '', performance.now() - this.initTime, templateUrl);
  }

  compareById(a: any, b: any) {
    return a && b && a.id === b.id;
  }

  compareQuestion(a: Question, b: Question) {
    return a && b && (a.idInBlock === b.idInBlock || (!a.idInBlock && !b.idInBlock)) && a.idInQuestionnaire === b.idInQuestionnaire;
  }

  onAdd() {
    this.onAddJump.emit();
  }

  onChange(question: Question) {
    this.logicJump.idInBlock = question.idInBlock;
    this.logicJump.idInQuestionnaire = question.idInQuestionnaire;
  }

  onRemove() {
    this.onRemoveJump.emit();
  }

  initLogicJump(){
    if (!this.logicJump.jumpTo && !_.isEmpty(this.nextQuestions)) {
      this.logicJump.jumpTo = _.head(this.nextQuestions).idInQuestionnaire;
    }
    if(!this.logicJump.idInBlock){
      this.logicJump.idInBlock = _.head(this.previousQuestions).idInBlock;
    }
    if(!this.logicJump.idInQuestionnaire){
      this.logicJump.idInQuestionnaire = _.head(this.previousQuestions).idInQuestionnaire;
    }
  }

}
