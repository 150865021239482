import { StateService } from '../services/state.service';
import { Component, Input, OnInit } from '@angular/core';
import * as _ from "lodash";


@Component({
    selector: 'dna-display-filter-table',
    templateUrl: './dnaDisplayFilterTable.component.html'
})

export class dnaDisplayFilterTableComponent implements OnInit {
    @Input() data: any;
    @Input() displayTable: any;
    @Input() arrayUser: any;
    @Input() objectForm: any;
    @Input() keyUser: any;
    order: string[];
    selectionType: { [a: string]: string }
    currentSelected: string;
    state: StateService;


    constructor(private stateService: StateService) { }

    ngOnInit() {
        this.state = this.stateService;
        this.order = ['workflow.id', 'block.index', 'component.index', 'formula.name'];

        this.selectionType = {
            wkUp: "workflowUp",
            wkDown: "workflowDown",
            blockUp: "blockUp",
            blockDown: "blockDown",
            formulaUp: "formulaUp",
            formulaDown: "formulaDown",
            componentUp: "componentUp",
            componentDown: "componentDown"
        }
    }

    sort(data: any[] = [], order: string[] = []) {
        if (data && order) {
            return data
                .slice(0) // Make a copy
                .sort((a, b) => this.sortFromArray(a,b,order,0))
            
        }
        return data;
    }

    /**
     * 
     * @param a : an object in an array to sort
     * @param b : an other object in an array to sort
     * @param order : string array of type ['foo.barr', '-bar.foo']. It s a path to the value we want to order by, with an optional - at the start. If there is a -, then the order of sorting is reversed
     * @param currentIndex : currentIndex in the order array
     */
    sortFromArray(a: any, b: any, order: string[], currentIndex: number): number {
        let path = order[currentIndex];
        let reverse = false;
        if(path[0] === '-') {
            reverse = true;
            path = path.substring(1);
        }
        if (_.at(a, path) < _.at(b, path)) {
            return reverse ? 1:-1;
        } else if (_.at(b, path) < _.at(a, path)) {
            return reverse ? -1: 1;
        }
        else if(++currentIndex < order.length) {
            return this.sortFromArray(a,b,order, currentIndex);
        }
        else {
            return 0;
        }
    }



    changeOrdering(dataToSort: string, ascending: boolean) {
        switch (dataToSort) {
            case this.stateService.WORKFLOWS:
                if (ascending) {
                    this.currentSelected = this.selectionType.wkUp;
                    this.order = ['workflow.name', 'block.index', 'component.index', 'formula.name'];
                }
                else {
                    this.currentSelected = this.selectionType.wkDown;
                    this.order = ['-workflow.name', 'block.index', 'component.index', 'formula.name'];
                }
                break;
            case this.stateService.BLOCKS:
                if (ascending) {
                    this.currentSelected = this.selectionType.blockUp;
                    this.order = ['block.index', 'workflow.id', 'component.index', 'formula.name'];
                }
                else {
                    this.currentSelected = this.selectionType.blockDown;
                    this.order = ['-block.index', 'workflow.id', 'component.index', 'formula.name'];
                }
                break;
            case this.stateService.FORMULA:
                if (ascending) {
                    this.currentSelected = this.selectionType.formulaUp;
                    this.order = ['formula.name', 'workflow.name', 'block.index', 'component.index'];
                }
                else {
                    this.currentSelected = this.selectionType.formulaDown;
                    this.order = ['-formula.name', 'workflow.name', 'block.index', 'component.index'];
                }
                break;
            case this.stateService.COMPONENTS:
                if (ascending) {
                    this.currentSelected = this.selectionType.componentUp;
                    this.order = ['component.type', 'workflow.name', 'block.index', 'formula.name'];
                }
                else {
                    this.currentSelected = this.selectionType.componentDown;
                    this.order = ['-component.type', 'workflow.name', 'block.index', 'formula.name'];
                }
                break;
        }
    }
}
