
import { flatMap, tap, catchError} from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { throwError, of } from 'rxjs';

import { Metiers, TypeMetier, CampaignProtocol } from '../../../../types';
import { CampaignService } from '../../../campaigns.service';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-protocol',
  templateUrl: './protocol.component.html',
  styleUrls: ['./protocol.component.less']
})
export class ProtocolComponent implements OnInit, AfterViewInit {

  metiers: typeof Metiers = Metiers;

  campaignProtocol: CampaignProtocol;
  metierName: TypeMetier;
  showSpinner: boolean = true;
  error: boolean = false;
  publishedTemplate: boolean = false;
  initTime = performance.now();

  constructor(
    private campaignService: CampaignService,
    private aiService: ApplicationInsightsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Protocol', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.error = false;
    this.route.parent.paramMap.pipe(
      flatMap(params => this.campaignService.getCampaignProtocol(params.get('idCampaign'))),
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      tap((campaignProtocol: CampaignProtocol) => this.campaignProtocol = campaignProtocol),
      tap((campaignProtocol: CampaignProtocol) => this.metierName = campaignProtocol.metier.name),
      tap((campaignProtocol: CampaignProtocol) => this.campaignService.updateBreadCrumb(campaignProtocol.name)),
      tap((campaignProtocol: CampaignProtocol) => this.publishedTemplate = this.campaignService.isTemplatePublishedCampaign(campaignProtocol.isTemplate, campaignProtocol.state)),
      tap(() => this.showSpinner = false),
      ).subscribe();
  }

}
