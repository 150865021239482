<div class="row">
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>CHEEKS_BLEMISH</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.cheeksBlemish" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'cheeksBlemish')" (onRemove)="removeItem(filter, $event, 'cheeksBlemish')" name="tag-input-cheeksBlemish"
      placeholder="+ {{ 'CHEEKSBLEMISH' | translate }}" secondaryPlaceholder="{{ 'CHEEKS_BLEMISH' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.cheeksBlemish" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>CHEEKS_REDNESS</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.cheeksRedness" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'cheeksRedness')" (onRemove)="removeItem(filter, $event, 'cheeksRedness')" name="tag-input-cheeksRedness"
      placeholder="+ {{ 'CHEEKS_REDNESS' | translate }}" secondaryPlaceholder="{{ 'CHEEKS_REDNESS' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.cheeksRedness" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>DILATED_PORES</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.dilatedPores" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'dilatedPores')" (onRemove)="removeItem(filter, $event, 'dilatedPores')" name="tag-input-dilatedPores"
      placeholder="+ {{ 'DILATED_PORES' | translate }}" secondaryPlaceholder="{{ 'DILATED_PORES' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.dilatedPores" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>EYELASHES_CURVE</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.eyelashesCurve" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'eyelashesCurve')" (onRemove)="removeItem(filter, $event, 'eyelashesCurve')" name="tag-input-eyelashesCurve"
      placeholder="+ {{ 'EYELASHES_CURVE' | translate }}" secondaryPlaceholder="{{ 'EYELASHES_CURVE' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.eyelashesCurve" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>EYELASHES_DENSITY</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.eyelashesDensity" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'eyelashesDensity')" (onRemove)="removeItem(filter, $event, 'eyelashesDensity')" name="tag-input-eyelashesDensity"
      placeholder="+ {{ 'EYELASHES_DENSITY' | translate }}" secondaryPlaceholder="{{ 'EYELASHES_DENSITY' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.eyelashesDensity" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>EYELASHES_LENGTH</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.eyelashesLength" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'eyelashesLength')" (onRemove)="removeItem(filter, $event, 'eyelashesLength')" name="tag-input-eyelashesLength"
      placeholder="+ {{ 'EYELASHES_LENGTH' | translate }}" secondaryPlaceholder="{{ 'EYELASHES_LENGTH' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.eyelashesLength" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>FACIAL_BRIGHTNESS</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.facialBrightness" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'facialBrightness')" (onRemove)="removeItem(filter, $event, 'facialBrightness')" name="tag-input-facialBrightness"
      placeholder="+ {{ 'FACIAL_BRIGHTNESS' | translate }}" secondaryPlaceholder="{{ 'FACIAL_BRIGHTNESS' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.facialBrightness" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>FACIAL_SKIN</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.facialSkin" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'facialSkin')" (onRemove)="removeItem(filter, $event, 'facialSkin')" name="tag-input-facialSkin"
      placeholder="+ {{ 'FACIAL_SKIN' | translate }}" secondaryPlaceholder="{{ 'FACIAL_SKIN' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.facialSkin" displayBy="value" identifyBy="Text" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>LIPS_SIZE</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.lipsSize" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'lipsSize')" (onRemove)="removeItem(filter, $event, 'lipsSize')" name="tag-input-lipsSize"
      placeholder="+ {{ 'LIPS_SIZE' | translate }}" secondaryPlaceholder="{{ 'LIPS_SIZE' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.lipsSize" displayBy="value" identifyBy="Text" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>LIPS_THICKNESS</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.lipsThickness" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'lipsThickness')" (onRemove)="removeItem(filter, $event, 'lipsThickness')" name="tag-input-lipsThickness"
      placeholder="+ {{ 'LIPS_THICKNESS' | translate }}" secondaryPlaceholder="{{ 'LIPS_THICKNESS' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.lipsThickness" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>PHOTOTYPE</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.phototype" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'phototype')" (onRemove)="removeItem(filter, $event, 'phototype')" name="tag-input-phototype"
      placeholder="+ {{ 'PHOTOTYPE' | translate }}" secondaryPlaceholder="{{ 'PHOTOTYPE' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.phototype" displayBy="value" identifyBy="Text" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>FOREHEAD_TONE</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.foreheadTone" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'foreheadTone')" (onRemove)="removeItem(filter, $event, 'foreheadTone')" name="tag-input-foreheadTone"
      placeholder="+ {{ 'FOREHEAD_TONE' | translate }}" secondaryPlaceholder="{{ 'FOREHEAD_TONE' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.foreheadTone" displayBy="value" identifyBy="Text" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>SKIN_TYPE</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.skinType" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'skinType')" (onRemove)="removeItem(filter, $event, 'skinType')" name="tag-input-skinType"
      placeholder="+ {{ 'SKIN_TYPE' | translate }}" secondaryPlaceholder="{{ 'SKIN_TYPE' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.skinType" displayBy="value" identifyBy="Text" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>UPPEREYELIDS_RELAXATION</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.upperEyelidsRelaxation" displayBy="value" identifyBy="Text"
      [onlyFromAutocomplete]="true" (onAdd)="addItem(filter, $event, 'upperEyelidsRelaxation')" (onRemove)="removeItem(filter, $event, 'upperEyelidsRelaxation')"
      name="tag-input-upperEyelidsRelaxation" placeholder="+ {{ 'UPPEREYELIDS_RELAXATION' | translate }}" secondaryPlaceholder="{{ 'UPPEREYELIDS_RELAXATION' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.upperEyelidsRelaxation" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>UPPEREYELIDS_SURFACE_AREA</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.upperEyelidsSurfaceArea" displayBy="value" identifyBy="Text"
      [onlyFromAutocomplete]="true" (onAdd)="addItem(filter, $event, 'upperEyelidsSurfaceArea')" (onRemove)="removeItem(filter, $event, 'upperEyelidsSurfaceArea')"
      name="tag-input-upperEyelidsSurfaceArea" placeholder="+ {{ 'UPPEREYELIDS_SURFACE_AREA' | translate }}" secondaryPlaceholder="{{ 'UPPEREYELIDS_SURFACE_AREA' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.upperEyelidsSurfaceArea" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>WRINKLES_UNDER_EYE</h5>
    <tag-input inputClass="tagInputFiltersTarget" [ngModel]="filter.wrinklesUnderEye" displayBy="value" identifyBy="Text" [onlyFromAutocomplete]="true"
      (onAdd)="addItem(filter, $event, 'wrinklesUnderEye')" (onRemove)="removeItem(filter, $event, 'wrinklesUnderEye')" name="tag-input-wrinklesUnderEye"
      placeholder="+ {{ 'WRINKLES_UNDER_EYE' | translate }}" secondaryPlaceholder="{{ 'WRINKLES_UNDER_EYE' | translate }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="target.wrinklesUnderEye" displayBy="value" identifyBy="Text"
        [keepOpen]="false" [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
</div>