<div *ngIf="isLinkableToRadioVersus(component)">
    <p translate>INDEX_TO_ASSOCIATE</p>
    <div class="row">
      <div class="col" style="padding:0 5px 0 0">
        <select class="form-control" [(ngModel)]="component.args.linkRadioVersus" [compareWith]="linkRadioVersusCompare">
          <option [ngValue]="undefined" translate>NO_LINK</option>
          <option *ngFor="let radioV of radioVersus" [ngValue]="radioV.link">{{radioV.title}}</option>
        </select>
      </div>
      <div class="col-6" style="padding:0 10px 0 0">
        <select class="form-control" *ngIf="component.args.linkRadioVersus && component.args.linkRadioVersus.operator" [(ngModel)]="component.args.linkRadioVersus.operator">
          <option *ngFor="let type of operatorTypes" [value]="type" translate>{{type}}</option>
        </select>
      </div>
    </div>
  </div>
  