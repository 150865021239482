<div *ngIf="couples && couples.length > 0" class="table-responsive">
  <table class="table table-hover table-borderless-th dataTable">
    <thead>
      <tr>
        <th translate>COUPLES</th>
        <th translate>BENCH</th>
        <th translate>IS_ACTIVE</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let couple of couples; let indexCouple = index">
        <td>{{ couple.name }}</td>
        <td>
          <select class="form-control" [ngModel]="couple.bench" (ngModelChange)="onChangeBench(indexCouple, $event)" [compareWith]="compareFormula"
            #bench="ngModel" name="bench" [disabled]="campaignPublished">
            <option *ngFor="let coupleFormula of couple.formula" [ngValue]="coupleFormula">{{ coupleFormula.name }}</option>
          </select>
        </td>
        <td>
          <dna-switch [isSelected]="couple.isActive" (onBooleanChanged)="onChangeIsActive(indexCouple, $event)"></dna-switch>
        </td>
      </tr>
    </tbody>
  </table>
</div>
