import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';

import {
  DragulaService, DragulaOptions
} from 'ng2-dragula';

import { Descriptor, Graph } from '../../../../../../types';
import { UtilService } from '../../../../../../shared/services/util.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dna-power-bi-descriptor-table',
  templateUrl: './power-bi-descriptor-table.component.html'
})
export class PowerBIDescriptorTableComponent implements OnChanges, OnDestroy {

  @Input() canRemoved: boolean = true;
  @Input() graph: Graph;
  @Input() isWorkflowPublished: boolean = false;
  @Input() editMode: boolean;

  @Output() onChange: EventEmitter<Descriptor[]> = new EventEmitter();

  keyDragula: string;
  subs = new Subscription();

  constructor(
    private dragulaService: DragulaService,
    private utilService: UtilService
  ) { 
    this.subs.add(this.dragulaService.drop(this.keyDragula)
      .subscribe(() => this.onChange.emit(this.graph.descriptors)))
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.graph.firstChange) {
      this.keyDragula = this.utilService.generateRandomID(4);
      const dOptions: DragulaOptions = {
        moves: function (el: Element, container: Element, handle: Element): boolean {
          return handle.className === "reorder" || handle.className === "fa fa-arrows-v";
        }
      }
      this.dragulaService.createGroup(this.keyDragula, dOptions);
    }
  }

  public ngOnDestroy(): void {
    this.dragulaService.destroy(this.keyDragula);
    this.subs.unsubscribe();
    this.onChange.unsubscribe();
  }



  onSwitchChanged(index: number, isActive: boolean) {
    this.graph.descriptors[index].isActive = isActive;
    this.onChange.emit(this.graph.descriptors);
  }

  removeDescriptor(index: number) {
    this.graph.descriptors.splice(index, 1);
    this.onChange.emit(this.graph.descriptors);
  }

}
