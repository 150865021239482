import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {

  transform(collection: Array<any>, key : string, property: string): any {
    if (!collection || !collection[0]) {
      return null;
    } else if ( collection[0] && !collection[0].hasOwnProperty(key)) {
      return collection;
    }
    else {
      const groupedCollection : Array<any> = collection.reduce((previous, current)=> {
        if(!previous[current[key]]) {
            previous[current[key]] = { label : current[property], data : [ current ] };
        } else {
            previous[current[key]].data.push(current);
        }
        return previous;
    }, {});
    return [].concat(...Object.values(groupedCollection));
    }
  }

}
