import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionBarButton, DNATypes, ActionTypes, CampaignsMulti } from '../../../types';
import { UtilService } from '../../../shared/services/util.service';
import * as _ from 'lodash';
import { DNATranslateService } from '../../../shared/services/translate.service';
import { Router } from '@angular/router';
import { MultiCampaignsService } from '../../multi-campaigns.service';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: '[dna-multi-campaigns-row]',
  templateUrl: './multi-campaigns-row.component.html',
  styleUrls: ['./multi-campaigns-row.component.less']
})
export class MultiCampaignsRowComponent implements OnInit {

  @Input() campaignMulti: CampaignsMulti;
  @Output() onClickAction: EventEmitter<ActionBarButton> = new EventEmitter();

  actionsButtons: Observable<ActionBarButton[]>;
  studies: string[] = [];
  workflows: string[] = [];
  formulas: string[] = [];
  synergy = [];
  orchestras = [];
  activiews = [];
  language:string;
  showSpinner: boolean = false;

  modalOption: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: "lg"
  };

  constructor(
    private utilService: UtilService,
    private translateService: DNATranslateService,
    private router: Router,
    private campaignMultiService: MultiCampaignsService
  ) { }

  ngOnInit() {
    this.language = this.translateService.getLanguage();
    this.actionsButtons = this.utilService.createActionsButtons(DNATypes.CampaignsMulti);
    this.initCampaignMulti(this.campaignMulti, this.language);
  }

  initCampaignMulti(campaignMulti, language: string = "english") {
    if (campaignMulti.workflows) {
      campaignMulti.workflows.forEach(wf => {
        const name = wf.name[language] ? wf.name[language] : wf.name.english
        this.workflows.push(name);
      });
      this.workflows = _.uniq(this.workflows);
    }

    if (campaignMulti.formulas) {
      campaignMulti.formulas.forEach(fm => {
            this.formulas.push(fm.name);
      });
      this.formulas = _.uniq(this.formulas);
    }

    if (campaignMulti.synergy) {
      this.synergy = campaignMulti.synergy.map( c => c.requestNumber);
      this.synergy = _.uniq(this.synergy);
    }

    if (campaignMulti.orchestra) {
      campaignMulti.orchestra.forEach(orc => {
        this.orchestras.push(Object.values(orc)[0]);
      })
      this.orchestras = _.uniq(this.orchestras);
    }

    if (campaignMulti.actiview) {
      this.activiews = campaignMulti.actiview.map( c => c.activityNumber);
      this.activiews = _.uniq(this.activiews);
    }

    if (campaignMulti.studies) {
      campaignMulti.studies.forEach(camp => {
        this.studies.push(camp.name);
      });
    }

  }

  openCampaignMulti() {
    this.campaignMultiService.setCampaignMultiFromLocalStorage({
      id: this.campaignMulti.id,
      name: this.campaignMulti.name
    })
    this.router.navigate(['multi-campaigns', this.campaignMulti.id]);
  }

  onClickButton(actionButton: ActionBarButton) {
    this.onClickAction.emit(actionButton);
  }

}
