import { FicheEntretienComponent } from './detail/FicheEntretien/ficheEntretien.component';
import {
  SharedModule
} from '../shared/shared.module';
import {
  VolunteersComponent
} from '../volunteers/list/volunteers.component';
import {
  CreateModalComponent
} from './detail/volunteerModal/createModal/createVolunteerModal.component';
import {
  CreateListModalComponent
} from './detail/volunteerModal/createListModal/createListVolunteersModal.component';
import {
  EditModalComponent
} from './detail/volunteerModal/editModal/editVolunteerModal.component';

import {
  NgModule
} from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import {
  AuthGuardService
} from '../shared/services/authenticationGuard.service';
import {
  VolunteerRowComponent
} from './list/volunteer-row/volunteer-row.component';
import {
  VolunteerDetailComponent
} from './detail/volunteerDetail.component';
import {
  GeneralInformationVolunteerComponent
} from './detail/generalInformation/generalInformation.component';
import {
  NgxTimelineModule
} from '@frxjs/ngx-timeline';
import {
  FicheCaracterisationComponent
} from './detail/FicheCaracterisation/FicheCaracterisation.component';
import { FicheRemanenceComponent } from './detail/FicheRemanence/ficheRemanence.component';
import { PanelistRowComponent } from './list/panelist-row/panelist-row.component';
import { PanelistArcsRowComponent } from './list/panelist-arcs-row/panelist-arcs-row.component';
import { GroupPanelistHARowComponent } from './list/group-panelist-horsarcs-row/group-panelist-horsarcs-row.component';
import { EditGroupHorsArcsRowComponent } from './list/edit-group-horsarcs-row/edit-group-horsarcs-row.component';

const appRoutes: Routes = [{
  path: 'volunteers',
  component: VolunteersComponent,
  canActivate: [AuthGuardService],
  data: {
    role: 'DNA_SCHEDULE'
  }
},
  {
    path: 'volunteers/:idVolunteer/:hub',
    component: VolunteerDetailComponent,
    canActivate: [AuthGuardService],
    children: [{
      path: 'generalInformation',
      component: GeneralInformationVolunteerComponent
    },
    {
      path: 'generalInformation/:bridgeNumber',
      component: GeneralInformationVolunteerComponent
    },
    {
      path: 'ficheCaracterisation/:idFiche',
      component: FicheCaracterisationComponent
    },
    {
      path: 'ficheCaracterisation/:idFiche/bridge/:bridgeNumber',
      component: FicheCaracterisationComponent
    },
    {
      path: 'ficheRemanence/:idFiche',
      component: FicheRemanenceComponent
    },
    {
      path: 'ficheRemanence/:idFiche/bridge/:bridgeNumber',
      component: FicheRemanenceComponent
    },
    {
      path: 'ficheRemanence/:idFiche/edit',
      component: FicheRemanenceComponent
    },
    {
      path: 'ficheRemanence/:idFiche/edit/bridge/:bridgeNumber',
      component: FicheRemanenceComponent
    },
    {
      path: 'ficheEntretien/:idFiche',
      component: FicheEntretienComponent
    },
    {
      path: 'ficheEntretien/:idFiche/bridge/:bridgeNumber',
      component: FicheEntretienComponent
    },
    {
      path: 'ficheEntretien/:idFiche/edit',
      component: FicheEntretienComponent
    },
    {
      path: 'ficheEntretien/:idFiche/edit/bridge/:bridgeNumber',
      component: FicheEntretienComponent
    }]
  }
];

@NgModule({
  imports: [
    SharedModule,
    NgxTimelineModule,
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    CreateModalComponent
  ],
  declarations: [
    VolunteersComponent,
    FicheRemanenceComponent,
    FicheCaracterisationComponent,
    FicheEntretienComponent,
    CreateModalComponent,
    VolunteerDetailComponent,
    EditModalComponent,
    GeneralInformationVolunteerComponent,
    CreateListModalComponent,
    VolunteerRowComponent,
    PanelistRowComponent,
    PanelistArcsRowComponent,
    GroupPanelistHARowComponent,
    EditGroupHorsArcsRowComponent
  ],
  providers: [],
})
export class VolunteersModule {
}
