<div class="modal-scroll">
  <div class="modal-header">
    <h3 class="text-center" translate>CONFORT_DESACTIVATE_TIME</h3>
  </div>
  <div class="modal-body form-group">
    <ngx-loading [show]="showSpinner"></ngx-loading>
    <div class="table-responsive">
      <table class="table table-hover table-borderless-th dataTable">
        <thead>
          <tr>
            <th translate>CONFORT_TIME</th>
            <th translate>WORKFLOW</th>
            <th translate>IS_ACTIVE</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of confortComponents">
            <td>
              <dna-translate [fieldToTranslate]="data.timeLabel"> </dna-translate>
            </td>
            <td>
              <dna-translate [fieldToTranslate]="data.nameWorkflow"> </dna-translate>
            </td>
            <td>
              <dna-switch [isSelected]="confortTimeDisplay[data.time]"
                (onBooleanChanged)="confortTimeDisplay[data.time] = !confortTimeDisplay[data.time]"></dna-switch>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button id="save" class="btn btn-primary mb-4" (click)="save()" translate="SAVE"></button>
  <button id="cancel" class="btn btn-default mb-4" (click)="cancel()" translate="CANCEL"></button>
</div>
