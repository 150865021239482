<div class="modal-header">
    <h4 class="modal-title" translate> {{ title }}</h4>
    <button class="close" type="button" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true" class="fa fa-times"></span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table">
        <thead>
          <tr>
            <th scope="col" translate>VOLUNTEER</th> 
            <th scope="col" translate>BLOCK_NAME</th>
            <th scope="col" translate>ANSWER</th>
            <th scope="col" translate>MESSAGE_TITLE</th>
          </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let error of errorsTab">
                <tr>
                    <td>{{ error.userName }}</td>
                    <td translate>{{ error.blockName[currentLanguage] || error.blockName.english || '-' }}</td>
                    <td translate>{{ error.answerName[currentLanguage] || error.answerName.english || '-' }}</td>
                    <td translate>{{ error.reason }}</td>
                </tr>
            </ng-container>
        </tbody>
      </table>
  </div>
  <!-- <div class="modal-footer">
    <button class="btn btn-primary" type="submit" (click)="validate()">{{ "VALIDATE" | translate }}</button>
  </div> -->
  