import { BubbleLegendFormatterContextObject, FormatterCallbackFunction, GradientColorObject, PatternObject, TooltipFormatterContextObject } from 'highcharts';
import { Translatable } from '../../../../../../../../../types'
import { Chart } from '../../chart.model';
import { BubbleOptionsModelInputInterface } from '../bubble-options.model';

// INPUT INTERFACE

export interface BubbleInputInterface {
    data: BubbleDataInterface[];
    axis: BubbleAxisInterface;
}

interface BubbleAxisItemInterface {
    name: Translatable;
};

interface BubbleAxisItemWithDoubleLabelInterface extends BubbleAxisItemInterface {
    labels: [Translatable, Translatable];
}

export interface BubbleDataInterface {
    name: string;
    data: {
        x: number;
        y: number;
        c?: number | string;
        z?: number;
    }
}
export interface BubbleAxisInterface {
    x: BubbleAxisItemWithDoubleLabelInterface;
    y: BubbleAxisItemWithDoubleLabelInterface;
    c?: BubbleAxisItemInterface | BubbleAxisItemInterface[];
    z?: BubbleAxisItemInterface | BubbleAxisItemInterface[];
}

// HELPER INTERFACE
interface CustomBubblePoint extends Highcharts.Point {
    z: number;
    colorValue: number;
    custom: BubbleDataInterface
}

interface CustomTooltipInterface extends TooltipFormatterContextObject {
    point: CustomBubblePoint
}

interface CreateDataOutputHelper {
    serie: Highcharts.SeriesBubbleOptions;
    gotNoCategoryColor: boolean;
    gotNoCategorySize: boolean;
    colorRange: BubbleRangeHelperInterface;
    sizeRange: BubbleRangeHelperInterface;
}

interface BubbleLegendInterfaceHelper {
    ranges: Highcharts.LegendBubbleLegendRangesOptions[];
    formatter: FormatterCallbackFunction<BubbleLegendFormatterContextObject>
}

interface BubbleRangeHelperInterface {
    max: number;
    min: number;
}

// OUTPUT INTERFACE

interface OutputAxisInterface {
    x: Highcharts.XAxisPlotLinesOptions[];
    y: Highcharts.YAxisPlotLinesOptions[];
}

interface OutputData {
    serie: Highcharts.SeriesBubbleOptions;
    axis: OutputAxisInterface;
    colorAxis: Highcharts.ColorAxisOptions;
    tooltipFormatter: Highcharts.TooltipFormatterCallbackFunction;
    withSizeAxis: boolean;
    bubbleLegendsRanges: Highcharts.LegendBubbleLegendRangesOptions[];
    bubbleLabelFormatter: FormatterCallbackFunction<BubbleLegendFormatterContextObject>;
}

// CONSTANT
enum CONSTANT {
    maxSize = 6,
    minSize = 0,
    minBubbleSize = 10,
    maxBubbleSize = 50,
}

const noCategory: Translatable = {
    english: "No category ",
    french: "No category fr",
    chinese: "No category CN",
    japanese: "No category JP",
    portuguese: "No category Pr",
    spanish: "No category Sp"
};

const getCategoryAxisValue = (value: number, colorAxis: BubbleAxisItemInterface | BubbleAxisItemInterface[]): number => {
    if (Array.isArray(colorAxis)) {
        const currentValue = colorAxis[value];
        if(currentValue) {
            return value;
        }
        return null;
    }
    return value;
};

const createHighchartDataOptions = (data: BubbleDataInterface[], axis: BubbleAxisInterface, lang: string): CreateDataOutputHelper => {
    const withSizeAxis: boolean = axis.z ? true : false;
    const withColorAxis: boolean = axis.c ? true : false;

    const colorRange: BubbleRangeHelperInterface = {
        max: null,
        min: null,
    };
    const sizeRange: BubbleRangeHelperInterface = {
        max: null,
        min: null
    };

    let gotNoCategoryColor: boolean = false;
    let gotNoCategorySize: boolean = false;

    const serie: Highcharts.SeriesBubbleOptions = {
        type: "bubble",
        showInLegend: false,
        sizeBy: "area",
        minSize: withSizeAxis ? CONSTANT.minBubbleSize : 3,
        maxSize: withSizeAxis ? CONSTANT.maxBubbleSize : 3,
        colorKey: "colorValue",
        data: data.map(d => {
            let colorValue = getCategoryAxisValue(Number(d.data.c), axis.c);
            if (colorValue === null) {
                gotNoCategoryColor = true;
                colorValue = (<BubbleAxisItemInterface[]>axis.c).length;
            }
            colorRange.max = colorRange.max === null ? colorValue : Math.max(colorRange.max, colorValue);
            colorRange.min = colorRange.min === null ? colorValue : Math.min(colorRange.min, colorValue);
            
            let sizeValue = getCategoryAxisValue(Number(d.data.z), axis.z);
            if (sizeValue === null) {
                gotNoCategorySize = true;
                sizeValue = (<BubbleAxisItemInterface[]>axis.z).length;
            }

            sizeRange.max = sizeRange.max === null ? sizeValue : Math.max(sizeRange.max, sizeValue);
            sizeRange.min = sizeRange.min === null ? sizeValue : Math.min(sizeRange.min, sizeValue);

            return {
                name: d.name,
                x: d.data.x || 0,
                y: d.data.y || 0,
                z: withSizeAxis ? sizeValue : 3,
                colorValue: withColorAxis ? colorValue : 0,
                // original values are stocked here
                custom: d,
                dataLabels: {
                    enabled: false,
                    format: "{point.name}"
                }
            }
        }),
    };

    return {
        serie,
        gotNoCategoryColor,
        gotNoCategorySize,
        colorRange,
        sizeRange
    };
}

const createPlotLines = (axis: BubbleAxisInterface, lang: string): OutputAxisInterface => {
    // XAxis
    const topLabel: Highcharts.XAxisPlotLinesOptions = {
        label: {
            text: Chart.getObjectValueTranslation(axis.y.labels[0], lang),
            verticalAlign: "top",
            rotation: 0,
            y: 20,
        },
        value: 0,
        width: 2,
        dashStyle: "Dash"
    };

    const bottomLabel: Highcharts.XAxisPlotLinesOptions = {
        label: {
            text: Chart.getObjectValueTranslation(axis.y.labels[1], lang),
            verticalAlign: "bottom",
            rotation: 0,
            y: -20
        },
        value: 0,
        width: 2,
        dashStyle: "Dash"
    };

    const xAxisPlotLines: Highcharts.XAxisPlotLinesOptions[] = [topLabel, bottomLabel];

    // YAxis
    const leftLabel: Highcharts.YAxisPlotLinesOptions = {
        label: {
            text: Chart.getObjectValueTranslation(axis.x.labels[0], lang),
            align: "left",
            rotation: 0,
            y: -10
        },
        value: 0,
        width: 2,
        dashStyle: "Dash"
    };
    const rightLabel: Highcharts.YAxisPlotLinesOptions = {
        label: {
            text: Chart.getObjectValueTranslation(axis.x.labels[1], lang),
            align: "right",
            rotation: 0,
            x: -10,
            y: -10
        },
        value: 0,
        width: 2,
        dashStyle: "Dash"
    }

    const yAxisPlotLines: Highcharts.YAxisPlotLinesOptions[] = [leftLabel, rightLabel];

    return {
        x: xAxisPlotLines,
        y: yAxisPlotLines
    };
}

const getOriginalValue = (value: string | number) => {
    if (value === 0) return value;
    if (!value) return 'nc';
    if (value === "") return 'nc';
    return value;
}

const getAxisLabel = (axis: BubbleAxisItemInterface | BubbleAxisItemInterface[], value: number, lang: string): string => {
    if(Array.isArray(axis)) {
        const tempCategory = axis[value];
        if(tempCategory) return Chart.getObjectValueTranslation(tempCategory.name, lang);
        return Chart.getObjectValueTranslation(noCategory, lang);
    }
    return Chart.getObjectValueTranslation(axis.name, lang);
}

const createTooltipFormatter = (axis: BubbleAxisInterface, lang: string, serie: Highcharts.SeriesBubbleOptions, routines: BubbleOptionsModelInputInterface["routines"]): Highcharts.TooltipFormatterCallbackFunction => {
    // Create shared tooltip when on same point
    const data = serie.data;
    // X Y Mapping
    const mapping: Map<number, Map<number, CustomBubblePoint[]>> = new Map();
    let inited: boolean = false;

    // MANDATORY
    const xAxisLabel: string = Chart.getObjectValueTranslation(axis.x.name, lang);
    const yAxisLabel: string = Chart.getObjectValueTranslation(axis.y.name, lang);

    // OPTIONAL
    const withColor: boolean = axis.c ? true : false;
    const withSize: boolean = axis.z ? true : false;

    return function (this: CustomTooltipInterface) {

        if(!inited) {
            this.point.series.data.map((d: CustomBubblePoint) => {
                const x = d.x;
                const y = d.y;
        
                let xMapping = mapping.get(x);
                if(!xMapping) {
                    xMapping = new Map();
                    mapping.set(x, xMapping);
                }
        
                let yMapping = xMapping.get(y);
                if(!yMapping) {
                    yMapping = [];
                    xMapping.set(y, yMapping);
                }
        
                yMapping.push(d);
            })
            inited = true;
        }


        if (!this.point) return false;
        const physicalX = this.point.x;
        const physicalY = this.point.y;

        const pointsInPositions = mapping.get(physicalX).get(physicalY);

        // Get routines colors
        const routineMapping: Map<string, {
            name: string,
            color: string
        }> = new Map();

        routines.forEach(r => {
            routineMapping.set(r.name, r);
        });

        const getColor = (name: string) => {
            return routineMapping.get(name).color;
        }

        const body = `
            <table style="border-spacing: 5px 0; border-collapse: separate">
                <thead>
                    <tr>
                        <th style="padding: 0 0.5rem"></th>
                        ${pointsInPositions.map(p => `<th style="text-align:right; color: ${getColor(p.name)}">${p.name}</th>`).join("")}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="font-weight: bold">${xAxisLabel}</td>
                        ${pointsInPositions.map(p => `<td style="text-align:right; color: ${getColor(p.name)}">${getOriginalValue(p.custom.data.x)}</td>`).join("")}
                    </tr>
                    <tr>
                        <td style="font-weight: bold">${yAxisLabel}</td>
                        ${pointsInPositions.map(p => `<td style="text-align:right; color: ${getColor(p.name)}">${getOriginalValue(p.custom.data.y)}</td>`).join("")}
                    </tr>
                    ${withColor && `
                        <tr>
                            ${Array.isArray(axis.c) ? `
                                    <td></td>
                                    ${pointsInPositions.map(p => `<td style="text-align:right; color: ${getColor(p.name)}">${getAxisLabel(axis.c, this.point.colorValue, lang)}</td>`)}
                                ` : `
                                    <td style="font-weight: bold">${getAxisLabel(axis.c, this.point.colorValue, lang)}</td>
                                    ${pointsInPositions.map(p => `<td style="text-align:right; color: ${getColor(p.name)}">${getOriginalValue(p.custom.data.c)}</td>`).join("")}
                                `}
                        </tr>
                    `}
                    ${withSize && `
                        <tr>
                            ${Array.isArray(axis.z) ? `
                                    <td></td>
                                    ${pointsInPositions.map(p => `<td style="text-align:right; color: ${getColor(p.name)}">${getAxisLabel(axis.z, this.point.z, lang)}</td>`)}
                                `
                                : `
                                    <td style="font-weight: bold">${getAxisLabel(axis.z, this.point.z, lang)}</td>
                                    ${pointsInPositions.map(p => `<td style="text-align:right; color: ${getColor(p.name)}">${getOriginalValue(p.custom.data.z)}</td>`).join("")}
                                `}
                        </tr>
                    `}
                </tbody>
            </table>`;
        return body;
    }
}

const createColorAxis = (axis: BubbleAxisInterface, lang: string, gotNoCategoryColor: boolean, colorRange : BubbleRangeHelperInterface): Highcharts.ColorAxisOptions => {
    const dataClasses: Highcharts.ColorAxisDataClassesOptions[] = [];
    const isArray = Array.isArray(axis.c);

    if(isArray) {
        (<BubbleAxisItemInterface[]>axis.c).forEach((c, i) => {
            dataClasses.push({
                from: i,
                to: i,
                name: Chart.getObjectValueTranslation(c.name, lang),
            });
        });
        if(gotNoCategoryColor) {
            const noCategoryIndex = dataClasses.length;
            dataClasses.push({
                from: noCategoryIndex,
                to: noCategoryIndex,
                name: Chart.getObjectValueTranslation(noCategory, lang),
            })
        }
    }

    const formatter: Highcharts.ColorAxisLabelsOptions["formatter"] = isArray 
        ? null
        : function() {
            if (this.isFirst) {
                return `
                    ${this.value}</br><span style="color: black; overflow: visible; font-weight: bold; position: absolute">${Chart.getObjectValueTranslation((<BubbleAxisItemInterface>axis.c).name, lang)}<span>
                `;
            } 
            let label = this.value.toString();
            return label;
        }
    return {
        showInLegend: axis.c ? true : false,
        min: (axis.c && !isArray) ? colorRange.min : 0,
        max: (axis.c && !isArray) ? colorRange.max : 0,
        maxColor: "red",
        minColor: "green",
        dataClasses: isArray ? dataClasses : null,
        labels: {
            useHTML: true,
            align: "left",
            style: {
                textOverflow: "visible"
            },
            formatter,
        }
    };
}

const createBubbleLegends = (axis: BubbleAxisInterface, lang: string, gotNoCategorySize: boolean, sizeRange : BubbleRangeHelperInterface): BubbleLegendInterfaceHelper => {
    if (!axis.z) return null;
    const isArray = Array.isArray(axis.z);

    const ranges: Highcharts.LegendBubbleLegendRangesOptions[] = [];

    if (isArray) {
        (<BubbleAxisItemInterface[]>axis.z).forEach((z, index) => {
            ranges.push({
                value: index,
            });
        });
        if(gotNoCategorySize) {
            ranges.push({
                value: ranges.length
            });
        }
    }

    const formatter: FormatterCallbackFunction<BubbleLegendFormatterContextObject> = isArray 
        ? function() {
            const currentCategory = (<BubbleAxisItemInterface[]>axis.z)[this.value];
            if(currentCategory) {
                return Chart.getObjectValueTranslation(currentCategory.name, lang);
            }
            return Chart.getObjectValueTranslation(noCategory, lang);
        }
        : function() {
            if (this.value === sizeRange.max) {
                return `${this.value} <span style="font-weight:bold">${Chart.getObjectValueTranslation((<BubbleAxisItemInterface>axis.z).name, lang)}</span>`;
            }
            return this.value.toString();
        };

    return {
        ranges,
        formatter
    }
}

type ColorScale = string | GradientColorObject | PatternObject;
const applyColors = (routineObject: any, colorScale: ColorScale[]): void => {
    if(!routineObject) return;
    const benchColor = colorScale.shift();
    let benchFound: boolean = false;
    let colorIndex = 0;
    routineObject.routines.forEach(r => {
        if(!benchFound && r.isBench) {
            r.color = benchColor;
            benchFound = true;
        } else {
            r.color = colorScale[colorIndex++];
        }
    });
}

const doWork = (data: BubbleDataInterface[], axis: BubbleAxisInterface, routineObject: any, lang: string): OutputData => {
    const outputAxis = createPlotLines(axis, lang);
    const { serie, gotNoCategoryColor, gotNoCategorySize, colorRange: bubbleColorRange, sizeRange } = createHighchartDataOptions(data, axis, lang);
    const tooltipFormatter = createTooltipFormatter(axis, lang, serie, routineObject.routines);
    const colorAxis = createColorAxis(axis, lang, gotNoCategoryColor, bubbleColorRange);
    const withSizeAxis: boolean = axis.z ? true : false;
    const { ranges: bubbleLegendsRanges, formatter: bubbleLabelFormatter} = createBubbleLegends(axis, lang, gotNoCategorySize, sizeRange);

    applyColors(routineObject, [...Chart.colorScale]);

    return {
        serie,
        axis: outputAxis,
        colorAxis,
        tooltipFormatter,
        withSizeAxis,
        bubbleLabelFormatter,
        bubbleLegendsRanges
    };
}

export default doWork;