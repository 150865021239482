<div class="dna-container">
    <ngx-loading [show]="showSpinner"></ngx-loading>
</div>
<div
    class="dna-container dna-container-row"
    >
    <div
        *ngIf="this.report && !this.report.length"
        [class.dna-message-banner--error]="this.reportError"
        dna-message-banner
        translate
    >
        {{ this.reportError ? this.reportError.error.message : 'VIZUALIZATIONS.BOARD.EMPTY' }}
    </div>
    <div *ngFor="let elt of this.report; trackBy: this.trackByFunction">
        <div class="dna-dashboard--chart-identifier" *ngIf=" elt?.identifier ">
            <span translate>VIZUALIZATIONS.BOARD.CHART</span> #{{ elt.identifier }}
            <dna-checkbox class="float-end" [isChecked]="elt.toCapture" [disabled]="false" [text]="capture" ngbTooltip="{{ ( elt.toCapture ? 'REMOVE_TO_CAPTURES' : 'ADD_TO_CAPTURES') | translate }}"
            placement="left" (onBooleanChanged)="onChangeGraphToCapture(elt)"></dna-checkbox>
        </div>
        <div
            dna-chart-tile
            *ngIf               = "elt && elt.chart"
            [element]           = "elt"
            [onImageLoad]       = "this.onImageLoad"
            [lang]              = "this.lang"
            (_onExcelDownload)  = "this._onExcelDownload($event)"
            (_onExporting)      = "this._onExporting"
            (_onMultipleCaptures) = "this.onMultipleCaptures($event)"
            (saveChartOptions)    = "saveChartOptions($event)">
        </div>
        <div
            dna-chart-tile-skeleton
            [error]="elt?.error || elt?.chart?.error"
            *ngIf="(!elt || !elt.chart) && (!elt?.error || !elt?.chart?.error)">
        </div>
    </div

    >

    <div
        *ngIf="!this.reportError"
        [ngClass]   = "{
            'dna-filters-container--collapsed' : this.filterCollapsed,
            'dna-filters-container--expanded'  : !this.filterCollapsed
        }"
        dna-filters
        [filters]   = "this.filters"
        [lang]      = "this.lang"
        [loading]   = "this.filtersLoading"
        (onSubmit)  = "this.onFiltersUpdate($event)"
        (onCollapse)= "this._onCollapse($event)"
    ></div>

    <dna-menu
        [ngClass]   = "{
            'dna-menu-container--collapsed' : this.menuCollapsed,
            'dna-menu-container--expanded'  : !this.menuCollapsed
        }"
        (onCollapse)= "this._onCollapseMenu($event)"
    ></dna-menu>
</div>
