<div class="modal-scroll">
  <div class="modal-header">
    <h3 class="text-center">{{'MANAGE_RANDOMISATION_FORMULA' | translate}}</h3>
  </div>
  <div class="modal-body">
    <ngx-loading [show]="showSpinner"></ngx-loading>
    <table class="table col-12">
      <th translate="VOLUNTEER"></th>
      <th translate="FORMULAS"></th>
      <th>{{ 'EVALUATION_SIDE' | translate }}</th>
      <tbody>
      <tr *ngFor="let v of volunteers">
        <td>{{ v.userName }}</td>
        <td>{{ v.formula }}</td>
        <td class="d-flex flex-row">
          <span class="col-6">{{ 'EVALUATION_SIDE' | translate }}: {{ v.lab }}</span>
          <select class="col-6" disabled="{{ v.isStarted }}" [(ngModel)]="v.side"
                  (ngModelChange)="changeSide(v)" name="side">
            <ng-container *ngIf="v.side === 'NONE'">
              <option value="NONE" translate=""></option>
            </ng-container>
            <option value="LEFT" [translate]="'LEFT'"></option>
            <option value="RIGHT" [translate]="'RIGHT'"></option>
          </select>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button id="save" class="btn btn-primary mb-4" (click)="save()" translate="SAVE"></button>
  <button id="cancel" class="btn btn-default mb-4" (click)="cancel()" translate="CANCEL"></button>
</div>
