  import {
    BlockItem,
    DNAComponent,
    LinkRadioVersus,
    Workflow,
    OperatorType
  } from './../../../types';
  import {
    Block
  } from '../../../types';
  import {
    Component,
    Input,
    OnInit
  } from '@angular/core';
  import * as _ from 'lodash';
  import { ReferenceTypeService } from '../../../shared/services/reference-type.service';
  
  @Component({
    selector: 'dna-multiple-choice-link-config',
    templateUrl: './multipleChoiceLinkConfiguration.component.html'
  })
  
  export class MultipleChoiceLinkConfigurationComponent implements OnInit {
    @Input() component: DNAComponent;
    @Input() workflow: Workflow;
    radioVersus: {
      link: LinkRadioVersus,
      title: string
    }[];
    operatorTypes: string[];
  
    constructor(
      private referenceTypeService: ReferenceTypeService
    ) {}
  
    ngOnInit() {
      this.operatorTypes = this.referenceTypeService.getOperatorType();
      if(_.get(this.component,'args.linkRadioVersus.operator') === '+') {
        this.component.args.linkRadioVersus.operator = OperatorType.INDEX_PLUS_SCALE_SCORE;
      }
      if(_.get(this.component,'args.linkRadioVersus.operator') === '-') {
        this.component.args.linkRadioVersus.operator = OperatorType.INDEX_MOINS_SCALE_SCORE;
      }
      if (this.isLinkableToRadioVersus(this.component)) {
        this.radioVersus = this.workflow.blocks.reduce((blocks: any, block: Block) => {
          let components = block.components.filter(c => c.type === 'dna-multiple-choice');
          components.map(c => blocks.push({
            link: new LinkRadioVersus(block.idInQuestionnaire, c.idInBlock),
            title: block.name.english + " - " + c.args.label.english
          }));
          return blocks;
        }, [])
      }
    }
  
    linkRadioVersusCompare(a:LinkRadioVersus, b:LinkRadioVersus) {
        return a && b && a.idInBlock === b.idInBlock && a.idInQuestionnaire === b.idInQuestionnaire;
    }
  
    isLinkableToRadioVersus(component: DNAComponent) {
      return component.type === 'dna-multiple-choice';
    }
  
  }
  