
import * as _ from 'lodash';
import {finalize, tap, mergeMap } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  Input
} from '@angular/core';

import {
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import { Campaign } from '../../../../../types';
import { CampaignService } from '../../../../campaigns.service';
import { ErrorManagerService } from '../../../../../shared/services/errorManager.service';
import { ApplicationInsightsService } from '../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-import-excel-modal',
  templateUrl: './import-excel-modal.component.html'
})
export class ImportExcelModalComponent implements AfterViewInit{

  @Input() campaignId: string;

  file: any;
  showSpinner: boolean = false;
  initTime = performance.now();

  constructor(
    public activeModal: NgbActiveModal,
    private aiService: ApplicationInsightsService,
    private campaignService: CampaignService,
    private errorManager: ErrorManagerService
  ) { }

  cancel() {
    this.activeModal.dismiss();
  }

  ngAfterViewInit() {
    this.aiService.logPageView('Import Excel Modal', '', performance.now() - this.initTime, 'Modals');
  }

  onFileChange(event: any) {
    if (!_.isEmpty(this.campaignId)) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.file = {
          filename: file.name,
          filetype: file.type,
          value: (<string>reader.result).split(',')[1]
        }
      }
    } else {
      this.file = event;
    }

  }

  async save() {
    if (!_.isEmpty(this.campaignId)) {
      this.showSpinner = true;
      let errorsInImport;
      await this.campaignService.importExcelEvaluation(this.campaignId, this.file).pipe(
        tap(errors => errorsInImport = errors),
        finalize(() => this.showSpinner = false)
      ).subscribe(
        () => {
          this.errorManager.displayMessage('IMPORT_SUCCESSFUL');
          this.activeModal.close(errorsInImport.list);
        },
        err => this.errorManager.catchError(err));
      } else {
        this.activeModal.close(this.file)
      }

  }

}
