import { Component, OnInit } from '@angular/core';
import { environment } from '@env';
import packageInfo from '../../../../package.json';

@Component({
    selector: 'copyright',
    templateUrl: './copyright.component.html',
    styleUrls: ['./copyright.component.less']
})

export class CopyrightComponent implements OnInit {
    copyrightVersion: string;

    constructor() { }

    ngOnInit() {
        this.copyrightVersion = packageInfo.version;
        if (environment.name() !== 'production') {
            this.copyrightVersion += ' - ' + environment.name();
        }
    }
}
