import { ChangeDetectionStrategy, Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'dna-filter-date',
    templateUrl: './dnaFilterDate.component.html',
    styleUrls: ['./dnaFilterDate.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class FilterDateComponent implements OnInit {

    @Input() dateFilterStart: any;
    @Input() dateFilterEnd: any;
    @Output() updateDateStart = new EventEmitter();
    @Output() updateDateEnd = new EventEmitter();

    constructor() { }

    ngOnInit() { }

    ngOnChanges() {
        if (this.dateFilterStart) {
            this.dateFilterStart = {
                year: this.dateFilterStart.getFullYear(),
                month: this.dateFilterStart.getMonth() + 1,
                day: this.dateFilterStart.getDate()
            }
        }

        if (this.dateFilterEnd) {
            this.dateFilterEnd = {
                year: this.dateFilterEnd.getFullYear(),
                month: this.dateFilterEnd.getMonth() + 1,
                day: this.dateFilterEnd.getDate()
            }
        }
    }

    clickDateStart() {
        if (this.dateFilterStart) {
            const stringStart = this.dateFilterStart.year + '-' + this.dateFilterStart.month + '-' + this.dateFilterStart.day;
            const dateStart = new Date(stringStart);
            this.updateDateStart.emit(dateStart);
        } else {
            this.updateDateStart.emit(null);
        }
    }

    clickDateEnd() {
        if (this.dateFilterEnd) {
            const stringEnd = this.dateFilterEnd.year + '-' + this.dateFilterEnd.month + '-' + this.dateFilterEnd.day;
            const dateEnd = new Date(stringEnd);
            this.updateDateEnd.emit(dateEnd);
        } else {
            this.updateDateEnd.emit(null);
        }
    }
}
