	<div class="date">
		<span class="datestart">{{textLeft | date : 'd MMM. yyyy' | uppercase}}</span>
		<span class="dateseparator">{{textSeparator}}</span>
		<span class="datestart">{{ (textRight | date : 'd MMM. yyyy' | uppercase) || subtitleEmpty}}</span>
	</div>
	<div class="name">
		<span>{{ title }}</span>
	</div>
	<div class="description">
		<span>{{ subtitle || subtitleEmpty }}</span>
	</div>
