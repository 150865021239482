
import {forkJoin as observableForkJoin,  Observable, Subscription } from 'rxjs';

import {mergeMap, tap} from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import * as _ from 'lodash';

import { AttributeDetails, TargetHair } from '../../../../types';
import { DNATranslateService } from '../../../services/translate.service';
import { FilterService } from '../../../services/filter.service';
import { ReferenceTypeService } from '../../../services/reference-type.service';

@Component({
  selector: 'dna-filters-target-hair',
  templateUrl: './filters-target-hair.component.html',
  styleUrls: ['./filters-target-hair.component.less']
})
export class FiltersTargetHairComponent implements OnInit, OnChanges, OnDestroy {

  @Input() filter: any;

  @Output() onUpdateFilter: EventEmitter<any> = new EventEmitter();

  subscriptions: Subscription[] = [];
  target: TargetHair = new TargetHair();

  constructor(
    private DNATranslateService: DNATranslateService,
    private filterService: FilterService,
    private referenceTypeService: ReferenceTypeService
  ) { }

  ngOnInit() {
    this.setFiltersWithTargetObjects();

    this.subscriptions.push(
      this.DNATranslateService.onLangChange().pipe(
        tap(() => this.setFiltersWithTargetObjects()))
        .subscribe()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterTarget && changes.filterTarget.currentValue) {
      this.filter = changes.filterTarget.currentValue;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  addItem(filter, item, key: string) {
    filter[key].push(item);
    this.updateCampaignsList(filter);
  }

  removeItem(filter, item, key: string) {
    _.remove(filter[key], i => i.Id === item.Id);
    this.updateCampaignsList(filter);
  }

  setFiltersWithTargetObjects() {
    let observables = [];
    _.forOwn(new TargetHair(), (value, key: string) => {
      if (key !== "type") {
        observables.push(this.referenceTypeService.dataTranslations(value, "Text", "value").pipe(
          tap(translation => this.target[key] = translation),
          mergeMap(() => this.translateFilterObject(this.filter[key])),
          tap(translation => this.filter[key] = translation),));
      }
    });

    observableForkJoin(...observables).subscribe();
  }

  private translateFilterObject(object: AttributeDetails[]): Observable<AttributeDetails[]> {
    return this.referenceTypeService.dataTranslations(object, "Text", "value");
  }

  updateCampaignsList(filter: any) {
    this.onUpdateFilter.emit(filter);
  }

}
