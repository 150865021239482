import { Chart } from "../chart.model";
import { SpiderOptions } from './spider-options.model';
import { TranslateService } from '@ngx-translate/core';
import * as SpiderChartLabelFormatter from './spider.label.formatter';
import * as SpiderChartTooltipFormatter from './spider.tooltip.formatter';
import { Languages, COMPUTING_METHODS } from "../../enums";
const mapSettingsKey: Map<string, string> = new Map();

import * as SpiderChartMedianCompute from './methods/median.method';
import * as SpiderChartDrilledCompute from './methods/drilled.method';

import { Descriptor } from "../../../../../../../../types";
import { Subject } from "rxjs";
import { Table } from "../table";

mapSettingsKey.set('scale', 'yAxis');
mapSettingsKey.set('visibilityThreshold', 'visibilityThreshold');

/**
 * Force labels to be shown
 * @param {Highcharts.Chart} el
 * @return void
 */
export class Spider extends Chart {
  protected _baseParameters: Highcharts.Options = {
    chart: {
      type: "line",
      polar: true,
      events: {
        load:  (el: any) => {
          this.linkRoutineToSeries(el.target);
          this.parameters.series.forEach((serie: any) => {
            serie.color = el.target.series.find((x: any) => x.name === serie.name).color;
          });
          this.updateChartTranslations(el.target, this.parameters, this.lang);
          this.buildGraphOptions(this.parameters);
          this.onOptionChangeAfterLoadSubject.next(this.options);
        }
      }
    },
    boost: {
      enabled: false,
    },
    xAxis: {
      tickmarkPlacement: 'on',
      lineWidth: 0,
      crosshair: true,
      labels: {
        staggerLines: 1,
        distance: 22,
        style: {
          width: 50
        },
        allowOverlap: true,
        useHTML: true,
        formatter: function (el: any) {
          const { userOptions } = el.chart;
          let langs: Array<string> = [this.lang];
          if (userOptions.hasOwnProperty('plotOptions') && userOptions.plotOptions.hasOwnProperty('default_language')) {
            if (userOptions.plotOptions.default_language.enabled && langs.indexOf(Languages.Default) === -1) langs.push(Languages.Default);
          }
          if (userOptions.hasOwnProperty('plotOptions') && userOptions.plotOptions.hasOwnProperty('attributes_blocks')) return SpiderChartLabelFormatter.formatter(el, langs, userOptions.plotOptions.attributes_blocks.enabled);
          else return SpiderChartLabelFormatter.formatter(el, langs, false);

        }.bind(this),
      }
    },
    plotOptions: {
      series: {
        pointPlacement: "on",
        turboThreshold: 0,
        dataLabels: {
          enabled: false,
          color: 'black',
        }
      }
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      tickInterval: 1,
      showLastLabel: true,
      labels: {
        allowOverlap: true,
        y: 5
      }
    },
    legend: {
      margin: 30
    },
    tooltip: {
      enabled: false,
      useHTML: true,
      formatter: function (el: any) {
        const { userOptions } = el.chart;
        return SpiderChartTooltipFormatter.formatter(
          this,
          userOptions.language,
          userOptions.hasOwnProperty('plotOptions') && userOptions.plotOptions.hasOwnProperty('attributes_blocks') && userOptions.plotOptions.attributes_blocks.enabled
        );
      },
    }
  }

  private additionnals = [{
    type: "area",
    name: "VIZUALIZATIONS.SPIDER.BARE_SKIN",
    meaning: "BARE_SKIN",
    translationKey: "VIZUALIZATIONS.SPIDER.BARE_SKIN",
    fillColor: "rgb(211,211,211, 0.3)",
    color: "#D3D3D3",
    threshold: -6,
    zIndex: -1,
    selected: false,
    data: [],
    value: 0,
    marker: {
      enabled: false
    },
  },
  {
    type: "line",
    meaning: "VISIBILITY_THRESHOLD",
    name: "VIZUALIZATIONS.SPIDER.VISIBILITY_THRESHOLD",
    translationKey: "VIZUALIZATIONS.SPIDER.VISIBILITY_THRESHOLD",
    color: "#808080",
    data: [],
    value: null,
    marker: {
      enabled: false
    },
  }
  ];

  private initialData: any;
  public onDrillSubject = new Subject<string>();
  private drilledState: boolean = false;
  public static mapSettings: Map<string, string> = mapSettingsKey;

  constructor(
    _parameters?: any,
    _data?: Array<any>,
    _lang?: string,
    _filters?: any,
    private _translateService?: TranslateService
  ) {
    super(_parameters, _data, _lang, _filters);
    this.tableRawData = this.formatRawData(_data, _lang);
    this.generateParametersAssignment(this.parameters);
    this.generateDataAssignment(this.data, this.lang);
  };

  private generateParametersAssignment(parameters) {
    Object.assign({ ...this._baseParameters, parameters });
    Object.keys({ ...this._baseParameters }).forEach((k) => {
      this.parameters[k] = Object.assign({ ...this._baseParameters[k] }, parameters[k])
    });
    Object.keys({ ...parameters }).forEach((k) => {
      this.parameters[k] = Object.assign({ ...this.parameters[k] }, parameters[k])
    });
  };

  protected generateDataAssignment = async (data: any, lang: string) => {
    this.data = data;
    if (this.initialData != data) this.initialData = data;
    const method = this.parameters.compute ? this.parameters.compute.method : null;
    const baseKey = this.parameters.compute ? this.parameters.compute.key : 'key';
    const payload = this.formatData(
      this.initialData,
      this.drilledState ? 'drilled' : method,
      baseKey,
      lang,
      this.parameters,
      this.descriptors,
      this.routines
    );

    this.parameters.tooltip.enabled = true;
    if (this.parameters.hasOwnProperty('drilldown') && this.parameters.drilldown.enabled) {
      this.parameters.plotOptions.series.cursor = 'pointer';
      this.parameters.plotOptions.series.events = {
        click: (() => this.handleDrill(this.drilledState)).bind(this)
      };
    }

    this._parameters.xAxis.categories = payload.categories;
    if (this.parameters.hasOwnProperty('plotOptions') && this.parameters.plotOptions.hasOwnProperty('visibilityThreshold') && this.parameters.plotOptions.visibilityThreshold.hasOwnProperty('value')) {
      this.additionnals.find((x: any) => { return x.meaning === 'VISIBILITY_THRESHOLD' }).value = this.parameters.plotOptions.visibilityThreshold.value
    } else {
      this.additionnals.findIndex((x: any) => { return x.meaning === 'VISIBILITY_THRESHOLD' }) >= 0 ? this.additionnals.splice(this.additionnals.findIndex((x: any) => { return x.meaning === 'VISIBILITY_THRESHOLD' }), 1) : '';
    }
    this._parameters.series = [...this.getAdditionnals(), ...payload.series];
    if (this.chart) { this.updateChartTranslations(this.chart, this.parameters, this.lang); }
  };

  private getAdditionnals = () => {
    const categories = this._parameters.xAxis.categories;
    const additionnals = this.additionnals;
    const datas = categories.reduce((datas, category) => {
      datas.bare.push(additionnals.find((x: any) => { return x.meaning === 'BARE_SKIN' }).value);
      datas.tresh.push(additionnals.find((x: any) => { return x.meaning === 'VISIBILITY_THRESHOLD' }).value);
      return datas;
    }, { bare: [], tresh: [] });
    return additionnals.map(x => ({ ...x, data: x.meaning === "BARE_SKIN" ? datas.bare : datas.tresh }));
  }

  protected formatData = (
    data: Array<any>,
    method: string,
    baseKey: string,
    lang: string,
    parameters: any,
    descriptors: Array<Descriptor>,
    routines: Array<any>
  ): { categories: Array<any>, series: Array<any> } => {

    const payload = {
      categories: {},
      series: {}
    };

    const drilledMethod = parameters.compute ? parameters.compute.method : null;

    switch (method) {
      case null:
        break;
      case COMPUTING_METHODS.MEDIAN:
        SpiderChartMedianCompute.doWork(data, baseKey, lang, parameters, descriptors, payload, routines);
        break;
      case COMPUTING_METHODS.DRILLED:
        SpiderChartDrilledCompute.doWork(data, baseKey, lang, parameters, descriptors, drilledMethod, payload, routines)
    };
    return {
      categories: Object.values(payload.categories),
      series: Object.values(payload.series)
    };
  };

  public buildGraphOptions = (options: any) => {
    const { title, subtitle, xAxis, yAxis, series, plotOptions, ...rest } = options;
    this.options = new SpiderOptions({
      general: { title, subtitle },
      series,
      plotOptions
    }, this.onOptionsChange);
  };


  private onOptionsChange = ( options: any ) => {
    const routinesMapping: Map<string, any> = new Map();
    let gotColorChange = false;

    if( this.routines.routines ) {
      this.routines.routines.forEach( r => {
        routinesMapping.set( r.name, r );
      } );
    }


    let fontOptions: any;

    for (let o in options) {
      if (o === 'general') {
        const {font, ...rest} = options[ o ];
        Object.assign( this.parameters, rest );
        fontOptions = font;
      } else {
        for (let e in options[o]) {
          if (Array.isArray(options[o][e])) {
            options[o][e].forEach(x => {
              Object.assign( this.parameters[ e ].find( y => y.name === x.name ), x );
              if( e === 'series' && routinesMapping.has( x.name ) ) {
                gotColorChange = true;
                routinesMapping.get(x.name).color = x.color;
              }
            })
          } else {
            Object.assign(this.parameters[e], options[o][e]);
          }
        }
      }
    }
    const { yAxis, xAxis, ...parameters } = this.parameters;
    Object.assign(this.parameters, { ...parameters });
    Object.assign(this.parameters.yAxis, yAxis);
    Object.assign( this.parameters.xAxis, xAxis );
    
    if( gotColorChange ) this.custom.updateRoutines( [ ...this.routines.routines ] );

    const chart = this.build();
    if( fontOptions ) this.updateChartFont( chart, fontOptions );
    this.updateChartTranslations(this.chart, this.parameters, this.lang);
  }

  protected setParamsToAxis = (params: any, axis: any) => Object.assign(axis, params);

  /**
   * updateChartTranslations
   * Method to manage languages changes
   * @param chart : Highcharts.Chart : Rendered chart
   * @param parameters : any : Chart's parameters
   * @param lang : string 
  */
  private updateChartTranslations = (chart: Highcharts.Chart, parameters: any, lang: string): void => {
    this.updateTitleTranslations(chart, lang, parameters);
    this.updateSubTitleTranslations(chart, lang, parameters);
    this.updateCategoriesTranslations(chart, parameters);
    this.updateSeriesTranslations(chart);
    chart.redraw();
  };

  /**
   * updateCategoriesTranslations
   * Method to update categories' translations
   * @param chart : Highcharts.Chart : Rendered chart
   * @param parameters : any : Chart's parameters
  */
  private updateCategoriesTranslations = (chart: Highcharts.Chart, parameters: any): void => {
    chart.xAxis[0].update({
      categories: parameters.xAxis.categories
    });
  };

  /**
   * updateSeriesTranslations
   * Method to update series' translations
   * @param chart : Highcharts.Chart : Rendered chart
  */
  private updateSeriesTranslations = (chart: Highcharts.Chart): void => {
    this._translateService
      .get(this.additionnals.map(x => x.name))
      .subscribe((res: any) => {
        chart.series
          .forEach((renderedSerie: Highcharts.Series) => {
            const userOptions: any = renderedSerie[ 'userOptions' ];
            const name = res[userOptions.translationKey] || renderedSerie.name;
            renderedSerie.update({
              name,
              type: userOptions.type
            }, false);
          });
      });
  };

  /**
   * handleDrill
   * Method to manage drill up / down on chart data;
   * It delegates logical part to other functions
   * @param isDrilled : boolean : Drilled status
   */
  private handleDrill = (isDrilled: boolean): void => {
    if (isDrilled) this._handleDrillUp();
    else this._handleDrillDown();
  };

  /**
    * _handleDrillDown
    */
  private _handleDrillDown = () => {
    while (this.chart.series.length) {
      this.chart.series[0].remove(false);
    };
    this.chart.redraw();
    this.parameters.series = [];
    const payload = this.formatData(
      this.initialData,
      'drilled',
      this.parameters.compute ? this.parameters.compute.key : 'key',
      this.lang,
      this.parameters,
      this.descriptors,
      this.routines
    );
    this._parameters.xAxis.categories = payload.categories;
    if (this.parameters.hasOwnProperty('plotOptions') && this.parameters.plotOptions.hasOwnProperty('visibilityThreshold') && this.parameters.plotOptions.visibilityThreshold.hasOwnProperty('value')) {
      this.additionnals.find((x: any) => { return x.meaning === 'VISIBILITY_THRESHOLD' }).value = this.parameters.plotOptions.visibilityThreshold.value
    } else {
      this.additionnals.findIndex((x: any) => { return x.meaning === 'VISIBILITY_THRESHOLD' }) >= 0 ? this.additionnals.splice(this.additionnals.findIndex((x: any) => { return x.meaning === 'VISIBILITY_THRESHOLD' }), 1) : '';
    }
    payload.series = [...this.getAdditionnals(), ...payload.series];
    if (this.parameters.hasOwnProperty('plotOptions') && this.parameters.plotOptions.hasOwnProperty('column') && this.parameters.plotOptions.column.hasOwnProperty('stacking')) this.parameters.plotOptions.column.stacking = null;
    
    payload.series.forEach(element => {
      this.chart.addSeries(element, false);
      this.parameters.series.push(element);
    })

    this.updateChartTranslations(this.chart, this.parameters, this.lang); this.drilledState = true;
    this.onDrillSubject.next('drill');
    this.unLinkRoutine();
  };

  /**
  * _handleDrillUp
  */
  private _handleDrillUp = () => {
    while (this.chart.series.length) {
      this.chart.series[0].remove(false);
    };
    this.parameters.series = [];
    const payload = this.formatData(
      this.initialData,
      this.parameters.compute ? this.parameters.compute.method : null,
      this.parameters.compute ? this.parameters.compute.key : 'key',
      this.lang,
      this.parameters,
      this.descriptors,
      this.routines
    );
    this._parameters.xAxis.categories = payload.categories;
    if (this.parameters.hasOwnProperty('plotOptions') && this.parameters.plotOptions.hasOwnProperty('visibilityThreshold') && this.parameters.plotOptions.visibilityThreshold.hasOwnProperty('value')) {
      this.additionnals.find((x: any) => { return x.meaning === 'VISIBILITY_THRESHOLD' }).value = this.parameters.plotOptions.visibilityThreshold.value
    } else {
      this.additionnals.findIndex((x: any) => { return x.meaning === 'VISIBILITY_THRESHOLD' }) >= 0 ? this.additionnals.splice(this.additionnals.findIndex((x: any) => { return x.meaning === 'VISIBILITY_THRESHOLD' }), 1) : '';
    }
    payload.series = [...this.getAdditionnals(), ...payload.series];
    if (this.parameters.hasOwnProperty('plotOptions') && this.parameters.plotOptions.hasOwnProperty('column') && this.parameters.plotOptions.column.hasOwnProperty('stacking')) this.parameters.plotOptions.column.stacking = null;

    payload.series.forEach(element => {
      this.chart.addSeries(element, false);
      (this.parameters.series = (this.parameters.series || [])).push(element);
    });
    this.updateChartTranslations(this.chart, this.parameters, this.lang);
    this.drilledState = false;
    this.onDrillSubject.next('drill');
    this.linkRoutineToSeries(this.chart);
  };

  protected formatRawData = (data: Array<any>, lang: string): { header: Array<any>, body: Array<any> } => {
    try {
      const payload = JSON.parse(JSON.stringify(data['data'])).reduce((accumulator: any, object: any, itemNumber: number) => {
        const payload = object.values.reduce((reducer: any, item: any, index: number) => {
          item.values.reduce((redacc: any, value: any, idx: number) => {
            redacc[value.label] = value;
            return redacc;
          }, reducer);
          return reducer;
        }, {});

        const keyList = Object.keys(payload);
        const oPayload = object.values.reduce((reducer: any, item: any, index: number) => {
          if (item.values.length !== keyList.length) {
            for (let obj of keyList) {
              const foundObject = item.values.find((x: any) => { return x.label === obj });
              if (!foundObject) {
                item.values.push({
                  label: payload[obj].label,
                  value: { label: "", key: "" }
                });
              }
            }
          }

          item.values.sort((a, b) => {
            const labelA = Chart.getObjectValueTranslation(a.label, lang)
            const labelB = Chart.getObjectValueTranslation(b.label, lang)
            return labelA.localeCompare(labelB);
          });
          
          return reducer;
        }, object);
        accumulator.push(oPayload);
        return accumulator;
      }, []);

      data['data'] = Object.values(payload.reduce((accumulator: any, object: any, itemNumber: number) => {
        if (object.attribute.hasOwnProperty('blockName')) {
          const key = object.attribute.blockName.english;
          accumulator[key] = accumulator[key] || { "attribute": { "label": object.attribute.blockName }, "values": [] };
          accumulator[key].values.push(object);
        }
        return accumulator;
      }, {}));

      const tableTemp = new Table({}, data, lang);
      return tableTemp.parameters.transformedHTMLData;
    } catch (e) {
      return { header: [], body: [] };
    }
  };

  private linkRoutineToSeries = (chart: Highcharts.Chart): void => {

    if (!this.routines || !this.routines.routines || !Array.isArray(this.routines.routines) || this.routines.routines.length === 0) return;
    const routineSerieMapping: Map<any, Highcharts.Series[]> = this.mapRoutineWithSeries(this.routines.routines, chart.series);

    let selectedRoutine;

    const newRoutines = [];
    chart.series.forEach((serie, index) => {
      serie.update({
        name: serie.name,
        type: serie.type as any,
        index
      }, false);
    });
    
    
    let maxIndex: number = chart.series.length;
    let lastIndex: number[] = undefined;
    routineSerieMapping.forEach((series, routine) => {
        routine.onClick = () => {
            if (routine.selected) {
                series.forEach((serie, index) => {
                  serie.update({
                    name: serie.name,
                    type: serie.type as any,
                    index: lastIndex[index],
                  });
                })
                selectedRoutine = undefined;
                lastIndex = undefined;
            } else {
                if (selectedRoutine) {
                    selectedRoutine.selected = false;
                    const selectedSeries = routineSerieMapping.get(selectedRoutine);
                    selectedSeries.forEach((selectedSerie, index) => {
                      selectedSerie.update({
                        name: selectedSerie.name,
                        type: selectedSerie.type as any,
                        index: lastIndex[index],
                      }, false);
                    })
                    lastIndex = undefined;
                }
                selectedRoutine = routine;
                lastIndex = [];
                series.forEach((serie, index) => {
                  lastIndex.push(serie.index);
                  serie.update({
                    name: serie.name,
                    type: serie.type as any,
                    index: maxIndex + index,
                  }, false);
                })
                chart.redraw();
            }

            routine.selected = !routine.selected;

            chart.series.forEach(serie => {
              if (!selectedRoutine) {
                serie.setState('normal');
                return;
              }
              if (series.includes(serie)) {
                serie.setState('normal');
                return
              }
              serie.setState('inactive', true);
            })
        }

        newRoutines.push(routine);
    });
    this.syncRoutinesSeriesColor(routineSerieMapping);
    this.custom.updateRoutines(newRoutines);
    chart.redraw();
  }

  private syncRoutinesSeriesColor = (routinesSeriesMapping: Map<any, Highcharts.Series[]>) => {
    routinesSeriesMapping.forEach((series, routine) => {
      if (series.length > 1) return;
      series.forEach(serie => {
        serie.update({
          name: serie.name,
          type: serie.type as any,
          color: routine.color
        }, false);
      })
    });
  }

  private mapRoutineWithSeries(routines: any[], series: Highcharts.Series[]): Map<any, Highcharts.Series[]> {
    const mapping = new Map();
    routines.forEach(routine => {
      const foundSeries = [];
      const routineSerie = series.find(serie => serie.name === routine.name);
      if (routineSerie) foundSeries.push(routineSerie);

      if (!foundSeries.length) {
        const formulas: string[] = routine.formula.map(f => f.name);
        const formulaSeries = series.filter(serie => formulas.includes(serie.name));

        foundSeries.push(...formulaSeries);
      }

      if (foundSeries.length) mapping.set(routine, foundSeries);
    });

    return mapping;
  }

  private unLinkRoutine() {
    if (!this.routines || !this.routines.routines || !Array.isArray(this.routines.routines) || this.routines.routines.length === 0) return;
    this.routines.routines.forEach(routine => {
      routine.selected = false;
      routine.onClick = undefined;
    });

    const newRoutines: any[] = this.routines.routines.map(r => r);

    this.custom.updateRoutines(newRoutines);
  }


  private updateChartFont = ( chart: Highcharts.Chart, option: {size: number, bold: boolean;} ): void => {
    try {
      chart.yAxis[ 0 ].update( {
        labels: {
          ...chart.yAxis[0].userOptions.labels,
          style: {
            fontSize: option.size + 'px',
            fontWeight: option.bold ? 'bold' : 'normal',
          }
        }
      }, false );
  
      chart.xAxis[ 0 ].update( {
        labels: {
          ...chart.xAxis[ 0 ].userOptions.labels,
          style: {
            fontSize: option.size + 'px',
            fontWeight: option.bold ? 'bold' : 'normal'
          }
        }
      }, false );
    } catch( e ) {
      console.error('error in updateChartFont, spider');
    }
  }

};