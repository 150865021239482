<div class="modal-header d-flex flex-row justify-content-between">
  <h4 class="modal-title"> {{ title | translate }}</h4>
  <button class="btn-close" type="button" aria-label="Close" (click)="cancel()">
  </button>
</div>
<div class="modal-body">
  <form>
    <form class="form-horizontal">
      <div class="form-group" [innerHTML]="message | translate"></div>
      <ng-template #contentPlaceholder></ng-template>
    </form>
  </form>
</div>
<div class="modal-footer">
  <button *ngFor="let button of customButtons || [{name: 'VALIDATE', event: 'validation', style: 'btn-primary'}]" [ngClass]="['btn', button.style]" type="submit"
          (click)="validate(button.event, button.closeOnValidate)">{{button.name | translate }}</button>
  <button class="btn btn-default" type="button" (click)="cancel()">{{ "CANCEL" | translate }}</button>
</div>
