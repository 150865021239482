

<div class="dna-menu" [ngClass]="{'dna-menu--collapsed' : this.collapsed}">
   <div class="dna-menu__body">
      <div class="menu-box">
         <dna-side-bar-menu [menus]="sideMenu" class="dna-menu" [ngClass]="{'dna-menu--collapsed' : this.collapsed}"> </dna-side-bar-menu>
      </div>
   </div>
</div>

<div class="dna-menu__tab" [ngClass]="{
   'dna-menu__tab--collapsed' : this.collapsed
}" (click)="this._handleWrapperCollapse(this.collapsed)">
   <i [ngClass]="{
   'right'   : this.collapsed,
   'left'    : !this.collapsed
}" class="arrow"></i>
</div>