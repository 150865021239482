<dna-layout>
<ngx-loading [show]="showSpinner">
</ngx-loading>
  <h1 class="mb-4 " id="banner-title" translate>LIBRARY_OF_BLOCKS</h1>
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" translate>DASHBOARD</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>LIBRARY_OF_BLOCKS</li>
    </ol>
  </nav>
  <div class="actions" id="banner-actions">
    <a class="btn btn-primary mb-4" (click)="openModalNewBlock()">
      <span translate>CREATE_BLOCK</span>
    </a>
  </div>
  <div class="block">
    <div class="ibox float-e-margins">
      <div class="ibox-title row cursor-pointer noMarginSide" aria-controls="blockFilter" (click)="isCollapsedBlocksFilter = !isCollapsedBlocksFilter">
        <h5 class="col-md-6" translate>FILTERS</h5>
        <div class="ibox-tools col-md-6">
          <button class="btn btn-primary" type="button"
            (click)="updateBlocks()" translate>SEARCH
          </button>
          <a>
            <i *ngIf="isCollapsedBlocksFilter; else isNotCollapsedBlocksFilter" class="fa fa-chevron-down"></i>
            <ng-template #isNotCollapsedBlocksFilter><i class="fa fa-chevron-up"></i></ng-template>
          </a>
        </div>
      </div>
      <div class="ibox-content" id="blockFilter" [ngbCollapse]="isCollapsedBlocksFilter">
        <div class="row">
          <div class="col-md-4 mt-2 mb-2">
            <dna-search-text (keydown.enter)="updateBlocks()" [data]="filter.name" (onSearch)="updateData($event, 'name', filter)">
            </dna-search-text>
          </div>
          <div class="col-md-4 mt-2 mb-2">
            <dna-filter-list [array]="states" [filterValue]="filter.states">
            </dna-filter-list>
          </div>
        </div>
      </div>
    </div>
    <input id="tourSign" type="hidden" value="blocks">
    <dna-table2 [data]="blocks" [headers]="tableHeaders$ |async" [numberOfObjectsPerPage]="filter.numberOfObjectsPerPage" [pageIndex]="filter.pageIndex"
      (parametersChanged)="onParametersChanged($event)" [reverse]="true" [sortFunction]="sort" [sortable]="true" [title]="'BLOCKS'" [totalItems]="totalItems">
      <ng-template #row let-data="data">
        <tr dna-block-row class="cursor-pointer" [block]="data" [currentLanguage]="currentLanguage"
          (onClick)="onClickActionButton($event, data.id)" (onSend)="onSendDatalakeData($event)"></tr>
      </ng-template>
    </dna-table2>
  </div>
  <div *ngIf="!showSpinner && error">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "ERROR" | translate }}</h5>
        </div>
      </div>
    </div>
    <dna-error-page (onClickReload)="init()"></dna-error-page>
  </div>
</dna-layout>
