export default {
    "type": "sankey",
    "configs": {
        "compute": {
            "method": "median",
            "key" : "key"
        },
        "title": {
            "text": "",
            "margin": 50
        },
        "subtitle": {
            "text": ""
        },
        "yAxis": {
            "min": 0,
            "title": {
                "text": ''
            },
            "tickInterval" : 1,
            "labels": {
                "enabled": false
            }
        },
        "plotOptions": {
            "attributes_blocks" : {
                "enabled" : true
            },
            "default_language" : {
                "enabled" : false
            }
        }
    }
}