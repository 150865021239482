<ngx-loading [show]="showSpinner"></ngx-loading>
<div *ngIf="!error" class="container" [ngSwitch]="metierName">
  <dna-protocol-hair *ngSwitchCase="metiers.Hair" [campaignProtocol]="campaignProtocol" [publishedTemplate]="publishedTemplate"></dna-protocol-hair>
  <dna-protocol-skin *ngSwitchCase="metiers.Skin" [campaignProtocol]="campaignProtocol" [publishedTemplate]="publishedTemplate"></dna-protocol-skin>
</div>
<div *ngIf="error" class="ibox float-e-margins">
  <div class="ibox-title">
    <div class="row">
      <div class="col-12 col-md-4 my-auto">
        <h5>{{ "ERROR" | translate }}</h5>
      </div>
    </div>
  </div>
  <dna-error-page (onClickReload)="init()"></dna-error-page>
</div>
