import {GridsterConfig} from 'angular-gridster2';
import {ErrorManagerService} from '../../services/errorManager.service';
import {UtilService} from '../../services/util.service';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {combineLatest, from, Observable, of, Subject, Subscription, zip} from 'rxjs';
import {Content, GridContentType, Image, ModalContent, Orientation, Page} from '../../../types';
import {catchError, delay, finalize, first, flatMap, mergeMap, take, tap, concatMap, switchMap} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageModalComponent} from '../../modals/image-modal/image-modal.component';
import * as _ from 'lodash';
import {DragulaService} from 'ng2-dragula';
import {PageEditorComponent} from '../../page-editor/page-editor.component';
import {IEditorInit} from '../image-layout/IEditorInit';
import {IFormat} from '../image-layout/IFormat';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import {CallbackModalEnum} from '../../modals/customModal/callbackModalEnum';


declare let require: any;
const domToImage = require('dom-to-image');

@Component({
  selector: 'dna-images-one-pager',
  templateUrl: './images-one-pager.component.html',
  styleUrls: ['./images-one-pager.component.less']
})
export class ImagesOnePagerComponent implements OnInit, OnChanges, OnDestroy {

  private _portraitFormat: IFormat = {WIDTH: '1240px', HEIGHT: '1700px'};
  private _landscapeFormat = '800px';
  private _isFilling = false;
  private _progress: number;
  private _progressTotal: number;
  ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  private _previewMode = false;


  constructor(
    private utilService: UtilService,
    private modalService: NgbModal,
    private elRef: ElementRef,
    private ref: ChangeDetectorRef,
    private errorManager: ErrorManagerService,
    private dragulaService: DragulaService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.subs.add(this.dragulaService.drop('DRAGULA_EVENTS')
      .subscribe(() => {
        if (this.pagesChanges()) {
          this.images = this.updatePagesInImages(this.images, this.pages);
          this.onUpdatedGrid.emit(this.pages);
          this.onCapturesDone.emit(
            {
              data: Array(this.pages.length).fill(''),
              pages: this.pages
            }
          );
          this.emitChange();
          this.onAutomaticFillPages.emit();
        }
      })
    );
  }

  applyHoverStyle(event: Event, imageId: string) {
    const element = event.currentTarget as HTMLElement;
    if (!this.isImageLarge(imageId)) {
      element.style.border = '2px solid #1ab394';
    }
  }

  removeHoverStyle(event: Event, imageId: string) {
    const element = event.currentTarget as HTMLElement;
    if (!this.isImageLarge(imageId)) {
      element.style.border = 'none';
    }
  }

  getLargeImagesCount(): number {
    return this.largeImages.size;
  }

  isImageLarge(imageId: string): boolean {
    return this.largeImages.has(imageId);
  }


  @Input() images: Image[];
  @Input() pages: Page[];
  @Input() takeCapture: Subject<void>;
  @Input() events: Observable<void>;
  @Output() onUpdatedGrid: EventEmitter<Page[]> = new EventEmitter();
  @Output() onImagesChanged: EventEmitter<Image[]> = new EventEmitter();
  @Output() onCapturesDone: EventEmitter<{ data: string[], pages: Page[], displaySuccess?: boolean }> = new EventEmitter();
  @Output() onCapturesError: EventEmitter<string> = new EventEmitter();
  @Output() onAutomaticFillPages: EventEmitter<string> = new EventEmitter();
  @Output() onAutoSave: EventEmitter<string> = new EventEmitter();
  @Output() onModalClose: EventEmitter<string> = new EventEmitter();
  @ViewChild('acc') accordion: any;

  orientations: Orientation[] = [Orientation.Landscape, Orientation.Portrait];
  showSpinner = false;
  captureMode = false;
  oldPages: Page[];
  pagesToCaptures: string[] = [];
  largeImages: Set<string> = new Set();
  largeImagesCount: number = 0;

  subs = new Subscription();
  activeIds: string[] = [];

  private _initEditor = {
    height: 200,
    menubar: false,
    base_url: '/tinymce', suffix: '.min',
    plugins: 'lists link',
    toolbar:
      'undo redo | fontsizeselect forecolor backcolor | bold italic underline  | ' +
      'numlist bullist | alignleft aligncenter alignright alignjustify alignnone | link',
    content_style: 'body {color: black;}'
  };

  static itemChange = (self) => (item): void => {
    self.addPageIfFound(item.url);
    self.emitChange();
  }

  static itemResize = (self) => (item): void => {
    self.addPageIfFound(item.url);
    self.emitChange();
  };

  /**
   * 20766
   * Method to get size of image
   * @param data
   * @returns
   */
  private static getImageSize(data: string) {
    const img = document.createElement('img');
    img.src = data;
    return {
      width: img.width,
      height: img.height
    };
  }

  /**
   * 20766
   * Method to verify if space is available in page to add Image
   * @param page
   * @param item
   * @returns
   */
  private static isAvailablePosition(page: Page, item: any): boolean {
    const usedRows = page.content.reduce((acc, curr) => {
      acc += curr.rows;
      return acc;
    }, 0);
    return (_.get(page, 'options.maxRows', 30) - usedRows) >= item.rows;
  }

  pagesChanges() {
    return !_.isEqual(this.oldPages, this.pages);
  }

  updatePagesInImages(images: Image[], pages: Page[]): Image[] {
    pages.forEach((page, index) => {
      page.content.forEach(content => {
        const imageFromPage = images.find(image => image.url === content.url);
        if (imageFromPage) {
          imageFromPage.pagesAdded = index + 1;
        }
      });
    });
    return images;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.dragulaService.destroy('DRAGULA_EVENTS');
    this.utilService.isOnOnePagerImage.emit(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pages) {
      this.initPages(this.images, changes.pages.currentValue);
    }
  }


  ngOnInit() {
    this.initPages(this.images, this.pages);
    this.oldPages = _.cloneDeep(this.pages);

  }

  private addPageToCapture(page: Page) {
    if (this.pagesToCaptures.find(id => id === page.id) === undefined) {
      this.pagesToCaptures.push(page.id);
    }
  }


  private initPages(images: Image[], pages: Page[]) {
    // Réinitialiser le compteur d'images grandes
    this.largeImages.clear();

    // Convert all observables to promises
    const imageDataPromises = images.map(image => {
      return image.data.toPromise().then(dataString => {
        return { image, dataString };
      }).catch(error => {
        console.error(`Failed to get data for image:`, error);
        return { image, dataString: null };
      });
    });

    // Once all data observables are resolved, continue with getting image sizes
    Promise.all(imageDataPromises).then(imagesWithData => {
      const imageSizePromises = imagesWithData.map(({ image, dataString }) => {
        if (dataString === null) {
          return Promise.resolve({ ...image, size: null });
        }

        return this.getImageSize(dataString).then(size => {
          const isLarge = size.height > 1700;
          if (isLarge) {
            this.largeImages.add(image.id);
          }
          return { ...image, size };
        }).catch(error => {
          console.error(`Failed to get size for image ${dataString}:`, error);
          return { ...image, size: null };
        });
      });

      // Once all image sizes are resolved, continue with the page initialization
      Promise.all(imageSizePromises).then(imagesWithSizes => {
        this.pages.map(p => {
          p.options = _.assign(p.options, this.getOption());
        });

        this.setPageAdded(imagesWithSizes, pages);

        if (pages.length === 0) {
          pages.push(this.createPage(Orientation.Portrait));
        }
      });
    });
  }


  private setPageAdded(images: Image[], pages: Page[]) {
    images.map(i => i.pagesAdded = 0);
    pages.map((p, index) => p.content.map(c => {
      images.filter(i => i.url === c.url).map(i => i.pagesAdded = index + 1);
    }));
    this.images = _.sortBy(images, ['pagesAdded', 'indexImage']);
    this.ref.detectChanges();
  }

  addPage(orientation: Orientation): void {
    this.pages.push(this.createPage(orientation));
    this.emitChange();
  }

  private emitChange(): void {
    const pages = this.pages.map(p => _.omit(p, ['options']));
    this.onUpdatedGrid.emit(pages);
  }

  openModalImage(image: Image, data: string): void {
    const modal = this.modalService.open(ImageModalComponent, {
      keyboard: false,
      backdrop: 'static',
      size: 'lg'
    });
    modal.componentInstance.title = image.name;
    modal.componentInstance.data = data;
  }

  getHeight(orientation: Orientation): string {
    return orientation === Orientation.Landscape ? this._landscapeFormat : this._portraitFormat.HEIGHT;
  }

  getMargin(indexPage, orientation: Orientation): string {
    if (indexPage === 0) {
      if (orientation === Orientation.Landscape) {
        return '-5%';
      }
      return '-11%';
    } else {
      if (orientation === Orientation.Landscape) {
        return '-5%';
      }
      return '-19%';
    }
  }

  deleteImage(image: Image, index: number): void {
    this.utilService.translateMessageModal('CONFIRM_DELETE', image.name, '').pipe(
      flatMap((modalContent: ModalContent) => this.utilService.openModalConfirm(modalContent)),
      tap(() => this.removeImageFromPage(image)),
      tap(() => this.images.splice(index, 1)),
      catchError(() => of()),
      take(1)
    ).subscribe(() => {
      this.ref.detectChanges();
      this.onUpdatedGrid.emit(this.pages);
      this.onImagesChanged.emit(this.images);
      this.capture(true).then(() => {
        this.onAutoSave.emit();
      });
    });
  }

  private removeImageFromPage(image: Image): void {
    this.pages.map(p => {
      _.remove(p.content, {'url': image.url});
    });
    if (this.pages[image.pagesAdded - 1]) {
      this.addPageToCapture(this.pages[image.pagesAdded - 1]);
    }
  }

  removePage(page): void {
    this.pages = this.pages.filter((pageToFilter) => {
      return pageToFilter.id !== page.id;
    });
    this.setPageAdded(this.images, this.pages);
    this.emitChange();
    this.capture(true, false).then(() => {
      this.onAutoSave.emit();
    });
  }

  private checkIfSpaceAvailable(page: Page, item: Content): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      setTimeout(() => {
        if (page.options.api.getNextPossiblePosition(item)) {
          page.content.push(item);
          this.emitChange();
          this.addPageToCapture(page);
          return resolve(true);
        }
        this.errorManager.displayMessage('CANNOT_ADD_ITEM', 'danger');
        return reject(false);
      }, 200);
    });
  }

  onChangeComment(page: Page) {
    this.emitChange();
    this.addPageToCapture(page);
  }

  private getOption(): GridsterConfig {
    return {
      margin: 0,
      draggable: {
        enabled: true,
        stop: ImagesOnePagerComponent.itemChange(this)
      },
      resizable: {
        enabled: true,
        handles: {
          s: false, // redimensionnement Sud (vertical)
          e: false,  // redimensionnement Est (horizontal)
          n: false, // redimensionnement Nord (vertical)
          w: true,  // redimensionnement Ouest (horizontal)
          se: true, // redimensionnement Sud-Est
          ne: false, // redimensionnement Nord-Est
          sw: false,  // redimensionnement Sud-Ouest
          nw: false  // redimensionnement Nord-Ouest
        },
        stop: ImagesOnePagerComponent.itemResize(this)
      },
      minCols: 30,
      maxCols: 30,
      minRows: 30,
      maxRows: 30
    };
  }

  private createPage(orientation: Orientation): Page {
    const page = new Page();
    page.id = `id${this.utilService.generateRandomID(5)}`;
    page.orientation = orientation;
    page.options = _.assign(page.options, this.getOption());

    page.content = [];
    return page;
  }

  onUploadImages(images: Image[]): void {
    images.map(i => {
        i.pagesAdded = 0;
        if (!i.id) {
            i.id = this.generateUniqueId();
        }
    });

    images.forEach(image => {
        if (image.height > 1700) {
            this.largeImages.add(image.id);
        }
    });

    this.images = this.images.concat(images);
    this.largeImagesCount = this.largeImages.size;

    this.onImagesChanged.emit(this.images);
    this.capture(true).then(() => {
        this.onAutoSave.emit();
    });
}

generateUniqueId(): string {
    return new Date().getTime().toString();
}


  private exportGraphToPng = (container: any): Observable<string> => {
    const promise = new Promise<string>((resolve, reject) => {
      setTimeout(() => {
        domToImage.toJpeg(container).then(dataUrl => {
          resolve(dataUrl);
        }).catch(err => {
          reject(err);
        });
      }, 100);
    });
    return from(promise);
  }

  private async capture(saveForce = false, fromModal = false, autoFill = false) {
    this._progress = 0;
    this.captureMode = true;

    if (this.pages.length === 0) {
      this.onCapturesDone.emit(
        {
          data: [],
          pages: []
        }
      );
    } else if (saveForce) {
      this.onCapturesDone.emit(
        {
          data: Array(this.pages.length).fill(''),
          pages: this.pages
        }
      );
    }

    let progression = 0;

    const captureRequest = (index) => {
      if (this.pages.find(p => p.id === this.pagesToCaptures[index])) {
        const div = fromModal ? document.getElementsByClassName(`${this.pagesToCaptures[index]}`)[0] :
          document.getElementsByClassName(`${this.pagesToCaptures[index]}`)[0];
        of(div).pipe(delay(100), mergeMap(() => from(domToImage.toJpeg(div)))).subscribe((res: string) => {
          const fillArray = Array(this.pages.length).fill('');
          let indexToUse: number;
          this.pages.forEach((page, pageIndex) => {
            if (this.pagesToCaptures[index] === page.id) {
              indexToUse = pageIndex;
            }
          });
          fillArray[indexToUse] = res;
          let displayStatus = true;
          if (autoFill) {
            displayStatus = this.pages.length === index + 1;
          }
          this.onCapturesDone.emit({data: fillArray, pages: this.pages, displaySuccess: displayStatus});
          if (displayStatus) {
            this.emitChange();
            this.onAutomaticFillPages.emit();
            if (saveForce || fromModal) {
              this.pagesToCaptures = [];
            }
          }
        });
      }
    };

    if (!saveForce) {
      captureRequest(0);
      this.events.pipe(take(this.pagesToCaptures.length)).subscribe(() => {
        if (this.pagesToCaptures.length - 1 !== progression && this.pagesToCaptures.length !== 0) {
          progression++;
          captureRequest(progression);
          this._progress = 100 * progression / this.pagesToCaptures.length;
        } else {
          progression++;
          this.captureMode = false;
          this._progress = 100 * progression / this.pagesToCaptures.length;
          setTimeout(() => {
            this._isFilling = false;
            this.ref.detectChanges();
            this._progress = 0;
          }, 500);
          this.pagesToCaptures = [];
        }
      });
    }
  }

  addComment(page: Page): void {
    const item = {x: 0, y: 0, cols: 20, rows: 2, type: GridContentType.Comment, id: this.utilService.generateRandomID(5), comment: ''};
    this.checkIfSpaceAvailable(page, item);
  }

  removeItem(event): void {
    const item = event.page.content.splice(event.index, 1)[0];
    if (item.type === GridContentType.Image) {
      this.setPageAdded(this.images, this.pages);
      if (this.largeImages[item.id]) {
        delete this.largeImages[item.id];
        this.largeImagesCount--;
      }
    }
    this.emitChange();
    this.addPageToCapture(event.page);
  }

  private findPageByImageURL(url: string): Page {
    for (const page of this.pages) {
      for (const content of page.content) {
        if (content.url === url) {
          return page;
        }
      }
    }
    return undefined;
  }

  private addPageIfFound(url: string) {
    const page = this.findPageByImageURL(url);
    if (page) {
      this.addPageToCapture(page);
    }
  }

  public sortByPageAdd(image: Image[]) {
    return image.sort((a, b) => {
        return a.pagesAdded - b.pagesAdded;
    });
  }


  closeAll() {
    this.activeIds = [];
  }

  openAll() {
    this.activeIds = this.pages.map((p, index) => 'ngb-panel-' + index);
  }

  openOnePage(indexPage: number) {
    this.activeIds = ['ngb-panel-' + indexPage];
  }

  /**
   * 20766
   * Method to fill pages with images automatically
   */

  automaticFill() {
    this._isFilling = true;
    let imagesNotAdded = this.images.filter(image => image.pagesAdded === 0);
    imagesNotAdded = _.sortBy(imagesNotAdded, ['indexImage']);

    this.processImages(imagesNotAdded).pipe(
      finalize(() => {
        if (this.pages.length > 1 && this.pages[0].content.length === 0) {
          this.pages.shift();
        }
        this.setPageAdded(this.images, this.pages);
        this.changeDetectorRef.detectChanges();
        this.capture(false, false, true).then(() => {
          this.emitChange();
          this.onAutomaticFillPages.emit();
        });
      })
    ).subscribe();
  }


  processImages(images: Image[]) {
    return from(images).pipe(
      concatMap(image => {
        if (!image.data) {
          throw new Error("Image data is not available as an Observable");
        }

        return image.data.pipe(
          concatMap(dataString => {
            if (typeof dataString !== 'string') {
              throw new Error("DataString is not a string");
            }
            return this.getImageSize(dataString);
          }),
          concatMap(size => {
            if (!size || typeof size.height !== 'number') {
              throw new Error("Size does not have a 'height' property or is not a number");
            }

            let currentPage = this.pages.length > 0 ? this.pages[this.pages.length - 1] : null;
            let currentPageHeight = currentPage ? currentPage.totalHeight : 0;

            const item = this.createItem(image, size);

            if (!currentPage || currentPageHeight + size.height > 1700 || !this.isAvailablePosition(currentPage, item)) {
              currentPage = this.createPage(Orientation.Portrait);
              this.pages.push(currentPage);
              currentPageHeight = 0;
            }

            this.addItemInPage(currentPage, item);
            if (currentPage) {
              currentPage.totalHeight = (currentPage.totalHeight || 0) + size.height;
            }

            // Return a resolved Observable to continue the sequence
            return of(null);
          })
        );
      })
    );
  }



  /**
   * 20766
   * Method to get size of image
   * @param data
   * @returns
   */

  private getImageSize(dataString: string): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img');
      img.onload = () => resolve({ width: img.naturalWidth, height: img.naturalHeight });
      img.onerror = reject;
      img.src = dataString;
    });
  }



  /**
   * 20766
   * Method to add item created in page and add page to captures
   * @param currentPage
   * @param item
   */
  private addItemInPage(currentPage: Page, item: any) {
    currentPage.content.push(item);
    this.addPageToCapture(currentPage);
  }

  /**
   * 20766
   * Method to verify if space is available in page to add Image
   * @param page
   * @param item
   * @returns
   */
  private isAvailablePosition(page: Page, item: any): boolean {
    const usedRows = page.content.reduce((acc, curr) => {
      acc += curr.rows;
      return acc;
    }, 0);
    return (_.get(page, 'options.maxRows', 30) - usedRows) >= item.rows;
  }

  /**
   * 20766
   * Method to create item with image max size
   * @param image
   * @param size
   * @returns
   */


  createItem(image, size) {
    const maxCols = 30;
    const maxRows = 30;
    const gridMaxWidth = 1157;
    const gridMaxHeight = 1700;
    let cols, rows;

    let scaleRatio = gridMaxWidth / size.width; // Pour occuper 100% de la largeur
    let adjustedHeight = size.height * scaleRatio;
    let additionalMarginRows = 0;

    // marge minimale en dessous de chaque image pour éviter le chevauchement
    const minimalMargin = 0.05 * gridMaxHeight;
    adjustedHeight += minimalMargin;

    cols = maxCols;
    rows = Math.ceil((adjustedHeight / gridMaxHeight) * maxRows) + additionalMarginRows;

    cols = Math.min(cols, maxCols);
    rows = Math.min(rows, maxRows);

    return {
      x: 0,
      y: 0,
      cols: cols,
      rows: rows,
      type: GridContentType.Image,
      url: image.url,
      data: image.data,
      name: image.name,
      id: this.utilService.generateRandomID(5)
    };
  }


  public openCustomPage(page: Page): void {
    const oldPages: Page[] = JSON.parse(JSON.stringify(this.pages));
    oldPages.forEach((oldPage, index) => {
      oldPage.content.forEach((pageContent, indexContent) => {
        if (this.pages[index].content[indexContent].data) {
          this.pages[index].content[indexContent].data.subscribe((data) => {
            pageContent.data = of(data);
          });
        }
      });
    });
    const oldImages: Image[] = JSON.parse(JSON.stringify(this.images));
    oldImages.forEach((oldImage, index) => {
      if (this.images[index].data) {
        this.images[index].data.subscribe((data) => {
          oldImage.data = of(data);
        });
      }
    });
    const modal = this.utilService.openCustomModal({
      title: 'Modification de la page',
      windowClass: 'modal-1200 custom-body',
      customButtons: [{name: 'VALIDATE', event: 'validation', style: 'btn-primary', closeOnValidate: false}],
      bodyComponent: PageEditorComponent,
      bodyComponentInputs:
        {
          _captureMode: false, _format: this._portraitFormat, _initEditor: this.initEditor,
          _page: page, _sizeLandScape: this.landscapeFormat,
          _pages: this.pages, _images: this.images
        },
      bodyComponentOutputs:
        ['_onChangeComment', '_onRemovedItem', '_onCommentAdd', '_onUpdatedGrid', '_onImagesChanged', '_onPageAdd'],
    });

    modal.result.then((res: CallbackModalEnum) => {
      if (res === CallbackModalEnum.dismissed) {
        this.images = oldImages;
        this.pages = oldPages;
        this.ref.detectChanges();
      }
    });

    modal.componentInstance.outputEvent$.subscribe((event) => {
      this.showSpinner = true;
      switch (event.eventName) {
        case '_onRemovedItem':
          this.removeItem(event.value);
          break;
        case '_ChangeComment':
          this.onChangeComment(event.value);
          break;
        case '_onCommentAdd':
          this.addComment(event.value);
          break;
        case '_UpdatedGrid':
          this.onUpdatedGrid.emit(event.value);
          break;
        case '_onImagesChanged':
          this.onImagesChanged.emit(event.value);
          break;
        case '_onPageAdd':
          this.setPageAdded(event.value.images, event.value.pages);
          this.ref.detectChanges();
          break;
        case 'validation':
          this.addPageToCapture(page);
          document.getElementsByClassName(page.id)[0].className = 'capture fit ' + page.id;
          document.getElementById('modal-loader').className = '';
          this.capture(false, true).then(() => {
            this.events.pipe(take(1)).subscribe(() => {
              document.getElementById('modal-loader').className = 'hide';
              this.images.sort((a, b) => {
                return b.pagesAdded - a.pagesAdded;
              });
              this.ref.detectChanges();
              this.onUpdatedGrid.emit(this.pages);
              this.onAutoSave.emit();
              this.showSpinner = false;
              modal.close();
            });
          });
          break;
      }
    });
  }

  public openPreview() {
    this._previewMode = true;
    document.getElementsByClassName('preview')[0].scrollTop = 0;
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
  }

  public closePreview() {
    this._previewMode = false;
    document.getElementsByTagName('body')[0].style.overflowY = 'auto';
  }

  public set previewMode(value: boolean) {
    this._previewMode = value;
  }
  public get previewMode(): boolean {
    return this._previewMode;
  }

  public get portraitFormat(): IFormat {
    return this._portraitFormat;
  }

  public get landscapeFormat(): string {
    return this._landscapeFormat;
  }

  public get initEditor(): IEditorInit {
    return this._initEditor;
  }

  public get isFilling(): boolean {
    return this._isFilling;
  }

  public get progressTotal(): number {
    return this._progressTotal;
  }

  public get progress(): number {
    return this._progress;
  }

}
