<div class="modal-header">
  <h3 class="modal-title" translate>PUBLICATION</h3>
</div>
<div *ngIf="type === 'LOOPS_AND_NO_VISITS'; else missVisits" class="modal-body">
  <p>{{"LOOPS_NO_VISIT" | translate}}</p>
  <ng-container *ngFor="let workflow of loopAndNoVisits">
    <li><dna-translate [fieldToTranslate]="workflow.workflowName"> </dna-translate></li>
  </ng-container>
</div>
<ng-template #missVisits>
  <div class="modal-body">
    <p>{{"ON_MISS_VISITS_MESSAGE" | translate}}</p>
  </div>
</ng-template>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="onValidate()" translate>PUBLISH</button>
  <button class="btn btn-outline-dark" type="button" (click)="onCancel()">{{ (type === 'LOOPS_AND_NO_VISITS' ? 'EDIT_VISITS' : 'EDIT_ROUTINES') | translate }}</button>
</div>