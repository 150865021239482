import { DNAObject } from '../../types';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'dna-filter-table',
    templateUrl: './dnaFilterTable.component.html'
})

export class FilterTableComponent implements OnInit {
    @Input() displayedArray: DNAObject[];
    @Input() key: string;
    @Input() valueToDisplay: string;
    @Input() title: string;
    @Input() icon: string;
    @Output() onArrayChanged = new EventEmitter();
    selectedArray: any;

    constructor() { }

    ngOnInit() {
        this.selectedArray = this.displayedArray.reduce((obj, data) => {
            obj[data[this.key]] = true;
            return obj;
        }, {});
        this.onArrayChanged.emit(this.selectedArray);
    }

    onClick(data: DNAObject) {
        this.selectedArray[data[this.key]] = !this.selectedArray[data[this.key]];
        this.onArrayChanged.emit(this.selectedArray);
    }
}
