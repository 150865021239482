import {TranslatableType} from '../../../interfaces';

interface TranslatableContainerInterface  {
    [key: string]: TranslatableContainerInterface | TranslatableType;
}

interface ConfigInterface {
  type: string;
  configs: {
    translations?: TranslatableContainerInterface;
    title: {
      text: string;
    };
    subtitle: {
      text: string;
    };
    xAxis: {
      title: {
        text: string;
      };
      min?: number;
    };
    yAxis: {
      title: {
        text: string;
        align: string;
      };
      min?: number;
      tickInterval?: number;
    };
    plotOptions: {
      attributes_blocks: {
        enabled: boolean;
      };
      default_language: {
        enabled: boolean;
      };
      series: {
        marker: {
          enabled: boolean;
        };
      };
    };
  };
}

const options: ConfigInterface = {
  type: 'kinetic',
  configs: {
    translations: {
      yAxis: {
        title: {
          english: 'Average score',
          french: 'Score moyen',
        }
      },
      xAxis: {
        title: {
          english: 'Time(mn)',
          french: 'Temps(mn)',
        }
      }
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      title: {
        text: 'Temps(mn)',
      },
    },
    yAxis: {
      title: {
        text: 'Score moyen',
        align: 'high',
      },
      min: 0,
      tickInterval: 1,
    },
    plotOptions: {
      attributes_blocks: {
        enabled: true,
      },
      default_language: {
        enabled: false,
      },
      series: {
        marker: {
          enabled: true,
        },
      },
    },
  },
};

export default options;
