
import {mergeMap, flatMap} from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input
} from '@angular/core';

import {
  UserInCampaign, Evaluation, Volunteers
} from './../../../../../types';
import {
  UtilService
} from '../../../../../shared/services/util.service';
import * as _ from 'lodash';


@Component({
  selector: '[dna-drop-user-row]',
  templateUrl: './drop-user-row.component.html',
  styleUrls: ['./drop-user-row.component.less']
})
export class DropUserRowComponent implements OnInit {

  @Input() accountable: UserInCampaign;
  @Input() volunteer: Volunteers;
  @Input() evaluations: Evaluation[] = [];
  isDisabled = false;
  isVolunteer = false;

  constructor(
    public utilService: UtilService,
  ) { }

  ngOnInit() {
    if (this.volunteer) {
      this.isVolunteer = true;
      if (!_.has(this.volunteer, 'isActive')) { this.volunteer.isActive = true; }
      // INC6401272 - ENHC0077346 : demande pour supprimer la règle qui permet de disable volontaire s'ils ont déjà commencé les évals
      // const volEvals = this.evaluations.filter(evaluation => evaluation.volunteer.name == this.volunteer.name && _.get(evaluation, 'progress', 0) != 0);
      // if (!_.isEmpty(volEvals)) this.isDisabled = true;
    }
  }

  confirmUpdateUser() {
    if (this.isVolunteer && !this.isDisabled) {
      this.utilService.translateMessageModal('CONFIRM_DROP_USER', this.volunteer.name, '')
      .pipe(flatMap(modalContent => this.utilService.openModalConfirm(modalContent)))
      .subscribe(() => {
        this.volunteer.isActive = !_.get(this.volunteer, 'isActive', true);
      });
    } else {
      this.utilService.translateMessageModal('CONFIRM_DROP_USER', this.accountable.name, '')
      .pipe(flatMap(modalContent => this.utilService.openModalConfirm(modalContent)))
      .subscribe(() => {
        this.accountable.isActive = !_.get(this.accountable, 'isActive', true);
      });
    }
  }

}
