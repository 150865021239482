
import {finalize} from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as _ from 'lodash';

import { ErrorManagerService } from '../../../../../shared/services/errorManager.service';
import { User, Workflow, Workspace } from '../../../../../types';
import { WorkflowService } from '../../../../../workflows/workflows.service';

@Component({
  selector: 'dna-workflow-import',
  templateUrl: './workflow-import.component.html',
  styleUrls: ['./workflow-import.component.css']
})
export class WorkflowImportComponent implements OnInit {
  importForm: FormGroup;
  showSpinner: boolean = false;
  @Input() user: User;
  @Input() workspaces: Workspace[];

  constructor(
    private errorManager: ErrorManagerService,
    private formBuilder: FormBuilder,
    private workflowService: WorkflowService
  ) {
    this.importForm = this.formBuilder.group({
      workflowToImport: ['', Validators.required],
      workspaces: [this.workspaces, Validators.required]
    });
  }

  ngOnInit() {
  }

  onSubmit(form: FormGroup) {
    try {
      let workflow: Workflow = JSON.parse(form.value.workflowToImport);
      workflow = _.omitBy(workflow, (value, key) => key[0] === '_');
      let workspacesToAdd = form.value.workspaces.map(w => w.id);

      if (!workflow.blocks) {
        this.errorManager.displayMessage('ERROR_WRONG_OBJECT', 'danger');
      }
      else if (_.isEmpty(workflow.blocks)) {
        this.errorManager.displayMessage('ON_ERROR_EMPTY_WORKFLOW', 'danger');
      }
      else {
        this.saveWorkflow(workflow, workspacesToAdd);
      }
    }
    catch (error) {
      this.errorManager.displayMessage('ERROR_IMPORT_WORKFLOW', 'danger');
      this.showSpinner = false;
    }
  }

  saveWorkflow(workflow: Workflow, workspacesId: string[]) {
    this.showSpinner = true;
    workflow.createdBy = { user: this.user.track };
    workflow.workspaces = workspacesId;
    if (workflow.blocks) {
      workflow.blocks.forEach(block => {
        block.createdBy = { user: this.user.track };
        block.workspaces = workspacesId;
      });
    }

    this.workflowService.createWorkflow(workflow).pipe(
      finalize(() => this.showSpinner = false))
      .subscribe((response) => {
        this.importForm.reset();
        this.errorManager.displayMessage('ON_SUCCESS_CREATE', 'success');
      }, err => {
        this.errorManager.catchError(err);
      });
  }

}
