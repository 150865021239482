  <!--
  <ngx-loading [show]="showSpinner">
</ngx-loading>
-->
<h1 class="mb-4" id="banner-title">
  <span translate>FICHE_REMANENCE</span> - {{panelist?.firstName}} {{panelist?.lastName}}
</h1>
<nav aria-label="breadcrumb" id="banner-nav">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/dashboard" translate>DASHBOARD</a>
    </li>
    <li class="breadcrumb-item">
      <a routerLink="/volunteers" translate>VOLUNTEERS</a>
    </li>
    <li class="breadcrumb-item" aria-current="page">
      <a (click)='goToGeneralInfo()'>{{panelist?.panelistNumber}}</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page" translate>
      FICHE_REMANENCE
    </li>
  </ol>
</nav>
<div class="actions" id="banner-actions">
  <a *ngIf="isEditable" class="btn btn-primary text-white" (click)="save()">
    <span translate>SAVE</span>
  </a>
  <a class="btn btn-primary text-white" (click)="goToGeneralInfo()">
    <span translate *ngIf="isEditable; else notEditable">CANCEL</span>
    <ng-template #notEditable><span translate>BACK</span></ng-template>
  </a>
</div>
<div class="block ibox float-e-margins" *ngIf="ficheRemanence">
  <div class="ibox-content">
    <div class="row margin-bottom">
      <div class="col-2">
        <label translate>BRIDGE</label>
      </div>
      <div class="col-4">
        <input [disabled]="!isEditable" class="form-control" type="text" [(ngModel)]="ficheRemanence.requestNumber">
      </div>
    </div>
    <div class="row margin-bottom">
      <div class="col-12 col-md-6">
        <div class="row">
          <label class="col-4" translate>CUIR_CHEVELU_SAIN</label>
          <div class="col-8 float-left">
            <dna-checkbox [disabled]="!isEditable" [isChecked]="ficheRemanence.cuirCheveluSain"
              (onBooleanChanged)="changeValue('cuirCheveluSain')"></dna-checkbox>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-4">
            <label translate>PELLICULES</label>
          </div>
          <div class="col-8">
            <select [disabled]="!isEditable" id="pellicules" class="form-control" name="pellicules"
              [(ngModel)]="ficheRemanence.pellicules" [compareWith]="compareById">
              <option [ngValue]="undefined"></option>
              <option *ngFor="let pellicule of ficheRemanenceOptions.pellicules" [ngValue]="pellicule">
                {{ pellicule.name | translate }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row margin-bottom border-top">
      <label class="col-3" style="padding-right: 5px" translate>COSMETIC_CHEVEUX_HUMIDE</label>
      <dna-checkbox [disabled]="!isEditable" [isChecked]="ficheRemanence.isCosmetiqueCheveuxHumide"
        (onBooleanChanged)="changeValue('isCosmetiqueCheveuxHumide')"></dna-checkbox>
    </div>
    <ng-container *ngIf="ficheRemanence.isCosmetiqueCheveuxHumide">
      <div class="row margin-bottom">
        <div class="col-12">
          <div class="d-flex flex-row">
            <div class="col-2">
              <label translate>DEMELAGE</label>
            </div>
            <div class="col-1" *ngFor="let echelleMiroir of ficheRemanenceOptions.echelleMiroir">
              <div
                class="d-flex flex-row align-items-center"
                (click)="onChangeData(echelleMiroir, typeCosmetique.cosmetiqueCheveuxHumide, optionCosmetiqueHumide.demelage)">
                <div class="iradio_square-green iradio"
                  [ngClass]="{'checked': isChecked(echelleMiroir, typeCosmetique.cosmetiqueCheveuxHumide, optionCosmetiqueHumide.demelage), 'disabled': !isEditable}">
                </div>
                <i></i>
                <label class="form-check-label">{{echelleMiroir.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <div class="col-12">
          <div class="d-flex flex-row">
            <div class="col-2">
              <label translate>LISSE_TOUCHER</label>
            </div>
            <div class="col-1" *ngFor="let echelleMiroir of ficheRemanenceOptions.echelleMiroir">
              <div
                class="d-flex flex-row align-items-center"
                (click)="onChangeData(echelleMiroir, typeCosmetique.cosmetiqueCheveuxHumide, optionCosmetiqueHumide.lisseToucher)">
                <div class="iradio_square-green"
                  [ngClass]="{'checked': isChecked(echelleMiroir, typeCosmetique.cosmetiqueCheveuxHumide, optionCosmetiqueHumide.lisseToucher), 'disabled': !isEditable}">
                </div>
                <i></i>
                <label class="form-check-label">{{echelleMiroir.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <div class="col-12">
          <div class="d-flex flex-row">
            <div class="col-2">
              <label translate>SOUPLESSE</label>
            </div>
            <div class="col-1" *ngFor="let echelleMiroir of ficheRemanenceOptions.echelleMiroir">
              <div
                class="d-flex flex-row align-items-center"
                (click)="onChangeData(echelleMiroir, typeCosmetique.cosmetiqueCheveuxHumide, optionCosmetiqueHumide.souplesse)">
                <div class="iradio_square-green"
                  [ngClass]="{'checked': isChecked(echelleMiroir, typeCosmetique.cosmetiqueCheveuxHumide, optionCosmetiqueHumide.souplesse), 'disabled': !isEditable}">
                </div>
                <i></i>
                <label class="form-check-label">{{echelleMiroir.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <div class="col-12">
          <div class="d-flex flex-row">
            <div class="col-2">
              <label translate>DENSITE</label>
            </div>
            <div class="col-1" *ngFor="let echelleMiroir of ficheRemanenceOptions.echelleMiroir">
              <div
                class="d-flex flex-row align-items-center"
                (click)="onChangeData(echelleMiroir, typeCosmetique.cosmetiqueCheveuxHumide, optionCosmetiqueHumide.densite)">
                <div class="iradio_square-green"
                  [ngClass]="{'checked': isChecked(echelleMiroir, typeCosmetique.cosmetiqueCheveuxHumide, optionCosmetiqueHumide.densite), 'disabled': !isEditable}">
                </div>
                <i></i>
                <label class="form-check-label">{{echelleMiroir.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <div class="col-12">
          <div class="d-flex flex-row">
            <div class="col-2">
              <label translate>ENROBAGE</label>
            </div>
            <div class="col-1" *ngFor="let echelleMiroir of ficheRemanenceOptions.echelleMiroir">
              <div
                class="d-flex flex-row align-items-center"
                (click)="onChangeData(echelleMiroir, typeCosmetique.cosmetiqueCheveuxHumide, optionCosmetiqueHumide.enrobage)">
                <div class="iradio_square-green"
                  [ngClass]="{'checked': isChecked(echelleMiroir, typeCosmetique.cosmetiqueCheveuxHumide, optionCosmetiqueHumide.enrobage), 'disabled': !isEditable}">
                </div>
                <i></i>
                <label class="form-check-label">{{echelleMiroir.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <h4 class="text-center col-12" translate>TYPE_ENROBAGE</h4>
      </div>
      <div class="row margin-bottom">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>LEFT_VALUES</label>
            </div>
            <div class="col-8">
              <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
                [(ngModel)]="ficheRemanence.cosmetiqueCheveuxHumide.typeEnrobageG">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let typeEnrobage of ficheRemanenceOptions.typeEnrobage" [ngValue]="typeEnrobage">
                  {{ typeEnrobage.name | translate}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>RIGHT_VALUES</label>
            </div>
            <div class="col-8">
              <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
                [(ngModel)]="ficheRemanence.cosmetiqueCheveuxHumide.typeEnrobageD">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let typeEnrobage of ficheRemanenceOptions.typeEnrobage" [ngValue]="typeEnrobage">
                  {{ typeEnrobage.name | translate}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom"
        *ngIf="ficheRemanence.cosmetiqueCheveuxHumide.typeEnrobageD?.id === '4' || ficheRemanence.cosmetiqueCheveuxHumide.typeEnrobageG?.id === '4'">
        <div class="col-12 col-md-6">
          <div class="row" [hidden]="ficheRemanence.cosmetiqueCheveuxHumide.typeEnrobageG?.id !== '4'">
            <div class="col-4"></div>
            <div class="col-8">
              <input [disabled]="!isEditable" class="form-control" placeholder="Type d'enrobage à gauche" type="text"
                [(ngModel)]="ficheRemanence.cosmetiqueCheveuxHumide.typeEnrobageOtherG">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="row" [hidden]="ficheRemanence.c_osmetiqueCheveuxHumide.typeEnrobageD?.id !== '4'">
            <div class="col-4"></div>
            <div class="col-8">
              <input [disabled]="!isEditable" class="form-control" placeholder="Type d'enrobage à droite" type="text"
                [(ngModel)]="ficheRemanence.cosmetiqueCheveuxHumide.typeEnrobageOtherD">
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <h4 class="text-center col-12" translate>INTEGRITE</h4>
      </div>
      <div class="row margin-bottom">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>LEFT_VALUES</label>
            </div>
            <div class="col-8">
              <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
                [(ngModel)]="ficheRemanence.cosmetiqueCheveuxHumide.integriteG">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let integrite of ficheRemanenceOptions.integrite" [ngValue]="integrite">
                  {{ integrite.name | translate}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>RIGHT_VALUES</label>
            </div>
            <div class="col-8">
              <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
                [(ngModel)]="ficheRemanence.cosmetiqueCheveuxHumide.integriteD">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let integrite of ficheRemanenceOptions.integrite" [ngValue]="integrite">
                  {{ integrite.name | translate}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-top margin-bottom">
        <div class="col-2">
          <label translate>FEEDBACK</label>
        </div>
        <div class="col-10">
          <textarea [disabled]="!isEditable" class="form-control" rows="2"
            [(ngModel)]="ficheRemanence.cosmetiqueCheveuxHumide.commentaires"></textarea>
        </div>
      </div>
      <div class="row margin-bottom">
        <div class="col-2">
          <label translate>TYPE_SECHAGE</label>
        </div>
        <div class="col-4">
          <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
            [(ngModel)]="ficheRemanence.cosmetiqueCheveuxHumide.typeSechage">
            <option [ngValue]="undefined"></option>
            <option *ngFor="let typeSechage of ficheRemanenceOptions.typeSechage" [ngValue]="typeSechage">
              {{ typeSechage.name | translate}}</option>
          </select>
        </div>
        <div class="col-6" *ngIf="ficheRemanence.cosmetiqueCheveuxHumide.typeSechage?.id === '7'">
          <input [disabled]="!isEditable" class="form-control" type="text"
            placeholder="Merci de renseigner un type de séchage"
            [(ngModel)]="ficheRemanence.cosmetiqueCheveuxHumide.typeSechageOther">
        </div>
      </div>
    </ng-container>
    <div class="d-flex flex-row margin-bottom border-top">
      <label class="col-3" style="padding-right: 5px" translate>COSMETIC_CHEVEUX_SEC</label>
      <dna-checkbox [disabled]="!isEditable" [isChecked]="ficheRemanence.isCosmetiqueCheveuxSec"
        (onBooleanChanged)="changeValue('isCosmetiqueCheveuxSec')"></dna-checkbox>
    </div>
    <ng-container *ngIf="ficheRemanence.isCosmetiqueCheveuxSec">
      <div class="d-flex flex-row margin-bottom">
        <div class="col-12">
          <div class="d-flex flew-row">
            <div class="col-2">
              <label translate>DEMELAGE</label>
            </div>
            <div class="col-1" *ngFor="let echelleMiroir of ficheRemanenceOptions.echelleMiroir">
              <div
                class="d-flex flex-row align-items-center"
                (click)="onChangeData(echelleMiroir, typeCosmetique.cosmetiqueCheveuxSec, optionCosmetiqueSec.demelage)">
                <div class="iradio_square-green"
                  [ngClass]="{'checked': isChecked(echelleMiroir, typeCosmetique.cosmetiqueCheveuxSec, optionCosmetiqueSec.demelage), 'disabled': !isEditable}">
                </div>
                <i></i>
                <label class="form-check-label">{{echelleMiroir.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <div class="col-12">
          <div class="d-flex flex-row">
            <div class="col-2">
              <label translate>LISSE_TOUCHER</label>
            </div>
            <div class="col-1" *ngFor="let echelleMiroir of ficheRemanenceOptions.echelleMiroir">
              <div
                class="d-flex flex-row align-items-center"
                (click)="onChangeData(echelleMiroir, typeCosmetique.cosmetiqueCheveuxSec, optionCosmetiqueSec.lisseToucher)">
                <div class="iradio_square-green"
                  [ngClass]="{'checked': isChecked(echelleMiroir, typeCosmetique.cosmetiqueCheveuxSec, optionCosmetiqueSec.lisseToucher), 'disabled': !isEditable}">
                </div>
                <i></i>
                <label class="form-check-label">{{echelleMiroir.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <div class="col-12">
          <div class="d-flex flex-row">
            <div class="col-2">
              <label translate>SOUPLESSE</label>
            </div>
            <div class="col-1" *ngFor="let echelleMiroir of ficheRemanenceOptions.echelleMiroir">
              <div
                class="d-flex flex-row align-items-center"
                (click)="onChangeData(echelleMiroir, typeCosmetique.cosmetiqueCheveuxSec, optionCosmetiqueSec.souplesse)">
                <div class="iradio_square-green"
                  [ngClass]="{'checked': isChecked(echelleMiroir, typeCosmetique.cosmetiqueCheveuxSec, optionCosmetiqueSec.souplesse), 'disabled': !isEditable}">
                </div>
                <i></i>
                <label class="form-check-label">{{echelleMiroir.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <div class="col-12">
          <div class="d-flex flex-row">
            <div class="col-2">
              <label translate>DENSITE</label>
            </div>
            <div class="col-1" *ngFor="let echelleMiroir of ficheRemanenceOptions.echelleMiroir">
              <div
                class="d-flex flex-row align-items-center"
                (click)="onChangeData(echelleMiroir, typeCosmetique.cosmetiqueCheveuxSec, optionCosmetiqueSec.densite)">
                <div class="iradio_square-green"
                  [ngClass]="{'checked': isChecked(echelleMiroir, typeCosmetique.cosmetiqueCheveuxSec, optionCosmetiqueSec.densite), 'disabled': !isEditable}">
                </div>
                <i></i>
                <label class="form-check-label">{{echelleMiroir.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <div class="col-12">
          <div class="d-flex flex-row">
            <div class="col-2">
              <label translate>FACILITE_PASSAGE_DOIGT</label>
            </div>
            <div class="col-1" *ngFor="let echelleMiroir of ficheRemanenceOptions.echelleMiroir">
              <div
                class="d-flex flex-row align-items-center"
                (click)="onChangeData(echelleMiroir, typeCosmetique.cosmetiqueCheveuxSec, optionCosmetiqueSec.facilitePassageDoigt)">
                <div class="iradio_square-green"
                  [ngClass]="{'checked': isChecked(echelleMiroir, typeCosmetique.cosmetiqueCheveuxSec, optionCosmetiqueSec.facilitePassageDoigt), 'disabled': !isEditable}">
                </div>
                <i></i>
                <label class="form-check-label">{{echelleMiroir.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <div class="col-12">
          <div class="d-flex flex-row">
            <div class="col-2">
              <label translate>ENROBAGE</label>
            </div>
            <div class="col-1" *ngFor="let echelleMiroir of ficheRemanenceOptions.echelleMiroir">
              <div class="d-flex flex-row align-items-center" (click)="onChangeData(echelleMiroir, 'cosmetiqueCheveuxSec', 'enrobage')">
                <div class="iradio_square-green"
                  [ngClass]="{'checked': isChecked(echelleMiroir, 'cosmetiqueCheveuxSec', 'enrobage'), 'disabled': !isEditable}">
                </div>
                <i></i>
                <label class="form-check-label">{{echelleMiroir.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <h4 class="text-center col-12" translate>TYPE_ENROBAGE</h4>
      </div>
      <div class="row margin-bottom">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>LEFT_VALUES</label>
            </div>
            <div class="col-8">
              <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
                [(ngModel)]="ficheRemanence.cosmetiqueCheveuxSec.typeEnrobageG">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let typeEnrobage of ficheRemanenceOptions.typeEnrobage" [ngValue]="typeEnrobage">
                  {{ typeEnrobage.name | translate}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>RIGHT_VALUES</label>
            </div>
            <div class="col-8">
              <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
                [(ngModel)]="ficheRemanence.cosmetiqueCheveuxSec.typeEnrobageD">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let typeEnrobage of ficheRemanenceOptions.typeEnrobage" [ngValue]="typeEnrobage">
                  {{ typeEnrobage.name | translate}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom"
        *ngIf="ficheRemanence.cosmetiqueCheveuxSec.typeEnrobageD?.id === '4' || ficheRemanence.cosmetiqueCheveuxSec.typeEnrobageG?.id === '4'">
        <div class="col-12 col-md-6">
          <div class="row" [hidden]="ficheRemanence.cosmetiqueCheveuxSec.typeEnrobageG?.id !== '4'">
            <div class="col-4"></div>
            <div class="col-8">
              <input [disabled]="!isEditable" class="form-control" placeholder="Type d'enrobage à gauche" type="text"
                [(ngModel)]="ficheRemanence.cosmetiqueCheveuxSec.typeEnrobageOtherG">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="row" [hidden]="ficheRemanence.cosmetiqueCheveuxSec.typeEnrobageD?.id !== '4'">
            <div class="col-4"></div>
            <div class="col-8">
              <input [disabled]="!isEditable" class="form-control" placeholder="Type d'enrobage à droite" type="text"
                [(ngModel)]="ficheRemanence.cosmetiqueCheveuxSec.typeEnrobageOtherD">
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-top margin-bottom">
        <div class="col-2">
          <label translate>FEEDBACK</label>
        </div>
        <div class="col-10">
          <textarea [disabled]="!isEditable" class="form-control" rows="2"
            [(ngModel)]="ficheRemanence.cosmetiqueCheveuxSec.commentaires"></textarea>
        </div>
      </div>
    </ng-container>
    <div class="d-flex flex-row margin-bottom border-top">
      <label class="col-3" style="padding-right: 5px" translate>EVALUATION_COULEUR</label>
      <dna-checkbox [disabled]="!isEditable" [isChecked]="ficheRemanence.isEvaluationCouleur"
        (onBooleanChanged)="changeValue('isEvaluationCouleur')"></dna-checkbox>
    </div>
    <ng-container *ngIf="ficheRemanence.isEvaluationCouleur">
      <div class="row margin-bottom">
        <h4 class="text-center col-12" translate>HT_RACINES</h4>
      </div>
      <div class="row margin-bottom">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>LEFT_VALUES</label>
            </div>
            <div class="col-8">
              <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
                [(ngModel)]="ficheRemanence.evaluationCouleur.htRacinesG">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let htRacines of ficheRemanenceOptions.htRacines" [ngValue]="htRacines">
                  {{ htRacines.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>RIGHT_VALUES</label>
            </div>
            <div class="col-8">
              <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
                [(ngModel)]="ficheRemanence.evaluationCouleur.htRacinesD">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let htRacines of ficheRemanenceOptions.htRacines" [ngValue]="htRacines">
                  {{ htRacines.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <h4 class="text-center col-12" translate>REFLET</h4>
      </div>
      <div class="row margin-bottom">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>LEFT_VALUES</label>
            </div>
            <div class="col-8">
              <input [disabled]="!isEditable" class="form-control" type="text"
                [(ngModel)]="ficheRemanence.evaluationCouleur.RefletG">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>RIGHT_VALUES</label>
            </div>
            <div class="col-8">
              <input [disabled]="!isEditable" class="form-control" type="text"
                [(ngModel)]="ficheRemanence.evaluationCouleur.RefletD">
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <h4 class="text-center col-12" translate>COUVERTURE_BLANC</h4>
      </div>
      <div class="row margin-bottom">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>LEFT_VALUES</label>
            </div>
            <div class="col-8">
              <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
                [(ngModel)]="ficheRemanence.evaluationCouleur.couvertureBlancG">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let couvertureBlanc of ficheRemanenceOptions.couvertureBlanc"
                  [ngValue]="couvertureBlanc">{{ couvertureBlanc.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>RIGHT_VALUES</label>
            </div>
            <div class="col-8">
              <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
                [(ngModel)]="ficheRemanence.evaluationCouleur.couvertureBlancD">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let couvertureBlanc of ficheRemanenceOptions.couvertureBlanc"
                  [ngValue]="couvertureBlanc">{{ couvertureBlanc.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom">
        <h4 class="text-center col-12" translate>UNISSON</h4>
      </div>
      <div class="row margin-bottom">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>LEFT_VALUES</label>
            </div>
            <div class="col-8">
              <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
                [(ngModel)]="ficheRemanence.evaluationCouleur.unissonG">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let couvertureBlanc of ficheRemanenceOptions.couvertureBlanc"
                  [ngValue]="couvertureBlanc">{{ couvertureBlanc.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>RIGHT_VALUES</label>
            </div>
            <div class="col-8">
              <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
                [(ngModel)]="ficheRemanence.evaluationCouleur.unissonD">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let couvertureBlanc of ficheRemanenceOptions.couvertureBlanc"
                  [ngValue]="couvertureBlanc">{{ couvertureBlanc.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="d-flex flex-row margin-bottom border-top">
      <label class="col-3" style="padding-right: 5px" translate>DEGRE_FRISURE</label>
      <dna-checkbox [disabled]="!isEditable" [isChecked]="ficheRemanence.isDegreFrissure"
        (onBooleanChanged)="changeValue('isDegreFrissure')"></dna-checkbox>
    </div>
    <ng-container *ngIf="ficheRemanence.isDegreFrissure">
      <div class="row margin-bottom">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>LEFT_VALUES</label>
            </div>
            <div class="col-8">
              <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
                [(ngModel)]="ficheRemanence.degreFrisureG">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let degreFrisure of ficheRemanenceOptions.degreFrisure" [ngValue]="degreFrisure">
                  {{ degreFrisure.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-4">
              <label translate>RIGHT_VALUES</label>
            </div>
            <div class="col-8">
              <select [disabled]="!isEditable" class="form-control" [compareWith]="compareById"
                [(ngModel)]="ficheRemanence.degreFrisureD">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let degreFrisure of ficheRemanenceOptions.degreFrisure" [ngValue]="degreFrisure">
                  {{ degreFrisure.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row margin-top margin-bottom border-top">
      <div class="col-2">
        <label translate>FEEDBACK</label>
      </div>
      <div class="col-10">
        <textarea [disabled]="!isEditable" class="form-control" rows="2"
          [(ngModel)]="ficheRemanence.comments"></textarea>
      </div>
    </div>
  </div>
</div>
