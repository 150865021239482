export default {
  "type": "table",
  "configs": {
    "subType": {
      "graph": "barchart"
    },
    "title": {
      "text": ""
    },
    "subtitle": {
      "text": ""
    },
    "yAxis": {
      "min": -6,
      "max": 6,
      "plotBands": [
        {
          "color": "rgba(239,236,236,0.5)",
          "from": -0.5,
          "to": 0.5,
        },
      ],
      "plotLines": [
        {
          "zIndex": 4,
          "color": '#787777',
          "dashStyle": 'shortdash',
          "width": 1,
          "value": 0.5
        },
        {
          "zIndex": 4,
          "color": '#787777',
          "dashStyle": 'shortdash',
          "width": 1,
          "value": -0.5,
        }
      ]
    },
    // Only usefull for plot options panel
    "plotOptions": {
      "grey_zone": {
        "enabled": false
      },
      "column": {
        "dataLabels": {
          "enabled": false
        }
      },
      "default_language" : {
          "enabled" : false
      }
    },
  }
}