import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Languages } from '../../enums';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: '[dna-scale-bar-tooltip]',
  templateUrl: './scale-bar-tooltip.component.html',
  styleUrls: ['./scale-bar-tooltip.component.scss']
})
export class ScaleBarTooltipComponent {
  @Input() color : string;
  @Input() data : { label : string, data : Array<any> };
  @Input() lang : string;
  
  public Languages = Languages;

  constructor() { }

};
