
import { of as observableOf, Observable, Subject } from 'rxjs';
import { catchError, tap, take, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { OnePager } from '../../../types';
import { OnePagerService } from '../../services/one-pager.service';
import { ErrorManagerService } from '../../services/errorManager.service';
import { UtilService } from '../../services/util.service';
import { ApplicationInsightsService } from '../../services/applicationInsights.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dna-one-pager-conclusions',
  templateUrl: './one-pager-conclusions.component.html',
  styleUrls: ['./one-pager-conclusions.component.less']
})
export class OnePagerConclusionsComponent implements OnInit, OnDestroy, AfterViewInit {

  onePager: OnePager;
  initialOnePager: OnePager;
  conclusionForm: FormGroup;
  initialConclusionForm: FormGroup;
  isChanged: boolean = false;
  isCancelAvailable: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  initTime = performance.now();

  constructor(
    private onePagerService: OnePagerService,
    private aiService: ApplicationInsightsService,
    private route: ActivatedRoute,
    private errorManagerService: ErrorManagerService,
    private utilService: UtilService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.init();
    this.initialConclusionForm = _.cloneDeep(this.conclusionForm);
  }

  private assignFormInOnePager = (form: any) => {
    this.onePager = Object.assign(this.onePager, form);
    this.onePagerService.setOnePager(this.onePager);
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Details One Pager Conclusions', '', performance.now() - this.initTime, templateUrl);
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.utilService.canDeactivate(this.isChanged, false);
  }

  ngOnDestroy() {
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
  }

  catchError = (err) => {
    this.errorManagerService.catchError(err);
    this.errorManagerService.displayMessage("ON_ERROR_UPDATE", "danger");
    return observableOf();
  };

  init() {
    this.onePager = this.onePagerService.getOnePager();
    this.initialOnePager = _.cloneDeep(this.onePager);
    this.conclusionForm = this.initConclusionForm(this.onePager);

    //Suppression de la sauvegarde auto temporairement le temps de trouver d'où viennent les pertes de données
    this.conclusionForm.valueChanges.pipe(
      tap(() => {
        if (!_.isEqual(this.formatFormValues(this.conclusionForm.value), this.formatFormValues(this.initialConclusionForm.value))) {
          this.isChanged = true;
          this.ref.detectChanges();
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  private initConclusionForm(onePager: OnePager): FormGroup {
    return new FormGroup({
      conclusion: new FormControl(onePager.conclusion),
      otherStrengths: new FormControl(onePager.otherStrengths),
      recommendation: new FormControl(onePager.recommendation),
      vigilance: new FormControl(onePager.vigilance)
    })
  }

  onCancel() {
    this.conclusionForm = _.cloneDeep(this.initialConclusionForm);
    this.isCancelAvailable = false;
    this.isChanged = false;
    this.ref.detectChanges();
  }

  save(conclusionForm: FormGroup, isAutoSave: boolean) {
    this.assignFormInOnePager(conclusionForm.value);
    this.updateOnePager(isAutoSave);
  }

  private updateOnePager = (isAutoSave: boolean) => {
    const elementsToUpdate = this.onePagerService.getElementToUpdate(this.initialOnePager, this.onePager)
    if (!Object.keys(elementsToUpdate).length) {
      this.errorManagerService.displayMessage("ON_SUCCESS_UPDATE");
    } else {
      if (!isAutoSave) {
        this.errorManagerService.displayMessage("ON_SAVE_PROCESSING", "info");
      }
      this.onePagerService.updateOnePager(this.onePager.id, elementsToUpdate).pipe(take(1),
          tap(() => this.errorManagerService.displayMessage("ON_SUCCESS_UPDATE")),
          catchError(this.catchError),
          tap((onePager: OnePager) => {
            this.onePager = onePager;
            this.onePagerService.setOnePager(this.onePager);
            this.initialConclusionForm = _.cloneDeep(this.conclusionForm);
            this.isChanged = false;
            this.ref.detectChanges();
          })
        ).subscribe();
    }
  }

  formatFormValues(formValues: any) {
    return {
      ...formValues,
      recommendation: this.utilService.htmlToText(formValues.recommendation),
      vigilance: this.utilService.htmlToText(formValues.vigilance),
      otherStrengths: this.utilService.htmlToText(formValues.otherStrengths),
      conclusion: this.utilService.htmlToText(formValues.conclusion)
    };
  }
}
