import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dna-formulas-insertion-modal',
  templateUrl: './formulas-insertion-modal.component.html',
  styleUrls: ['./formulas-insertion-modal.component.less']
})
export class FormulasInsertionModalComponent implements OnInit {
  formulas: string;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.activeModal.dismiss();
  };

  onInsert(formulas: string = ''): Array<object> {
    let objArray = [];
    if (formulas) {
      let formulasArray: Array<string> = formulas.split("\n")
        .map(array => array.split("\t").filter(formula => formula))
        .reduce((acc, curr) => acc.concat(curr));

      objArray = formulasArray.map(formula => {
        return { name: formula.toUpperCase() };
      });
    }

    return objArray;
  }

  validate(object: Object) {
    this.activeModal.close(object);
  }

}
