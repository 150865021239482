<td (click)="onClickBlock(block.id)">
  <dna-translate [fieldToTranslate]="block.name"> </dna-translate>
  <span *ngIf="!block.name[currentLanguage] && block.name.english">
    <i>(english version)</i>
  </span>
</td>
<td (click)="onClickBlock(block.id)" translate>{{ block.state }}</td>
<td (click)="onClickBlock(block.id)">{{ block.created_on | date:'yyyy-MM-dd' }}</td>
<td (click)="onClickBlock(block.id)">{{ block.updated_on | date:'yyyy-MM-dd' }}</td>
<td>
    <div class="d-flex flex-row gap-2 align-items-center">
      <dna-favorites [id]="block.id" type="block"></dna-favorites>
      <dna-action-bar class="d-flex w-100 gap-2" [actionsButtons]="actionsButtons$ | async" (onClick)="onClickButton($event)"></dna-action-bar>
    </div>
</td>
