<ngx-loading [show]="showSpinner"></ngx-loading>
<form [formGroup]="exportForm" (ngSubmit)="onSubmit(exportForm)">
  <div class="form-group">
    <label class="control-label col-sm-5" translate>CHOOSE_BLOCK_EXPORT</label>
    <div class="col-sm-6">
      <tag-input class="form-control" formControlName="blockToExport" [maxItems]="1" [onlyFromAutocomplete]="true" name="blockToExport"
        [secondaryPlaceholder]="'TYPE_NAME' | translate: {object: 'BLOCK' | translate | lowercase}">
        <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="blocks" [displayBy]="'name'" [identifyBy]="'id'" [minimumTextLength]="3" [showDropdownIfEmpty]="false">
        </tag-input-dropdown>
      </tag-input>
    </div>
  </div>
  <div class="form-group">
    <button class="btn btn-primary" [disabled]="exportForm.pristine || exportForm.invalid" type="submit" translate>EXPORT</button>
    <button class="btn btn-default" type="button" (click)="cancel()" translate>CANCEL</button>
  </div>
  <div *ngIf="isDisplayed" class="form-group">
    <label for="export">
      <textarea id="export" class="form-control" formControlName="blockJSON"></textarea>
    </label>
  </div>
</form>
