import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TargetKeys, TargetMulti, Campaign, CampaignMultiTarget } from '../../../types'
import * as _ from 'lodash';
import { MultiCampaignsService } from '../../multi-campaigns.service';
import { flatMap, tap, catchError } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { UtilService } from '../../../shared/services/util.service';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';



@Component({
  selector: 'dna-target-multi',
  templateUrl: './target-multi.component.html',
  styleUrls: ['./target-multi.component.less']
})
export class TargetMultiComponent implements OnInit, AfterViewInit {

  campaignTarget: Campaign[] = [];
  targetHead: string[] = [];
  targetTraduction: any;
  widthTable: number = 0;
  showSpinner: boolean;
  error: boolean = false;
  initTime = performance.now();

  constructor(
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private multiCampaignsService: MultiCampaignsService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Multi Campaigns Details Target', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.showSpinner = true;
    this.error = false;
    this.route.parent.paramMap.pipe(
      flatMap((params: ParamMap) => this.multiCampaignsService.getCampaignMultiTarget(params.get('idCampaignMulti'))),
      catchError(err => {
        this.error = true;
        return observableOf([]);
      }),
      tap((data: CampaignMultiTarget) => this.campaignTarget = this.utilService.formatTargetData(data.studies)),
      tap((data: CampaignMultiTarget) => this.multiCampaignsService.hasNameChanged.next(data.name)),
      tap(() => this.targetTraduction = TargetKeys),
      tap(() => this.targetHead = this.utilService.getTargetHead(this.campaignTarget)),
      tap(() => this.widthTable = this.targetHead.length * 200 + 300),
      tap(() => this.showSpinner = false),
      tap(() => this.utilService.formatTable(this.targetHead, this.campaignTarget))
    ).subscribe();
  }

  getValues(array: any) {
    return !_.isEmpty(array) ? array.filter(a => a.IsValue) : array;
  }

  getTargetHead(campaignTarget): string[] {
    let head: string[] = [];
    campaignTarget.forEach(cp => {
      if (_.get(cp, 'target', undefined)) {
        for (let targetKey of Object.keys(cp.target)) {
          if (!_.isEmpty(cp.target[targetKey].filter(elm => elm.IsValue))) {
            head.push(targetKey);
          }
        }
      }
    });
    return _.uniq(head);
  }


}
