<form role="form" [formGroup]="editNameForm">
  <div class="modal-header">
    <h3 class="text-center" translate>EDIT_COMPONENT_NAME</h3>
  </div>
  <div class="table-responsive">
    <table class="table table-borderless-th">
      <thead>
        <tr>
          <th translate>LANGUAGE</th>
          <th translate>NAME</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let language of languages">
          <td>
            <label [ngClass]="{required: language === 'english'}" translate>{{ language }}</label>
          </td>
          <td formGroupName="label">
            <input class="form-control" type="text" formControlName="{{language}}">
            <div *ngIf="language === 'english' && ((editNameForm.submitted && editNameForm.invalid) || (editNameForm.dirty && editNameForm.invalid))"
              class="alert alert-danger" translate>
              REQUIRED
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <label class="col-sm-12"></label>
  <div class="modal-footer">
    <button id="save" class="btn btn-primary mb-4" type="button" (click)="save(editNameForm)" translate>SAVE</button>
    <button id="cancel" class="btn btn-default mb-4" type="button" (click)="cancel()" translate>CANCEL</button>
  </div>
</form>
