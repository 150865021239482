<div class="row">
  <div class="col-sm-6">
    <div class="list-group" *ngFor="let block of workflowConfiguration.blocks; let index = index">
      <ng-container *ngIf="!block.parameters?.inactive">
        <span class="badge badge-secondary label-loop" *ngIf="minIndex == index" translate>LOOP.START</span>
        <div class="list-group-item" (click)="setLoopItem(index)"
          [ngClass]="{ 'active': firstItemSelected === index || (index >= minIndex && index <= maxIndex) }">
          <span>
            <dna-translate [fieldToTranslate]="block.name"></dna-translate>{{block.suffix}}
          </span>
        </div>
        <span class="badge badge-secondary label-loop" *ngIf="maxIndex == index" translate>LOOP.END</span>
      </ng-container>
    </div>
  </div>
  <div class="col-sm-6">
    <p *ngIf="firstItemSelected === undefined" translate>LOOP.SELECT_FIRST</p>
    <p *ngIf="firstItemSelected !== undefined && secondItemSelected === undefined" translate>LOOP.SELECT_SECOND</p>
    <div *ngIf="secondItemSelected !== undefined ">
      <div class="form-group col-sm-12">
        <label class="col-sm-12" translate>LOOP.NBR</label>
        <input type="number" class="form-control col-sm-12" name="loopNumber" [(ngModel)]="nbIterationLoop" min="2"
          max="5" (ngModelChange)="calculateRange(nbIterationLoop)" [disabled]="!isWorkflowEditable">
      </div>
      <div class="form-group col-sm-12 mt-3" *ngFor="let index of range">
        <label class="col-sm-12">{{ "LOOP.LABEL_START" | translate }}: {{ index+1 }}</label>
        <input type="text" class="form-control col-sm-12" [(ngModel)]="labelLoop[index]"
          [disabled]="!isWorkflowEditable">
      </div>
      <div class="form-group col-sm-12 mt-3">
        <label class="col-sm-12" translate>ADD_DUPLICATED_BLOC</label>
        <select id="duplicatedBlocLocation" class="form-control" name="duplicatedBlocLocation"
          [(ngModel)]="duplicatedBlocLocation" [compareWith]="compare" [disabled]="!isWorkflowEditable">
          <option *ngFor="let location of blocDuplicateLocationList" [ngValue]="location">{{location.value | translate
            }}</option>
        </select>
      </div>
      <div class="form-group col-sm-12 mt-3">
        <div *ngIf="isWorkflowEditable; else nonEditable" class="d-flex flex-row gap-1">
          <button class="btn btn-primary mr-2" type="button"
            (click)="createLoop(minIndex, maxIndex, nbIterationLoop, labelLoop)" translate>CREATE_REPETITION</button>
          <button class="btn btn-default" type="button"
            (click)="firstItemSelected = undefined; secondItemSelected = undefined; minIndex = undefined; maxIndex = undefined"
            translate>CANCEL</button>
        </div>
        <ng-template #nonEditable>
          <button class="btn btn-default" type="button"
            (click)="firstItemSelected = undefined; secondItemSelected = undefined; minIndex = undefined; maxIndex = undefined"
            translate>CLOSE</button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
