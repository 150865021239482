import { HttpRestService } from '../services/httpRest.service';
import { User } from '../../types';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dna-load-name',
    templateUrl: './dnaLoadName.component.html'
})

export class LoadNameComponent implements OnInit {
    @Input() user: User;
    
    constructor(private httpRestService: HttpRestService) { }

    ngOnInit() {
      
    this.httpRestService.getPeople(this.user.key).subscribe(
        response => this.user = response,
        error => this.user.name = "Not found"
    );         
    }
}
