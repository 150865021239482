import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationInsightsService } from '../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-confirm-publish-modal',
  templateUrl: './confirm-publish-modal.component.html',
  styleUrls: ['./confirm-publish-modal.component.less']
})
export class ConfirmPublishModalComponent implements AfterViewInit {

  @Input() loopAndNoVisits: {
    idWorkflow: string,
    workflowName: string,
    loopAndNoVisite: boolean
  }[];
  @Input() type: string;

  initTime = performance.now();

  constructor(
    private activeModal: NgbActiveModal,
    private aiService: ApplicationInsightsService
    ) { }

  onCancel() {
    this.activeModal.dismiss();
  }

  ngAfterViewInit() {
    this.aiService.logPageView('Comfirm Publish Modal', '', performance.now() - this.initTime, 'Modals');
  }

  onValidate() {
    this.activeModal.close(true);
  }

}
