import { Injectable, OnDestroy } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { OnePager } from "../../../types";
import { OnePagerService } from "../../../shared/services/one-pager.service";
import { Observable, of, Subject } from "rxjs";
import { MultiCampaignsService } from '../../multi-campaigns.service';
import {  flatMap, catchError, takeUntil } from 'rxjs/operators';

@Injectable()
export class OnePagerMultiResolver implements Resolve<OnePager>, OnDestroy {

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private onePagerService: OnePagerService,
        private campaignMultiService: MultiCampaignsService
        ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<OnePager> {
        const idCampaignMulti = route.parent.paramMap.get('idCampaignMulti');
        this.campaignMultiService.setCampaignMultiId(idCampaignMulti);
        return this.campaignMultiService.getCampaignMultiOnePager(idCampaignMulti).pipe(
            flatMap((result : {id: string}) => this.onePagerService.getAndBuildOnePagerMulti(result.id, idCampaignMulti)),
            takeUntil(this.destroy$),
            catchError(err=> {
                return of(undefined);
            }));
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}