<div class="container">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <div class="ibox float-e-margins" *ngIf="!error && !showSpinner">
    <div class="ibox-title mb-2">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "VISITS" | translate }}</h5>
        </div>
        <div class="col-12 col-md-8">
          <div *ngIf="visitsChanged" class="float-end">
            <ng-container>
              <dna-save-cancel (onClickSave)="save()" (onClickCancel)="onCancel()"></dna-save-cancel>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!errorWorkflow">
      <div class="row col-12 mb-2 px-3">
        <label class="col-2 form-control-label" translate>SELECTION</label>
        <div class="col-1">
          <dna-switch [isSelected]="campaignVisits.visitSelection" [disabled]="publishedTemplate" (onBooleanChanged)="setSelection()"></dna-switch>
        </div>
        <div class="col-9">{{ "VISIT_SELECTION" | translate}}</div>
      </div>
      <div class="row col-12 mb-2 px-3">
        <div class="col-3">{{ "VISITS_NUMBER_BY_VOLUNTEER" | translate}}</div>
        <div class="col-9"><input type="number" [(ngModel)]="campaignVisits.nbVisitsByVolunteer" disabled></div>
      </div>
      <dna-visits-page *ngIf="campaignVisits" [campaignVisits]="campaignVisits" [initSelectedBlocks]="initSelectedBlocks" [onErrorVisitsValues]="onErrorVisitsValues" (visitsChanged)="visitsUpdated($event)"></dna-visits-page>
    </ng-container>
      <div *ngIf="!showSpinner && error" class="ibox float-e-margins">
      <div class="ibox-title">
        <div class="row">
          <div class="col-12 col-md-4 my-auto">
            <h5>{{ "ERROR" | translate }}</h5>
          </div>
        </div>
      </div>
      <dna-error-page (onClickReload)="init()"></dna-error-page>
    </div>
    <div *ngIf="!showSpinner && errorWorkflow" class="ibox float-e-margins">
      <div class="ibox-title">{{ "ERROR_VISITS_WORKFLOW" | translate}}</div>
    </div>
  </div>
</div>
