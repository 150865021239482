import {
  NgModule
} from '@angular/core';
import {
  ReactiveFormsModule
} from '@angular/forms';
import {
  RouterModule,
  Routes
} from '@angular/router';

import {
  DragulaModule
} from 'ng2-dragula';

import {
  AuthGuardService
} from '../shared/services/authenticationGuard.service';
import {
  BlockComponent
} from './detail/block.component';
import {
  BlockDetailComponent
} from './detail/block-detail/block-detail.component';
import {
  BlockRowComponent
} from './list/row/block-row.component';
import {
  BlocksComponent
} from '../blocks/list/blocks.component';
import { BlockService } from './blocks.service';
import {
  ComponentConfigurationComponent
} from './detail/component/configuration/component-configuration.component';
import {
  ComponentConfigurationModule
} from '../components/args/componentConfiguration.module';
import {
  ComponentModalEditNameComponent
} from './detail/component/edit-name/component-modal-edit-name.component';
import {
  ComponentSelectionComponent
} from './detail/component/selection/component-selection.component';
import { PreviewComponents } from './detail/block-detail/preview-components/preview-components.component';
import {
  SharedModule
} from '../shared/shared.module';

const appRoutes: Routes = [
  {
    path: 'blocks',
    component: BlocksComponent,
    canActivate: [AuthGuardService],
    data: {
      role: 'DNA_BLOCK'
    }
  },
  {
    path: 'blocks/:idBlock',
    component: BlockComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
    resolve: {
      block: BlockService
    },
    data: {
      role: 'DNA_BLOCK'
    },
    children: [{
      path: 'addComponents',
      component: ComponentSelectionComponent
    },
    {
      path: 'editComponent',
      component: ComponentConfigurationComponent
    },
    {
      path: 'previewComponents',
      component: PreviewComponents
    },
    {
      path: '',
      component: BlockDetailComponent,
      canDeactivate: [AuthGuardService]
    }]
  }
];

@NgModule({
  imports: [
    ComponentConfigurationModule,
    DragulaModule.forRoot(),
    ReactiveFormsModule,
    RouterModule.forChild(appRoutes),
    SharedModule
  ],
  exports: [
    BlockComponent
  ],
  declarations: [
    BlockComponent,
    BlockDetailComponent,
    BlockRowComponent,
    BlocksComponent,
    ComponentConfigurationComponent,
    ComponentModalEditNameComponent,
    ComponentSelectionComponent,
    PreviewComponents
  ]
})
export class BlocksModule { }
