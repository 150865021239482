import {Chart} from '../chart.model';
import {TornadoOptions} from './tornado-options.model';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import * as TornadoLabelFormatter from './tornado.label.formatter';
import * as TornadoTooltipFormatter from './tornado.tooltip.formatter';
import {Languages, COMPUTING_METHODS} from '../../enums';

import * as TornadoChartMedianCompute from './methods/median.method';
import {Descriptor} from '../../../../../../../../types';
import {Table} from '../table';


const mapSettingsKey: Map<string, string> = new Map();
mapSettingsKey.set( 'scale', 'yAxis' );

interface PositionInterface {
    category: string | number;
    position: number[];
}

interface DataSumInterface {
    category: string | number;
    stacks: {
        label: string;
        value: number;
        elements: Highcharts.SVGElement[];
    }[];
}

/**
 * Reorder tornado graph data by categories
 * @param el 
 */
const translateGraph = ( el ) => {
    const chart: Highcharts.Chart = el.target;
    const series = chart.series;

    // GET summ by data and category and stacking
    const dataSum: DataSumInterface[] = [];
    // Get position of stacked data by category
    const positions: PositionInterface[] = [];

    series.forEach( serie => {
        const stackKey = serie[ "stackKey" ];
        serie.points.forEach( pointItem => {
            const category = pointItem.x;
            const value = Math.abs( pointItem.y );
            let categoryData = dataSum.find( datas => {
                return datas.category === category;
            } );

            if( !categoryData ) {
                categoryData = {
                    category: category,
                    stacks: []
                };
                dataSum.push( categoryData );
            }

            let stackData = categoryData.stacks.find( stack => {
                return stack.label === stackKey;
            } );

            if( !stackData ) {
                stackData = {
                    label: stackKey,
                    value: 0,
                    elements: []
                };
                categoryData.stacks.push( stackData );
            }

            stackData.value += value;
            stackData.elements.push( pointItem[ "graphic" ] );

            let categoryPosition = positions.find( pos => {
                return pos.category === category;
            } );

            if( !categoryPosition ) {
                categoryPosition = {
                    category: category,
                    position: []
                };
                positions.push( categoryPosition );
            }

            const xPosition = pointItem[ "shapeArgs" ].x;

            if( !categoryPosition.position.includes( xPosition ) ) {
                categoryPosition.position.push( xPosition );
            }
        } );
    } );

    // sort positions DESC
    positions.forEach( p => {
        p.position.sort( ( a, b ) => {
            return b - a;
        } );
    } );

    dataSum.forEach( ds => {
        // sort stack sum values DESC
        ds.stacks.sort( ( a, b ) => {
            return b.value - a.value;
        } );

        const category = ds.category;
        const innerPositions = positions.find( p => {
            return p.category === category;
        } ).position;
        // Reassiging x positions depending of sum value
        ds.stacks.forEach( ( ds, index ) => {
            ds.elements.forEach( el => {
                if( el ) {
                    el.attr( {
                        x: innerPositions[ index ],
                    } );
                }
            } );
        } );
    } );
};

export class Tornado extends Chart {
    protected _baseParameters: any = {
        chart: {
            type: 'bar',
            inverted: true,
            events: {
                load: function ( el ) {
                    this.parameters.series.forEach( ( serie: any ) => {
                        serie.color = el.target.series.find( ( x: any ) => x.name === serie.name ).color;
                    } );
                    this.updateChartTranslations( el.target, this.parameters, this.lang );
                    this.buildGraphOptions( this.parameters );
                    this.onOptionChangeAfterLoadSubject.next( this.options );
                    translateGraph( el );
                }.bind( this ),
                redraw: translateGraph
            }
        },
        xAxis: {
            type: 'category',
            crosshair: true,
            useHTML: true,
            labels: {
                formatter: function ( el: any ) {
                    const {userOptions} = el.chart;
                    let langs: Array<string> = [ this.lang ];
                    if( userOptions.hasOwnProperty( 'plotOptions' ) && userOptions.plotOptions.hasOwnProperty( 'default_language' ) ) {
                        if( userOptions.plotOptions.default_language.enabled && langs.indexOf( Languages.Default ) === -1 ) langs.push( Languages.Default );
                    }
                    if( userOptions.hasOwnProperty( 'plotOptions' ) && userOptions.plotOptions.hasOwnProperty( 'attributes_blocks' ) ) return TornadoLabelFormatter.formatter( el, langs, userOptions.plotOptions.attributes_blocks.enabled );
                    else return TornadoLabelFormatter.formatter( el, langs, false );

                }.bind( this ),
            }
        },
        yAxis: {
            tickInterval: 1,
            title: {
                enabled: true,
                text: ''
            }
        },
        tooltip: {
            //shared: true,
            useHTML: true,
            formatter: function ( el: any ) {
                const {userOptions} = el.chart;
                if( userOptions.hasOwnProperty( 'plotOptions' ) && userOptions.plotOptions.hasOwnProperty( 'attributes_blocks' ) ) return TornadoTooltipFormatter.formatter( this, userOptions.language, userOptions.plotOptions.attributes_blocks.enabled );
                else return TornadoTooltipFormatter.formatter( this, userOptions.language, false );
            },
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false,
                    inside: false,
                    color: 'black',
                }
            },
            bar: {
                stacking: "normal",
                dataLabels: {
                    enabled: false,
                    color: 'black',
                }
            }
        },
        subtitle: {
            marginTop: 100
        }
    };

    private initialData: any;
    public onDrillSubject = new Subject<string>();
    private drilledState: boolean = false;
    public static mapSettings: Map<string, string> = mapSettingsKey;

    constructor (
        _parameters?: any,
        _data?: Array<any>,
        _lang?: string,
        _filters?: any,
        private _translateService?: TranslateService
    ) {
        super( _parameters, _data, _lang, _filters );
        this.tableRawData = this.formatRawData( _data, _lang );
        this.generateParametersAssignment( this.parameters );
        this.generateDataAssignment( this.data, this.lang );
    };

    private generateParametersAssignment ( parameters ) {
        Object.assign( {...this._baseParameters, parameters} );
        Object.keys( {...this._baseParameters} ).forEach( ( k ) => {
            this.parameters[ k ] = Object.assign( {...this._baseParameters[ k ]}, parameters[ k ] );
        } );
        Object.keys( {...parameters} ).forEach( ( k ) => {
            this.parameters[ k ] = Object.assign( {...this.parameters[ k ]}, parameters[ k ] );
        } );
    };

    protected generateDataAssignment = ( data: any, lang: string ) => {
        this.data = data;
        if( this.initialData != data ) this.initialData = data;
        const method = this.parameters.compute ? this.parameters.compute.method : null;
        const baseKey = this.parameters.compute ? this.parameters.compute.key : 'key';
        const payload = this.formatData(
            this.initialData,
            method,
            baseKey,
            lang,
            this.parameters,
            this.descriptors,
            this.routines );
        this.parameters.series = payload.series;
        this.parameters.xAxis.categories = payload.categories;
        this.parameters.details = payload.details;

        if( this.chart ) this.updateChartTranslations( this.chart, this.parameters, this.lang );
    };

    /**
     * buildGraphOptions
     */
    public buildGraphOptions = ( options: any ) => {
        const {title, subtitle, xAxis, yAxis, series, plotOptions, ...rest} = options;
        this.options = new TornadoOptions( {
            general: {title, subtitle},
            xAxis,
            yAxis,
            series,
            plotOptions
        }, this.onOptionsChange );
    };

    protected setParamsToAxis = ( params: any, axis: any ) => Object.assign( axis, params );

    protected formatData = (
        data: Array<any>,
        method: string,
        baseKey: string,
        lang: string,
        parameters: any,
        descriptors: Array<Descriptor>,
        routines: Array<any>
    ): {categories: Array<any>, series: Array<any>, details: any;} => {
        const payload = {
            categories: {},
            series: {},
            details: {}
        };

        switch( method ) {
            case COMPUTING_METHODS.MEDIAN:
                TornadoChartMedianCompute.doWork( data, baseKey, lang, parameters, descriptors, payload, routines );
                payload.details = TornadoChartMedianCompute.doTableWork( Object.values( payload.categories ), Object.values( payload.series ), baseKey, lang, {} );
                break;
        }
        return {
            categories: Object.values( payload.categories ),
            series: Object.values( payload.series ),
            details: payload.details
        };
    };

    private onOptionsChange = ( options: any ) => {
        const routinesMapping: Map<string, any> = new Map();
        let gotColorChange = false;

        if( this.routines.routines ) {
            this.routines.routines.forEach( r => {
                routinesMapping.set( r.name, r );
            } );
        }

        let fontOptions: any;

        for( let o in options ) {
            if( o === 'general' ) {
                const {font, ...rest} = options[ o ];
                Object.assign( this.parameters, rest );
                fontOptions = font;
            } else {
                for( let e in options[ o ] ) {
                    if( Array.isArray( options[ o ][ e ] ) ) {
                        options[ o ][ e ].forEach( x => {
                            Object.assign( this.parameters[ e ].find( y => y.name === x.name ), x );

                            if( e === 'series' && routinesMapping.has( x.name ) ) {
                                gotColorChange = true;
                                routinesMapping.get( x.name ).color = x.color;
                            }
                        } );
                    } else {
                        Object.assign( this.parameters[ e ], options[ o ][ e ] );
                    }
                }
            }
        }
        const {yAxis, xAxis, ...parameters} = this.parameters;
        Object.assign( this.parameters, {...parameters} );
        Object.assign( this.parameters.yAxis, yAxis );
        Object.assign( this.parameters.xAxis, xAxis );

        if( gotColorChange ) this.custom.updateRoutines( this.routines.routines.map( x => x ) );

        const chart = this.build();
        if( fontOptions ) this.updateChartFont( chart, fontOptions );
        this.updateChartTranslations( chart, this.parameters, this.lang );
    };

    /**
   * updateChartTranslations
   * Method to manage languages changes
   * @param chart : Highcharts.Chart : Rendered chart
   * @param parameters : any : Chart's parameters
   * @param lang : string
  */
    private updateChartTranslations = ( chart: Highcharts.Chart, parameters: any, lang: string ): void => {
        this.updateTitleTranslations( chart, lang, parameters );
        this.updateSubTitleTranslations( chart, lang, parameters );
        this.updateCategoriesTranslations( chart, parameters );
        this.updateSeriesTranslations( chart );
        this.updatePlotlinesAndBandsTranslations( chart );

        chart.redraw();
    };

    /**
     * updateCategoriesTranslations
     * Method to update categories' translations
     * @param chart : Highcharts.Chart : Rendered chart
     * @param parameters : any : Chart's parameters
    */
    private updateCategoriesTranslations = ( chart: any, parameters: any ): void => {
        chart.xAxis[ 0 ].update( {
            categories: parameters.xAxis.categories
        } );
    };

    /**
     * updatePlotlinesAndBandsTranslations
     * Method to update plot lines and bands translations
     * @param chart : Highcharts.Chart : Rendered chart
    */
    private updatePlotlinesAndBandsTranslations = ( chart: any ): void => {
        const keys = [ ...chart.yAxis[ 0 ].plotLinesAndBands ].map( x => x.options.translationKey );
        try {
            this._translateService
                .get( keys )
                .subscribe( ( res: any ) => {
                    chart.yAxis[ 0 ].plotLinesAndBands
                        .forEach( ( renderedPlotlineOrBand: any, index: number ) => {
                            if( res[ renderedPlotlineOrBand.options.translationKey ] ) chart.yAxis[ 0 ].plotLinesAndBands[ index ].label.attr( {
                                text: res[ renderedPlotlineOrBand.options.translationKey ].toUpperCase()
                            } );
                        } );
                } );
        } catch( e ) {}
    };

    /**
     * updateSeriesTranslations
     * Method to update series' translations
     * @param chart : any : Rendered chart
    */
    private updateSeriesTranslations = ( chart: any ): void => {
        const keys = [ ...chart.series ].map( x => x.userOptions.translationKey );
        try {
            this._translateService
                .get( keys )
                .subscribe( ( res: any ) => {
                    chart.series
                        .forEach( ( renderedSerie: any, index: number ) => {
                            if( res[ renderedSerie.userOptions.translationKey ] ) chart.series[ index ].update( {name: `${renderedSerie.userOptions.baseLabel} - ${res[ renderedSerie.userOptions.translationKey ]}`}, false );
                        } );
                } );
        } catch( e ) {}
    };

    protected formatRawData = ( data: Array<any>, lang: string ): {header: Array<any>, body: Array<any>;} => {
        try {
            const payload = JSON.parse( JSON.stringify( data[ 'data' ] ) ).reduce( ( accumulator: any, object: any, itemNumber: number ) => {
                const payload = object.values.reduce( ( reducer: any, item: any, index: number ) => {
                    item.values.reduce( ( redacc: any, value: any, idx: number ) => {
                        redacc[ value.label ] = value;
                        return redacc;
                    }, reducer );
                    return reducer;
                }, {} );

                const keyList = Object.keys( payload );
                const oPayload = object.values.reduce( ( reducer: any, item: any, index: number ) => {
                    if( item.values.length !== keyList.length ) {
                        for( let obj of keyList ) {
                            const foundObject = item.values.find( ( x: any ) => {return x.label === obj;} );
                            if( !foundObject ) {
                                item.values.push( {
                                    label: payload[ obj ].label,
                                    value: {label: "", key: ""}
                                } );
                            }
                        }
                    }

                    item.values.sort( ( a, b ) => {
                        const labelA = Chart.getObjectValueTranslation( a.label, lang );
                        const labelB = Chart.getObjectValueTranslation( b.label, lang );
                        return labelA.localeCompare( labelB );
                    } );

                    return reducer;
                }, object );
                accumulator.push( oPayload );
                return accumulator;
            }, [] );

            data[ 'data' ] = Object.values( payload.reduce( ( accumulator: any, object: any, itemNumber: number ) => {
                if( object.attribute.hasOwnProperty( 'blockName' ) ) {
                    const key = object.attribute.blockName.english;
                    accumulator[ key ] = accumulator[ key ] || {"attribute": {"label": object.attribute.blockName}, "values": []};
                    accumulator[ key ].values.push( object );
                }
                return accumulator;
            }, {} ) );

            const tableTemp = new Table( {}, data, lang );
            return tableTemp.parameters.transformedHTMLData;
        } catch( e ) {
            return {header: [], body: []};
        }
    };

    private updateChartFont = ( chart: Highcharts.Chart, option: {size: number, bold: boolean;} ): void => {
        try {
            chart.yAxis[ 0 ].update( {
                labels: {
                    ...chart.yAxis[0].userOptions.labels,
                    style: {
                        fontSize: option.size + 'px',
                        fontWeight: option.bold ? 'bold' : 'normal',
                    }
                }
                }, false );
        
                chart.xAxis[ 0 ].update( {
                labels: {
                    ...chart.xAxis[ 0 ].userOptions.labels,
                    style: {
                        fontSize: option.size + 'px',
                        fontWeight: option.bold ? 'bold' : 'normal'
                    }
                }
            }, false );
        } catch( e ) {
            console.error('error in updateChartFont, tornado');
        }
    }
};