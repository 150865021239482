<div class="modal-header">
  <h4 class="modal-title" translate>LOGIC_JUMP</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
  </button>
</div>
<div class="modal-body">
  <div class="card" *ngIf="previousQuestions.length === 0; else prevQuestions">
    <div class="card-body">
      <div class="card-text row text-center">
        <div class="col" style="padding-top: 8px;" translate>NO_LOGIC_JUMPS</div>
      </div>
    </div>
  </div>
  <ng-template #prevQuestions>
    <div class="card" *ngIf="logicJumps.length === 0">
      <div class="card-body">
        <div class="card-text d-flex flex-row justify-content-between">
          <div style="padding-top: 8px;" translate>WANT_TO_ADD_LOGIC</div>
          <div class="text-right">
            <button class="btn btn-outline-dark" (click)="addLogicJump()" translate>ADD_LOGIC_JUMP</button>
          </div>
        </div>
      </div>
    </div>
    <div class="container" dragula="logic-jump-bag" [(dragulaModel)]='logicJumps' *ngIf="logicJumps.length > 0">
      <div style="margin-bottom:20px;" *ngFor="let logicJump of logicJumps; let index = index">
        <dna-logic-jump-block [blockName]="blockName" [previousQuestions]="previousQuestions" [nextQuestions]="nextQuestions" [logicJump]="logicJump"
          [logicJumpLength]="logicJumps.length" (onRemoveJump)="logicJumps.splice(index,1)" (onAddJump)="addLogicJump()"></dna-logic-jump-block>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="card-text form-row d-flex flex-row align-items-center" style="margin-top: 10px">
            <div translate class='col-4'>ELSE_GO_TO</div>
            <div class='col-8 form-group'>
              <select class='form-control' name="selectedQuestion" [compareWith]="compareQuestion" [ngModel]="selectedQuestion" (ngModelChange)="onChange($event)">
                <option *ngFor='let question of nextQuestions' [ngValue]="question">{{question.libelle}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="close()" translate>SAVE</button>
  <button type="button" class="btn btn-outline-dark" (click)="dismiss()" translate>CANCEL</button>
</div>
