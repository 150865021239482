<div class="container">
  <div class="ibox float-e-margins">
    <div class="ibox-content">
      <form [formGroup]="headerForm">
        <h1>{{ "STUDY" | translate }}: {{ campaign.name }} </h1>
        <h5>({{ "MODIFY_ON" | translate }} {{ campaign.updated_on | date:'dd-MM-yyyy hh:mm' }})</h5>
        <div class="d-flex flex-row justify-content-between w-100 pt-1">
          <h3 translate class="align-self-center">ONE_PAGER_IDENTIFICATION</h3>
          <button *ngIf="!isChanged" [disabled]="booleanIsOnePagerFromCampaign" id="init" class="btn btn-primary mb-4" type="button"
            (click)="reinitForm()" translate>RESET</button>
          <div *ngIf="isChanged" class="d-flex flex-row gap-1">
            <button id="save" class="btn btn-primary mb-4 " type="button" (click)="save(headerForm)"
              translate>SAVE</button>
            <button id="cancel" class="btn btn-default mb-4" type="button" (click)="onCancel()"
              translate>CANCEL</button>
          </div>
        </div>
        <div class="pt-1 form-group">
          <div class="table-responsive">
            <table class="table table-bordered">
              <tr>
                <th class="table-secondary identification-th" translate>PROJECT_NAME</th>
                <td class="identification-td">
                  <input class="form-control" type="text" formControlName="projectName" name="input-project-name"
                    placeholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: {'object': 'PROJECT' | translate | lowercase} }}">
                </td>
              </tr>
              <tr>
                <th class="table-secondary identification-th">
                  {{ "NUMBER_ELEMENT" | translate: { element: "Actiview" } }}</th>
                <td class="identification-td">
                  <input class="form-control" type="text" disabled formControlName="actiview" name="input-actiview"
                    placeholder="{{ 'ENTER_MASC' | translate: { element: 'NUMBER' | translate | lowercase } }}">
                </td>
              </tr>
              <tr>
                <th class="table-secondary identification-th">{{ "NUMBER_ELEMENT" | translate: { element: "Synergy" } }}
                </th>
                <td class="identification-td">
                  <input class="form-control" type="text" disabled formControlName="bridge" name="input-bridge"
                    placeholder="{{ 'ENTER_MASC' | translate: { element: 'NUMBER' | translate | lowercase } }}">
                </td>
              </tr>
              <tr>
                <th class="table-secondary identification-th">
                  {{ "NUMBER_ELEMENT" | translate: { element: "Orchestra" } }}</th>
                <td class="identification-td">
                  <input class="form-control" type="text" disabled formControlName="orchestra" name="input-orchestra"
                    placeholder="{{ 'ENTER_MASC' | translate: { element: 'NUMBER' | translate | lowercase } }}">
                </td>
              </tr>
              <tr>
                <th class="table-secondary identification-th" translate>MANAGER</th>
                <td class="identification-td">
                  <tag-input inputClass="tagInputOnePager" formControlName="manager"
                    displayBy="name" identifyBy="key" name="input-manager" [onlyFromAutocomplete]="true" placeholder="+ {{ 'USER' | translate }}"
                    secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: {'object': 'USER' | translate | lowercase} }}" maxItems="1">
                    <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="users" displayBy="name" identifyBy="key" [minimumTextLength]="3"
                      [showDropdownIfEmpty]="false">
                    </tag-input-dropdown>
                </tag-input>
                </td>
              </tr>
              <tr>
                <th class="table-secondary identification-th" translate>EXPERT</th>
                <td class="identification-td">
                  <tag-input inputClass="tagInputOnePager" formControlName="expert" displayBy="name" identifyBy="key"
                    name="expertToAdd" [onlyFromAutocomplete]="true" placeholder="+ {{ 'USER' | translate }}"
                    secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: {'object': 'USER' | translate | lowercase} }}">
                    <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="users" displayBy="name"
                      identifyBy="key" [minimumTextLength]="3" [showDropdownIfEmpty]="false">
                    </tag-input-dropdown>
                  </tag-input>
                </td>
              </tr>
              <tr>
                <th class="table-secondary identification-th" translate>LABORATORY</th>
                <td class="identification-td">
                  <input class="form-control" type="text" formControlName="laboratory" name="input-laboratory"
                    placeholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { object: 'LABORATORY' | translate | lowercase } }}">
                </td>
              </tr>
              <tr>
                <th class="table-secondary identification-th" translate>HUB</th>
                <td class="identification-td">
                  <input class="form-control" type="text" formControlName="hub" name="input-place"
                    placeholder="{{ 'ENTER_MASC' | translate: { element: 'PLACE' | translate | lowercase } }}">
                </td>
              </tr>
              <tr>
                <th class="table-secondary identification-th" translate>FIELDWORK</th>
                <td class="identification-td">
                  <input class="form-control" type="text" formControlName="fieldwork" name="input-place"
                    placeholder="{{ 'ENTER_MASC' | translate: { element: 'FIELDWORK' | translate | lowercase } }}">
                </td>
              </tr>
              <tr>
                <th class="table-secondary identification-th" translate>ONE_PAGER_DATE</th>
                <td class="identification-td">
                  <input class="form-control" type="text" formControlName="studyDate" name="input-date"
                    placeholder="{{ 'ENTER_FEM' | translate: { element: 'date' } }}">
                </td>
              </tr>
              <tr>
                <th class="table-secondary identification-th required" translate>ESTIMATED_CONTRIBUTION</th>
                <td class="identification-td">
                  <input class="form-control" type="number" min="0" step="0.5" formControlName="estimatedContribution" name="estimatedContribution"
                    placeholder="{{ 'ENTER_ESTIMATED_CONTRIBUTION' | translate }}"
                    [ngClass]="{ 'is-invalid': (headerForm.controls.estimatedContribution.errors?.required && (headerForm.controls.estimatedContribution.touched || submitted || !formValidAndPublished)) }" required>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
