export default {
    "type": "scalebar",
    "configs": {
        "compute": {
            "method": "median",
            "key" : "key",
            "greyScale": true,
        },
        "title": {
            "text": ""
        },
        "subtitle": {
            "text": ""
        },
        "plotOptions": {
            "attributes_blocks" : {
                "enabled" : true
            },
            "default_language" : {
                "enabled" : false
            }
        }
    }
}