import { Injectable } from '@angular/core';
import { FilterType } from '../interfaces';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterKeyTranslations } from '../enums';
import * as _ from 'lodash';

@Injectable()
export class FilterService {
  private _filters : any;

  constructor() {
    this._filters = {};
  };

  public get filters() : any { return this._filters; };
  
  public set filters(_filters : any) { this._filters = _filters;};
  
  /**
   * addFilters
   */
  public addFilters = ( filters : any ) : any => {
    if ( !filters || filters === undefined) return this.filters;
    for (let filterKey of Object.keys(filters)) {
      this.filters[filterKey] = (this.filters[filterKey] || { name : FilterKeyTranslations[filterKey], options: filters[filterKey] });
      for (let filter of filters[filterKey]) {
        const existingFilterIndex = this.filters[filterKey].options.findIndex(( f => f.key === filter.key ));
        if( existingFilterIndex === -1 ) this.filters[filterKey].options.push( filter );
      }
    }
    return this.filters;
  };

  /**
   * toFormGroup
   */
  public static toFormGroup = (filtersObject : any) : FormGroup => {
    const fb = new FormBuilder();
    const form = {};
    Object.keys(filtersObject).forEach( (elementKey : string) => {
      const formPayload = {};
      if(!filtersObject[elementKey].hasOwnProperty('collapsed')) filtersObject[elementKey].collapsed = true;
      let isMulti = !_.has(filtersObject[elementKey], 'options');
      if(isMulti) {
        filtersObject[elementKey].forEach(( filter : FilterType) => {
          formPayload[filter.id] = filter.isApplied;   
        });
      } else {
        filtersObject[elementKey].options.forEach(( filter : FilterType) => {
          formPayload[filter.key] = filter.isApplied;   
        });
      }
      form[elementKey] = fb.group(formPayload);
    });
    return fb.group(form);
  };

  /**
   * formatFromFormGroup
   */
  public static formatToInitial = (form) => {
    const payload = {};
    Object.keys(form)
    .reduce(( reducer : any , key) => {
      reducer[key] = form[key].options;
      return reducer;
    }, payload );
    return payload;
  };

  /**
   * resetFilters
   */
  public resetFilters = () => {
    delete this._filters;
    this.filters = {};
  }

};
