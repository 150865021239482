<script>
  document.getElementById("scrollablediv").scroll(0, 500);
</script>
<dna-layout>
  <div class="dna-container">
    <h1 class="mb-4" id="banner-title">
      <span translate>CAMPAIGN</span>
    </h1>
    <ngx-loading [show]="showSpinner"></ngx-loading>
    <div *ngIf="!showSpinner && !error" class="ibox float-e-margins p-3">

      <nav aria-label="breadcrumb" id="banner-nav">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a routerLink="/dashboard" translate>DASHBOARD</a>
          </li>
          <li class="breadcrumb-item">
            <a routerLink="/campaigns" translate>CAMPAIGNS</a>
          </li>
          <li class="breadcrumb-item" *ngIf="studyTimepoint">
            <a [routerLink]="['/campaigns', idCampaign, 'edit', 'analyse']" translate>{{ studyTimepoint.name }}</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/campaigns', idCampaign, 'edit', 'photos']" translate>Photos</a>
          </li>
          <li class="breadcrumb-item active" translate>VOLUNTEER</li>
        </ol>
      </nav>

      <div class="block">
        <span translate>VOLUNTEERS_LIST</span>
        <select class="selectVolunteers" [(ngModel)]="volunteerSelectedOption" (change)="redirectToSamePage()">
          <option *ngFor="let volunteer of optionsVolunteers" [value]="volunteer">{{ volunteer }}</option>
        </select>
        <section>
          <div class="table-responsive">
            <table class="table table-hover dataTable">
              <thead class="head-table">
                <tr class="case-border ">
                  <th class="case-border">
                    {{'ABOUT_PRODUCT' | translate}}
                  </th>
                  <th class="case-border">
                    {{'APPLICATION_RANDO' | translate}}
                  </th>
                  <th class="case-border">
                    {{'LEGEND_FORMULA_NAME' | translate}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="case-border">Context</td>
                  <td class="case-border" *ngIf="randoVolunteer && randoVolunteer?.formulaLab">
                <tr translate> {{randoVolunteer?.formulaLab.treatment}} : {{randoVolunteer?.formulaLab.side |
                  translate}}</tr>
                <tr translate>{{randoVolunteer.formulaBench.treatment}} : {{randoVolunteer.formulaBench.side |
                  translate}}</tr>
                </td>
                <td class="case-border" *ngIf="randoVolunteer && randoVolunteer?.formulaLab">
                  <tr>{{randoVolunteer.formulaLab.treatment}} : {{randoVolunteer.formulaLab.formulaName}}</tr>
                  <tr>{{randoVolunteer.formulaBench.treatment}} : {{randoVolunteer.formulaBench.formulaName}}</tr>
                </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <section>
          <div class="manage-actions">
            <div class="selected-action">
              <div class="selection" *ngIf="selectedPhotosCount.length">
                <i class="fa fa-times pointer" aria-hidden="true" (click)="resetSelection()"></i>
                <span><strong>{{ selectedPhotosCount.length }} {{'PHOTOS_SELECTED' | translate}}</strong></span>
                <div class="actions">
                  <i class="fa fa-trash pointer" aria-hidden="true" (click)="onRemovePhotos()"
                    title="delete photos"></i>
                  <i class="fa fa-download pointer" aria-hidden="true" (click)="createAndDownloadPhotosZip()"
                    title="download photos"></i>
                  <i class="fa fa-file-text pointer" aria-hidden="true" (click)="sendPhotosToReport()"
                    title="send photos to report"></i>
                </div>
              </div>
            </div>
            <div class="wrap-buttons">
              <div class="text-center">
                <input type="checkbox" [checked]="isSelectedAll" (change)="selectAllPhotos($event)">
                <span>{{'ALL_SELECT' | translate}}</span>
              </div>
              <div>
                <button class="outline-button" type="button" (click)="openModalToAddPhoto()" translate><i
                    class="fa fa-plus" aria-hidden="true"></i> {{'ADD_PHOTO' | translate}}</button>
              </div>

            </div>
          </div>
        </section>

        <ngx-loading [show]="showSpinnerLoadingPhoto"></ngx-loading>
        <div
          *ngIf="timepointPhotoVolunteerList && timepointPhotoVolunteerList.length > 0 && !showSpinnerLoadingPhoto; else noDataToDisplay">
          <div id="scrollablediv">
            <div class="containerImages">
              <div class="timepoint" *ngFor="let timepoint of timepointPhotoVolunteerList">
                <div class="title-timepoint">
                  <strong>
                    <p>{{ timepoint.timepointName.visitName}}</p>
                    <div *ngIf="displayEnglishLanguage(timepoint.timepointName.blockName); else displayMultiTranslateBlockName">
                      <div>{{timepoint.timepointName.blockName[Languages.English]}}</div>
                    </div>
                    <ng-template #displayMultiTranslateBlockName>
                      <div
                        [innerHTML]="timepoint.timepointName.blockName | multiTranslations : [ timepoint.timepointName.blockName, showDefaultLanguage ? Languages.Default : null ] : currentLanguage">
                      </div>
                    </ng-template>

                    <div
                      *ngIf="displayEnglishLanguage(timepoint.timepointName.componentName); else displayMultiTranslateComponentName">
                      <div>{{timepoint.timepointName.componentName[Languages.English]}}</div>
                    </div>
                    <ng-template #displayMultiTranslateComponentName>
                      <div
                        [innerHTML]="timepoint.timepointName.componentName  | multiTranslations : [ timepoint.timepointName.componentName, showDefaultLanguage ? Languages.Default : null ] : currentLanguage">
                      </div>
                    </ng-template>

                  </strong>
                </div>
                <div class="count-photos">{{ timepoint.referencesPhoto.length }} photos</div>
                <div class="content-item" *ngFor="let photo of timepoint.referencesPhoto; let idxPhoto = index"
                  [attr.data-index]="idxPhoto">
                  <div class="card"
                    [class.selected]="isPhotoSelected(photo.photoId)">
                    <div class="card-photo">
                      <i *ngIf="photo.isInOnePager" class="fa fa-file-text pointer left" aria-hidden="true"></i>
                      <i class="fa fa-external-link pointer right" aria-hidden="true"
                        (click)="openModalManagePhoto(photo, timepoint.timepointId)"></i>
                      <img [src]="(getImageUrl(photo.photoId) ? getImageUrl(photo.photoId) : this.fallback) | safe"
                        (click)="addToSelection(photo, timepoint.timepointId)" alt="image photo volunteer"
                        loading="lazy" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="loadImages()">
            </div>
          </div>
        </div>
        <ng-template #noDataToDisplay>
          <div class="text-center">there is not photos yet, you could start to adding one</div>
        </ng-template>

      </div>
      <dna-menu [ngClass]="{
            'dna-menu-container--collapsed' : this.menuCollapsed,
            'dna-menu-container--expanded'  : !this.menuCollapsed
        }" (onCollapse)="this._onCollapseMenu($event)"></dna-menu>
    </div>
  </div>
</dna-layout>
