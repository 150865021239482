  <ngx-loading [show]="showSpinner">
</ngx-loading>
<div class="modal-header">
  <button class="btn-close float-end" type="button" (click)="cancel()">
    <span class="sr-only">Close</span>
  </button>
  <i class="fa fa fa-calendar modal-icon"></i>
  <h4 class="modal-title">
    <span translate>CREATE </span>
    <span translate>CAMPAIGN</span>
  </h4>
  <small>{{ "CREATION_ITEM" | translate: { type: "CAMPAIGNS" | translate | lowercase } }}</small>
</div>
<div class="modal-body">
  <form role="form" #campaignForm="ngForm">
    <div class="ibox">
      <div class="ibox-title">
        <h5 translate>GENERAL</h5>
      </div>
      <div class="ibox-content">
        <div class="form-group">
          <label>{{ 'NAME' | translate }}
            <dna-mandatory [ngClass]="{'has-error': (submitted && name.invalid) || (name.dirty && name.invalid)}">
            </dna-mandatory>
          </label>
          <input class="form-control" #name="ngModel" [(ngModel)]="campaignName" type="text" name="name" required>
          <div *ngIf="(submitted && name.invalid) || (name.dirty && name.invalid)" class="alert alert-danger" translate>REQUIRED</div>
        </div>
        <dna-checkbox *ngIf="hasSynergy" [isChecked]="keepSynergy" text="KEEP_SYNERGY" (onBooleanChanged)="keepSynergy = !keepSynergy">
        </dna-checkbox>
        <dna-checkbox *ngIf="hasArcs" [isChecked]="keepArcs" text="KEEP_ARCS" (onBooleanChanged)="keepArcs = !keepArcs">
        </dna-checkbox>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="save(campaignForm)" translate>VALIDATE</button>
  <button class="btn btn-default" type="button" (click)="cancel()" translate>CANCEL</button>
</div>
