
import {tap, map, takeUntil, shareReplay} from 'rxjs/operators';
import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  ViewChild, AfterViewInit
} from '@angular/core';

import * as _ from 'lodash';
import {
  NgbActiveModal, NgbTimeStruct
} from '@ng-bootstrap/ng-bootstrap';
import {
  Observable,
  Subject
} from 'rxjs';

import {
  CampaignFormulas,
  Formula,
  KeyValue,
  Metiers,
  Order,
  Routine2,
  RoutineApplication,
  RoutineStep,
  TypeApplicationMode,
  TypeDryingType,
  TypeMetier,
  Visit
} from '@app/types';
import {
  CampaignService
} from '../../../../campaigns.service';
import {
  ErrorManagerService
} from '@app/shared/services/errorManager.service';
import {
  ReferenceTypeService
} from '@app/shared/services/reference-type.service';
import {
  UtilService
} from '@app/shared/services/util.service';
import { DNATranslateService } from '@app/shared/services/translate.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ApplicationInsightsService } from '@app/shared/services/applicationInsights.service';
import { ActivatedRoute } from '@angular/router';

export type AllVisits = {
  idVisit: string;
  visitName: string;
  visitIndex: number;
}

@Component({
  selector: 'dna-routines',
  templateUrl: './routines.component.html',
  styleUrls: ['./routines.component.less']
})
export class RoutinesComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() campaign: CampaignFormulas;
  @Input() visitsList: AllVisits[];
  @ViewChild('routineForm') routineForm: FormArray;

  lang: string;
  categoriesSorted: KeyValue[];
  formulas: Formula[] = [];
  metierName: TypeMetier;
  metiers: typeof Metiers = Metiers;
  routineApplication: RoutineApplication[];
  routines: Routine2[] = [];
  routinesOriginal: Routine2[] = [];
  visitsSelected: AllVisits[] = [];
  //const pour enrgistrer les erreurs denas les routines
  routinesErrors: {[routineError: string]: boolean} = {};
  VISIT = 'V';
  ORDER = 'O';
  STEP = 'S';
  isRoutineValidated = false;
  initTime = performance.now();

  applicationModes$: Observable<TypeApplicationMode[]>;
  dryingTypes$: Observable<TypeDryingType[]>;
  onChangeForm$;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private activeModal: NgbActiveModal,
    private campaignService: CampaignService,
    private errorManagerService: ErrorManagerService,
    private referenceTypeService: ReferenceTypeService,
    private utilService: UtilService,
    private aiService: ApplicationInsightsService,
    private route: ActivatedRoute,
    private DNATranslate: DNATranslateService,
  ) { }

  ngOnInit() {
    this.lang = this.DNATranslate.getLanguage();
    this.formulas = _.cloneDeep(this.campaign.formula.formulas);
    this.formulas.unshift({ name: 'NO_FORMULA' });
    this.metierName = _.get(this.campaign, 'metier.name', undefined);
    this.routineApplication = this.referenceTypeService.getRoutineApplications();
    this.routines = _.cloneDeep(this.campaign.formula.routines);

    this.routinesOriginal = _.cloneDeep(this.campaign.formula.routines);
    this.setVisitsSelected();
    this.getCategories(this.metierName).pipe(
      takeUntil(this.destroy$))
      .subscribe();

    this.routineForm = this.initForm(this.routines);
    this.applicationModes$ = this.referenceTypeService.getApplicationModes(this.metierName).pipe(shareReplay(1));
    this.dryingTypes$ = this.referenceTypeService.getDryingTypes().pipe(shareReplay(1));
    this.onChangeForm$ = this.routineForm.valueChanges.subscribe(() => {
      if (this.isRoutineValidated) {
        this.isValid(this.routines);
      }
    });
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Formulas Routines', '', performance.now() - this.initTime, templateUrl);
  }

  ngOnDestroy() {
    this.onChangeForm$.unsubscribe();
    this.destroy$.next(true);
    this.activeModal.dismiss();
    this.destroy$.unsubscribe();
  }

  /**
   *
   * @returns a list of visits already present in routines
   */
  setVisitsSelected() {
    const tab: AllVisits[] = [];
      _.get(this.routines, [0, 'visits'], []).map(visite => {
          const v = this.visitsList.find(v => v.idVisit === visite.id);
          if(v) tab.push(v);
      });
    this.visitsSelected = tab;
  }

  /**
   *
   * @returns a list of visits available (not in routines)
   */
  getAvailableVisits(visit) {
    const availableVisits = this.visitsList.filter(v => _.isUndefined(this.visitsSelected.find(vs => vs.idVisit === v.idVisit)) || (!_.isUndefined(this.visitsSelected.find(vs => vs.idVisit === v.idVisit)) && v.idVisit === visit.id));
    return availableVisits;
  }

  initForm(routines) {
    return new FormArray(routines?.map((routine) =>
        new FormGroup({
          visits: routines?.visits ? new FormArray(routine?.visits?.map(visit =>
            new FormGroup({
              orders: new FormArray(visit?.orders?.map(order =>
                new FormGroup({
                  shade: new FormControl(order.shade),
                  steps: new FormArray(order.steps?.map(step =>
                    new FormGroup({
                      formula: new FormGroup({
                        lot: new FormControl(step.formula.lot),
                      })
                    })
                  ))
                }),
              )),
              unit: new FormGroup({
                name: new FormControl(visit.unit?.name)
              })
            })
          )) : new FormArray([])
        })
    ));
  }

  addObjectInEmptySelect(routines: Routine2[], indexVisit: number, indexOrder: number, indexStep: number, key: string, data: any): Routine2[] {
    return routines.map(
      (routine: Routine2) => {
        if (routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula[key] === undefined) {
          routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula[key] = data;
        }

        return routine;
      }
    );
  }

  onChangeTime(value: NgbTimeStruct, indexRoutine: number, indexVisit: number, indexOrder: number, indexStep: number) {
    if(value) {
      this.routines[indexRoutine].visits[indexVisit].orders[indexOrder].steps[indexStep].formula.pauseTime = value;
    }
  }

  addTagInEmptyInputTag(routines: Routine2[], indexVisit: number, indexOrder: number, indexStep: number, key: string, data: any): Routine2[] {
    return routines.map(
      (routine: Routine2) => {
        if (_.get(routine, `visits[${indexVisit}].orders[${indexOrder}].steps[${indexStep}].formula[${key}]`, []).length === 0) {
          routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula[key] = [data];
        }

        return routine;
      }
    );
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.utilService.canDeactivate(this.routines, this.routinesOriginal);
  }

  compareKeyValue(c1: KeyValue, c2: KeyValue): boolean {
    return (c1 && c2) ? c1.key === c2.key : false;
  }

  getCategories(metierName: TypeMetier): Observable<KeyValue[]> {
    let categories;
    switch (metierName) {
      case Metiers.Hair:
        categories = this.campaignService.getCategoriesHair();
        break;
      case Metiers.Skin:
        categories = this.campaignService.getCategoriesSkin();
        break;
      default:
        categories = this.campaignService.getCategoriesHair();
    }

    return this.referenceTypeService.stringToKeyValueTranslations(categories).pipe(
      map((keyvalue: KeyValue[]) => this.utilService.sortArrayOfKeyValueObjects(keyvalue)),
      tap((keyvalue: KeyValue[]) => this.categoriesSorted = keyvalue),);
  }

  isValid(routines: Routine2[]) {
    this.routinesErrors = {};
    let hasErrors = false;
    for (let routine of routines) {
      if (!routine.name) hasErrors = true;
      routine.visits.forEach((visit, indexVisit) =>  {
        if (_.isNil(visit.id) || _.isEmpty(visit.id)) {
          this.routinesErrors[this.VISIT + indexVisit] = true;
          hasErrors = true;
        }
        visit.orders.forEach((order, indexOrder) => {
          if (!order.name) {
            this.routinesErrors[this.VISIT + indexVisit] = true;
            this.routinesErrors[this.ORDER + indexOrder] = true;
            hasErrors = true;
          }
          order.steps.forEach((step, indexStep) => {
            if (!step.formula || !step.name || !_.isNumber(step.formula.quantity) || step.formula.formulaName.length === 0 || !step.formula.productName
                  || (this.metierName === this.metiers.Skin && !_.isNumber(step.formula.timeDrying)) || (this.metierName !== this.metiers.Skin && !step.formula.lot)) {
              this.routinesErrors[this.VISIT + indexVisit] = true;
              this.routinesErrors[this.ORDER + indexOrder] = true;
              this.routinesErrors[this.STEP + indexStep] = true;
              hasErrors = true;
            }
            if(!_.has(step, 'formula.pauseTime')) {
              step.formula.pauseTime = {
                hour: 0,
                minute: 0,
                second: 0
              };
            }
          }); //end forEach steps
        }); //end forEach orders
      }); //end forEach visits
    }
    this.isRoutineValidated = hasErrors;
    return !hasErrors;
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  onOrderUpdate(routines: Routine2[], indexVisit: number, indexOrder: number, result: [any, string]): Routine2[] {
    const data = result[0];
    const key = result[1];

    return routines.map(
      (routine: Routine2) => {
        routine.visits[indexVisit].orders[indexOrder][key] = data;
        return routine;
      }
    );
  }

  onValidate(campaign: CampaignFormulas, routines: Routine2[]) {
    this.isRoutineValidated = true;
    if (this.isValid(routines)) {
      campaign.formula.routines = _.cloneDeep(routines);
      this.activeModal.close(campaign);
    } else {
      this.errorManagerService.displayMessage("ON_ERROR_FORM", "danger", {}, true);
    }
  }

  orderCreate(routines: Routine2[], indexVisit: number) {
    this.routines = routines.map((routine: Routine2) => {
      if (routine.visits && routine.visits[indexVisit]) {
        if (!routine.visits[indexVisit].orders) {
          routine.visits[indexVisit].orders = [];  // Initialisation du tableau si nécessaire, si routine.visits[indexVisit].orders est undefined
        }
        routine.visits[indexVisit].orders.push(new Order(routine.visits[indexVisit].orders.length.toString()));
      } else {
        console.error('Invalid data:', routine.visits);
      }
      return routine;
    });
  }


  orderDelete(routines: Routine2[], indexVisit: number, indexOrder: number): boolean {
    this.routines = routines.map(
      (routine: Routine2) => {
        routine.visits[indexVisit].orders.splice(indexOrder, 1);
        return routine;
      }
    );

    /**
     * Return false to stop event spreading (accordion)
     */
    return false;
  }

  stepCreate(routines: Routine2[], indexVisit: number, indexOrder: number) {
    this.routines = routines.map(
      (routine: Routine2) => {
        routine.visits[indexVisit].orders[indexOrder].steps.push(new RoutineStep(`${routine.visits[indexVisit].orders[indexOrder].steps.length + 1}`));
        return routine;
      }
    );
  }

  stepDelete(routines: Routine2[], indexVisit: number, indexOrder: number, indexStep: number): boolean {
    this.routines = routines.map(
      (routine: Routine2) => {
        routine.visits[indexVisit].orders[indexOrder].steps.splice(indexStep, 1);
        return routine;
      }
    );

    /**
     * Return false to stop event spreading (accordion)
     */
    return false;
  }

  stepUpdateName(routines: Routine2[], indexVisit: number, indexOrder: number, indexStep: number, stepName: string): Routine2[] {
    return routines.map(
      (routine: Routine2) => {
        routine.visits[indexVisit].orders[indexOrder].steps[indexStep].name = stepName;
        return routine;
      }
    );
  }

  visitCreate(routines: Routine2[]): Routine2[] {
    let nbVisits = _.get(routines, [0 , 'visits', 'length'], 0);
    if(this.visitsList.length <= nbVisits) {
      this.errorManagerService.displayMessage("ERROR_ON_ADD_VISIT", "danger", {}, true);
      return routines;
    } else {
      routines = routines.map(
        (routine: Routine2) => {
          const newVisit = new Visit(nbVisits);
          const previousVisit = this.visitsList.find(v => v.idVisit === routine.visits[nbVisits-1].id);
          const availableVisits = _.xor(this.visitsSelected, this.visitsList);
          const nextVisit = previousVisit ? availableVisits.find(visit => visit.visitIndex === previousVisit.visitIndex+1) : {idVisit: null, visitName:'', visitIndex: _.get(routines, [0, 'visits', 'length'], '')};
          _.set(newVisit, 'id', !_.isUndefined(nextVisit) ? nextVisit.idVisit : availableVisits[0].idVisit);
          _.set(newVisit, 'name', !_.isUndefined(nextVisit) ? nextVisit.visitName : availableVisits[0].visitName);
          _.set(newVisit, 'index', !_.isUndefined(nextVisit) ? nextVisit.visitIndex : availableVisits[0].visitIndex);
          routine.visits.push(newVisit);
          return routine;
        });
        this.setVisitsSelected();
        return routines;
    }
  }

  visitDelete(routines: Routine2[], indexVisit: number): boolean {
    this.routines = routines.map(
      (routine: Routine2) => {
        routine.visits.splice(indexVisit, 1);
        return routine;
      }
    );
    this.setVisitsSelected();
    /**
     * Return false to stop event spreading (accordion)
     */
    return false;
  }

  visitUpdate(routines: Routine2[], indexVisit: number, visitId: string): Routine2[] {
    let visitInList = this.visitsList.find(vil => vil.idVisit === visitId);
    if(visitInList) {
      routines = routines.map(
        (routine: Routine2) => {
          routine.visits[indexVisit].name = visitInList.visitName;
          routine.visits[indexVisit].index = visitInList.visitIndex;
          routine.visits[indexVisit].id = visitId;
          return routine;
        }
      );
    }
    this.setVisitsSelected();
    return routines;
  }

}
