import { ColumnChartOptionsType, ChartOption } from '../../interfaces';
import { Chart } from '../chart.model';
import { OptionType } from '../../enums';

export class ColumnChartOptions {

    public options : ColumnChartOptionsType = {
        general : {label : null,options : [] }, 
        xAxis : {label : null,options : []},
        yAxis : {label : null,options : []},
        series : {label : null,options : []},
        plotOptions : {label : null, options : []},
    };

    public callback : Function;

    constructor(
    {
        general,
        xAxis,
        yAxis,
        series,
        plotOptions,
    }, callback : Function) {
        this.callback = callback;
        // GENERAL PART
        this.options[ 'general' ].label = ( general.label || 'VIZUALIZATIONS.GENERAL' );
        try { this.options['general'].options.push({ label : 'VIZUALIZATIONS.CHART_TITLE', key: "chart_title", value : (general.title.text || null), type : OptionType.Text,  originalObject : general.title });} 
        catch(e) { this.options['general'].options.push({ label : 'VIZUALIZATIONS.CHART_TITLE', key: 'chart_title', value : null, type : OptionType.Text,  originalObject : general.title });}
        try { this.options['general'].options.push({ label : 'VIZUALIZATIONS.CHART_SUBTITLE',  key: 'chart_subtitle', value : (general.subtitle.text || null), type : OptionType.Text,  originalObject : general.subtitle });}
        catch(e) { this.options['general'].options.push({ label : 'VIZUALIZATIONS.CHART_SUBTITLE', key: 'chart_subtitle', value : null, type : OptionType.Text,  originalObject : general.subtitle });}
        try {
            this.options[ 'general' ].options.push( { 
                label: 'Chart text size',
                key: 'chart_font_size',
                value: 11,
                type: OptionType.Number,
            })
        } catch( e ) {
            console.error( 'error when creating font size option' );
        }
        try {
            this.options[ 'general' ].options.push( {
                label: 'Chart text bold',
                key: 'chart_font_bold',
                value: false,
                type: OptionType.Checkbox,
            })
        } catch( e ) {
            console.error( 'error when creating font bold option' );
        }
        // xAXIS PART
        this.options.xAxis.label = ((xAxis && xAxis.label) || 'VIZUALIZATIONS.X_AXIS');
        try { this.options.xAxis.options.push({ label : ((xAxis && xAxis.title && xAxis.title.label) || 'VIZUALIZATIONS.X_AXIS_LABEL'), key: 'x_axis_label', value : ((xAxis && xAxis.title && xAxis.title.text )|| null), type : OptionType.Text }); }
        catch(e) { this.options.xAxis.options.push({ label : 'VIZUALIZATIONS.X_AXIS_LABEL', key: 'x_axis_label', value : null, type : OptionType.Text }); }
        // yAXIS PART
        this.options.yAxis.label = ((xAxis && xAxis.label) || 'VIZUALIZATIONS.Y_AXIS');
        try { this.options.yAxis.options.push(
            { 
                label : ((yAxis && yAxis.title && yAxis.title.label )|| 'VIZUALIZATIONS.Y_AXIS_LABEL'), 
                key: 'y_axis_label', 
                value : ((yAxis && yAxis.title && yAxis.title.text) || null), 
                type : OptionType.Text 
            },{
                label : 'CHARTS.MIN_SCALE_LABEL', 
                key: 'y_scale_min', 
                value : ((yAxis && yAxis.min) || 0), 
                type : OptionType.Number 
            },{
                label : 'CHARTS.MAX_SCALE_LABEL', 
                key: 'y_scale_max', 
                value : ((yAxis && yAxis.max) || 0), 
                type : OptionType.Number 
            }
            
            );}
        catch(e) { this.options.yAxis.options.push(
            { 
                label : 'VIZUALIZATIONS.Y_AXIS_LABEL', 
                key: 'y_axis_label', 
                value : null, 
                type : OptionType.Text 
            }
            ,{
                label : 'CHARTS.MIN_SCALE_LABEL', 
                key: 'y_scale_min', 
                value : null, 
                type : OptionType.Number 
            },{
                label : 'CHARTS.MAX_SCALE_LABEL', 
                key: 'y_scale_max', 
                value : null, 
                type : OptionType.Number 
            }); }
        // SERIES PART
        this.options.series.label = (series && series.label || 'VIZUALIZATIONS.SERIES');
        try {
            this.options.series.options = series.reduce(( accumulator : Array<ChartOption>, serie : any, index : number) => {
                accumulator.push({ label : (serie.name || index.toString()), value : (serie.color || Chart.colorScale[index % Chart.colorScale.length]), type : OptionType.Color, key: `color_${ index.toString() }`});
                return accumulator;
            }, []);
        } catch(e) {}

        // PLOT OPTIONS
        this.options.plotOptions.label = (plotOptions && plotOptions.label || 'VIZUALIZATIONS.PLOT_OPTIONS');
        // Show/Hide labels
        try { this.options.plotOptions.options.push({ label: (plotOptions.series.dataLabels.label || 'VIZUALIZATIONS.SHOW_DATA_LABELS'), key: 'show_data_label', value: (plotOptions.series.dataLabels.enabled || false), type: OptionType.Checkbox }); }
        catch (e) { this.options.plotOptions.options.push({ label: 'VIZUALIZATIONS.SHOW_DATA_LABELS', value: false, key: 'show_data_label', type: OptionType.Checkbox }); }
        
        // Show/Hide attributes' blocks
        if( plotOptions && plotOptions.hasOwnProperty('attributes_blocks') ) {
            try {
                this.options.plotOptions.options.push({ label : 'VIZUALIZATIONS.SHOW_ATTRIBUTES_BLOCKS', key: 'show_attributes_blocks', value: (plotOptions.attributes_blocks.enabled || false), type: OptionType.Checkbox});
            }
            catch (e) { 
                this.options.plotOptions.options.push({ label: 'VIZUALIZATIONS.SHOW_ATTRIBUTES_BLOCKS', key: 'show_attributes_blocks', value: false, type: OptionType.Checkbox }); 
            }
        }

        if (plotOptions && plotOptions.hasOwnProperty('default_language')) {
            this.options.plotOptions.options.push(
                {
                    label: 'VIZUALIZATIONS.SHOW_DEFAULT_LANGUAGE',
                    key: 'show_default_language',
                    value: (plotOptions.default_language.enabled || false),
                    type: OptionType.Checkbox
                }
            );
        };
        
        // Show/Hide grey zone (if exists)
        if( plotOptions && plotOptions.hasOwnProperty('grey_zone')) {
            try { 
                this.options.plotOptions.options.push({ label : 'VIZUALIZATIONS.SHOW_GREY_ZONE', key: 'show_grey_zone', value: (plotOptions.grey_zone.enabled || false), type: OptionType.Checkbox });
            }
            catch (e) { 
                this.options.plotOptions.options.push({ label: 'VIZUALIZATIONS.SHOW_GREY_ZONE', key: 'show_grey_zone', value: false, type: OptionType.Checkbox }); 
            }
        }

        // Show/Hide label with value 0 (if exists)
        if( plotOptions && plotOptions.hasOwnProperty('show_column_zero')) {
            try { 
                this.options.plotOptions.options.push({ label : 'VIZUALIZATIONS.SHOW_COLUMN_ZERO', key: 'show_column_zero', value: (plotOptions.show_column_zero.enabled || false), type: OptionType.Checkbox });
            }
            catch (e) { 
                this.options.plotOptions.options.push({ label: 'VIZUALIZATIONS.SHOW_COLUMN_ZERO', key: 'show_column_zero', value: true, type: OptionType.Checkbox }); 
            }
        }

    }

    public getParametersFromOptions = ():any => {
        let payload = {
            general : {},
            specifics : {}
        };
        Object.assign(payload.general,      this.getGeneralParameterFromOptions());
        Object.assign(payload.specifics,    this.getAxisParameterFromOptions('xAxis'));
        Object.assign(payload.specifics,    this.getAxisParameterFromOptions('yAxis'));
        Object.assign(payload.specifics,    this.getSeriesParametersFromOptions());
        Object.assign(payload.specifics,    this.getPlotOptionsParametersFromOptions());
        return payload;
    };

    private getGeneralParameterFromOptions = () : any => {
        return {
            title : { 
                translations: {
                    ...this.options.general.options[0].originalObject.translations,
                    user: this.options.general.options[0].value
                }
            },
            subtitle : {
                translations: {
                    ...this.options.general.options[1].originalObject.translations,
                    user: this.options.general.options[1].value
                }
            },
            font: {
                size: this.options.general.options[ 2 ].value,
                bold: this.options.general.options[ 3 ].value,
            }
        };
    };

    private getAxisParameterFromOptions = (axis : string) : any => {
        let payload = {};
        payload[axis] = {title : { text : this.options[axis].options[0].value }};
        if(axis === "yAxis"){
            payload[axis] = {
                min: Number(this.options[axis].options[1].value),
                max: Number(this.options[axis].options[2].value)
            }
        }
        return payload
    };

    private getSeriesParametersFromOptions = () : any => {
        return {
            series : this.options.series.options.map(( serie : any ) => {
                return {
                    name : serie.label,
                    color : serie.value
                };
            })
        }
    };

    private getPlotOptionsParametersFromOptions = () : any => {
        const payload = {
            plotOptions : {
                series : {
                    dataLabels : {
                        enabled : (this.options.plotOptions.options.find(x => x.key === 'show_data_label') ? this.options.plotOptions.options.find(x => x.key === 'show_data_label').value : false)
                    }
                }
            }
        }

        if( this.options.hasOwnProperty('plotOptions') && this.options.plotOptions.options.find(x => x.key === 'show_grey_zone')) {
            payload.plotOptions['grey_zone'] = { enabled : (this.options.plotOptions.options.find(x => x.key === 'show_grey_zone').value || false) };
        }
        if( this.options.hasOwnProperty('plotOptions') && this.options.plotOptions.options.find(x => x.key === 'show_attributes_blocks')) {
            payload.plotOptions['attributes_blocks'] = { enabled : (this.options.plotOptions.options.find(x => x.key === 'show_attributes_blocks').value || false) };
        }
        if (this.options.hasOwnProperty('plotOptions') && this.options.plotOptions.options.find(x => x.key === 'show_default_language')) {
            payload.plotOptions['default_language'] = { enabled: (this.options.plotOptions.options.find(x => x.key === 'show_default_language').value || false) };
        }
        if (this.options.hasOwnProperty('plotOptions') && this.options.plotOptions.options.find(x => x.key === 'show_column_zero')) {
            payload.plotOptions['show_column_zero'] = { enabled: (this.options.plotOptions.options.find(x => x.key === 'show_column_zero').value) };
        }
        return payload;
    };
};