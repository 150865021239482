<div class="container">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <div class="ibox float-e-margins" *ngIf="infoGenOk && !showSpinner">
    <form #generalInfoForm="ngForm" name="campaignForm" novalidate>
      <div class="ibox-title">
        <div class="row">
          <div class="col-12 col-md-6 my-auto">
            <h5 class="text-center">{{ "GENERAL" | translate }}</h5>
            <ng-template #campaignId>
              <h5 class="text-center">{{ "GENERAL" | translate }} - {{ "MODIFICATION" | translate }}</h5>
            </ng-template>
          </div>
          <div class="col-12 col-md-6">
            <div class="float-end d-flex flex-row gap-2">
              <ng-container *ngIf="isChangedCampaign()">
                <button id="save" class="btn btn-primary" type="button" (click)="save(generalInfoForm)" translate>SAVE</button>
                <button id="cancel" class="btn btn-default" type="button" (click)="clickCancel()" translate>CANCEL</button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="ibox-content d-flex flex-column gap-3">
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label required" [ngClass]="{ 'is-invalid': name.invalid && (name.touched || submitted) }">
              {{ "NAME" | translate }}
            </label>
            <div class="col-12 col-md-9">
              <input class="form-control" [ngClass]="{ 'is-invalid': name.invalid && (name.touched || submitted) }"
                #name="ngModel" [(ngModel)]="campaignMulti.name" type="text" name="name" required>
            </div>
            <div *ngIf="name.dirty || name.touched || submitted" class="offset-1 text-center invalid-feedback">
              <p *ngIf="name.errors && name.errors.required" translate>REQUIRED</p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label">{{ "STUDY_DESCRIPTION" | translate }}</label>
            <div class="col-12 col-md-9">
              <textarea class="form-control" [(ngModel)]="campaignMulti.description" style="overflow: hidden" type="text" name="description"></textarea>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label required">{{ "CAMPAIGN_TYPE" | translate }}</label>
            <div class="col-12 col-md-9">
              <select class="form-control" [ngClass]="{ 'is-invalid': (!campaignMulti.studyType || !campaignMulti.studyType.id) && submitted }" required [(ngModel)]="campaignMulti.studyType" [compareWith]="byId"
                name="studyType">
                <option *ngFor="let item of typeCampaigns" [ngValue]="item" translate>{{ item.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label required">{{ 'ANALYSE_TYPE' | translate }}</label>
            <div class="col-12 col-md-9">
              <select class="form-control" [ngClass]="{ 'is-invalid': !campaignMulti.analyseType && submitted }" required [(ngModel)]="campaignMulti.analyseType" name="analyseTypeSelection">
                <option *ngFor="let analyseType of listAnalyseTypes" [ngValue]="analyseType">{{ analyseType | translate }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label">
              {{ "PROJECTS.PROJECT_NUMBER" | translate }}
            </label>
            <div class="col-12 col-md-9">
              <input class="form-control" [(ngModel)]="campaignMulti.actiview" type="text" name="actiview" disabled>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label">
              {{ "PROJECTS.PROJECT_BRIDGE" | translate }}
            </label>
            <div class="col-12 col-md-9">
              <input class="form-control" [(ngModel)]="campaignMulti.synergy" type="text" name="synergy" disabled>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label">
              {{ "PROJECTS.PROJECT_ARCS_STUDY" | translate }}
            </label>
            <div class="col-12 col-md-9">
              <input class="form-control" [(ngModel)]="campaignMulti.arcs" type="text" name="arcsNumbers" disabled>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label" for="hub" translate>HUB</label>
            <div class="col-12 col-md-9">
              <input *ngIf="campaignMulti.hubs.length < 2; else multipleHubs" class="form-control" [(ngModel)]="campaignMulti.hub" type="text" name="hubs" disabled>
              <ng-template #multipleHubs>
                <select class="form-control" [(ngModel)]="campaignMulti.hub" name="hubs">
                  <option *ngFor="let item of campaignMulti.hubs" [ngValue]="item">{{ item | translate }}</option>
                </select>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="!infoGenOk && !showSpinner">
    <dna-error-page (onClickReload)="init()"></dna-error-page>
  </div>
</div>
