<ngx-loading [show]="showSpinner">
</ngx-loading>
<div class="modal-header">
    <h4 class="modal-title" > {{ title | translate}}</h4>
    <button class="btn-close" type="button" aria-label="Close" (click)="cancel()"></button>
  </div>
<div class="modal-body">
    <div class="ibox">
      <div class="ibox-content">
        <select class="selectTimepointsName" [(ngModel)]="timepointSelected" name="name" (change)="asociatePhotoToTimepoint()" [disabled]="disabledSelectTimepoint">
            <option disabled [ngValue]="undefined" translate>SELECT_TIMEPOINT</option>
            <option *ngFor="let timepoint of timepointRefs" [ngValue]="timepoint">{{ timepoint.name }}</option>
        </select>
        <br/>
        <select class="selectTimepointsName" [(ngModel)]="volunteerSelected" (change)="asociatePhotoToVolunteer()" [disabled]="disabledSelectVolunteer">
          <option disabled [ngValue]="undefined" translate>SELECT_VOLUNTEER</option>
          <option *ngFor="let volunteerId of volunteersId" [value]="volunteerId">{{ volunteerId }}</option>
        </select>
      </div>
    </div>
    <div class="ibox" *ngIf="timepointSelected && volunteerSelected">
      <span translate>IMPORT_PHOTO</span>
      <div class="buttonInput">
          <input class="btn btn-primary btn-block" type="file" name="file" accept="image/*" (change)="onPhotoAdd($event)">
      </div>
    </div>
    <div class="ibox">
      <div *ngIf="urlPhotoAdded" class="sectionPhoto">
        <img [src]="urlPhotoAdded | safe" alt="photo uploaded" width="100%">
      </div>
    </div>
</div>

<div class="modal-footer">
  <div class="button-save">
    <div *ngIf="timepointSelected && urlPhotoAdded">
      <button class="modal-button" type="button" (click)="uploadPhoto()" translate>SAVE</button>
    </div>
  </div>
</div>
