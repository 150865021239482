import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: '[dna-routines-container]',
  templateUrl: './routines-container.component.html',
  styleUrls: ['./routines-container.component.scss']
})
export class RoutinesContainerComponent {
  @Input() routine: {
    id: string;
    color: string;
    name: string;
    formula: Array<{
      name: string
    }>;
    onClick?: () => void;
    selected?: boolean;
  };
  constructor() { }
};
