 <ngx-loading [show]="showSpinner"></ngx-loading>
<table class="table table-hover table-borderless-th dataTable">
  <thead>
    <tr>
      <th class="parameter-text" style="background-color: transparent" translate> NAME </th>
      <th class="parameter-text text-end" style="background-color: transparent" translate> ACTIONS </th>
    </tr>
  </thead>
  <tbody *ngFor="let workspace of workspaces; let indexWorkspace = index ">
    <tr>
      <td [ngStyle]='stripRow(indexWorkspace)'>
        <span class="parameter-text" translate>{{workspace.name}}</span>
      </td>
      <td class="cursor-pointer" [ngStyle]='stripRow(indexWorkspace)'>
        <div (click)="$event.stopPropagation()">
          <dna-action-bar class="action d-flex flex-row gap-2 align-items-center justify-content-end" [actionsButtons]="actionsButtons | async" (onClick)='onClickActionButton($event, workspace.id, workspaces)'>
          </dna-action-bar>
        </div>
      </td>
    </tr>
  </tbody>
</table>
