<div class="modal-header">
  <h3 class="modal-title" translate>VISITS</h3>
</div>
<div class="modal-body">
  {{ "ERROR_ON_DELETE_VISIT" | translate }}
  <li *ngFor="let visit of unableDeleteVisitTab; let indexWorkflow = index">
    {{ visit.name }}
  </li>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-dark" type="button" (click)="onClose()" translate>CLOSE</button>
</div>