<div class="modal-header">
  <h4 class="modal-title" translate>FORMULA</h4>
  <button class="btn-close" type="button" aria-label="Close" (click)="cancel()">
  </button>
</div>
<div class="modal-body">
  <label for="insertion">{{ 'IMPORT_FORMULAS' | translate }}:</label>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <textarea id="insertion" [(ngModel)]="formulas"></textarea>
    </div>
  </div>
  <div class="offset-md-5 col-md-2">
    <button class="btn btn-primary" (click)="validate(onInsert(formulas))" translate>INSERT</button>
  </div>
</div>
