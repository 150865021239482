import { AnalyseMultiService } from './analyse-multi.services';
import { CampaignsMulti } from './../../../types';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MultiCampaignsService } from '../../multi-campaigns.service';
import { map, mergeMap, tap, catchError, take, takeUntil } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';

@Component({
  selector: 'dna-analyse-multi-root',
  templateUrl: './analyse-multi-root.component.html',
 // styleUrls: ['./analyse.component.less']
})

export class AnalyseMultiRootComponent implements OnInit, OnDestroy {
    showSpinner: boolean;
    error: boolean;
    multiCampaign: CampaignsMulti;
    destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private multiCampaignsService: MultiCampaignsService,
    private analyseMultiService: AnalyseMultiService
  ) { }

  ngOnInit() {
    this.showSpinner = true;
    this.error = false;
    this.route.parent.paramMap.pipe(
      map(param => param.get('idCampaignMulti')),
      mergeMap(idCampaign => this.multiCampaignsService.getMultiCampaignStudies(idCampaign)),
      tap(multiC => this.multiCampaign = multiC),
      tap(multiC => this.analyseMultiService.saveCampaignMulti(multiC)),
      takeUntil(this.destroy$),
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        return throwError(err);
      }),

      tap(() => this.showSpinner = false),
      take(1)
    ).subscribe();
  }

  ngOnDestroy() {
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
  }

}