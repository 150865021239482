<div class="container">
  <ngx-loading [show]="showSpinner">
  </ngx-loading>
  <div *ngIf="!showSpinner && !error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "WORKFLOWS" | translate }}</h5>
        </div>
        <div *ngIf="campaign.state !== campaignStates.Finished && campaign.state !== campaignStates.Abandoned && isChangedCampaign()" class="col-12 col-md-8 my-auto">
          <div class="float-end d-flex flex-row gap-1">
            <button id="save" class="btn btn-primary mr-2" type="button" (click)="save(campaign)" translate>SAVE</button>
            <button id="cancel" class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div class="mb-4 row">
        <div class="col-md-5 my-auto">
          <dna-search-text [data]="searchText" (onSearch)="updateList($event, displayFavoriteOnly)"></dna-search-text>
        </div>
        <div class="offset-md-1 col-md-1 my-auto">
          <dna-button-display-only logo="fa fa-star" [value]="displayFavoriteOnly" (onClick)="updateList(searchText, !displayFavoriteOnly)"></dna-button-display-only>
        </div>
      </div>
      <div *ngIf="campaign && workflowsToAdd" class="row">
        <div class="col-12 col-md-7 separator-right workflows-lists scroll-bar">
          <h4 class="text-center" translate>CAMPAIGN_WORKFLOWS_LIST_ADD</h4>
          <ul>
            <ng-container *ngFor="let hub of hubs; let indexHub = index">
              <div class="row space-between">
                <div class="col-md-4 my-auto">
                  <h4>{{ hub | translate }}</h4>
                </div>
                <div class="col-md-4 my-auto">
                  <div class="float-end d-flex flex-row gap-1 collapse-expand-right">
                    <button class="btn btn-light" [ngClass]="collapsedHubTab[indexHub] ? 'open' : 'closed'" attr.aria-controls="{{'collapseHub_' + indexHub}}" (click)="collapsedHubTab[indexHub] = !collapsedHubTab[indexHub]">
                      <i class="fa"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="collapse show" id="{{'collapseHub_' + indexHub}}" [ngbCollapse]="collapsedHubTab[indexHub]">
                <ng-container *ngFor="let workflow of workflowsToAdd; let indexWorkflow = index">
                  <li *ngIf="(workflow.hub && workflow.hub === hub) || (!workflow.hub && hub === 'Others')" class="py-2">
                    <div class="row">
                      <div class="col-md-1 my-auto text-center">
                        <span class="icon">
                          <dna-favorites [id]="workflow.id" [type]="workflowType"></dna-favorites>
                        </span>
                      </div>
                      <div class="col-md-9 my-auto">
                        <div class="name">
                          <dna-translate [fieldToTranslate]="workflow.name"> </dna-translate>
                        </div>
                        <div class="description">
                          <dna-translate [fieldToTranslate]="workflow.description"> </dna-translate>
                        </div>
                      </div>
                      <div *ngIf="campaign.state !== campaignStates.Finished && campaign.state !== campaignStates.Abandoned" class="col-md-2 my-auto text-center">
                        <i class="fa fa-arrow-right" (click)="onAddWorkflow(workflow.id)" aria-hidden="true"></i>
                      </div>
                    </div>
                  </li>
                </ng-container>
              </div>
            </ng-container>
          </ul>
        </div>
        <div class="col-12 col-md-5 scroll-bar workflows-lists">
          <h4 class="text-center" translate>CAMPAIGN_WORKFLOWS_LIST_CHOSEN</h4>
          <ul>
            <li class="workflows-selected py-3" *ngFor="let workflow of campaign.workflows; let index = index">
              <div class="row">
                <div class="col-md-9 my-auto">
                  <div class="name">
                    <dna-translate [fieldToTranslate]="workflow.name"> </dna-translate>
                  </div>
                  <div class="description">
                    <dna-translate [fieldToTranslate]="workflow.description"> </dna-translate>
                  </div>
                  <div *ngIf="workflow.hub" class="description">
                    {{ workflow.hub }}
                  </div>
                </div>
                <div class="col-md-3 my-auto text-center icon-spacing">
                  <span *ngIf="outOfDateWfs.includes(workflow.id) && (campaign.parameters.isTemplate || campaign.state == campaignStates.Draft || campaign.state == campaignStates.Suspended)"
                    class="fa fa-refresh" (click)="updateWorkflow(workflow)"></span>
                  <span class="fa fa-cog" (click)="openModalWorkflow(workflow)"></span>
                  <span class="fa fa-bar-chart" (click)="goToPowerBIDescription(workflow)"></span>
                  <span *ngIf="canIRemoveThisElement(workflow)" class="fa fa-trash" (click)="onRemoveWorkflow(index)"></span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!showSpinner && error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "ERROR" | translate }}</h5>
        </div>
      </div>
    </div>
    <dna-error-page (onClickReload)="init()"></dna-error-page>
  </div>
</div>
