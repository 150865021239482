import { DNAComponent } from '../../../types';
import { UtilService } from '../../../shared/services/util.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'dna-rich-text-editor-config',
  templateUrl: './richTextEditorConfiguration.component.html',
  styleUrls: ['./richTextEditor.component.css']
})

export class RichTextEditorConfigurationComponent implements OnInit {
  @Input() inactive: boolean;
  @Input() component: DNAComponent;

  rtModules: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'],
      ['link', 'image', 'video']
    ]
  };

  constructor(private utilService: UtilService) { }

  ngOnInit() {
  }

}
