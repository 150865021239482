<ng-container *ngIf="!horizontalAlignment">
  <div *ngFor="let data of array" class="i-checks" (click)="pushData(data); clickFilter()">
    <dna-checkbox [isChecked]="isInFilter(data)" sideLabel="right" [text]="fieldToDisplay ? data[fieldToDisplay] : data"></dna-checkbox>
  </div>
</ng-container>
<div *ngIf="horizontalAlignment" class="col-12 d-flex flex-row gap-3">
  <label *ngIf="title" class="pr-2">{{ title | translate }}: </label>
  <div *ngFor="let data of array" class="i-checks pr-3" (click)="pushData(data); clickFilter()">
    <dna-checkbox [isChecked]="isInFilter(data)" sideLabel="right" [text]="fieldToDisplay ? data[fieldToDisplay] : data"></dna-checkbox>
  </div>
</div>
