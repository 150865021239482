import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { CampaignService } from '../../campaigns/campaigns.service';
import { UtilService } from './util.service';
import { ErrorManagerService } from './errorManager.service';
import * as _ from 'lodash';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class ActivateGuardService implements CanActivate {

    constructor(
        private campaignService: CampaignService,
        private utilService: UtilService,
        private errorManager: ErrorManagerService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const campaign = this.campaignService.getCampaignFromLocalStorage(route.parent.paramMap.get('idCampaign'));
        const hasFormulasAndUsers = this.utilService.hasFormulasAndUsers(campaign);
        if(!hasFormulasAndUsers) {
            this.errorManager.displayMessage('ERROR_INCOMPLETE_CAMPAIGN', "danger");
            this.router.navigate(_.isEmpty(_.get(campaign, 'users.accountables'))
                ? ['campaigns', campaign.id, 'edit', 'users'] 
                : ['campaigns', campaign.id, 'edit', 'formulas']);
        }
        return hasFormulasAndUsers;
    }
}