import {forkJoin as observableForkJoin, Observable} from 'rxjs';

import {map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import * as _ from 'lodash';

import {
  AttributeData,
  AttributeDetails,
  FicheCaracterisation,
  Metiers,
  OnePager,
  Panelist,
  PDFTranslation,
  SextiFormula,
  TypeApplicationMode,
  TypeDryingType,
  UserInCampaign,
  RoutineTabForReport,
  CampaignsMulti,
  Campaign,
  CampaignMultiReferenceTested,
  AnalyseTypes,
  OnePagerHeaderInfo,
  Resource
} from '../../types';

import {DNATranslateService} from './translate.service';

import * as htmlToPdfmake from 'html-to-pdfmake';
import {OnePagerService} from './one-pager.service';
import {CommonUtilsPDF} from '../commonUtilsPDF/commonUtilsPDF';

@Injectable()
export class GeneratePDFMultiService {

  formsCaracterisation: FicheCaracterisation[] = [];
  formsCaracterisationHaveBeenSet = false;
  metiers: typeof Metiers = Metiers;
  panelists: Panelist[] = [];
  valuesFromAttributeDetails: string[][];
  currentLanguage: string;
  pdfReportUserByCol: boolean;
  private _commonUtilsPDF: CommonUtilsPDF;

  constructor(
    private dnaTranslateService: DNATranslateService,
    private onePagerService: OnePagerService
  ) {
    this._commonUtilsPDF = new CommonUtilsPDF();
  }

  convertToString(array): string {
    return array.map(data => data.name).join(', ');
  }

  getContent(
    pdfReportUserByCol: boolean,
    onePager: OnePager,
    routinesTabByCampaign: any,
    valuesFromRoutinesTranslations: object[][],
    translations: PDFTranslation,
    imagesCapturesTab,
    formsCaracterisation,
    campaignsMulti: CampaignsMulti,
    campaignsOnePagers: Resource[],
    campaigns: Campaign[],
    tabReferenceTested: CampaignMultiReferenceTested,
    routines: SextiFormula[],
    visitsByVolunteerTab: any[],
    volunteersStarted: { [idCampaign: string]: string },
    headInfo: OnePagerHeaderInfo
  ): any[] {
    this.pdfReportUserByCol = pdfReportUserByCol;
    const manager: string[] = onePager.identification.manager.map((ex: UserInCampaign, index) => (index > 0 ? ', ' : '').concat(ex.name ? `${ex.name}` : `${ex}`));
    valuesFromRoutinesTranslations = _.flatten(valuesFromRoutinesTranslations);
    const content = [];
    const isRoutines = !_.isEmpty(_.get(tabReferenceTested, 'referenceTab.[0]', []));
    this.currentLanguage = this.dnaTranslateService.getLanguage();

    content.push(
      {
        margin: [0, 0, 0, 20],
        table: {
          widths: [90, '*', 90, '*'],
          heights: 10,
          body: [
            [
              {
                text: translations.projectName,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: campaignsMulti.name,
                style: ['response', 'textColor']
              },
              {
                text: translations.orchestra,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: campaignsMulti.orchestra.map(el => el.orchestraNumber).join(", "),
                style: ['response', 'textColor']
              }
            ],
            [
              {
                text: translations.bridge,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: this.formatActSynOrchValues(campaignsMulti.synergy),
                style: ['response', 'textColor']
              },
              {
                text: translations.manager,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: manager.length > 0 ? manager[0] : '',
                style: ['response', 'textColor']
              }
            ],
            [
              {
                text: translations.actiview,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: this.formatActSynOrchValues(campaignsMulti.actiview),
                style: ['response', 'textColor']
              },
              {
                text: translations.expert,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: headInfo ? (headInfo.experts || '') : '',
                style: ['response', 'textColor']
              },
            ],
            [
              {
                text: translations.hub,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: campaignsMulti.hub,
                style: ['response', 'textColor']
              },
              {
                text: translations.laboratory,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: headInfo ? (headInfo.laboratory || '') : '',
                style: ['response', 'textColor']
              }
            ],
            [
              {
                text: translations.fieldwork,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: headInfo ? (headInfo.fieldwork || ''): '',
                style: ['response', 'textColor']
              },
              {
                text: translations.onePagerDate,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: headInfo ? (headInfo.studyDates || '') : '',
                style: ['response', 'textColor']
              }
            ],
            [
              {
                text: translations.onePagerDateModif,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                colSpan: 3,
                text: new Date(_.get(campaignsMulti, 'created_on', '')).toLocaleDateString(),
                style: ['response', 'textColor']
              },
              {},
              {}
            ]
          ]
        },
        layout: {
          hLineWidth: function (i, node) {
            return 1;
          },
          vLineWidth: function (i, node) {
            return 1;
          }
        }
      }
    );

    const targetProtocol = [];
    targetProtocol.push(
      [
        {
          colSpan: 2,
          alignment: 'center',
          text: translations.onePagerVolunteers,
          fillColor: '#d6d8db',
          style: ['tableHeader', 'textColor']
        }, {}
      ]
    );
    campaigns.forEach(c => {
      targetProtocol.push(
        [
          {
            text: c.name,
            style: ['response', 'textColor']
          },
          {
            text: volunteersStarted ? (volunteersStarted[c.id] || '') : '' ,
            style: ['response', 'textColor']
          }
        ]
      );
    });
    targetProtocol.push(
      [
        {
          colSpan: 2,
          alignment: 'center',
          text: translations.targets,
          fillColor: '#d6d8db',
          style: ['tableHeader', 'textColor']
        }, {}
      ]
    );
    campaignsOnePagers.forEach(campaignOnePager => {
      campaignOnePager.resources[0].targetAssignment.forEach(
        item => {
          targetProtocol.push(
            [
              {
                text: campaigns.find(c => c.idOnePager === campaignOnePager.resources[0].id).name,
                style: ['response', 'textColor']
              },
              {
                text: item.target,
                style: ['response', 'textColor']
              }
            ]
          );
        }
      );
    });
    targetProtocol.push(
      [
        {
          colSpan: 2,
          alignment: 'center',
          text: translations.protocol,
          fillColor: '#d6d8db',
          style: ['tableHeader', 'textColor']
        }, {}
      ]
    );
    campaignsOnePagers.forEach(campaignOnePager => {
      targetProtocol.push(
        [
          {
            text: campaigns.find(c => c.idOnePager === campaignOnePager.resources[0].id).name,
            style: ['response', 'textColor']
          },
          this.formatRichText(campaignOnePager.resources[0].protocol)
        ]
      );
    });

    content.push(
      {
        margin: [0, 20, 0, 20],
        table: {
          widths: [90, '*'],
          heights: 10,
          body: [
            [
              {
                text: translations.onePagerContext,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              this.formatRichText(onePager.context)
            ],
            [
              {
                text: translations.objective,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              this.formatRichText(onePager.objectives)
            ],
            [
              {
                text: translations.kpa,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                columns: [
                  {
                    table: {
                      heights: 25,
                      widths: [80, '*'],
                      body: [
                        [
                          {
                            text: 'KPA',
                            fillColor: '#ED75F7',
                            style: ['tableHeader', 'textColor']
                          },
                          {
                            text: onePager.kpa.attributes,
                            style: ['response', 'textColor']
                          }
                        ],
                        [
                          {
                            text: 'Category attributes',
                            fillColor: '#ECE8D4',
                            style: ['tableHeader', 'textColor']
                          },
                          {
                            text: onePager.kpa.categories,
                            style: ['response', 'textColor']
                          }
                        ],
                        [
                          {
                            text: 'Must haves',
                            fillColor: '#C1C0BE',
                            style: ['tableHeader', 'textColor']
                          },
                          {
                            text: onePager.kpa.mustHaves,
                            style: ['response', 'textColor']
                          }
                        ]
                      ]
                    },
                    layout: 'noBorders',
                    margin: [0, 0, 0, 0]
                  }
                ]
              }
            ],
            ...targetProtocol,

          ]
        },
        layout: {
          hLineWidth: function (i, node) {
            return 1;
          },
          vLineWidth: function (i, node) {
            return 1;
          }
        }
      }
    );

    if (isRoutines) {
      if (campaignsMulti.analyseType === AnalyseTypes.comparisonBareSkin) {
        const routinesBareSkin = this.refTestedValues(routines, true, tabReferenceTested);
        const tabRoutines = {
          widths: ['*', 'auto', '*', 'auto', 'auto', 'auto'],
          body: [
            [
              {
                text: translations.routine,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.study,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.formula,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.formula,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.routineBatch,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.socle,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              }
            ],
            ...routinesBareSkin
          ]
        };

        content.push(
          {
            margin: [0, 5, 1, 20],

            table: tabRoutines


          }
        );

      } else {

        const testedProducts = this.refTestedValues(_.get(tabReferenceTested, 'testedTab', []), false, tabReferenceTested);

        const referenceProducts = this.refTestedValues(_.get(tabReferenceTested, 'referenceTab', []), false, tabReferenceTested);

        const tabTested = {
          widths: ['*', 'auto', 'auto', '*', 'auto', 'auto', 'auto'],
          body: [
            [
              {
                colSpan: 7,
                alignment: 'center',
                text: translations.onePagerTested,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              }, {}, {}, {}, {}, {}, {}
            ],
            [
              {
                text: translations.routine,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.study,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.visit,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.formula,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.formula,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.routineBatch,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.socle,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              }
            ],
            ...testedProducts
          ]
        };

        const tabReference = {
          widths: ['*', 'auto', 'auto', '*', 'auto', 'auto', 'auto'],
          body: [
            [
              {
                colSpan: 7,
                alignment: 'center',
                text: translations.onePagerReference,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              }, {}, {}, {}, {}, {}, {}
            ],
            [
              {
                text: translations.routine,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.study,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.visit,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.formula,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.formula,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.routineBatch,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              {
                text: translations.socle,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              }
            ],
            ...referenceProducts
          ]
        };

        const couples = [];
        const tabCouples = _.get(tabReferenceTested, 'couples', []);
        tabCouples.forEach(
          (couple: any, index) => {
            if (index === 0) {
              couples.push(
                [
                  {
                    text: couple.campaignName,
                    style: ['response', 'textColor']
                  },
                  {
                    text: couple.tested,
                    style: ['response', 'textColor']
                  },
                  {
                    text: this.onePagerService.getRoutineMultiName(index),
                    style: ['response', 'textColor']
                  },
                  {
                    text: couple.reference,
                    style: ['response', 'textColor']
                  },
                  {
                    text: this.onePagerService.getRoutinePrime(index),
                    style: ['response', 'textColor']
                  },
                  {
                    rowSpan: tabCouples.length,
                    alignment: 'center',
                    text: 'RM2',
                    style: ['response', 'textColor']
                  }
                ]
              );
            } else {
              couples.push(
                [
                  {
                    text: couple.campaignName,
                    style: ['response', 'textColor']
                  },
                  {
                    text: couple.tested,
                    style: ['response', 'textColor']
                  },
                  {
                    text: this.onePagerService.getRoutineMultiName(index),
                    style: ['response', 'textColor']
                  },
                  {
                    text: couple.reference,
                    style: ['response', 'textColor']
                  },
                  {
                    text: this.onePagerService.getRoutinePrime(index),
                    style: ['response', 'textColor']
                  },
                  {}
                ]
              );
            }
          }
        );


        content.push(
          {
            margin: [0, 0, 0, 20],
            table: {
              widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],
              body: [
                [
                  {
                    rowSpan: 2,
                    text: translations.study,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']
                  },
                  {
                    colSpan: 2,
                    alignment: 'center',
                    text: translations.onePagerTested,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']
                  }, {},
                  {
                    colSpan: 3,
                    alignment: 'center',
                    text: translations.onePagerReference,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']
                  }, {}, {}
                ],
                [
                  {},
                  {
                    text: translations.nameStudy,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']

                  },
                  {
                    text: translations.nameMulti,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']

                  },
                  {
                    text: translations.nameStudy,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']

                  },
                  {
                    text: translations.nameMulti,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']

                  },
                  {
                    text: translations.nameCombined,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']

                  }
                ],
                ...couples
              ]
            }
          }
        );

        content.push(
          {
            margin: [0, 0, 0, 20],
            table: tabTested
          }
        );
        content.push(
          {
            margin: [0, 0, 0, 20],
            table: tabReference
          }
        );
      }
    } else {
      if (campaignsMulti.analyseType !== AnalyseTypes.comparisonBareSkin) {
        const couples = [];
        const tabCouples = _.get(tabReferenceTested, 'couples', []);
        tabCouples.forEach(
          (couple: any, index) => {
            couples.push(
              [
                {
                  text: couple.campaignName,
                  style: ['response', 'textColor']
                },
                {
                  text: couple.tested,
                  style: ['response', 'textColor']
                },
                {
                  text: couple.reference,
                  style: ['response', 'textColor']
                },
              ]
            );
          });

        content.push(
          {
            margin: [0, 5, 1, 20],
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    colSpan: 3,
                    alignment: 'center',
                    text: translations.studiedCouples,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']
                  }, {}, {}
                ],
                [
                  {
                    text: translations.study,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']
                  },
                  {
                    text: translations.onePagerTested,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']
                  },
                  {
                    text: translations.onePagerReference,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']
                  }
                ],
                ...couples
              ]
            }
          }
        );
      } else {
        const formulas = [];
        campaigns.forEach(
          (c: Campaign) => {
            formulas.push(
              [
                {
                  text: c.name,
                  style: ['response', 'textColor']
                },
                {
                  text: this.getFormulaByCampaign(c.id, _.get(c, 'formula.listFormulas', []), campaignsMulti),
                  style: ['response', 'textColor']
                }
              ]
            );
          });

        content.push(
          {
            margin: [0, 5, 1, 20],
            table: {
              widths: ['*', '*'],
              body: [
                [
                  {
                    text: translations.study,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']
                  },
                  {
                    text: translations.formulas,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']
                  }
                ],
                ...formulas
              ]
            }
          }
        );
      }
    }

    content.push(
      {
        table: {
          widths: [90, '*'],
          heights: 10,
          body: [
            [
              {
                text: translations.conclusions,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              this.formatRichText(onePager.conclusion)
            ],
            [
              {
                text: translations.otherStrengths,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              this.formatRichText(onePager.otherStrengths)
            ],
            [
              {
                text: translations.onePagerVigilance,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              this.formatRichText(onePager.vigilance)
            ],
            [
              {
                text: translations.onePagerRecommandation,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
              this.formatRichText(onePager.recommendation)
            ]
          ]
        },
        layout: {
          hLineWidth: function (i, node) {
            return 1;
          },
          vLineWidth: function (i, node) {
            return 1;
          }
        }
      }
    );

    if (!_.isEmpty(routinesTabByCampaign)) {
      routinesTabByCampaign.forEach((routinesByCampaign, indexRoutine) => {
        if (!_.isEmpty(routinesByCampaign)) {
          content.push({
            pageBreak: 'before',
            pageOrientation: 'landscape',
            text: translations.campaign + ' : ' + campaigns[indexRoutine].name
          });
          content.push({
            margin: [0, 10, 0, 0],
            text: translations.routines,
            style: 'header'
          });

          let rows;
          routinesByCampaign.forEach(routine => {
            rows = [];
            const tabRoutines = routine.routineDatas;
            const routinesInactiveColumns = this.getRoutineTableHead(tabRoutines);
            const routineHead = [];
            if (!routinesInactiveColumns.includes('visit')) {
              routineHead.push({
                text: translations.routineVisit,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            if (!routinesInactiveColumns.includes('orderName')) {
              routineHead.push({
                text: translations.order,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            if (!routinesInactiveColumns.includes('shades')) {
              routineHead.push({
                text: translations.routineShades,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            if (!routinesInactiveColumns.includes('evaluation')) {
              routineHead.push({
                text: translations.evaluation,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            if (!routinesInactiveColumns.includes('stepName')) {
              routineHead.push({
                text: translations.steps,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            if (!routinesInactiveColumns.includes('productName')) {
              routineHead.push({
                text: translations.routineProductsNames,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            if (!routinesInactiveColumns.includes('categorie')) {
              routineHead.push({
                text: translations.categories,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            if (!routinesInactiveColumns.includes('formulaName')) {
              routineHead.push({
                text: translations.formula,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            if (!routinesInactiveColumns.includes('lot')) {
              routineHead.push({
                text: translations.routineBatch,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            if (!routinesInactiveColumns.includes('quantity')) {
              routineHead.push({
                text: translations.routineQuantities,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            if (!routinesInactiveColumns.includes('quantitiesByVolunteer')) {
              routineHead.push({
                text: translations.routineQuantitiesByVolunteer,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            if (!routinesInactiveColumns.includes('applicationMode')) {
              routineHead.push({
                text: translations.applicationModes,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            if (!routinesInactiveColumns.includes('pauseTime')) {
              routineHead.push({
                text: translations.routinePauseTime,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              });
            }
            rows.push(routineHead);


            switch (campaigns[indexRoutine].metier.name) {
              case this.metiers.Hair: {
                if (!routinesInactiveColumns.includes('applyTo')) {
                  rows[0].push({
                    text: translations.routineApplyTo,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']
                  });
                }
                if (!routinesInactiveColumns.includes('dryingTypes')) {
                  rows[0].push({
                    text: translations.dryingTypes,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']
                  });
                }
                break;
              }
              case this.metiers.Skin: {
                if (!routinesInactiveColumns.includes('timeDrying')) {
                  rows[0].push({
                    text: translations.timeDrying,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']
                  });
                }
                break;
              }
            }

            tabRoutines.forEach((tr, indexTr) => {
              const nbr = indexTr + 1;
              const routineValues = [];
              if (!routinesInactiveColumns.includes('visit')) {
                routineValues.push({
                  text: tr.visit || '',
                  style: ['response', 'textColor']
                });
              }
              if (!routinesInactiveColumns.includes('orderName')) {
                routineValues.push({
                  text: tr.orderName || '',
                  style: ['response', 'textColor']
                });
              }
              if (!routinesInactiveColumns.includes('shades')) {
                routineValues.push({
                  text: tr.shades || '',
                  style: ['response', 'textColor']
                });
              }
              if (!routinesInactiveColumns.includes('evaluation')) {
                routineValues.push({
                  text: tr.evaluation || '',
                  style: ['response', 'textColor']
                });
              }
              if (!routinesInactiveColumns.includes('stepName')) {
                routineValues.push({
                  text: tr.stepName || '',
                  style: ['response', 'textColor']
                });
              }
              if (!routinesInactiveColumns.includes('productName')) {
                routineValues.push({
                  text: tr.productName || '',
                  style: ['response', 'textColor']
                });
              }
              if (!routinesInactiveColumns.includes('categorie')) {
                routineValues.push({
                  text: function () {
                    if (valuesFromRoutinesTranslations) {
                      const translation = valuesFromRoutinesTranslations.find((v: object) => {
                        return v['key'] === tr.categorie;
                      });
                      if (translation) {
                        return translation['value'];
                      }
                    }
                    return '';
                  }(),
                  style: ['response', 'textColor']
                });
              }
              if (!routinesInactiveColumns.includes('formulaName')) {
                routineValues.push({
                  text: tr.formulaName === 'NO_FORMULA' ? translations.noFormula : tr.formulaName,
                  style: ['response', 'textColor']
                });
              }
              if (!routinesInactiveColumns.includes('lot')) {
                routineValues.push({
                  text: tr.lot || '',
                  style: ['response', 'textColor']
                });
              }
              if (!routinesInactiveColumns.includes('quantity')) {
                routineValues.push({
                  text: tr.quantity || '',
                  style: ['response', 'textColor']
                });
              }
              if (!routinesInactiveColumns.includes('quantitiesByVolunteer')) {
                let text = '';
                tr.quantitiesByVolunteer.forEach((elt, index) => {
                  if (elt.isStarted) {
                    text = index === tr.quantitiesByVolunteer.length - 1
                      ? text = text.concat(elt.volunteerName + ' : ' + elt.quantity)
                      : text.concat(elt.volunteerName + ' : ' + elt.quantity) + '\n';
                  }
                });
                routineValues.push({
                  text: text,
                  style: ['response', 'textColor']
                });
              }
              if (!routinesInactiveColumns.includes('applicationMode')) {
                routineValues.push({
                  text: function () {
                    if (valuesFromRoutinesTranslations) {
                      const translations = _.get(tr, 'applicationMode', []);
                      if (!_.isEmpty(translations)) {
                        translations.map((applicator: TypeApplicationMode) => valuesFromRoutinesTranslations.find((v: object) => v['key'] === applicator.key));
                        return translations.length > 0
                          ? translations.map((translation: object) => translation['value']).join(', ')
                          : '';
                      }
                    }
                    return '';
                  }(),
                  style: ['response', 'textColor']
                });
              }
              if (!routinesInactiveColumns.includes('pauseTime')) {
                let pauseTime = '';
                tr.pauseTime.forEach((elt, index) => {
                  if (!_.isUndefined(elt.pauseTime)) {
                    if (elt.isStarted) {
                      const timeFormat = !_.isUndefined(_.get(elt, 'pauseTime.hour')) ? `${elt.pauseTime.hour} : ${elt.pauseTime.minute} : ${elt.pauseTime.second}` : elt.pauseTime;
                      pauseTime = index === tr.pauseTime.length - 1
                        ? pauseTime = pauseTime.concat(elt.volunteerName + ' : ' + timeFormat)
                        : pauseTime = pauseTime.concat(elt.volunteerName + ' : ' + timeFormat + '\n');
                    }
                  }
                });
                routineValues.push({
                  text: pauseTime,
                  style: ['response', 'textColor']
                });
              }
              rows.push(routineValues);

              switch (campaigns[indexRoutine].metier.name) {
                case this.metiers.Hair: {
                  if (!routinesInactiveColumns.includes('applyTo')) {
                    rows[nbr].push({
                      text: function () {
                        if (valuesFromRoutinesTranslations) {
                          const translationApplyTo = valuesFromRoutinesTranslations.find((v) => v['key'] === tr.applyTo);
                          return translationApplyTo ? translationApplyTo['value'] : '';
                        }
                        return '';
                      }(),
                      style: ['response', 'textColor']
                    });
                  }
                  if (!routinesInactiveColumns.includes('dryingTypes')) {
                    rows[nbr].push({
                      text: function () {
                        if (valuesFromRoutinesTranslations) {
                          const translations = _.get(tr, 'dryingTypes', [])
                            .map((dryingType: TypeDryingType) => valuesFromRoutinesTranslations.find((v: object) => v['key'] === dryingType.key));

                          return translations.length > 0
                            ? translations.map((translation: object) => translation['value']).join(', ')
                            : '';
                        }
                        return '';
                      }(),
                      style: ['response', 'textColor']
                    });
                  }
                  break;
                }
                case this.metiers.Skin: {
                  if (!routinesInactiveColumns.includes('timeDrying')) {
                    rows[nbr].push({
                      text: tr.timeDrying || '',
                      style: ['response', 'textColor']
                    });
                  }
                  break;
                }
              }
            });

            const contentTableRoutineWidth = [];
            const bodyRoutine = [];
            const routinesActiveColumns = _.keys(tabRoutines[0]).filter(key => !routinesInactiveColumns.includes(key));
            routinesActiveColumns.forEach((key, index) => {
              if (key === 'pauseTime') {
                contentTableRoutineWidth.push(105);
              } else {
                contentTableRoutineWidth.push(routinesActiveColumns.length > 12 ? 'auto' : '*');
              }
              if (index === 0) {
                bodyRoutine.push(
                  {
                    colSpan: routinesActiveColumns.length,
                    alignment: 'center',
                    text:
                      _.isEmpty(routine.label)
                        ? `${this.onePagerService.getRoutineName(routine.name, campaigns[indexRoutine].name, tabReferenceTested)}`
                        : `${this.onePagerService.getRoutineName(routine.name, campaigns[indexRoutine].name, tabReferenceTested)} - ${routine.label}`,
                    fillColor: '#d6d8db',
                    style: ['tableHeader', 'textColor']
                  }
                );
              } else {
                bodyRoutine.push({});
              }
            });

            content.push({
              margin: [0, 20, 0, 20],
              table: {
                widths: contentTableRoutineWidth,
                heights: 10,
                body: [
                  bodyRoutine,
                  ...rows
                ]
              }
            });
          });
        }
      });
    }

    if (!_.isEmpty(visitsByVolunteerTab)) {
      visitsByVolunteerTab.forEach(visitsByVolunteer => {
        if (!_.isEmpty(_.get(visitsByVolunteer, 'visitsByVolunteer', []))) {
          content.push({
            pageBreak: 'before',
            pageOrientation: 'landscape',
            text: visitsByVolunteer.campaignName
          });

          const visitsRows = [];
          const contentTableVisitsWidth = !_.has(visitsByVolunteer.visitsByVolunteer[0], 'workflowName') ? ['auto'] : ['auto', 'auto'];
          const visitsHeader = !_.has(visitsByVolunteer.visitsByVolunteer[0], 'workflowName') ? [{
              text: translations.volunteer,
              fillColor: '#d6d8db',
              style: ['tableHeader', 'textColor']
            }]
            : [{
              text: translations.workflow,
              fillColor: '#d6d8db',
              style: ['tableHeader', 'textColor']
            },
              {
                text: translations.volunteer,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
            ];
          visitsByVolunteer.visitsByVolunteer[0].visits.forEach(visit => {
            contentTableVisitsWidth.push('auto');
            visitsHeader.push({
              text: `${translations.visit} : ${visit.name}`,
              fillColor: '#d6d8db',
              style: ['tableHeader', 'textColor']
            });
          });
          visitsRows.push(visitsHeader);
          visitsByVolunteer.visitsByVolunteer.forEach(value => {
            const visitValues = !_.has(value, 'workflowName') ? [{
                text: value.volunteerName,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              }]
              : [{
                text: value.workflowName,
                fillColor: '#d6d8db',
                style: ['tableHeader', 'textColor']
              },
                {
                  text: value.volunteerName,
                  fillColor: '#d6d8db',
                  style: ['tableHeader', 'textColor']
                }];
            value.visits.forEach(visit => {
              visitValues.push({
                text: visit.startDate === 'NOT_STARTED' ? translations.notStarted : visit.startDate,
                fillColor: 'white',
                style: ['response', 'textColor']
              });
            });
            visitsRows.push(visitValues);
          });

          content.push({
            margin: [0, 20, 0, 20],
            table: {
              widths: contentTableVisitsWidth,
              heights: 10,
              body: [
                ...visitsRows
              ]
            }
          });
        }
      });
    }

    imagesCapturesTab.forEach((i) => {
      content.push({
        image: i.data,
        pageBreak: 'before',
        pageOrientation: i.orientation.id === 0 ? 'landscape' : 'portrait',
        fit: i.orientation.id === 0 ? [800, 800] : [550, 1300],
      });
    });

    if (!_.isEmpty(formsCaracterisation) && formsCaracterisation.some(fc => !_.isEmpty(fc.AttributeData))) {
      const maxColAllowed = 6;
      const maxRowsBeforePageBreak = 11;
      const divide = _.chunk(formsCaracterisation, maxColAllowed);
      let shouldBreak = false;
      // For each group, split recursively the array based on the length of each value traversed
      divide.forEach(panelListGroup => {
        const panelListTable = this.buildPanelListTable(panelListGroup, translations, maxColAllowed, this.pdfReportUserByCol);
        const sum = [];
        const recursSplit = (arrayToSplit) => {
          const split = [];
          arrayToSplit.forEach((value, index) => {
            if (value.length >= maxColAllowed) {
              split.push([...value.slice(0, maxColAllowed + 1)]);
              arrayToSplit[index].splice(0, maxColAllowed + 1);
              shouldBreak = false;
            } else {
              split.push([...value]);
              shouldBreak = true;
            }
          });
          sum.push(split);
          if (!shouldBreak) {
            recursSplit(arrayToSplit);
          }
        };
        recursSplit(panelListTable);
        sum.forEach((table) => {
          content.push(
            {
              pageBreak: sum[0].length > maxRowsBeforePageBreak ? 'before' : '',
              pageOrientation: 'landscape',
              text: ''
            },
            {
              margin: [0, 0, 0, 20],
              table: {
                heights: 10,
                body: table
              }
            }
          );
        });
      });
    }
    return content;
  }

  getStyles(): any {
    return {
      appendices: {
        fontSize: 24,
        alignment: 'center',
        margin: [0, 20, 0, 10]
      },
      name: {
        fontSize: 12,
        margin: [0, 0, 0, 10]
      },
      order: {
        margin: [10, 0, 0, 10]
      },
      step: {
        margin: [15, 0, 0, 10]
      },
      visit: {
        margin: [5, 0, 0, 10]
      },
      response: {
        fontSize: 8,
        alignment: 'justify'
      },
      tableHeader: {
        fontSize: 8,
        bold: true,
        margin: [2, 0, 0, 0]
      },
      textColor: {
        color: '#676a6c'
      },
      title: {
        fontSize: 26,
        margin: [0, 0, 0, 5]
      },
      subTitle: {
        fontSize: 16,
        bold: true,
        margin: [0, 20, 0, 15]
      },
      routineTitle: {
        fontSize: 14
      }
    };
  }

  getTranslation(text: any): Observable<string> {
    return typeof text === 'string'
      ? this.dnaTranslateService.translateMessage(text)
      : this.dnaTranslateService.translateMessage(text.sentence, {element: text.type});
  }

  getTranslations(): Observable<PDFTranslation> {
    const messages = new PDFTranslation();
    const observables: Observable<string>[] = [];
    let translation$: Observable<string>;

    _.forOwn(messages, (value, key) => {
      translation$ = this.getTranslation(value).pipe(tap((trad: string) => messages[key] = trad));
      observables.push(translation$);
    });

    return observableForkJoin(observables).pipe(map(() => messages));
  }

  getRoutineTableHead(tableRoutine: any[]) {
    let routinetableHead = [];
    tableRoutine.forEach((row, index) => {
      if (index === 0) {
        routinetableHead = _.keys(_.pickBy(row, (value, key) => _.isUndefined(value)
          || (key === 'quantity' && _.every(value, ['isStarted', false]))
          || (key === 'quantitiesByVolunteer' && _.every(value, ['isStarted', false]))
          || (key === 'pauseTime' && (value === 0 || (_.get(value, 'hour') === 0 && _.get(value, 'minute') === 0 && _.get(value, 'second') === 0)))
          || key !== 'pauseTime' && _.get(value, 'length', 0) === 0
        ));
        if (_.isEmpty(row.applyTo)) {
          routinetableHead.push('applyTo');
        }
        if (_.isEmpty(row.timeDrying)) {
          routinetableHead.push('timeDrying');
        }
        if (_.isEmpty(_.get(row, 'dryingTypes', []))) {
          routinetableHead.push('dryingTypes');
        }
      } else {
        const emptyKeys = _.keys(_.pickBy(row, (value, key) =>
          _.isUndefined(value)
          || (key === 'quantity' && _.every(value, ['isStarted', false]))
          || (key === 'quantitiesByVolunteer' && _.every(value, ['isStarted', false]))
          || (key === 'pauseTime' && (value === 0 || (_.get(value, 'hour') === 0
            && _.get(value, 'minute') === 0 && _.get(value, 'second') === 0)))
          || key !== 'pauseTime' && _.get(value, 'length', 0) === 0
        ));
        routinetableHead = _.intersection(emptyKeys, routinetableHead);
      }
    });
    return routinetableHead;
  }

  /**
   * Construction du tableau des pannelistes
   * filtre du tableau sur les lignes vides
   * @param formsCharacterisation
   * @param translations
   * @param maxCol
   * @param pdfReportUserByCol
   */
  buildPanelListTable(formsCharacterisation, translations, maxCol: number, pdfReportUserByCol): any[] {
    const tab = [];
    const header = [];
    const rows = [];
    if (pdfReportUserByCol) {
      // Building a row per volunteer
      formsCharacterisation.forEach((attribute) => {
        const volunteerRow = [];
        attribute.AttributeData.forEach((data, index) => {
          if (index % maxCol === 0) {
            volunteerRow.push({
              text: attribute.idPanelist,
              fillColor: '#d6d8db',
              style: ['tableHeader', 'textColor']
            });
          }
          data.AttributeDetails.forEach((detail) => {
            if (parseInt(detail.Code, 10) === parseInt(data.Value, 10)) {
              volunteerRow.push({
                text: detail.Text,
                style: ['response', 'textColor']
              });
            }
          });
          if (!volunteerRow[index + 1]) {
            volunteerRow[index + 1] = {
              text: '',
              style: ['response', 'textColor']
            };
          }
        });
        rows.push(volunteerRow);
      });
      // Since every attribute are the same for every user, we verify and take the first one as a model
      if (formsCharacterisation[0]) {
        formsCharacterisation[0].AttributeData.forEach((formData, index) => {
          // If the index is divisible by the max column param, it means we need to add a header attribute
          if (index % maxCol === 0) {
            // Build a header from every attribute found in the characterisation object
            header.push({
              text: translations.attributes,
              fillColor: '#d6d8db',
              style: ['tableHeader', 'textColor']
            });
          }
          header.push({
            text: formData.AttributeName,
            fillColor: '#d6d8db',
            style: ['tableHeader', 'textColor']
          });
        });
      }
      tab.push(header);
      // Pushing each volunteer line one after the other
      rows.forEach((row) => {
        tab.push(row);
      });
    } else {
      // Building a row per attribute
      header.push({
        text: translations.attributes,
        fillColor: '#d6d8db',
        style: ['tableHeader', 'textColor']
      });
      const attributeRow = {};
      formsCharacterisation.forEach((attribute) => {
        attribute.AttributeData.forEach((data) => {
          if (!attributeRow[data.AttributeName]) {
            attributeRow[data.AttributeName] = [{
              text: data.AttributeName,
              fillColor: '#d6d8db',
              style: ['response', 'textColor']
            }];
          }
          const textData = this._commonUtilsPDF.dataValueHandler(data);

          attributeRow[data.AttributeName].push({
            text: textData,
            style: ['response', 'textColor']
          });
        });
        header.push({
          text: attribute.idPanelist,
          fillColor: '#d6d8db',
          style: ['tableHeader', 'textColor']
        });
        rows.push(attributeRow);
      });
      tab.push(header);
      // Pushing each data line one after the other
      for (const value of Object.entries(attributeRow)) {
        tab.push(value[1]);
      }
    }
    this.filterEmptyRows(tab);
    return tab;
  }

  /**
   * Méthode pour supprimer du tableau les lignes pour lesquelles
   * il n'y a aucune réponse
   * @param pannelistTab
   */
  filterEmptyRows(pannelistTab) {
    _.remove(pannelistTab, function (ligne) {
      return ligne.every(function (element, index) {
        return index !== 0
          ? element.text === ''
          : true;
      });
    });
  }

  /**
   * La conclusion (en html) est converti en un objet compatible avec pdfMake
   * @param conclusion
   * @return formatedConclusion: un tableau d'objets avec des texts/images et leur style
   */
  formatRichText(richText: string): any[] {
    let formatedRichText = [];
    if (!_.isNil(richText)) {
      if (!richText.startsWith('<')) {
        richText = '<p>' + richText + '</p>';
      }
      formatedRichText = htmlToPdfmake(richText);
      formatedRichText.map((obj) => {
        // font size
        if (_.get(obj, 'style', []).includes('ql-size-small')) {
          _.set(obj, 'fontSize', 6);
        } else if (_.get(obj, 'style', []).includes('ql-size-large')) {
          _.set(obj, 'fontSize', 10);
        } else if (_.get(obj, 'style', []).includes('ql-size-huge')) {
          _.set(obj, 'fontSize', 12);
        } else {
          _.set(obj, 'fontSize', 8);
        }
        // marge
        if (_.get(obj, 'style', []).includes('html-p')) {
          _.set(obj, 'margin', [0, 0, 0, 0]);
        }
        // color
        const color = _.get(obj, 'color', '');
        if (color == '#000000' || color == '') {
          _.set(obj, 'color', '#676a6c');
        }
      });
    }
    return formatedRichText;
  }

  /********** GETTERS & SETTERS **********/

  getFormsCaracterisation(): FicheCaracterisation[] {
    return _.cloneDeep(this.formsCaracterisation);
  }

  setFormsCaracterisation(formsCaracterisation: FicheCaracterisation[]) {
    this.formsCaracterisation = _.cloneDeep(formsCaracterisation);
  }

  getPanelists(): Panelist[] {
    return _.cloneDeep(this.panelists);
  }

  setPanelists(panelists: Panelist[]) {
    this.panelists = _.cloneDeep(panelists);
  }

  getValuesFromAttributeDetails(): string[][] {
    return _.cloneDeep(this.valuesFromAttributeDetails);
  }

  setValuesFromAttributeDetails(valuesFromAttributeDetails: string[][]) {
    this.valuesFromAttributeDetails = _.cloneDeep(valuesFromAttributeDetails);
  }


  formatValues(values: string[]) {
    return values.reduce((name, b, index) => {
      if (index > 0) { name += ' , '; }
      name += b;
      return name;
    }, '');
  }

  formatActSynOrchValues(values: { [idEtude: string]: string; }[]) {
    let stringValues = [];
    if( Array.isArray( values ) ){
      values.forEach(o => stringValues = stringValues.concat(o));
      return stringValues.join(', ');
    }
    return values
  }

  refTestedValues(tabReferenceTested: SextiFormula[], isBareSkin: boolean, tab: CampaignMultiReferenceTested) {
    const refTestedValues = [];
    tabReferenceTested.forEach(
      (val: SextiFormula, index) => {
        const routineName = isBareSkin ? val.routineName : this.onePagerService.getRoutineName(val.routineName, val.campaignName, tab);
        refTestedValues.push(
          [
            {
              text: (`${val.routineLabel}` !== '' && !_.isUndefined(val.routineLabel))
                ? `${routineName} - ${val.routineLabel}`
                : `${routineName}`,
              style: ['response', 'textColor']
            },
            {
              text: `${val.campaignName}`,
              style: ['response', 'textColor']
            },
            {
              text: `${val.visitName}`,
              style: ['response', 'textColor']
            },
            {
              text: `${val.formulaName}`,
              style: ['response', 'textColor']
            },
            {
              text: `${val.formulaName}`,
              style: ['response', 'textColor']
            },
            {
              text: `${val.lot || ''}`,
              style: ['response', 'textColor']
            },
            {
              text: `${val.socle || ''}`,
              style: ['response', 'textColor']
            },
          ]
        );
      }
    );
    return refTestedValues;
  }

  getFormulaByCampaign(idCampaign, formulas, campaignsMulti) {
    const campaignFormulas = _.get(campaignsMulti, 'chosenFormulas', []).find(c => c.idCampaign === idCampaign);
    if (!campaignFormulas) {
      return formulas.map(f => f.name).join(', ');
    } else {
      const activeFormulas = formulas.filter(f => _.get(campaignFormulas, 'formulas', []).find(cf => cf.id === f.id && cf.isActive));
      return activeFormulas.map(f => f.name).join(', ');
    }
  }

}
