<div [style.height]="getHeight(page.orientation)" class="image-layout-container">
  <button *ngIf="!captureMode" class="comment btn btn-primary btn-sm m-r-sm" (click)="onCommentAdd(page)">Ajouter un commentaire</button>
  <gridster [id]='page.id' [options]="!captureMode ? page.options : previewOption" [ngClass]="captureMode ? 'capture '+page.id : page.id">
    <gridster-item [item]="item" *ngFor="let item of page.content; let itemIndex = index">
      <div *ngIf="item.type === gridContentCommentType" style="height: 100%">
        <div class="row" style="margin:0; height: 100%">
          <div class="col-11 gridster-item-content" style="margin-bottom: 2em">
            <editor class="col-12" [(ngModel)]="item.comment" style="height: 100%" [inline]="true" [init]="initEditor"
                    (ngModelChange)="onChangeComment(page)">
            </editor>
          </div>
          <div class="col-1" *ngIf="!captureMode">
            <button (click)="removeItem(page, itemIndex)" type="button" class="btn btn-link">
              <i class='fa fa-remove'></i>
            </button>
            <i class='fa fa-arrows' style="padding-left: 13px;"></i>
          </div>
        </div>
      </div>
      <div *ngIf="item.type === gridContentImageType">
        <div class="container">
          <div class="container__one-pager">
            <div class="container__one-pager__items">

              <div class="container__one-pager__items__content">
                <div class="container__one-pager__items__content__text">
                  <span>{{item.name}}</span>
                </div>

                <div class="container__one-pager__items__content__button">
                  <button [ngClass]="{'capture-button': captureMode}" (click)="removeItem(page, itemIndex)" type="button"
                    class="btn btn-link btn-sm">X
                  </button>
                </div>
              </div>

              <div class="container__one-pager__items__img">
                <img [src]="item.data | async" alt="" [id]="item.id">
              </div>

            </div>

          </div>
        </div>
      </div>
    </gridster-item>
  </gridster>
</div>


