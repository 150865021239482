<dna-layout>
  <h1 class="mb-4" id="banner-title">
    <span translate>CAMPAIGN</span>
  </h1>
<ngx-loading [show]="showSpinner">
</ngx-loading>
  <div *ngIf="!error">
    <nav aria-label="breadcrumb" id="banner-nav">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/dashboard" translate>DASHBOARD</a>
        </li>
        <li class="breadcrumb-item">
          <a routerLink="/campaigns" translate>CAMPAIGNS</a>
        </li>
        <li class="breadcrumb-item" *ngIf="campaign">
          <a [routerLink]="['/campaigns', campaign.id, 'edit', 'analyse']" translate>{{ campaign.name }}</a>
        </li>
        <li class="breadcrumb-item active" translate>ANALYTICS</li>
      </ol>
    </nav>

    <div class="block" *ngIf="isNewReport">
      <div class="float-end mb-1">
        <button class="btn btn-primary margin-btn" type="button" (click)="takeCaptures()"
        translate>TAKE_CAPTURES
        <i class="fa fa-camera" aria-hidden="true"></i>
        </button>
        <button class="btn btn-primary margin-btn left-margin" type="button" (click)="goToPowerBIDescription(workflow)"
          translate>EDIT_CHART</button>
      </div>
      <dna-dashboard class="block" *ngIf="campaign && workflow" [campaign]="campaign" [workflow]="workflow" [lang]="currentLanguage"
        [onImageLoad]="uploadImage" (_onMultipleCaptures) = "this.onMultipleCaptures($event)">
      </dna-dashboard>
    </div>
    <div class="block" *ngIf="!isNewReport">
      <dna-report-layout [workflow]="workflow" [imagesFromOnePager]="images"
        (updateImagesAndOnePager)="updateOnePager($event)" (onImageLoaded)="onImageLoaded($event)"
        (onPowerbiCapture)="onCapture($event)"></dna-report-layout>
    </div>
  </div>
</dna-layout>
