<div class="container">
  <ngx-loading [show]="showSpinner">
  </ngx-loading>
  <div *ngIf="!showSpinner && !error" class="ibox float-e-margins">
    <form novalidate name="campaignForm">
      <div class="ibox-title">
        <h5 class="center" translate> PARAMETERS </h5>
        <!-- <p *ngIf="isDisabled" translate> PUBLISHED_CAMPAIGNS_MESSAGE </p> -->
        <div *ngIf="isChangedCampaign()" class="d-flex flex-row gap-1">
          <button id="save" class="btn btn-primary" type="button" (click)="save()" translate>SAVE</button>
          <button id="cancel" class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
        </div>
      </div>

      <div class="ibox-content d-flex flex-column gap-3">
        <div *ngIf="!isDisabled || (isCampaignPublished && allowAttributionAfterPublish)" class="form-group">
          <div class="row">
            <label class="col-3 control-label" translate>ATTRIBUTION_USERS_FORMULA</label>
            <div class="col-9">
              <button id="save" class="btn btn-primary btn-sm" (click)="openModalAttribution()" translate>MANAGE_ATTRIBUTION_USERS_FORMULA</button>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="(campaign?.parameters.viewType !== viewTypes.ModeList) && (currentWorkspace.preferences.parameters.editSubmitted.isActive)">
          <div class="row">
            <label class="col-3 control-label">{{ "EDIT_SUBMITTED" | translate }}</label>
            <div class="col-9">
              <dna-switch [disabled]="isDisabled" [isSelected]="campaign?.parameters.editSubmitted" (onBooleanChanged)="onChangeData($event, campaign.parameters, 'editSubmitted')"></dna-switch>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="(campaign?.parameters.viewType !== viewTypes.ModeList) && (currentWorkspace.preferences.parameters.returnButton.isActive)">
          <div class="row">
            <label class="col-3 control-label">{{ "RETURN_BUTTON" | translate }}</label>
            <div class="col-9">
              <dna-switch [disabled]="isDisabled" [isSelected]="campaign?.parameters.previousAvailable" (onBooleanChanged)="onChangeData($event, campaign.parameters, 'previousAvailable')"></dna-switch>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="currentWorkspace.preferences.parameters.feedback.isActive">
          <div class="row">
            <label class="col-3 control-label">{{ "DISPLAY_FEEDBACK" | translate }}</label>
            <div class="col-9">
              <dna-switch [disabled]="isDisabled" [isSelected]="campaign?.parameters.displayFeedback" (onBooleanChanged)="onChangeData($event, campaign.parameters, 'displayFeedback')"></dna-switch>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="currentWorkspace.preferences.parameters.blocksName.isActive">
          <div class="row">
            <label class="col-3 control-label">{{ "DISPLAY_BLOCK_NAME" | translate }}</label>
            <div class="col-9">
              <dna-switch [disabled]="isDisabled" [isSelected]="campaign?.parameters.displayBlockName" (onBooleanChanged)="onChangeData($event, campaign.parameters, 'displayBlockName')"></dna-switch>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="currentWorkspace.preferences.parameters.notificationPush.isActive">
          <div class="row">
            <label class="col-3 control-label">{{ "PUSH_NOTIFICATION" | translate }}</label>
            <div class="col-9">
              <dna-switch [disabled]="isDisabled" [isSelected]="campaign?.parameters.pushNotification" (onBooleanChanged)="onChangeData($event, campaign.parameters, 'pushNotification')"></dna-switch>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="currentWorkspace.preferences.parameters.pdfReportUserByCol.isActive">
          <div class="row">
            <label class="col-3 control-label">{{ "PDF_REPORT_USER_BY_COL" | translate }}</label>
            <div class="col-9">
              <dna-switch [isSelected]="campaign?.parameters.pdfReportUserByCol" (onBooleanChanged)="onChangeData($event, campaign.parameters, 'pdfReportUserByCol')"></dna-switch>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="campaign?.parameters.pushNotification">
          <div class="row" *ngIf="currentWorkspace.preferences.parameters.notificationRecall.isActive">
            <label class="col-3 control-label">{{ "NOTIFICATION_RECALL" | translate }}</label>
            <div class="col-1">
              <dna-switch [disabled]="isDisabled" [isSelected]="campaign?.parameters.reminder.active" (onBooleanChanged)="onChangeData($event, campaign.parameters.reminder, 'active')"></dna-switch>
            </div>
            <div class="col-5">
              <label class="control-label" translate>REMAINDER_EXPLAINATION</label>
            </div>
          </div>
          <div class="row margin-top" *ngIf="campaign?.parameters.reminder.active">
            <label class="col-3 control-label">{{ "DELAY_NOTIFICATIONS" | translate }}</label>
            <div class="col-4">
              <input [disabled]="isDisabled" class="form-control" type="number" [(ngModel)]="campaign?.parameters.reminder.delay" name="delay"
                min="1">
            </div>
            <div class="col-2">
              <span>minutes</span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="!showSpinner && error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "ERROR" | translate }}</h5>
        </div>
      </div>
    </div>
    <dna-error-page (onClickReload)="init()"></dna-error-page>
  </div>
</div>
