<div class="dna-filters__tab" [ngClass]="{
   'dna-filters__tab--collapsed' : this.collapsed
}" (click)="this._handleWrapperCollapse(this.collapsed)">
<i [ngClass]="{
   'left'   : this.collapsed,
   'right'  : !this.collapsed
}" class="arrow"></i>
</div>

<div class="dna-filters" [ngClass]="{'dna-filters--collapsed' : this.collapsed}">
<div class="dna-filters__header"> <p translate> VIZUALIZATIONS.FILTERS.FILTERS </p> </div>
<div class="dna-filters__body">
   <div dna-loader *ngIf="this.loading"></div>
   <div *ngIf="!this.loading && !this.filters && !this.filters.length" translate> VIZUALIZATIONS.FILTERS.NO_FILTERS
   </div>
   <form id="dna-filters" *ngIf="this.form && this.filters" [formGroup]="this.form" (ngSubmit)="this._onSubmit()">
      <div class="dna-filters__form-group" *ngFor="let formPart of this._objectKeys(this.filters); let i = index;">
         <div class="dna-filters__form-group__header" (click)="this._handleWrapFilterCategory(formPart, this.filters);">
            <h4> {{ this.filters[formPart].name | translate }}
               <small>({{ getSelectedFilters(formPart) }}/{{ this.filters[formPart].options.length }})</small></h4>
            <i [ngClass]="{
               'up'     : this.filters[formPart].collapsed,
               'down'   : !this.filters[formPart].collapsed
            }" class="arrow"></i>
         </div>
         <div [ngClass]="{'form-subgroup-container--collapsed' : this.filters[formPart].collapsed }"
            class="dna-filters__form-subgroup-container">
            <small *ngIf="!this.filters[formPart].options.length" translate>
               VIZUALIZATIONS.FILTERS.NO_FILTERS_CATEGORY </small>
            <!-- Check all / none part -->
            <div *ngIf="this.filters[formPart].options.length" class="dna-filters__form-subgroup-container-group--subgroup">
               <div class="dna-filters__form-subgroup-container-group">
                  <label title="{{ 'VIZUALIZATIONS.FILTERS.CHECK_ALL' | translate }}"
                     [attr.for]="this.filters[formPart].name + '_checkall'" translate>
                     VIZUALIZATIONS.FILTERS.CHECK_ALL
                  </label>
                  <input [attr.id]="this.filters[formPart].name + '_checkall'"
                     [attr.name]="this.filters[formPart].name + '_checkall'" class="dna-filters__form-control" type="checkbox"
                     (change)="this.checkAllFilters(formPart, $event)"
                     [checked]="this.checkEveryObject(formPart)" />
               </div>
            </div>
            <!-- Real options part -->
            <div [formGroupName]="formPart" class="dna-filters__form-subgroup-container-group--subgroup"
               *ngFor="let option of this.filters[formPart].options | groupBy:'clusterKey':'clusterValue'">
               <div *ngIf="option.label && option.data" class="dna-filters__form-subgroup-container-group--grouped">
                  <p title="{{ option.label | applyTranslation : this.lang : Languages.Default }}"> {{ option.label }} </p>
                  <div *ngFor="let op of option.data" class="dna-filters__form-subgroup-container-group">
                     <label title="{{ op.value | applyTranslation : this.lang : Languages.Default }}"
                        [attr.for]="this.filters[formPart].name + '_' + op.clusterKey + '_' + op.key">
                        {{ op.value | applyTranslation : this.lang : Languages.Default }}
                     </label>
                     <input 
                        [attr.id]="this.filters[formPart].name + '_' + op.clusterKey + '_' + op.key" 
                        [attr.name]="op.key" 
                        class="dna-filters__form-control" 
                        type="checkbox"
                        [formControlName]="op.key" />
                  </div>
               </div>
               <div *ngIf="!option.label && !option.data" class="dna-filters__form-subgroup-container-group">
                  <label title="{{ option.value | applyTranslation : this.lang : Languages.Default }}"
                     [attr.for]="this.filters[formPart].name + '_' + option.key">
                     {{ option.value | applyTranslation : this.lang : Languages.Default }}
                  </label>
                  <input 
                     [attr.id]="this.filters[formPart].name + '_' + option.key" 
                     [attr.name]="option.key" 
                     class="dna-filters__form-control" 
                     type="checkbox"
                     [formControlName]="option.key" />
               </div>

            </div>
         </div>
      </div>
   </form>
</div>
<div class="dna-filters__footer">
   <input dna-button class="button--primary" type="submit" (click)="this._onInitFilters()"
      [attr.disabled]="this.loading || (!this.filters && !this.filters.length) ? '' : null"
      value="{{ 'RESET' | translate }}">
   <input dna-button class="button--primary" type="submit" (click)="this._onSubmit()"
      [attr.disabled]="this.loading || (!this.filters && !this.filters.length) ? '' : null"
      value="{{ 'VIZUALIZATIONS.APPLY' | translate }}">
</div>
</div>