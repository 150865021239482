<ngx-loading [show]="showSpinner">
</ngx-loading>
<div class="modal-header">
  <button id="close" class="close" type="button" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
    <span class="sr-only">Close</span>
  </button>
  <div class="header-padding">
    <i class="fa fa-line-chart modal-icon"></i>
    <h4 class="modal-title" translate>SAVE_CAPTURE</h4>
    <small translate>SAVE_CAPTURE_DESCRIPTION</small>
  </div>
</div>
<div class="modal-body">
  <div class="text-center">
    <img [src]="capturePowerBI" />
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="onSubmit(capturePowerBI)" translate>VALIDATE</button>
  <button class="btn btn-default" type="button" (click)="cancel()" translate>CANCEL</button>
</div>
