<form id="OLD_LESS_VERSION">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <ng-container *ngIf="!showSpinner && !error" class="ibox float-e-margins">
    <div class="modal-scroll">
      <div class="modal-header">
        <h3 class="text-center">{{'ATTRIBUTION_USERS_FORMULA' | translate}}</h3>
      </div>
      <div class="modal-body" style="width:100% ">
        <div class='row'>
          <div class="col-md-12 text-center">
            <div class="btn-group btn-group-lg" role="group" style="padding-bottom: 30px">
              <button class="btn" [ngClass]="{'btn-secondary': display != 'attribution', 'btn-primary': display == 'attribution' }" (click)="display = 'attribution'"
                translate>ATTRIBUTION</button>
              <button class="btn" [disabled]="isCampaignPublished" [ngClass]="{'btn-secondary': display != 'sequential', 'btn-primary': display == 'sequential' }" (click)="display = 'sequential'"
                translate>SEQUENCE</button>
              <button class="btn" [disabled]="isCampaignPublished" [ngClass]="{'btn-secondary': display != 'randomization', 'btn-primary': display == 'randomization' }"
                (click)="display = 'randomization'"  translate>RANDOMIZATION</button>
            </div>
          </div>
        </div>
        <div *ngIf="display === 'attribution'">
          <table class="table">
            <th></th>
            <th>Formulas\Users</th>
            <th *ngFor="let user of users">{{user.name}}</th>
            <tr *ngFor="let formula of users[0].formula">
              <td></td>
              <td>{{formula.name}}</td>
              <td *ngFor="let user of users">
                <div *ngIf="isCampaignPublished">
                  <label class="btn" *ngIf="evalStartingByFormula[user.key][formula.id].isStarting" style="pointer-events: none; opacity: 0.33;">
                    <i class="fa" [ngClass]="{'fa-check-square-o': checkboxFormula[user.key][formula.id], 'fa-square-o': !checkboxFormula[user.key][formula.id]}"></i>
                  </label>
                  <label class="btn" *ngIf="!evalStartingByFormula[user.key][formula.id].isStarting" (click)="updateCheckbox(user.key, formula.id)">
                    <i class="fa" [ngClass]="{'fa-check-square-o': checkboxFormula[user.key][formula.id], 'fa-square-o': !checkboxFormula[user.key][formula.id]}"></i>
                  </label>
                </div>
                <div *ngIf="!isCampaignPublished">
                  <label class="btn" (click)="updateCheckbox(user.key, formula.id)">
                    <i class="fa" [ngClass]="{'fa-check-square-o': checkboxFormula[user.key][formula.id], 'fa-square-o': !checkboxFormula[user.key][formula.id]}"></i>
                  </label>
                </div>
              <td>
            </tr>
          </table>
        </div>
        <div *ngIf="display === 'sequential'">
          <div class="row">
            <p class="control-label col-sm-8"> {{ "SEQUENTIAL_MODE" | translate}} </p>
            <div class="col-sm-4">
              <dna-switch [disabled]="campaignCopy !== campaignStates.Draft && campaignCopy !== campaignStates.Suspended" [isSelected]="campaignCopy.parameters.sequentialMode" (onBooleanChanged)="campaignCopy.parameters.sequentialMode = !campaignCopy.parameters.sequentialMode"></dna-switch>
            </div>
          </div>
          <div class="row">
            <p class="col-md-12 text-center" translate>SEQUENTIAL_DESCRIPTION</p>
          </div>
          <div class="row" *ngIf="campaignCopy.parameters.sequentialMode">
            <div class="col-md-12 text-center">
              <div class="btn btn-default" (click)="randomize()">
                <i class="fa fa-random"></i> {{'RANDOM' | translate}}
              </div>
            </div>
            <div class="col-md-12">
              <div *ngFor="let user of users">
                <p class="col-md-12">{{'USER' | translate}} : {{ user.name }}</p>
                <div class="container row" [dragula]='"dna-sequence-bag" + user.key' [(dragulaModel)]='user.formula' id="source">
                  <div *ngFor="let formula of user.formula" class="draggableComponents formula" id="formula-{{formula.name}}">
                    <button class="btn btn-outline-primary" style="margin: 2px">{{ formula.name}} </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="display == 'randomization'">
          <span translate>APPLY_RANDOMIZATION</span>
          <dna-switch [isSelected]="campaignCopy.parameters.randomMode" (onBooleanChanged)="campaignCopy.parameters.randomMode = !campaignCopy.parameters.randomMode"></dna-switch>
          <div *ngIf="campaignCopy.parameters.randomMode">
            <span translate>UPLOAD_RANDOMIZATION</span>
            <input type="file" (change)="onFileChange($event)" multiple="false" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button id="save" type="button" class="btn btn-primary" (click)="save()" translate>SAVE</button>
      <button id="undo" type="button" class="btn btn-default" (click)="cancel()" translate>CANCEL</button>
    </div>
  </ng-container>

</form>
