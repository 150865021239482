<div class="dna-scale-bar">
    <div *ngIf="this.error" class="error"> {{ this.errorMessage | translate }} </div>
    <div class="ratio-wrapper" *ngIf="!this.error">
        <div class="items-container">
            <div class="item" *ngFor="let item of this.getSegment()" [style.background-color]="item"></div>
            <ng-container *ngFor="let parameter of this.parameters">
                <div *ngFor="let data of parameter.data" [class.r-value--bottom]="!parameter.isBench"
                    [style.left]="this.getRPosition(data.y)" class="r-value">
                    <div dna-scale-bar-tooltip [lang]="this.lang" [color]="parameter.color" [data]="{ 
                        label : parameter.name, 
                        data : data.details
                    }" class="tooltip-dna-container">
                    </div>
                    <div class="label">{{ parameter.name }}</div>
                    <svg height="100%" width="100%" viewBox="0 0 100 100">
                        <polygon [style.fill]="parameter.color" [style.stroke]="purple" [style.stroke-width]="1"
                            points="0,0 100,0 50,100" />
                    </svg>
                </div>
            </ng-container>
        </div>
    </div>
</div>