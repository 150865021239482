import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DNAObject, User, Workspace } from './../../../types';
import { ErrorManagerService } from './../../services/errorManager.service';
import { HttpRestService } from './../../services/httpRest.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'dna-create-library-item-modal',
    templateUrl: './create-library-item-modal.component.html',
    styleUrls: ['./create-library-item-modal.component.less', '../modals.component.less']
})
export class CreateLibraryItemModalComponent implements OnInit {

    @Input() data: DNAObject;
    @Input() faIcon: string;
    @Input() title: string;
    @Input() type: string;

    user: User;
    submitted: boolean = false;

    constructor(
        private activeModal: NgbActiveModal,
        private errorManager: ErrorManagerService,
        private httpRestService: HttpRestService,
        private userService: UserService,
    ) { }

    ngOnInit() {
        this.user = this.userService.getUser();
    }

    cancel() {
        this.activeModal.dismiss();
    }

    changeCurrentWorkspace(workspace: Workspace) {
        this.user.currentWorkspace = workspace;
    }

    save(form: NgForm) {
        this.submitted = true;
        if (!form.invalid) {
            this.activeModal.close(this.data);
        } else {
            this.errorManager.displayMessage('ON_ERROR_FORM', 'danger');
        }
    }

}
