import {Chart} from '../chart.model';
import {ColumnChartOptions} from './columnchart-options.model';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import * as ColumnChartLabelFormatter from './columnchart.label.formatter';
import * as ColumnChartTooltipFormatter from './columnchart.tooltip.formatter';
import {Languages, COMPUTING_METHODS} from '../../enums';
import {Descriptor} from '../../../../../../../../types';

import * as ColumnChartMedianCompute from './methods/median.method';
import * as ColumnChartDrilledCompute from './methods/drilled.method';
import * as ColumnChartCountCompute from './methods/count.method';
import * as ColumnChartNoneCompute from './methods/none.method';
import * as ColumnChartNoneMedianCompute from './methods/none-median.method';
import * as ColumnChartConfortWithPValue from './methods/confortWithPvalue.method';
import * as ColumnChartConfortPreferences from './methods/confortPreferences.method';
import * as ColumnChartConfortPercentWithCategory from './methods/confortPercentageWithCategories.method';
import {Table} from '../table';
import {PayloadInterface} from './models';

const mapSettingsKey: Map<string, string> = new Map();
mapSettingsKey.set( 'scale', 'yAxis' );
mapSettingsKey.set( 'show_column_zero', 'show_column_zero' );
mapSettingsKey.set( 'show_attributes_blocks', 'show_attributes_blocks' );
mapSettingsKey.set( 'show_default_language', 'show_default_language' );
mapSettingsKey.set( 'show_data_label', 'show_data_label' );

const MAX_DRILL_COUNT = 2;

interface ItemInterface {
  attribute: {
    label: any;
  };
  values: Array<ItemInterface | ItemEndInterface>;
}

interface ItemEndInterface {
  label: string;
  value: {
    label: string;
    [ x: string ]: any;
  };
}

export class ColumnChart extends Chart {

  constructor (
    _parameters?: any,
    _data?: Array<any>,
    _lang?: string,
    _filters?: any,
    private _translateService?: TranslateService
  ) {
    super( _parameters, _data, _lang, _filters );
    if ( this.data && this.data.length ) {
      this.tableRawData = this.formatRawData( _data, _lang );
      this.generateParametersAssignment( this.parameters );
      this.generateDataAssignment( this.data, this.lang );
    }
    this.loadChartTitles( this.parameters, _lang );
  }

  public static mapSettings: Map<string, string> = mapSettingsKey;
  protected _baseParameters: any = {
    chart: {
      type: 'column',
      events: {
        load: function ( el ) {
          this.linkRoutineToSeries( el.target );
          this.parameters.series.forEach( ( serie: any ) => {
            serie.color = el.target.series.find(
              ( x: any ) => x.name === serie.name
            ).color;
          } );
          this.updateChartTranslations( el.target, this.parameters, this.lang );
          this.buildGraphOptions( this.parameters );
          this.onOptionChangeAfterLoadSubject.next( this.options );
        }.bind( this ),
      },
    },
    xAxis: {
      type: 'category',
      crosshair: true,
      useHTML: true,
      labels: {
        formatter: function ( el: any ) {
          const {userOptions} = el.chart;
          const langs: Array<string> = [ this.lang ];
          if (
            userOptions.hasOwnProperty( 'plotOptions' ) &&
            userOptions.plotOptions.hasOwnProperty( 'default_language' )
          ) {
            if (
              userOptions.plotOptions.default_language.enabled &&
              langs.indexOf( Languages.Default ) === -1
            ) {
              langs.push( Languages.Default );
            }
          }
          if (
            userOptions.hasOwnProperty( 'plotOptions' ) &&
            userOptions.plotOptions.hasOwnProperty( 'attributes_blocks' )
          ) {
            return ColumnChartLabelFormatter.formatter(
              el,
              langs,
              userOptions.plotOptions.attributes_blocks.enabled
            );
          } else {return ColumnChartLabelFormatter.formatter( el, langs, false ); }
        }.bind( this ),
      },
    },
    yAxis: {
      tickInterval: 1,
      title: {
        enabled: true,
        text: '',
      },
    },
    tooltip: {
      enabled: false,
      useHTML: true,
      formatter: function ( el: any ) {
        const {userOptions} = el.chart;
        if (
          userOptions.hasOwnProperty( 'plotOptions' ) &&
          userOptions.plotOptions.hasOwnProperty( 'attributes_blocks' )
        ) {
          return ColumnChartTooltipFormatter.formatter(
            this,
            userOptions.language,
            userOptions.plotOptions.attributes_blocks.enabled
          );
        } else {
          return ColumnChartTooltipFormatter.formatter(
            this,
            userOptions.language,
            false
          );
        }
      },
    },
  };

  protected staticParameters: any = {
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          inside: false,
          color: 'black',
          formatter: this.getLabelFormatter(),
        },
      },
    },
  };

  private initialData: any;
  public onDrillSubject = new Subject<string>();
  private drilledState = false;

  private generateParametersAssignment ( parameters ) {
    const method = this.parameters.compute
      ? this.parameters.compute.method
      : null;
    if ( method === 'count' ) {
      parameters.yAxis.max = null;
      this.setStaticParameters(
        {
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
              },
            },
          },
        },
        this._baseParameters
      );
    }
    // CANT SEE THE PURPOSE HERE, REMOVE IF NOT NEEDED
    Object.assign( {...this._baseParameters, parameters} );

    Object.keys( {...this._baseParameters} ).forEach( ( k ) => {
      this.parameters[ k ] = Object.assign(
        {...this._baseParameters[ k ]},
        parameters[ k ]
      );
    } );
    Object.keys( {...parameters} ).forEach( ( k ) => {
      this.parameters[ k ] = Object.assign(
        {...this.parameters[ k ]},
        parameters[ k ]
      );
    } );
    this.setStaticParameters( this.staticParameters );
  }

  /**
   * Set default parameters of chart if not already set / Recursive
   * @param parameters Parameters to set
   * @param target target to be set
   */
  private setStaticParameters (
    parameters: any,
    target: any = this.parameters,
    previousKey: string = null,
    previous: any = null
  ): void {
    const properties = Object.keys( parameters );
    if ( this.checkEnding( parameters ) ) {
      if ( typeof target === 'object' && !Object.keys( target ).length ) {
        previous[ previousKey ] = parameters;
      }
      return;
    }
    properties.forEach( ( key ) => {
      if ( !target[ key ] ) {target[ key ] = {}; }
      this.setStaticParameters( parameters[ key ], target[ key ], key, target );
    } );
  }

  /**
   * Check if the data passed is not a object / dont think its optimized so you can change it if necessary
   * @param data data to be checked
   */
  private checkEnding ( data: any ) {
    if ( Array.isArray( data ) ) {return true; }
    const stopType: Set<string> = new Set( [ 'function', 'string', 'number' ] );
    if ( stopType.has( typeof data ) ) {return true; }
    if ( !Object.keys( data ).length ) {return true; }
    return false;
  }

  protected generateDataAssignment = ( data: any, lang: string ) => {
    this.data = data;
    if ( this.initialData !== data ) {this.initialData = data; }
    const method = this.parameters.compute
      ? this.parameters.compute.method
      : null;
    const baseKey = this.parameters.compute
      ? this.parameters.compute.key
      : 'key';
    const payload = this.formatData(
      this.initialData,
      this.drilledState ? 'drilled' : method,
      baseKey,
      lang,
      this.parameters,
      this.descriptors,
      this.routines
    );
    this.parameters.series = payload.series;
    this.parameters.xAxis.categories = payload.categories;

    if ( payload.options.maxY ) {
      this.parameters.yAxis.max = payload.options.maxY;
    }

    if ( payload.options.minY ) {
      this.parameters.yAxis.min = payload.options.minY;
    }

    if ( payload.options.yAxisLabelFormatterFactory ) {
      if ( !this.parameters.yAxis.labels ) { this.parameters.yAxis.labels = {}; }
      this.parameters.yAxis.labels.formatter = payload.options.yAxisLabelFormatterFactory( this, 'lang' );
    }

    // ENNABLE TOOLTIP WHEN NO DRILLDOWN
    if (
      !this.parameters.drilldown ||
      this.parameters.series.length > MAX_DRILL_COUNT
    ) {
      this.parameters.tooltip.enabled = true;
    } else if (
      this.parameters.hasOwnProperty( 'drilldown' ) &&
      this.parameters.drilldown.enabled
    ) {
      this.parameters.plotOptions.series.cursor = 'pointer';
      this.parameters.plotOptions.series.events = {
        click: ( () => this.handleDrill( this.drilledState ) ).bind( this ),
      };
    }

    if ( this.chart ) {
      this.updateChartTranslations( this.chart, this.parameters, this.lang );
    }
  }

  /**
   * buildGraphOptions
   */
  public buildGraphOptions = ( options: any ) => {
    const {
      title,
      subtitle,
      xAxis,
      yAxis,
      series,
      plotOptions,
      identifier,
      ...rest
    } = options;
    this.options = new ColumnChartOptions(
      {
        general: {title, subtitle},
        xAxis,
        yAxis,
        series,
        plotOptions,
      },
      this.onOptionsChange
    );
  }

  protected setParamsToAxis = ( params: any, axis: any ) =>
    Object.assign( axis, params )

  protected formatData = (
    data: Array<any>,
    method: string,
    baseKey: string,
    lang: string,
    parameters: any,
    descriptors: Array<Descriptor>,
    routines: any
  ): {
    categories: Array<any>;
    series: Array<any>;
    options?: {
      maxY?: number;
      minY?: number;
      yAxisLabelFormatterFactory?: ( objectReference: any, langPath: string ) => ( yAxisPoint: any ) => string;
    };
  } => {
    const payload: PayloadInterface = {
      categories: {},
      series: [],
      options: {},
    };
    const drilledMethod = parameters.compute ? parameters.compute.method : null;
    switch ( method ) {
      case null:
        break;
      case COMPUTING_METHODS.MEDIAN:
        ColumnChartMedianCompute.doWork(
          data,
          baseKey,
          lang,
          descriptors,
          payload,
          routines
        );
        break;
      case COMPUTING_METHODS.DRILLED:
        ColumnChartDrilledCompute.doWork(
          data,
          baseKey,
          lang,
          payload,
          drilledMethod
        );
        break;
      case COMPUTING_METHODS.COUNT:
        ColumnChartCountCompute.doWork(
          data,
          baseKey,
          lang,
          descriptors,
          payload,
          routines
        );
        break;
      case COMPUTING_METHODS.NONE:
        ColumnChartNoneCompute.doWork(
          data,
          baseKey,
          lang,
          descriptors,
          payload,
          routines,
          this
        );
        break;
      case COMPUTING_METHODS.NONE_MEDIAN:
        ColumnChartNoneMedianCompute.doWork(
          data,
          baseKey,
          lang,
          descriptors,
          payload,
          routines,
          this
        );
        break;
      case COMPUTING_METHODS.CONFORT_WITH_PVALUE:
        ColumnChartConfortWithPValue.doWork(
          data,
          baseKey,
          lang,
          descriptors,
          payload,
          routines,
          this
        );
        break;
      case COMPUTING_METHODS.CONFORT_PREFERENCES:
        ColumnChartConfortPreferences.doWork(
          data,
          baseKey,
          lang,
          descriptors,
          payload,
          routines,
          this
        );
        break;
      case COMPUTING_METHODS.CONFORT_PERCENT_WITH_CATEGORY:
        ColumnChartConfortPercentWithCategory.doWork(
          data,
          baseKey,
          lang,
          descriptors,
          payload,
          routines,
          this
        );
        break;
    }
    return {
      categories: Object.values( payload.categories ),
      series: Object.values( payload.series ),
      options: payload.options ? payload.options : null,
    };
  }

  private onOptionsChange = ( options: any ) => {
    const routinesMapping: Map<string, any> = new Map();
    let gotColorChange = false;

    if ( this.routines.routines ) {
      this.routines.routines.forEach( r => {
        routinesMapping.set( r.name, r );
      } );
    }

    let fontOptions: any;

    for ( const o in options ) {
      if ( o === 'general' ) {
        const {font, ...rest} = options[ o ];
        Object.assign( this.parameters, rest );
        fontOptions = font;
      } else {
        for ( const e in options[ o ] ) {
          if ( Array.isArray( options[ o ][ e ] ) ) {
            options[ o ][ e ].forEach( ( x ) => {
              Object.assign(
                this.parameters[ e ].find( ( y ) => y.name === x.name ),
                x
              );

              if ( e === 'series' && routinesMapping.has( x.name ) ) {
                gotColorChange = true;
                routinesMapping.get( x.name ).color = x.color;
              }

            } );
          } else {
            Object.assign( this.parameters[ e ], options[ o ][ e ] );
          }
        }
      }
    }
    const {yAxis, xAxis, ...parameters} = this.parameters;
    Object.assign( this.parameters, {...parameters} );
    Object.assign( this.parameters.yAxis, yAxis );
    Object.assign( this.parameters.xAxis, xAxis );

    if ( gotColorChange ) { this.custom.updateRoutines( this.routines.routines.map( x => x ) ); }

    const chart = this.build();
    if ( fontOptions ) { this.updateChartFont( chart, fontOptions ); }
    this.updateChartTranslations( chart, this.parameters, this.lang );
    /*
     * Hide/show grey zone feature for both xAxis & yAxis
     */
    chart.xAxis.forEach( ( axis: any ) => {
      axis.plotLinesAndBands.forEach( ( element: any ) => {
        if ( !this.parameters.plotOptions.grey_zone.enabled ) {
          element.svgElem.hide();
          // Add try/catch hook to handle plotBands/plotLines that don't have any label
          try {
            element.label.hide();
          } catch ( e ) {}
        } else {element.svgElem.show(); }
      } );
    } );
    chart.yAxis.forEach( ( axis: any ) => {
      axis.plotLinesAndBands.forEach( ( element: any ) => {
        if ( !this.parameters.plotOptions.grey_zone.enabled ) {
          element.svgElem.hide();
          // Add try/catch hook to handle plotBands/plotLines that don't have any label
          try {
            element.label.hide();
          } catch ( e ) {}
        } else {element.svgElem.show(); }
      } );
    } );
  }

  /**
   * updateChartTranslations
   * Method to manage languages changes
   * @param chart : Highcharts.Chart : Rendered chart
   * @param parameters : any : Chart's parameters
   * @param lang : string
   */
  private updateChartTranslations = (
    chart: Highcharts.Chart,
    parameters: any,
    lang: string
  ): void => {
    this.updateTitleTranslations( chart, lang, parameters );
    this.updateSubTitleTranslations( chart, lang, parameters );
    this.updateCategoriesTranslations( chart, parameters );
    this.updateSeriesTranslations( chart, lang );
    chart.yAxis[ 0 ].update( {}, false );
    chart.redraw();
  }

  /**
   * updateCategoriesTranslations
   * Method to update categories' translations
   * @param chart : Highcharts.Chart : Rendered chart
   * @param parameters : any : Chart's parameters
   */
  private updateCategoriesTranslations = (
    chart: Highcharts.Chart,
    parameters: any
  ): void => {
    chart.xAxis[ 0 ].update( {
      categories: parameters.xAxis.categories,
    } );
  }

  /**
   * updateSeriesTranslations
   * Method to update series' translations
   * @param chart : Highcharts.Chart : Rendered chart
   * @param lang : string
   */
  private updateSeriesTranslations = (
    chart: Highcharts.Chart,
    lang: string
  ): void => {
    try {
      chart.series.forEach(
        ( renderedSerie: Highcharts.Series, index: number ) => {
          const userOptions: any = renderedSerie[ 'userOptions' ];
          chart.series[ index ].update(
            {
              name: userOptions.translations[ lang ],
              type: userOptions.type,
            },
            false
          );
        }
      );
    } catch ( e ) {}
  }

  /**
   * handleDrill
   * Method to manage drill up / down on chart data;
   * It delegates logical part to other functions
   * @param isDrilled : boolean : Drilled status
   */
  private handleDrill = ( isDrilled: boolean ): void => {
    if ( isDrilled ) {this._handleDrillUp(); } else {this._handleDrillDown(); }
  }

  /**
   * _handleDrillDown
   */
  private _handleDrillDown = (): void => {
    while ( this.chart.series.length ) {
      this.chart.series[ 0 ].remove();
    }
    this.parameters.series = [];
    const payload = this.formatData(
      this.initialData,
      'drilled',
      this.parameters.compute ? this.parameters.compute.key : 'key',
      this.lang,
      this.parameters,
      this.descriptors,
      this.routines
    );
    this.parameters.xAxis.categories = payload.categories;
    this.chart.xAxis[ 0 ].update( {
      categories: this.parameters.xAxis.categories,
    } );
    if (
      this.parameters.hasOwnProperty( 'plotOptions' ) &&
      this.parameters.plotOptions.hasOwnProperty( 'column' ) &&
      this.parameters.plotOptions.column.hasOwnProperty( 'stacking' )
    ) {
      this.parameters.plotOptions.column.stacking = null;
    }
    payload.series.forEach( ( element ) => {
      this.chart.addSeries( element );
      ( this.parameters.series = this.parameters.series || [] ).push( element );
    } );
    this.drilledState = true;
    this.onDrillSubject.next( 'drill' );
    this.unLinkRoutine();
  }

  /**
   * _handleDrillUp
   */
  private _handleDrillUp = (): void => {
    while ( this.chart.series.length ) {
      this.chart.series[ 0 ].remove( false );
    }
    this.parameters.series = [];
    const payload = this.formatData(
      this.initialData,
      this.parameters.compute ? this.parameters.compute.method : null,
      this.parameters.compute ? this.parameters.compute.key : 'key',
      this.lang,
      this.parameters,
      this.descriptors,
      this.routines
    );
    this._parameters.xAxis.categories = payload.categories;
    this.chart.xAxis[ 0 ].update( {
      categories: this.parameters.xAxis.categories,
    } );
    if (
      this.parameters.hasOwnProperty( 'plotOptions' ) &&
      this.parameters.plotOptions.hasOwnProperty( 'column' ) &&
      this.parameters.plotOptions.column.hasOwnProperty( 'stacking' )
    ) {
      this.parameters.plotOptions.column.stacking = 'value';
    }
    payload.series.forEach( ( element ) => {
      this.chart.addSeries( element );
      ( this.parameters.series = this.parameters.series || [] ).push( element );
    } );
    this.drilledState = false;
    this.onDrillSubject.next( 'drill' );
    this.linkRoutineToSeries( this.chart );
  }

  /**
   * Create table for row data
   * @param {any} data
   * @param {string} lang
   */
  protected formatRawData = (
    data: any,
    lang: string
  ): {header: Array<any>; body: Array<any>; } => {
    data.data = this.createTableData( data.data, lang );
    const tableTemp = new Table( {}, data, lang );
    return tableTemp.parameters.transformedHTMLData;
  }

  /**
   * Legacy formatRawData function, keeping it in here for when there's side effect with the new one
   * @param {any} data
   * @param {string} lang
   */
  protected formatRawDataLegacy = (
    data: any,
    lang: string
  ): {header: Array<any>; body: Array<any>; } => {
    try {
      const payload = JSON.parse( JSON.stringify( data[ 'data' ] ) ).reduce(
        ( accumulator: any, object: any, itemNumber: number ) => {
          const payload = object.values.reduce(
            ( reducer: any, item: any, index: number ) => {
              item.values.reduce( ( redacc: any, value: any, idx: number ) => {
                redacc[ value.label ] = value;
                return redacc;
              }, reducer );
              return reducer;
            },
            {}
          );

          const keyList = Object.keys( payload );
          const oPayload = object.values.reduce(
            ( reducer: any, item: any, index: number ) => {
              if ( item.values.length !== keyList.length ) {
                for ( const obj of keyList ) {
                  const foundObject = item.values.find( ( x: any ) => {
                    return x.label === obj;
                  } );
                  if ( !foundObject ) {
                    item.values.push( {
                      label: payload[ obj ].label,
                      value: {label: '', key: ''},
                    } );
                  }
                }
              }

              item.values.sort( ( a, b ) => {
                const labelA = Chart.getObjectValueTranslation( a.label, lang );
                const labelB = Chart.getObjectValueTranslation( b.label, lang );
                return labelA.localeCompare( labelB );
              } );
              return reducer;
            },
            object
          );
          accumulator.push( oPayload );
          return accumulator;
        },
        []
      );
      data[ 'data' ] = Object.values(
        payload.reduce( ( accumulator: any, object: any, itemNumber: number ) => {
          if ( object.attribute.hasOwnProperty( 'blockName' ) ) {
            const key = object.attribute.blockName.english;
            accumulator[ key ] = accumulator[ key ] || {
              attribute: {label: object.attribute.blockName},
              values: [],
            };
            accumulator[ key ].values.push( object );
          }
          return accumulator;
        }, {} )
      );

      const tableTemp = new Table( {}, data, lang );
      return tableTemp.parameters.transformedHTMLData;
    } catch ( e ) {
      return {header: [], body: []};
    }
  }

  /**
   * Get a Highcharts label formatter the chart instance is injected in scope
   * @return {Function}
   */
  protected getLabelFormatter (): Function {
    const chart: ColumnChart = this;
    return function (): string | number {
      if ( chart._parameters.plotOptions.show_column_zero.enabled ) {return this.y; }
      return this.y > 0 ? this.y : null;
    };
  }

  /**
   * Format data for the row data. Recursive
   * A lot of find call in this function. May cause performance issue with big data.
   * Adopt a key value search if needed.
   * @param {any} data
   * @param {Array<ItemInterface|ItemEndInterface>} fullObject
   * @return {Array<ItemInterface|ItemEndInterface>}
   */
  protected createTableData = (
    data: any[],
    lang,
    fullObject: Array<ItemInterface | ItemEndInterface> = [],
    order: string[] = [],
    toBeOrdered: ItemInterface[] = [],
    rootLevel: boolean = true
  ): Array<ItemInterface | ItemEndInterface> => {
    data.forEach( ( item ) => {
      // SETTING BASE OBJECT TO WORK WITH
      let tempFullObject = fullObject;
      // CHECK IF END OF DATA. !ATTRIBUTE = NO MORE CHILDREN DATA
      if ( !item.attribute ) {
        // FINDING ALREADY CREATED END OBJECT / CASTING TO STRING IF NUMBER (TABLE BUILDING DOES NOT LIKE IT)
        const currentItemLabel = isNaN( item.label )
          ? item.label
          : item.label.toString();
        let currentObject: ItemEndInterface = <ItemEndInterface>(
          tempFullObject.find( ( i: ItemEndInterface ) => {
            return i.label === currentItemLabel;
          } )
        );
        // IF NOT FOUND CREATE THE SQUELETON WITH CURRENT DATA INFO AND LABEL TO NULL
        if ( !currentObject ) {
          // CHECK ORDER IF PREVOUSLY CREATED IN ANOTHER COLUMN, IF NOT CREATED PUSH IN ARRAY FOR ORDER
          if (
            !order.includes(
              Chart.getObjectValueTranslation( currentItemLabel, lang )
            )
          ) {
            order.push( Chart.getObjectValueTranslation( currentItemLabel, lang ) );
          }
          currentObject = {
            label: Chart.getObjectValueTranslation( currentItemLabel, lang ),
            value: {
              ...item.value,
              label: null,
            },
          };
          // SAVE IT IN THE BASE OBJECT
          tempFullObject.push( currentObject );
        }
        // CONCAT LABEL VALUE INTO FOUND OR CREATED SQUELETON.
        currentObject.value.label = currentObject.value.label
          ? Chart.getObjectValueTranslation( currentObject.value.label, lang ) +
          ', ' +
          Chart.getObjectValueTranslation( item.value.label, lang )
          : Chart.getObjectValueTranslation( item.value.label, lang );
        // END HERE.
        return tempFullObject;
      }
      // NOT END OF DATA.
      // BLOCKNAME IS PRESENT ONLY IN DATA ROOT
      const currentBlockName = item.attribute.blockName;
      // ATTRIBUTE.LABEL = TABLE HEADER
      const currentLabel = item.attribute.label;
      // INITIATE WORKING CURRENT OBJECT
      let currentObject: ItemInterface;
      // CHECK IF HEAD OF DATA
      if ( currentBlockName ) {
        // FIND ROOT HEADER
        currentObject = <ItemInterface> tempFullObject.find(
          ( i: ItemInterface ) => {
            return i.attribute.label === currentBlockName;
          }
        );
        if ( !currentObject ) {
          currentObject = {
            attribute: {
              label: currentBlockName,
            },
            values: [],
          };
          tempFullObject.push( currentObject );
        }
        // CHANGE BASE OBJECT BY ONE LEVEL IN
        tempFullObject = currentObject.values;
      }
      // FIND WORKING CURRENT OBJECT WITH CURRENT HEADER
      currentObject = <ItemInterface> tempFullObject.find( ( i: ItemInterface ) => {
        return i.attribute.label === currentLabel;
      } );

      if ( !currentObject ) {
        currentObject = {
          attribute: {
            label: currentLabel,
          },
          values: [],
        };
        tempFullObject.push( currentObject );
      }
      // RECURSIVE INTO NEXT LEVEL, AND GET
      const innerData = this.createTableData(
        item.values,
        lang,
        currentObject.values,
        order,
        toBeOrdered,
        false
      );
      // CHECK IF CURRENT OBJECT HAS END OBJECT AS CHILDREN
      if ( innerData[ 0 ] && innerData[ 0 ].hasOwnProperty( 'value' ) ) {
        // PUSH IN ARRAY TO BE ORDERED IN END PROCESS
        toBeOrdered.push( currentObject );
      }
    } );
    // CHECK IF END PROCESS
    if ( rootLevel ) {
      // START REORDERING
      toBeOrdered.forEach( ( item ) => {
        const ordered = [];
        order.forEach( ( orderItem ) => {
          let foundItem = item.values.find( ( endItem: ItemEndInterface ) => {
            return (
              Chart.getObjectValueTranslation( endItem.label, lang ) === orderItem
            );
          } );
          // CREATED NOT FOUND ITEM. PREVENT COLUMN OFFSET WHEN MISSING VALUES
          if ( !foundItem ) {
            foundItem = {
              label: orderItem,
              value: {
                label: '',
              },
            };
          }
          ordered.push( foundItem );
        } );
        item.values = ordered;
      } );
    }
    // RETURN OBJECT
    return fullObject;
  }

  private linkRoutineToSeries = ( chart: Highcharts.Chart ): void => {
    if (
      !this.routines ||
      !this.routines.routines ||
      !Array.isArray( this.routines.routines ) ||
      this.routines.routines.length === 0
    ) {
      return;
    }
    const routineSerieMapping: Map<any, Highcharts.Series[]> =
      this.mapRoutineWithSeries( this.routines.routines, chart.series );

    const newRoutines = [];

    routineSerieMapping.forEach( ( series, routine ) => {
      routine.onClick = () => {
        chart.series.forEach( ( serie ) => {
          if ( !series.includes( serie ) ) {serie.setState( 'inactive', true ); } else {serie.setState( 'normal' ); }
        } );
      };

      newRoutines.push( routine );
    } );
    this.syncRoutinesSeriesColor( routineSerieMapping );
    this.custom.updateRoutines( newRoutines );
    chart.redraw();
  }

  private syncRoutinesSeriesColor = (
    routinesSeriesMapping: Map<any, Highcharts.Series[]>
  ) => {
    routinesSeriesMapping.forEach( ( series, routine ) => {
      if ( series.length > 1 ) {return; }
      series.forEach( ( serie ) => {
        serie.update(
          {
            name: serie.name,
            type: serie.type as any,
            color: routine.color,
          },
          false
        );
      } );
    } );
  }

  private mapRoutineWithSeries (
    routines: any[],
    series: Highcharts.Series[]
  ): Map<any, Highcharts.Series[]> {
    const mapping = new Map();
    routines.forEach( ( routine ) => {
      const foundSeries = [];
      const routineSerie = series.find( ( serie ) => serie.name === routine.name );
      if ( routineSerie ) {foundSeries.push( routineSerie ); }

      if ( !foundSeries.length ) {
        const formulas: string[] = routine.formula.map( ( f ) => f.name );
        const formulaSeries = series.filter( ( serie ) =>
          formulas.includes( serie.name )
        );

        foundSeries.push( ...formulaSeries );
      }

      if ( foundSeries.length ) {mapping.set( routine, foundSeries ); }
    } );

    return mapping;
  }

  private unLinkRoutine () {
    if (
      !this.routines ||
      !this.routines.routines ||
      !Array.isArray( this.routines.routines ) ||
      this.routines.routines.length === 0
    ) {
      return;
    }
    this.routines.routines.forEach( ( routine ) => {
      routine.selected = false;
      routine.onClick = undefined;
    } );

    const newRoutines: any[] = this.routines.routines.map( ( r ) => r );

    this.custom.updateRoutines( newRoutines );
  }

  private updateChartFont = ( chart: Highcharts.Chart, option: {size: number, bold: boolean; } ): void => {
    chart.yAxis[ 0 ].update( {
      labels: {
        ...chart.yAxis[0].userOptions.labels,
        style: {
          fontSize: option.size + 'px',
          fontWeight: option.bold ? 'bold' : 'normal',
        }
      }
    }, false );

    chart.xAxis[ 0 ].update( {
      labels: {
        ...chart.xAxis[ 0 ].userOptions.labels,
        style: {
          fontSize: option.size + 'px',
          fontWeight: option.bold ? 'bold' : 'normal'
        }
      }
    }, false );
  }
}
