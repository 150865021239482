<div ngbAccordion #acc="ngbAccordion" *ngFor="let block of workflowConfiguration.blocks; let indexBlock = index">
  <div ngbAccordionItem>
    <div ngbAccordionHeader class="header-style">
      <div class="d-flex flex-row align-items-center justify-content-evenly">
        <div class="col-10">
          <button style="width:100%" class="row align-items-center btn btn-link p-0" ngbAccordionButton="">
            <span class="col-6" style="float:left">
              <dna-translate [fieldToTranslate]="block.name"></dna-translate>
              {{ block.suffix }}
            </span>
            <span class="col-6">
              {{ "NUMBER_OBJECTS_SELECTED" | translate: {objects: "COMPONENTS" | translate | lowercase} }}:
              {{ componentsSelectedByBlock[indexBlock] }}/{{ block.components.length }}
            </span>
          </button>
        </div>
        <div (click)="updateInactiveBlockToQuestion(block, indexBlock, $event); false" style="white-space: nowrap">
          <span class="fa fa-check-square-o col-sm-2"
                [ngClass]="{ 'fa-square-o': block.parameters?.inactive, 'fa-check-square-o': !block.parameters?.inactive }"></span>
          <span translate>ACTIVATE_BLOCK</span>
        </div>
      </div>
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <div class="row">
            <div class="col-md-4 mb-2" *ngFor="let component of block.components">
              <div class="d-flex flex-row align-items-center" (click)="updateInactiveQuestionToBlock(component, block, indexBlock)">
                <span class="col-sm-2 fa fa-check-square-o"
                  [ngClass]="{ 'fa-square-o': component.args.inactive, 'fa-check-square-o': !component.args.inactive }"></span>
                <label class="col-sm-10" translate>
                  <dna-translate [fieldToTranslate]="component.args.label"></dna-translate>
                </label>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
