declare var require: any;
import { Component, AfterViewInit, ViewChild, ElementRef, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Chart } from '../../models';
import * as _ from 'lodash';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let ColorAxis = require('highcharts/modules/coloraxis');

const Highcharts = require('highcharts');
require('highcharts/modules/boost')(Highcharts);
noData(Highcharts);
More(Highcharts);
ColorAxis(Highcharts);
Boost(Highcharts)
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dna-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements AfterViewInit {
  @ViewChild('chart', { read: ElementRef }) chartElm: ElementRef;
  @Input() chart: Chart;
  constructor(
    private ref: ChangeDetectorRef,
  ) { };

  ngAfterViewInit() {
    const initialData = _.get(this.chart, 'initialData', '');
    const lengthOfInitialData = initialData.length;

    const heightPerLine = 21;
    const totalHeightChart = heightPerLine * lengthOfInitialData;

    const chartStyle = document.querySelector('.dna-chart');
    chartStyle['style'].height = lengthOfInitialData <= 29 ? '600px' : `${totalHeightChart}px`;

    this.chart.build(this.chartElm.nativeElement);
    this.ref.detectChanges();
  };

};
