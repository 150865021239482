import { Languages } from "../../enums";

const getObjectValueTranslation = (object: any, lang: string): string => {
    switch (typeof object) {
        case 'object':
            if (object.hasOwnProperty('custom')) return object;
            else if (object.hasOwnProperty('user') && object.user.length) return object.user;
            else if (object.hasOwnProperty(lang) && object[lang].length) return object[lang];
            else return object[Languages.Default];
        default:
            return object;
    };
};


const formatter = (element: any, displayLanguages: Array<string>, showBlock: boolean): string => {

    // Anchor class to customize label size and position
    const header = `<div class="anchor-label" style="margin-top: 14px; width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column">`;
    const footer = `</div>`;
    let body: string = ``;

    if (displayLanguages.length && displayLanguages.length > 1) {
        switch (typeof element) {
            case 'object':
                if (showBlock  && element.hasOwnProperty('blockName')) {
                    const payload = [];
                    displayLanguages.reduce((accumulator: Array<any>, lang: string) => {
                        accumulator.push(`<b>${getObjectValueTranslation(element.blockName, lang)}</b>`);
                        return accumulator
                    }, payload);
                    body = `
                   ${ body}
                      <div style="text-align: center;font-size: 9px;"> ${ payload.join(' / ') } </div>`;
                }

                const payload = [];
                    displayLanguages.reduce((accumulator: Array<any>, lang: string) => {
                        accumulator.push(`${getObjectValueTranslation(element.label, lang)}`);
                        return accumulator;
                    }, payload);
                body = `
                ${ body}<div style="text-align: center;font-size: 9px;"> ${ payload.join(' / ') } </div>`;
                break;
            default:
                body = `<div style="text-align: center;"> ${element} </div>`;
        };
    } else {
        switch (typeof element) {
            case 'object':
                if (showBlock  && element.hasOwnProperty('blockName')) {
                    body = `
                   ${ body}
                      <div style="text-align: center;"> <b>${ getObjectValueTranslation(element.blockName, displayLanguages[0])}</b> </div>`;
                }
                body = `
                ${ body}<div style="text-align: center;"> ${getObjectValueTranslation(element.label, displayLanguages[0])}</div>`;
                break;
            default:
                body = `<div style="text-align: center;"> ${element} </div>`;
                break;
        };
    }


    return [
        header,
        body,
        footer
    ].join("");
};

export {
    formatter
};