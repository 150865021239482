import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as _ from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DNATranslateService } from '../../../../shared/services/translate.service';
import { ErrorManagerService } from '../../../../shared/services/errorManager.service';
import { Translatable } from '../../../../types';

@Component({
    selector: 'dna-component-modal-edit-name',
    templateUrl: './component-modal-edit-name.component.html'
})
export class ComponentModalEditNameComponent implements OnInit {

    @Input() inactive: boolean;
    @Input() label: Translatable;

    editNameForm: FormGroup;
    labelCopy: Translatable;
    languages: string[] = [];

    constructor(
        private activeModal: NgbActiveModal,
        private builder: FormBuilder,
        private DNATranslate: DNATranslateService,
        private errorManager: ErrorManagerService
    ) { }

    ngOnInit() {
        this.labelCopy = _.cloneDeep(this.label);
        this.DNATranslate.getLanguages().then(
            languages => this.languages = languages
        );

        this.editNameForm = this.builder.group({
            label: this.builder.group({
                english: ['', Validators.required],
                french: '',
                japanese: '',
                chinese: '',
                portuguese: '',
                spanish: ''
            })
        });

        this.editNameForm.valueChanges.subscribe((values: any) => {
            this.labelCopy = values.label;
        });
        this.setFormValues();

        if (this.inactive) {
            this.editNameForm.disable();
        } else {
            this.editNameForm.enable();
        }
    }

    cancel() {
        this.activeModal.dismiss();
    }

    save(form: FormGroup) {
        if (!form.invalid) {
            this.label = this.labelCopy;
            this.activeModal.close(this.label);
        } else {
            this.errorManager.displayMessage('ON_ERROR_FORM', "danger");
        }
    }

    setFormValues() {
        try {
            this.editNameForm.patchValue({
                label: this.label
            });
        }
        catch (error) {
            console.log(error);
        }
    }

}
