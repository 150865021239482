import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationInsightsService } from '../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-visits-updated-modal',
  templateUrl: './visits-updated-modal.component.html',
  styleUrls: ['./visits-updated-modal.component.less']
})
export class VisitsUpdatedModalComponent implements OnInit, AfterViewInit {

  @Input() blocksDeleted: {
    visit: string;
    idWorkflow: string;
    workflowName: string;
    blockName: string;
    blockId: string;
  }[]

  initTime = performance.now();

  constructor(
    private aiService: ApplicationInsightsService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.aiService.logPageView('Visits Updated Modal', '', performance.now() - this.initTime, 'Modals');
  }

  cancel() {
    this.activeModal.dismiss();
  }

}
