import {
  empty as observableEmpty,
  Observable,
  of as observableOf,
  Subject,
  throwError as observableThrowError
} from 'rxjs';

import { catchError, filter, finalize, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';

import * as _ from 'lodash';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import {
  ActionBarButton,
  ActionTypes,
  CampaignsMulti,
  CampaignStates,
  DNATypes,
  FilterSchedule,
  GroupedFormula,
  Hub,
  Campaign,
  LightCampaign,
  ModalContent,
  Pager,
  Result,
  SynergyDemand,
  TableHeader,
  Type,
  TypeMetier,
  User,
  Workflow
} from '@app/types';
import { ApplicationInsightsService } from '@app/shared/services/applicationInsights.service';
import { CampaignService } from '../campaigns.service';
import { CreateCampaignModalComponent } from './create-campaign/create-campaign-modal.component';
import { DNATranslateService } from '@app/shared/services/translate.service';
import { ErrorManagerService } from '@app/shared/services/errorManager.service';
import { FilterService } from '@app/shared/services/filter.service';
import { ReferenceTypeService } from '@app/shared/services/reference-type.service';
import { StateService } from '@app/shared/services/state.service';
import { SynergyDemandService } from '@app/shared/services/synergy-demand.service';
import { UserService } from '@app/shared/services/user.service';
import { UtilService } from '@app/shared/services/util.service';
import { Table2Component } from '@app/shared/table2/table2.component';
import {
  CreateCampaignMultiModalComponent
} from '@app/multi-campaigns/list/create-campaign-multi/create-campaign-multi-modal.component';
import { MultiCampaignsService } from '@app/multi-campaigns/multi-campaigns.service';
import * as FileSaver from 'file-saver';
import { ViewChild } from '@angular/core';
import { SearchTextComponent } from '@app/shared/dnaSearch/dnaSearchText.component';

@Component({
  selector: 'dna-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.less']
})
export class CampaignsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(Table2Component) table2Component: Table2Component;
  @ViewChildren(SearchTextComponent) searchTextComponents: QueryList<SearchTextComponent>;


  campaignsList: Campaign[];
  campaigns: LightCampaign[];
  campaignsFiltered: LightCampaign[] = [];
  campaignsSelected: LightCampaign[] = [];
  filter: FilterSchedule;
  hubs: Hub[] = [];
  isChangedFilterProtocol = false;
  isChangedFilterTarget = false;
  language: string;
  listFormulas: GroupedFormula[] = [];
  listUsers: User[];
  listWorkflows: Workflow[] = [];
  initListFormulas: GroupedFormula[] = [];
  initListWorkflows: Workflow[] = [];
  metierName: TypeMetier;
  showSpinner = true;
  states: string[];
  typeCampaigns: Type[];
  typeEvaluations: Type[];
  user: User;
  wkLabel: string;
  initTime = performance.now();
  isCollapsedCampaignsFilter = false;
  isCollapsedCampaignsFilterProtocol = true;
  isCollapsedCampaignsFilterTarget = true;
  totalItems: Number = 0;
  campaignsCompatible = true;

  metadataUsers$: Observable<User[]>;
  tableHeaders$: Observable<TableHeader[]>;

  destroy$: Subject<boolean> = new Subject<boolean>();
  onTextChanged$: Subject<string> = new Subject<string>();

  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };

  constructor(
    private aiService: ApplicationInsightsService,
    private campaignService: CampaignService,
    private errorManagerService: ErrorManagerService,
    private filterService: FilterService,
    private modalService: NgbModal,
    private referenceType: ReferenceTypeService,
    private route: ActivatedRoute,
    private router: Router,
    private stateService: StateService,
    private synergyDemandService: SynergyDemandService,
    private translateService: DNATranslateService,
    private userService: UserService,
    private utilService: UtilService,
    private campaignsMultiService: MultiCampaignsService
  ) {}

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaigns', '', performance.now() - this.initTime, templateUrl);
  }

  ngOnInit() {
    this.hubs = this.referenceType.getHubs();
    this.language = this.translateService.getLanguage();
    this.states = this.stateService.getVisibleStates(this.stateService.CAMPAIGNS);
    this.typeCampaigns = this.referenceType.getTypeCampaign();
    this.typeEvaluations = this.referenceType.getTypeEvaluation();
    this.tableHeaders$ = this.utilService.createHeaders(DNATypes.Campaign);
    this.getWorkflowTranslation();
    this.init(this.language);

    this.translateService.onLangChange().pipe(
      tap(event => this.language = event.lang),
      tap(() => this.tableHeaders$ = this.utilService.createHeaders(DNATypes.Campaign)),
      tap(() => this.getWorkflowTranslation()),
      takeUntil(this.destroy$))
      .subscribe();

    this.userService.onUserChanged().pipe(
      tap((user: User) => this.init(user.language)),
      takeUntil(this.destroy$))
      .subscribe();

    this.filterService.onfilterCampaignChanged().pipe(
      tap(() => this.resetFilters())
    ).subscribe();
    this.filter = this.filterService.filter.schedules;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addPropertyToCampaigns(campaigns: LightCampaign[] = [], property: string, value: any): LightCampaign[] {
    return campaigns.map((c: LightCampaign) => this.utilService.addProperty(c, property, value));
  }

  analyse(campaignsSelected: LightCampaign[] = []) {
    this.openModalNewCampaignMulti(campaignsSelected);
    this.campaignService.setCampaignsSelected([]);
  }

  catchError = (error) => {
    this.hideSpinner();
    this.errorManagerService.catchError(error);
    return observableEmpty();
  }

  clearFilters(filterType: string, event: any) {
    event.stopPropagation();
    switch (filterType) {
      case 'protocol':
        this.filter.protocol = this.filterService.initializeFilterProtocol();
        this.isChangedFilterProtocol = false;
        break;
      case 'target':
        this.filter.target = this.filterService.initializeFilterTarget();
        this.isChangedFilterTarget = false;
        break;
    }
    this.onFilterChanged();
  }

  deleteCampaign(campaign: LightCampaign): Observable<any> {
    this.showSpinner = true;

    const campaign$ = observableOf(campaign);
    const isLinked$ = campaign$.pipe(filter((c: LightCampaign) => c.synergy ? true : false),
      mergeMap((c: LightCampaign) => this.synergyDemandService.getSynergyDemandById(c.synergy.requestNumber)),
      mergeMap((synergyDemand: SynergyDemand) => this.campaignService.unlinkSynergyDemandWithCampaign(synergyDemand)),
      mergeMap(() => campaign$));

    return this.campaignService.deleteCampaign(campaign.id).pipe(
      finalize(() => this.showSpinner = false),
      tap(() => isLinked$.subscribe()),
      catchError(error => {
        this.catchError(error);
        throw new Error(error);
      }));
  }

  getCampaignMetadataAndSetUserName = (): Observable<User[]> => {
    return this.campaignService.getCampaignMetadata().pipe(
      take(1),
      map(meta => this.campaignService.displayUsersName(meta)));
  }

  getWorkflowTranslation() {
    this.translateService.translateMessage('WORKFLOW').pipe(
      mergeMap((wk: string) => this.translateService.translateMessage('ENTER_OBJECT_NAME_MASC', { object: wk.toLowerCase() })),
      tap((txt: string) => this.wkLabel = txt),
      take(1))
      .subscribe();
  }

  getWorkflowWithTranslatedName(workflows: Workflow[], language: string = 'english'): Workflow[] {
    return workflows.map(
      (w: Workflow) => {
        w.name = w.name[language] ? w.name[language] : w.name.english;
        return w;
      }
    );
  }

  hasFilterOn(filter: any) {
    const array = Object.getOwnPropertyNames(filter);
    for (const value of array) {
      const arrayEmpty = _.isObject(filter[value]) && _.isEmpty(filter[value]);
      const numberOrStringEmpty = !_.isObject(filter[value]) && (filter[value] === undefined || filter[value] === '');
      if (arrayEmpty || numberOrStringEmpty) {
        return true;
      }
    }
    return false;
  }

  hideSpinner = () => this.showSpinner = false;

  initCampaigns(campaigns: Observable<Result>, language: string = 'english'): Observable<any> {
    this.campaignsSelected = [];
    this.showSpinner = true;
    return campaigns.pipe(
      tap((result: Result) => this.totalItems = result.totalItems),
      map((result: Result) => this.addPropertyToCampaigns(result.list, 'isSelected', false)),
      tap((campaigns: LightCampaign[]) => this.campaigns = _.cloneDeep(campaigns)),
      tap((campaigns: LightCampaign[]) => this.campaignsFiltered = this.initCampaignsFiltered(campaigns)),
      tap((campaignsFiltered: LightCampaign[]) => this.campaignsFiltered = this.utilService.filterListCampaign(campaignsFiltered, this.filter)),
      tap((campaignsFiltered: LightCampaign[]) => [this.listFormulas, this.listWorkflows] = this.initObjectsLists(campaignsFiltered, language)),
      tap(() => this.campaignsSelected = this.initCampaignsSelected()),
      finalize(() => this.showSpinner = false));
  }

  initCampaignsSelected(): LightCampaign[] {
    const campaignsSelected = this.campaignService.getCampaignsSelected();
    if (campaignsSelected) {
      campaignsSelected.forEach(cs => {
        const campaign = this.campaignsFiltered.find(cf => cf.id === cs.id);
        if (campaign) {
          campaign.isSelected = true;
        }
      });
      return campaignsSelected;
    } else {
      return [];
    }
  }

  init(language: string = 'english') {
    this.user = this.userService.getUser();
    this.metierName = _.get(this.user, 'currentWorkspace.metier.name', undefined);
    this.filter = this.filterService.getFilter(this.userService.getUserHub(this.user.hub)).schedules;
    this.getCampaignMetadataAndSetUserName().pipe(
      tap((users: User[]) => this.listUsers = users),
      mergeMap(() => this.route.paramMap),
      tap(this.initParameterURL),
      mergeMap(() => this.initCampaigns(this.campaignService.getCampaignsLight(this.filter), language)),
      tap(() => [this.initListFormulas, this.initListWorkflows] = [this.listFormulas, this.listWorkflows]),
      takeUntil(this.destroy$))
      .subscribe();
  }

  initCampaignsFiltered(campaigns: LightCampaign[] = []): LightCampaign[] {
    if (this.campaignsSelected.length > 0) {
      let index;
      this.campaignsSelected.forEach(
        (campaignSelected: LightCampaign) => {
          index = campaigns.findIndex((c: LightCampaign) => c.id === campaignSelected.id);
          if (index > -1) {
            campaigns[index].isSelected = campaignSelected.isSelected;
          }
        }
      );
    }
    return campaigns;
  }

  initObjectsLists(campaigns: LightCampaign[] = [], language: string = 'english'): [GroupedFormula[], Workflow[]] {
    let formulas = [], workflows = [];
    let updatedFormulas = [], updatedWorkflows = [];
    campaigns.forEach((c: LightCampaign) => {
      formulas = formulas.concat(c.formulas);
      workflows = workflows.concat(this.getWorkflowWithTranslatedName(_.cloneDeep(c.workflows), language));
    });
    updatedFormulas = _.uniqBy(formulas, 'name').filter(f => !_.isNil(f));
    updatedWorkflows = _.uniqBy(workflows, 'name').filter(f => !_.isNil(f));

    this.campaignService.setFormulas(updatedFormulas);
    this.campaignService.setWorkflows(updatedWorkflows);
    return [updatedFormulas, updatedWorkflows];
  }

  initParameterURL = (params: ParamMap) => {
    if (params.has('states') || params.get('byUser') === 'true' || params.has('hubs') || params.has('users') || params.get('typeCampaigns')) {
      this.filter = this.filterService.resetFilterCampaign();
      if (params.has('states')) {
        const states: string[] = [params.get('states')][0].split(',');
        this.filter.states = states;
      }

      if (params.get('byUser') === 'true') {
        this.filter.byUser = true;
        this.filter.track = this.user.track;
      }

      if (params.has('hubs')) {
        const hubs: string[] = [params.get('hubs')][0].split(',');
        this.filter.hubs = <Hub[]>hubs;
      }

      if (params.has('users')) {
        const paramUsers: string[] = [params.get('users')][0].split(',');
        this.filter.users = paramUsers.map(u => this.listUsers.find(user => user.key === u));
      }
      if (params.has('typeCampaigns')) {
        this.filter.studyType = JSON.parse(params.get('typeCampaigns'));
      }
    } else {
      this.resetFilters();
    }
  }

  onClickActionButton(actionButton: ActionBarButton, idCampaign: string) {
    const campaign = this.campaigns.find(campaign => campaign.id === idCampaign);
    switch (actionButton.id) {
      case ActionTypes.Duplicate:
        this.openModalNewCampaign(campaign);
        break;
      case ActionTypes.Delete:
        this.utilService.translateMessageModal('CONFIRM_COMPLETE_DELETE', campaign.name, 'THE_CAMPAIGN').pipe(
          mergeMap((modalContent: ModalContent) => this.utilService.openModalConfirm(modalContent)),
          mergeMap(() => this.deleteCampaign(campaign)),
          take(1))
          .subscribe(
            () => this.onSuccessDelete(campaign),
            error => { }
          );
        break;
      case ActionTypes.Remove:
        this.utilService.translateMessageModal('CONFIRM_DELETE', campaign.name, 'THE_CAMPAIGN').pipe(
          mergeMap((modalContent: ModalContent) => this.utilService.openModalConfirm(modalContent)),
          mergeMap(() => this.removeCampaignState(campaign, CampaignStates.Removed)),
          take(1))
          .subscribe(
            (newCampaign: LightCampaign) => this.onSuccessChangeState(newCampaign),
            error => { }
          );
        break;
      case ActionTypes.PutBack:
        this.utilService.translateMessageModal('CONFIRM_PUT_BACK', campaign.name, 'THE_CAMPAIGN').pipe(
          mergeMap((modalContent: ModalContent) => this.utilService.openModalConfirm(modalContent)),
          mergeMap(() => this.updateCampaignState(campaign, campaign.oldState)),
          take(1))
          .subscribe(
            (newCampaign: LightCampaign) => this.onSuccessChangeState(newCampaign),
            error => { }
          );
        break;
      case ActionTypes.ExportConfort:
        this.showSpinner = true;
        this.campaignService.exportConfortPanel(idCampaign).pipe(
          finalize(() => this.showSpinner = false)
        ).subscribe(
          blob => FileSaver.saveAs(blob, _.get(campaign, 'synergy.requestNumber', campaign.name) + '-don.xlsx'),
          err => {
            console.log(err);
          }
        );
        break;
    }
  }

  onFilterChanged(language: string = 'english') {
    this.initCampaigns(this.campaignService.getHttpCampaignsWithFilter(this.filter, true, true), language).pipe(
      take(1))
      .subscribe();
  }

  onParametersChanged(event: { pager: Pager, type: string }) {
    _.set(this.filter, 'page', _.get(event, 'pager.currentPage', 1));
    _.set(this.filter, 'limit', _.get(event, 'pager.pageSize', 10));
    this.onFilterChanged(this.language);
  }

  onSendDatalakeData(event: any) {
    this.initCampaigns(this.campaignService.getHttpCampaignsWithFilter(this.filter, true, true), this.language).pipe(
      take(1))
      .subscribe(
        () => { this.showSpinner = false; },
        error => this.catchError(error)
      );
  }

  onSuccessChangeState(campaign: LightCampaign) {
    this.campaigns[this.campaigns.findIndex(c => c.id === campaign.id)] = campaign;
    this.updateCampaignFiltered();
    this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE');
  }

  onSuccessDelete(campaign: LightCampaign) {
    _.remove(this.campaigns, campaign);
    this.updateCampaignFiltered();
    this.errorManagerService.displayMessage('ON_SUCCESS_DELETE');
  }

  onSwitchChanged(switchState: boolean, campaign: LightCampaign) {
    this.campaignsFiltered = this.updatePropertyIsSelected(_.cloneDeep(this.campaignsFiltered), campaign.id, switchState);
    this.campaignsSelected = this.updateCampaignsSelected(_.cloneDeep(this.campaignsSelected), _.cloneDeep(campaign), switchState);
    this.campaignService.setCampaignsSelected(this.campaignsSelected);
    this.campaignsCompatible = !this.checkDifferentValues(this.campaignsSelected.map((c: any) => c.parameters.isRoutine));
  }

  checkDifferentValues(arr: boolean[]): boolean {
    const firstValue = arr[0];
    for (let i = 1; i < arr.length; i++) {
        if (arr[i] !== firstValue) {
            return true;
        }
    }
    return false;
}

  openModalNewCampaign(campaign?) {
    if (campaign) {
      const modalDuplicateCampaign = this.modalService.open(CreateCampaignModalComponent, this.modalOption);
      modalDuplicateCampaign.componentInstance.idCampaignToDuplicate = campaign.id;
      modalDuplicateCampaign.componentInstance.hasArcs = campaign.hasArcs;
      modalDuplicateCampaign.componentInstance.hasSynergy = _.get(campaign, 'synergy.requestNumber', false);
      modalDuplicateCampaign.result.then((id) => {
        this.router.navigate(['/campaigns', id, 'edit']);
      }).catch((err) => {
        this.router.navigate(['campaigns']);
      });

    } else {
      const modalNewCampaign = this.modalService.open(CreateCampaignModalComponent, this.modalOption);
      modalNewCampaign.result.then((id) => {
        this.router.navigate(['/campaigns', id, 'edit']);
      }).catch((err) => {
        this.router.navigate(['campaigns']);
      });
    }
  }

  openModalNewCampaignMulti(campaignSelected: LightCampaign[]) {
    const modalnewCampaignMulti = this.modalService.open(CreateCampaignMultiModalComponent, this.modalOption);
    modalnewCampaignMulti.componentInstance.campaignSelected = campaignSelected;
    modalnewCampaignMulti.result.then(result => {
      this.showSpinner = true;
      const campaigns = campaignSelected.map(c => ({ id: c.id, name: c.name }));
      this.createCampaignMulti(result.campaignMultiName, result.analyseType, result.workspace.id, campaigns);
    }).catch((err) => {
      this.router.navigate(['campaigns']);
    });
  }

  private createCampaignMulti(campaignMultiName, analyseType, workspace, campaigns) {
    const campaignMulti = new CampaignsMulti(campaignMultiName, analyseType, workspace, campaigns, this.user.track);
    return this.campaignsMultiService.createCampaignsMulti(campaignMulti).pipe(
      tap(campaignMulti => this.campaignsMultiService.setCampaignMultiFromLocalStorage(campaignMulti)),
      tap(campaignMulti => this.router.navigate(['multi-campaigns', campaignMulti.id])),
      catchError(error => {
        this.catchError(error);
        return observableThrowError(error);
      }),
      finalize(() => this.showSpinner = false)
    ).subscribe(
      () => this.errorManagerService.displayMessage('ON_SUCCESS_CREATE')
    );
  }

  resetFilters(event?: any) {
    if (event) { event.stopPropagation(); }
    this.filterService.resetFilterService();
    this.filter = this.filterService.getFilter(this.userService.getUserHub(this.user.hub)).schedules;
    this.clearFilterInputs();
    if (this.initListFormulas && this.initListWorkflows) {
      [this.listFormulas, this.listWorkflows] = [this.initListFormulas, this.initListWorkflows];
    }
  }

  searchCampaign() {
    this.onFilterChanged(this.language);
    // on laisse un délai de 200ms pour permettre de reset le composant table2 qui gère le tableau, pour l'affichage des favoris
    setTimeout(() => {
      this.table2Component.firstPage(1);
    }
      , 200);
  }

  searchTemplate() {
    this.filter.displayTemplate = !this.filter.displayTemplate;
    this.filter.startDate = this.filter.displayTemplate ? undefined : this.filterService.getDateSearchCampaign();
  }

  sortContent = (headerId: string, reverse: boolean ) => {
    switch (headerId) {
      case 'NAME':
        _.set(this.filter, 'orderBy', `name`);
        break;
      case 'STATE':
        _.set(this.filter, 'orderBy', `state`);
        break;
      case 'ORCHESTRA':
            _.set(this.filter, 'orderBy', 'synergy.orchestra');
        break;
      case 'ACTIVIEW':
          _.set(this.filter, 'orderBy', 'activityNumber');
        break;
      case 'PROGRESSION':
        _.set(this.filter, 'orderBy', 'progress');
        break;
        case 'BRIDGE':
        _.set(this.filter, 'orderBy', 'synergy.requestNumber');
        break;
      case 'CAMPAIGN_TYPE':
        _.set(this.filter, 'orderBy', 'studyType.id');
        break;
      case 'DATE_CREATION':
        _.set(this.filter, 'orderBy', 'created_on');
        break;
      case 'PRIORITY':
        _.set(this.filter, 'orderBy', 'synergy.priority');
        break;
      case 'DATE_MODIFICATION':
        _.set(this.filter, 'orderBy', 'updated_on');
        break;
      default:
        _.set(this.filter, 'orderBy', 'updated_on');
        break;
    }
    reverse ? _.set(this.filter, 'order', 'ASC') : _.set(this.filter, 'order', 'DESC');
    this.onFilterChanged();
  }

  updateCampaignsSelected(campaigns: LightCampaign[], campaign: LightCampaign, switchState: boolean) {
    const index = campaigns.findIndex((c: LightCampaign) => c.id === campaign.id);

    if (switchState) {
      if (index === -1) {
        campaign.isSelected = switchState;
        campaigns.push(campaign);
      } else {
        campaigns[index].isSelected = switchState;
      }
    } else {
      campaigns.splice(index, 1);
    }

    return campaigns;
  }

  updateCampaignFiltered() {
    this.campaignService.setCampaigns(this.campaigns);
    this.campaignsFiltered = this.initCampaignsFiltered(this.campaigns);
    this.campaignsFiltered = this.utilService.filterListCampaign(this.campaignsFiltered, this.filter);
    [this.listFormulas, this.listWorkflows] = this.initObjectsLists(this.campaignsFiltered, this.language);
  }

  updateCampaignState(campaign: LightCampaign, state: CampaignStates): Observable<LightCampaign> {
    this.showSpinner = true;
    return this.campaignService.putCampaignState(campaign.id, {
      state: state,
      oldState: campaign.state
    }).pipe(
      map(() => {
        campaign.state = state;
        return campaign;
      }),
      finalize(() => {
        this.showSpinner = false;
      }),
      catchError(error => {
        this.catchError(error);
        return observableThrowError(error);
      }));
  }

  removeCampaignState(campaign: LightCampaign, state: CampaignStates): Observable<LightCampaign> {
    this.showSpinner = true;

    const campaign$ = observableOf(campaign);
    const isLinked$ = campaign$.pipe(filter((c: LightCampaign) => c.synergy ? true : false),
      mergeMap((c: LightCampaign) => this.synergyDemandService.getSynergyDemandById(c.synergy.requestNumber)),
      mergeMap((synergyDemand: SynergyDemand) => this.campaignService.unlinkSynergyDemandWithCampaign(synergyDemand)),
      mergeMap(() => campaign$), );

    return this.campaignService.putCampaignState(campaign.id, {
          state: state,
          oldState: campaign.state,
          synergy: '',
          actiview: ''
        }).pipe(
      tap(() => isLinked$.subscribe()),
      map(() => {
        campaign.state = state;
        campaign.activityNumber = '';
        campaign.synergy = null;
        return campaign;
      }),
      finalize(() => {
        this.showSpinner = false;
      }),

      catchError(error => {
        this.catchError(error);
        return observableThrowError(error);
      }));
  }

  updateData(text: any, type: string) {
    // conversion du text en type KeyValue[], tel que spécifié dans le type FilterSchedule
    // s'il n'y a pas de texte, on remet le filtre à son état initial
    if (type === 'bridge' || type === 'actiview') {
      this.filter[type] = text ? [{'key': text, 'value': text}] : [];
    } else {
      this.filter[type] = text ? text : '';
    }
  }
  updateDate(text: any, type: string) {
    this.filter[type] = text;
  }

  updateFilterProtocol(filter: any) {
    this.filter.protocol = filter;
    this.isChangedFilterProtocol = this.hasFilterOn(filter);
  }

  updateFilterTarget(filter: any) {
    this.filter.target = filter;
    this.isChangedFilterTarget = this.hasFilterOn(filter);
  }

  updateObject(value: any, object: any, property: any) {
    object[property] = value;
  }
  updatePropertyIsSelected(campaigns: LightCampaign[], idCampaign: string, switchState: boolean): LightCampaign[] {
    const index = campaigns.findIndex((campaign: LightCampaign) => campaign.id === idCampaign);
    campaigns[index].isSelected = switchState;
    return campaigns;
  }

  clearFilterInputs() {
    this.searchTextComponents.forEach(searchText => searchText.clearInputs());
  }
}
