export default {
  type: 'columnchart',
  configs: {
    compute: {
      method: 'confortPercentWithCategory',
      key: 'key'
    },
    translations: {
      yAxis: {
        title: {
          english: 'Percentage of volunteers (%)',
          french: 'Pourcentage de volontaires (%)',
        },
      },
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    chart: {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    yAxis: {
      min: 0,
      max: 100,
      tickInterval: 10,
      labels: {
          formatter: function() {
              return this.value + '%';
          }
      }
    },
    plotOptions: {
      attributes_blocks: {
        enabled: true,
      },
      default_language: {
        enabled: false,
      },
      show_column_zero: {
        enabled: true,
      },
      series: {
        dataLabels: {
          enabled: false,
        },
      },
    },
  },
};
