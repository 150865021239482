export default {
    "type": "spider",
    "configs": {
        "title": {
            "text": "",
            "margin": 50
        },
        "subtitle" : {
            "text" : ""
        },
        "compute": {
            "method" : "median",
            "key" : "label"
        },
        "drilldown": {
            "enabled": true
        },
        "yAxis" : {
            "min" : -6,
            "max": 6
        },
        "plotOptions" : {
            "attributes_blocks": {
                "enabled": false
            },
            "visibilityThreshold" : {
                "value" : 0
            },
            "default_language" : {
                "enabled" : false
            },
            "tooltip_details" : {
                "enabled" : false
            }
        }
    }
}
