import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GridContentType, Orientation, Page} from '../../../types';
import {IFormat} from './IFormat';
import {IEditorInit} from './IEditorInit';
import {GridsterConfig} from 'angular-gridster2';

@Component({
  selector: 'dna-image-layout',
  templateUrl: './image-layout.component.html',
  styleUrls: ['./image-layout.component.less']
})

export class ImageLayoutComponent {

  @Input() private _landscapeFormat: string;
  @Input() private _format: IFormat;
  @Input() private _page: Page;
  @Input() private _captureMode: boolean;
  @Input() private _initEditor: IEditorInit;

  @Output() private _onChangeComment: EventEmitter<Page> = new EventEmitter();
  @Output() private _onCommentAdd: EventEmitter<Page> = new EventEmitter();
  @Output() private _onRemovedItem: EventEmitter<{ page: Page, index: number }> = new EventEmitter();

  constructor() {
  }

  public getHeight(orientation: Orientation): string {
    return orientation === Orientation.Landscape ? this._landscapeFormat : this._format.HEIGHT;
  }

  public onChangeComment(page: Page): void {
    this._onChangeComment.emit(page);
  }

  public onCommentAdd(page: Page): void {
    this._onCommentAdd.emit(page);
  }

  public removeItem(page: Page, index: number): void {
    this._onRemovedItem.emit({page, index});
  }

  public get previewOption(): GridsterConfig {
    return {
      margin: 0,
      draggable: {
        enabled: false,
      },
      resizable: {
        enabled: false,
      },
      minCols: 30,
      maxCols: 30,
      minRows: 30,
      maxRows: 30
    };
  }

  public get page(): Page {
    return this._page;
  }

  public set page(value: Page) {
    this._page = value;
  }

  public get captureMode(): boolean {
    return this._captureMode;
  }

  public get gridContentCommentType(): GridContentType {
    return GridContentType.Comment;
  }

  public get gridContentImageType(): GridContentType {
    return GridContentType.Image;
  }

  public get initEditor(): IEditorInit {
    return this._initEditor;
  }

}
