<ngx-loading [show]="showSpinner"></ngx-loading>
<div *ngIf="!showSpinner" class="ibox float-e-margins">
  <div class="ibox-title">
    <div class="row">
      <div class="col-12 col-md-4 my-auto">
        <h5>{{ "PROTOCOL" | translate }}</h5>
      </div>
    </div>
  </div>
  <div class="ibox-content">
    <div *ngIf="!error && protocoleHead.length > 0" class="table-protocol">
      <table class="table table-bordered" [ngStyle]="{'width': widthTable + 'px'}">
        <thead>
          <tr>
            <th class="fixed-column-head fixed-column">{{ "CAMPAIGNS" | translate }}</th>
            <th *ngFor="let head of protocoleHead; let index = index;" style="width:200px">
              {{ protocolTraduction[head].name | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cp of campaignProtocol">
            <th id="td_{{cp.id}}" class="fixed-column studies-background" title="{{ cp.name }}">{{ cp.name }}</th>
            <ng-container *ngFor="let head of protocoleHead; let indexHead = index;">
              <ng-container *ngIf="protocolTraduction[head].type != 'array', else value_array">
                <td id="td_{{cp.id}}_{{indexHead}}">
                  {{ campaignProtocolFormatted[cp.id][head] ? (campaignProtocolFormatted[cp.id][head].key | translate) : "" }}</td>
              </ng-container>
              <ng-template #value_array>
                <td id="td_{{cp.id}}_{{indexHead}}">
                  <ng-container *ngFor="let val of campaignProtocolFormatted[cp.id][head].key; let indexVal = index;">
                    {{ val | translate }}
                    <ng-container *ngIf="indexVal+1 != campaignProtocolFormatted[cp.id][head].key.length"><br></ng-container>
                  </ng-container>
                </td>
              </ng-template>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!error && protocoleHead.length == 0">{{ "PROTOCOL_CONFIG_ERROR" | translate }}</div>
    <dna-error-page *ngIf="error"  (onClickReload)="init()"></dna-error-page>
  </div>
</div>
