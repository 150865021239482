
import { throwError as observableThrowError, forkJoin as observableForkJoin, Observable, Subscription, empty, throwError, of, iif } from 'rxjs';

import { take, tap, finalize, mergeMap, catchError, filter, map, merge, share, flatMap } from 'rxjs/operators';
import {
  ActivatedRoute,
  ParamMap,
  Router,
} from '@angular/router';
import { AfterViewInit, Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import {
  CampaignStates,
  User,
  ViewType,
  Workflow,
  WorkflowProject,
  Language,
  ModalContent,
  Block,
  Graph,
  Translatable,
  Descriptor,
  DNAComponent,
  CampaignWorkflow,
} from '../../../../types';
import { CampaignService } from '../../../campaigns.service';
import { DNATranslateService } from '../../../../shared/services/translate.service';
import { ErrorManagerService } from '../../../../shared/services/errorManager.service';
import { ReferenceTypeService } from '../../../../shared/services/reference-type.service';
import { StateService } from '../../../../shared/services/state.service';
import { UserService } from '../../../../shared/services/user.service';
import { UtilService } from '../../../../shared/services/util.service';
import { WorkflowEditModalComponent } from './workflow-edit/workflow-edit-modal.component';
import { WorkflowService } from '../../../../workflows/workflows.service';
import { VisitsUpdatedModalComponent } from './visits-updated-modal/visits-updated-modal.component';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-campaign-workflows',
  templateUrl: './campaign-workflows.component.html',
  styleUrls: ['./campaign-workflows.component.less']
})
export class CampaignWorkflowsComponent implements OnInit, AfterViewInit {

  private _campaign: CampaignWorkflow;
  private _wksArrayReduced: Workflow[];

  campaign: CampaignWorkflow;
  campaignStates: typeof CampaignStates = CampaignStates;
  currentLanguage: Language;
  displayFavoriteOnly: boolean = false;
  hubs: any[] = [];
  isDisabled: boolean;
  outOfDateWfs: string[] = [];
  searchText: string = '';
  showSpinner: boolean = false;
  subscribeParam: Subscription;
  subscribeUser: Subscription;
  user: User;
  workflows: Workflow[] = [];
  workflowsLinked: Workflow[] = [];
  workflowsUnlinked: Workflow[] = [];
  workflowsToAdd: Workflow[] = [];
  workflowType: string;
  newLoopCreated: boolean = false;
  error: boolean = false;
  collapsedHubTab = {};
  initTime = performance.now();

  CUSTOMIZE_CARD_MODE: ViewType;

  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg',
    windowClass: 'dna-modal-campaign'
  };

  visitsUpdatedModalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg',
    windowClass: 'dna-modal-campaign'
  };

  constructor(
    private campaignService: CampaignService,
    private errorManager: ErrorManagerService,
    private modalService: NgbModal,
    private referenceTypeService: ReferenceTypeService,
    private aiService: ApplicationInsightsService,
    private route: ActivatedRoute,
    private router: Router,
    private stateService: StateService,
    private translateService: DNATranslateService,
    private userService: UserService,
    private utilService: UtilService,
    private workflowService: WorkflowService
  ) { }

  ngOnInit() {
    this.currentLanguage = this.translateService.getLanguage();
    this.hubs = this.referenceTypeService.getHubs();
    this.hubs.push('Others');
    this.user = this.userService.getUser();
    this.workflowType = this.stateService.WORKFLOWS;
    this.CUSTOMIZE_CARD_MODE = this.campaignService.CUSTOMIZE_CARD_MODE;
    this.init();
    this.subscribeUser = this.userService.onUserChanged().subscribe(() => {
      this.currentLanguage = this.translateService.getLanguage();
      this.user = this.userService.getUser();
    });
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Workflows', '', performance.now() - this.initTime, templateUrl);
  }

  init(){
    this.error = false;
    this.showSpinner = true;
    this.subscribeParam = this.route.parent.paramMap.pipe(
      flatMap((params: ParamMap) => this.campaignService.getCampaignWorkflow(params.get('idCampaign'))),
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      tap((campaign: CampaignWorkflow) => this.campaign = this.initCampaign(campaign)),
      tap((campaign: CampaignWorkflow) => this.campaignService.updateBreadCrumb(campaign.name)),
      flatMap(() => this.initOtherValues()),
      tap(() => this.collapseAllHubs()),
      tap(() => this.showSpinner = false)
      ).subscribe();
  }

  initCampaign(campaign: CampaignWorkflow): CampaignWorkflow {
    if (!campaign.workflows) campaign.workflows = [];
    // Commented if needed in other hubs in the future
    // if (campaign.actiview && campaign.actiview.studyType) {
    //   this.initWorkflowsMethodsOrStudy(campaign);
    // }
    return campaign;
  }

  initOtherValues() {
    return this.workflowService.getWorkflowsForCampaign().pipe(
      tap((wk) => {
        this.workflows = wk.list;
        this.campaignService.setWorkflows(wk.list)
        this.removeWorkflow(_.cloneDeep(this.workflows));
        this.isDisabled =  this.utilService.isNotEditable(this.campaign.state);
        this._campaign = _.cloneDeep(this.campaign);

        this.initOutOfDateWorkflows(this.campaign.workflows);

        if (this.outOfDateWfs.length > 0 && (this.campaign.state === CampaignStates.Draft || this.campaign.state === CampaignStates.Suspended)) {
          this.errorManager.displayMessage('WORKFLOWS_OUT_OF_DATE', 'warning');
        }
      })
    )
  }

  ngOnDestroy() {
    this.subscribeParam.unsubscribe();
    this.subscribeUser.unsubscribe();
  }

  private initOutOfDateWorkflows(campaignWorkflows) {
    campaignWorkflows.forEach((workflow: Workflow) =>
      this.outOfDate(this.workflows, workflow)
    );
  }

  isChangedCampaign() {
    return this.utilService.isDifferent(this._campaign, this.campaign);
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.utilService.canDeactivate(this.campaign, this._campaign);
  }

  canIRemoveThisElement(element: Workflow) {
    if (this.campaign.state == CampaignStates.Draft || this.campaign.state == CampaignStates.Suspended)
      return true;

    return !this.isInOriginalCampaign(element);
  }

  displayButtonLinkOrUnlink(workflowsProject: Workflow[]) {
    this.campaign.workflows = this.campaign.workflows.map(workflow => this.updateProjectInWorkflowsCampaign(workflow, workflowsProject));
  }

  filterAndSort(workflows: Workflow[] = [], displayFavoriteOnly: boolean = false, text: string = '', language: string = 'english'): Workflow[] {
    let aName, bName;
    return this.utilService.filterObjects(workflows, displayFavoriteOnly, text, language)
      .sort((a: Workflow, b: Workflow) => {
        aName = a.name[language] ? a.name[language] : a.name['english'];
        bName = b.name[language] ? b.name[language] : b.name['english'];
        return aName.localeCompare(bName);
      });
  }

  // initWorkflowsMethodsOrStudy(campaign: CampaignWorkflow) {
  //   this.campaignService.getWorkflowsProjectByStudy(campaign.actiview.studyType).subscribe(
  //     workflowsWithProject => {
  //       this.displayButtonLinkOrUnlink(workflowsWithProject);
  //       this.removeWorkflow(_.cloneDeep(this.workflowsToAdd));
  //     });
  // }

  isInOriginalCampaign(element: Workflow) {
    return _.find(this._campaign.workflows, o => o.id == element.id);
  }

  link(workflow: Workflow, nameMethod: string, nameStudy: string) {
    let projectInWorkflow: WorkflowProject = new WorkflowProject();
    if (nameMethod !== '')
      projectInWorkflow.method = nameMethod;

    projectInWorkflow.study = nameStudy;
    workflow.project = projectInWorkflow;
    let index = this.campaign.workflows.findIndex(workflowCampaign => workflowCampaign.id !== workflow.id && workflowCampaign.project && workflowCampaign.project.study === workflow.project.study && workflowCampaign.project.method === workflow.project.method);
    if (index >= 0) {
      delete this.campaign.workflows[index].project;
      let findWorkflowUnlinked = this.workflowsUnlinked.find(workflowUnlinked => workflowUnlinked.id == this.campaign.workflows[index].id);
      if (!findWorkflowUnlinked)
        this.workflowsUnlinked.push(this.campaign.workflows[index]);

      let indexLinkToDelete = this.workflowsLinked.findIndex(workflowLinked => workflowLinked.id == this.campaign.workflows[index].id);
      if (indexLinkToDelete >= 0)
        this.workflowsLinked.splice(indexLinkToDelete, 1);
    }
    let indexUnlinkToDelete = this.workflowsUnlinked.findIndex(workflowUnlinked => workflowUnlinked.id == workflow.id);
    if (indexUnlinkToDelete >= 0)
      this.workflowsUnlinked.splice(indexUnlinkToDelete, 1);

    let findWorkflowLinked = this.workflowsLinked.find(workflowLinked => workflowLinked.id == workflow.id);
    if (!findWorkflowLinked)
      this.workflowsLinked.push(workflow);
  }

  private nextSave(campaign: CampaignWorkflow) {
    campaign.workflows = campaign.workflows.map(wk => wk = <Workflow>_.omitBy(wk, (value, key) => key[0] === '_' || ['createdBy', 'created_on', 'state', 'workspaces'].find(a => a === key)));

    if (campaign.workflows.length > 0) {
      this.campaignService.putCampaignWorkflow(campaign).pipe(
        tap((res) => {
          if(!_.isEmpty(res.blocksDeleted)) {
            this.openVisitsUpdatedModal(res.blocksDeleted);
          }
          if(res.haveBlocksStatusChangedOrDeleted) {
            this.errorManager.displayMessage('CUSTOMIZED_MODE_RESET', 'warning', { timeOut: 10000 });
          }
          campaign.workflows = res.workflows;
          this.showSpinner = false;
          this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
        }),
        catchError(http => {
          this.showSpinner = false;
          http.error.err === 'DEACTIVATE_QUESTIONS_NOT_ALLOW' ? this.errorManager.displayMessage('DEACTIVATE_QUESTIONS_NOT_ALLOW', 'danger') : this.errorManager.catchError(http);
          return observableThrowError(http);
        }))
        .subscribe((res) => {
          if(!_.isEmpty(res.blocksDeleted)) {
            this.openVisitsUpdatedModal(res.blocksDeleted)
          }
          this._campaign = _.cloneDeep(this.campaign);
        });
    } else {
      this.showSpinner = false;
      this.errorManager.displayMessage('ON_ERROR_EMPTY_WORKFLOW', 'danger');
    }
  }

  //DNA-V2 : pour l'instant on récupère le questionnaire en entier et on le sauvegarde dans l'étude comme c'est fait actuellement.
  // TODO : est-ce qu'on créé une nouvelle instance du questionnaire et on ne garde que l'id dans l'étude?
  onAddWorkflow(id: string) {
    this.showSpinner = true;
    this.workflowService.getWorkflow(id).pipe(
      tap((wk) => this.campaign.workflows.push(wk)),
      flatMap((wk) => this.workflowService.getWorkflowComponents(wk.id)),
      tap((result) => this.campaign.workflows[this.campaign.workflows.length-1] = this.initComponents(this.campaign.workflows[this.campaign.workflows.length-1], result.list)),
      finalize(() => {
        this.removeWorkflow(_.cloneDeep(this.workflows));
        this.showSpinner = false;
      })
    ).subscribe()
  }

  initComponents(workflow, result) {
    let wk = {...workflow};
    wk.blocks.forEach(b => {
      b.components.forEach((c, index) => {
        const component = result.find(fullComponents => fullComponents.id === c);
        if (component) {
          b.components[index] = {...component}
        }

      })
    })
    return wk;
  }

  onCancel() {
    this.campaign = _.cloneDeep(this._campaign);
    this.removeWorkflow(_.cloneDeep(this.campaignService.getWorkflows()));
    this.workflowsLinked = [];
    this.workflowsUnlinked = [];
    this.newLoopCreated = false;
    this.initOutOfDateWorkflows(this.campaign.workflows);
  }

  openVisitsUpdatedModal(blocksDeleted) {
    const modal = this.modalService.open(VisitsUpdatedModalComponent, this.visitsUpdatedModalOption);
    modal.componentInstance.blocksDeleted = blocksDeleted;
    modal.result
    .then(
      () => {},
      (reason) => { }
    );
  }

  goToPowerBIDescription(workflow: Workflow) {
    this.router.navigate(['campaigns', this.campaign.id, 'edit', 'analyse', workflow.id, 'powerBIDescription']);
  }

  onRemoveWorkflow(index: number) {
    let modalContent: ModalContent;
    const idWorkflow = this.campaign.workflows[index].id;
    const wkName = this.translateService.getTranslation(this.campaign.workflows[index].name, this.currentLanguage);
    this.utilService.translateMessageModal('CONFIRM_DELETE', wkName, 'THE_WORKFLOW').pipe(
      map((content: ModalContent) => modalContent = content),
      mergeMap(() => this.translateService.translateMessage('DELETE_WORKFLOW_WARNING')),
      tap((translation: string) => modalContent.message = modalContent.message.concat(` ${translation}`)),
      mergeMap(() => this.utilService.openModalConfirm(modalContent)),
      tap(() => this.showSpinner = true),
      map(() => {
        if (this.campaign.actiview && this.campaign.actiview.studyType) {
          this.updateListLinkUnlink(index);
        }
        this.outOfDateWfs.splice(this.outOfDateWfs.findIndex(wk => wk === this.campaign.workflows[index].id), 1);
        this.campaign.workflows.splice(index, 1);
        this.removeWorkflow(_.cloneDeep(this.workflows));
        return idWorkflow;
      }),
      mergeMap((workflowId: string) =>
       (this.isChangedCampaign(),
        this.campaignService.deleteCampaignWorkflow(this.campaign.id, workflowId))
      ),
      tap(() => this._campaign = _.cloneDeep(this.campaign)),
      finalize(() => {
        this.showSpinner = false;
        this.errorManager.displayMessage('ON_SUCCESS_UPDATE_WORKFLOW');
      })
    )
    .subscribe();
  }

  private openModal(workflow: Workflow) {
    const workflowToSend = _.pick(workflow, ['id', 'name', 'blocks', 'parameters', 'isChangedActivecomponent', 'notificationMode', 'startDate']);
    const modal = this.modalService.open(WorkflowEditModalComponent, this.modalOption);
    modal.componentInstance.isWorkflowEditable = this.campaign.state === CampaignStates.Draft || this.campaign.state === CampaignStates.Suspended;
    modal.componentInstance.areEvaluationsStarted = _.get(this.campaign, 'evaluations', []).some(evaluation => _.get(evaluation, 'progress', 0) !== 0);
    modal.componentInstance.workflowConfiguration = workflowToSend;
    modal.componentInstance.editMode = true;
    modal.result.then(
      (result) => {
        let workflowConfiguration = result[0];
        let workflow = this.campaign.workflows.find(w => w.id === workflowConfiguration.id);
        workflow.blocks = _.get(workflowConfiguration, 'blocks', []);
        workflow.parameters = _.get(workflowConfiguration, 'parameters');
        workflow = this.amendGraphs(_.cloneDeep(workflow))
        this.newLoopCreated = result[1];
      },
      (reason) => { }
    );
  }

  amendGraphs(workflow: Workflow): Workflow {
    workflow.graphs = _.get(workflow,'graphs', []).map(g => this.amendGraph(g, workflow))
    return workflow;
  }

  amendGraph = (graph: Graph, workflow: Workflow): Graph => {
    let descriptors = _.get(graph,'descriptors', []);
    graph.descriptors = descriptors
      .map(d => this.updateDescriptorLoop(d, workflow))
      .filter(d => this.filterDescriptor(d, workflow))
    return graph;
  }

  updateDescriptorLoop = (descriptor: Descriptor, workflow: Workflow): Descriptor => {
    let findBlock = <Block> _.get(workflow,'blocks', []).find(b => b.idInQuestionnaire === descriptor.idInQuestionnaire);
    if(!findBlock) {
      findBlock = _.get(workflow,'blocks', []).find(b => b.idInQuestionnaire === descriptor.idInQuestionnaire + '@0');
      let findComponent = <DNAComponent> _.get(findBlock, 'components', []).find(c => c.idInBlock === descriptor.idInBlock);
      if(findBlock) {
        descriptor.idInQuestionnaire += '@0';
        descriptor.blockName = findBlock.name;
        descriptor.name = _.get(findComponent,'args.label', new Translatable());
      }
      else {
        let findComponent = <DNAComponent> _.get(findBlock, 'components', []).find(c => c.idInBlock === descriptor.idInBlock);
        descriptor.name = _.get(findComponent,'args.label', new Translatable());
        descriptor.isActive = false;
      }
    }
    return descriptor;
  }

  filterDescriptor = (descriptor: Descriptor, workflow: Workflow): boolean => {
    let block: Block, component: DNAComponent;
    block = _.get(workflow,'blocks', []).find((b: Block) => b.idInQuestionnaire === descriptor.idInQuestionnaire);
    if (!block) {
      return false;
    }
    component = _.get(block,'components', []).find((c: DNAComponent) => c.idInBlock === descriptor.idInBlock);
    return !_.get(component,'args.inactive');
  }

  openModalWorkflow(workflow: Workflow) {
    if (!workflow.blocks) {
      this.showSpinner = true;
      this.workflowService.getWorkflow(workflow.id).pipe(
        finalize(() => this.showSpinner = false))
        .subscribe(wk => {
          let index = this.campaign.workflows.findIndex(workflowCampaign => workflowCampaign.id === wk.id);
          this.campaign.workflows[index] = wk;
          this.openModal(wk);
        });
    } else {
      this.openModal(workflow);
    }
  }

  outOfDate(wfsMetadata: Workflow[], workflow: Workflow) {
    let activeWf = wfsMetadata.find((w: Workflow) => workflow.id.includes(w.id));
    if(activeWf) {
      if (workflow.updated_on) {
        if (activeWf.updated_on !== workflow.updated_on) {
          this.outOfDateWfs.push(workflow.id);
          return true;
        }
      } else {
        if (activeWf.updated_on !== workflow.updated_on) {
          this.outOfDateWfs.push(workflow.id);
          return true;
        }
      }
    }
    return false;
  }

  removeWorkflow(workflows: Workflow[]) {
    this._wksArrayReduced = this.utilService.removeWorkflowsInList(workflows, this.campaign.workflows);
    this.workflowsToAdd = this.filterAndSort(_.cloneDeep(this._wksArrayReduced), this.displayFavoriteOnly, this.searchText, this.currentLanguage);
  }

  save(campaign: CampaignWorkflow) {
    this.showSpinner = true;
    // if (this.campaign.actiview && this.campaign.actiview.studyType && (this.workflowsLinked.length > 0 || this.workflowsUnlinked.length > 0))
    //   this.updateLinkAndUnlinkWorkflowsInBase();

    let promises = campaign.workflows.reduce((arr, curr) => {
      if (!curr.blocks) {
        arr.push(this.workflowService.getWorkflow(curr.id))
      }
      return arr;
    }, []);

    if (promises.length > 0) {
      observableForkJoin(promises).subscribe((wks: Workflow[]) => {
        wks.map(wk => {
          let index = campaign.workflows.findIndex(workflowCampaign => workflowCampaign.id === wk.id);
          campaign.workflows[index] = wk;
        });
        this.nextSave(campaign);
      });
    } else {
      this.nextSave(campaign);
    }
  }

  unlink(workflow: Workflow) {
    if (workflow.project) {
      delete workflow.project;
      let indexMethodToDelete = this.workflowsLinked.findIndex(workflowMethodToDelete => workflowMethodToDelete.id == workflow.id);
      if (indexMethodToDelete >= 0) {
        this.workflowsLinked.splice(indexMethodToDelete, 1);
      }
      let findWorkflow = this.workflowsUnlinked.find(workflowUnlinked => workflowUnlinked.id == workflow.id);
      if (!findWorkflow) {
        this.workflowsUnlinked.push(workflow);
      }
    }
  }

  // updateLinkAndUnlinkWorkflowsInBase() {
  //   this.showSpinner = true;
  //   this.campaignService.getWorkflowsProjectByStudy(this.campaign.actiview.studyType).subscribe(
  //     workflowsWithProject => {

  //       let observablesFirst = workflowsWithProject.reduce((observables, workflowProject) => {
  //         let workflowToUnlink = this.workflowsUnlinked.find(workflowUnlinked => workflowProject.id == workflowUnlinked.id);
  //         if (workflowToUnlink) {
  //           observables.push(this.campaignService.deleteWorkflowWithProject(workflowToUnlink));
  //         }
  //         let indexWorkflowTolink = this.workflowsLinked.findIndex(workflowLinked => workflowProject.id === workflowLinked.id);
  //         if (indexWorkflowTolink >= 0) {
  //           if (workflowProject.project.study !== this.workflowsLinked[indexWorkflowTolink].project.study || workflowProject.project.method !== this.workflowsLinked[indexWorkflowTolink].project.method) {
  //             observables.push(this.campaignService.updateWorkflowWithProject(this.workflowsLinked[indexWorkflowTolink]));
  //             this.workflowsLinked.splice(indexWorkflowTolink, 1);
  //           }
  //         }
  //         return observables;
  //       }, []);

  //       let observablesSecond = this.workflowsLinked.reduce((observables, workflowToUpdate) => {
  //         observables.push(this.campaignService.updateWorkflowWithProject(workflowToUpdate));
  //         return observables;
  //       }, observablesFirst);

  //       observableForkJoin(observablesSecond).subscribe(
  //         success => this.showSpinner = false,
  //         error => this.showSpinner = false);
  //     },
  //     error => this.showSpinner = false);
  // }

  updateList(searchText: string, displayFavorites: boolean) {
    this.displayFavoriteOnly = displayFavorites;
    this.searchText = searchText;
    this.workflowsToAdd = this.filterAndSort(_.cloneDeep(this._wksArrayReduced), displayFavorites, searchText, this.currentLanguage);
  }

  updateListLinkUnlink(index: number) {
    let indexLinkedToDelete = this.workflowsLinked.findIndex(workflowLinked => workflowLinked.id == this.campaign.workflows[index].id);
    if (indexLinkedToDelete >= 0) {
      this.workflowsLinked.splice(indexLinkedToDelete, 1);
    }
    let findWorkflowUnlinked = this.workflowsUnlinked.find(workflowUnlinked => workflowUnlinked.id == this.campaign.workflows[index].id);
    if (!findWorkflowUnlinked) {
      if (this.campaign.workflows[index].project) {
        delete this.campaign.workflows[index].project;
      }
      this.workflowsUnlinked.push(this.campaign.workflows[index]);
    }
  }

  updateProjectInWorkflowsCampaign(workflow: Workflow, workflowsProject: Workflow[]): Workflow {
    if (workflow.project) {
      let workflowToUnlink = workflowsProject.find(workflowProject => workflow.id === workflowProject.id);
      if (workflowToUnlink) {
        if (workflow.project.study !== workflowToUnlink.project.study || workflow.project.method !== workflowToUnlink.project.method)
          delete workflow.project;
      } else {
        delete workflow.project;
      }
    } else {
      let workflowToLink = workflowsProject.find(workflowProject => workflow.id === workflowProject.id);
      if (workflowToLink)
        workflow.project = workflowToLink.project;
    }
    return workflow;
  }

  /********** WORKFLOW UPDATE ON REFRESH **********/

  getUpdatedWorkflow(workflow: Workflow) {
    this.showSpinner = true;
    return this.workflowService.getWorkflow(workflow.id).pipe(
      finalize(() => this.showSpinner = false),
      catchError(error => {
        this.errorManager.catchError(error);
        return observableThrowError(error);
      }))
  }

  onSuccessUpdateState(updateWorkflow: Workflow): Workflow {
    this.errorManager.displayMessage('ON_SUCCESS_UPDATE_WORKFLOW');
    let index = this.campaign.workflows.findIndex((w: Workflow) => w.id === updateWorkflow.id);
    this.campaign.workflows[index] = updateWorkflow;
    this.outOfDateWfs = this.outOfDateWfs.filter((o: string) => o !== updateWorkflow.id);
    return updateWorkflow;
  }

  updateWorkflow(workflowInCampaign: Workflow) {
    let workflowFromLibrary: Workflow;
    let workflowInCampaignReferenceId: string = workflowInCampaign.id;
    let originalWorkflowInCampaign = _.cloneDeep(workflowInCampaign);
    let visualizationModeConfiguration = _.get(originalWorkflowInCampaign, 'parameters.pages', []);

    // on récupère l'id de la reference si on est sur un id composite de questionnaire externalisé
    const regExp = /.*?_(.*)/;

    let regExpMatch;
    // regExpMatch est de la forme ["résultat de la recherche", "questionnaireId"]
    if(regExpMatch = workflowInCampaign.id.match(regExp)) workflowInCampaignReferenceId = regExpMatch[1];

    const workflowUpdates$ = this.workflowService.getWorkflow(workflowInCampaignReferenceId)
      .pipe(
        tap((wk: Workflow) => workflowFromLibrary = wk),
        flatMap((wk) => this.workflowService.getWorkflowComponents(wk.id)),
        tap((result) => workflowFromLibrary = this.initComponents(workflowFromLibrary, result.list)),
        map(() => this.newBlocksAddedAndLoop(workflowFromLibrary.blocks, workflowInCampaign.blocks)),
        share()
      )

    const newBlockAndLoop$ = workflowUpdates$
      .pipe(
        filter((bool: Boolean) => bool === true),
        mergeMap(() => this.utilService.translateMessageModal('WORKFLOW_REFRESH', '', '')),
        mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)),
        map(() => this.loadCampaignBlocksFromQuestionnaire(workflowFromLibrary, workflowInCampaign)),
        catchError((error) => {
          return empty();
        })
      )

    const noNewBlocks$ = workflowUpdates$
      .pipe(
        filter(bool => !bool),
        map(() => this.updateBlockList(workflowFromLibrary.blocks, workflowInCampaign)),
        map((workflowInCampaign: Workflow) => this.keepVisualizationModeConfiguration(visualizationModeConfiguration, workflowInCampaign))
      )

    return newBlockAndLoop$.pipe(
      merge(noNewBlocks$),
      map((wk: Workflow) => this.updateBlocksStatus(wk)),
      map((wk: Workflow) => this.applyDescriptorsStatus(wk, originalWorkflowInCampaign.blocks)),
      map((wk: Workflow) => this.updateGraphs(wk, workflowFromLibrary)),
      map((wk: Workflow) => this.updateWorkflowInformations(wk, workflowFromLibrary)),
      tap((wk: Workflow) => this.onSuccessUpdateState(wk))
    )
      .subscribe();
  }

  private updateBlocksStatus(workflow) {
    workflow.blocks = workflow.blocks.map(block => {
      if(_.every(_.get(block, 'components', []), ['args.inactive', true]) && (!_.has(block, 'parameters.inactive') || !_.get(block, 'parameters.inactive'))) {
        _.set(block, 'parameters.inactive', true);
      }
      return block;
    });
    return workflow;
  }

  private updateWorkflowInformations(workflowFromCampaign, workflowFromLibrary): Workflow {
      workflowFromCampaign.updated_on = workflowFromLibrary.updated_on;
      workflowFromCampaign.updated_on = workflowFromLibrary.updated_on;
      workflowFromCampaign.name = workflowFromLibrary.name;
      workflowFromCampaign.description = workflowFromLibrary.description;
    return workflowFromCampaign;
  }

  private loadCampaignBlocksFromQuestionnaire(workflowFromLibrary: Workflow, workflowInCampaign: Workflow) {
    workflowInCampaign.blocks = workflowFromLibrary.blocks;
    return workflowInCampaign;
  }

  private updateBlockList(currentWorkflowBlocks: Block[], workflowCampaign: Workflow) {
    const newBlocks = [];
    if (workflowCampaign.blocks.some(b => b.idInQuestionnaire.includes('@'))) {
      workflowCampaign.blocks.forEach(bic => {
        const id = bic.idInQuestionnaire.includes('@') ? bic.idInQuestionnaire.substring(0, bic.idInQuestionnaire.indexOf('@')) : bic.idInQuestionnaire;
        let blockToUpdate: Block = _.cloneDeep(currentWorkflowBlocks.find(b => b.idInQuestionnaire === id));
        if (!_.isUndefined(blockToUpdate)) {
          blockToUpdate.name = bic.name;
          blockToUpdate.idInQuestionnaire = bic.idInQuestionnaire;
          blockToUpdate.sequenceInWorkflow = bic.sequenceInWorkflow;
          blockToUpdate.suffix = bic.suffix;
          blockToUpdate.components = blockToUpdate.components.map(c => {
            const component = bic.components.find(ci => ci.idInBlock === c.idInBlock);
            if(component) {
              component.args.label = c.args.label;
              return component;
            } else return c;
          })
          newBlocks.push(blockToUpdate);
        }
      });
      workflowCampaign.blocks = newBlocks;
    }
    else {
      const blocksInCampaignCopy = _.cloneDeep(workflowCampaign.blocks);
      workflowCampaign.blocks = currentWorkflowBlocks;
      workflowCampaign.blocks = workflowCampaign.blocks.map(blockInCampaign => {
        const foundBlock = blocksInCampaignCopy.find(b => b.id === blockInCampaign.id && b.idInQuestionnaire === blockInCampaign.idInQuestionnaire);
        if(foundBlock) {
          _.set(blockInCampaign, 'suffix', foundBlock.suffix);
          return blockInCampaign;
        }
        return blockInCampaign;
      });
    }
    return workflowCampaign;
  }

  private applyDescriptorsStatus(workflowInCampaign: Workflow, originalWorkflowBlocksInCampaign: Block[]) {
    for (let block of originalWorkflowBlocksInCampaign) {
      for (let component of block.components) {
        if (_.get(component, 'args.inactive', false)) {
          let componentInCurrentWorkflow = workflowInCampaign.blocks.find(b => b.idInQuestionnaire === block.idInQuestionnaire)
            .components.find(c => c.idInBlock === component.idInBlock);
          componentInCurrentWorkflow.args.inactive = true;
        }
      }
    }
    return workflowInCampaign;
  }

  private newBlocksAddedAndLoop(currentWorkflowBlocks: Block[], workflowBlocksInCampaign: Block[]) {
    if (workflowBlocksInCampaign.some(b => b.idInQuestionnaire.includes('@'))) {
      for (let block of currentWorkflowBlocks) {
        if (!workflowBlocksInCampaign.some(bic => bic.idInQuestionnaire.includes(block.idInQuestionnaire))) {
          return true;
        }
      };
      return false;
    }
    return false;
  }

  private keepVisualizationModeConfiguration(visualizationModeConfiguration: number[], workflowInCampaign: Workflow) {
    if (!_.isEmpty(visualizationModeConfiguration)) {
      if (workflowInCampaign.blocks.length === visualizationModeConfiguration.length) {
        _.set(workflowInCampaign, 'parameters.pages', visualizationModeConfiguration);
      } else {
        _.set(workflowInCampaign, 'parameters.pages', []);
        this.errorManager.displayMessage('VIEW_MODE_RESET', 'warning');
      }
    }
    return workflowInCampaign;
  }

  private findComponent(blocks: Block[], descriptor: { idInQuestionnaire: string, idInBlock: string }) {
    const block = blocks.find(b => b.idInQuestionnaire === descriptor.idInQuestionnaire);
    if (block) {
      return block.components.find(c => c.idInBlock === descriptor.idInBlock);
    }
    return undefined;
  }

  private updateGraphs(workflow: Workflow, workflowFromLibrary: Workflow) {
    let campaignGraphs: Graph[] = _.get(workflow, 'graphs', []).filter(g => _.has(g, 'id'));
    let graphsFromQuestionnaire: Graph[] = _.get(workflowFromLibrary, 'graphs', []);

    //1 Pour chacun des graphs de la campagne, on verifie que les descripteurs existent bien dans le nouveau questionnaire. Si non, on les supprime.
    let newCampaignGraph = [];
    for (let graph of campaignGraphs) {
      let descs = [];
      for (let descriptor of graph.descriptors) {
        let component = this.findComponent(workflow.blocks, descriptor);
        if (component) {
          descs.push(descriptor);
        }
      }
      graph.descriptors = descs;

      //Si on a au moins un descripteur dans le graph, on l'ajoute a la nouvelle liste de graph de la campagne
      if (graph.descriptors.length > 0) {
        newCampaignGraph.push(graph);
      }
    }

    // On ajoute les eventuels nouveaux graphs du questionnaire aux graphs de la campagne.
    for (let graph of graphsFromQuestionnaire) {
      const indexGraphInCampaign = campaignGraphs.findIndex(g => g.id === graph.id);
      //Deux cas, si le graph n'existe pas dans la campagne, on l'ajoute
      if (indexGraphInCampaign === -1) {
        newCampaignGraph.push(graph);
      }
      // S'il exsite, on le met à jour
      else {
        newCampaignGraph[indexGraphInCampaign] = graph;
      }
    }

    workflow.graphs = newCampaignGraph;

    // Pour chacun des graphs,
    // on met à jour leur descripteurs si ils sont dans une boucle dans la campagne (idInQuestionnaire avec @);
    workflow.graphs.forEach(qgic => {
      qgic.descriptors.map(desc => {
        const findBlock = workflow.blocks.find(b => b.idInQuestionnaire === desc.idInQuestionnaire + '@0');
        if (!_.isUndefined(findBlock)) {
          desc.idInQuestionnaire = findBlock.idInQuestionnaire;
          desc.blockName = findBlock.name;
          desc.name = findBlock.components.find(c => c.idInBlock === desc.idInBlock).args.label;
        }
      });
    });

    return workflow;
  }

  private collapseAllHubs() {
    this.hubs.forEach((hub, indexHub) => {
      this.collapsedHubTab[indexHub] = hub !== this.campaign.hub;
    });
  }

}
