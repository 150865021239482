import {
  Component,
  Input,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';
import {
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import {
  Block,
  Descriptor,
  DNAComponent,
  Workflow,
  Translatable
} from '../../../../../../types';
import { ReferenceTypeService } from '../../../../../../shared/services/reference-type.service';
import {
  ErrorManagerService
} from '../../../../../../shared/services/errorManager.service';

@Component({
  selector: 'dna-power-bi-modal-add-descriptor',
  templateUrl: './power-bi-modal-add-descriptor.component.html',
  styleUrls: ['./power-bi-modal-add-descriptor.component.less']
})
export class PowerBIModalAddDescriptorComponent implements OnInit {

  @Input() descriptorsInWorkflow: Descriptor[] = [];
  @Input() descriptorsInGraph: Descriptor[] = [];
  @Input() useSwitchComponent: boolean = false;
  @Input() workflow: Workflow;
  @Input() isGroup: boolean = false;

  componentsNumberByBlock: number[] = [];
  descriptorsSelectedByBlock: number[] = [];
  groupName: string = "";
  calculationMethods: string[] = [];
  calculationMethodSelected: string = "";
  listDescriptors: Descriptor[];
  collapsedBlocksTab = {};

  selectAll: {
    [key: string]: boolean;
  } = {};

  constructor(
    private activeModal: NgbActiveModal,
    private errorManager: ErrorManagerService,
    private referenceTypeService: ReferenceTypeService
    ) { }

    ngOnInit(): void {
      if(this.isGroup) {
        this.listDescriptors = _.cloneDeep(this.descriptorsInGraph);
        this.descriptorsInGraph = [];
        this.calculationMethods = this.referenceTypeService.getCalculationMethod();
      }
      this.workflow.blocks.forEach((block, indexBlock) => {
        this.collapsedBlocksTab[indexBlock] = true;
        this.selectAll[indexBlock] = this.hasSelectedAllComponents(this.descriptorsInGraph, block);
      })
    }

  hasSelectedAllComponents(descriptorsInGraph: Descriptor[], block: Block) {
    return _.every(block.components, function(component) {
      return !_.isUndefined(descriptorsInGraph.find(de => de.idInBlock === component.idInBlock && de.idInQuestionnaire === block.idInQuestionnaire))
    })
  }

  getNumberSelected(block: Block, descriptorsInWorkflow: Descriptor[]): string {
    return `${this.getDescriptorsInBlock(descriptorsInWorkflow, block.idInQuestionnaire).filter(d => this.isDescriptorSelected(d)).length} / ${this.getDescriptorsInBlock(descriptorsInWorkflow, block.idInQuestionnaire).length}`;
  }

  getDescriptorsInBlock(descriptorsInWorkflow: Descriptor[], blockidInQuestionnaire: string) {
    return descriptorsInWorkflow.filter(d => d.idInQuestionnaire === blockidInQuestionnaire);
  }

  isDescriptorSelected(d: Descriptor): boolean {
    return !_.isUndefined(this.descriptorsInGraph.find(de => de.idInBlock === d.idInBlock && de.idInQuestionnaire === d.idInQuestionnaire));
  }
  

  addOrRemoveDescriptors(descriptor: Descriptor, indexBlock: number, block: Block) {
    if(this.isDescriptorSelected(descriptor)) {
      _.remove(this.descriptorsInGraph, { idInBlock : descriptor.idInBlock, idInQuestionnaire: descriptor.idInQuestionnaire })
    } else {
      let d =  new Descriptor(
        descriptor.blockName,
        descriptor.idBlock,
        descriptor.idInBlock,
        descriptor.idInQuestionnaire,
        descriptor.name
      );
      if (descriptor.type === 'dna-confort') {
        d.type = descriptor.type;
        d.confortValues = descriptor.confortValues;
      }
      d.blockSuffix = _.get(block, 'suffix', '');
      this.descriptorsInGraph.push(d);
    }
    this.selectAll[indexBlock] = this.hasSelectedAllComponents(this.descriptorsInGraph, this.workflow.blocks[indexBlock]);
  }

  cancel() {
    this.activeModal.dismiss();
  }

  isSelected(block: Block, component: DNAComponent) {
    return this.descriptorsInGraph.some(
      (descriptor: Descriptor) => descriptor.idInBlock === component.idInBlock &&
        descriptor.idInQuestionnaire === block.idInQuestionnaire &&
        descriptor.isActive
    );
  }

  selectAllDescriptors(descriptors: Descriptor[], indexBlock: number, event: any, block: Block) {
    this.selectAll[indexBlock] = !this.selectAll[indexBlock];
    event.stopPropagation();
    if (this.selectAll[indexBlock]) {
      descriptors.filter(d => !this.isDescriptorSelected(d)).map(d => this.addOrRemoveDescriptors(d, indexBlock, block));
    } else {
      descriptors.filter(d => this.isDescriptorSelected(d)).map(d => this.addOrRemoveDescriptors(d, indexBlock, block));
    }
  }

  submit(descriptors: Descriptor[]) {
    if (this.isGroup) {
      if (_.isEmpty(this.calculationMethodSelected) || _.isEmpty(this.groupName)) {
        this.errorManager.displayMessage("ON_ERROR_FORM", "danger");
      } else {
        let groupDescriptor = new Descriptor(new Translatable(""), "", "", "", new Translatable(this.groupName));
        groupDescriptor.listDescriptors = _.cloneDeep(descriptors.filter(d => d.isActive));
        groupDescriptor.isGroup = true;
        groupDescriptor.calculationMethod = this.calculationMethodSelected;
        descriptors = this.listDescriptors;
        descriptors.push(groupDescriptor);
        this.activeModal.close(descriptors);
      }
    } else {
      this.activeModal.close(descriptors);
    }
    
    
  }

  openCloseDescriptors(openOrClose: boolean) {
    Object.keys(this.collapsedBlocksTab).forEach(key => this.collapsedBlocksTab[key] = openOrClose);
  }
}
