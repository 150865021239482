import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IEditorInit} from '../images/image-layout/IEditorInit';
import {Image, Page} from '../../types';
import {IFormat} from '../images/image-layout/IFormat';

@Component({
  selector: 'dna-page-editor',
  templateUrl: './page-editor.component.html',
  styleUrls: ['./page-editor.component.less']
})
export class PageEditorComponent {

  @Input() private _captureMode: boolean;
  @Input() private _initEditor: IEditorInit;
  @Input() private _page: Page;
  @Input() private _pages: Page[];
  @Input() private _images: Image[];
  @Input() private _sizeLandScape: string;
  @Input() private _format: IFormat;


  @Output() private _onChangeComment: EventEmitter<Page> = new EventEmitter();
  @Output() private _onCommentAdd: EventEmitter<Page> = new EventEmitter();
  @Output() private _onRemovedItem: EventEmitter<{ page: Page, index: number }> = new EventEmitter();
  @Output() private _onImagesChanged: EventEmitter<Image[]> = new EventEmitter();
  @Output() private _onUpdatedGrid: EventEmitter<Page[]> = new EventEmitter();
  @Output() private _onPageAdd: EventEmitter<{ images: Image[], pages: Page[] }> = new EventEmitter<{images: Image[]; pages: Page[]}>();

  constructor() {
  }

  public get captureMode(): boolean {
    return this._captureMode;
  }

  public get initEditor(): IEditorInit {
    return this._initEditor;
  }

  public get page(): Page {
    return this._page;
  }

  public removeItem(item): void {
    this._onRemovedItem.emit(item);
  }

  public onChangeComment(page): void {
    this._onChangeComment.emit(page);
  }

  public onCommentAdd(page): void {
    this._onCommentAdd.emit(page);
  }

  public get sizeLandScape(): string {
    return this._sizeLandScape;
  }

  public get pages(): Page[] {
    return this._pages;
  }

  public set pages(value: Page[]) {
    this._pages = value;
  }

  public get images(): Image[] {
    return this._images;
  }

  public set images(value: Image[]) {
    this._images = value;
  }

  public onUpdatedGrid(pages: Page[]): void {
    this._onUpdatedGrid.emit(pages);
  }

  public onImagesChanged(images: Image[]): void {
    this._onImagesChanged.emit(images);
  }

  public get format(): IFormat {
    return this._format;
  }

  public onPageAdd(info: { images: Image[]; pages: Page[] }): void {
    this._onPageAdd.emit({images: info.images, pages: info.pages});
  }
}
