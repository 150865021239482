
import {catchError, finalize, map, takeUntil, tap} from 'rxjs/operators';
import {
  Component,
  Input,
  OnInit,
  OnDestroy, AfterViewInit
} from '@angular/core';
import {
  NgForm
} from '@angular/forms';

import * as _ from 'lodash';
import {
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import { Subject, throwError as observableThrowError } from 'rxjs';

import {
  Campaign,
  Preference,
  SynergyDemand,
  User,
  ViewGroups,
  ViewTypes,
  Workspace,
  CampaignStates,
  FormulaCampaign,
  UserCampaign,
  OnePager
} from '../../../types';
import {
  CampaignService
} from '../../campaigns.service';
import {
  ErrorManagerService
} from '../../../shared/services/errorManager.service';
import {
  UserService
} from '../../../shared/services/user.service';
import { UtilService } from '../../../shared/services/util.service';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-create-campaign-modal',
  templateUrl: './create-campaign-modal.component.html',
  styleUrls: ['../../../shared/modals/modals.component.less', './create-campaign-modal.component.less']
})
export class CreateCampaignModalComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() idCampaignToDuplicate: string;
  @Input() hasArcs: boolean;
  @Input() hasSynergy: boolean;
  @Input() synergyDemand: SynergyDemand;

  campaign: Campaign;
  campaignName: string;
  keepArcs: boolean = false;
  keepSynergy: boolean = false;
  submitted: boolean;
  user: User;
  workspace: Workspace = new Workspace();
  showSpinner: boolean = false;
  initTime = performance.now();

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public activeModal: NgbActiveModal,
    private campaignService: CampaignService,
    private errorManager: ErrorManagerService,
    private aiService: ApplicationInsightsService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.workspace = this.user.currentWorkspace;
  }

  ngAfterViewInit() {
    this.aiService.logPageView('Create Campaign Modal', '', performance.now() - this.initTime, 'Modals');
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  private initCampaign(): Campaign {
    // Create new campaign
    this.campaign = new Campaign(this.user.currentWorkspace.preferences);
    this.campaign.workspaces = [this.workspace.id];
    this.campaign.state = CampaignStates.Draft;
    this.campaign.createdBy = {
      user: this.user.track
    };
    this.campaign.formula = new FormulaCampaign();
    this.campaign.users = new UserCampaign();
    this.campaign.timeZone = new Date().getTimezoneOffset();
    this.campaign.arcs = [];

    this.campaign.hub = this.userService.getUserHub(this.user.hub);
    if (_.has(this.workspace, 'metier.id')) this.campaign.metier = this.workspace.metier;
    this.campaign.name = this.campaignName;
    this.campaign.parameters.isTemplate = false;
    if (this.campaign.parameters.viewType === ViewTypes.ModeList) this.campaign.parameters.viewGroup = ViewGroups.FormulaGroup;
    if (this.synergyDemand) this.campaign.synergy = this.synergyDemand;
    return this.campaign;
  }

  save(form: NgForm) {
    this.showSpinner = true;
    this.submitted = true;
    if (!form.invalid) {
      //duplicate
      if(this.idCampaignToDuplicate) {
        const body = this.setBody();
        this.campaignService.duplicateCampaign(this.idCampaignToDuplicate, body).pipe(
          catchError(this.onErrorRequest),
          tap((resp) => this.activeModal.close(resp.id)),
          finalize(() => this.showSpinner = false)
        ).subscribe();
      } else {
        this.campaign = this.initCampaign();
        this.campaignService.createCampaign(this.campaign, new OnePager()).pipe(
          catchError(this.onErrorRequest),
          tap((campaign) => this.activeModal.close(campaign.id)),
          finalize(() => this.showSpinner = false)
        ).subscribe();
      }
    } else {
      this.showSpinner = false;
      this.errorManager.displayMessage('ON_ERROR_FORM', 'danger');
    }
  }

  /**
   * get the needed informations to create the campaign
   */
  setBody() {
    return {
      createdBy: { user: this.user.track },
      timeZone: new Date().getTimezoneOffset(),
      keepArcs: this.keepArcs,
      hub: this.userService.getUserHub(this.user.hub),
      workspaces: [this.workspace.id],
      campaignName: this.campaignName,
      metier : this.workspace.metier,
      keepSynergy: this.keepSynergy,
      onePager: new OnePager()
    };
  }

  onErrorRequest = (err: Response) => {
    this.showSpinner = false;
    this.errorManager.catchError(err);
    this.errorManager.displayMessage('ON_ERROR_UPDATE', 'danger');
    return observableThrowError(err);
  }

}
