import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dna-increase-decrease-selection',
  templateUrl: './increase-decrease-selection.component.html',
  styleUrls: ['./increase-decrease-selection.component.scss']
})
export class IncreaseDecreaseSelectionComponent {
  @Input() parameters : any;
  
  public form : FormGroup;
  checked = {1: false, 2: false, 3: false};
  
  constructor(private _fb : FormBuilder) {
    this.form = this._fb.group({
      choice : null
    })
  };

  clickRadio(value:any) {
    if (this.checked[value]) {
      this.form.reset();
      this.checked[value] = false;
    }
    else {
      for (let key in this.checked) {
        this.checked[key] =  key == value;
      }
    }
}

  
}