<form #dnaPhotoForm="ngForm" class='form-horizontal d-flex flex-column gap-3'>
  <div class="form-group">
    <div class="col-sm-2">
      <label class="control-label" translate>LABEL</label>
      <span class="fa fa-info" placement="bottom" ngbTooltip="{{'tooltip_label' | translate}}" container="body"></span>
    </div>
    <div class="col-sm-10">
      <input name="label" type='text' class='form-control' [(ngModel)]="component.args.label.english" [disabled]="inactive">
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-2">
      <label class="control-label" translate>TAG</label>
      <span class="fa fa-info" placement="bottom" ngbTooltip="{{'tooltip_tag' | translate}}" container="body"></span>
    </div>
    <div class="col-sm-10">
      <input name="tag" type='text' class='form-control' [(ngModel)]="component.args.tag" [disabled]="inactive">
    </div>
  </div>
</form>
