<form #volunteerForm="ngForm" novalidate>
  <div class="modal-header">
    <h3 class="text-center" translate>ADD_VOLUNTEERS_EXCEL_FILE</h3>
  </div>
  <label class="col-sm-12"></label>
  <div class="row">
    <div class="col col-md-5 box-upload">
      <div id="upload-button">
        <label>
          <input class="btn btn-primary" type="file" #file (change)="uploadFiles($event)" name="file" accept="'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
          />
          <span class="btn btn-primary" translate>UPLOAD_EXCEL_FILE</span>
          <span>{{ fileName }}</span>
        </label>
      </div>
    </div>
    <div class="col col-md-3 box-upload">

    </div>
    <div class="col col-md-4 box-download">
      <span translate>EXAMPLE_TEMPLATE_EXCEL_FILE </span>
      <a target="_self" class="btn btn-primary" href="{{urlTemplateExcel}}" download="template.xlsx" translate>DOWNLOAD_TEMPLATE_EXCEL_FILE</a>
    </div>
  </div>

  <label class="col-sm-12"></label>

  <div id="ibox-Blocks" class="ibox-content collapse show">
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th translate>NAME</th>
            <th translate>FIRST_NAME</th>
            <th translate>EMAIL</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let testeur of listes">
            <td>{{ testeur.name }}</td>
            <td>{{ testeur.firstName }}</td>
            <td>{{ testeur.email }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <label class="col-sm-12"></label>

  <div class="form-group col-sm-10">
    <dna-checkbox [isChecked]="doRegister" (onBooleanChanged)="doRegister = !doRegister"></dna-checkbox>
    {{'REGISTER' | translate}}
  </div>

  <div class="modal-footer">
    <button id="save" class="btn btn-primary mb-4" (click)="save(listes)" translate>SAVE</button>
    <button id="cancel" class="btn btn-default mb-4" (click)="cancel()" translate>CANCEL</button>
  </div>
</form>
