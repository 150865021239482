import { Component, Input, Output, EventEmitter} from "@angular/core";
import { VolunteerService } from "../../volunteers.service";

@Component({
    selector: '[dna-group-panelist-horsarcs-row]',
    templateUrl: './group-panelist-horsarcs-row.component.html',
    styleUrls: ['./group-panelist-horsarcs-row.component.less']
  })
  export class GroupPanelistHARowComponent {

    @Input() panelist: any;
  
    @Output() onClickGroupPanelistHA: EventEmitter<any> = new EventEmitter();

    constructor(private volunteerService: VolunteerService) { }
    
    onClickHA() {
      this.onClickGroupPanelistHA.emit();
    }
  }