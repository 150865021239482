import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import * as _ from 'lodash';

import { DNAObject } from '../../../types';

@Component({
    selector: 'dna-filter-list',
    templateUrl: './filter-list.component.html',
    styleUrls: ['./filter-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterListComponent implements OnInit {

    @Input() array: DNAObject[];
    @Input() fieldToDisplay: string;
    @Input() filterValue: DNAObject[];
    @Input() horizontalAlignment: boolean = false;
    @Input() title: string;

    @Output() updateFilter = new EventEmitter();

    constructor() { }

    ngOnInit() { }

    clickFilter() {
        this.updateFilter.emit();
    }

    getIndex(state: DNAObject, filterArray: DNAObject[]) {
        let field = this.fieldToDisplay;
        if (field) {
            return _.findIndex(filterArray, function (current) {
                return current[field] === state[field];
            });
        }
        return filterArray.indexOf(state);
    }

    isInFilter(data: DNAObject) {
        let field = this.fieldToDisplay;
        if (field) {
            return _.find(this.filterValue, function (current) {
                return current[field] === data[field];
            });
        }
        return this.filterValue.indexOf(data) != -1;
    }

    pushData(state: DNAObject) {
        let index = this.getIndex(state, this.filterValue);
        if (index == -1) this.filterValue.push(state);
        else {
            this.filterValue.splice(index, 1);
        }
    }

}
