import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import _ from 'lodash';

@Pipe({
  name: 'applyMultiTranslations'
})
export class ApplyMultiTranslationsPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) { }
  transform(objectToTranslate: any, langs?: Array<any>, fallback?: string): any {
    langs = Object.keys(langs.filter(x => x).reduce((acc: any, lang: string) => {
      acc[lang] = null;
      return acc;
    }, {}));
    if (objectToTranslate !== undefined && objectToTranslate !== null) {
      switch (typeof objectToTranslate) {
        case 'number':
          return objectToTranslate;
        case 'string':
          return _.escape(objectToTranslate);
        case 'object':
          if (!objectToTranslate) return "";
          if (objectToTranslate && objectToTranslate.hasOwnProperty('user') && objectToTranslate.user.length) return objectToTranslate.user;
          if (objectToTranslate && objectToTranslate.hasOwnProperty('custom')) return ' - ';
          if (!langs.length) return objectToTranslate[fallback];
          
          const html = langs.reduce((accumulator: string, lang: string, index: number) => {
            if (objectToTranslate.hasOwnProperty(lang) && objectToTranslate[lang].length) accumulator = `${accumulator} ${objectToTranslate[lang]} ${langs.length > 1 && index !== langs.length - 1 ? '<br />' : ''}`;
            else if (!objectToTranslate.hasOwnProperty(lang) || !objectToTranslate[lang].length && lang != fallback) {
              accumulator = `${accumulator} ${objectToTranslate[fallback]} ${langs.length > 1 && index !== langs.length - 1 ? '<br />' : ''}`;
            }
            return accumulator;
          }, '');
          return this._sanitizer.bypassSecurityTrustHtml(html);
      }
    }
    else {
      return '';
    }
  }
}
