import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { BlockExportComponent } from './general/my-profile/import-export/export/block/block-export.component';
import { BlockImportComponent } from './general/my-profile/import-export/import/block/block-import.component';
import { BlocksModule } from './blocks/blocks.module';
import { CampaignsModule } from './campaigns/campaigns.module';
import { ComponentConfigurationModule } from './components/args/componentConfiguration.module';
import { CustomDatepickerI18n } from './datepickerUtil/datepicker-i18n';
import { ExportComponent } from './general/my-profile/import-export/export/export.component';
import { ImportComponent } from './general/my-profile/import-export/import/import.component';
import { ImportExportComponent } from './general/my-profile/import-export/import-export.component';
import { MultiCampaignsModule } from './multi-campaigns/multi-campaigns.module';
import { MyProfileComponent } from './general/my-profile/my-profile.component';
import { NgbDateFRParserFormatter } from './datepickerUtil/ngb-date-fr-parser-formatter';
import { SharedModule } from './shared/shared.module';
import { ProgressBarComponent } from './shared/progressBar/progressBar.component';
import { VolunteersModule } from './volunteers/volunteers.module';
import { WorkflowExportComponent } from './general/my-profile/import-export/export/workflow/workflow-export.component';
import { WorkflowImportComponent } from './general/my-profile/import-export/import/workflow/workflow-import.component';
import { WorkflowsModule } from './workflows/workflows.module';
import { WorkspacesModule } from './general/my-profile/workspaces/workspaces.module';
import { environment } from '@env';
import { TranslationsManagementComponent } from './general/my-profile/translations-management/translations-management.component';
import { CustomTranslateLoader } from './shared/translateLoader/customTranslateLoader';
import { TranslationsService } from './shared/services/translations.service';
import { ExportDataSetComponent } from './export-data-set/export-data-set.component';
import {
  MsalModule,
  MsalInterceptor,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalRedirectComponent
} from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { AppRoutingModule } from './app-routing.module';
import { AuthComponent } from './general/auth/auth.component';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { TagInputModule } from 'ngx-chips';
import { VolunteersManagementComponent } from '@app/general/my-profile/volunteers-management/volunteers-management.component';
const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    AppRoutingModule,
    BlocksModule,
    BrowserModule,
    BrowserAnimationsModule,
    CampaignsModule,
    ComponentConfigurationModule,
    HttpClientModule,
    MultiCampaignsModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circle,
      fullScreenBackdrop: true,
      primaryColour: '#1ab394',
      secondaryColour: '#f3f3f4'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [TranslationsService]
      }
    }),
    VolunteersModule,
    WorkflowsModule,
    WorkspacesModule,
    MsalModule,
    TagInputModule
  ],
  exports: [],
  declarations: [
    AppComponent,
    BlockExportComponent,
    BlockImportComponent,
    ExportComponent,
    ExportDataSetComponent,
    ImportComponent,
    ImportExportComponent,
    MyProfileComponent,
    WorkflowExportComponent,
    WorkflowImportComponent,
    TranslationsManagementComponent,
    ProgressBarComponent,
    AuthComponent,
    VolunteersManagementComponent
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: 'TRANSLATIONSSERVICES', useClass: TranslationsService },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}

export function loggerCallback(logLevel: LogLevel, message: string) {
  if (logLevel === LogLevel.Error || logLevel === LogLevel.Warning) {
    console.log(message);
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msal.clientId,
      authority: environment.msal.authority,
      redirectUri: environment.msal.redirectUri,
      postLogoutRedirectUri: environment.msal.postLogoutRedirectUri,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: true
    },
    system: {
      allowNativeBroker: false,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}
