<div class="modal-scroll">
  <div class="modal-header">
    <h3 class="modal-title" translate>BLOCKS</h3>
  </div>
  <div class="modal-body">
    <div *ngFor="let workflow of blocksByWorkflow; let indexWorkflow = index">
      <div class="row col-12">
        <button type="button" class="btn btn-block btn-light" attr.aria-controls="{{'collapseWorkflow_' + indewWorkflow}}" (click)="collapsedWorkflowsTab[indexWorkflow] = !collapsedWorkflowsTab[indexWorkflow]">
          <h4><dna-translate [fieldToTranslate]="workflow.blocks[0].workflowName"> </dna-translate></h4>
        </button>
      </div>
      <div class="row col-12" id="{{'collapseWorkflow_' + indewWorkflow}}" [ngbCollapse]="collapsedWorkflowsTab[indexWorkflow]">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th style="width: 30%">{{ "NAME" | translate }}</th>
              <th style="width: 50%">{{ "DESCRIPTION" | translate }}</th>
              <th style="width: 20%">{{ "VISIT_INDEX" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="line-descriptor cursor-pointer"
              [ngClass]="{'selected': isBlockInThisVisit(block.id, block.idInQuestionnaire), 'selectedOther': isBlockInOtherVisit(block.id, block.idInQuestionnaire)}"
              *ngFor="let block of workflow.blocks" (click)="addOrRemoveBlock(block)">
              <ng-container *ngIf="block.isActive">
                <td>
                  <dna-translate *ngIf="!block.selected; else nameToDisplay" [fieldToTranslate]="block.name"> </dna-translate>
                  <ng-template #nameToDisplay>{{ block.nameToDisplay }}</ng-template>
                </td>
                <td>
                  <dna-translate [fieldToTranslate]="block.description"> </dna-translate>
                </td>
                <td>{{block.index+1 || ''}}</td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="onValidate()" translate>VALIDATE</button>
  <button class="btn btn-outline-dark" type="button" (click)="onCancel()" translate>CANCEL</button>
</div>
