
import {finalize} from 'rxjs/operators';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import * as _ from 'lodash';

import { User, Workspace } from '../../types';
import { UserService } from '../services/user.service';
import { WorkspaceService } from '../../general/my-profile/workspaces/workspaces.service';

@Component({
  selector: 'dna-workspace-selection',
  templateUrl: './workspace-selection.component.html',
  styleUrls: ['./workspace-selection.component.less']
})
export class WorkspaceSelectionComponent implements OnInit {

  @Output() onWorkspace: EventEmitter<Workspace> = new EventEmitter();

  showSpinner: boolean = true;
  user: User;
  workspaces: Workspace[] = [];
  workspaceSelected: Workspace;

  constructor(
    private userService: UserService,
    private workspaceService: WorkspaceService
  ) { }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.workspaceService.getWorkspacesByUser(this.user.track).pipe(
      finalize(() => {
        this.showSpinner = false;
        this.emitWorkspace(this.workspaceSelected);
      }))
      .subscribe((workspaceArray: Workspace[]) => {
        this.workspaces = workspaceArray;
        this.workspaceSelected = this.workspaceService.selectWorkspace(this.user, this.workspaces);
      });
  }

  emitWorkspace(workspace: Workspace) {
    this.onWorkspace.emit(workspace);
  }

}
