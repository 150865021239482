import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  NgForm
} from '@angular/forms';

import * as _ from 'lodash';
import {
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import {
  Block,
  WorkflowConfiguration,
  Campaign,
  CampaignStates,
  Descriptor,
  DNAComponent,
  Evaluation,
  Graph,
  Workflow,
  Translatable,
  CampaignWorkflow
} from '../../../../../types';
import {
  ErrorManagerService
} from '../../../../../shared/services/errorManager.service';

@Component({
  selector: 'dna-workflow-edit-modal',
  templateUrl: './workflow-edit-modal.component.html'
})
export class WorkflowEditModalComponent implements OnInit {

  @Input() isWorkflowEditable: boolean;
  @Input() areEvaluationsStarted: boolean;
  @Input() workflowConfiguration: WorkflowConfiguration;
  @Input() consultationMode: boolean;

  newLoopCreated: boolean = false;
  isActivateQuestionDisplay: boolean = false;
  currentWorkflowConfiguration: WorkflowConfiguration;

  constructor(
    private activeModal: NgbActiveModal,
    private errorManager: ErrorManagerService
  ) {}

  ngOnInit() {
    this.currentWorkflowConfiguration = _.cloneDeep(this.workflowConfiguration);
  }

  cancel(form: NgForm) {
    this.activeModal.dismiss();
  }

  changeDisplay(display) {
    this.isActivateQuestionDisplay = this.consultationMode
      ? false
      : _.isEqual(display, 'activateQuestions') && !this.areEvaluationsStarted;
  }

  loopCreated() {
    this.newLoopCreated = true;
  }

  submit(form: NgForm) {
    const atLeastOneBlockActive = this.currentWorkflowConfiguration.blocks.some((block: Block) => !block.parameters.inactive);
    if (form.valid && atLeastOneBlockActive) {
      this.activeModal.close([this.currentWorkflowConfiguration, this.newLoopCreated]);
    } else {
      !form.valid ? this.errorManager.displayMessage("ON_ERROR_FORM", "danger") : this.errorManager.displayMessage("ERROR_ACTIVATE_BLOCK", "danger");
    }
  }
}
