
import {throwError as observableThrowError,
  Observable
,  Subscription,
of,
Subject} from 'rxjs';

import {catchError, finalize, mergeMap, tap} from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';

import {
  ErrorManagerService
} from '../../../shared/services/errorManager.service';
import {
  DNATypes,
  Feedback,
  FilterSchedule,
  TableHeader,
  User,
  __Links
} from '../../../types';
import {
  HttpRestService
} from '../../../shared/services/httpRest.service';
import {
  UtilService
} from '../../../shared/services/util.service';
import { UserService } from '../../../shared/services/user.service';
import { FilterService } from '../../../shared/services/filter.service';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-feedbacks',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.less']
})

export class FeedbackComponent implements OnInit, AfterViewInit {

  feedbacks: Feedback[] = [];
  orderedBy: string;
  reverse: boolean;
  showSpinner = true;
  subscription: Subscription;
  tableHeaders$: Observable<TableHeader[]>;
  filter: any;
  __links: __Links;
  totalItems: number;
  user: User;
  error = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  initTime = performance.now();

  constructor(
    private errorManagerService: ErrorManagerService,
    private httpRestService: HttpRestService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private userService: UserService,
    private filterService: FilterService,
    private errorManager: ErrorManagerService
  ) { }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Profile Feedback', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.tableHeaders$ = this.utilService.createHeaders(DNATypes.Feedbacks);
    this.tableHeaders$.subscribe();
    this.user = this.userService.getUser();
    this.filter = this.filterService.getFilter().feedbacks;
    this.subscription = this.httpRestService.getFeedBacks().pipe(
      tap(feedbacks => {
        this.feedbacks = feedbacks.list;
        this.__links = feedbacks.__links;
        this.totalItems = feedbacks.totalItems;
      }),
      catchError(err => {
        this.error = true;
        return this.catchError(err);
      }),
      finalize(() => {
        this.showSpinner = false;
      })
    ).subscribe();
  }

  catchError = (error) => {
    this.showSpinner = false;
    this.errorManager.catchError(error);
    this.errorManager.displayMessage("ON_ERROR_UPDATE", "danger");
    return of();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  deleteBlock(feedback: Feedback): Observable<any> {
    this.showSpinner = true;
    return this.httpRestService.deleteFeedBack(feedback.id).pipe(
      tap(() => _.remove(this.feedbacks, feedback)),
      tap(() => this.totalItems--),
      finalize(() => this.showSpinner = false),
      catchError(err => {
        this.errorManagerService.catchError(err);
        return observableThrowError(err);
      }),);
  }

  sort(headerId: string, reverse: boolean) {
    switch (headerId) {
      case 'TITLE':
        return this.utilService.sortListByType(this.feedbacks, 'title', reverse);
      case 'SOURCE':
        return this.utilService.sortListByType(this.feedbacks, 'source', reverse);
    }
  }

  remove(feedback: Feedback) {
    this.utilService.translateMessageModal('CONFIRM_DELETE', feedback.title, 'THE_FEEDBACK').pipe(
      mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)),
      mergeMap(() => this.deleteBlock(feedback)))
      .subscribe(() => {
        _.remove(this.feedbacks, feedback);
        this.errorManagerService.displayMessage('ON_SUCCESS_DELETE');
      });
  }

}
