<form #workspaceForm="ngForm" novalidate>
  <div class="modal-scroll">
    <ngx-loading [show]="showSpinner"></ngx-loading>
    <div class="modal-header mb-3">
      <h3 class="text-center" translate>WORKSPACE</h3>
    </div>
    <div class="modal-body">
      <div class="form-group mb-4" [ngClass]="{'has-error' : (submitted && name.invalid) || (name.dirty && name.invalid) }">
        <label class="control-label col-sm-6">{{ 'NAME' | translate }}
          <dna-mandatory></dna-mandatory>
        </label>
        <div class="col-sm-10">
          <input class="form-control" [disabled]="!userIsAdministrator" type="text" placeholder="{{ 'NAME' | translate }}" [(ngModel)]="workspaceCopy.name"
            #name="ngModel" name="name" style="width:70%" required>
          <div *ngIf="(submitted && name.invalid) || (name.dirty && name.invalid)" class="alert alert-danger" translate>
            REQUIRED
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-sm-6" for="selectMetier" translate>METIER</label>
        <div class="col-sm-10">
          <select class="form-control" [(ngModel)]="workspaceCopy.metier" [compareWith]="byId" name="metier">
            <option [ngValue]="undefined"></option>
            <option *ngFor="let metier of metiers" [ngValue]="metier" translate>{{metier.name}}</option>
          </select>
        </div>
      </div>
      <div class="form-group mb-4" [ngClass]="{'has-error' : submitted && workspaceCopy.users.length == 0}">
        <label class="control-label col-sm-6">{{ 'USERS' | translate }}
          <dna-mandatory></dna-mandatory>
        </label>
        <div class="col-sm-10">
          <tag-input inputClass="tagInputWorkspace" [(ngModel)]="workspaceCopy.users" displayBy="name" identifyBy="key" [onlyFromAutocomplete]="true"
            (onRemove)="onRemoveUser($event)" name="tagInputUsers" placeholder="+ {{ 'USER' | translate }}" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { 'object': 'USER' | translate | lowercase } }}">
            <tag-input-dropdown [appendToBody]="true" [zIndex]="99999" [autocompleteItems]="users" displayBy="name" identifyBy="key" [minimumTextLength]="3"
              [showDropdownIfEmpty]="false">
            </tag-input-dropdown>
          </tag-input>
          <div *ngIf="submitted && workspaceCopy.users.length == 0" class="alert alert-danger" translate>
            REQUIRED
          </div>
        </div>
      </div>
      <div class="form-group mb-4">
        <label class="control-label col-sm-6">{{ 'ADMINISTRATORS' | translate }}</label>
        <div class="col-sm-10">
          <tag-input inputClass="tagInputWorkspace" [disable]="!userIsAdministrator" [(ngModel)]="workspaceCopy.administrators" displayBy="name"
            identifyBy="key" [onlyFromAutocomplete]="true" (onAdd)="onAddAdminToUsers($event)" name="tagInputAdmins" placeholder="+ {{ 'ADMINISTRATOR' | translate }}"
            secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { 'object': 'ADMINISTRATOR' | translate | lowercase } }}">
            <tag-input-dropdown [appendToBody]="true" [zIndex]="99999" [autocompleteItems]="users" displayBy="name" identifyBy="key" [minimumTextLength]="3"
              [showDropdownIfEmpty]="false">
            </tag-input-dropdown>
          </tag-input>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <dna-save-cancel (onClickCancel)="cancel()" (onClickSave)="save(workspaceForm)" type="administration"></dna-save-cancel>
  </div>
</form>
