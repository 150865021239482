
import { tap, flatMap, catchError} from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, OnInit } from '@angular/core';

import * as _ from 'lodash';

import { Metiers, TypeMetier, CampaignTarget } from '../../../../types';
import { CampaignService } from '../../../campaigns.service';
import { of, throwError } from 'rxjs';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-target',
  templateUrl: './target.component.html',
  styleUrls: ['./target.component.less']
})
export class TargetComponent implements OnInit, AfterViewInit {

  metiers: typeof Metiers = Metiers;

  campaignTarget: CampaignTarget;
  metierName: TypeMetier;
  error: boolean = false;
  publishedTemplate: boolean = false;
  initTime = performance.now();
  showSpinner: boolean = true;

  constructor(
    private campaignService: CampaignService,
    private aiService: ApplicationInsightsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Target', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.error = false;
    this.route.parent.paramMap.pipe(
      flatMap(params => this.campaignService.getCampaignTarget(params.get('idCampaign'))),
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      tap((campaignTarget: CampaignTarget) => this.campaignTarget = campaignTarget),
      tap((campaignTarget: CampaignTarget) => this.metierName = campaignTarget.metier.name),
      tap((campaignTarget: CampaignTarget) => this.publishedTemplate = this.campaignService.isTemplatePublishedCampaign(campaignTarget.isTemplate, campaignTarget.state)),
      tap((campaignTarget: CampaignTarget) => this.campaignService.updateBreadCrumb(campaignTarget.name)),
      tap(() => this.showSpinner = false),
    ).subscribe();
  }
}
