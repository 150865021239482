import { Component, Input, OnInit } from '@angular/core';
import { DNATranslateService } from '../services/translate.service';

@Component({
  selector: 'app-progress-bar',
  template: `
    <div class="progress-bar-container">
      <div class="progress-bar" [ngClass]="{'error': isError}" [style.width]="progress + '%'"></div>
      <span class="progress-percentage">{{currentFile}} / {{totalFiles}}</span>
      <span *ngIf="isExportedStudies" class="exported-studies">{{ totalExportedStudies }} {{ exportedStudies }}</span>
      <span *ngIf="isDownloadComplete" class="download-confirmation">{{ downloadCompleted }}</span>
      <span *ngIf="isError" class="error-message">{{ errorMessage }}</span>
    </div>
  `,
  styleUrls: ['./progressBar.component.less']
})
export class ProgressBarComponent implements OnInit {
  @Input() progress: number = 0;
  @Input() currentFile: number = 0;
  @Input() totalFiles: number = 0;
  @Input() totalExportedStudies: number = 0;
  @Input() isError: boolean = false;
  @Input() errorMessage: string = '';
  @Input() isDownloadComplete: boolean = false;
  @Input() isExportedStudies: boolean = false;

  downloadCompleted: string = '';
  exportedStudies: string = '';

  constructor(private dnaTranslateService: DNATranslateService) {}

  ngOnInit() {
    this.dnaTranslateService.translateMessage("DOWNLOAD_COMPLETE").subscribe(translation => {
      this.downloadCompleted = translation;
    });

    this.dnaTranslateService.translateMessage("EXPORTED_STUDIES").subscribe(translation => {
      this.exportedStudies = translation;
    });
  }
}
