import { Injectable } from '@angular/core';
import { UserInCampaign } from '../types';

@Injectable()
export class AccountableService{
    accountables: UserInCampaign[];
    setCurrentAccountables(accountable: UserInCampaign[]){
        this.accountables = accountable;
    }
    clear(){
        this.accountables = null;
    }
}