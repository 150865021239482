<ngx-loading [show]="spinner"></ngx-loading>
<div *ngIf="!spinner" class="modal-scroll">
  <div class="modal-header d-flex flex-row justify-content-between">
    <h3 class="modal-title">
      {{ 'CHOOSE_CHARACTERIZATIONS' | translate }}
    </h3>
    <button class="close" type="button" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
      <span class="sr-only">Close</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="row">
        <div class="col-12 ">
          <button class="btn btn-outline-secondary m-3" type="submit" (click)="getAttributeDataFromArcs()"
            translate>UPDATE_CHARACTERIZATIONS</button>
        </div>
      </div>
      <ng-container *ngFor="let hub of getHubLoop()">
          <div class="row">
              <div class="col-12 pb-2 pt-5">
                <h2>{{ getHub(hub) | translate }}</h2>
              </div>
            </div>
        <div class="row">
          <div class="col-12">
            <input class="icheckbox_square-green" type="checkbox" [checked]="allChecked[hub]" [(ngModel)]="allChecked[hub]"
              (ngModelChange)="allChecked[hub] ? checkAll(hub) : unCheckAll(hub)">
            <label>{{ (allChecked[hub] ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}</label>
          </div>
        </div>
        <div class="row" *ngFor="let index of getLengthCharac(hub)">
          <div class="col-6">
            <ng-container *ngIf="characterizations[hub][index*2]">
              <input class="icheckbox_square-green" type="checkbox" [checked]="characterizations[hub][index*2].isSelected"
                [ngModel]="characterizations[hub][index*2].isSelected"
                (ngModelChange)="characterizations[hub][index*2].isSelected = !characterizations[hub][index*2].isSelected">
              <label>{{ characterizations[hub][index*2].AttributeName }}</label>
            </ng-container>
          </div>
          <div class="col-6">
            <ng-container *ngIf="characterizations[hub][index*2+1]">
              <input class="icheckbox_square-green" type="checkbox" [checked]="characterizations[hub][index*2+1].isSelected"
                [ngModel]="characterizations[hub][index*2+1].isSelected"
                (ngModelChange)="characterizations[hub][index*2+1].isSelected = !characterizations[hub][index*2+1].isSelected">
              <label>{{ characterizations[hub][index*2+1].AttributeName }}</label>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div *ngIf="!spinner" class="modal-footer">
  <button class="btn btn-primary" type="submit" (click)="submit()" translate>VALIDATE</button>
  <button class="btn btn-default" type="button" (click)="cancel()" translate>CANCEL</button>
</div>
