import { Translatable } from '../../../../../../../../../types';
import { Chart } from '../../chart.model';
import * as Highcharts from 'highcharts';

// INPUT INTERFACES
interface attributeInterface {
    blockName: Translatable;
    label: Translatable;
}
interface dataEndValue {
    category: string;
    count: number;
    key: number;
    label: string;
    total: number;
}
interface dataEndValueInput {
    label: Translatable;
    value: dataEndValue;
}
interface dataValueInput {
    attribute: {
        label: string;
    },
    values: dataEndValueInput[];
}
interface dataInput {
    attribute: attributeInterface;
    values: dataValueInput[];
}

// HIGHCHART OVERWRITED INTERFACES

interface customPointOptions extends Highcharts.PointOptionsObject {
    details: detailsInterface
}

interface customColumnSerieOption extends Highcharts.SeriesColumnOptions {
    data: customPointOptions[]
}

interface CustomPoint extends Highcharts.Point {
    details: detailsInterface
}

// HELPER INTERFACES

interface categoryInterface {
    etude: attributeInterface;
    category: string;
    label: Translatable;
}

interface detailsInterface extends dataEndValue {
    text: string;
}

interface PerCatSerieInterface {
    category: string | number,
    first: CustomPoint,
    values: number[],
    firstValue: number,
    firstName: string,
    secondName: string,
    firstLabel: string,
    total: number,
    totalVoted: number,
}

// OUTPUT INTERFACES
interface CreateSeriesOutput {
    series: customColumnSerieOption[],
    categories: categoryOuputInterface[]
}

interface categoryOuputInterface {
    blockName: Translatable;
    attribute: Translatable;
    label: Translatable;
}

/**
 * Create series
 * @param {dataValueInput[]} inputData 
 * @param {categoryInterface[]} categories array of categories
 * @param {customColumnSerieOption[]} series 
 * @param {attributeInterface} currentEtude
 * @return {void}
 */
const createSeriesOptions = (inputData: dataValueInput[], categories: categoryInterface[], series: customColumnSerieOption[], currentEtude: attributeInterface): void => {
    inputData.forEach(data => {
        let currentSeries = series.find(s => s.name === data.attribute.label);

        data.values.forEach(end => {
            let currentCategoryIndex: number = categories.findIndex(cat => cat.etude === currentEtude && cat.category === end.value.category);
            // CREATE CATEGORY IF NOT EXIST
            if(currentCategoryIndex === -1) {
                currentCategoryIndex = categories.length;
                categories.push({
                    etude: currentEtude,
                    category: end.value.category,
                    label: end.label
                });
            }
            // CREATE SERIE IF NOT EXIST
            if (!currentSeries) {
                currentSeries = {
                    name: data.attribute.label,
                    type: "column",
                    data: [],
                    events: {
                        click: null
                    }
                };
                series.push(currentSeries);
            }
            // CREATE NEW POINT IN SERIE
            currentSeries.data.push({
                y: end.value.key,
                x: currentCategoryIndex,
                dataLabels: {
                    format: "{point.details.text}"
                },
                details: {
                    ...end.value,
                    text: end.value.label
                }
            })

        })
    })
};

/**
 * Create series and categories
 * @param {dataInput[]} data input data to be formated
 * @return {CreateSeriesOutput} 
 */
const createHightchartDataOptions = (data: dataInput[]): CreateSeriesOutput => {
    const series: customColumnSerieOption[] = [];
    const categories: categoryInterface[] = [];

    data.forEach(serieInput => {
        const currentEtude = serieInput.attribute;
        createSeriesOptions(serieInput.values, categories, series, currentEtude);
    });

    return {
        series,
        // FORMAT CATEGORIES FOR OUR NEEDS
        categories: categories.map(cat => {
            return {
                blockName: cat.etude.blockName,
                attribute: cat.etude.label,
                label: cat.label
            }
        })
    };
};

const orderRoutines = (fullRoutines: any[], series: any[]) => {
    const temp = [];
    fullRoutines.forEach(r => {
        const selected = series.find(s => {
            return s.name === r.name;
        });

        temp.push(selected);
    })
    return temp;
}

const applyBenchOnClick = (bench: string[], series: any[], routinesObject: any, chart: any) => {
    const benchFound = [];

    series.forEach(s => {
        if (bench.includes(s.name)) {
            benchFound.push(s);
        }
    });

    const perCatSeries: PerCatSerieInterface[] = [];

    let show = true;
    let firstFound = false;
    const rest: Highcharts.Series[] = [];
    // TOP LEFT ROUTINES WHEN ON NO DRILLUP STATE
    let firstRoutines: any[] = null;
    // TOP LEFT ROUTINES WHEN ON DRILLUP STATE
    let secondRoutines: any[] = null;

    // ONCLICK FUNCTION WHEN ON NO DRILLUP STATE
    const firstFunction = function() {
        chart.custom.updateRoutines(secondRoutines);
        perCatSeries.forEach(cat => {
            cat.first.update({
                y: Number(cat.totalVoted) / Number(cat.total) * 100,
                name: cat.secondName
            }, false);
            cat.first.details.text = cat.totalVoted + "/" + cat.total;
            cat.first.series.update({
                type: "column",
                name: cat.secondName
            }, false);
        });
        rest.forEach(s => {
            s.update({
                showInLegend: false,
                type: "column",
                visible: false
            }, false);
        });
    }

    // ONCLICK FUNCTION WHEN ON DRILLUP STATE
    const secondFunction = function() {
        chart.custom.updateRoutines(firstRoutines);
        perCatSeries.forEach(cat => {
            cat.first.update({
                y: cat.firstValue,
            }, false);
            cat.first.details.text = cat.firstLabel;
            cat.first.series.update({
                type: "column",
                name: cat.firstName
            }, false)
        });
        rest.forEach(s => {
            s.update({
                type: "column",
                visible: true,
                showInLegend: true
            }, false)
        });
    }

    // INIT TOP LEFT ROUTINES AND SERIES TO BE DRILL UP
    const initFunction = function() {
        // INIT ROUTINES
        if (!firstRoutines) {
            firstRoutines = routinesObject.routines;
            secondRoutines = [];
            const temp = [];
            firstRoutines.forEach(r => {
                if (!r.isBench) {
                    secondRoutines.push(r);
                } else {
                    temp.push(r);
                }
            });

            const groupedRoutine = temp.reduce((routine, current) => {
                if(!routine) {
                    routine = {
                        ...current,
                        name: current.name.split("'")[0],
                        formula: []
                    }
                }

                current.formula.forEach(f => {
                    if (!routine.formula.find(rf => rf.name === f.name)) {
                        routine.formula.push(f);
                    }
                })

                return routine;
            }, null);

            secondRoutines.push(groupedRoutine);
        }
        const chart: Highcharts.Chart = this.chart
        // PREPARE DRILL UP DATA
        if (perCatSeries.length === 0) {
            chart.series.forEach(s => {
                if (bench.includes(s.name)) {
                    s.data.forEach((d: CustomPoint) => {
                        let currentPerCat = perCatSeries.find(p => p.category === d.x);
                        if (!currentPerCat) {
                            currentPerCat = {
                                category: d.x,
                                first: d,
                                values: [d.y],
                                firstValue: d.y,
                                firstName: s.name,
                                secondName: s.name.split("'")[0],
                                firstLabel: d.details.text,
                                total: Number(d.details.text.split('/').pop().trim()),
                                totalVoted: Number(d.details.text.split('/').shift().trim())
                            }
                            perCatSeries.push(currentPerCat);
                        } else {
                            currentPerCat.values.push(d.y);
                            currentPerCat.total += Number(d.details.text.split('/').pop().trim());
                            currentPerCat.totalVoted += Number(d.details.text.split('/').shift().trim());
                        }
                    });
                    if(!firstFound) {
                       firstFound = true; 
                    } else {
                        rest.push(s);
                    }
                }
            })
        }

        if (show) {
            firstFunction();
            show = false;
        } else {
            secondFunction();
            show = true;
        }
        chart.legend.
        chart.redraw()
    }

    benchFound.forEach((b: Highcharts.SeriesOptionsType) => {
        b.events.click = initFunction;
        //b.cursor= "pointer";
    })
}

type ColorScale = string | Highcharts.GradientColorObject | Highcharts.PatternObject;
const applyColors = (routineObject: any, firstBench: string, colorScale: ColorScale[], series: customColumnSerieOption[]): void => {
    const firstColor = colorScale.shift();
    let colorIndex = 0;

    series.forEach(s => {
        let color = firstBench === s.name ? firstColor : colorScale[colorIndex++];
        s.color = color;
        const currentRoutine = routineObject.routines.find(r => r.name === s.name);

        if (currentRoutine) {
            currentRoutine.color = color;
        }

    })
}


const doWork = (data: Array<any>, baseKey: string, lang: string, parameters: any, payload: any, routinesObject : any, chart: any) : any => {

    const simpleRoutines = [];
    const benchRoutines = [];

    routinesObject.routines.forEach(routine => {
        if (routine.isBench) benchRoutines.push(routine);
        else simpleRoutines.push(routine);
    });

    const fullRoutines = [...simpleRoutines, ...benchRoutines];
    const benchIndex = benchRoutines.map(bench => bench.name);
    const firstBench = benchIndex[0];
    const {series, categories} = createHightchartDataOptions(data);
    applyColors(routinesObject, firstBench, [...Chart.colorScale], series);
    const ordered = orderRoutines(fullRoutines, series);
    applyBenchOnClick(benchIndex, ordered, routinesObject, chart);

    payload.series = ordered;
    payload.categories = categories;
};

export {
    doWork
}