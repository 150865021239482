import { Chart } from "../../chart.model";
import * as SankeyChartLabelFormatter from '../sankey.label.formatter';

const compute_moyenne = (x: Array<number>): number => {
    return x.reduce((acc: number, value: number) => {
        acc += value;
        return acc;
    }, 0) / x.length;
};

const compute_ecart_type = (x: Array<number>, moyenne: number): number => {
    return Math.sqrt(x.reduce((acc: number, value: number) => {
        acc += Math.pow((value - moyenne), 2);
        return acc;
    }, 0) / x.length
    );
}

const doWork = (data: Array<any>, baseKey : string, lang: string, answers: any, payload: any, routinesObject: any): any => {
    let routines = routinesObject && routinesObject.hasOwnProperty('routines') && routinesObject.routines && routinesObject.routines.length ? routinesObject.routines.sort((a, b) => a.isBench - b.isBench) : [];

    const rowNumber = Object.keys(answers.reduce((acc : any, answer : any ) => { acc[answer.key] = null; return acc; }, {})).length;
    const scaleColors = createColors(rowNumber);
    const baseStatisticalSerie = {};
    payload.yAxis.max = rowNumber;

    answers
        .reduce((reducer: any, answer: any, index: number) => {
            if (baseStatisticalSerie.hasOwnProperty(answer[baseKey])) return reducer;
            else {
                baseStatisticalSerie[answer[baseKey]] = null;
                (reducer = (reducer || [])).push({
                    color: answer.color || scaleColors[answer.key - 1],
                    from: answer[baseKey] - 1,
                    to: answer[baseKey],
                    label: {
                        text: Chart.getObjectValueTranslation(answer.label, lang).toUpperCase(),
                        align: 'left',
                        y: 0,
                        translations: answer.label
                    }
                });
            }
            return reducer;
        }, payload.yAxis.plotBands);

    const _baseStatisticalSerie = [Math.min(...Object.keys(baseStatisticalSerie).map(x => Number(x))), Math.max(...Object.keys(baseStatisticalSerie).map(x => Number(x)))];
    const mb = compute_moyenne(_baseStatisticalSerie);
    const etb = compute_ecart_type(_baseStatisticalSerie, mb);

    return data.reduce((accumulator: any, item: any, index: number) => {
        if (item.hasOwnProperty('values') && Array.isArray(item.values)) {
            // CHEAT
            if (!routines.length) {
                item.values.reduce((acc: any, object: any, index: number) => {
                    acc[Chart.getObjectValueTranslation(object.attribute.label, lang)] = {
                        name: Chart.getObjectValueTranslation(object.attribute.label, lang),
                        isBench: index === 0,
                        color: Chart.colorScale[index]
                    }
                    return acc;
                }, {});
            }
            routines = Object.values(routines);

            accumulator.xAxis.plotBands.push({
                color: 'rgba(0,0,0,0)',
                from: 0.5 + (item.values.length * (index + 1)) - (item.values.length),
                to: 0.5 + (item.values.length * (index + 1)),
                className: "anchor-plotband", // Anchor to customize width and position
                label: {
                    y: -50,
                    useHTML: true,
                    text: SankeyChartLabelFormatter.formatter(item.attribute, [lang], true),
                    originalObject: item.attribute,
                }
            });

            accumulator.xAxis.plotLines.push({
                color: "rgba(0,0,0,0.3)",
                dashStyle: 'dashdot',
                value: 0.5 + (item.values.length * (index + 1)),
            });

            routines.reduce((reducer: any, routine: any, routineIndex: number) => {
                const keyLabel = [Chart.getObjectValueTranslation(item.attribute.blockName, lang), Chart.getObjectValueTranslation(item.attribute.label, lang), routine.name].join('_')
                reducer.xAxis.categories[keyLabel] = '';

                /*
                * Add boxes series
                */
                reducer.series[routine.name] = (reducer.series[routine.name] || {
                    name: routine.name,
                    linkedTo: routine.name,
                    type: "columnrange",
                    enableMouseTracking: false,
                    data: [],
                    color: `${routine.color}99`
                });

                /*
               **  Compute the current serie's median.
               **  Then, we can get the given class which provides values to correctly set the 
               **  box within the graph
               */
                const foundObject = item.values.find((object: any) => Chart.getObjectValueTranslation(object.attribute.label, lang) === routine.name);
                const med = median(Chart.castValuesToNumber(foundObject.values, baseKey, true), baseKey);
                const statisticalClassMin = answers.find((p: any) => p[baseKey] === Math.floor(med));
                const statisticalClassMax = answers.find((p: any) => p[baseKey] === Math.ceil(med));
                const pl = {
                    baseLow: Number(statisticalClassMin[baseKey]) - .5,
                    baseHigh: Number(statisticalClassMax[baseKey]) - .5,
                    name: `${
                        Chart.getObjectValueTranslation(statisticalClassMin.label, lang) !== Chart.getObjectValueTranslation(statisticalClassMax.label, lang) ?
                            Chart.getObjectValueTranslation(statisticalClassMin.label, lang) + '|' + Chart.getObjectValueTranslation(statisticalClassMax.label, lang) :
                            Chart.getObjectValueTranslation(statisticalClassMin.label, lang)}`,
                    low: null,
                    high: null
                };

                /*
                **  Computing the boxes size by measuring the distribution rate.
                **  Once that stuff done, apply this rate to the max gap, which is 1 (the range for each box).
                **  The more the rate is closed to 1, the more the box will be big.
                **  For the gap value, set the minimum valie to 0.5 to let the box appears even if the gap value is 0. 
                */
                const statSerie = foundObject.values.map((x: any) => x.value[baseKey]);
                const ms = compute_moyenne(statSerie);
                const ets = compute_ecart_type(statSerie, ms);

                const mean = (pl.baseLow + pl.baseHigh) / 2;

                pl.low = mean - Math.max(((ets / etb) / 2), 0.05);
                pl.high = mean + Math.max(((ets / etb) / 2), 0.05);
                reducer.series[foundObject.attribute.label].data.push({
                    x: Object.keys({ ...reducer.xAxis.categories }).indexOf(keyLabel),
                    ...pl,
                });
                /*
                **  Add lines series
                */
                const keySerie = `${routine.name}.line`;

                reducer.series[keySerie] = (reducer.series[keySerie] || {
                    name: routine.name,
                    type: 'spline',
                    id: routine.name,
                    color: routine.color,
                    showInLegend: true,
                    zIndex: 2,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#FFFFFF'
                    },
                    data: []
                });

                reducer.series[keySerie].data.push({
                    details: {
                        serie: foundObject.values,
                        median: med,
                        meaning: pl.name,
                        step: Chart.getObjectValueTranslation(item.attribute.label, lang)
                    },
                    x: Object.keys({ ...reducer.xAxis.categories }).indexOf(keyLabel),
                    y: (pl.low + pl.high) / 2,
                });

                return reducer;
            }, accumulator);
        }
        return accumulator;
    }, payload);
};

const median = (array: Array<any>, key: string) => {
    array = array.filter(x => x.hasOwnProperty(key) && x[key] !== null);
    if (!array.length) return null;
    const mid = Math.floor(array.length / 2),
        nums = [...array].sort((a, b) => a[key] - b[key]);
    return array.length % 2 !== 0 ? nums[mid][key] : (nums[mid - 1][key] + nums[mid][key]) / 2;
};

const createColors = (number): Array<string> => {
    const min = 0;
    const max = 200;
    const diff = max - min;
    const interval =
        number === 1 ? 0 :
            Math.floor(diff / (number - 1));
    const colorsArray = []; for (let i = 0; i < number; i++) {
        const n = min + interval * i;
        const rgb = `rgba(${n},${n},${n}, .2)`;
        colorsArray.unshift(rgb);
    } return colorsArray;
};

export {
    doWork
}