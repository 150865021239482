<form>
  <div class="modal-scroll">
    <div class="modal-header">
      <h4 class="modal-title" translate> EDIT_PREFERENCES </h4>
    </div>
    <div class="modal-body">
      <ngx-loading [show]="showSpinner"></ngx-loading>
      <ng-container *ngIf="!showSpinner && !error">
        <table class="table table-hover table-borderless-th dataTable">
          <thead>
            <tr>
              <td class="header-first-table" colspan="2" translate> CAMPAIGN_FILTERS </td>
            </tr>
            <tr class="header-second-table">
              <td width="70%" translate> OPTIONS </td>
              <td width="30%" translate> DISPLAY </td>
            </tr>
          </thead>
          <tr class="center">
            <td translate>CAMPAIGN_TYPE</td>
            <td>
              <select class="form-control" [(ngModel)]="workspace.preferences.filter.campaignType" name="filterCampaignType" [compareWith]="compare">
                <option [ngValue]="campaignType" *ngFor="let campaignType of typeCampaigns" translate>
                  {{ campaignType.name }}
                </option>
              </select>
            </td>
          </tr>
        </table>
        <table class="table table-hover table-borderless-th dataTable">
          <thead>
            <tr>
              <td class="header-first-table" colspan="2" translate> CREATION_CAMPAIGN </td>
            </tr>
            <tr class="header-second-table">
              <td width="70%" translate> OPTIONS </td>
              <td width="30%" translate> DISPLAY </td>
            </tr>
          </thead>
          <tbody *ngFor="let optionProject of listArraysPreference.projects">
            <tr class="center">
              <td translate> {{ preferenceTrad.projects[optionProject.key].name }} </td>
              <td>
                {{ 'NO' | translate }}
                <dna-switch [isSelected]="optionProject.value.isActive" (onBooleanChanged)="clickOptions(workspace.preferences.projects, optionProject.key, 'isActive', $event)">
                </dna-switch>
                {{ 'YES' | translate }}
              </td>
            </tr>
          </tbody>
          <tr class="center">
            <td translate>CAMPAIGN_TYPE</td>
            <td>
              <select class="form-control" [(ngModel)]="workspace.preferences.campaignType" name="campaignType" [compareWith]="compare">
                <option [ngValue]="campaignType" *ngFor="let campaignType of typeCampaigns" translate>
                  {{ campaignType.name }}
                </option>
              </select>
            </td>
          </tr>
        </table>
        <table class="table table-hover table-borderless-th dataTable">
          <thead>
            <tr>
              <td class="header-first-table" colspan="3" translate> FORMULAS </td>
            </tr>
            <tr class="header-second-table">
              <td width="60%" translate> OPTIONS </td>
              <td width="20%" translate> DISPLAY </td>
              <td width="20%" translate> DEFAULT_VALUE </td>
            </tr>
          </thead>
          <tbody *ngFor="let optionFormula of listArraysPreference.formulas">
            <tr class="center">
              <td translate> {{ preferenceTrad.formulas[optionFormula.key].name }} </td>
              <td>
                {{ 'NO' | translate }}
                <dna-switch [isSelected]="optionFormula.value.isActive" (onBooleanChanged)="clickOptions(workspace.preferences.formulas, optionFormula.key, 'isActive', $event)">
                </dna-switch>
                {{ 'YES' | translate }}
              </td>
              <td>
                <select *ngIf="optionFormula.key === 'applicationZone' && optionFormula.value.isActive" class="form-control" [(ngModel)]="workspace.preferences.formulas.applicationZone.selectedValue"
                  [compareWith]="compareKey" (ngModelChange)="workspace.preferences.formulas.applicationZone.selectedValue = $event" name="applicationZone">
                  <option [ngValue]="zone" *ngFor="let zone of applicationZones" translate>
                    {{ zone.value }}
                  </option>
              </select>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table table-hover table-borderless-th dataTable">
          <thead>
            <tr>
              <td class="header-first-table" colspan="3" translate> USERS </td>
            </tr>
            <tr class="header-second-table">
              <td width="60%" translate> OPTIONS </td>
              <td width="20%" translate> DISPLAY </td>
              <td width="20%" translate> DEFAULT_VALUE </td>
            </tr>
          </thead>
          <tbody *ngFor="let optionUser of listArraysPreference.users">
            <tr class="center">
              <td translate> {{ preferenceTrad.users[optionUser.key].name }} </td>
              <td>
                {{ 'NO' | translate }}
                <dna-switch [isSelected]="optionUser.value.isActive" (onBooleanChanged)="clickOptionsUsers(workspace.preferences.users, optionUser.key, 'isActive', $event)">
                </dna-switch>
                {{ 'YES' | translate }}
              </td>
              <td>
                {{ 'NO' | translate }}
                <dna-switch [disabled]="optionUser.key === 'collaborative' && optionUserVolunteer.value.defaultValue" [isSelected]="optionUser.value.defaultValue"
                  (onBooleanChanged)="clickOptionsUsers(workspace.preferences.users, optionUser.key, 'defaultValue', $event)">
                </dna-switch>
                {{ 'YES' | translate }}
              </td>
            </tr>
          </tbody>
        </table>
        <table [ngClass]="{'frame-error':listSelectedViews?.length == 0}" class="table table-hover table-borderless-th dataTable">
          <thead>
            <tr>
              <td class="header-first-table" colspan="2" translate> VIEWS </td>
            </tr>
            <tr>
              <td class="error" *ngIf="listSelectedViews?.length == 0" colspan="2" translate> DISPLAY_ERROR_VIEW </td>
            </tr>
            <tr class="header-second-table">
              <td width="70%" translate> VIEWS </td>
              <td width="30%" translate> DISPLAY </td>
            </tr>
          </thead>
          <tbody *ngFor="let view of listArraysPreference.views">
            <tr class="center">
              <td translate> {{ preferenceTrad.views[view.key].name }} </td>
              <td>
                {{ 'NO' | translate }}
                <dna-switch [isSelected]="view.value.isActive" (onBooleanChanged)="clickOptionsViews(workspace.preferences.views, view.key, 'isActive', $event)">
                </dna-switch>
                {{ 'YES' | translate }}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table table-hover table-borderless-th dataTable">
          <thead>
            <tr>
              <td class="header-first-table" colspan="2" translate> DEFAULT_VIEW </td>
            </tr>
            <tr class="header-second-table">
              <td translate width="50%"> </td>
              <td translate width="50%"> CHOOSE_DEFAULT_VIEW </td>
            </tr>
          </thead>
          <tbody>
            <tr class="center">
              <td translate> DEFAULT_VIEW </td>
              <td>
                <select class="form-control" [ngModel]="workspace.preferences.defaultView" (ngModelChange)="workspace.preferences.defaultView = $event"
                  name="defaultView">
                  <option [value]="preferenceTrad.views[view.key].viewType" *ngFor="let view of listSelectedViews" translate>
                    {{ preferenceTrad.views[view.key].name }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table table-hover table-borderless-th dataTable">
          <thead>
            <tr>
              <td class="header-first-table" colspan="2" translate> GRAPH </td>
            </tr>
            <tr class="header-second-table">
              <td translate width="70%"> GRAPH</td>
              <td translate width="30%"> DISPLAY </td>
            </tr>
          </thead>
          <tbody *ngFor="let graph of graphs">
            <tr class="center">
              <td translate> {{ graph.name }} </td>
              <td>
                {{ 'NO' | translate }}
                <dna-switch [isSelected]="workspace.preferences.graphs[graph.id]" (onBooleanChanged)="workspace.preferences.graphs[graph.id] = !workspace.preferences.graphs[graph.id]">
                </dna-switch>
                {{ 'YES' | translate }}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table table-hover table-borderless-th dataTable">
          <thead>
            <tr>
              <td class="header-first-table" colspan="3" translate> PARAMETERS </td>
            </tr>
            <tr class="header-second-table">
              <td width="60%" translate> OPTIONS </td>
              <td width="20%" translate> DISPLAY </td>
              <td width="20%" translate> DEFAULT_VALUE </td>
            </tr>
          </thead>
          <tbody *ngFor="let option of listArraysPreference.parameters">
            <tr class="center">
              <td translate> {{ preferenceTrad.parameters[option.key].name }} </td>
              <td>
                <ng-container *ngIf="option.value.isActive !== undefined">
                  {{ 'NO' | translate }}
                  <dna-switch [isSelected]="option.value.isActive" (onBooleanChanged)="clickOptions(workspace.preferences.parameters, option.key, 'isActive', $event)">
                  </dna-switch>
                  {{ 'YES' | translate }}
                </ng-container>
              </td>
              <td>
            </tr>
          </tbody>
        </table>
        <table class="table table-hover table-borderless-th dataTable">
          <thead>
            <tr>
              <td class="header-first-table" colspan="2" translate> VOLUNTEERS </td>
            </tr>
            <tr class="header-second-table">
              <td width="70%" translate> OPTIONS </td>
              <td width="30%" translate> DISPLAY </td>
            </tr>
          </thead>
          <tbody>
            <tr class="center">
              <td translate> DISPLAY_HORS_ARCS_VOLUNTEERS </td>
              <td>
                {{ 'NO' | translate }}
                <dna-switch [isSelected]="this.workspace.preferences.displayNonArcsVolunteers" (onBooleanChanged)="this.workspace.preferences.displayNonArcsVolunteers = !this.workspace.preferences.displayNonArcsVolunteers">
                </dna-switch>
                {{ 'YES' | translate }}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table table-hover table-borderless-th dataTable">
          <thead>
            <tr>
              <td class="header-first-table" colspan="2" translate> CONFORT_PANEL </td>
            </tr>
            <tr class="header-second-table">
              <td width="70%" translate> OPTIONS </td>
              <td width="30%" translate> DISPLAY </td>
            </tr>
          </thead>
          <tbody>
            <tr class="center">
              <td translate> EXPORT_CONFORT </td>
              <td>
                {{ 'NO' | translate }}
                <dna-switch [isSelected]="this.workspace.preferences.displayPanelConfort" (onBooleanChanged)="this.workspace.preferences.displayPanelConfort = !this.workspace.preferences.displayPanelConfort">
                </dna-switch>
                {{ 'YES' | translate }}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table table-hover table-borderless-th dataTable">
          <thead>
            <tr>
              <td class="header-first-table" colspan="2" translate> ONE_PAGER_CHARAC </td>
            </tr>
          </thead>
          <tbody>
            <tr class="center">
              <td translate> ONE_PAGER_CHARAC_DISPLAY </td>
              <td>
                {{ 'NO' | translate }}
                <dna-switch [isSelected]="this.workspace.preferences.displayOnePagerCharac" (onBooleanChanged)="this.workspace.preferences.displayOnePagerCharac = !this.workspace.preferences.displayOnePagerCharac">
                </dna-switch>
                {{ 'YES' | translate }}
              </td>
            </tr>
            <tr class="center">
              <td translate> SELECT_CARAC_DESC </td>
              <td>
                <button class="btn btn-primary" type="button" (click)="chooseCaracterisation()" translate>
                  SELECT_CARAC
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <div *ngIf="!showSpinner && error" class="ibox float-e-margins">
        <div class="ibox-title">
          <div class="row">
            <div class="col-12 col-md-4 my-auto">
              <h5>{{ "ERROR" | translate }}</h5>
            </div>
          </div>
        </div>
        <dna-error-page (onClickReload)="init()"></dna-error-page>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="submit" (click)="save()">{{"VALIDATE" | translate}}</button>
    <button class="btn btn-default" type="button" (click)="cancel()">{{"CANCEL" | translate}}</button>
  </div>
</form>
