
import {finalize} from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  Output,
  SimpleChanges,
  EventEmitter
} from '@angular/core';
import * as pbi from 'powerbi-client';
import {
  PowerBIService
} from '../powerbi.service';
import {
  DNATranslateService
} from '../../services/translate.service';
import {
  TypeDataviz
} from '../../../types';

const config = {
  mono: {
    tableFilter: "EVALUATIONS",
    basicFilterLanguage: "EVA - Language name",
    basicFilterCampaigns: "CPG_cod_campaign",
    basicFilterProtocol: "QUE_cod_protocol"
  },
  multi: {
    tableFilter: "INTER_STUDIES",
    basicFilterLanguage: "INTER_STD_language",
    basicFilterCampaigns: "INTER_STD_code",
    basicFilterProtocol: "INTER_STD_questionnaire"
  }
};

@Component({
  selector: 'dna-display-powerbi-data',
  templateUrl: './powerBIViewer.component.html',
  styleUrls: ['./powerBIViewer.component.less']
})

export class PowerBIViewerComponent implements OnInit {
  @Input() id: string;
  @Input() type: string;
  @Input() embedUrl: string;
  @Input() idCampaigns: string[];
  @Input() idWorkflow: string;
  @Input() idDashboard?: string;
  @Output() pagesEvent: EventEmitter<any> = new EventEmitter();
  @Output() displayButtonsEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() isMulti;
  data: pbi.Embed;
  idContainer: string;
  showSpinner: boolean = false;
  typeDataviz: typeof TypeDataviz = TypeDataviz;

  constructor(
    private powerbiService: PowerBIService,
    private translateService: DNATranslateService
  ) { }

  ngOnInit() { }

  ngOnChanges(object: SimpleChanges) {
    this.showData();
  }

  showData() {
    this.showSpinner = true;
    this.powerbiService.getUserAuthenticationToken().pipe(
      finalize(() => this.showSpinner = false))
      .subscribe(
      data => {
        this.configuration(data.accessToken, data.expiresOn);
        this.displayButtonsEvent.emit(true);
      });
  }

  configuration(token: string, expiresOn: number) {
    let config: any = {
      type: this.type, // 'report', 'dashboard', 'tile', 'visual'
      accessToken: token,
      embedUrl: this.embedUrl,
      filters: this.createConfigurationFilters(),
      dashboardId: this.idDashboard,
      pageView: "fitToWidth", // fitToWidth, oneColumn, actualSize
      id: this.id,
      tokenType: pbi.models.TokenType.Aad,
      // Configuration settings work only for reports (not for dashboards)
      settings: {
        background: pbi.models.BackgroundType.Transparent,
        customLayout: {
          displayOption: pbi.models.DisplayOption.FitToPage
        },
        filterPaneEnabled: false,
        layoutType: pbi.models.LayoutType.Custom,
        navContentPaneEnabled: false
      }
    };
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    let dataContainer = <HTMLElement>document.getElementById('containerData');
    if (this.type === this.typeDataviz.typeTile) {
      dataContainer.id = this.id;
    }
    powerbi.reset(dataContainer);
    this.data = powerbi.embed(dataContainer, config);
    //   Allows to remove the iframe border (report or dashboard)
    this.data.iframe.style.border = 'none';

    this.data.off('loaded');
    this.data.on('loaded', () => {
      // GetPages(): FOR REPORTS ONLY (Dashboards and tiles don't have pages)
      if (this.type === this.typeDataviz.typeReport) {
        (<pbi.Report>this.data).getPages().then((reportPages) => this.pagesEvent.emit(reportPages));
        (<pbi.Report>this.data).updateSettings({
          filterPaneEnabled: true,
          visualSettings: {
            visualHeaders: [
              {
                settings: {
                  visible: true
                }
              }
            ]
          }
        });
      }
      this.setTokenExpirationListener(expiresOn, 1);
    });
  }

  setTokenExpirationListener(tokenExpiration, minutesToRefresh) {
    // get current time
    let currentTime = Date.now();
    let expiration = tokenExpiration;
    let safetyInterval = minutesToRefresh * 60 * 1000;

    // time until token refresh in milliseconds
    let timeout = expiration - currentTime - safetyInterval;

    // if token already expired, generate new token and set the access token
    if (timeout <= 0) {
      console.log("Updating PowerBI embed token.");
      this.powerbiService.getUserAuthenticationToken().pipe(
        finalize(() => this.showSpinner = false))
        .subscribe(
        data => {
          this.data.setAccessToken(data.accessToken);
          this.setTokenExpirationListener(data.expiresOn, 1);
        });

    }
    // set timeout so minutesToRefresh minutes before token expires, token will be updated
    else {
      console.log("PowerBI embed token will be updated in " + timeout + " milliseconds.");
      setTimeout(() => {
        this.setTokenExpirationListener(tokenExpiration, 1);
      }, timeout);
    }
  }

  createConfigurationFilters(): any[] {
    let filters = [];
    let tableFilter = this.isMulti ? config.multi.tableFilter : config.mono.tableFilter;
    if (this.type === this.typeDataviz.typeReport) {
      const basicFilterLanguage: pbi.models.IBasicFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: tableFilter,
          column: this.isMulti ? config.multi.basicFilterLanguage : config.mono.basicFilterLanguage
        },
        operator: "In",
        values: [this.translateService.getLanguage()],
        filterType: pbi.models.FilterType.Basic
      };
      filters.push(basicFilterLanguage);

      const basicFilterCampaigns: pbi.models.IBasicFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: tableFilter,
          column: this.isMulti ? config.multi.basicFilterCampaigns : config.mono.basicFilterCampaigns
        },
        operator: "In",
        values: this.idCampaigns,
        filterType: pbi.models.FilterType.Basic
      };
      filters.push(basicFilterCampaigns);

      if (this.idWorkflow) {
        const basicFilterProtocol: pbi.models.IBasicFilter = {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: tableFilter,
            column: this.isMulti ? config.multi.basicFilterProtocol : config.mono.basicFilterProtocol
          },
          operator: "In",
          values: [this.idWorkflow],
          filterType: pbi.models.FilterType.Basic
        };
        filters.push(basicFilterProtocol);
      }

    }
    return filters;
  }

}
