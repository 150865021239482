
import {takeUntil} from 'rxjs/operators';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  AfterViewInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnDestroy,
  ViewContainerRef
} from '@angular/core';
import { chartsConfigs } from '../../../configs';
import { Subject } from 'rxjs';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let ColorAxis = require('highcharts/modules/coloraxis');

const Highcharts = require('highcharts');
require('highcharts/modules/boost')(Highcharts);
noData(Highcharts);
More(Highcharts);
ColorAxis(Highcharts);
Boost(Highcharts)
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: '[dna-single-inverted-barchart]',
  templateUrl: './single-inverted-barchart.component.html',
  styleUrls: ['./single-inverted-barchart.component.scss']
})
export class SingleInvertedBarchartComponent implements AfterViewInit, OnDestroy {
  @ViewChild('chart', { read: ElementRef }) chartElm: ElementRef;
  @Input() parameters: any;
  @Input() onOptionsChange: Subject<any>;

  public graph: any;
  private chartOptions: any;
  private chartInstance: Highcharts.Chart;
  private series: Array<any>;
  private unsubsriber$ = new Subject<void>();

  constructor(
    private ref: ChangeDetectorRef,
  ) { }

  /**
     * upadtePlotLinesAndBandVisibilty
     * Method to hide/show grey zone feature for both xAxis & yAxis
     * @param chart : Highcharts.Chart : Rendered chart
     * @param parameters : any : Chart's parameters
     */
  private upadtePlotLinesAndBandVisibilty = (chart: Highcharts.Chart, parameters: any) => {

    for (let axis of chart.xAxis) {
      for (let plotBand of axis['plotLinesAndBands']) {
        if (parameters.plotOptions.grey_zone.enabled) {
          plotBand.hidden = false;
          plotBand.svgElem.show();
        } else {
          plotBand.hidden = true;
          plotBand.svgElem.hide();
        }
      }
    }

    for (let axis of chart.yAxis) {

      for (let plotBand of axis['plotLinesAndBands']) {
        if (parameters.plotOptions.grey_zone.enabled) {
          plotBand.hidden = false;
          plotBand.svgElem.show();
        } else {
          plotBand.hidden = true;
          plotBand.svgElem.hide();
        }
      }
    }
  };

  ngOnDestroy() {
    this.unsubsriber$.next();
    this.unsubsriber$.complete();
  }

  ngAfterViewInit() {
    this.series = this.parameters.series.map((serie: any) => {
      return {
        type: 'column',
        name: serie.label,
        color: serie.color,
        showInLegend: false,
        data: [serie.data]
      }
    });

    this.chartElm.nativeElement.focus();
    const chartOptions = {
      series: this.series
    };

    this.chartOptions = Object.assign(chartsConfigs['commons']['BarChartMSRepart'].configs, chartOptions);
    this.chartOptions.yAxis = Object.assign(this.chartOptions.yAxis, this.parameters.configurations);
    // INIT SHOW_GREY_ZONE VALUE
    this.chartOptions.plotOptions.grey_zone = this.parameters.other.grey_zone;

    this.chartInstance = Highcharts.chart(this.chartElm.nativeElement, this.chartOptions);
    this.upadtePlotLinesAndBandVisibilty(this.chartInstance, this.chartOptions);
    this.onOptionsChange.pipe(
      takeUntil(this.unsubsriber$))
      .subscribe({
        next: (params: any) => {
          try {
            this.chartOptions.plotOptions.column.dataLabels.enabled = params.plotOptions.column.dataLabels.enabled;
            this.chartOptions.plotOptions.grey_zone = params.plotOptions.grey_zone;
            this.chartOptions.series = this.series;
            this.chartInstance.update(this.chartOptions);
          } catch (e) { }
          this.upadtePlotLinesAndBandVisibilty(this.chartInstance, params);
        }
      });
    this.ref.checkNoChanges();
  }

}
