import { DNAComponent } from '../../../types';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dna-input-text-config',
    templateUrl: './inputTextConfiguration.component.html',
    styleUrls: ['./inputTextConfiguration.css']
})

export class InputTextConfigurationComponent implements OnInit {
    @Input() inactive: boolean;
    @Input() component: DNAComponent;

    constructor() { }

    ngOnInit() {}
}

