<span *ngIf="hasEvaluation && onlyStarted" class="cursor-pointer" [ngbTooltip]="completionUsers">
    {{usersStarted}}
</span>
<span *ngIf="hasEvaluation && !onlyStarted" class="cursor-pointer" [ngbTooltip]="usersFinished">
    {{usersFinish}}/{{totalUsers}}
</span>

<ng-template #completionUsers>
    <ul class="tooltip-list">
        <li>{{ 'STARTED' | translate }} : {{ usersStarted }}</li>
    </ul>
</ng-template>

<ng-template #usersFinished>
    <ul class="tooltip-list">
            <li>{{ 'ENDED' | translate }} : {{ usersFinish }}</li>
        <li>{{ 'VOLUNTEERS_EVALUATED' | translate }} : {{ totalUsers }}</li>
    </ul>
</ng-template>