export default {
    "type" : "linechart",
    "configs" : {
        "compute" : {
            "method" : "medianRef",
            "key" : "label"
        },
        "drilldown": {
            "enabled": false
        },
        "title" : {
            "text" : "",
            "margin": 50
        },
        "subtitle" : {
            "text" : ""
        },
        "chart": {
            "inverted": true
        },
        "yAxis" : {
            "max" : 3,
            "min": -3,
            "tickInterval" : 1,
            "tickmarkPlacement": 0,
        },
        "plotOptions" : {
            "attributes_blocks": {
                "enabled": false
            },
            "default_language" : {
                "enabled" : false
            },
            "series": {
                "pointPlacement": "on"
            }
        },
        "xAxis": {
            "tickmarkPlacement": "on",
            "gridLineWidth": 1,
        }
    },
}