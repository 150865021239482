import { EventEmitter, Injectable } from '@angular/core';

import { CampaignStates, Filter, FilterSchedule, Hub, States } from '../../types';
import { ReferenceTypeService } from './reference-type.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  isFirstPassage: boolean = true;
  filterCampaignChanged: EventEmitter<any> = new EventEmitter();
  filter: Filter = null;

  constructor(private referenceTypeService: ReferenceTypeService) {
    this.filter = this.generateFilter();
    this.filterUntouched = this.generateFilter();
  }

  generateFilter(): Filter {
    let filter: Filter = {
      blocks: {
        states: [States.Draft, States.Published, States.Suspended],
        displayFavoritesOnly: false,
        displayTestsOnly: false,
        name: '',
        pageIndex: 1,
        numberOfObjectsPerPage: 10,
        orderBy: 'updated_on',
        order: 'DESC'
      },
      campaignMulti: {
        formulas: [],
        workflows: [],
        users: [],
        name: '',
        hubs: this.referenceTypeService.getHubs(),
        startDate: 0,
        endDate: 0,
        page: 1,
        limit: 10,
        orderBy: 'updated_on',
        order: 'DESC'
      },
      components: {
        displayFavoritesOnly: false,
        searchText: '',
      },
      dashboard: {
        areMyStudies: false,
        displayDemandsNotLinked: false,
        hubs: this.referenceTypeService.getHubs(),
        name: '',
        // numberOfObjectsPerPage: 10,
        // pageIndex: 1,
        startDate: 0,
        endDate: 0,
        states: [
          CampaignStates.Draft,
          CampaignStates.Suspended,
          CampaignStates.Published,
          CampaignStates.Archived,
          CampaignStates.Abandoned,
          CampaignStates.Finished
        ],
        studyType: this.referenceTypeService.getTypeCampaign(),
        typeEvaluation: this.referenceTypeService.getTypeEvaluation(),
        users: [],
      },
      panelists: {
        page: 1,
        limit: 10,
        hub: this.referenceTypeService.getHubs(),
        fiche: 'ALL_TIMELINE_TYPES',
        startDate: 0,
        endDate: 0,
        orchestra: '',
        requestNumber: '',
        panelistNumber: '',
        orderBy: 'created_on',
        order: 'DESC'
      },
      schedules: {
        actiview: [],
        synergy: '',
        byUser: false,
        track: '',
        formulas: [],
        workflows: [],
        users: [],
        displayFavoritesOnly: false,
        displayTemplate: false,
        hubs: this.referenceTypeService.getHubs(),
        name: '',
        page: 1,
        limit: 10,
        startDate: undefined,
        endDate: 0,
        states: [States.Draft, States.Published, States.Suspended],
        studyType: this.referenceTypeService.getTypeCampaignFilter(),
        typeEvaluation: this.referenceTypeService.getTypeEvaluation(),
        target: {
          abondanceCheveux: [],
          alopecieFemme: [],
          alopecieHomme: [],
          baseNaturelle: [],
          casse: [],
          categorieNuance: [],
          cheveuxBlancs: [],
          cheveuxBlancsPourcentDevant: [],
          cheveuxBlancsPourcentNuque: [],
          cheveuxNaturels: [],
          cosmetiqueEquilibre: [],
          degreFrisure: [],
          durete: [],
          frisottis: [],
          grosseur: [],
          hauteurTonColoration: [],
          hommePortBarbe: [],
          length: [],
          natureCheveux: [],
          natureCuirChevelu: [],
          regulariteFrisure: [],
          sensibilisation: [],
          tinctorialEquilibre: [],
          cheeksBlemish: [],
          cheeksRedness: [],
          dilatedPores: [],
          eyelashesCurve: [],
          eyelashesDensity: [],
          eyelashesLength: [],
          facialBrightness: [],
          facialSkin: [],
          lipsSize: [],
          lipsThickness: [],
          phototype: [],
          skinTone: [],
          skinType: [],
          upperEyelidsRelaxation: [],
          upperEyelidsSurfaceArea: [],
          wrinklesUnderEye: [],
          darkCircles: []
        },
        protocol: {
          applicationAreas: [],
          applicationModes: [],
          applicationTypes: [],
          protocol_methods: [],
          methods: [],
          substrates: [],
          scales: [],
          studies: [],
          studyTypes: [],
          tests: [],
          timepoints: '',
          timepointsInterval: ''
        },
        orchestra: [],
        bridge: [],
        timepointsNb: 0,
        timepointsName: [],
        isExport: false,
        orderBy: 'updated_on',
        order: 'DESC'
      },
      trainings: {
        states: [States.Draft, States.Published, States.Suspended],
        displayFavoritesOnly: false,
        name: '',
        pageIndex: 1,
        numberOfObjectsPerPage: 10
      },
      feedbacks: {
        pageIndex: 1,
        numberOfObjectsPerPage: 10
      },
      translations: {
        pageIndex: 1,
        numberOfObjectsPerPage: 10
      },
      workflows: {
        displayFavoritesOnly: false,
        displayTestsOnly: false,
        hubs: this.referenceTypeService.getHubs(),
        name: '',
        numberOfObjectsPerPage: 10,
        pageIndex: 1,
        states: [States.Draft, States.Published, States.Suspended],
        orderBy: 'updated_on',
        order: 'DESC'
      },
    };

    return filter;
  }

  filterUntouched: Filter = _.cloneDeep(this.filter);

  initializeFilterProtocol(): any {
    this.filter.schedules.protocol = {
      applicationAreas: [],
      applicationModes: [],
      applicationTypes: [],
      protocol_methods: [],
      methods: [],
      substrates: [],
      scales: [],
      studies: [],
      studyTypes: [],
      tests: [],
      timepoints: '',
      timepointsInterval: '',
    };

    return this.filter.schedules.protocol;
  }

  initializeFilterTarget(): any {
    this.filter.schedules.target = {
      abondanceCheveux: [],
      alopecieFemme: [],
      alopecieHomme: [],
      baseNaturelle: [],
      casse: [],
      categorieNuance: [],
      cheveuxBlancs: [],
      cheveuxBlancsPourcentDevant: [],
      cheveuxBlancsPourcentNuque: [],
      cheveuxNaturels: [],
      cosmetiqueEquilibre: [],
      degreFrisure: [],
      durete: [],
      frisottis: [],
      grosseur: [],
      hauteurTonColoration: [],
      hommePortBarbe: [],
      length: [],
      natureCheveux: [],
      natureCuirChevelu: [],
      regulariteFrisure: [],
      sensibilisation: [],
      tinctorialEquilibre: [],
      cheeksBlemish: [],
      cheeksRedness: [],
      dilatedPores: [],
      eyelashesCurve: [],
      eyelashesDensity: [],
      eyelashesLength: [],
      facialBrightness: [],
      facialSkin: [],
      lipsSize: [],
      lipsThickness: [],
      phototype: [],
      skinTone: [],
      skinType: [],
      upperEyelidsRelaxation: [],
      upperEyelidsSurfaceArea: [],
      wrinklesUnderEye: [],
      darkCircles: []
    };

    return this.filter.schedules.target;
  }

  getDateSearchCampaign() {
    let date = new Date();
    date.setMonth(date.getMonth() - 3);
    return date;
  }

  getFilter(hub?: Hub): Filter {
    if (hub && this.isFirstPassage) {
      this.filter.dashboard.hubs = [hub];
      this.filter.schedules.hubs = [hub];
      this.filter.workflows.hubs = [hub];
      this.filter.panelists.hub = [hub];
      this.filter.campaignMulti.hubs = [hub];
      this.filter.schedules.startDate = this.getDateSearchCampaign();
      this.isFirstPassage = false;
    }
    return this.filter;
  }

  getFilterMulti() {
    const filterS = _.cloneDeep(this.filterUntouched.schedules);
    filterS.states = [CampaignStates.Archived, CampaignStates.Finished, CampaignStates.Published, CampaignStates.Draft, CampaignStates.Suspended];
    return filterS;
  }

  resetFilterCampaign(): FilterSchedule {
    let resetFilters = _.cloneDeep(this.filterUntouched.schedules);
    //bug 23511 le filtre studyType est initialisé en fonction du workspace donc on doit relancer la méthode.
    resetFilters.studyType = this.referenceTypeService.getTypeCampaignFilter();
    return resetFilters;
  }

  resetFilterService() {
    this.filter.schedules = _.cloneDeep(this.filterUntouched.schedules);
    //bug 23511 le filtre studyType est initialisé en fonction du workspace donc on doit relancer la méthode.
    this.filter.schedules.studyType = this.referenceTypeService.getTypeCampaignFilter();
    this.isFirstPassage = true;
  }

  resetFilterPanelists(): Filter {
    return _.cloneDeep(this.filterUntouched.panelists);
  }

  resetFilterCampaignsExport(hub: Hub): FilterSchedule {
    let filter = _.cloneDeep(this.filterUntouched.schedules);
    filter.hubs = [hub];
    filter.studyType = this.referenceTypeService.getTypeCampaign();
    filter.states = [CampaignStates.Published, CampaignStates.Finished, CampaignStates.Archived];
    filter.startDate = 0;
    filter.isExport = false;
    filter.name = '';
    return filter;
  }

  resetFilterCampaignMulti(): Filter {
    return _.cloneDeep(this.filterUntouched.campaignMulti);
  }

  onfilterCampaignChanged() {
    return this.filterCampaignChanged;
  }

  updateFilterCampaign() {
    return this.filterCampaignChanged.emit();
  }
}
