export default {
  type: 'columnchart',
  configs: {
    translations: {
      yAxis: {
        title: {
          english: 'Average score',
          french: 'Score moyen',
        },
      },
    },
    compute: {
      method: 'confortWithPvalue',
      key: 'key',
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    chart: {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    yAxis: {
      title: {
        align: 'high',
      },
      tickInterval: 1,
    },
    plotOptions: {
      attributes_blocks: {
        enabled: true,
      },
      default_language: {
        enabled: false,
      },
      show_column_zero: {
        enabled: true,
      },
      series: {
        dataLabels: {
          enabled: false,
        },
      },
    },
  },
};
