<ngx-loading [show]="showSpinner"></ngx-loading>
<form *ngIf="!showSpinner" [formGroup]="editBlockForm" (ngSubmit)="onSubmit(editBlockForm)">
  <h1 class="mb-4" id="banner-title" translate>
    <span translate>BLOCKS</span>
  </h1>
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" translate>DASHBOARD</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/workflows" translate>LIBRARY_OF_WORKFLOWS</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/workflows', workflow.id]">
          <dna-translate [fieldToTranslate]="workflow.name"> </dna-translate>
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>EDIT</li>
      <li class="breadcrumb-item active" aria-current="page" translate>BLOCK</li>
    </ol>
  </nav>
  <div class="d-flex flex-row gap-1 justify-content-end" id="banner-actions">
    <button class="btn btn-primary mb-4" [disabled]="!editBlockForm.dirty"
      type="submit">
      <span translate>VALIDATE</span>
    </button>
    <button class="btn btn-primary mb-4" type="button" (click)="cancel()">
      <span translate>CANCEL</span>
    </button>
  </div>
  <div class="block">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5 translate>WORKFLOW</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <div formArrayName="blocks">
            <table class="table table-hover table-borderless-th">
              <thead>
                <tr>
                  <th>#</th>
                  <th translate>NAME</th>
                  <th translate>MANDATORY</th>
                  <th>
                    <span ngbTooltip="{{ 'DELAY_START' | translate }}" translate>DELAY_START_TITLE</span>
                  </th>
                  <th>
                    <span ngbTooltip="{{ 'DELAY_END' | translate }}" translate>DELAY_END_TITLE</span>
                  </th>
                  <th translate>CONTEXTUAL_EVAL</th>
                  <th translate>CONTEXTUAL_FORMULA</th>
                  <!-- <th translate>ORDER</th>
                  <th translate>ACTION</th> -->
                </tr>
              </thead>

              <tbody>
                <tr class="cursor-pointer"
                  *ngFor="let blockOfForm of editBlockForm.get('blocks').controls ; let indexBlock = index"
                  [formGroupName]="indexBlock">
                  <td>{{indexBlock + 1}}</td>
                  <td>
                    <span>{{blockOfForm.controls.name.value}}</span>
                  </td>
                  <td>
                    <div class="form-group">
                      <label class="switch">
                        <input class="form-control" formControlName="isMandatory" type="checkbox">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input class="form-control" formControlName="delayStart" type="number">
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input class="form-control" formControlName="delayEnd" type="number">
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <label class="switch">
                        <input class="form-control" formControlName="contextualEval" type="checkbox"
                          (click)="setContextual(blockOfForm.controls.contextualEval, ['contextualEval', 'contextualFormula'], indexBlock)">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <label class="switch">
                        <input class="form-control" formControlName="contextualFormula" type="checkbox"
                          (click)="setContextual(blockOfForm.controls.contextualFormula, ['contextualFormula', 'contextualEval'], indexBlock)">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
