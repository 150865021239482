import {Chart} from "../chart.model";
import * as medianWithSemAndPvalue from './methods/medianWithSemAndPvalue';
import {XAxisOptions} from "highcharts";
import {Table} from "../table/table.model";
import {KineticChartOptions} from "./kinetic-options.model";
import {ChartDefaultLanguage, ChartDoWorker, ChartFormatter, ChartLang, KineticInputInterface, KineticInputType} from "./models";
import {Languages} from '../../enums';

const mapSettingsKey: Map<string, string> = new Map();
mapSettingsKey.set( 'scale', 'yAxis' );
mapSettingsKey.set( 'show_attributes_blocks', 'show_attributes_blocks' );
mapSettingsKey.set( 'show_default_language', 'show_default_language' );
mapSettingsKey.set( 'show_data_label', 'show_data_label' );

export class kineticChart extends Chart {

    private kinetic: ChartDoWorker & ChartFormatter & ChartDefaultLanguage & ChartLang;

    constructor ( _parameters?: any, _data?: any, _lang?: string, _filters?: any ) {
        super( _parameters, _data, _lang, _filters );
        if( _data && _data.data && _data.data.length ) {
            this.generateParametersAssignment( this.parameters );
            this.generateDataAssignment( this.data, this.lang );
            this.tableRawData = this.formatRawData( _data, _lang );
        }
        this.loadChartTitles( this.parameters, _lang );
    }

    private generateParametersAssignment ( parameters ) {
        // CANT SEE THE PURPOSE HERE, REMOVE IF NOT NEEDED
        Object.assign( {...this._baseParameters, parameters} );

        Object.keys( {...this._baseParameters} ).forEach( ( k ) => {
            this.parameters[ k ] = Object.assign( {...this._baseParameters[ k ]}, parameters[ k ] );
        } );
        Object.keys( {...parameters} ).forEach( ( k ) => {
            this.parameters[ k ] = Object.assign( {...this.parameters[ k ]}, parameters[ k ] );
        } );
    };

    public static mapSettings: Map<string, string> = mapSettingsKey;

    public buildGraphOptions = ( options ) => {
        const {title, subtitle, xAxis, yAxis, series, plotOptions, ...rest} = options;
        this.options = new KineticChartOptions( options, this.onOptionsChanges );
    };

    protected formatData = ( data: KineticInputType, lang: string ) => {
        if( this.kinetic ) {
            this.kinetic.lang = lang;
        } else {
            this.kinetic = medianWithSemAndPvalue.doWork( data, this.routines, lang );
            this.kinetic.showAttributeBlock = this.parameters.plotOptions.attributes_blocks.enabled;
            this.kinetic.defaultLanguage = this.parameters.plotOptions.default_language.enabled ? Languages.Default : null;
            const options = this.kinetic.getOptions();
            this.parameters.series = this.kinetic.getSeries();
            this.parameters.xAxis.labels = ( options.xAxis as XAxisOptions ).labels;
            this.parameters.xAxis.tickPositions = ( options.xAxis as XAxisOptions ).tickPositions;
            if( ( options.xAxis as XAxisOptions ).plotLines ) {
                this.parameters.xAxis.plotLines = ( options.xAxis as XAxisOptions ).plotLines;
            }
        }
    };

    protected formatRawData = ( data: KineticInputInterface, lang: string ): {
        header: Array<any>;
        body: Array<any>;
    } => {
        try {
            const tableChart = new Table( {}, {data: this.kinetic.getRawTableData()}, lang );
            return tableChart.parameters.transformedHTMLData;
        } catch( e ) {
            return {
                header: [],
                body: []
            };
        }
    };

    protected generateDataAssignment = ( data: KineticInputType, lang: string ) => {
        this.formatData( data, lang );
        //this.parameters.xAxis.plotLines = (options.xAxis as XAxisOptions).plotLines;
        if( this.chart ) this.updateChartTranslations( this.chart, this.parameters, this.lang );
    };

    protected setParamsToAxis = () => {

    };

    private onOptionsChanges = ( options ) => {
        const routinesMapping: Map<string, any> = new Map();
        let gotColorChange = false;

        if( this.routines.routines ) {
            this.routines.routines.forEach( r => {
                routinesMapping.set( r.name, r );
            } );
        }

        let fontOptions: any;

        for( let o in options ) {
            if( o === 'general' ) {
                const {font, ...rest} = options[ o ];
                Object.assign( this.parameters, rest );
                fontOptions = font;
            } else {
                for( let e in options[ o ] ) {
                    if( Array.isArray( options[ o ][ e ] ) ) {
                        options[ o ][ e ].forEach( x => {
                            Object.assign( this.parameters[ e ].find( y => y.name === x.name ), x );
                            if( e === 'series' && routinesMapping.has( x.name ) ) {
                                gotColorChange = true;
                                routinesMapping.get( x.name ).color = x.color;
                            };
                        } );
                    } else {
                        Object.assign( this.parameters[ e ], options[ o ][ e ] );
                    }
                }
            }
        }

        const {yAxis, xAxis, ...parameters} = this.parameters;
        Object.assign( this.parameters, {...parameters} );
        Object.assign( this.parameters.yAxis, yAxis );
        Object.assign( this.parameters.xAxis, xAxis );
        if( gotColorChange ) this.custom.updateRoutines( this.routines.routines.map( x => x ) );

        this.updateshowBlockAttribute();
        this.updateShowDefaultLanguage();
        const chart = this.build();
        if( fontOptions ) this.updateChartFont( chart, fontOptions );
        this.updateChartTranslations( chart, this.parameters, this.lang );
    };

    private updateChartTranslations = ( chart: any, parameters: any, lang: string ) => {
        this.updateTitleTranslations( chart, lang, parameters );
        this.updateSubTitleTranslations( chart, lang, parameters );
        chart.redraw();
    };

    private updateshowBlockAttribute () {
        this.kinetic.showAttributeBlock = this.parameters.plotOptions.attributes_blocks.enabled;
    }

    private updateShowDefaultLanguage () {
        this.kinetic.defaultLanguage = this.parameters.plotOptions.default_language.enabled ? Languages.Default : null;
    }

    private updateChartFont = ( chart: Highcharts.Chart, option: {size: number, bold: boolean;} ): void => {
        try {
            chart.yAxis[ 0 ].update( {
            labels: {
                ...chart.yAxis[0].userOptions.labels,
                style: {
                fontSize: option.size + 'px',
                fontWeight: option.bold ? 'bold' : 'normal',
                }
            }
            }, false );
    
            chart.xAxis[ 0 ].update( {
            labels: {
                ...chart.xAxis[ 0 ].userOptions.labels,
                style: {
                fontSize: option.size + 'px',
                fontWeight: option.bold ? 'bold' : 'normal'
                }
            }
            }, false );
        } catch( e ) {
            console.error('error updating chart font, bullble');
        }
    }

}