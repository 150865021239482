<form #objectForm="ngForm" novalidate>
  <div class="modal-scroll">
    <div class="modal-header">
      <h3 class="modal-title">
        <dna-translate [fieldToTranslate]="currentWorkflowConfiguration.name"> </dna-translate>
      </h3>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <dna-workflow-edit-detail [isWorkflowEditable]="isWorkflowEditable"
          [workflowConfiguration]="currentWorkflowConfiguration" (loopCreated)="loopCreated()"
          (changeDisplay)="changeDisplay($event)"></dna-workflow-edit-detail>
      </div>
    </div>
    <div class="modal-footer">
      <div *ngIf="isWorkflowEditable; else nonEditable" class="d-flex flex-row gap-2">
        <button class="btn btn-primary" type="submit" (click)="submit(objectForm)" translate>VALIDATE</button>
        <button class="btn btn-default" type="button" (click)="cancel(objectForm)" translate>CANCEL</button>
      </div>
      <ng-template #nonEditable>
        <button class="btn btn-default" type="button" (click)="cancel(objectForm)" translate>CLOSE</button>
      </ng-template>
    </div>
  </div>
</form>
