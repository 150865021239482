import { UtilService } from '@app/shared/services/util.service';
import { DNAComponent } from '@app/types';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
    selector: 'dna-timer-config',
    templateUrl: './timerConfiguration.component.html',
    styleUrls: ['./timerConfiguration.css']
})

export class TimerConfigurationComponent implements OnInit {
    @Input() inactive: boolean;
    @Input() submitted: boolean;
    @Input() component: DNAComponent;
    @Output() updateError: EventEmitter<boolean> = new EventEmitter();

    constructor(private utilService: UtilService) { }

    ngOnInit() {
        this.sendError();
    }

    sendError() {
        const hasError = this.component.args.timer == null;
        this.updateError.emit(hasError);
    }
}
