import {
  AfterViewInit,
  Component,
  Input,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import {
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import { AccountableService } from '../../../../accountable.service';
import {
  Campaign, CampaignParameters, CampaignRawData, CampaignStates, Evaluation
} from '../../../../../types';
import {
  DNATranslateService
} from '../../../../../shared/services/translate.service';
import { CampaignService } from '../../../../../campaigns/campaigns.service';
import { catchError, tap, finalize } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { ApplicationInsightsService } from '../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-modal-attribution',
  templateUrl: './modal-attribution.component.html',
  styleUrls: ['./modal-attribution.component.less']
})

export class ModalAttributionComponent implements OnInit, AfterViewInit {

  @Input() campaign: CampaignParameters;

  campaignCopy: CampaignParameters;
  checkboxFormula: any;
  checkboxFormulaInit: any;
  currentLanguage: string;
  data: any;
  display: string;
  randomizationMatrix: Object = {};
  userInfos: Object = {};
  users: any[] = [];
  randomizationFileLoaded: boolean = false;
  campaignStates: typeof CampaignStates = CampaignStates;
  evaluations: Evaluation[];
  showSpinner: boolean = false;
  error: boolean = false;
  isCampaignPublished: boolean = false;
  evalStartingByFormula : any;
  idEvaluationList : any[] = [];
  initTime = performance.now();

  constructor(
    private accountableService: AccountableService,
    private activeModal: NgbActiveModal,
    private aiService: ApplicationInsightsService,
    private DNATranslate: DNATranslateService,
    private campaignService: CampaignService,
  ) { }

  ngOnInit() {
    this.showSpinner = true;
    this.error = false;
    this.campaignCopy = _.cloneDeep(this.campaign);
    this.isCampaignPublished = this.campaignCopy.state === CampaignStates.Published;
    this.users = this.createUserList(this.campaignCopy);
    this.users = _.sortBy(this.users, 'name');
    this.users.map(u => u.formula.sort((a, b) => a.sequential - b.sequential));
    this.display = 'attribution';
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.checkboxFormula = {};
    this.checkboxFormula = this.initCheckboxFormula(this.campaignCopy, this.checkboxFormula);
    this.checkboxFormulaInit = _.cloneDeep(this.checkboxFormula);


    let accountables = this.accountableService.accountables;
    if (this.users.length > 0 && this.users[0] && this.users[0].name) {
      accountables = _.cloneDeep(this.campaignCopy.users.accountables);
    }
    if(accountables && accountables.length){
      accountables.map(a => { this.userInfos[a.name] = a.key; });
    }

    this.campaignService.getCampaignRawData(this.campaignCopy.id).pipe(
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      tap((campaignRawData: CampaignRawData) => this.evaluations = this.campaignService.getEvaluations([campaignRawData])),
      finalize(() => this.showSpinner = false)
    ).subscribe(()=>{
      this.evalStartingByFormula = {}
      this.evalStartingByFormula = this.initEvaluationStarting(this.evaluations);
    });
  }

  ngAfterViewInit() {
    this.aiService.logPageView('Attribution Modal', '', performance.now() - this.initTime, 'Modals');
  }

  cancel() {
    this.activeModal.close({campaign:this.campaign, idObjects : null});
  }

  createUserList(campaign: CampaignParameters): any[] {
    if (campaign.users.isVolunteer) {
      return campaign.users.volunteers.map(v => {
        v.key = v.name;
        return v;
      });
    }
    return campaign.users.accountables;
  }

  initCheckboxFormula(campaign: CampaignParameters, checkboxFormula: any) {
    for (let user of this.users) {
      this.checkboxFormula[user.key] = {};
      for (let formula of user.formula) {
        this.checkboxFormula[user.key][formula.id] = formula.isActive;
      }
    }
    return checkboxFormula;
  }

  initEvaluationStarting(evaluations:any){
    if(!evaluations) return null
    for (let user of this.users) {
      this.evalStartingByFormula[user.key] = {};
      for (let formula of user.formula) {
        this.setValues(null, user.key, formula.id, -1 )
      }
    }
    let userId = null;
    evaluations.forEach((ev:any) => {
      userId = (ev.volunteer && Object.keys(ev.volunteer).length > 0) ? ev.volunteer.name : ev.users[0].key;
      if(!this.isUserInObject(userId)){
        this.evalStartingByFormula[userId] = {}
      }
      this.setValues(ev.id, userId, ev.formula.id, ev.progress)
    })
    return this.evalStartingByFormula
  }

  setValues(evalId:string, userId:string, formulaId:string, progressEval:number){
    this.evalStartingByFormula[userId][formulaId] = {
      idEvaluation:evalId,
      isStarting :progressEval > 0,
    }
  }

  isUserInObject(userId:string){
    return this.evalStartingByFormula[userId]
  }
  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {
        type: 'binary'
      });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <any>(XLSX.utils.sheet_to_json(ws, {
        header: 1
      }));

      let days = this.data[0].slice(1);
      console.log("data", this.data);
      for (let i = 1; i < this.data.length; i++) {
        let row = this.data[i];
        console.log("row[0]", row[0]);
        console.log("this.userInfos[row[0]]", this.userInfos[row[0]]);
        if (row.length > 0 && this.userInfos[row[0]]) {
          console.log("inside loop");
          let userSchedule = [];
          for (let j = 1; j < this.data[0].length; j++) {
            let targetDate = new Date(this.campaignCopy.startDate);
            // targetDate.setDate(targetDate.getDate() + days[j - 1] - 1);
            targetDate.setUTCHours(0, 0, 0, 0);
            let daysInMilliseconds = (days[j - 1] - 1) * 24 * 60 * 60 * 1000;
            targetDate.setTime(targetDate.getTime() + daysInMilliseconds);
            // userSchedule.push({formula: row[j], date: days[j - 1]});
            userSchedule.push({ formula: row[j], date: targetDate.getTime() });
          }
          this.randomizationMatrix[this.userInfos[row[0]]] = userSchedule;
        }
      }
      console.log("Excel Data", this.randomizationMatrix);
      if(!_.isEmpty(this.randomizationMatrix)) this.randomizationFileLoaded = true;
    };
    reader.readAsBinaryString(target.files[0]);
  }

  randomize() {
    this.users.map(u => u.formula = _.shuffle(u.formula));
  }

  save() {
    let idObjects = null;
    this.campaign = this.campaignCopy;
    this.users.map(u => {
      u.formula.map((f, i) => {
        f.sequential = i;
      });
      if (this.campaign.parameters.randomMode && this.randomizationFileLoaded) {
        let userInRandomizationMatrix;
        if (!(Object.keys(this.randomizationMatrix).length === 0 && this.randomizationMatrix.constructor === Object)) {
          userInRandomizationMatrix = this.randomizationMatrix[u.key];
          console.log("find user", userInRandomizationMatrix);
        }
        u.formula = u.formula.reduce((array, f, i) => {
          if (userInRandomizationMatrix) {
            let targetFormula = userInRandomizationMatrix.find(o => o.formula == f.name);
            if (targetFormula) {
              console.log("find formula:", targetFormula);
              console.log("formula information", f);
              f.startDate = targetFormula.date;
              array.push(f);
            }
          }
          return array;
        }, []);
      }

      if (this.campaign.users.isVolunteer) {
        this.campaign.users.volunteers.find(v => v.name === u.name).formula = u.formula;

      } else {
        this.campaign.users.accountables.find(v => v.key === u.key).formula = u.formula;
      }
    })
    if(this.isCampaignPublished){
      idObjects = this.checkStatusFormulaChanged()
    }

    this.activeModal.close({campaign:this.campaign, idObjects : idObjects});
  }

  updateCheckbox(userKey: string, formulaId: string) {
    this.checkboxFormula[userKey][formulaId] = !this.checkboxFormula[userKey][formulaId];
    let user;
    if (this.campaignCopy.users.isVolunteer) {
      user = this.campaignCopy.users.volunteers.find(v => v.name === userKey);
    } else {
      user = this.campaignCopy.users.accountables.find(a => a.key === userKey);
    }

    let formula = user.formula.find(f => f.id === formulaId);
    formula.isActive = !formula.isActive;
  }

  checkStatusFormulaChanged(){
    let usersFormulas = {}
    let idEvaluationList = []

    for (let user of this.users) {
      for (let formula of user.formula) {
        if(this.isCheckboxChanged(user.key, formula.id)){
          if(this.isDeactivateFormula(user.key, formula.id)){
            if(this.evalStartingByFormula[user.key] && this.evalStartingByFormula[user.key][formula.id]){
              const idEvaluation = this.evalStartingByFormula[user.key][formula.id].idEvaluation;
              idEvaluationList.push(idEvaluation)
            }
          }else{
              if(usersFormulas[user.key] && usersFormulas[user.key].length > 0){
                usersFormulas[user.key].push(formula.id)
              }else{
                usersFormulas[user.key] = [formula.id]
              }
        }};
      }
    }
    return {
      usersFormulas:usersFormulas,
      evaluationsId:idEvaluationList
    }
  }
  isCheckboxChanged(userKey: string, formulaId:string){
    return this.checkboxFormulaInit[userKey][formulaId] !== this.checkboxFormula[userKey][formulaId]
  }
  isDeactivateFormula(userKey: string, formulaId:string){
    return !this.checkboxFormula[userKey][formulaId]
  }
}
