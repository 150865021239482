import {
  NgModule
} from '@angular/core';
import {
  ReactiveFormsModule
} from '@angular/forms';
import {
  RouterModule,
  Routes
} from "@angular/router";

import {
  DragulaModule
} from "ng2-dragula";

import {
  AuthGuardService
} from "../shared/services/authenticationGuard.service";
import {
  BlockConfigurationComponent
} from './detail/blockConfiguration/blockConfiguration.component';
import {
  BlockSelectionComponent
} from './detail/blockSelection/blockSelection.component';
import {
  ComponentConfigurationBlockComponent
} from './detail/componentConfiguration/componentConfiguration.component';
import {
  ComponentConfigurationModule
} from '../components/args/componentConfiguration.module';
import { CreateWorkflowModalComponent } from './list/create-workflow/create-workflow-modal.component';
import {
  EditAllBlocksComponent
} from './detail/editAllBlocks/edit-all-blocks.component';
import {
  LogicJumpBlockComponent
} from './detail/logicJumps/logic-jump-block/logic-jump-block.component';
import {
  LogicJumpsModalComponent
} from './detail/logicJumps/logicJumpsModal.component';
import {
  ModalExtendEditBlocksComponent
} from './detail/editAllBlocks/modalExtendEditBlocks/modal-extend-edit-blocks.component';
import { PowerBIWorkflowComponent } from './detail/powerBIWorkflow/powerBIWorkflow.component';
import {
  PreviewWorkflow
} from './detail/preview-workflow/preview-workflow';
import {
  SharedModule
} from '../shared/shared.module';
import {
  WorkflowComponent
} from './detail/workflow.component';
import {
  WorkflowDetailComponent
} from './detail/workflow-detail/workflow-detail.component';
import {
  WorkflowRowComponent
} from './list/row/workflow-row.component';
import {
  WorkflowsComponent
} from '../workflows/list/workflows.component';
import {
  WorkflowService
} from './workflows.service';

const appRoutes: Routes = [
  {
    path: 'workflows',
    component: WorkflowsComponent,
    canActivate: [AuthGuardService],
    data: {
      role: 'DNA_WORKFLOW'
    }
  },
  {
    path: 'workflows/:idWorkflow',
    component: WorkflowComponent,
    canActivate: [AuthGuardService],
    data: {
      role: 'DNA_WORKFLOW'
    },
    resolve: {
      workflow: WorkflowService
    },
    children: [
      {
        path: '',
        component: WorkflowDetailComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'addBlocks',
        component: BlockSelectionComponent
      },
      {
        path: 'editBlock/:id',
        component: BlockConfigurationComponent
      },
      {
        path: 'editBlock',
        component: BlockConfigurationComponent
      },
      {
        path: 'editBlock/:idInQuestionnaire/editComponent/:idInBlock',
        component: ComponentConfigurationBlockComponent
      },
      {
        path: 'editComponent',
        component: ComponentConfigurationBlockComponent
      },
      {
        path: 'editBlocks',
        component: EditAllBlocksComponent
      },
      {
        path: 'graph',
        component: PowerBIWorkflowComponent
      },
      {
        path: 'preview',
        component: PreviewWorkflow
      }
    ]
  },
];

@NgModule({
  imports: [
    ComponentConfigurationModule,
    DragulaModule.forRoot(),
    ReactiveFormsModule,
    RouterModule.forChild(appRoutes),
    SharedModule
  ],
  exports: [],
  declarations: [
    BlockConfigurationComponent,
    BlockSelectionComponent,
    ComponentConfigurationBlockComponent,
    CreateWorkflowModalComponent,
    EditAllBlocksComponent,
    LogicJumpBlockComponent,
    LogicJumpsModalComponent,
    ModalExtendEditBlocksComponent,
    PowerBIWorkflowComponent,
    PreviewWorkflow,
    WorkflowComponent,
    WorkflowDetailComponent,
    WorkflowRowComponent,
    WorkflowsComponent
  ]
})
export class WorkflowsModule { }
