<div class="selection">
  <div class="btn-group btn-group-lg">
    <button id="importLabel" class="btn btn-selection btn-primary" [routerLink]="['/myprofile', 'import-export', 'import']">
      <label for="importLabel" translate>IMPORT</label>
    </button>
    <button id="exportLabel" class="btn btn-selection btn-secondary" [routerLink]="['/myprofile', 'import-export', 'export']">
      <label for="exportLabel" translate>EXPORT</label>
    </button>
  </div>
</div>
<ngx-loading [show]="showSpinner"></ngx-loading>
<select id="objectType" [(ngModel)]="objectType">
  <option *ngFor="let object of objectArray" value="{{ object }}" translate>{{ object }}</option>
</select>
<dna-block-import [workspaces]='workspaces | async' [user]="user" *ngIf="objectType == 'BLOCK'"></dna-block-import>
<dna-workflow-import [workspaces]='workspaces | async' [user]="user" *ngIf="objectType == 'WORKFLOW'"></dna-workflow-import>
