import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Metiers, TypeMetier } from '../../../types';

@Component({
  selector: 'dna-filters-target',
  templateUrl: './filters-target.component.html',
  styleUrls: ['./filters-target.component.less']
})
export class FiltersTargetComponent implements OnInit {

  @Input() filter: any;
  @Input() metierName: TypeMetier;

  @Output() onUpdateFilterTarget: EventEmitter<any> = new EventEmitter();

  metiers: typeof Metiers = Metiers;

  constructor() { }

  ngOnInit() {
  }

  updateCampaignsList(filter: any) {
    this.onUpdateFilterTarget.emit(filter);
  }

}
