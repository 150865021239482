import {Languages} from '../../../enums';
import {Chart} from '../../chart.model';
import {
    ChartDefaultLanguage, ChartDoWorker, ChartFormatter,
    ChartLang, KineticInputType, KineticRoutineInterface, KineticTimeInterface,
    KineticValueInterface, KineticVolonteerInterface, RoutineInterface
} from '../models';


class Block {

    public times: Time[] = [];
    constructor ( public raw: KineticTimeInterface[ 'attribute' ][ 'blockName' ] ) {}
}


class Routine {
    public color;
    constructor ( public raw: KineticRoutineInterface, public kinetic: Kinetic ) {
        const currentRoutineData = this.kinetic.routineData.routines.find( r => r.name === this.raw.attribute.label );

        if( currentRoutineData ) {this.color = currentRoutineData.color;}
    }
}

class Value {
    constructor ( public raw: KineticValueInterface, public volonteer: Volonteer, public routine: Routine, public time: Time ) {

    }
}

class Time {

    public gotValue = false;
    public routineValueMapping: Map<Routine, Value[]> = new Map();
    public routineVolonteer_valueMapping: Map<string, Value> = new Map();
    public routineMapping: Map<string, Routine> = new Map();

    constructor ( public time: number, public kinetic: Kinetic, public raw: KineticTimeInterface ) {
        this.gotValue = !( this.raw.attribute.time === undefined || this.raw.attribute.time === null );
        this.getValues();
    }

    private getValues () {
        this.raw.values.forEach( r => {
            const routine = this.kinetic.getRoutine( r );
            this.routineMapping.set( r.attribute.label, new Routine( r, this.kinetic ) );
            r.values.forEach( v => {
                const volonteer = this.kinetic.getVolonteer( v );
                const currentValues = this.getRoutineValueMapping( routine );
                const value = new Value( v.value, volonteer, routine, this );
                currentValues.push( value );
                this.createRoutineVolonteerValueMapping( routine, volonteer, value );
            } );
        } );
    }

    private getRoutineValueMapping ( r: Routine ): Value[] {
        let current = this.routineValueMapping.get( r );
        if( !current ) {
            current = [];
            this.routineValueMapping.set( r, current );
        }
        return current;
    }

    private createRoutineVolonteerValueMapping ( routine: Routine, volonteer: Volonteer, value: Value ) {
        const key = this.getRoutineVolonteerValueMappingKey( routine, volonteer );
        this.routineVolonteer_valueMapping.set( key, value );
    }

    private getRoutineVolonteerValueMappingKey ( routine: Routine, volonteer: Volonteer ): string {
        return `${routine.raw.attribute.label}-${volonteer.raw.label}`;
    }

    public getRoutineVolonteerValue = ( routine: Routine, volonteer: Volonteer ) => {
        const key = this.getRoutineVolonteerValueMappingKey( routine, volonteer );
        return this.routineVolonteer_valueMapping.get( key );
    };
}

class Volonteer {
    constructor ( public raw: KineticVolonteerInterface, public kinetic: Kinetic ) {

    }
}


class Kinetic implements ChartDoWorker, ChartFormatter, ChartDefaultLanguage, ChartLang {
    static pValueLabelsLibrary: {
        [ level: string ]: {
            label: string,
            xOffset: number;
        };
    } = {
            '1': {
                label: '∗',
                xOffset: -3,
            },
            '2': {
                label: '(∗)',
                xOffset: -9,
            },
        };

    private timeMapping: Map<number, Time> = new Map();
    private routineMapping: Map<string, Routine> = new Map();
    private volonteerMapping: Map<string, Volonteer> = new Map();
    private blockTimeMapping: Map<string, Block> = new Map();

    public showAttributeBlock = false;
    public defaultLanguage: string | null = null;
    public series: Highcharts.SeriesOptionsType[];
    public options: Highcharts.Options;
    public rawTableData: any[];

    constructor ( public raw: KineticInputType, public routineData: RoutineInterface, public lang: string ) {
        this.createGraph();
        this.createHighchartOptions();
        this.createRawTableData();
    }

    private createGraph () {
        let lastTime = -5;
        const lastIndex = this.raw.length - 1;
        this.raw.forEach( ( t, tIndex ) => {
            const currentOffset = lastIndex === tIndex ? 15 : 5;
            lastTime = isNaN( t.attribute.time ) ? lastTime + currentOffset : t.attribute.time;
            const time = new Time( lastTime, this, t );
            this.timeMapping.set( lastTime, time );
            const block = this.getBlockTimes( time.raw.attribute.blockName );
            block.times.push( time );
        } );
    }

    public getRoutine = ( r: KineticRoutineInterface ): Routine => {
        let routine = this.routineMapping.get( r.attribute.label );
        if( !routine ) {
            routine = new Routine( r, this );
            this.routineMapping.set( r.attribute.label, routine );
        }
        return routine;
    };

    public getVolonteer = ( v: KineticVolonteerInterface ): Volonteer => {
        let volonteer = this.volonteerMapping.get( v.label );
        if( !volonteer ) {
            volonteer = new Volonteer( v, this );
            this.volonteerMapping.set( v.label, volonteer );
        }
        return volonteer;
    };

    private getBlockTimes = ( block: KineticTimeInterface[ 'attribute' ][ 'blockName' ] ): Block => {
        const id = Chart.getObjectValueTranslation( block, Languages.Default );
        let current = this.blockTimeMapping.get( id );
        if( !current ) {
            current = new Block( block );
            this.blockTimeMapping.set( id, current );
        }
        return current;
    };

    private getMedian = ( numbers: number[] ) => {
        let median = 0;
        const numsLen = numbers.length;
        numbers.sort( ( a: number, b: number ) => a - b );

        if(
            numsLen % 2 === 0 // is even
        ) {
            // average of two middle numbers
            median = ( numbers[ numsLen / 2 - 1 ] + numbers[ numsLen / 2 ] ) / 2;
        } else { // is odd
            // middle number only
            median = numbers[ ( numsLen - 1 ) / 2 ];
        }
        return median % 1 ? Number( median.toFixed( 2 ) ) : median;
    };

    private createHighchartOptions () {
        this.createSeries();
        this.createOptions();
    }

    private createOptions () {
        const kinetic = this;
        const xAxisOption: Highcharts.XAxisOptions = {
            tickPositions: [],
            labels: {
                useHTML: true,
                formatter: function () {
                    const time = kinetic.timeMapping.get( this.value as number);
                    return Chart.getObjectValueTranslation( time.raw.attribute.blockName, kinetic.lang );
                }
            },
            plotLines: []
        };

        for( const [ timeValue, time ] of this.timeMapping ) {
            xAxisOption.tickPositions.push( timeValue );
            if( time.raw.attribute.hasOwnProperty( 'pValueLevel' ) ) {
                const label = Kinetic.pValueLabelsLibrary[ time.raw.attribute.pValueLevel ];
                xAxisOption.plotLines.push( {
                    width: 0,
                    value: timeValue,
                    label: {
                        text: label.label,
                        align: 'left',
                        textAlign: 'left',
                        rotation: 0,
                        x: label.xOffset,
                        y: -2,
                        style: {
                            fontSize: '18',
                            color: '#666666'
                        },
                    },
                } );
            }
        }
        this.options = {
            xAxis: xAxisOption,
        };
    }

    private createSeries () {
        const series: Highcharts.SeriesOptionsType[] = [];

        const routinesArray: string[] = [];
        for( let key of this.routineMapping.keys() ) {
            routinesArray.push( key );
        }

        const routinesOrder = routinesArray.sort( ( a: string, b: string ) => a.localeCompare( b ) );

        routinesOrder.forEach( routineName => {
            const routine = this.routineMapping.get( routineName );
            const serie: Highcharts.SeriesOptionsType = {
                type: "spline",
                name: routineName,
                data: [],
            };

            const SEMSerie: Highcharts.SeriesErrorbarOptions = {
                name: routineName + " - SEM",
                type: "errorbar",
                data: [],
            };

            if( routine.color ) {
                serie.color = routine.color;
            }

            for( const [ timeValue, time ] of this.timeMapping ) {
                const values = time.routineValueMapping.get( routine );

                const median = values
                    ? this.getMedian(
                        values.filter( ( v ) => !isNaN( v.raw.key ) ).map( ( v ) => v.raw.key )
                    )
                    : null;

                serie.data.push( {
                    x: timeValue,
                    y: median,
                } );

                const timeRoutine = time.routineMapping.get( routine.raw.attribute.label );

                if(
                    timeRoutine.raw.attribute.hasOwnProperty( "semMax" ) &&
                    timeRoutine.raw.attribute.hasOwnProperty( "semMin" )
                ) {
                    SEMSerie.data.push( {
                        x: timeValue,
                        low: timeRoutine.raw.attribute.semMin,
                        high: timeRoutine.raw.attribute.semMax,
                    } );
                }
            }

            series.push( serie, SEMSerie );
        } );

        this.series = series;
    }

    private createRawTableData () {

        const tablePayload: any[] = [];

        this.blockTimeMapping.forEach( block => {
            const column = {
                attribute: {
                    label: block.raw,
                },
                values: []
            };

            block.times.forEach( time => {
                const currentTime = {
                    attribute: {
                        label: time.raw.attribute.label
                    },
                    values: []
                };

                column.values.push( currentTime );

                this.routineMapping.forEach( routine => {
                    const currentRoutine = {
                        attribute: {
                            label: routine.raw.attribute.label
                        },
                        values: []
                    };

                    currentTime.values.push( currentRoutine );

                    this.volonteerMapping.forEach( volonteer => {
                        const value = time.getRoutineVolonteerValue( routine, volonteer );
                        const currentVolonteer = {
                            label: volonteer.raw.label,
                            value: {
                                key: value.raw.key,
                                label: value.raw.label,
                                color: value.raw.color
                            }
                        };

                        currentRoutine.values.push( currentVolonteer );
                    } );
                } );
            } );

            tablePayload.push( column );
        } );

        this.rawTableData = tablePayload;
    }

    public getSeries = () => this.series;
    public getOptions = () => this.options;

    public getRawTableData = () => this.rawTableData;
}



export const doWork = ( data: KineticInputType, routines: RoutineInterface, lang: string ): Kinetic => {
    return new Kinetic( data, routines, lang );
};
