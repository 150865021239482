import { BubbleOptionsType, ChartOption } from '../../interfaces';
import { OptionType } from '../../enums';
import { Annotation, Chart, Point } from 'highcharts';

export interface BubbleOptionsModelInputInterface {
    general: any;
    formulas: Highcharts.Point[],
    routines: {
        name: string,
        color: string,
        formula: {
            name: string
        }[]
    }[],
    series: {
        name: string;
        value: string;
    }[]
}

interface CustomCharWithAnnotation extends Chart {
    addAnnotation: (option : Highcharts.AnnotationsOptions, redraw? : boolean) => Highcharts.Annotation;
    removeAnnotation: (idOrAnnotation: number | string | Highcharts.Annotation) => void;
}

interface CustomAnnotationClass extends Annotation {
    update: (userOptions: Highcharts.AnnotationsOptions) => void;
}

interface HelperFormulaInterface {
    point: Highcharts.Point;
    value: boolean;
}


export interface HelperPayloadInterface {
    general: any;
    specifics: {
        formulas: HelperFormulaInterface[];
        series: {
            name: string;
            value: string;
        }[];
    }
}

class PostionFactory {

    private xYMapping: Map<number, Map<number, Point[]>> = new Map();
    private yXMapping: Map<number, number[]> = new Map();
    private PointXYMapping: Map<Point, Point[]> = new Map();
    private PointsRadiusMapping: Map<Point[], number> = new Map();

    public constructor(private points: Point[]) {
        this.points.forEach(point => {
            this.addToMapping(point);
        });
    }

    private addToMapping(point: Point) {
        const { x, y } = point;

        let xMapping = this.xYMapping.get(x);
        if (!xMapping) {
            xMapping = new Map();
            this.xYMapping.set(x, xMapping);
        }

        let yMapping = xMapping.get(y);

        if (!yMapping) {
            yMapping = [];
            xMapping.set(y, yMapping);
        }

        yMapping.push(point);
        this.PointXYMapping.set(point, yMapping);

        this.PointsRadiusMapping.set(yMapping, Math.max(
            point['z'],
            this.PointsRadiusMapping.get(yMapping) || 0
        ));

        if (!this.yXMapping.has(y)) {
            this.yXMapping.set(y, []);
        }
        this.yXMapping.get(y).push(x);
    }

    public getXYAnnotationPosition(point: Point): {x: number, y: number, align: Highcharts.AlignValue, verticalAlign: Highcharts.VerticalAlignValue} {

        const siblingsPoints = this.PointXYMapping.get(point);
        const nbPart = siblingsPoints.length;
        const currentIndex = siblingsPoints.findIndex(p => p === point);

        const xPoints = this.xYMapping.get(point.x);
        const yPoints = this.yXMapping.get(point.y);

        let gotPointOnTop = xPoints.has(point.y + 1);
        let gotPointOnBottom = xPoints.has(point.y - 1);
        let gotPointOnRight = yPoints && yPoints.includes(point.x + 1);
        let gotPointOnLeft = yPoints && yPoints.includes(point.x - 1);
        let gotPointOnTL = this.yXMapping.has(point.y + 1) && this.yXMapping.get(point.y + 1).includes(point.x - 1);
        let gotPointOnTR = this.yXMapping.has(point.y + 1) && this.yXMapping.get(point.y + 1).includes(point.x + 1);
        let gotPointOnBL = this.yXMapping.has(point.y - 1) && this.yXMapping.get(point.y - 1).includes(point.x - 1);
        let gotPointOnBR = this.yXMapping.has(point.y - 1) && this.yXMapping.get(point.y - 1).includes(point.x + 1);

        if (
            gotPointOnTop &&
            gotPointOnBottom &&
            gotPointOnRight &&
            gotPointOnLeft &&
            gotPointOnTL &&
            gotPointOnTR &&
            gotPointOnBL &&
            gotPointOnBR
        ) return {
            x: 0,
            y: 0,
            align: 'center',
            verticalAlign: 'middle'
        };

        const partList = [
            gotPointOnTop,
            gotPointOnBottom,
            gotPointOnRight,
            gotPointOnLeft,
            gotPointOnTL,
            gotPointOnTR,
            gotPointOnBL,
            gotPointOnBR
        ];

        const nbPartAvailable = partList.filter(x => !x).length;

        const partAngle = ((360 / 8) * nbPartAvailable)  / nbPart;

        const anglesRange: [number, number, Highcharts.AlignValue, Highcharts.VerticalAlignValue] [] = [];

        let tempAngle = 0;

        if (!gotPointOnBottom) anglesRange.push([tempAngle, tempAngle + 25, 'center', 'middle']);
        tempAngle += 25;
        if (!gotPointOnBR) anglesRange.push([tempAngle, tempAngle + 45, 'left', 'middle']);
        tempAngle += 45;
        if (!gotPointOnRight) anglesRange.push([tempAngle, tempAngle + 45, 'left', 'middle']);
        tempAngle += 45;
        if (!gotPointOnTR) anglesRange.push([tempAngle, tempAngle + 45, 'left', 'middle']);
        tempAngle += 45;
        if (!gotPointOnTop) anglesRange.push([tempAngle, tempAngle + 45, 'center', 'middle']);
        tempAngle += 45;
        if (!gotPointOnTL) anglesRange.push([tempAngle, tempAngle + 45, 'right', 'middle']);
        tempAngle += 45;
        if (!gotPointOnLeft) anglesRange.push([tempAngle, tempAngle + 45, 'right', 'middle']);
        tempAngle += 45;
        if (!gotPointOnBL) anglesRange.push([tempAngle , tempAngle + 45, 'right', 'middle']);
        tempAngle += 45;
        if (!gotPointOnBottom) anglesRange.push([tempAngle, tempAngle + 25, 'center', 'middle']);

        tempAngle = 0;

        let angle = partAngle * currentIndex;
        while (angle > 0) {
            const [min, max] = anglesRange[0];
            const range = max - min;
            if (angle >= range) {
                angle = angle - range;
                anglesRange.shift();
            } else {
                anglesRange[0][0] = min + angle;
                angle = 0;
            }
        }

        const currentAngle = anglesRange[0][0];

        const currentRadius = this.PointsRadiusMapping.get(siblingsPoints);
        
        const x = ((currentRadius + 50)) * Math.sin(Math.PI * 2 * (currentAngle) / 360);
        const y = ((currentRadius + 50)) * Math.cos(Math.PI * 2 * (currentAngle) / 360);

        return {
            x,
            y,
            align: anglesRange[0][2],
            verticalAlign: anglesRange[0][3]
        }
    }
}

export class BubbleOptions {

    public options: BubbleOptionsType = {
        general: { label: null, options: [] },
        formulas: {
            label: null,
            options: []
        },
        series: {
            label: null,
            options: []
        }
    };

    public callback: Function;

    constructor(
        {
            general,
            formulas = [],
            routines = [],
            series = []
        }: BubbleOptionsModelInputInterface, callback: Function) {
        this.callback = callback;
        // GENERAL PART
        this.options['general'].label = general.label || 'VIZUALIZATIONS.GENERAL';
        try { this.options['general'].options.push({ label: 'VIZUALIZATIONS.CHART_TITLE', value: (general.title.text || null), type: OptionType.Text, key: "chart_title" }); }
        catch (e) { this.options['general'].options.push({ label: 'VIZUALIZATIONS.CHART_TITLE', value: null, type: OptionType.Text, key: "chart_title" }); }
        try { this.options['general'].options.push({ label: 'VIZUALIZATIONS.CHART_SUBTITLE', value: (general.subtitle.text || null), type: OptionType.Text, key: 'chart_subtitle' }); }
        catch (e) { this.options['general'].options.push({ label: 'VIZUALIZATIONS.CHART_SUBTITLE', value: null, type: OptionType.Text, key: "chart_subtitle" }); }
        this.options.formulas.label = "formulas";
        this.options.formulas.options.push( this.createFormulasLabelOptions( formulas, routines ) );
        
        // SERIES PART
        this.options.series.label = 'VIZUALIZATIONS.SERIES';

        try {
            this.options.series.options = series.map( serie => {
                return {
                    label: serie.name,
                    value: serie.value,
                    type: OptionType.Color,
                    key: `key_${serie.name}`
                }
            })
        } catch( e ) {
            console.warn( 'error in bubble colors configuration creation', e );
        }
    }

    public getParametersFromOptions = (): HelperPayloadInterface => {
        let payload: HelperPayloadInterface = {
            general : {},
            specifics : {
                formulas: [],
                series: this.getSeriesParameterFromOptions()
            },
        };
        Object.assign(payload.general, this.getGeneralParameterFromOptions());

        return payload;
    };

    private getGeneralParameterFromOptions = (): any => {
        return {
            title: { text: this.options.general.options[0].value },
            subtitle: { text: this.options.general.options[1].value }
        }
    };

    private getSeriesParameterFromOptions = (): any => {
        return this.options.series.options.map( serie => {
            return {
                name: serie.label,
                value: serie.value,
            };
        });
    }

    private getHexColor = (colorStr : string):string => {
        var a = document.createElement('div');
        a.style.color = colorStr;
        var colors = window.getComputedStyle( document.body.appendChild(a) ).color.match(/\d+/g).map(function(a){ return parseInt(a,10); });
        document.body.removeChild(a);
        return String((colors.length >= 3) ? '#' + (((1 << 24) + (colors[0] << 16) + (colors[1] << 8) + colors[2]).toString(16).substr(1)) : false);
    };

    private createFormulasLabelOptions = (formulas: Point[], routines: BubbleOptionsModelInputInterface["routines"]): ChartOption => {
        const advancePositionFactory = new PostionFactory( formulas );
        return {
            label: "Select show labels",
            value: formulas.map(f => {
                let previousState = true;
                const currentRoutine = routines.find(r => r.name === f.name);
                const {x, y, align, verticalAlign} = advancePositionFactory.getXYAnnotationPosition(f);
                // Create annotation for each point and insert into chart
                let annotation: CustomAnnotationClass = <CustomAnnotationClass>(<CustomCharWithAnnotation>f.series.chart).addAnnotation({
                    labels: [
                        {
                            point: {
                                x: f.x,
                                y: f.y,
                                xAxis: 0,
                                yAxis: 0
                            },
                            allowOverlap: true,
                            formatter: function() {
                                if (!this["target"].annotation.userOptions.visible) return ;
                                return `

                                    <table style="color: ${currentRoutine.color}; background-color: white">
                                        <thead>
                                            <tr>
                                                <th style="font-weight: bold">
                                                    ${f.name}
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            ${
                                                currentRoutine.formula.map(f => {
                                                    return `
                                                        <tr>
                                                            <td></td>
                                                            <td>
                                                                ${f.name}
                                                            </td>
                                                        </tr>
                                                    `
                                                })
                                            }
                                        </tbody>
                                    </table>
        
                                `
                            },
                            y,
                            x,
                            align,
                            verticalAlign,
                            useHTML: true
                        },
                    ],
                    labelOptions: {
                        backgroundColor: "white",
                        borderColor: currentRoutine.color,
                        
                    },
                    visible: previousState,
                    id: f.name
                });

                const optionOutput = {
                    label: f.name,
                    key: f.name,
                    value: true,
                    originalObject: f,
                    events: {
                        change: (value) => {
                            if (value !== previousState) {
                                previousState = value;
                                annotation.update({
                                    visible: value,
                                })
                            }
                        }
                    }
                }
                return optionOutput
            }),
            hideLabel: true,
            key: "formulas_label",
            type: OptionType.GroupCheckBox,
            originalObject: formulas
        }
    }

};