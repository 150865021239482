<div class="modal-header">
  <h4 class="modal-title"> {{ title }}</h4>
  <button class="close" type="button" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true" class="fa fa-times"></span>
  </button>
</div>
<div class="modal-body">
  <ngx-loading [show]="arcsLoading" [config]="{ fullScreenBackdrop: false }">
  </ngx-loading>
  <form #addArcsForm="ngForm" name="arcsform" class="form-horizontal" novalidate>
    <div class="form-group row">
      <label class="col-md-2 my-auto form-control-label required">
        {{ "PROJECTS.PROJECT_ARCS_STUDY" | translate }}
      </label>
      <div class="col-md-6 input-group">
        <div class="input-group-prepend">
          <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" name="system"
            aria-haspopup="true" aria-expanded="false">
            {{ arcsSystem }}
          </button>
          <div class="dropdown-menu">
            <ng-container *ngFor="let country of countries">
              <a class="dropdown-item" (click)="arcsSystem = country">{{ country }}</a>
            </ng-container>
          </div>
        </div>
        <input type="text" [(ngModel)]="arcsNumber" class="form-control" name="arcsNumber" #arcsNumberInput="ngModel"
          required>
      </div>
      <div *ngIf="arcsExists" class="offset-4 invalid-feedback">
        <p translate>PROJECTS.ALREADY_EXIST_PROJECT_ARCS</p>
      </div>
      <div *ngIf="arcsIncorrect" class="offset-4 invalid-feedback">
        <p translate>PROJECTS.INCORRECT_PROJECT_ARCS</p>
      </div>
    </div>

  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button"
    (click)="updateArcsInProject(arcsNumber, arcsSystem)">{{ "VALIDATE" | translate }}</button>
  <button *ngIf="!isUpdatingArcs" class="btn btn-default" type="button"
    (click)="cancel()">{{ "CANCEL" | translate }}</button>
</div>
