import {ConfortPercentageWithCategoriesNamespace, PayloadInterface, RoutineInput} from '../models';
import * as HighChart from 'highcharts';

class ConfortPercentageWithCategories {

  public series: HighChart.SeriesOptionsType[];
  public options: HighChart.Options;
  public totalVoteRoutineMapping: Map<string, number> = new Map();
  public categoryRoutineCountMapping: Map<number, Map<string, number>> = new Map();

  constructor(
    public raw: ConfortPercentageWithCategoriesNamespace.InputInterface[],
    public rawCategories: ConfortPercentageWithCategoriesNamespace.RawCategoryInterface[],
    public routines: RoutineInput
  ) {
    this.createGraph();
    this.createHighChartOptions();
  }

  private createGraph(): void {
    this.raw.forEach(input => {
      input.values.forEach(routine => {
        const routineName = routine.attribute.label;
        if (!this.totalVoteRoutineMapping.has(routineName)) {
          this.totalVoteRoutineMapping.set(routineName, 0);
        }
        routine.values.forEach(volunteer => {
            const currentRoutineTotalPoint = this.totalVoteRoutineMapping.get(routineName);
            this.totalVoteRoutineMapping.set(routineName, currentRoutineTotalPoint + 1);
            const categoryKey = volunteer.value.key;

            if (!this.categoryRoutineCountMapping.has(categoryKey)) {
              this.categoryRoutineCountMapping.set(categoryKey, new Map());
            }

            const currentCategoryMapping = this.categoryRoutineCountMapping.get(categoryKey);

            if (!currentCategoryMapping.has(routineName)) {
              currentCategoryMapping.set(routineName, 0);
            }

            currentCategoryMapping.set(routineName, currentCategoryMapping.get(routineName) + 1);
        });
      });
    });
  }

  private createHighChartOptions(): void {
    const routineOrder = this.routines.routines.map(routine => routine.name).sort((a, b) => a.localeCompare(b));

    const series: HighChart.SeriesOptionsType[] = [];

    routineOrder.forEach(routineName => {
      const currentSerie: HighChart.SeriesOptionsType = {
        name: routineName,
        type: 'column',
        data: []
      };

      this.rawCategories.forEach(category => {
        const currentCategoryIndex = category.key;
        const currentPoint = this.getPoints(routineName, currentCategoryIndex);
        const totalPoint = this.getRoutineTotalPoint(routineName);
        currentSerie.data.push(Math.round(currentPoint * 100 / totalPoint));
      });

      series.push(currentSerie);
    });

    this.series = series;

    this.options = {
      xAxis: {
        categories: this.rawCategories as any
      }
    };

  }

  private getPoints(routineName: string, categoryKey: number): number {
    if (!this.categoryRoutineCountMapping.has(categoryKey)) {
      return 0;
    }

    const currentCategoryMapping = this.categoryRoutineCountMapping.get(categoryKey);

    if (!currentCategoryMapping.has(routineName)) {
      return 0;
    }

    return currentCategoryMapping.get(routineName);
  }

  private getRoutineTotalPoint(routineName: string): number {
    if (!this.totalVoteRoutineMapping.has(routineName)) {
      return 0;
    }

    return this.totalVoteRoutineMapping.get(routineName);
  }
}

export const doWork = (
   data: ConfortPercentageWithCategoriesNamespace.InputInterface[],
   baseKey, lang, descriptors,
   payload: PayloadInterface,
   routines: RoutineInput,
   _
): void => {
  const confortPercentage = new ConfortPercentageWithCategories(data, descriptors, routines);
  payload.series = confortPercentage.series;
  payload.categories = (confortPercentage.options.xAxis as HighChart.XAxisOptions).categories;
};
