<ngx-loading [show]="showSpinner">
</ngx-loading>
<h1 class="mb-4" id="banner-title" translate>
  CONFIGURE_COMPONENT
</h1>
<ng-container *ngIf="component">
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" translate>DASHBOARD</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/blocks" translate>LIBRARY_OF_BLOCKS</a>
      </li>
      <li class="breadcrumb-item" [ngClass]="{ 'active': block.id == undefined }">
        <a [routerLink]="['/blocks', block.id]">
          <dna-translate [fieldToTranslate]="block.name"> </dna-translate>
        </a>
      </li>
      <li class="breadcrumb-item active">
        {{ component.args.label[currentLanguage] || component.args.label.english || component.type }}
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>CONFIGURE</li>
    </ol>
  </nav>
  <div class="actions d-flex flex-row gap-1 justify-content-end" id="banner-actions">
    <button *ngIf="!inactive" id="submit" class="btn btn-primary" type="submit" (click)="validate()" translate>VALIDATE</button>
    <button class="btn btn-default" type="button" (click)="cancel()" translate>{{ inactive ? "BACK" : "CANCEL" }}</button>
  </div>
  <div class="block">
    <div *ngIf="inactive" class="error text-center" translate>BLOCK_IS_PUBLISHED</div>
    <div>
      <div class="mb-4">
        <dna-component-config [component]="component" [inactive]="inactive" [submitted]="submitted"
          (updateError)="hasError = $event"></dna-component-config>
      </div>
      <div class="mb-4">
        <b translate>TRANSLATE_COMPONENT</b>
      </div>
      <div class="row">
        <div class="col-sm-2 text-center" *ngFor="let language of languages">
          <b translate>{{ language }}</b>
        </div>
      </div>
      <br>
      <div *ngFor="let translateObject of fieldsOfComponents; let indexComponent = index">
        <label class="mb-2">
          <b>{{ translateObject[0].type }}:</b>
        </label>
        <br>
        <div *ngFor="let objt of translateObject">
          <div *ngIf="objt.type == 'String'" class="mb-2">
            <label translate class="mb-2">{{ objt.translationKey }}</label>
            <div class="row">
              <div class="col-sm-2" *ngFor="let language of languages">
                <input class="form-control" [disabled]="inactive" [(ngModel)]="component.args[objt.fieldName][language]"
                  type="text" name="{{ objt.translationKey + '_' + language }}">
              </div>
            </div>
          </div>
          <div *ngIf="objt.type == 'Array'" class="mb-2">
            <label translate class="mb-2">{{ objt.translationKey }}</label>
            <div class="row">
              <div class="col-sm-2" *ngFor="let language of languages">
                <tag-input class="form-control tag-input" [disable]="inactive"
                  [(ngModel)]="component.args[objt.fieldName][language]" [displayBy]="objt.key" [identifyBy]="objt.key"
                  [maxItems]="50" name="{{ objt.translationKey + '_' + language }}">
                </tag-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input type="hidden" id="tourSign" value="CONFIGURE">
  </div>
</ng-container>
