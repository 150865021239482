import {Chart} from '../chart.model';
import {LineChartOptions} from './linechart-options.model';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import * as LineChartLabelFormatter from './linechart.label.formatter';
import * as LineChartTooltipFormatter from './linechart.tooltip.formatter';
import {Languages, COMPUTING_METHODS} from '../../enums';

import * as LineChartNormalizedMedianCompute from './methods/normalized-median.method';
import * as LineChartMedianCompute from './methods/median.method';
import * as LineChartDrilledCompute from './methods/drilled.method';
import * as LineChartMedianWithBenchAsRef from './methods/median-with-bench-ref.method';

import {Descriptor} from '../../../../../../../../types';
import * as Highcharts from 'highcharts';
import {Table} from '../table';

import * as _ from 'lodash';

const mapSettingsKey: Map<string, string> = new Map();
mapSettingsKey.set( 'scale', 'yAxis' );
mapSettingsKey.set( 'show_default_language', 'show_default_language' );
mapSettingsKey.set( 'show_attributes_blocks', 'show_attributes_blocks' );
mapSettingsKey.set( 'show_grey_zone', 'show_grey_zone' );

const MAX_DRILL_COUNT = 2;

export class LineChart extends Chart {
    protected _baseParameters: any = {
        chart: {
            type: 'line',
            events: {
                load: function ( el ) {
                    this.parameters.series.forEach( ( serie: any ) => {
                        serie.color = el.target.series.find( ( x: any ) => x.name === serie.name ).color;
                    } );
                    this.updateChartTranslations( el.target, this.parameters, this.lang );
                    this.buildGraphOptions( this.parameters );
                    this.linkRoutinesToChart( el.target );
                    this.onOptionChangeAfterLoadSubject.next( this.options );
                }.bind( this )
            }
        },
        xAxis: {
            type: 'category',
            crosshair: true,
            useHTML: true,
            labels: {
                formatter: function ( el: any ) {
                    const {userOptions} = el.chart;
                    let langs: Array<string> = [ this.lang ];
                    if( userOptions.hasOwnProperty( 'plotOptions' ) && userOptions.plotOptions.hasOwnProperty( 'default_language' ) ) {
                        if( userOptions.plotOptions.default_language.enabled && langs.indexOf( Languages.Default ) === -1 ) langs.push( Languages.Default );
                    }
                    if( userOptions.hasOwnProperty( 'plotOptions' ) && userOptions.plotOptions.hasOwnProperty( 'attributes_blocks' ) ) return LineChartLabelFormatter.formatter( el, langs, userOptions.plotOptions.attributes_blocks.enabled );
                    else return LineChartLabelFormatter.formatter( el, langs, false );

                }.bind( this ),
            }
        },
        yAxis: {
            title: {
                enabled: true,
                text: ''
            }
        },
        tooltip: {
            enabled: false,
            //shared: true,
            useHTML: true,
            formatter: function ( el: any ) {
                const {userOptions} = el.chart;
                if( userOptions.hasOwnProperty( 'plotOptions' ) && userOptions.plotOptions.hasOwnProperty( 'attributes_blocks' ) ) return LineChartTooltipFormatter.formatter( this, userOptions.language, userOptions.plotOptions.attributes_blocks.enabled );
                else return LineChartTooltipFormatter.formatter( this, userOptions.language, false );
            },
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false,
                    inside: false,
                    color: 'black'
                }
            }
        },
    };


    private initialData: any;
    public onDrillSubject = new Subject<string>();
    public custom: {
        updateRoutines: ( routines: any[] ) => void;
    } = {
            updateRoutines: undefined
        };
    private drilledState: boolean = false;
    public static mapSettings: Map<string, string> = mapSettingsKey;

    constructor (
        _parameters?: any,
        _data?: Array<any>,
        _lang?: string,
        _filters?: any,
        private _translateService?: TranslateService
    ) {
        super( _parameters, _data, _lang, _filters );
        if( this.data && this.data.length ) {
            this.tableRawData = this.formatRawData( _data, _lang );
            this.generateParametersAssignment( this.parameters );
            this.generateDataAssignment( this.data, this.lang );
        }
    };

    /**
     * Link routine to chart form manipulation
     */
    private linkRoutinesToChart ( chart: Highcharts.Chart ) {
        if( !this.routines || !this.routines.routines || !Array.isArray( this.routines.routines ) || this.routines.routines.length === 0 ) return;

        const routineSerieMapping: Map<any, Highcharts.Series[]> = this.mapRoutineWithSeries( this.routines.routines, chart.series );

        let selectedRoutine;

        const newRoutines = [];

        chart.series.forEach( ( serie, index ) => {
            serie.update( {
                name: serie.name,
                type: serie.type as any,
                index
            }, false );
        } );

        chart.redraw();

        let maxIndex: number = chart.series.length;
        let lastIndex: number[] = undefined;

        routineSerieMapping.forEach( ( series, routine ) => {
            routine.onClick = () => {
                if( routine.selected ) {
                    series.forEach( ( serie, index ) => {
                        serie.update( {
                            name: serie.name,
                            type: serie.type as any,
                            index: lastIndex[ index ],
                            zIndex: undefined
                        } );
                    } );
                    selectedRoutine = undefined;
                    lastIndex = undefined;
                } else {
                    if( selectedRoutine ) {
                        selectedRoutine.selected = false;
                        const selectedSeries = routineSerieMapping.get( selectedRoutine );
                        selectedSeries.forEach( ( selectedSerie, index ) => {
                            selectedSerie.update( {
                                name: selectedSerie.name,
                                type: selectedSerie.type as any,
                                index: lastIndex[ index ],
                                zIndex: undefined
                            }, false );
                        } );
                        lastIndex = undefined;
                    }
                    selectedRoutine = routine;
                    lastIndex = [];
                    series.forEach( ( serie, index ) => {
                        lastIndex.push( serie.index );
                        serie.update( {
                            name: serie.name,
                            type: serie.type as any,
                            index: maxIndex + index,
                            zIndex: maxIndex
                        }, false );
                    } );
                    chart.redraw();
                }

                routine.selected = !routine.selected;

                chart.series.forEach( serie => {
                    if( !selectedRoutine ) {
                        serie.setState( 'normal' );
                        return;
                    }
                    if( series.includes( serie ) ) {
                        serie.setState( 'normal' );
                        return;
                    }
                    serie.setState( 'inactive', true );
                } );
            };

            newRoutines.push( routine );
        } );
        this.custom.updateRoutines( newRoutines );
    }

    private mapRoutineWithSeries ( routines: any[], series: Highcharts.Series[] ): Map<any, Highcharts.Series[]> {
        const mapping = new Map();
        routines.forEach( routine => {
            const foundSeries = [];
            const routineSerie = series.find( serie => serie.name === routine.name );
            if( routineSerie ) foundSeries.push( routineSerie );

            if( !foundSeries.length ) {
                const formulas: string[] = routine.formula.map( f => f.name );
                const formulaSeries = series.filter( serie => formulas.includes( serie.name ) );

                foundSeries.push( ...formulaSeries );
            }

            if( foundSeries.length ) mapping.set( routine, foundSeries );
        } );

        return mapping;
    }

    private generateParametersAssignment ( parameters ) {
        const method = this.parameters.compute ? this.parameters.compute.method : null;
        if( method === 'normalized-median' ) parameters.yAxis.min = null;
        Object.assign( {...this._baseParameters, parameters} );
        Object.keys( {...this._baseParameters} ).forEach( ( k ) => {
            this.parameters[ k ] = Object.assign( {...this._baseParameters[ k ]}, parameters[ k ] );
        } );
        Object.keys( {...parameters} ).forEach( ( k ) => {
            this.parameters[ k ] = Object.assign( {...this.parameters[ k ]}, parameters[ k ] );
        } );
    };

    protected generateDataAssignment = ( data: any, lang: string ) => {
        this.data = data;
        if( this.initialData != data ) this.initialData = data;
        const method = this.parameters.compute ? this.parameters.compute.method : null;
        const baseKey = this.parameters.compute ? this.parameters.compute.key : 'key';
        const payload = this.formatData(
            _.cloneDeep(this.initialData),
            this.drilledState ? 'drilled' : method,
            baseKey,
            lang,
            this.parameters,
            this.descriptors,
            this.routines
        );
        this.parameters.series = payload.series;
        this.parameters.xAxis.categories = payload.categories;

        this.parameters.tooltip.enabled = true;

        if( this.parameters.hasOwnProperty( 'drilldown' ) && this.parameters.drilldown.enabled ) {
            this.parameters.plotOptions.series.cursor = 'pointer';
            this.parameters.plotOptions.series.events = {
                click: ( () => this.handleDrill( this.drilledState ) ).bind( this )
            };
        }

        if( payload.tooltipFormatter ) {
            this.parameters.tooltip.formatter = payload.tooltipFormatter;
        }

        if( this.chart ) this.updateChartTranslations( this.chart, this.parameters, this.lang );
    };

    /**
     * buildGraphOptions
     */
    public buildGraphOptions = ( options: any ) => {
        const {title, subtitle, xAxis, yAxis, series, plotOptions, ...rest} = options;
        this.options = new LineChartOptions( {
            general: {title, subtitle},
            xAxis,
            yAxis,
            series,
            plotOptions
        }, this.onOptionsChange );
    };

    protected setParamsToAxis = ( params: any, axis: any ) => Object.assign( axis, params );

    protected formatData = (
        data: Array<any>,
        method: string,
        baseKey: string,
        lang: string,
        parameters: any,
        descriptors: Array<Descriptor>,
        routines: Array<any>
    ): {categories: Array<any>, series: Array<any>, tooltipFormatter?: Highcharts.TooltipFormatterCallbackFunction;} => {
        const payload = {
            categories: {},
            series: {},
            tooltipFormatter: null
        };

        const drilledMethod = parameters.compute ? parameters.compute.method : null;

        switch( method ) {
            case null:
                break;
            case COMPUTING_METHODS.NORMALIZED_MEDIAN:
              LineChartNormalizedMedianCompute.doWork( data, baseKey, lang, parameters, descriptors, drilledMethod, payload, routines );
              const values: any = {} = Object.values( payload.series ).reduce( ( accumulator: Array<number>, serie: any ): Array<number> => {
                  return [ ...accumulator, ...serie.data.map( ( x: any ) => x.y ) ];
              }, [ 0 ] as Array<number> );
              parameters.yAxis.max = Math.max( ...values );
              break;


            case COMPUTING_METHODS.MEDIAN:
                LineChartMedianCompute.doWork( data, baseKey, lang, parameters, descriptors, drilledMethod, payload, routines );
                break;
            case COMPUTING_METHODS.DRILLED:
              LineChartDrilledCompute.doWork( data, baseKey, lang, parameters, descriptors, drilledMethod, payload, routines );
                if( drilledMethod == COMPUTING_METHODS.NORMALIZED_MEDIAN ) {
                    const values: any = {} = Object.values( payload.series ).reduce( ( accumulator: Array<number>, serie: any ): Array<number> => {
                        return [ ...accumulator, ...serie.data.map( ( x: any ) => Math.ceil( x.y ) ) ];
                    }, [ 0 ] as Array<number> );
                    parameters.yAxis.max = Math.max( ...values );

                }
                break;
            case COMPUTING_METHODS.MEDIAN_BENCH_REF:
                LineChartMedianWithBenchAsRef.doWork( data, baseKey, lang, parameters, payload, routines );
                break;
        };
        return {
            categories: Object.values( payload.categories ),
            series: Object.values( payload.series ),
            tooltipFormatter: payload.tooltipFormatter
        };
    };

    private onOptionsChange = ( options: any ) => {
        const routinesMapping: Map<string, any> = new Map();
        let gotColorChange = false;

        if( this.routines.routines ) {
            this.routines.routines.forEach( r => {
                routinesMapping.set( r.name, r );
            } );
        }

        let fontOptions: any;

        for( let o in options ) {
            if( o === 'general' ) {
                const {font, ...rest} = options[ o ];
                Object.assign( this.parameters, rest );
                fontOptions = font;
            } else {
                for( let e in options[ o ] ) {
                    if( Array.isArray( options[ o ][ e ] ) ) {
                        options[ o ][ e ].forEach( x => {
                            Object.assign( this.parameters[ e ].find( y => y.name === x.name ), x );
                            if( e === 'series' && routinesMapping.has( x.name ) ) {
                                gotColorChange = true;
                                routinesMapping.get( x.name ).color = x.color;
                            }
                        } );
                    } else {
                        Object.assign( this.parameters[ e ], options[ o ][ e ] );
                    }
                }
            }
        }
        const {yAxis, xAxis, ...parameters} = this.parameters;
        Object.assign( this.parameters, {...parameters} );
        Object.assign( this.parameters.yAxis, yAxis );
        Object.assign( this.parameters.xAxis, xAxis );

        if( gotColorChange ) this.custom.updateRoutines( this.routines.routines.map( x => x ) );

        const chart = this.build();
        if( fontOptions ) this.updateChartFont( chart, fontOptions );
        this.updateChartTranslations( chart, this.parameters, this.lang );
    };

    protected median = ( array: Array<any> ) => {
        const mid = Math.floor( array.length / 2 ),
            nums = [ ...array ].sort( ( a, b ) => a.value - b.value );
        return array.length % 2 !== 0 ? nums[ mid ].value : ( nums[ mid - 1 ].value + nums[ mid ].value ) / 2;
    };

    /**
     * updateChartTranslations
     * Method to manage languages changes
     * It mainly turns category from original language to the targeted one.
     * @param chart : any : Rendered chart
     * @param parameters : any : Chart's parameters
     * @param lang : string
    */
    private updateChartTranslations = ( chart: any, parameters: any, lang: string ) => {
        this.updateTitleTranslations( chart, lang, parameters );
        this.updateSubTitleTranslations( chart, lang, parameters );
        this.updateCategoriesTranslations( chart, parameters );
        chart.redraw();
        this.upadtePlotLinesAndBandVisibilty( chart, parameters );
    };

    /**
     * updateCategoriesTranslations
     * Method to update categories' translations
     * @param chart : Highcharts.Chart : Rendered chart
     * @param parameters : any : Chart's parameters
    */
    private updateCategoriesTranslations = ( chart: Highcharts.Chart, parameters: any ): void => {
        chart.xAxis[ 0 ].update( {
            categories: parameters.xAxis.categories
        } );
    };

    /**
     * updateYAxisScale
     * Method to update min/max value parameter for YAxis in the case where
     * normalized-linechart values are positives (it shouldn't, but we still have to handle this edge case),
     * and user is in drilled mode.
     * @param chart : Highcharts.Chart : Rendered chart
     * @param parameters : any : Chart's parameters
     */
    public updateYAxisScale = ( chart: Highcharts.Chart, parameters: any ): void => {
        chart.yAxis[ 0 ].update( {
            min: parameters.yAxis.min,
            max: parameters.yAxis.max,
        } );

    };

    /**
     * handleDrill
     * Method to manage drill up / down on chart data;
     * It delegates logical part to other functions
     * @param isDrilled : boolean : Drilled status
     */
    private handleDrill = (isDrilled: boolean): void => {
      isDrilled ? this._handleDrillUp() : this._handleDrillDown();
      this.upadtePlotLinesAndBandVisibilty( this.chart, this.parameters );
  };


    /**
    * _handleDrillDown
    */
    private _handleDrillDown = () => {
        while( this.chart.series.length ) {
            this.chart.series[ 0 ].remove();
        };
        this.parameters.series = [];

        const payload = this.formatData(
            _.cloneDeep(this.initialData),
            'drilled',
            this.parameters.compute ? this.parameters.compute.key : 'key',
            this.lang,
            this.parameters,
            this.descriptors,
            this.routines
        );
        this._parameters.xAxis.categories = payload.categories;
        if( this.parameters.hasOwnProperty( 'plotOptions' ) && this.parameters.plotOptions.hasOwnProperty( 'column' ) && this.parameters.plotOptions.column.hasOwnProperty( 'stacking' ) ) this.parameters.plotOptions.column.stacking = null;
        payload.series.forEach( element => {
            this.chart.addSeries( element );
            ( this.parameters.series = ( this.parameters.series || [] ) ).push( element );
        } );
        this.updateYAxisScale( this.chart, this.parameters );
        this.drilledState = true;
        this.onDrillSubject.next( 'drill' );
        this.unLinkRoutine();
    };


    /**
    * _handleDrillUp
    */
    private _handleDrillUp = () => {
      while( this.chart.series.length ) {
          this.chart.series[ 0 ].remove( false );
      };
      this.parameters.series = [];
      const payload = this.formatData(
          _.cloneDeep(this.initialData),
          this.parameters.compute ? this.parameters.compute.method : null,
          this.parameters.compute ? this.parameters.compute.key : 'key',
          this.lang,
          this.parameters,
          this.descriptors,
          this.routines
      );
      this._parameters.xAxis.categories = payload.categories;
      if( this.parameters.hasOwnProperty( 'plotOptions' ) && this.parameters.plotOptions.hasOwnProperty( 'column' ) && this.parameters.plotOptions.column.hasOwnProperty( 'stacking' ) ) this.parameters.plotOptions.column.stacking = 'value';
      payload.series.forEach( element => {
          this.chart.addSeries( element );
          ( this.parameters.series = ( this.parameters.series || [] ) ).push( element );
      } );
      this.updateYAxisScale( this.chart, this.parameters );
      this.drilledState = false;
      this.onDrillSubject.next( 'drill' );
      this.linkRoutinesToChart( this.chart );
  };


    protected formatRawData = ( data: Array<any>, lang: string ): {header: Array<any>, body: Array<any>;} => {
        try {
            const payload = JSON.parse( JSON.stringify( data[ 'data' ] ) ).reduce( ( accumulator: any, object: any, itemNumber: number ) => {
                const payload = object.values.reduce( ( reducer: any, item: any, index: number ) => {
                    item.values.reduce( ( redacc: any, value: any, idx: number ) => {
                        redacc[ value.label ] = value;
                        return redacc;
                    }, reducer );
                    return reducer;
                }, {} );

                const keyList = Object.keys( payload );
                const oPayload = object.values.reduce( ( reducer: any, item: any, index: number ) => {
                    if( item.values.length !== keyList.length ) {
                        for( let obj of keyList ) {
                            const foundObject = item.values.find( ( x: any ) => {return x.label === obj;} );
                            if( !foundObject ) {
                                item.values.push( {
                                    label: payload[ obj ].label,
                                    value: {label: "", key: ""}
                                } );
                            }
                        }
                    }

                    item.values.sort( ( a, b ) => {
                        const labelA = Chart.getObjectValueTranslation( a.label, lang );
                        const labelB = Chart.getObjectValueTranslation( b.label, lang );
                        return labelA.localeCompare( labelB );
                    } );

                    return reducer;
                }, object );
                accumulator.push( oPayload );
                return accumulator;
            }, [] );

            data[ 'data' ] = Object.values( payload.reduce( ( accumulator: any, object: any, itemNumber: number ) => {
                if( object.attribute.hasOwnProperty( 'blockName' ) ) {
                    const key = object.attribute.blockName.english;
                    accumulator[ key ] = accumulator[ key ] || {"attribute": {"label": object.attribute.blockName}, "values": []};
                    accumulator[ key ].values.push( object );
                }
                return accumulator;
            }, {} ) );
            const tableTemp = new Table( {}, data, lang );
            return tableTemp.parameters.transformedHTMLData;
        } catch( e ) {
            return {header: [], body: []};
        }
    };

    /**
     * upadtePlotLinesAndBandVisibilty
     * Method to hide/show grey zone feature for both xAxis & yAxis
     * @param chart : Highcharts.Chart : Rendered chart
     * @param parameters : any : Chart's parameters
     */
    private upadtePlotLinesAndBandVisibilty = ( chart: Highcharts.Chart, parameters: any ) => {
        for( let axis of chart.xAxis ) {
            for( let plotBand of axis[ 'plotLinesAndBands' ] ) {
                if( parameters.plotOptions.grey_zone.enabled ) {
                    plotBand.hidden = false;
                    plotBand.svgElem.show();
                } else {
                    plotBand.hidden = true;
                    plotBand.svgElem.hide();
                }
            }
        }
        for( let axis of chart.yAxis ) {
            for( let plotBand of axis[ 'plotLinesAndBands' ] ) {
                if( parameters.plotOptions.grey_zone.enabled ) {
                    plotBand.hidden = false;
                    plotBand.svgElem.show();
                } else {
                    plotBand.hidden = true;
                    plotBand.svgElem.hide();
                }
            }
        }
    };

    private unLinkRoutine () {
        if( !this.routines || !this.routines.routines || !Array.isArray( this.routines.routines ) || this.routines.routines.length === 0 ) return;
        this.routines.routines.forEach( routine => {
            routine.selected = false;
            routine.onClick = undefined;
        } );

        const newRoutines: any[] = this.routines.routines.map( r => r );

        this.custom.updateRoutines( newRoutines );
    }


    private updateChartFont = ( chart: Highcharts.Chart, option: {size: number, bold: boolean;} ): void => {
        chart.yAxis[ 0 ].update( {
        labels: {
            ...chart.yAxis[0].userOptions.labels,
            style: {
            fontSize: option.size + 'px',
            fontWeight: option.bold ? 'bold' : 'normal',
            }
        }
        }, false );

        chart.xAxis[ 0 ].update( {
        labels: {
            ...chart.xAxis[ 0 ].userOptions.labels,
            style: {
            fontSize: option.size + 'px',
            fontWeight: option.bold ? 'bold' : 'normal'
            }
        }
        }, false );
    }

};
