import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Answer } from '../../../../../../types';
import { ApplicationInsightsService } from '../../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-modal-edit-answer',
  templateUrl: './modal-edit-answer.component.html',
  styleUrls: ['./modal-edit-answer.component.less']
})
export class ModalEditAnswerComponent implements OnInit, AfterViewInit {

  @Input() currentLanguage: string;
  @Input() answer: Answer;

  changeValueForm: FormGroup;
  initTime = performance.now();

  constructor(
    private activeModal: NgbActiveModal,
    private aiService: ApplicationInsightsService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    if (!this.answer) {
      this.activeModal.dismiss();
    } else {
      this.initialiseAnswers(this.answer);
    }
  }

  ngAfterViewInit() {
    this.aiService.logPageView('Edit Answer Modal', '', performance.now() - this.initTime, 'Modals');
  }

  cancel() {
    this.activeModal.dismiss();
  }

  getFormData() {
    return <FormArray>this.changeValueForm.get('records');
  }

  initialiseAnswers(answer: Answer) {
    const recordArray = answer.records.map(record => {
      return this.formBuilder.group({
        newValue: [record.data[0] ? record.data[0].value || '-' : '-', Validators.required]
      });
    });

    this.changeValueForm = this.formBuilder.group({
      label: answer.label[this.currentLanguage] || answer.label['english'] || '-',
      records: this.formBuilder.array(recordArray)
    });
  }

  save(form: FormGroup, indexRecord) {
    this.answer.records[indexRecord].data[0].value = form.value.records[indexRecord].newValue;
    this.activeModal.close(this.answer);
  }

}
