<div class="container">
  <ngx-loading [show]="showSpinner">
  </ngx-loading>
  <div *ngIf="!showSpinner && !error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "GRAPH" | translate }}</h5>
        </div>
        <div class="col-12 col-md-8">
          <div *ngIf="isWorkflowChanged; else isNotChanged" class="float-end">
            <ng-container>
              <dna-save-cancel (onClickSave)="save()" (onClickCancel)="onCancel()"></dna-save-cancel>
            </ng-container>
          </div>
          <ng-template #isNotChanged>
              <div class="float-end ng-star-inserted">
                  <button class="btn btn-primary no-margin-bottom" id="back" type="button" (click)="onBack()" translate>DISPLAY_VIEW_REPORT</button>
              </div>
          </ng-template>
        </div>
      </div>
    </div>
    <dna-graphs-page [graphs]="graphs" [workflow]="workflow" [descriptorsInWorkflow]="descriptorsInWorkflow" [protocol_methods]="campaign.protocol_methods" [editMode]="true" [hub]="campaign.hub"
    (isWorkflowChanged)="graphs = $event; isWorkflowChanged = true"></dna-graphs-page>
  </div>
  <div *ngIf="!showSpinner && error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "ERROR" | translate }}</h5>
        </div>
      </div>
    </div>
    <dna-error-page (onClickReload)="init()"></dna-error-page>
  </div>
</div>
