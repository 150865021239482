<div class="modal-header">
    <h4 class="modal-title" id="modal-confirm-name" translate>AUTO_CREATE_GROUP_DESCRIPTOR</h4>
</div>
<div class="modal-body">
    <div class="form-group">
        <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label required" for="workflow">
                {{'WORKFLOW' | translate}} - {{'CAMPAIGN' | translate}} <i ngbTooltip="{{'AUTO_CREATE_WORKFLOW_TOOLTIP' | translate}}" class="fa fa-info-circle"></i>
            </label>
            <div class="col-12 col-md-9">
                <select class="form-control" [(ngModel)]="selectedWorkflow"
                    name="workflow">
                    <option *ngFor="let wk of workflows" [ngValue]="wk">
                        <dna-translate [fieldToTranslate]="wk.name"> </dna-translate> - {{wk.campaignName}} 
                    </option>
                </select>
            </div>
        </div>
        <div class="row" style="padding-top:20px">
            <label class="col-12 col-md-3 my-auto form-control-label required" for="pertinence">
                {{'PERTINANCE' | translate}} % <i ngbTooltip="{{'AUTO_CREATE_PERTINANCE_TOOLTIP' | translate}}" class="fa fa-info-circle"></i>
            </label>
            <div class="col-12 col-md-9">
                <input class="form-control" [(ngModel)]="pertinence" type="number" name="pertinence">
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close({selectedWorkflow: selectedWorkflow, pertinence: pertinence})" translate>CONFIRM</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()" translate>CANCEL</button>
</div>