<div class="container">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <div class="ibox float-e-margins">
    <div class="ibox-title">
      <h5 class="text-center">{{ 'DETAIL' | translate: { object: 'EVALUATION' | translate | lowercase } }}</h5>
      <div>
        <button class="btn btn-primary" (click)="goBack()" translate>BACK_LIST</button>
      </div>
    </div>
    <div class="ibox-content">
      <ng-container *ngIf="(objects$ | async) as objects">
        <div class="form-group">
          <div class="row mb-3">
            <label class="col-3 form-control-label">{{ "WORKFLOW" | translate }}</label>
            <dna-translate class="col-9" [fieldToTranslate]="objects[0].workflowName"> </dna-translate>
          </div>
          <div class="row">
            <label class="col-3 form-control-label">{{ "FORMULA" | translate }} </label>
            <p class="col-9">{{ objects[0].formula.name }}</p>
          </div>
          <div class="row">
            <label class="col-3 form-control-label">{{ "USERS" | translate }} </label>
            <p class="col-9">
              <ng-container *ngFor="let user of objects[0].users; let isLast = last">
                {{ user.name }}{{ isLast ? "" : ", " }}
              </ng-container>
            </p>
          </div>
          <div *ngIf="objects[0].volunteer">
            <div class="row">
              <label class="col-3 form-control-label">{{ "VOLUNTEER" | translate }}</label>
              <p class="col-9">{{ objects[0].volunteer.name }}</p>
            </div>
          </div>
        </div>
        <dna-table [data]="objects[0].answers" [headers]="tableHeaders$ | async" orderedBy="INDEX"
          [reverse]="false" [sortFunction]="sort" [title]="'ANSWERS'">
          <ng-template #row let-data="data">
            <tr dna-detail-evaluation-row class="cursor-pointer" [answer]="data" [idCampaign]="idCampaign$ | async" [currentLanguage]="currentLanguage"
              [evaluation]="objects[0]"></tr>
          </ng-template>
        </dna-table>
      </ng-container>
    </div>
  </div>
</div>
