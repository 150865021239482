<div class="modal-scroll">
  <div class="modal-header">
    <h3 class="text-center" translate>CUSTOMIZE_VIEW</h3>
  </div>
  <div class="modal-body">
    <div class='row'>
      <table class="table table-borderless-th">
        <thead>
          <th class="text-center" translate>VISITS</th>
          <th class="text-center" translate>PAGES</th>
          <th class="text-center" translate>BLOCKS</th>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">
              <div class="col-sm-12">
                <div class="list-group" *ngFor='let visitObject of originalCampaign.visits; let indexVisit = index'>
                  <div class="list-group-item cursor-pointer" [ngClass]="{'active':visit.id === visitObject.id}" (click)='selectVisit(visitObject)'>
                    <span>
                      {{ visitObject.name }}
                    </span>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">
              <div class="col-sm-12">
                <div class="list-group" *ngFor='let page of pages'>
                  <div class="list-group-item"> {{ page }} </div>
                </div>
              </div>
            </td>
            <td class="text-center">
              <div class="col-sm-12">
                <div class="list-group" *ngFor='let block of activeBlocks; let indexBlock = index'>
                  <div class="list-group-item" [ngClass]="{'active': pages[indexBlock] === pages[indexBlock + 1] || ( indexBlock >=1 && pages[indexBlock] === pages[indexBlock - 1] )}"
                    (click)="selectOrDeselectBlocks(indexBlock)">
                    <span class="cursor-pointer">
                      {{ blockNameWithSuffix(block, visit) }}
                    </span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button id="save" class="btn btn-primary mb-4" (click)="save()" translate>SAVE</button>
  <button id="cancel" class="btn btn-default mb-4" (click)="cancel()" translate>CANCEL</button>
</div>
