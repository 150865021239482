<ngx-loading [show]="showSpinner"></ngx-loading>
<ng-container *ngIf="!showSpinner">
    <router-outlet *ngIf="!error"></router-outlet>
    <div class="container" *ngIf="error">
        <div class="ibox float-e-margins">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-12 col-md-4 my-auto">
                        <h5>{{ "ANALYTICS" | translate }}</h5>
                    </div>
                </div>
            </div>
            <div class="ibox-content">
                <div>
                    <dna-error-page (onClickReload)="ngOnInit()"></dna-error-page>
                </div>
            </div>
        </div>
    </div>
</ng-container>
