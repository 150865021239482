<div *ngFor="let image of sortByPageAdd(images); let index = index" [title]="image.title"
     class="col-8 gallery-container"
     (mouseenter)="applyHoverStyle($event, image.id)" (mouseleave)="removeHoverStyle($event, image.id)"
     [ngStyle]="{ 'border': isImageLarge(image.id) ? '2px solid orange' : 'none', 'padding': '15px' }">
  <div class="row" style="margin: 0">
    <div class="img-parent">
      <img *ngIf="image.data | async as imgData; else loading" [src]="imgData"
           (click)="openModalImage(image, imgData)" class="img-fluid img-reduced img-thumbnail"
           style="width:auto; cursor: zoom-in;">
      <ng-template #loading>Loading...</ng-template>
    </div>
  </div>
  <div class="row">
    <label class="label-image">{{ image.name }}</label>
  </div>
  <div [ngClass]="image.pagesAdded > 0 ? '' : 'd-flex flex-row justify-content-center gap-1'">
    <button *ngIf="image.pagesAdded === 0" class="btn btn-primary btn-sm m-r-sm" type="button"
            (click)="addImageToPage(image, page)">{{ 'ADD' | translate }}
    </button>
    <div class="img-added"
         *ngIf="image.pagesAdded > 0">{{ 'IMAGES_ADDED_TO_PAGES' | translate }} {{ checkCurrent(image) ? 'actuelle' : image.pagesAdded }}
    </div>
    <div class="d-flex flex-row justify-content-center gap-1">
      <button *ngIf="image.pagesAdded !== 0 && checkCurrent(image)" class="btn btn-warning btn-sm" type="button"
              (click)="removeImageFromPage(image)">{{ 'REMOVE' | translate }}
      </button>
      <button class="btn btn-warning btn-sm" type="button"
              (click)="deleteImage(image, index)">{{ 'DELETE' | translate }}
      </button>
    </div>
  </div>
</div>

