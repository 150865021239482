import { Campaign, CampaignsMulti, Graph } from './../../../types';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AnalyseMultiService {

  serverUrlStudio: string;
  campaignMulti: CampaignsMulti;

  constructor(
    private http: HttpClient
  ) {
    this.serverUrlStudio = environment.server_url_studio();
  }

  saveCampaignMulti(campaignMulti: CampaignsMulti) : void {
    this.campaignMulti = campaignMulti;
  }

  getCampaignMulti(): CampaignsMulti {
    return this.campaignMulti;
  }

  getChartsFromCampaignMulti(idCampaign: string): Observable<any> {
      return this.http.get<any>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${idCampaign}/graphs`);
  }

  getChartByIdCampaignMulti(idCampaign: string, chart: Graph, filters: any, campaigns: Campaign[]): Observable<any> {
    return this.http.post<any>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${idCampaign}/graphs`, { graph: chart, filters, campaigns});
}
}
