import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MultiCampaignsService } from '../../multi-campaigns.service';
import { throwError } from 'rxjs';
import { catchError, tap, take } from 'rxjs/operators';


@Component({
  selector: 'dna-descriptors-multi-root',
  templateUrl: './descriptors-multi-root.component.html',
  styleUrls: ['./descriptors-multi-root.component.less']
})
export class DescriptorMultiRootComponent implements OnInit {
  showSpinner: boolean;
  error: boolean;

  constructor(
    private multiCampaignsService: MultiCampaignsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.showSpinner = true;
    this.error = false;
    let id = this.route.parent.snapshot.paramMap.get('idCampaignMulti');
    this.multiCampaignsService.getCampaignsMultiDescriptors(id).pipe(
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        return throwError(err);
      }),
      tap(campaignMulti => this.multiCampaignsService.setLocalCampaignMultiDescriptor(campaignMulti)),
      tap(campaignMulti => this.multiCampaignsService.hasNameChanged.next(campaignMulti.name)),
      tap(() => this.showSpinner = false),
      take(1)
    ).subscribe()
  }

}


