import { DNAObject } from '../../types';

import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dna-brick-state',
    templateUrl: './brickState.component.html'
})

export class BrickStateComponent implements OnInit {
    @Input() item: DNAObject;

    constructor() { }

    ngOnInit() { }
}