<div class="ibox float-e-margins">
  <div class="ibox-title">
    <h5 translate>USER</h5>
    <div class="ibox-tools">
    </div>
  </div>
  <div class="ibox-content">
    {{ user.displayName }}
  </div>
</div>
