<dna-layout>
  <h1 class="mb-4 " id="banner-title" translate>CHARTS_SETTINGS</h1>
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/myprofile" translate>MY_PROFILE</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/myprofile/workspaces" translate>WORKSPACES</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>CHARTS_SETTINGS</li>
    </ol>
  </nav>
  <div class="actions" id="banner-actions">
    <button *ngIf="!canSave()"class="btn btn-primary mb-4" (click)="goToWorkspaces()">
      <span translate>BACK</span>
    </button>
    <button *ngIf="canSave()" class="btn btn-primary mb-4" (click)="save()">
      <span translate>SAVE</span>
    </button>
    <button *ngIf="canSave()" class="btn btn-primary mb-4" (click)="cancel()">
      <span translate>CANCEL</span>
    </button>
  </div>
  <ngx-loading [show]="showSpinner">
  </ngx-loading>

  <div class="block" *ngIf="!showSpinner">
    <div class="ibox float-e-margins">
      <div class="ibox-content">
        <div class="row">
          <div class="form-group col-12">
            <form>
              <ngx-loading [show]="showSpinnerSave"></ngx-loading>
              <div class="row justify-content-md-center">
                <div class="col-12 col-md-9 my-auto">
                  <table class="table table-hover table-borderless-th dataTable">
                  <tbody>
                    <thead class="header-first-table">
                      <th scope="col" colspan="2" translate> GRAPH_PREFERENCES </th>
                    </thead>
                    <tr class="header-second-table">
                      <td class="param" translate> OPTIONS </td>
                      <td class="value" translate> VALUE </td>
                    </tr>
                    <ng-container *ngFor="let preference of chartsPreferences; let index=index">
                      <tr *ngIf="allGraph[preference]" class="center">
                        <td translate> {{ workspace.chartsPreferences[preference].name }} </td>
                        <td>
                            {{ 'NO' | translate }}
                            <dna-switch [isSelected]="workspace.chartsPreferences[preference].value"
                              (onBooleanChanged)="changeAllGraphValue(preference)">
                            </dna-switch>
                            {{ 'YES' | translate }}
                        </td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                  <ng-container *ngFor="let chartSettings of workspace.chartsSettings; let index=index">
                    <table class="table table-hover table-borderless-th dataTable">
                      <thead class="header-first-table">
                        <th scope="col" colspan="2" translate> {{chartSettings.name}} </th>
                      </thead>
                      <tr *ngIf="containsParam(chartSettings.id)" class="header-second-table">
                        <td class="param" translate> PARAMETERS </td>
                        <td class="value" translate> VALUE </td>
                      </tr>
                      <tbody>
                        <!--Scale-->
                        <tr *ngIf="isGraphSetting(chartSettings.id, 'scale')">
                          <td class="center" translate> CHARTS.SCALE </td>
                          <td class="center">
                            <label class="offset-1">min </label>
                            <input class="col-2" [(ngModel)]="chartSettings.scale.min" type="number"
                              [name]="'scaleMin'+index" required>
                            <label class="offset-1">max </label>
                            <input class="col-2" [(ngModel)]="chartSettings.scale.max" type="number"
                              [name]="'scaleMax'+index" required>
                            <span class="offset-5 error" *ngIf="chartSettings.scale.min >= chartSettings.scale.max"
                              translate>ERROR_MIN</span>
                          </td>
                        </tr>
                        <!--plotBands-->
                        <tr *ngIf="isGraphSetting(chartSettings.id, 'plotBands')">
                          <td class="center" translate> CHARTS.PLOT_BANDS </td>
                          <td class="center">
                            <label class="offset-1">min </label>
                            <input class="col-2" [(ngModel)]="chartSettings.plotBands.min" type="number"
                              [name]="'plotBandsMin'+index" required>
                            <label class="offset-1">max </label>
                            <input class="col-2" [(ngModel)]="chartSettings.plotBands.max" type="number"
                              [name]="'plotBandsMax'+index" required>
                            <span class="offset-5 error"
                              *ngIf="chartSettings.plotBands.min >= chartSettings.plotBands.max"
                              translate>ERROR_MIN</span>
                          </td>
                        </tr>
                        <!--VisiblityTheashold-->
                        <tr *ngIf="isGraphSetting(chartSettings.id, 'visibilityThreshold')">
                          <td class="center" translate> CHARTS.VISIBILITY_THRESHOLD </td>
                          <td class="center">
                            <input class="col-2" [(ngModel)]="chartSettings.visibilityThreshold" type="number"
                              [name]="'visibilityThreshold'+index" required>
                          </td>
                        </tr>
                        <!--Axis-->
                        <tr *ngIf="isGraphSetting(chartSettings.id, 'axis')">
                          <td class="center" translate> CHARTS.AXIS </td>
                          <td class="center">
                            <label class="offset-2">Axe X : min </label>
                            <input class="col-2" [(ngModel)]="chartSettings.axis.x.min" type="number"
                              [name]="'xMin'+index" required>
                            <label class="offset-1"> max </label>
                            <input class="col-2" [(ngModel)]="chartSettings.axis.x.max" type="number"
                              [name]="'xMax'+index" required>
                            <label class="offset-2">Axe Y : min </label>
                            <input class="col-2" [(ngModel)]="chartSettings.axis.y.min" type="number"
                              [name]="'yMin'+index" required>
                            <label class="offset-1"> max </label>
                            <input class="col-2" [(ngModel)]="chartSettings.axis.y.max" type="number"
                              [name]="'yMax'+index" required>
                            <span class="offset-5 error"
                              *ngIf="chartSettings.axis.x.min >= chartSettings.axis.x.max || chartSettings.axis.y.min >= chartSettings.axis.y.max"
                              translate>ERROR_MIN</span>
                          </td>
                        </tr>
                        <!--DecisionRules-->
                        <tr *ngIf="isGraphSetting(chartSettings.id, 'decisionRules')">
                          <td class="center" translate> CHARTS.DECISION_RULES </td>
                          <td class="center">
                            <ng-container *ngFor="let decisionRule of chartSettings.decisionRules; let indexDR = index">
                              <label class="offset-1">min </label>
                              <input class="col-2" [(ngModel)]="chartSettings.decisionRules[indexDR].min" type="number"
                                [name]="'min'+indexDR" required>
                              <label class="offset-1">max </label>
                              <input class="col-2" [(ngModel)]="chartSettings.decisionRules[indexDR].max" type="number"
                                [name]="'max'+indexDR" required>
                              <label class="offset-1">value </label>
                              <input class="col-2" [(ngModel)]="chartSettings.decisionRules[indexDR].value" type="text"
                                [name]="'value'+indexDR" required>
                              <a class="fa fa-times mr-1" (click)="removeDecisionRule(chartSettings.id, indexDR)"></a>
                              <span class="offset-3 error"
                                *ngIf="chartSettings.decisionRules[indexDR].min >= chartSettings.decisionRules[indexDR].max"
                                translate>ERROR_MIN</span>
                            </ng-container>
                            <a class="link" (click)="addNewDecisionRule(chartSettings.id)"
                              translate>ADD_DECISION_RULE</a>
                          </td>
                        </tr>
                        <tr class="header-second-table">
                          <td class="param" translate> OPTIONS </td>
                          <td class="value" translate> VALUE </td>
                        </tr>
                        <ng-container *ngFor="let preference of chartsPreferences; let index=index">
                          <tr *ngIf="isGraphSetting(chartSettings.id, preference)" class="center">
                            <td translate> {{ workspace.chartsPreferences[preference].name }} </td>
                            <td>
                              {{ 'NO' | translate }}
                              <dna-switch [isSelected]="chartSettings[preference]"
                                (onBooleanChanged)="chartSettings[preference] = !chartSettings[preference]">
                              </dna-switch>
                              {{ 'YES' | translate }}
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </ng-container>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</dna-layout>
