import { UtilService } from '../../../shared/services/util.service';
import { DNAComponent, PhotoSize } from '../../../types';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dna-photos-config',
    templateUrl: './photosConfiguration.component.html',
    styleUrls: ['./photosConfiguration.component.less']
})

export class PhotosConfigurationComponent implements OnInit {
    @Input() inactive: boolean;
    @Input() component: DNAComponent;
    photoSizes: PhotoSize[]

    constructor(private utilService: UtilService) { }

    ngOnInit() {
        this.initComponent();
        console.log("component:",this.component)
    }

    initComponent() {
        if (!this.component.args.hasFrame) {
            this.component.args.hasFrame = false;
        }
    }
}

