
import { Injectable } from '@angular/core';

import { IndividualConfig, ToastrService } from 'ngx-toastr';

import { DNATranslateService } from './translate.service';

@Injectable()
export class ErrorManagerService {

    constructor(
        private toastr: ToastrService,
        private translateService: DNATranslateService
    ) { }

    catchError(res: Response) {
        let message = 'UNKNOW_ERROR';
        switch (res.status) {
            case 404: message = '404_ERROR';
                break;
            case 409: message = '409_ERROR';
                break;

        }
        this.translateService.translateMessage(message).subscribe(messageTranslated =>
            this.toastr.error(messageTranslated)
        );
    }

    displayMessage(message: string, type: string = 'success', options: Partial<IndividualConfig> = {}, translate: boolean = true) {
        options.progressBar = true;
        if (translate) {
            this.translateService.translateMessage(message).subscribe(messageTranslated =>
                this.sendMessage(messageTranslated, type, options)
            );
        }
        else {
            this.sendMessage(message, type, options);
        }
    }

    sendMessage(message: string, type: string, options: Partial<IndividualConfig>) {
        /**
         * The setTimeout function is used to fix "ExpressionChangedAfterItHasBeenCheckedError" error on toasts.
         * This solution is temporary.
         */
        switch (type) {
            case 'danger':
                setTimeout(() => this.toastr.error(message, '', options));
                break;
            case 'success':
                setTimeout(() => this.toastr.success(message, '', options));
                break;
            case 'warning':
                setTimeout(() => this.toastr.warning(message, '', options));
                break;
            default:
                setTimeout(() => this.toastr.info(message, '', options));
                break;
        }
    }
}
