<form [formGroup]="editBlockForm" (ngSubmit)="onSubmit(editBlockForm)">
  <div class="ibox float-e-margins">
    <div class="ibox-title">
      <h5>
        <span translate>PARAMETERS</span>
      </h5>
    </div>
    <div class="ibox-content">
      <div class="form-group row">
        <div class="col-sm-2">
          <label>{{ 'CHARTS.CHART_CHOOSE_TYPE' | translate }}:</label>
        </div>
        <div class="col-sm-4">
          <select class="form-control" formControlName="chartType">
            <option *ngFor="let type of chartTypes" [value]="type.key" translate>{{type.value}}</option>
          </select>
        </div>
      </div>

      <div class="table-responsive" formGroupName="chartName">
        <table class="table table-hover table-borderless-th">
          <thead>
            <tr>
              <th translate>LANGUAGE</th>
              <th translate>CHARTS.CHART_NAME</th>
            </tr>
          </thead>
          <tbody>
            <tr class="cursor-pointer" *ngFor="let language of languages">
              <td>
                <label translate>{{language}}</label>
              </td>
              <td>
                <div class="form-group">
                  <input class="form-control" formControlName="{{language}}" type="text">
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <button class="btn btn-primary mb-4" [disabled]="editBlockForm.pristine" type="submit">
        <span translate>VALIDATE</span>
      </button>
      <button class="btn btn-primary mb-4" type="button" (click)="cancel()">
        <span translate>CANCEL</span>
      </button>
    </div>
  </div>
</form>
