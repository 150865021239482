import { Chart } from "../../chart.model";

const doWork = (data: Array<any>, baseKey: string, lang: string, descriptors: any, payload: any, routinesObject : any): any => {
    const routines = routinesObject && routinesObject.hasOwnProperty('routines') && routinesObject.routines && routinesObject.routines.length ? routinesObject.routines.sort((a, b) => b.isBench - a.isBench) : [];
    descriptors.reduce(( reducer : object, descriptor : any ) => {
        const key = [descriptor.key, Chart.getObjectValueTranslation(descriptor.label, lang), Chart.getObjectValueTranslation(descriptor.attribute, lang)].join('_');
        reducer[key] = { ...descriptor };
        return reducer;
    }, payload.categories);

    return data.reduce((accumulator: any, item: any) => {
        if (item.hasOwnProperty('values') && Array.isArray(item.values)) {
           item.values.reduce(( reducer : {categories : object, series : object}, object : any, idx : number) => {
                const keySerie = Chart.getObjectValueTranslation(object.attribute.label, lang);
                let color;
                let foundRoutine = routines.find((routine : any ) => routine.name == keySerie );
                if (!foundRoutine || !foundRoutine.color) color = Chart.colorScale[idx];
                else color = foundRoutine.color;
                reducer.series[keySerie] = (reducer.series[keySerie] || {
                    name: Chart.getObjectValueTranslation(object.attribute.label, lang),
                    color,
                    data : []
                });

                for (const category of Object.values(reducer.categories).filter(( category ) => {
                    return Chart.getObjectValueTranslation(item.attribute.label, lang) === Chart.getObjectValueTranslation(category.attribute, lang)
                })) {
                    const pl = [...object.values].filter(( { value } : any) => value[baseKey] === category[baseKey]);
                    reducer.series[keySerie].data.push({
                        y : pl.length,
                        x : Object.values(reducer.categories).findIndex(x => x == category),
                        details: pl
                    })
                }
                return reducer;
            }, accumulator);
        }
        return accumulator;
    }, payload);
};

export {
    doWork
};