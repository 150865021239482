<div class="modal-header">
  <button class="close" type="button" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
    <span class="sr-only">Close</span>
  </button>
  <i class="fa fa-comments modal-icon"></i>
  <h4 class="modal-title">{{'FEEDBACK' | translate}}</h4>
  <small translate>FEEDBACK_DESCRIPTION</small>
</div>
<div class="modal-body">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <form role="form" #feedbackForm="ngForm">
    <div class="form-group">
      <label class="required" translate>TITRE</label>
      <input class="form-control" [ngClass]="{'is-invalid':title.touched && !title.valid}" type="text" [(ngModel)]="model.title" #title="ngModel"
        name="title" required>
      <div class="invalid-feedback">
        <small translate>EMPTY_TITLE</small>
      </div>
    </div>
    <div class="form-group">
      <label class="required" translate>FEEDBACK</label>
      <textarea class="form-control" [ngClass]="{'is-invalid':comments.touched && !comments.valid}" name="comments" [(ngModel)]="model.comments"
        #comments="ngModel" rows="5" required></textarea>
      <div class="invalid-feedback">
        <small translate>EMPTY_COMMENT</small>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="cancel()" translate>CANCEL</button>
  <button class="btn btn-primary" type="button" (click)="ok(feedbackForm, model)" translate>SEND</button>
</div>
