import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'applyTranslation'
})
export class ApplyTranslationPipe implements PipeTransform {

  transform(value: any, lang?: any, fallback? : any): any {
    switch (typeof value) {
      case 'number' :
        return value;
      case 'string':
        return value;
      case 'object':
        if( value && value.hasOwnProperty('user') && value.user.length) return value.user;
        else if( value && value.hasOwnProperty(lang) && value[lang].length) return value[lang];
        else if (value && value.hasOwnProperty('custom')) return ' - ';
        else if (value) return value[fallback];
        else return null;
    }
  };

};
