
import {of as observableOf, throwError as observableThrowError, 
  Observable
} from 'rxjs';

import {catchError, mergeMap} from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  Input
} from '@angular/core';
import {
  Volunteers
} from '../../../../types';
import {
  VolunteerService
} from '../../../../volunteers/volunteers.service';
import {
  ErrorManagerService
} from '../../../../shared/services/errorManager.service';
import {
  NgbActiveModal
} from "@ng-bootstrap/ng-bootstrap";
import {
  NgForm
} from "@angular/forms";

@Component({
  selector: 'dna-volunteer',
  templateUrl: './createVolunteerModal.component.html'
})

export class CreateModalComponent implements OnInit {
  @Input() volunteer: Volunteers;
  volunteerCopy: Volunteers;
  doRegister: boolean = false;

  constructor(
    private volunteerService: VolunteerService,
    private errorManager: ErrorManagerService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    if (!this.volunteerCopy) {
      this.volunteerCopy = new Volunteers;
    }
  }

  cancel() {
    this.activeModal.dismiss();
  };

  save(form: NgForm) {
    if (form.valid) {
      this.registerVolunteer(this.volunteerCopy).pipe(mergeMap(() => this.postVolunteer(this.volunteerCopy)))
        .subscribe(() => {
            this.errorManager.displayMessage('ON_SUCCESS_CREATE');
            this.activeModal.close();
          },
          error => this.errorManager.displayMessage(error, "danger")
        )
    } else {
      this.errorManager.displayMessage('ON_ERROR_FORM', "danger");
    }
  }

  postVolunteer(volunteer: Volunteers) {
     return this.volunteerService.createVolunteers([volunteer]).pipe(mergeMap(volunteers => {
       if(volunteers.length === 0) return observableThrowError('VOLUNTEER_ALREADY_EXISTS');
       return observableOf(volunteers);
     }));
  }

  registerVolunteer(volunteer: Volunteers) {
    if (this.doRegister) {
      return this.volunteerService.register(volunteer).pipe(catchError(this.onErrorRequest));
    }
    return observableOf(volunteer);
  }

  onErrorRequest(err: HttpErrorResponse) {
    if(err.status === 409) {
       return observableThrowError('VOLUNTEER_ALREADY_EXISTS');
    }
    return observableThrowError('UNKNOW_ERROR');
  }

}
