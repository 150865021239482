<ngx-loading [show]="showSpinner"></ngx-loading>
<div class="modal-header">
  <h4 class="modal-title"> {{ "ENTER_INFORMATIONS" | translate }}</h4>
  <button class="close" type="button" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true" class="fa fa-times"></span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <h4 class="required" translate>MILOR_ARCHIVE_TITLE</h4>
    <div><span class="small">{{title}}</span></div>
  </div>
  <div class="form-group">
    <h4 class="required" translate>MILOR_ARCHIVE_LANGUAGE</h4>
    <div *ngFor="let language of languages">
      <input type="radio" id="language" name="lang" [value]="language" [(ngModel)]="lang" (click)="changeLanguage(language)">
      <label for="language" translate>{{ language }}</label>
    </div>
  </div>
  <div class="form-group">
    <h4 translate>OPTIONS</h4>
    <div (click)="selectOption('actiview', $event); false">
      <label translate>
        <span class="fa" [ngClass]="activiewSelected ? 'fa-check-square-o' : 'fa-square-o'"></span>
        PROJECTS.PROJECT_NUMBER
      </label>
    </div>
    <div (click)="selectOption('orchestra', $event); false">
      <label translate>
        <span class="fa" [ngClass]="orchestraSelected ? 'fa-check-square-o' : 'fa-square-o'"></span>
        ORCHESTRA_NUMBER
      </label>
    </div>
    <div>
      <h4 translate>
      <dna-switch [isSelected]="workspaceSelected"
        (onBooleanChanged)="hybridStudySelected($event); selectOption('hybrid', $event)">
      </dna-switch>
      STUDY_HYBRID</h4>
    </div>
    <div class="form-group" *ngIf="workspaceSelected">
      <h5 translate>WORKSPACES</h5>
      <div *ngFor="let workspace of workspaces">
        <label (click)="selectOption('workspace', $event, workspace)">
          <i class="fa"
            [ngClass]="{'fa-check-square-o': workspacesSelected[workspace.id], 'fa-square-o': !workspacesSelected[workspace.id]}"></i>
          {{ workspace.name }}
        </label>
      </div>
    </div>
  </div>
  <!-- <div class="form-group">
    <h4 class="required" translate>RECIPIENTS</h4>
    <tag-input [(ngModel)]="recipients" secondaryPlaceholder="+ {{ 'ADD_RECIPIENTS_MAIL' | translate }}"
      placeholder="+ {{ 'RECIPIENTS' | translate }}">
    </tag-input>
  </div> -->
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="submit" (click)="validate(title)">{{ "VALIDATE" | translate
    }}</button>
  <button class="btn btn-default" type="button" (click)="cancel()">{{ "CANCEL" | translate }}</button>
</div>
