import { BoxPlotOptionsType, ChartOption } from '../../interfaces';
import { Chart } from '../chart.model';
import { OptionType } from '../../enums';

export class BoxPlotOptions {

    public options : BoxPlotOptionsType = {
        general : {label : null,options : [] }, xAxis : {label : null,options : []},yAxis : {label : null,options : []},series : {label : null,options : []}
    };

    public callback : Function;

    constructor(
    {
        general,
        xAxis,
        yAxis,
        series
    }, callback : Function) {
        this.callback = callback;
        // GENERAL PART
        this.options['general'].label = (general.label || 'VIZUALIZATIONS.GENERAL');
        try { this.options['general'].options.push({ label : 'VIZUALIZATIONS.CHART_TITLE', key: "chart_title", value : (general.title.text || null), type : OptionType.Text });} 
        catch(e) { this.options['general'].options.push({ label : 'VIZUALIZATIONS.CHART_TITLE', key: 'chart_title', value : null, type : OptionType.Text });}
        try { this.options['general'].options.push({ label : 'VIZUALIZATIONS.CHART_SUBTITLE',  key: 'chart_subtitle', value : (general.subtitle.text || null), type : OptionType.Text });}
        catch(e) { this.options['general'].options.push({ label : 'VIZUALIZATIONS.CHART_SUBTITLE', key: 'chart_subtitle', value : null, type : OptionType.Text });}

        try {
            this.options[ 'general' ].options.push( { 
                label: 'Chart text size',
                key: 'chart_font_size',
                value: 11,
                type: OptionType.Number,
            })
        } catch( e ) {
            console.error( 'error when creating font size option' );
        }
        try {
            this.options[ 'general' ].options.push( {
                label: 'Chart text bold',
                key: 'chart_font_bold',
                value: false,
                type: OptionType.Checkbox,
            })
        } catch( e ) {
            console.error( 'error when creating font bold option' );
        }

        // xAXIS PART
        this.options.xAxis.label = (xAxis.label || 'VIZUALIZATIONS.X_AXIS');
        try { this.options.xAxis.options.push({ label : (xAxis.title.label || 'VIZUALIZATIONS.X_AXIS_LABEL'), key: 'x_axis_label', value : (xAxis.title.text || null), type : OptionType.Text }); }
        catch(e) { this.options.xAxis.options.push({ label : 'VIZUALIZATIONS.X_AXIS_LABEL', key: 'x_axis_label', value : null, type : OptionType.Text }); }
        // yAXIS PART
        this.options.yAxis.label = (xAxis.label || 'VIZUALIZATIONS.Y_AXIS');
        try { this.options.yAxis.options.push({ label : (yAxis.title.label || 'VIZUALIZATIONS.Y_AXIS_LABEL'), key: 'y_axis_label', value : (yAxis.title.text || null), type : OptionType.Text }); }
        catch(e) { this.options.yAxis.options.push({ label : 'VIZUALIZATIONS.Y_AXIS_LABEL', key: 'y_axis_label', value : null, type : OptionType.Text }); }
        // SERIES PART
        this.options.series.label = (series && series.label || 'VIZUALIZATIONS.SERIES');
        try {
            this.options.series.options = series.reduce(( accumulator : Array<ChartOption>, serie : any, index : number) => {
                accumulator.push({ label : (serie.name || index.toString()), value : (serie.color || Chart.colorScale[index % Chart.colorScale.length]), type : OptionType.Color, key: `color_${ index.toString() }`});
                return accumulator;
            }, []);
        } catch(e) {}
    }

    public getParametersFromOptions = ():any => {
        let payload = {
            general : {},
            specifics : {}
        };
        Object.assign(payload.general, this.getGeneralParameterFromOptions());
        Object.assign(payload.specifics, this.getAxisParameterFromOptions('xAxis'));
        Object.assign(payload.specifics, this.getAxisParameterFromOptions('yAxis'));
        Object.assign(payload.specifics, this.getSeriesParametersFromOptions());
        return payload;
    };

    private getGeneralParameterFromOptions = () : any => {
        return {
            title : { text : this.options.general.options[0].value },
            subtitle: {text: this.options.general.options[ 1 ].value},
            font: {
                size: this.options.general.options[ 2 ].value,
                bold: this.options.general.options[ 3 ].value,
            }
        }
    };

    private getAxisParameterFromOptions = (axis : string) : any => {
        let payload = {};
        payload[axis] = {title : { text : this.options[axis].options[0].value }};
        return payload
    };

    private getSeriesParametersFromOptions = () : any => {
        return {
            series : this.options.series.options.map(( serie : any ) => {
                return {
                    name : serie.label,
                    color : serie.value
                };
            })
        }
    };
};