import {UtilService} from '../shared/services/util.service';
import {DNATranslateService} from '../shared/services/translate.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from '../shared/services/localStorage.service';
import {environment} from '../../environments/environment';
import {
  OnePagerPreviewMulti, CampaignMultiHead, CampaignMultiTargetProtocol, Filter, CampaignsMulti, DescriptorGroup,
  CampaignFormulasMulti, ChosenBenchRoutines, WorkflowConfiguration, CampaignMultiDescriptor, CampaignsMultiInfoGen,
  CampaignMultiWorkflows, IdValue, CampaignsMultiUsers, Graph, DNAComponent, Translatable, Descriptor, Workflow, AnalyseType, Campaign,
  CampaignsMultiProtocol, CampaignMultiTarget, Languages, CampaignsMultiPutUsers, Result
} from '../types';
import {Observable, Subject} from 'rxjs';
import * as _ from 'lodash';
import * as stringSimilarity from 'string-similarity';

@Injectable()
export class MultiCampaignsService {

  campaignMulti: CampaignsMulti;
  campaignMultiId: string;
  campaignMultiName: string;
  campaignMultiDescriptor: CampaignMultiDescriptor;
  campaignMultiDescriptorUntouched: CampaignMultiDescriptor;


  private storageKeys = {
    CampaignMulti: 'DNA_CAMPAIGN_MULTI'
  };

  hasNameChanged: Subject<string> = new Subject();

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private translateService: DNATranslateService,
    private utilService: UtilService
  ) {
  }

  createCampaignsMulti = (campaignsMulti: CampaignsMulti, idCampaignMulti?): Observable<{ id: string, name: string }> => {
    const query = idCampaignMulti
      ? '?duplicate=' + idCampaignMulti
      : '';
    return this.http.post<{ id: string, name: string }>(`${environment.apiStudiesUrl}/v1/campaignsMulti` + query, campaignsMulti);
  }

  deleteCampaignMulti(id: string): Observable<Campaign> {
    return this.http.delete<Campaign>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${id}`);
  }

  getCampaignsMultiGeneralInfo(id: string): Observable<CampaignsMultiInfoGen> {
    return this.http.get<CampaignsMultiInfoGen>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${id}/generaleInformation`);
  }

  getMultiCampaignStudies(id: string): Observable<CampaignsMulti> {
    return this.http.get<CampaignsMulti>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${id}/studies`);
  }

  getMultiStudiesCampaigns(id: string): Observable<any> {
    return this.http.get<CampaignsMulti>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${id}/getCampaigns`);
  }

  setUsersMulti(id: string, body: CampaignsMultiPutUsers ): Observable<CampaignsMulti> {
    return this.http.put<CampaignsMulti>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${id}/setUsersMulti`, body);
  }

  deleteCampaignStudy(id: string, idCampaign: string): Observable<CampaignsMulti> {
    return this.http.delete<CampaignsMulti>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${id}/studies/${idCampaign}`);
  }

  addCampaignStudy(id: string, idCampaign: string, isNameUpdated: boolean = false): Observable<CampaignsMulti> {
    return this.http.post<CampaignsMulti>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${id}/studies/${idCampaign}`, {addName: isNameUpdated});
  }

  getHttpCampaignsWithFilter(filter: Filter): Observable<Result> {
    return this.http.get<Result>(`${environment.apiStudiesUrl}/v1/campaignsMulti?${this.utilService.getParams(filter, true)}`);
  }

  putCampaignsMultiGeneralInfo = (body: any): Observable<CampaignsMultiInfoGen> => {
    return this.http.put<any>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${body.id}/generaleInformation`, body);
  }

  getCampaignsMultiWorkflows(id: string): Observable<CampaignMultiWorkflows> {
    return this.http.get<CampaignMultiWorkflows>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${id}/workflows`);
  }

  getCampaignsMultiWorkflowConfiguration(campaignId: string, idWorkflow: string): Observable<WorkflowConfiguration> {
    return this.http.get<WorkflowConfiguration>(`${environment.apiStudiesUrl}/v1/campaignsMulti/campaign/${campaignId}/workflow/${idWorkflow}/configuration`);
  }

  getCampaignsMultiWorkflowGraphs(campaignId: string, idWorkflow: string): Observable<Result> {
    return this.http.get<Result>(`${environment.apiStudiesUrl}/v1/campaignsMulti/campaign/${campaignId}/workflow/${idWorkflow}/graphs`);
  }

  putCampaignsMultiGraphs(id: string, graphs: Graph[], descriptors: DescriptorGroup[]): Observable<Graph[]> {
    return this.http.put<Graph[]>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${id}/graphs`, {graphs: graphs, descriptors: descriptors});
  }

  getCampaignsMultiDescriptors(id: string): Observable<CampaignMultiDescriptor> {
    return this.http.get<CampaignMultiDescriptor>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${id}/descriptors`);
  }

  putCampaignMultiDescriptor(campaignMulti: CampaignMultiDescriptor):
    Observable<{ descriptorsGroups: DescriptorGroup[], graphs: Graph[] }> {
    return this.http.put<{ descriptorsGroups: DescriptorGroup[], graphs: Graph[] }>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${campaignMulti.id}/descriptors`, {descriptorsGroups: campaignMulti.descriptorsGroups, graphs: campaignMulti.graphs});
  }

  // Getter & Setter: CampaignMulti from localStorage
  getCampaignMultiFromLocalStorage = (id: string) => {
    return this.localStorageService.getItem(this.storageKeys.CampaignMulti + id);
  }

  setCampaignMultiFromLocalStorage(campaignMulti: IdValue): void {
    this.localStorageService.setItem(this.storageKeys.CampaignMulti + campaignMulti.id, campaignMulti.name);
  }

  getCampaignMultiProtocol(idCampaignMulti: string): Observable<CampaignsMultiProtocol> {
    return this.http.get<CampaignsMultiProtocol>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${idCampaignMulti}/protocol`);
  }

  getCampaignMultiTarget(idCampaignMulti: string): Observable<CampaignMultiTarget> {
    return this.http.get<CampaignMultiTarget>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${idCampaignMulti}/target`);
  }

  getCampaignMultiFormulas(idCampaignMulti: string): Observable<CampaignFormulasMulti> {
    return this.http.get<CampaignFormulasMulti>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${idCampaignMulti}/formulas`);
  }

  patchCampaignMultiFormulas(idCampaignMulti: string, elementToUpdate: {
    analyseType: AnalyseType, chosenBench?: ChosenBenchRoutines,
    chosenRoutines?: ChosenBenchRoutines, nbRoutines?: number
  }): Observable<CampaignsMulti> {
    return this.http.patch<CampaignsMulti>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${idCampaignMulti}/formulas`, elementToUpdate);
  }

  getCampaignMultiUsers(idCampaignMulti: string): Observable<CampaignsMultiUsers> {
    return this.http.get<CampaignsMultiUsers>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${idCampaignMulti}/users`);
  }

  patchCampaignMultiOnePager(idCampaignMulti: string, idOnePager: string): Observable<CampaignsMulti> {
    return this.http.patch <CampaignsMulti>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${idCampaignMulti}/onePager`, {idOnePager: idOnePager});
  }

  getCampaignMultiOnePager(idCampaignMulti: string): Observable<{id: string}> {
    return this.http.get <{id: string}>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${idCampaignMulti}/onePager`);
  }

  getCampaignMultiOnePagerTargetProtocol(idCampaignMulti: string): Observable<CampaignMultiTargetProtocol> {
    return this.http.get <CampaignMultiTargetProtocol>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${idCampaignMulti}/onePager/targetProtocol`);
  }

  getCampaignMultiOnePagerHead(idCampaignMulti: string): Observable<CampaignMultiHead> {
    return this.http.get <CampaignMultiHead>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${idCampaignMulti}/onePager/head`);
  }

  getCampaignMultiOnePagerPreview(idCampaignMulti: string): Observable<OnePagerPreviewMulti> {
    return this.http.get <OnePagerPreviewMulti>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${idCampaignMulti}/onePager/preview`);
  }

  putCampaignMultiGraphOptions = (idCampaignMulti: string, body: any): Observable<string> => {
    return this.http.put<string>(`${environment.apiStudiesUrl}/v1/campaignsMulti/${idCampaignMulti}/graphs/options`, body);
  }

  getCampaignMultiId() {
    return this.campaignMultiId;
  }

  setCampaignMultiId(id: string) {
    this.campaignMultiId = id;
  }

  setLocalCampaignMultiDescriptor(data: CampaignMultiDescriptor) {
    this.campaignMultiDescriptor = data;
    this.setLocalCampaignMultiDescriptorUntouched(data);
  }

  private setLocalCampaignMultiDescriptorUntouched(data: CampaignMultiDescriptor) {
    this.campaignMultiDescriptorUntouched = _.cloneDeep(data);
  }

  getLocalCampaignMultiDescriptor() {
    return _.cloneDeep(this.campaignMultiDescriptor);
  }

  /**
   * Complete et ajoute des groupes de descripteurs en fonction du questionnaire passé en parametre et du degré de pertinence attendu
   * @param multiCampaign
   * @param selectedWorkflow
   * @param pertinence
   * @param nbVisits
   */
  autocompleteDescriptorsGroups(multiCampaign: CampaignMultiDescriptor, selectedWorkflow:
    Pick<Workflow, 'id' | 'name'> & { campaignName: string, campaignId: string, idMulti: string }, pertinence: number, nbVisits: number[]) {
    // Variable contenant l'ensemble des descripteurs
    const allDescriptors = this.initAllDescriptors(multiCampaign);

    // Variable contenant les descripteurs n'ayant pas encore été ajoutés à un groupe
    if (!multiCampaign.descriptorsGroups) {
      multiCampaign.descriptorsGroups = [];
    }
    const filteredDescriptors = this.filterDescriptorsAlreadyAdded(allDescriptors, multiCampaign.descriptorsGroups);

    // Variable contenant uniquement les descripteurs pas encore ajoutés à un groupe du workflow selectionné
    const filteredWorkflowDescripteurs = filteredDescriptors.filter(d => d.campaignId ===
      selectedWorkflow.campaignId && d.workflowId === selectedWorkflow.id);

    // Pour chaque goupe déjà existant, on ajoute si possible le descripteur le plus pertinant du workflow en court
    multiCampaign.descriptorsGroups.forEach(group => {
      const filteredGroup = this.filterDescriptorsInGroup(filteredWorkflowDescripteurs, group);
      if (filteredGroup.length > 0) {
        this.updatePertinance(filteredGroup, group.descriptors, multiCampaign, nbVisits.some(v => v > 1));
        const bestMatch: Descriptor = _.last(_.sortBy(filteredGroup, 'pertinance'));
        if (bestMatch.pertinance >= pertinence) {
          group.descriptors.push(bestMatch);
          _.remove(filteredWorkflowDescripteurs, d => this.matchDescriptor(d, bestMatch));
        }
      }
    });

    // Pour les descripteurs restant, on créer de nouveaux groupe de descripteurs si on trouve ou moins deux attributs
    filteredWorkflowDescripteurs.map(d => {
      const descriptorGroup = new DescriptorGroup('');
      descriptorGroup.id = this.generateId(multiCampaign.descriptorsGroups);
      this.pushDescriptor(descriptorGroup, d);
      let bestMatch: Descriptor;
      const isNotVisit = nbVisits.every(v => v < 2);
      do {
        const othersDescriptors = this.filterDescriptorsInGroup(filteredDescriptors, descriptorGroup);
        this.updatePertinance(othersDescriptors, descriptorGroup.descriptors, multiCampaign, !isNotVisit);
        bestMatch = _.last(_.sortBy(othersDescriptors, 'pertinance'));
        if (bestMatch && bestMatch.pertinance >= pertinence) {
          descriptorGroup.descriptors.push(bestMatch);
        }
      }
      while (bestMatch && bestMatch.pertinance >= pertinence);
      if (descriptorGroup.descriptors.length > 1) {
        const suffixes: string[] = _.uniq(descriptorGroup.descriptors.map(s => _.get(s, 'blockSuffix', '')));
        this.updateNameGroup(descriptorGroup, d.name, suffixes);
        multiCampaign.descriptorsGroups.push(descriptorGroup);
      }
    });

    return multiCampaign;
  }

  updateNameGroup(descriptorGroup: DescriptorGroup, name: Translatable, suffixes: string[] = []): void {
    const n = _.cloneDeep(name);
    const suffix = suffixes.reduce((acc, curr) => {
      return acc + curr;
    }, '');
    descriptorGroup.name = !_.isEmpty(suffix) ? this.utilService.addSuffix(n, suffix) : n;
  }

  updateDynamicallyNameGroup(descriptorGroup: DescriptorGroup, descriptorName: Translatable, descriptorSuffix: string) {
    const name = !_.isEmpty(descriptorGroup.descriptors) ? _.cloneDeep(descriptorGroup.descriptors[0].name) : _.cloneDeep(descriptorName);
    const suffixes = descriptorGroup.descriptors.map(d => d.blockSuffix);
    suffixes.push(descriptorSuffix);
    const suffix = _.uniq(suffixes).reduce((acc, curr) => {
      return acc + curr;
    }, '');
    return this.utilService.addSuffix(name, suffix);
  }

  pushDescriptor(descriptorGroup: DescriptorGroup, data: Descriptor): void {
    descriptorGroup.descriptors.push(data);
  }

  generateId(array) {
    let id;
    do {
      id = this.utilService.generateRandomID();
    }
    while (!this.utilService.checkUnicityData(array, 'id', id));
    return id;
  }

  /**
   *
   * @param othersDescriptors : liste des descripteurs pour lesquels on souhaite mettre à la jour le degré de pertinence
   * @param descriptors : les descripteurs du groupe (seul le 1er sert pour calculer la pertinence)
   * @param multiCampaign : la multicampagne
   * @param isVisit
   */
  updatePertinance(
    othersDescriptors: Descriptor[], descriptors: Descriptor[] = [], multiCampaign: CampaignMultiDescriptor, isVisit: boolean) {
    const descriptorTemoin = descriptors[0];
    othersDescriptors.map(d => d.pertinance = this.calculatePertinance(d, descriptorTemoin, multiCampaign, isVisit));
  }

  calculatePertinance(descriptor: Descriptor, temoin: Descriptor, multiCampaign: CampaignMultiDescriptor, isVisit: boolean): number {
    const lang = this.translateService.getLanguage();
    if (!temoin) {
      return 100;
    }
    const componentTemoin: DNAComponent = this.getComponent(temoin, multiCampaign);
    const componentDescriptor: DNAComponent = this.getComponent(descriptor, multiCampaign);
    let score;
    if (descriptor.type !== temoin.type) {
      return 0;
    }
    switch (descriptor.type) {
      case 'dna-multiple-choice':
        score = this.compareMultipleChoice(temoin, descriptor, isVisit, lang, componentDescriptor, componentTemoin);
        break;
      case 'dna-input-text':
        score = this.compareInputText(temoin, descriptor, isVisit, lang, componentDescriptor, componentTemoin);
        break;
    }
    return Math.trunc(score);
  }

  compareMultipleChoice(
    temoin: Descriptor, descriptor: Descriptor, isVisit: boolean, lang: Languages, componentDescriptor: DNAComponent,
    componentTemoin: DNAComponent) {
    let score = 0;
    if (isVisit) {
      score += stringSimilarity.compareTwoStrings(descriptor.scaleDetail, temoin.scaleDetail) * 50;
      if (descriptor.blockSuffix === temoin.blockSuffix) {
        score += 10;
      }
    } else {
      score += stringSimilarity.compareTwoStrings(descriptor.scaleDetail, temoin.scaleDetail) * 60;
    }
    score += (stringSimilarity.compareTwoStrings(this.translateService.getTranslation(componentDescriptor.args.label, lang),
      this.translateService.getTranslation(componentTemoin.args.label, lang)) * 20);
    score += (stringSimilarity.compareTwoStrings(this.translateService.getTranslation(descriptor.blockName, lang),
      this.translateService.getTranslation(temoin.blockName, lang)) * 20);
    return score;
  }

  compareInputText(temoin: Descriptor, descriptor: Descriptor, isVisit: boolean, lang:
    Languages, componentDescriptor: DNAComponent, componentTemoin: DNAComponent) {
    let score = 0;
    if (isVisit) {
      if (componentDescriptor.args.leftRightMode === componentTemoin.args.leftRightMode) {
        score += 30;
      }
      if (descriptor.blockSuffix === temoin.blockSuffix) {
        score += 10;
      }
    } else {
      if (componentDescriptor.args.leftRightMode === componentTemoin.args.leftRightMode) {
        score += 40;
      }
    }
    score += (stringSimilarity.compareTwoStrings(this.translateService.getTranslation(componentDescriptor.args.label, lang),
      this.translateService.getTranslation(componentTemoin.args.label, lang)) * 30);
    score += (stringSimilarity.compareTwoStrings(this.translateService.getTranslation(descriptor.blockName, lang),
      this.translateService.getTranslation(temoin.blockName, lang)) * 30);
    return score;
  }

  getComponent(descriptor: Descriptor, multiCampaign: CampaignMultiDescriptor): DNAComponent {
    return multiCampaign.studies.find(c => c.id === descriptor.campaignId)
      .workflows.find(wk => wk.id === descriptor.workflowId)
      .blocks.find(b => b.idInQuestionnaire === descriptor.idInQuestionnaire)
      .components.find(c => c.idInBlock === descriptor.idInBlock);
  }

  initAllDescriptors(multiCampaign: CampaignMultiDescriptor): Descriptor[] {
    const descriptors = [];
    multiCampaign.studies.map(campaign =>
      campaign.workflows.map(wk =>
        wk.blocks.map(b => b.components
          .filter(c => c.type === 'dna-input-text' || c.type === 'dna-multiple-choice')
          .filter(c => !c.args.inactive)
          .map(c => descriptors.push(
            this.utilService.createDescriptor(
              campaign.id, campaign.name, wk.id, wk.name, b.id, b.name, _.get(b, 'suffix', ''), b.idInQuestionnaire, c))
          )
        )
      )
    );
    return descriptors;
  }

  private matchDescriptor(descA: Descriptor, descB: Descriptor): boolean {
    return descA.idInQuestionnaire === descB.idInQuestionnaire && descA.idInBlock === descB.idInBlock
      && descB.workflowId === descA.workflowId && descB.campaignId === descA.campaignId;
  }

  /**
   * Filtrer les descripteurs déjà ajoutés à un groupe
   * @param allDescriptors
   * @param descriptorsGroups
   */
  filterDescriptorsAlreadyAdded(allDescriptors: Descriptor[], descriptorsGroups: DescriptorGroup[]): Descriptor[] {
    const descriptors: Descriptor[] = _.flatten(descriptorsGroups.map(dg => dg.descriptors));
    return allDescriptors.filter(d => descriptors.find(des => this.matchDescriptor(des, d)) === undefined);
  }

  /**
   * Filtrer les descripteurs dans un groupe pour supprimer les descripteur d'une campagne déjà ajouté
   * @param allDescriptors
   * @param currentGroup
   */
  filterDescriptorsInGroup(allDescriptors: Descriptor[], currentGroup: DescriptorGroup): Descriptor[] {
    return allDescriptors.filter(d => currentGroup.descriptors.find(g => g.campaignId === d.campaignId) === undefined);
  }
}
