<div class="modal-scroll">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <div class="modal-header">
    <div class="header-padding">
      <i class="fa fa-clipboard modal-icon"></i>
      <h4 class="modal-title">{{ "CREATE_WORKFLOW" | translate }}</h4>
      <small>{{ "CREATION_ITEM" | translate: { type: "WORKFLOW" | translate } }}</small>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="workflowForm">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>
            <span translate>NAME</span> &amp;
            <span translate>DESCRIPTION</span>
          </h5>
        </div>
        <div class="ibox-content table-responsive">
          <table class="table table-borderless-th">
            <thead>
              <tr>
                <th translate>LANGUAGE</th>
                <th translate>NAME</th>
                <th translate>DESCRIPTION</th>
              </tr>
            </thead>
            <tbody *ngIf="!showSpinner" formArrayName="languages">
              <tr *ngFor="let language of workflowForm.get('languages').controls; let languageIndex = index" [formGroupName]="languageIndex">
                <td>
                  <label [ngClass]="{ required: language.value.id === 'english' }" translate>
                    {{ language.value.id | translate }}
                  </label>
                </td>
                <td>
                  <input class="form-control" [ngClass]="{ 'is-invalid': (submitted || language.controls.name.touched) && !language.controls.name.valid }"
                    formControlName="name" type="text" [name]="language.value.id + 'Name'">
                  <div *ngIf="language.value.id === 'english'" class="invalid-feedback">
                    <small>{{ "ENTER_NAME" | translate }}</small>
                  </div>
                </td>
                <td>
                  <textarea class="form-control" [ngClass]="{ 'is-invalid': (submitted || language.controls.description.touched) && !language.controls.description.valid }"
                    formControlName="description" name="language.value.id + 'Description'"></textarea>
                  <div *ngIf="language.value.id === 'english'" class="invalid-feedback">
                    <small>{{ "ENTER_DESCRIPTION" | translate }}</small>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5 translate>HUB</h5>
        </div>
        <div class="ibox-content">
          <select class="form-control" formControlName="hub" name="hubSelection">
            <option *ngFor="let hubToSelect of hubs" [value]="hubToSelect" translate>{{ hubToSelect }}</option>
          </select>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="onSubmit(workflowForm)" type="button" translate>VALIDATE</button>
  <button class="btn btn-default" (click)="cancel()" type="button" translate>CANCEL</button>
</div>
