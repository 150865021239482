import { Injectable } from '@angular/core';
import { Workflow, WorkflowPBI, CampaignAnalyse } from '../../../../types';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AnalyseService {
  
  serverUrlStudio: string;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.serverUrlStudio = environment.server_url_studio();
  }

  goToPageDatavizBO(workflow: Workflow, name: string, objectName: string = 'campaign') {
    let link = workflow.dataVizualisation.link.replace(`{${objectName}.name}`, encodeURIComponent(name));
    window.open(link, '_blank');
  }

  goToPageDatavizPowerbi(workflow: Workflow, campaignId: string) {
    this.router.navigate(['/campaigns', campaignId, 'dataviz', workflow.id]);
  }

  groupByWorkflows = (campaign: CampaignAnalyse): WorkflowPBI[] => {
    let workflows = [];
      for (let w of campaign.workflows) {
        workflows.push({
          name: w.name,
          id: w.id,
          idCampaign: [campaign.id],
          nameCampaign: [campaign.name],
          dataVizualisation: w.dataVizualisation
        })
      }
    return workflows;
  }
}
