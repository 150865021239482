<ngx-loading [show]="showSpinner"></ngx-loading>
<div class="ibox float-e-margins">
  <div class="ibox-title">
    <div class="row">
      <div class="col-12 col-md-4 my-auto">
        <h5 class="mr-1">{{ "TARGET" | translate }}</h5>
      </div>
      <div class="col-12 col-md-2 my-auto">
        <dna-checkbox [isChecked]="allFormChecked" [disabled]="publishedTemplate" [text]="(allFormChecked ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate" (onBooleanChanged)="allFormChecked ? uncheckAllForm() : checkAllForm()"></dna-checkbox>
      </div>
      <div class="col-12 col-md-6 my-auto">
        <div class="float-end d-flex flex-row gap-1" *ngIf="isChangedTarget()">
          <button class="btn btn-primary mr-2" type="button" (click)="onSubmit()" translate>SAVE</button>
          <button class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
        </div>
      </div>
    </div>
  </div>
  <div class="ibox-content" *ngIf="target">
    <div class="row margin-bottom border-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.length ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.length"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.length = selectUnselectLine(target.length, lineSelectedObject.length)">
        </dna-checkbox>
        <label translate>LENGTH</label>
      </div>
      <div class="col-1" *ngFor="let length of target.length; let index = index">
        <dna-checkbox [isChecked]="length.IsValue" [disabled]="publishedTemplate" [text]="length.Text" (onBooleanChanged)="onChangeData(target.length, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.degreFrisure ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.degreFrisure"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.degreFrisure = selectUnselectLine(target.degreFrisure, lineSelectedObject.degreFrisure)">
        </dna-checkbox>
        <label translate>DEGRE_FRISURE</label>
      </div>
      <div class="col-1" *ngFor="let degreFrisure of target.degreFrisure; let index = index">
        <dna-checkbox [isChecked]="degreFrisure.IsValue" [disabled]="publishedTemplate" [text]="degreFrisure.Text" (onBooleanChanged)="onChangeData(target.degreFrisure, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.regulariteFrisure ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.regulariteFrisure"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.regulariteFrisure = selectUnselectLine(target.regulariteFrisure, lineSelectedObject.regulariteFrisure)">
        </dna-checkbox>
        <label translate>REGULARITE_FRISURE</label>
      </div>
      <div class="col-2" *ngFor="let regulariteFrisure of target.regulariteFrisure; let index = index">
        <dna-checkbox [isChecked]="regulariteFrisure.IsValue" [disabled]="publishedTemplate" [text]="regulariteFrisure.Text" (onBooleanChanged)="onChangeData(target.regulariteFrisure, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.frisottis ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.frisottis"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.frisottis = selectUnselectLine(target.frisottis, lineSelectedObject.frisottis)">
        </dna-checkbox>
        <label translate>FRISOTTIS</label>
      </div>
      <div class="col-2" *ngFor="let frisottis of target.frisottis; let index = index">
        <dna-checkbox [isChecked]="frisottis.IsValue" [disabled]="publishedTemplate" [text]="frisottis.Text" (onBooleanChanged)="onChangeData(target.frisottis, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.alopecieFemme ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.alopecieFemme"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.alopecieFemme = selectUnselectLine(target.alopecieFemme, lineSelectedObject.alopecieFemme)">
        </dna-checkbox>
        <label translate>ALOPECIE_FEMME</label>
      </div>
      <div class="col-1" *ngFor="let alopecieFemme of target.alopecieFemme; let index = index">
        <dna-checkbox [isChecked]="alopecieFemme.IsValue" [disabled]="publishedTemplate" [text]="alopecieFemme.Text" (onBooleanChanged)="onChangeData(target.alopecieFemme, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.alopecieHomme ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.alopecieHomme"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.alopecieHomme = selectUnselectLine(target.alopecieHomme, lineSelectedObject.alopecieHomme)">
        </dna-checkbox>
        <label translate>ALOPECIE_HOMME</label>
      </div>
      <div class="col-1" *ngFor="let alopecieHomme of target.alopecieHomme; let index = index">
        <dna-checkbox [isChecked]="alopecieHomme.IsValue" [disabled]="publishedTemplate" [text]="alopecieHomme.Text" (onBooleanChanged)="onChangeData(target.alopecieHomme, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.baseNaturelle ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.baseNaturelle"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.baseNaturelle = selectUnselectLine(target.baseNaturelle, lineSelectedObject.baseNaturelle)">
        </dna-checkbox>
        <label translate>BASE_NATURELLE</label>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-2 margin-bottom" *ngFor="let baseNaturelle of target.baseNaturelle; let index = index">
        <dna-checkbox [isChecked]="baseNaturelle.IsValue" [disabled]="publishedTemplate" [text]="baseNaturelle.Text" (onBooleanChanged)="onChangeData(target.baseNaturelle, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-3">
        <label translate>PAS_CHEVEUX_BLANCS</label>
      </div>
      <div class="col-2" *ngFor="let cheveuxBlancs of target.cheveuxBlancs; let index = index">
        <dna-checkbox [isChecked]="cheveuxBlancs.IsValue" [disabled]="publishedTemplate" [text]="cheveuxBlancs.Text" (onBooleanChanged)="onChangeData(target.cheveuxBlancs, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom">
      <div class="col-5">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.cheveuxBlancsPourcentDevant ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}"
          [isChecked]="lineSelectedObject.cheveuxBlancsPourcentDevant" [disabled]="publishedTemplate" cssClass="icheckbox_square-green-sm" (onBooleanChanged)="lineSelectedObject.cheveuxBlancsPourcentDevant = selectUnselectLine(target.cheveuxBlancsPourcentDevant, lineSelectedObject.cheveuxBlancsPourcentDevant)">
        </dna-checkbox>
        <label translate>POURCENT_CHEVEUX_BLANCS_DEVANT</label>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-2 margin-bottom" *ngFor="let cheveuxBlancs of target.cheveuxBlancsPourcentDevant; let index = index">
        <dna-checkbox [isChecked]="cheveuxBlancs.IsValue" [disabled]="publishedTemplate" [text]="cheveuxBlancs.Text" (onBooleanChanged)="onChangeData(target.cheveuxBlancsPourcentDevant, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.cheveuxBlancsPourcentNuque ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}"
          [isChecked]="lineSelectedObject.cheveuxBlancsPourcentNuque" [disabled]="publishedTemplate" cssClass="icheckbox_square-green-sm" (onBooleanChanged)="lineSelectedObject.cheveuxBlancsPourcentNuque = selectUnselectLine(target.cheveuxBlancsPourcentNuque, lineSelectedObject.cheveuxBlancsPourcentNuque)">
        </dna-checkbox>
        <label translate>POURCENT_CHEVEUX_BLANCS_NUQUE</label>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-2 margin-bottom" *ngFor="let cheveuxBlancs of target.cheveuxBlancsPourcentNuque; let index = index">
        <dna-checkbox [isChecked]="cheveuxBlancs.IsValue" [disabled]="publishedTemplate" [text]="cheveuxBlancs.Text" (onBooleanChanged)="onChangeData(target.cheveuxBlancsPourcentNuque, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.abondanceCheveux ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.abondanceCheveux"
          cssClass="icheckbox_square-green-sm" (onBooleanChanged)="lineSelectedObject.abondanceCheveux = selectUnselectLine(target.abondanceCheveux, lineSelectedObject.abondanceCheveux)">
        </dna-checkbox>
        <label translate>ABONDANCE_CHEVEUX</label>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col margin-bottom" *ngFor="let abondanceCheveux of target.abondanceCheveux; let index = index">
        <dna-checkbox [isChecked]="abondanceCheveux.IsValue" [disabled]="publishedTemplate" [text]="abondanceCheveux.Text" (onBooleanChanged)="onChangeData(target.abondanceCheveux, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.sensibilisation ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.sensibilisation"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.sensibilisation = selectUnselectLine(target.sensibilisation, lineSelectedObject.sensibilisation)">
        </dna-checkbox>
        <label translate>SENSIBILISATION</label>
      </div>
      <div class="col-1" *ngFor="let sensibilisation of target.sensibilisation; let index = index">
        <dna-checkbox [isChecked]="sensibilisation.IsValue" [disabled]="publishedTemplate" [text]="sensibilisation.Text" (onBooleanChanged)="onChangeData(target.sensibilisation, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.grosseur ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.grosseur"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.grosseur = selectUnselectLine(target.grosseur, lineSelectedObject.grosseur)">
        </dna-checkbox>
        <label translate>GROSSEUR_CHEVEUX</label>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-1 margin-bottom"></div>
      <div class="col-2 margin-bottom" *ngFor="let grosseur of target.grosseur; let index = index">
        <dna-checkbox [isChecked]="grosseur.IsValue" [disabled]="publishedTemplate" [text]="grosseur.Text" (onBooleanChanged)="onChangeData(target.grosseur, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.durete ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.durete"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.durete = selectUnselectLine(target.durete, lineSelectedObject.durete)">
        </dna-checkbox>
        <label translate>DURETE</label>
      </div>
      <div class="col-2" *ngFor="let durete of target.durete; let index = index">
        <dna-checkbox [isChecked]="durete.IsValue" [disabled]="publishedTemplate" [text]="durete.Text" (onBooleanChanged)="onChangeData(target.durete, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.natureCheveux ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.natureCheveux"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.natureCheveux = selectUnselectLine(target.natureCheveux, lineSelectedObject.natureCheveux)">
        </dna-checkbox>
        <label translate>NATURE_CHEVEUX</label>
      </div>
      <div class="col-2" *ngFor="let nature of target.natureCheveux; let index = index">
        <dna-checkbox [isChecked]="nature.IsValue" [disabled]="publishedTemplate" [text]="nature.Text" (onBooleanChanged)="onChangeData(target.natureCheveux, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.natureCuirChevelu ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.natureCuirChevelu"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.natureCuirChevelu = selectUnselectLine(target.natureCuirChevelu, lineSelectedObject.natureCuirChevelu)">
        </dna-checkbox>
        <label translate>NATURE_CUIR_CHEVELU</label>
      </div>
      <div class="col-2" *ngFor="let nature of target.natureCuirChevelu; let index = index">
        <dna-checkbox [isChecked]="nature.IsValue" [disabled]="publishedTemplate" [text]="nature.Text" (onBooleanChanged)="onChangeData(target.natureCuirChevelu, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.cheveuxNaturels ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.cheveuxNaturels"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.cheveuxNaturels = selectUnselectLine(target.cheveuxNaturels, lineSelectedObject.cheveuxNaturels)">
        </dna-checkbox>
        <label translate>CHEVEUX_NATURELS</label>
      </div>
      <div class="col-2" *ngFor="let cheveuxNaturels of target.cheveuxNaturels; let index = index">
        <dna-checkbox [isChecked]="cheveuxNaturels.IsValue" [disabled]="publishedTemplate" [text]="cheveuxNaturels.Text" (onBooleanChanged)="onChangeData(target.cheveuxNaturels, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.hauteurTonColoration ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.hauteurTonColoration"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.hauteurTonColoration = selectUnselectLine(target.hauteurTonColoration, lineSelectedObject.hauteurTonColoration)">
        </dna-checkbox>
        <label translate>HAUTEUR_TON_COLORATION</label>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col margin-bottom" *ngFor="let hauteurTonColoration of target.hauteurTonColoration; let index = index">
        <dna-checkbox [isChecked]="hauteurTonColoration.IsValue" [disabled]="publishedTemplate" [text]="hauteurTonColoration.Text" (onBooleanChanged)="onChangeData(target.hauteurTonColoration, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.categorieNuance ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.categorieNuance"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.categorieNuance = selectUnselectLine(target.categorieNuance, lineSelectedObject.categorieNuance)">
        </dna-checkbox>
        <label translate>ROUTINE_SHADES</label>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-3 margin-bottom" *ngFor="let categorieNuance of target.categorieNuance; let index = index">
        <dna-checkbox [isChecked]="categorieNuance.IsValue" [disabled]="publishedTemplate" [text]="categorieNuance.Text" (onBooleanChanged)="onChangeData(target.categorieNuance, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.cosmetiqueEquilibre ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.cosmetiqueEquilibre"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.cosmetiqueEquilibre = selectUnselectLine(target.cosmetiqueEquilibre, lineSelectedObject.cosmetiqueEquilibre)">
        </dna-checkbox>
        <label translate>COSMETIC_EQUILIBRE</label>
      </div>
      <div class="col-2" *ngFor="let cosmetiqueEquilibre of target.cosmetiqueEquilibre; let index = index">
        <dna-checkbox [isChecked]="cosmetiqueEquilibre.IsValue" [text]="cosmetiqueEquilibre.Text" (onBooleanChanged)="onChangeData(target.cosmetiqueEquilibre, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.tinctorialEquilibre ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.tinctorialEquilibre"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.tinctorialEquilibre = selectUnselectLine(target.tinctorialEquilibre, lineSelectedObject.tinctorialEquilibre)">
        </dna-checkbox>
        <label translate>TINCTORIAL_EQUILIBRE</label>
      </div>
      <div class="col-2" *ngFor="let tinctorialEquilibre of target.tinctorialEquilibre; let index = index">
        <dna-checkbox [isChecked]="tinctorialEquilibre.IsValue" [disabled]="publishedTemplate" [text]="tinctorialEquilibre.Text" (onBooleanChanged)="onChangeData(target.tinctorialEquilibre, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.casse ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.casse"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.casse = selectUnselectLine(target.casse, lineSelectedObject.casse)">
        </dna-checkbox>
        <label translate>CASSE</label>
      </div>
      <div class="col-2" *ngFor="let casse of target.casse; let index = index">
        <dna-checkbox [isChecked]="casse.IsValue" [disabled]="publishedTemplate" [text]="casse.Text" (onBooleanChanged)="onChangeData(target.casse, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.hommePortBarbe ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.hommePortBarbe"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.hommePortBarbe = selectUnselectLine(target.hommePortBarbe, lineSelectedObject.hommePortBarbe)">
        </dna-checkbox>
        <label translate>BARBE</label>
      </div>
      <div class="col-2" *ngFor="let barbe of target.hommePortBarbe; let index = index">
        <dna-checkbox [isChecked]="barbe.IsValue" [disabled]="publishedTemplate" [text]="barbe.Text" (onBooleanChanged)="onChangeData(target.hommePortBarbe, index)">
        </dna-checkbox>
      </div>
    </div>
  </div>
</div>
