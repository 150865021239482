import { UserService } from '../services/user.service';
import { HttpRestService } from '../services/httpRest.service';
import { DNAObject, Favorite } from '../../types';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dna-tests',
    templateUrl: './dnaTests.component.html',
    styleUrls: ['./dnaTests.component.css']
})

export class TestsComponent implements OnInit {
    @Input() item: DNAObject;
    @Input() type: string;
    @Input() isButton: boolean;
    isTest: boolean;

    constructor(private userService: UserService, private httpRestService: HttpRestService) { }

    ngOnInit() {
        this.isTest = this.userService.isTest(this.item);
    }

    addOrRemoveTest(item: DNAObject, typeState: string) {
        let user = this.userService.getUser();
        this.isTest = !this.isTest;
        this.userService.updateTest(item, typeState);
        this.httpRestService.addOrRemoveTest(user, item.id, typeState).subscribe();
    }
}
