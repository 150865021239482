export default {
    "type": "table",
    "configs": {
        "title": {
            "text" : ""
        },
        "subtitle": {
            "text" : ""
        },
        "plotOptions": {
            "default_language" : {
                "enabled" : false
            }
        }
    }
}