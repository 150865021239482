import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CampaignDisplayMode, CampaignVisit, VisitBlock } from '../../../../../types';
import { DNATranslateService } from '../../../../../shared/services/translate.service';
import { ApplicationInsightsService } from '../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-configure-customize',
  templateUrl: './configure-customize-modal.component.html',
  styleUrls: ['./configure-customize-modal.component.less']
})

export class ConfigureCustomizeModalComponent implements OnInit, AfterViewInit {

  @Input() campaign: CampaignDisplayMode;

  originalCampaign: CampaignDisplayMode;
  pages: number[];
  visit: CampaignVisit = new CampaignVisit();
  currentLanguage: string;
  initTime = performance.now();
  activeBlocks: VisitBlock[];

  constructor(
    private activeModal: NgbActiveModal,
    private aiService: ApplicationInsightsService,
    private translateService: DNATranslateService
  ) {
  }

  ngOnInit() {
    this.originalCampaign = _.cloneDeep(this.campaign);
    this.currentLanguage = this.translateService.getLanguage();
  }

  ngAfterViewInit() {
    this.aiService.logPageView('Configure Customized Modal', '', performance.now() - this.initTime, 'Modals');
  }

  cancel() {
    this.activeModal.dismiss();
  }

  save() {
    this.campaign.visits = this.originalCampaign.visits;
    this.activeModal.close(this.campaign);
  }

  selectOrDeselectBlocks(indexBlock: number) {
    if (!this.visit.pages) {
      this.visit.pages = [];
    }

    if ((this.pages[indexBlock + 1] && this.pages[indexBlock] !== this.pages[indexBlock + 1] && this.pages[indexBlock + 1] !== this.pages[indexBlock + 2]) || (this.pages[indexBlock] === this.pages[indexBlock - 1])) {
      // Select 2 blocks when click the block TOP
      if (indexBlock == 0 || (indexBlock >= 1 && this.pages[indexBlock - 1] && this.pages[indexBlock] !== this.pages[indexBlock - 1])) {
        this.pages[indexBlock + 1] = this.pages[indexBlock];
        this.pages = this.pages.map((page, index) => this.updatePages(page, index, indexBlock, true));
        this.visit.pages = this.pages;
      } else {
        // Deselect 2 blocks  when click the block BOT
        this.pages[indexBlock] = this.pages[indexBlock - 1] + 1;
        this.pages = this.pages.map((page, index) => this.updatePages(page, index, indexBlock - 1, false));
        this.visit.pages = this.pages;
      }
    }
    // Deselect 2 blocks when click the block TOP
    else if (this.pages[indexBlock + 1] && this.pages[indexBlock] === this.pages[indexBlock + 1]) {
      this.pages[indexBlock + 1] = this.pages[indexBlock] + 1;
      this.pages = this.pages.map((page, index) => this.updatePages(page, index, indexBlock, false));
      this.visit.pages = this.pages;
    }
  }

  blockNameWithSuffix(block: VisitBlock, visit: CampaignVisit) {
    const blockName = !_.isEmpty(block.name[this.currentLanguage]) ? block.name[this.currentLanguage] : block.name['english'];
    return !_.isEmpty(visit.name) ? `${blockName}_${visit.name}` : blockName;
  }

  selectVisit(visit: CampaignVisit) {
    this.visit = visit;
    this.activeBlocks = this.visit.blocks.filter(block => block.isActive);
    this.pages = this.visit.pages ? this.visit.pages : this.activeBlocks.map((block, index) => index + 1);
  }

  updatePages(page: number, index: number, indexBlock: number, isSelection: boolean): number {
    return (index < indexBlock + 2) ? page : (isSelection ? page - 1 : page + 1);
  }

}
