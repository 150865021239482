  <td (click)="openCampaignMulti()">
    <span class="cursor-pointer">{{ campaignMulti.name }}</span>
  </td>
  <td (click)="openCampaignMulti()">
    <span *ngIf="studies?.length > 1; else single_campaign" class="cursor-pointer" [ngbTooltip]="campaignList">
      <span>{{ studies[0] }} (+{{ studies.length-1 }})</span>
    </span>
    <ng-template #single_campaign>
      <span class="cursor-pointer">
          <span class="cursor-pointer">{{ studies[0] }}</span>
      </span>
    </ng-template>
  </td>
  <td (click)="openCampaignMulti()">
    <span *ngIf="workflows?.length > 1; else single_workflow" class="cursor-pointer" [ngbTooltip]="workflowList">
      <span>{{ workflows[0] }} (+{{ workflows.length-1 }})</span>
    </span>
    <ng-template #single_workflow>
      <span class="cursor-pointer">
          <span class="cursor-pointer">{{ workflows[0] }}</span>
      </span>
    </ng-template>
  </td>
  <td (click)="openCampaignMulti()">
    <span *ngIf="formulas?.length > 1; else single_formulas" class="cursor-pointer" [ngbTooltip]="formulasList">
      <span>{{ formulas[0] }} (+{{ formulas.length-1 }})</span>
    </span>
    <ng-template #single_formulas>
      <span class="cursor-pointer">{{ formulas[0] }}</span>
    </ng-template>
  </td>
  <td (click)="openCampaignMulti()">
      <span *ngIf="orchestras?.length > 1; else single_orchestras" class="cursor-pointer" [ngbTooltip]="orchestrasList">
          <span>{{ orchestras[0] }} (+{{ orchestras.length-1 }})</span>
        </span>
        <ng-template #single_orchestras>
          <span  class="cursor-pointer">{{orchestras[0]}} </span>
        </ng-template>
  </td>
  <td (click)="openCampaignMulti()">
      <span *ngIf="activiews?.length > 1; else single_activiews" class="cursor-pointer" [ngbTooltip]="activiewsList">
          <span>{{ activiews[0] }} (+{{ activiews.length-1 }})</span>
        </span>
        <ng-template #single_activiews>
          <span  class="cursor-pointer">{{activiews[0]}} </span>
        </ng-template>
  </td>
  <td (click)="openCampaignMulti()">
      <span *ngIf="synergy?.length > 1; else single_synergy" class="cursor-pointer" [ngbTooltip]="synergyList">
          <span>{{ synergy[0] }} (+{{ synergy.length-1 }})</span>
        </span>
        <ng-template #single_synergy>
          <span  class="cursor-pointer">{{synergy[0]}} </span>
        </ng-template>
  </td>
  <td (click)="openCampaignMulti()">
    <span class="cursor-pointer">{{ campaignMulti.created_on | date:'yyyy-MM-dd' }}</span>
  </td>
  <td class="width-action-bar">
    <dna-action-bar [actionsButtons]="actionsButtons | async" (onClick)="onClickButton($event)"></dna-action-bar>
  </td>
  <ng-template #campaignList>
    <ul class="tooltip-list">
      <ng-container *ngFor="let c of studies">
          <li>{{ c }}</li>
      </ng-container>
    </ul>
  </ng-template>
  <ng-template #workflowList>
    <ul class="tooltip-list">
      <ng-container *ngFor="let wk of workflows">
          <li>{{ wk }}</li>
      </ng-container>
    </ul>
  </ng-template>
  <ng-template #formulasList>
    <ul class="tooltip-list">
      <ng-container *ngFor="let formula of formulas">
        <li>{{ formula }}</li>
      </ng-container>
    </ul>
  </ng-template>
  <ng-template #synergyList>
      <ul class="tooltip-list">
        <ng-container *ngFor="let br of synergy">
          <li>{{ br }}</li>
        </ng-container>
      </ul>
    </ng-template>
    <ng-template #orchestrasList>
        <ul class="tooltip-list">
          <ng-container *ngFor="let orc of orchestras">
            <li>{{ orc }}</li>
          </ng-container>
        </ul>
      </ng-template>
 
      <ng-template #activiewsList>
          <ul class="tooltip-list">
            <ng-container *ngFor="let act of activiews">
              <li>{{ act }}</li>
            </ng-container>
          </ul>
        </ng-template>
     
 
  