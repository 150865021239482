<form #routineForm="ngForm" (ngSubmit)="onValidate(campaign, routines)" novalidate>
  <div class="modal-scroll">
    <div class="modal-header">
      <h1 class="mb-4" translate>
        ROUTINES
      </h1>
    </div>
    <ng-container *ngIf="routines && routines[0] && routines[0].visits">
      <div class="modal-body">
        <div class="container">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <h5 translate>VISITS</h5>
            </div>
            <div class="ibox-content">
              <div class="table-responsive">

                <!-- ACCORDEONS FOR VISITS -->

                <div ngbAccordion id="config-visit">
                  <div ngbAccordionItem="{{'v' + indexVisit}}" [collapsed]="true" class="panel-default"
                       *ngFor="let visit of routines[0].visits; let indexVisit = index">
                    <div ngbAccordionHeader class="collapse-header-background">
                      <div class="d-flex align-items-center justify-content-between"
                           [ngClass]="{'routine-errors': routinesErrors[VISIT + indexVisit]}">
                        <button ngbAccordionButton class="btn btn-link">
                          <b *ngIf="visit && visit.name; else defaultVisit">{{ "ROUTINE_VISIT" | translate }} {{
                                  visit.index + 1
                            }}: {{ visit.name }} </b>
                          <ng-template #defaultVisit><b>{{ "ROUTINE_VISIT" | translate }} {{ visit.index + 1 }}</b>
                          </ng-template>
                        </button>
                        <button class="btn btn-danger" type="button" (click)="visitDelete(routines, indexVisit)">
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>
                    <div ngbAccordionCollapse>
                      <div ngbAccordionBody>
                        <ng-template>
                          <div class="form-group row">
                            <label class="col-12 col-md-2 col-form-label">{{ "ROUTINE_VISIT" | translate }}:</label>
                            <div class="col-12 col-md-10">
                              <select class="form-control mb-3" [(ngModel)]="visit.id"
                                      [name]="'visitName' + '-' + indexVisit"
                                      (ngModelChange)="routines = visitUpdate(routines, indexVisit, $event)" required>
                                <option></option>
                                <option *ngFor="let v of getAvailableVisits(visit)" [ngValue]="v.idVisit">
                                <span *ngIf="v && v.visitName; else defaultVisit">{{ "ROUTINE_VISIT" | translate }} {{
                                        v.visitIndex + 1
                                  }}: {{ v.visitName }}</span>
                                  <ng-template #defaultVisit>
                                    <b>{{ "ROUTINE_VISIT" | translate }} {{ v.visitIndex + 1 }}</b>
                                  </ng-template>
                                </option>
                              </select>
                            </div>
                          </div>

                          <!-- ACCORDEONS FOR ORDERS -->

                          <div ngbAccordion id="config-order">
                            <ng-container *ngFor="let order of visit.orders; let indexOrder = index">
                              <div ngbAccordionItem [collapsed]="true" class="panel-default">
                                <div ngbAccordionHeader class="collapse-header-background">
                                  <div class="d-flex align-items-center justify-content-between"
                                       [ngClass]="{'routine-errors': routinesErrors[VISIT + indexVisit] && routinesErrors[ORDER + indexOrder]}">
                                    <button ngbAccordionButton class="btn btn-link">
                                      <b>{{ "ORDER" | translate }}: {{ order.name }}</b>
                                    </button>
                                    <button class="btn btn-danger" type="button"
                                            (click)="orderDelete(routines, indexVisit, indexOrder)">
                                      <i class="fa fa-trash"></i>
                                    </button>
                                  </div>
                                </div>
                                <div ngbAccordionCollapse>
                                  <div ngbAccordionBody>
                                    <ng-template>
                                      <div class="container d-flex flex-column gap-3 mb-3">
                                        <div class="form-group row">
                                          <label class="col-12 col-md-3 col-form-label" translate>ORDER</label>
                                          <div class="col-12 col-md-9">
                                            <input class="form-control" [ngModel]="order.name"
                                                   (ngModelChange)="routines = onOrderUpdate(routines, indexVisit, indexOrder, [$event, 'name'])"
                                                   type="text"
                                                   [name]="'orderName' + '-' + indexVisit + '-' + indexOrder"
                                                   required>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-12 col-md-3 col-form-label"
                                                 translate>ROUTINE_SHADES</label>
                                          <div class="col-12 col-md-9">
                                            <input class="form-control" [ngModel]="order.shade"
                                                   (ngModelChange)="routines = onOrderUpdate(routines, indexVisit, indexOrder, [$event, 'shade'])"
                                                   type="text" [name]="'shade' + '-' + indexVisit + '-' + indexOrder">
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-12 col-md-3 col-form-label" translate>EVALUATION</label>
                                          <div class="col-12 col-md-9">
                                            <input class="form-control" [ngModel]="order.evaluation"
                                                   (ngModelChange)="routines = onOrderUpdate(routines, indexVisit, indexOrder, [$event, 'evaluation'])"
                                                   type="text"
                                                   [name]="'evaluation' + '-' + indexVisit + '-' + indexOrder">
                                          </div>
                                        </div>
                                      </div>

                                      <!-- ACCORDEONS FOR ROUTINES -->

                                      <div ngbAccordion id="config-routine">
                                        <ng-container *ngFor="let step of order.steps; let indexStep = index">
                                          <div ngbAccordionItem [collapsed]="true" class="panel-default">
                                            <div ngbAccordionHeader class="collapse-header-background">
                                              <div class="d-flex align-items-center justify-content-between"
                                                   [ngClass]="{'routine-errors': routinesErrors[VISIT + indexVisit] && routinesErrors[ORDER + indexOrder] && routinesErrors[STEP + indexStep]}">
                                                <button ngbAccordionButton class="btn btn-link">
                                                  <b>{{ "STEP" | translate }}: {{ step.name }}</b>
                                                </button>
                                                <button class="btn btn-danger" type="button"
                                                        (click)="stepDelete(routines, indexVisit, indexOrder, indexStep)">
                                                  <i class="fa fa-trash"></i>
                                                </button>
                                              </div>
                                            </div>
                                            <div ngbAccordionCollapse>
                                              <div ngbAccordionBody>
                                                <ng-template>
                                                  <div class="form-group row mb-3">
                                                    <label class="col-12 col-md-3 col-form-label">{{
                                                            "STEP" | translate
                                                      }}:</label>
                                                    <div class="col-12 col-md-9">
                                                      <input class="form-control" [ngModel]="step.name"
                                                             (ngModelChange)="routines = stepUpdateName(routines, indexVisit, indexOrder, indexStep, $event)"
                                                             type="text"
                                                             [name]="'stepName' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep"
                                                             required>
                                                    </div>
                                                  </div>
                                                  <table class="table table-hover">
                                                    <thead>
                                                    <th translate class="pl-3">
                                                      ROUTINE
                                                    </th>
                                                    <th *ngFor="let routine of routines; let indexRoutine = index">
                                                      <input class="form-control mb-3" disabled [(ngModel)]="routine.name"
                                                             type="text"
                                                             [name]="'routineName' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep + '-' + indexRoutine"
                                                             required>
                                                    </th>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                      <td translate>ROUTINE_LABEL</td>
                                                      <td *ngFor="let routine of routines; let indexRoutine = index">
                                                        <input class="form-control" [(ngModel)]="routine.label"
                                                               type="text"
                                                               [name]="'routineLabel' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep + '-' + indexRoutine">
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td translate>ROUTINE_PRODUCT_NAME</td>
                                                      <td *ngFor="let routine of routines; let indexRoutine = index">
                                                        <input class="form-control"
                                                               [ngModel]="routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula.productName | translate"
                                                               (ngModelChange)="routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula.productName = $event"
                                                               type="text"
                                                               [name]="'productName' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep + '-' + indexRoutine"
                                                               required>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td translate>CATEGORY</td>
                                                      <td *ngFor="let routine of routines; let indexRoutine = index">
                                                        <select class="form-control"
                                                                [(ngModel)]="routine.visits[indexVisit].orders[indexOrder].steps[indexStep].class"
                                                                [name]="'category' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep + '-' + indexRoutine">
                                                          <option [ngValue]="undefined"></option>
                                                          <option *ngFor="let category of categoriesSorted"
                                                                  [ngValue]="category.key">{{
                                                                  category.value
                                                            }}
                                                          </option>
                                                        </select>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <b translate>FORMULA</b>
                                                      </td>
                                                      <td *ngFor="let routine of routines; let indexRoutine = index">
                                                        <select class="form-control"
                                                                [(ngModel)]="routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula.formulaName"
                                                                [name]="'formulaToAdd' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep + '-' + indexRoutine"
                                                                required>
                                                          <option *ngFor="let formula of formulas"
                                                                  [ngValue]="formula.name"
                                                                  translate>{{
                                                                  formula.name
                                                            }}
                                                          </option>
                                                        </select>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td translate>ROUTINE_BATCH</td>
                                                      <td *ngFor="let routine of routines; let indexRoutine = index;">
                                                        <input class="form-control"
                                                               [ngModel]="routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula.lot | translate"
                                                               (ngModelChange)="routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula.lot = $event"
                                                               type="text"
                                                               [name]="'batch' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep + '-' + indexRoutine"
                                                               [required]="metierName !== metiers.Skin">
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td translate>ROUTINE_QUANTITY</td>
                                                      <td *ngFor="let routine of routines; let indexRoutine = index;">
                                                        <input class="form-control"
                                                               [(ngModel)]="routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula.quantity"
                                                               type="number" min="0"
                                                               [name]="'quantity' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep + '-' + indexRoutine"
                                                               required>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td translate>APPLICATION_MODES</td>
                                                      <td *ngFor="let routine of routines; let indexRoutine = index">
                                                        <tag-input
                                                          [(ngModel)]="routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula.applicators"
                                                          (onAdd)="routines = addTagInEmptyInputTag(routines, indexVisit, indexOrder, indexStep, 'applicators', $event)"
                                                          displayBy="value" identifyBy="key"
                                                          [onlyFromAutocomplete]="true"
                                                          [name]="'applicationModes' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep + '-' + indexRoutine"
                                                          placeholder="+ {{ 'APPLICATION_MODE' | translate }}"
                                                          secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { object: 'APPLICATION_MODE' | translate | lowercase } }}">
                                                          <tag-input-dropdown
                                                            [autocompleteItems]="applicationModes$ | async"
                                                            displayBy="value" identifyBy="key"
                                                            [keepOpen]="false"
                                                            [showDropdownIfEmpty]="true" [zIndex]="99999">
                                                          </tag-input-dropdown>
                                                        </tag-input>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td translate>ROUTINE_PAUSE_TIME</td>
                                                      <td *ngFor="let routine of routines; let indexRoutine = index;">
                                                        <dna-timepicker
                                                          [time]="routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula.pauseTime"
                                                          [name]="'pauseTime' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep + '-' + indexRoutine"
                                                          (onChangeTime)="onChangeTime($event, indexRoutine, indexVisit, indexOrder, indexStep)">
                                                        </dna-timepicker>
                                                      </td>
                                                    </tr>
                                                    <ng-container [ngSwitch]="metierName">
                                                      <ng-container *ngSwitchCase="metiers.Hair">
                                                        <tr>
                                                          <td translate>ROUTINE_APPLY_TO</td>
                                                          <td
                                                            *ngFor="let routine of routines; let indexRoutine = index">
                                                            <select class="form-control"
                                                                    [(ngModel)]="routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula.applyTo"
                                                                    (ngModelChange)="routines = addObjectInEmptySelect(routines, indexVisit, indexOrder, indexStep, 'applyTo', $event)"
                                                                    [name]="'applyTo' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep + '-' + indexRoutine">
                                                              <option [ngValue]="undefined"></option>
                                                              <option *ngFor="let application of routineApplication"
                                                                      [ngValue]="application"
                                                                      translate>{{ application }}
                                                              </option>
                                                            </select>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td translate>DRYING_TYPES</td>
                                                          <td
                                                            *ngFor="let routine of routines; let indexRoutine = index">
                                                            <tag-input
                                                              [(ngModel)]="routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula.dryingTypes"
                                                              (onAdd)="routines = addTagInEmptyInputTag(routines, indexVisit, indexOrder, indexStep, 'dryingTypes', $event)"
                                                              displayBy="value" identifyBy="key"
                                                              [onlyFromAutocomplete]="true"
                                                              [name]="'dryingTypes' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep + '-' + indexRoutine"
                                                              placeholder="+ {{ 'DRYING_TYPES' | translate }}"
                                                              secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { object: 'DRYING_TYPE' | translate | lowercase } }}">
                                                              <tag-input-dropdown
                                                                [autocompleteItems]="dryingTypes$ | async"
                                                                displayBy="value" identifyBy="key"
                                                                [keepOpen]="false"
                                                                [showDropdownIfEmpty]="true" [zIndex]="99999">
                                                              </tag-input-dropdown>
                                                            </tag-input>
                                                          </td>
                                                        </tr>
                                                      </ng-container>
                                                      <ng-container *ngSwitchCase="metiers.Skin">
                                                        <tr>
                                                          <td translate>TIME_DRYING</td>
                                                          <td
                                                            *ngFor="let routine of routines; let indexRoutine = index;">
                                                            <input class="form-control"
                                                                   [(ngModel)]="routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula.timeDrying"
                                                                   type="number" min="0"
                                                                   [name]="'timeDrying' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep + '-' + indexRoutine"
                                                                   required>
                                                          </td>
                                                        </tr>
                                                      </ng-container>
                                                    </ng-container>
                                                    <tr>
                                                      <td translate>ROUTINE_DEVICE</td>
                                                      <td *ngFor="let routine of routines; let indexRoutine = index;">
                                                        <input class="form-control"
                                                               [(ngModel)]="routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula.device"
                                                               type="text"
                                                               [name]="'device' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep + '-' + indexRoutine">
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td translate>SOCLE</td>
                                                      <td *ngFor="let routine of routines; let indexRoutine = index;">
                                                        <input class="form-control"
                                                               [(ngModel)]="routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula.socle"
                                                               type="text"
                                                               [name]="'socle' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep + '-' + indexRoutine">
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td translate>FEEDBACK</td>
                                                      <td *ngFor="let routine of routines; let indexRoutine = index;">
                                                        <textarea class="form-control"
                                                                  [(ngModel)]="routine.visits[indexVisit].orders[indexOrder].steps[indexStep].formula.comments"
                                                                  type="text"
                                                                  [name]="'comments' + '-' + indexVisit + '-' + indexOrder + '-' + indexStep + '-' + indexRoutine"
                                                                  rows="2"></textarea>
                                                      </td>
                                                    </tr>
                                                    </tbody>
                                                  </table>
                                                </ng-template>
                                              </div>
                                            </div>
                                          </div>
                                        </ng-container>
                                      </div>
                                      <div class="text-right pt-3">
                                        <button class="btn btn-primary btn-sm" type="button"
                                                (click)="stepCreate(routines, indexVisit, indexOrder)"
                                                translate>ADD_FORMULA
                                        </button>
                                      </div>
                                    </ng-template>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                          <div class="text-right pt-3">
                            <button class="btn btn-primary btn-sm" type="button"
                                    (click)="orderCreate(routines, indexVisit)" translate>ADD_ORDER
                            </button>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right pt-3">
                <button class="btn btn-primary btn-sm" type="button" (click)="routines = visitCreate(routines)"
                        translate>ADD_VISIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <div class="float-end d-flex flex-row gap-2">
      <button id="validate" class="btn btn-primary" type="submit" translate>VALIDATE</button>
      <button id="cancel" class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
    </div>
  </div>
</form>
