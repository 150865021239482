<div class="dna-header">
    <div id="outer">
        <div id="inner">
            <nav class="navbar navbar-inverse navbar-fixed-top">
                <div class="container-fluid">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="navbar-header">
                        <!--<button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded="false">
                            <span class="sr-only">Toggle navigation</span>
                            <i class="fa fa-globe"></i>
                        </button>-->
                        <div class="navbar-brand">
                            <div>
                                <div id="logo"><img src="./assets/dna_logo_d.png" height="100px"></div>
                                <div id="titles">
                                    <div id="title">DNA</div>
                                    <div id="subtitle">DATA NETWORK ACQUISITION</div>
                                    <div id="titleOrigin">studio</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /.navbar-collapse -->
                </div>
                <!-- /.container-fluid -->
            </nav>

          <div  class="header-container">  <ng-content > </ng-content>  </div>

        </div>
    </div>

    <li id="lang" class="navbar navbar-inverse" style="position: absolute;top:0;right:50px;" ngbDropdown>
            <a class="nav-link" role="button" id='lang' ngbDropdownToggle>
                <span translate>{{currentLanguage}}</span>
            </a>
            <div ngbDropdownMenu aria-labelledby="lang">
              <button *ngFor='let language of languages' [ngClass]="{'active' : currentLanguage == language}" (click)="changeLanguage(language)"
                class="dropdown-item" translate>{{language}}</button>
            </div>
    </li>
    <div class="poweredby">Powered by <span>The Cosmetrics</span></div>
    <div class="version" [ngClass]="{'changeLog': changeLog}">
        <a (click)="changeLog = !changeLog">{{ version }}</a>
        <div *ngIf="changeLog">{{ branchVersion }}</div>
        <!--DO NOT TOUCH WHAT IS IN COMMENT-->
        <!--<div marked class="changelogcontent" src="'../i18n/app_changelog/' + currentLanguage + '.md'"></div>-->
    </div>
</div>
