import { Injectable } from '@angular/core';

import { CampaignStates, States } from '../../types';

@Injectable()
export class StateService {

    BLOCK = 'block';
    BLOCKS = 'blocks';
    CAMPAIGN = 'campaign';
    CAMPAIGNS = 'campaigns';
    COMPONENTS = 'components';
    FAVORITES = 'favorites';
    FORMULA = 'formula';
    SYNERGY_DEMANDS = 'synergyDemands';
    TRAININGS = 'trainings';
    WORKFLOW = 'workflow';
    WORKFLOWS = 'workflows';

    constructor() { }

    getVisibleStates(dataType: string): string[] {
        switch (dataType) {
            case this.BLOCKS:
            case this.WORKFLOWS:
                return [States.Draft, States.Published, States.Suspended, States.Removed];
            case this.SYNERGY_DEMANDS:
                return [CampaignStates.Draft, CampaignStates.Suspended];
            case this.CAMPAIGNS:
                return [CampaignStates.Draft, CampaignStates.Published, CampaignStates.Suspended, CampaignStates.Finished, CampaignStates.Abandoned, CampaignStates.Archived, CampaignStates.Removed];
            case this.TRAININGS:
                return [CampaignStates.Draft, CampaignStates.Published, CampaignStates.Suspended, CampaignStates.Finished];
        }
    }

}
