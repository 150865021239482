<form #dnaTimerForm="ngForm" class="form-horizontal d-flex flex-column gap-3">
    <div class="form-group">
        <div class="col-sm-2">
            <label class="control-label" translate>LABEL </label>
            <span class="fa fa-info" placement="bottom" ngbTooltip="{{'tooltip_label' | translate}}" container="body"></span>
        </div>
        <div class="col-sm-10">
             <input name="label" type='text' class='form-control' [(ngModel)]="component.args.label.english" [disabled]="inactive" required>
        </div>
    </div>
    <div class="form-group">
        <div class="col-sm-2">
            <label class="control-label" translate>TAG</label>
            <span class="fa fa-info" placement="bottom" ngbTooltip="{{'tooltip_tag' | translate}}" container="body"></span>
        </div>
        <div class="col-sm-10">
            <input name="tag" type='text' class='form-control' [(ngModel)]="component.args.tag" [disabled]="inactive">
        </div>
    </div>
    <div class="form-group">
        <div class="col-sm-2">
            <label for="size" class="control-label" translate>TIMER  <dna-mandatory></dna-mandatory></label>
            <span class="fa fa-info" placement="bottom" ngbTooltip="{{'tooltip_timer-start' | translate}}" container="body"></span>
        </div>
        <div class="col-sm-10">
            <input #timerInput="ngModel" name="timer" type='number' class='form-control' min="0" [(ngModel)]="component.args.timer" (ngModelChange)="sendError()" [disabled]="inactive" required>
            <div *ngIf="timerInput.invalid && timerInput.touched" class="alert alert-danger" translate>
                    REQUIRED
                </div>
        </div>
    </div>
</form>
