<div class="container">
  <ngx-loading [show]="showSpinner || isPDFLoading"></ngx-loading>
  <div class="ibox float-e-margins">
    <div class="ibox-title">
      <h5 translate> ACTIONS </h5>
    </div>
    <div class="ibox-content">
      <div class="row mt-4 mb-4">
        <div class="col-12 col-md-3">
          <button id="open" class="btn btn-primary" type="button" [disabled]="pdf === undefined" (click)="openPdf()"><h3 translate>OPEN</h3></button>
        </div>
        <label class="col-12 col-md-9 col-form-label"><h3 translate>OPEN_PDF</h3></label>
      </div>
      <div class="row mt-4 mb-4">
        <div class="col-12 col-md-3">
          <button id="download" class="btn btn-primary" type="button" [disabled]="pdf === undefined" (click)="downloadPdf()"><h3 translate>DOWNLOAD</h3></button>
        </div>
        <label class="col-12 col-md-9 col-form-label"><h3 translate>DOWNLOAD_REPORT</h3></label>
      </div>

      <!-- DO NOT REMOVE - PROCESSMAP DEACTIVATED -->
      <div class="row" *ngIf="isMilorAvailable">
        <div class="col-12 col-md-3">
          <button id="milor" class="btn btn-primary"
            type="button" (click)="sendToMilor()" translate>MILOR_SEND</button>
        </div>
        <label class="col-12 col-md-9 col-form-label" translate> MILOR_SEND_DESC </label>
      </div>
      <div class="row" *ngIf="isMilorAvailable">
        <div class="col-12 col-md-3">
          <button id="milor" [disabled]="onePager?.archiveId === undefined" class="btn btn-primary"
            type="button" (click)="goToMilorPlatform()" translate>MILOR_OPEN_DOC</button>
        </div>
        <label class="col-12 col-md-9 col-form-label" translate> MILOR_OPEN_DOC_DESC </label>
      </div>
      <div class="row" *ngIf="campaign?.actiview !== undefined">
        <div class="col-12 col-md-3">
          <button id="milor" class="btn btn-primary"
            type="button" (click)="openModalActiviewData()" translate>SAVE_SEND_ACTIVIEW</button>
        </div>
        <label class="col-12 col-md-9 col-form-label" translate> SEND_ACTIVIEW_DESC </label>
      </div>
      <!-- DO NOT REMOVE - PROCESSMAP DEACTIVATED -->
      <div class="row" *ngIf="campaign?.state !== campaignStates.Archived">
        <div class="col-12 col-md-3">
          <button id="archive" placement="top" ngbTooltip="{{ 'ARCHIVE_DESC' | translate }}" class="btn btn-primary"
            type="button" (click)="archiveCampaign()" [disabled]="!onePager?.sendToMilor" translate>ARCHIVE</button>
        </div>
        <label class="col-12 col-md-9 col-form-label">{{ onePager?.sendToMilor ? ('ARCHIVE_CAMPAIGN' | translate) : ('ARCHIVE_DESC' | translate) }}</label>
      </div>
    </div>
  </div>
</div>
