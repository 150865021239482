import {
  HttpRestService
} from '../shared/services/httpRest.service';
import {
  Injectable
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';

import { HubById } from '../types';

import {
  Observable
} from 'rxjs';
import { map } from 'rxjs/operators';
import {
  environment
} from "../../environments/environment";
import { FicheCaracterisation, FicheEntretien, FicheRemanence, FilterSchedule, Panelist, PanelistTimeline, TimelineTypes, TimelineTypesDB, Volunteers, VolunteerOS, GroupVolunteers, ExportVolunteerOS } from '../types';
import * as _ from 'lodash';

@Injectable()
export class VolunteerService {
  serverUrlStudio: string;
  volunteers: Volunteers[] = [];
  panelist: Panelist;
  panelistIsLoaded: boolean = false;

  constructor(
    private http: HttpClient

  ) {
    this.serverUrlStudio = environment.server_url_studio();
  }

  createVolunteers(volunteer: Volunteers[]): Observable<Volunteers[]> {
    return this.http.post<Volunteers[]>(this.serverUrlStudio + '/volunteers', volunteer);
  }

  deleteVolunteer(idVolunteer: string): Observable<string[]> {
    return this.http.delete<string[]>(this.serverUrlStudio + '/volunteers/' + idVolunteer);
  }


  getPanelists(filters?: any): Observable<any> { //V2
    return filters
      ? this.http.get<any>(`${environment.apiApptalkUrl}/v1/panelists?${this.getParams(filters)}`)
      : this.http.get<any>(`${environment.apiApptalkUrl}/v1/panelists`);
  }

  getPanelistsArcs(filters?: any): Observable<any> { //V2
    return filters
      ? this.http.get<any>(`${environment.apiApptalkUrl}/v1/panelists/arcs?${this.getParams(filters)}`)
      : this.http.get<any>(`${environment.apiApptalkUrl}/v1/panelists/arcs`);
  }

  getAllPanelists(): Observable<Panelist[]> {
    return this.http.get<Panelist[]>(this.serverUrlStudio + '/panelists');
  }

  getPanelistById(id: string, hub: string, ref: string = 'arcs'): Observable<Panelist> {
    return this.http.get<any>(`${environment.apiApptalkUrl}/v1/panelists/${ref}/${hub}/${id.replace(/\//g, "~")}`);
  }

  exportPanelistsById(panelistIds: { panelistIds: string[] }, ref: string, hub: string): Observable<any> {
    return this.http.post<any>(`${environment.apiApptalkUrl}/v1/panelists/export/${ref}/${hub}`, panelistIds);
  }

  putPanelist(panelist: Panelist): Observable<Panelist> {
    return this.http.put<Panelist>(this.serverUrlStudio + '/panelists/' + panelist.id, panelist);
  }

  postFicheRemanence(id: string, hub: string, fiche: FicheRemanence): Observable<FicheRemanence> {
    return this.http.post<FicheRemanence>(`${environment.apiApptalkUrl}/v1/forms/${hub}/remanence/${id.replace(/\//g, "~")}`, fiche);
  }

  getFicheRemanence(id: string, hub: string): Observable<FicheRemanence> {
    return this.http.get<FicheRemanence>(`${environment.apiApptalkUrl}/v1/forms/${hub}/remanence/${id.replace(/\//g, "~")}`);
  }

  getFicheEntretien(id: string, hub: string): Observable<FicheEntretien> {
    return this.http.get<FicheEntretien>(`${environment.apiApptalkUrl}/v1/forms/${hub}/care/${id.replace(/\//g, "~")}`);
  }

  postFicheEntretien(id: string, hub: string, fiche: FicheEntretien): Observable<FicheEntretien> {
    return this.http.post<FicheEntretien>(`${environment.apiApptalkUrl}/v1/forms/${hub}/care/${id.replace(/\//g, "~")}`, fiche);
  }

  postFicheCaracterisation(panelistNumber: string, hub: string, fiche: FicheCaracterisation, ref: string = 'arcs'): Observable<FicheCaracterisation> {
    return this.http.post<FicheCaracterisation>(`${environment.apiApptalkUrl}/v1/forms/${ref}/${hub}/characterisation/${panelistNumber.replace(/\//g, "~")}`, fiche);
  }

  getFicheCaracterisation(id: string, hub: string): Observable<FicheCaracterisation> {
    return this.http.get<FicheCaracterisation>(`${environment.apiApptalkUrl}/v1/forms/${hub}/characterisation/${id.replace(/\//g, "~")}`);
  }

  setPanelist(panelist: Panelist) {
    this.panelist = panelist;
  }

  getPanelist(): Panelist {
    return this.panelist;
  }

  setPanelistIsLoaded(panelistIsLoaded: boolean) {
    this.panelistIsLoaded = panelistIsLoaded;
  }

  getPanelistIsLoaded(): boolean {
    return this.panelistIsLoaded;
  }

  postPanelist(panelist: Panelist): Observable<Panelist> {
    return this.http.post<Panelist>(this.serverUrlStudio + '/panelists', panelist);
  }

  searchPanelist(filter: FilterSchedule): Observable<Panelist[]> {
    return this.http.post<Panelist[]>(this.serverUrlStudio + '/panelists/search', filter);
  }

  getVolunteersInLocal(): Volunteers[] {
    return this.volunteers;
  }

  putVolunteer(volunteer: Volunteers): Observable<Volunteers> {
    return this.http.put<Volunteers>(this.serverUrlStudio + '/volunteers/' + volunteer.id, volunteer);
  }

  setVolunteers(volunteersNew: Volunteers[]) {
    this.volunteers = volunteersNew;
  }

  register(volunteer: Volunteers) {
    return this.http.post<Volunteers>(this.serverUrlStudio + '/volunteers/register', volunteer);
  }

  getPanelistsWithFilter(filter: FilterSchedule): Observable<Panelist[]> {
    return this.http.post<Panelist[]>(this.serverUrlStudio + '/panelists/search', filter);
  }

  getPanelistUpdatedTimeline(idPanelist: string): Observable<Panelist> {
    return this.http.get<Panelist>(this.serverUrlStudio + '/panelistTimelineUpdater/' + idPanelist);
  }

  getFicheCaracByPanelist(idPanelist: string, bridgeNumber: string): Observable<FicheCaracterisation> {
    return this.http.get<FicheCaracterisation>(this.serverUrlStudio + '/ficheCaracterisation/' + idPanelist + '/synergy/' + bridgeNumber);
  }

  getTimelineSubclass(timelinetype: TimelineTypes): string {
    switch (timelinetype) {
      case TimelineTypes.FICHE_CARACTERISATION:
        return TimelineTypesDB.FICHE_CARACTERISATION;
      case TimelineTypes.FICHE_REMANENCE:
        return TimelineTypesDB.FICHE_REMANENCE;
      case TimelineTypes.FICHE_ENTRETIEN:
        return TimelineTypesDB.FICHE_ENTRETIEN;
      case TimelineTypes.STUDY:
        return TimelineTypesDB.STUDY;
      case TimelineTypes.VISIT:
        return TimelineTypesDB.VISIT;
      default:
        return TimelineTypesDB.FICHE_CARACTERISATION;
    }
  }

  getParams(filters) {
    let params = '', paramFilters = '';
    Object.keys(filters).forEach(key => {
      if (filters[key].length || filters[key] > 0) {
        switch (key) {
          case 'page':
          case 'limit':
          case 'orderBy':
          case 'order':
            params = params === '' ? params.concat(`${key}=${filters[key]}`) : params.concat('&', `${key}=${filters[key]}`);
            break;
          case 'fiche':
            if (TimelineTypes.ALL_FICHE !== filters[key]) {
              paramFilters = paramFilters.concat(`subclass[eq]=${this.getTimelineSubclass(filters[key])};`)
            }
            break;
          case 'startDate':
            paramFilters = paramFilters.concat(`created_on[gt]=${filters[key]};`);
            break;
          case 'endDate':
            paramFilters = paramFilters.concat(`created_on[lt]=${filters[key]};`);
            break;
          default:
            paramFilters = Array.isArray(filters[key]) ? paramFilters.concat(`${key}[in]=(${filters[key].map(s => `'${s}'`).join(',')});`) : paramFilters.concat(`${key}[ct]=${filters[key]};`);
            break;
        }
      }
    })
    return params.concat(`&filters=[${paramFilters}]`);
  }

  getFicheLight(fiche: (FicheEntretien | FicheCaracterisation | FicheRemanence)): PanelistTimeline {
    return new PanelistTimeline(fiche.id, fiche.subclass, fiche.created_on, "", "");
  }

  getTradFiche(subclass) {
    switch (subclass) {
      case TimelineTypesDB.FICHE_CARACTERISATION:
        return TimelineTypes.FICHE_CARACTERISATION;
      case TimelineTypesDB.FICHE_REMANENCE:
        return TimelineTypes.FICHE_REMANENCE;
      case TimelineTypesDB.FICHE_ENTRETIEN:
        return TimelineTypes.FICHE_ENTRETIEN;
      case TimelineTypesDB.STUDY:
        return TimelineTypes.STUDY;
      case TimelineTypesDB.VISIT:
        return TimelineTypes.VISIT;
      default:
        return "FICHE";
    }
  }

  //---------Volunteers Group OS -------

  postVolunteersGroup(vg: GroupVolunteers): Observable<string> {
    return this.http.post<any>(`${environment.apiApptalkUrl}/v1/panelistGroupsHorsArcs/${vg.hub}/${vg.groupName.replace(/\//g, "~")}`, vg);
  }

  putVolunteersGroup(vg: GroupVolunteers): Observable<string> {
    return this.http.put<any>(`${environment.apiStudiesUrl}/v1/panelistGroupsHorsArcs/${vg.hub}/${vg.id}`, vg);
  }
  exportHttpRapportAgences(listVolunteers: any[], listStudyIds: string[], groupName: string): Observable<Blob> {
    return this.http.post(`${environment.apiStudiesUrl}/v1/campaigns/export-rapport-agences`, { listVolunteers, listStudyIds, groupName }, {
      responseType: "arraybuffer",
      observe: 'response'
    }).pipe(map((response: any) => {
      return new Blob([response.body], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
    }))
  }

  getVolunteerGroup(filters?: any): Observable<any> {
    return filters
      ? this.http.get<any>(`${environment.apiApptalkUrl}/v1/panelistGroupsHorsArcs?${this.getParams(filters)}`)
      : this.http.get<any>(`${environment.apiApptalkUrl}/v1/panelistGroupsHorsArcs`);
  }

  getVolunteerGroupShort(hub: HubById): Observable<any> {
    return this.http.get<any>(`${environment.apiApptalkUrl}/v1/panelistGroupsHorsArcs/${hub}`);
  }
}
