
import {of as observableOf, forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as _ from 'lodash';

import { DataVizualisation, OptionDataviz, TypeDataviz } from '../../types';
import {
  PowerBIService
} from '../powerbi/powerbi.service';
import { ErrorManagerService } from '../services/errorManager.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'dna-data-viz-link',
  templateUrl: './data-viz-link.component.html',
  styleUrls: ['./data-viz-link.component.less']
})
export class DataVizLinkComponent implements OnInit {
  @Input() dataVizualisation: DataVizualisation;
  @Output() onChanges: EventEmitter<any> = new EventEmitter();

  optionDataviz: typeof OptionDataviz = OptionDataviz;
  idPowerbiWorkspace: string;
  displayPowerbiSearch: boolean = true;
  typeDataviz: typeof TypeDataviz = TypeDataviz;

  constructor(
    private errorManager: ErrorManagerService,
    private powerBIService: PowerBIService,
    private userService: UserService
  ) { }

  ngOnInit() {
    let powerbiWorkspace = this.userService.getUser().currentWorkspace.pbiWorkspaces;
    this.idPowerbiWorkspace =  powerbiWorkspace && powerbiWorkspace.length >0 ? powerbiWorkspace[0].id : undefined;
    if (!this.idPowerbiWorkspace) {
      this.displayPowerbiSearch = false; 
    }
  }

  public requestAutocompleteItems = (): Observable<Response> => {

    return observableForkJoin([this.powerBIService.getDashboards(this.idPowerbiWorkspace), this.powerBIService.getReports(this.idPowerbiWorkspace)]).pipe(
      map(data => {
        data[0].value = data[0].value.map(value => {
          value.name = value.displayName;
          value.type= this.typeDataviz.typeDashboard;
          if (this.idPowerbiWorkspace) {
            value.idGroup = this.idPowerbiWorkspace;
          }
          return _.omit(value, ['displayName', 'isReadOnly']);
        });
        data[1].value = data[1].value.map(value => {
          value.type= this.typeDataviz.typeReport;
          if (this.idPowerbiWorkspace) {
            value.idGroup = this.idPowerbiWorkspace;
          }
          return _.omit(value, ['datasetId', 'isOwnedByMe', 'webUrl'])
        });
        return data[0].value.concat(data[1].value);
      }),
      catchError(err => {
        this.errorManager.catchError(err);
        return observableOf([]);
      }),);
  };

  onChange() {
    if (!this.idPowerbiWorkspace) {
      this.displayPowerbiSearch = false; 
    }
    this.onChanges.emit();
  }

}
