
import {takeUntil, tap, mergeMap, take, finalize} from 'rxjs/operators';
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  Output,
  EventEmitter,
  Input, AfterViewInit
} from '@angular/core';
import {
    Location
} from '@angular/common';

import * as _ from 'lodash';
import {
    Subscription
,  Subject ,  Observable } from 'rxjs';
import {
    NgbModal,
    NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";

import {
    TypeDataviz,
    WorkflowPBI,
    Image,
    ModalContent
} from '../../../../../types';
import { UploadCaptureModalComponent } from './upload-capture/upload-capture-modal.component';
import { PowerBIService } from '../../../../../shared/powerbi/powerbi.service';
import { UtilService } from '../../../../../shared/services/util.service';
import { CampaignService } from '../../../../campaigns.service';

declare function setContentHintToText(): any; // Declare JS function from inline script
declare function stopCapture(): any; // Declare JS function from inline script

@Component({
    selector: 'dna-report-layout',
    templateUrl: './reportLayout.component.html',
    styleUrls: ['./reports.component.less']
})
export class ReportLayoutComponent implements OnInit {

    @ViewChild("canvas") canvasRef: ElementRef;
    @ViewChild("capture") captureRef: ElementRef;
    @ViewChild("powerbiContainer") powerbiContainerRef: ElementRef;
    @ViewChild("powerbiSelector") powerbiSelectorRef: ElementRef;

    @Output() onPowerbiCapture = new EventEmitter();
    @Output() onImageLoaded = new EventEmitter();
    @Output() updateImagesAndOnePager: EventEmitter<Image[]> = new EventEmitter();

    @Input() workflow: WorkflowPBI;
    @Input() isMulti;
    @Input() imagesFromOnePager: Image[];

    currentIndex: number;
    embedUrl: string;
    idDashboard: string;
    idReport: string
    showSpinner: boolean = false;
    subscriptions: Subscription[] = [];
    tiles: any = [];
    type: string;
    typeDataviz: typeof TypeDataviz = TypeDataviz;

    x1 = 0; y1 = 0; x2 = 0; y2 = 0; y3 = 0; scrollYInitial = 0;
    w = 0; h = 0; x = 0; y = 0;
    isMouseDown = false;
    isMouseSelecting = false;

    modalOption: NgbModalOptions = {
        backdrop: "static",
        keyboard: false,
        size: "lg"
    };
    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private modalService: NgbModal,
        private renderer: Renderer2,
        public location: Location,
        private powerbiService: PowerBIService,
        private utilService: UtilService,
        private campaignService: CampaignService
    ) { }

    ngOnInit() {
        window.onscroll = () => this.displayScrollButton();

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseWheel = this.onMouseWheel.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
       this.updateComponentVariables(this.workflow);
    }

    updateComponentVariables(workflow: WorkflowPBI) {
        let type = workflow.dataVizualisation.object[0].type;
        if (type === this.typeDataviz.typeReport) {
            this.idReport = workflow.dataVizualisation.object[0].id;
            this.type = type;
            this.embedUrl = workflow.dataVizualisation.object[0].embedUrl;
        } else {
            this.showSpinner = true;
            this.powerbiService.getTilesFromDashboard(workflow.dataVizualisation.object[0].idGroup, workflow.dataVizualisation.object[0].id).pipe(
                finalize(() => this.showSpinner = false))
                .subscribe((success) => {
                    this.type = this.typeDataviz.typeTile;
                    this.idDashboard = workflow.dataVizualisation.object[0].id;
                    this.tiles = success.value;
                });
        }
    }

    ngOnDestroy() {
        stopCapture(); // Call JS function from inline script
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getZoomFactorDevice() {
        return (Math.round(window.devicePixelRatio * 1000) / 1000);
    }

    getZoomFactorBrowser() {
        const roundedPercentage = Math.round((window.outerWidth / window.innerWidth) * 100);
        const nearest = Math.round(roundedPercentage / 5) * 5;
        return nearest / 100 * this.getZoomFactorDevice();
    }

    onMouseDown(ev: MouseEvent) {
        this.isMouseDown = true;
        this.x1 = this.x2 = ev.clientX;
        this.y1 = this.y2 = this.y3 = ev.clientY;
        this.scrollYInitial = window.scrollY;
        this.renderer.setStyle(this.powerbiSelectorRef.nativeElement, 'display', 'block');
    }

    onMouseMove(ev: MouseEvent) {
        if (this.isMouseDown) {
            this.x2 = ev.clientX;
            this.y2 = ev.clientY;
            this.renderer.setStyle(this.powerbiSelectorRef.nativeElement, 'left', Math.min(this.x1, this.x2) + 'px');
            this.renderer.setStyle(this.powerbiSelectorRef.nativeElement, 'top', Math.min(this.y2, this.y3) + 'px');
            this.renderer.setStyle(this.powerbiSelectorRef.nativeElement, 'width', Math.abs(this.x2 - this.x1) + 'px');
            this.renderer.setStyle(this.powerbiSelectorRef.nativeElement, 'height', Math.abs(this.y2 - this.y3) + 'px');
        }
    }

    onMouseUp(ev: MouseEvent) {
        setContentHintToText();
        this.isMouseDown = false;
        let zoomFactorDevice = this.getZoomFactorDevice();
        this.x = zoomFactorDevice * (Math.min(this.x1, this.x2) + 1);
        this.y = zoomFactorDevice * (Math.min(this.y2, this.y3) + 1);
        this.w = zoomFactorDevice * (Math.abs(this.x2 - this.x1) - 2);
        this.h = zoomFactorDevice * (Math.abs(this.y2 - this.y3) - 2);
        this.powerbiUnselect();
        this.renderer.setStyle(this.powerbiSelectorRef.nativeElement, 'display', 'none');
        setTimeout(() => {
            this.powerbiCaptureInCanvas();
        }, 500);
    }

    onMouseWheel(ev: MouseEvent) {
        if (this.isMouseDown) {
            this.y3 = this.y1 - (window.scrollY - this.scrollYInitial)
            this.renderer.setStyle(this.powerbiSelectorRef.nativeElement, 'top', Math.min(this.y2, this.y3) + 'px');
            this.renderer.setStyle(this.powerbiSelectorRef.nativeElement, 'height', Math.abs(this.y2 - this.y3) + 'px');
        }
    }

    powerbiSelect() {
        if (!this.isMouseSelecting) {
            this.isMouseSelecting = true;
            document.addEventListener("mousedown", this.onMouseDown);
            document.addEventListener("mousemove", this.onMouseMove);
            document.addEventListener("mouseup", this.onMouseUp);
            document.addEventListener("scroll", this.onMouseWheel);
            this.renderer.setStyle(this.captureRef.nativeElement, 'display', 'block');
            let iFrames = document.getElementsByTagName("iframe");
            Array.from(iFrames).forEach((iFrame) => {
                this.renderer.setStyle(iFrame, 'pointer-events', 'none');
            });
        }
    }

    powerbiUnselect() {
        this.isMouseSelecting = false;
        document.removeEventListener("mousedown", this.onMouseDown);
        document.removeEventListener("mousemove", this.onMouseMove);
        document.removeEventListener("mouseup", this.onMouseUp);
        document.removeEventListener("scroll", this.onMouseWheel);

        let iFrames = document.getElementsByTagName("iframe");
        Array.from(iFrames).forEach((iFrame) => {
            this.renderer.setStyle(iFrame, 'pointer-events', 'auto');
        });
    }

    /********** Scroll button management part **********/

    displayScrollButton() {
        if (document.getElementById("btn-go-top")) {
            if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
                document.getElementById("btn-go-top").style.display = "block";
                if(document.getElementById("header-inner")) document.getElementById("header-inner").style.height = "70px";
                if(document.getElementById("banner-title")) document.getElementById("banner-title").style.display ="none";
                if(document.getElementById("banner-nav")) document.getElementById("banner-nav").style.top = "75px";
                if(document.getElementById("banner-actions")) document.getElementById("banner-actions").style.top = "65px";
            } else {
                document.getElementById("btn-go-top").style.display = "none";
                if(document.getElementById("header-inner")) document.getElementById("header-inner").style.height = "163px";
                if(document.getElementById("banner-title")) document.getElementById("banner-title").style.display = "block" ;
                if(document.getElementById("banner-nav")) document.getElementById("banner-nav").style.top = "150px";
                if(document.getElementById("banner-actions")) document.getElementById("banner-actions").style.top = "100px";
            }
        }
    }

    scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    powerbiCaptureInCanvas() {
        try {
            let canvas = this.canvasRef.nativeElement;
            const v = this.captureRef.nativeElement;

            canvas.width = this.w;
            canvas.height = this.h;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(v, this.x, this.y, this.w, this.h, 0, 0, this.w, this.h);

            const shareData = canvas.toDataURL();

            this.renderer.setStyle(this.captureRef.nativeElement, "display", "none");

            const modalPBICapture = this.modalService.open(UploadCaptureModalComponent, this.modalOption);
            modalPBICapture.componentInstance.capturePowerBI = shareData;
            modalPBICapture.result.then(
                (result: string) => {
                    const array = result.split(",");
                    this.onPowerbiCapture.emit(array);
                }
            ).catch(() => { });
        } catch (e) {
            console.log("powerbiCaptureInCanvas", e);
        }
    }

    uploadFiles(event: any) {
        const files = event.target.files;
        let dataToEmit$: Observable<any>[] = [];
        if (files) {
            _.forEach(files, file => {
                this.utilService.uploadFile(file).subscribe(dataToEmit => this.onImageLoaded.emit(dataToEmit));
            });
        }
    }

    removeImage(image: Image) {
        this.utilService.translateMessageModal('CONFIRM_DELETE', image.name, '').pipe(
          take(1),
          mergeMap((modalContent: ModalContent) => this.utilService.openModalConfirm(modalContent)),
          tap(() => {
            _.remove(this.imagesFromOnePager, (i: Image) => i.name === image.name);
            this.updateImagesAndOnePager.emit(this.imagesFromOnePager);
          }),
          takeUntil(this.destroy$),)
          .subscribe()
      }

    pasteImg(event: ClipboardEvent) {
        const files = _.get(event, 'clipboardData.files', []);
        if(!_.isEmpty(files) && this.utilService.isImageFile(files[0])){
            this.utilService.uploadFile(files[0]).subscribe(dataToEmit => this.onImageLoaded.emit(dataToEmit));
        }
    }
}
