<dna-layout>
    <div class="dna-container">
      <h1 class="mb-4" id="banner-title">
        <span translate>CAMPAIGN</span>
      </h1>
      <ngx-loading [show]="showSpinner"></ngx-loading>
      <div *ngIf="!showSpinner && !error" class="ibox float-e-margins p-3">

          <nav aria-label="breadcrumb" id="banner-nav">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a routerLink="/dashboard" translate>DASHBOARD</a>
                </li>
                <li class="breadcrumb-item">
                  <a routerLink="/campaigns" translate>CAMPAIGNS</a>
                </li>
                <li class="breadcrumb-item" *ngIf="studyTimepoint">
                  <a [routerLink]="['/campaigns', idCampaign, 'edit', 'analyse']" translate>{{ studyTimepoint.name }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a [routerLink]="['/campaigns', idCampaign, 'edit', 'photos']" translate>Photos</a>
                </li>
                <li class="breadcrumb-item active" translate>Timepoint</li>
              </ol>
            </nav>

        <div class="block">
          <span translate>LIST_TIMEPOINTS</span>
          <select class="selectVolunteers" [(ngModel)]="timepointSelectedOption" name="name"(change)="redirectToSamePage()">
              <option *ngFor="let timepoint of optionsTimepoints" [ngValue]="timepoint">{{ timepoint.name }}</option>
          </select>

          <section>
            <div class="manage-actions">
              <div class="selected-action">
                <div class="selection" *ngIf="selectedPhotosCount.length">
                  <i class="fa fa-times pointer" aria-hidden="true" (click)="resetSelection()"></i>
                  <span><strong>{{ selectedPhotosCount.length }} {{'PHOTOS_SELECTED' | translate}}</strong></span>
                  <div class="actions">
                    <i class="fa fa-trash pointer" aria-hidden="true" (click)="handleRemovePhotos()" title="delete photos"></i>
                    <i class="fa fa-download pointer" aria-hidden="true" (click)="createAndDownloadPhotosZip()" title="download photos"></i>
                    <i class="fa fa-file-text pointer" aria-hidden="true" (click)="sendPhotosToReport()" title="send photos to report"></i>
                  </div>
                </div>
              </div>
              <div class="wrap-buttons">
                <div class="text-center">
                  <input type="checkbox" [checked]="isSelectedAll" (change)="selectAllPhotos($event)">
                  <span>{{'ALL_SELECT' | translate}}</span>
                </div>
                <div>
                  <button class="outline-button" type="button" (click)="openModalToAddPhoto()"><i class="fa fa-plus" aria-hidden="true" ></i> {{'ADD_PHOTO' | translate}}</button>
                </div>

              </div>
          </div>
          </section>

          <ngx-loading [show]="showSpinnerLoadingPhoto"></ngx-loading>
          <div><strong>{{'TREATMENT' | translate}}: R1 (or R3) {{'LEFT'}}  </strong></div>
          <div class="containerImages">
            <ng-container *ngIf="timepointPhotoVolunteerList?.LEFT?.length > 0 && !showSpinnerLoadingPhoto ; else noPhotosToDisplay">
                <div class="timepoint" *ngFor="let timepoint of timepointPhotoVolunteerList.LEFT">
                    <div *ngIf="timepoint.randomisation.formulaLab.side==='LEFT'">
                        <div>
                            <strong>
                              <p>{{'VOLUNTEER' | translate}}: {{ timepoint.volunteerId}} </p>
                            </strong>
                          </div>
                          <div class="count-photos">{{ timepoint.referencesPhoto.length }} photos</div>
                          <div class="content-item"
                              *ngFor="let photo of timepoint.referencesPhoto; let idxPhoto = index"
                              [attr.data-index]="idxPhoto"

                              >
                              <div class="card"
                              [class.selected]="isPhotoSelected(photo.photoId)">
                                  <div class="card-photo">
                                    <i *ngIf="photo.isInOnePager" class="fa fa-file-text pointer left" aria-hidden="true"></i>
                                    <i class="fa fa-external-link pointer right" aria-hidden="true" (click)="openModalManagePhoto(photo, timepoint.timepointId, timepoint.volunteerId)"></i>
                                    <img [src]="(getImageUrl(photo.photoId) ? getImageUrl(photo.photoId) : this.fallback) | safe" (click)="addToSelection(photo, timepoint.volunteerId)" alt="image photo volunteer" loading="lazy"/>
                                  </div>
                              </div>
                          </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #noPhotosToDisplay>
                <div>No photos</div>
            </ng-template>
          </div>

          <hr/>

          <div><strong>{{'TREATMENT' | translate}}: R1 (or R3) {{'RIGHT'}}</strong> </div>
          <div class="containerImages">
            <ng-container *ngIf="timepointPhotoVolunteerList?.RIGHT?.length > 0 && !showSpinnerLoadingPhoto; else noPhotosToDisplay">
                <div class="timepoint" *ngFor="let timepoint of timepointPhotoVolunteerList.RIGHT">
                    <div *ngIf="timepoint.randomisation.formulaLab.side==='RIGHT'">
                        <div>
                            <strong>
                              <p>{{'VOLUNTEER' | translate}}: {{ timepoint.volunteerId}} </p>
                            </strong>
                        </div>
                        <div class="count-photos">{{ timepoint.referencesPhoto.length }} photos</div>
                        <div class="content-item"
                            *ngFor="let photo of timepoint.referencesPhoto; let idxPhoto = index"
                            [attr.data-index]="idxPhoto"

                            >
                            <div class="card"
                            [ngClass]="{'is-inOnePager': photo.isInOnePager}"
                            [class.selected]="isPhotoSelected(photo.photoId)">
                                <div class="card-photo">
                                  <i *ngIf="photo.isInOnePager" class="fa fa-file-text pointer left" aria-hidden="true"></i>
                                  <i class="fa fa-external-link pointer right" aria-hidden="true" (click)="openModalManagePhoto(photo, timepoint.timepointId, timepoint.volunteerId)"></i>
                                  <img [src]="(getImageUrl(photo.photoId) ? getImageUrl(photo.photoId) : this.fallback) | safe" (click)="addToSelection(photo, timepoint.volunteerId)" alt="image photo volunteer" loading="lazy"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #noPhotosToDisplay>
                <div>No photos</div>
            </ng-template>
          </div>
          <hr/>
          <ng-container *ngIf="timepointPhotoVolunteerList?.NONE?.length > 0 && !showSpinnerLoadingPhoto" >
            <div>TREATMENT_WITHOUT_RANDO </div>
            <div class="containerImages">
                <div class="timepoint" *ngFor="let timepoint of timepointPhotoVolunteerList.NONE">
                    <div class="title-timepoint">
                        <strong>
                          <p>{{'VOLUNTEER' | translate}}: {{ timepoint.volunteerId}} </p>
                        </strong>
                    </div>
                    <div class="count-photos">{{ timepoint.referencesPhoto.length }} photos</div>
                    <div class="content-item"
                        *ngFor="let photo of timepoint.referencesPhoto; let idxPhoto = index"
                        [attr.data-index]="idxPhoto"

                        >
                        <div class="card"
                        [ngClass]="{'is-inOnePager': this.photosInOnePager.includes(photo.photoId)}"
                        [class.selected]="isPhotoSelected(photo.photoId) && !this.photosInOnePager.includes(photo.photoId)">
                            <div class="card-photo">
                                <i *ngIf="photo.isInOnePager" class="fa fa-file-text pointer left" aria-hidden="true"></i>
                                <i class="fa fa-external-link pointer right" aria-hidden="true" (click)="openModalManagePhoto(photo, timepoint.timepointId, timepoint.volunteerId)"></i>
                                <img [src]="(getImageUrl(photo.photoId) ? getImageUrl(photo.photoId) : this.fallback) | safe" (click)="addToSelection(photo, timepoint.volunteerId)" alt="image photo volunteer" loading="lazy"/>
                            </div>
                        </div>
                    </div>
                  </div>
            </div>
          </ng-container>

        <div
          infinite-scroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          (scrolled)="loadImages()"
        >

        </div>

        </div>
      </div>
      <dna-menu
          [ngClass]   = "{
              'dna-menu-container--collapsed' : this.menuCollapsed,
              'dna-menu-container--expanded'  : !this.menuCollapsed
          }"
          (onCollapse)= "this._onCollapseMenu($event)"
      ></dna-menu>
    </div>

  </dna-layout>
