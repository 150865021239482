import {ConfortPreferencesNamespace, PayloadInterface, RoutineInput} from '../models';
import * as HighChart from 'highcharts';
import {Translatable} from '../../../../../../../../../types';

class ConfortPreferenceChartWorker {

  constructor(
    public raw: ConfortPreferencesNamespace.InputData[],
    public lang: string,
    public routines: RoutineInput
    ) {
    this.createGraph();
    this.createHighChartOptions();
  }

  public categoryMapping: Map<string, {
    blockName: Translatable,
    label: Translatable
  }> = new Map();
  public categoryQuestionMapping: Map<string, Translatable> = new Map();
  public categoryRoutineCount: Map<string, Map<string, number>> = new Map();
  public fullRoutine: Set<string> = new Set();
  public series: HighChart.SeriesOptionsType[];
  public options: HighChart.Options;
  public totalVolunteers: number = 0;


  private static getRoutineKey(routineLabel: string | Translatable): string {
    if (typeof routineLabel === 'string') {
      return routineLabel;
    }

    return routineLabel.english || 'NONE';
  }

  private createGraph() {
    this.raw.forEach(category => {
      this.categoryMapping.set(category.attribute.label.english, category.attribute);
      this.categoryQuestionMapping.set(category.attribute.label.english, category.attribute.blockName);
      category.values.forEach(routine => {
        if (!this.categoryRoutineCount.has(category.attribute.label.english)) {
          this.categoryRoutineCount.set(category.attribute.label.english, new Map());
        }
        const currentCountMapping = this.categoryRoutineCount.get(category.attribute.label.english);
        const currentRoutineKey = ConfortPreferenceChartWorker.getRoutineKey(routine.attribute.label);
        if (!this.fullRoutine.has(currentRoutineKey)) {
          this.fullRoutine.add(currentRoutineKey);
        }

        if (!currentCountMapping.has(currentRoutineKey)) {
          currentCountMapping.set(currentRoutineKey, 0);
        }

        routine.values.forEach(volunteer => {
          if (volunteer.value.key === 1) {
            currentCountMapping.set(currentRoutineKey, currentCountMapping.get(currentRoutineKey) + 1);
          }
          this.totalVolunteers++;
        });

      });
    });
  }

  private createHighChartOptions() {
    const series: HighChart.SeriesOptionsType[] = [];
    const routinesOrder = this.routines.routines
        .map(routine => routine.name)
        .sort((a, b) => a.localeCompare(b));
    const nonRoutineItem = Array.from(this.fullRoutine).filter(routineName => !routinesOrder.includes(routineName));

    if (nonRoutineItem.length !== 1 || routinesOrder.length !== 2) {
      throw new Error('Invalid number of routines');
    }

    const routinesWithMiddleValue = [
      routinesOrder[0],
      nonRoutineItem[0],
      routinesOrder[1]
    ];

    const categoriesOrder = Array.from(this.categoryMapping.keys());

    routinesWithMiddleValue.forEach((routineName, index) => {
      const currentSerie: HighChart.SeriesOptionsType = {
        name: routineName,
        type: 'column',
        data: []
      };
      if (index === 1) { currentSerie.color = HighChart.getOptions().colors[2]; }
      categoriesOrder.forEach(category => {
        if (
          !this.categoryRoutineCount.has(category) ||
          !this.categoryRoutineCount.get(category).has(routineName)
        ) {
          currentSerie.data.push(0);
        } else {
          currentSerie.data.push(Math.round(this.categoryRoutineCount.get(category).get(routineName) * 100 / this.totalVolunteers));
        }
      });

      series.push(currentSerie);
    });

    this.series = series;
    this.options = {
      xAxis: {
        categories: categoriesOrder.map(categoryName => this.categoryMapping.get(categoryName) as any),
      }
    };
  }
}

export const doWork = (
  data: ConfortPreferencesNamespace.InputData[],
  baseKey, lang, descriptors,
  payload: PayloadInterface,
  routines: RoutineInput,
  _
): void => {
  const confortPreference = new ConfortPreferenceChartWorker(data, lang, routines);
  payload.series = confortPreference.series;
  payload.categories = (confortPreference.options.xAxis as HighChart.XAxisOptions).categories;
};
