<td>{{ answer.index + 1 }}</td>
<td>
  <dna-translate [fieldToTranslate]="answer.label"> </dna-translate>
</td>
<td>
  <ng-container *ngIf="user !== undefined">
    {{ user.name }}
  </ng-container>
</td>
<td *ngIf="evaluation.volunteer">{{ evaluation.volunteer.name }}</td>
<td>
  <ng-container *ngFor="let data of datas; let index = index">
    <span *ngIf="index > 0"> - </span> {{ data.value }}
  </ng-container>
</td>
<td>{{ answer.comment }}</td>
