
import {of as observableOf, 
  forkJoin
, 
  Observable
} from 'rxjs';

import {catchError, mergeMap} from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input
} from '@angular/core';
import {
  Volunteers
} from '../../../../types';
import {
  VolunteerService
} from '../../../../volunteers/volunteers.service';
import {
  ErrorManagerService
} from '../../../../shared/services/errorManager.service';
import {
  NgbActiveModal
} from "@ng-bootstrap/ng-bootstrap";
import {
  WorkBook
} from 'xlsx/types';
import * as XLSX from 'xlsx';
import {
  environment
} from "../../../../../environments/environment";

@Component({
  selector: 'dna-list-volunteers',
  templateUrl: './createListVolunteersModal.component.html',
  styleUrls: ['./createListVolunteersModal.component.css']
})

export class CreateListModalComponent implements OnInit {
  doRegister: boolean;
  display: boolean;
  fileName: string;
  listes: Volunteers[];
  urlTemplateExcel: string;
  showSpinner: boolean = true;

  constructor(
    private volunteerService: VolunteerService,
    private errorManager: ErrorManagerService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    let urlServer = environment.server_url_studio();
    this.urlTemplateExcel = urlServer.split('/studio')[0] + "/template.xlsx";
  }

  uploadFiles(event: any) {

    let target: DataTransfer = < DataTransfer > (event.target);
    let file = event.target.files;

    if (file) {
      this.fileName = file[0].name;
      let reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        let bstr: string = e.target.result;
        let workbook: XLSX.WorkBook = XLSX.read(bstr, {
          type: 'binary'
        });
        this.listes = this.getVolunteersFromExcelFile(workbook);
      }

      reader.readAsBinaryString(target.files[0]);
    }
  }

  getVolunteersFromExcelFile(workbook: WorkBook) {
    let volunteers: Volunteers[] = [];

    workbook.SheetNames.forEach(function (y) {
      let worksheet = workbook.Sheets[y];

      let index = 1;
      let volunteer = new Volunteers;
      for (let z in worksheet) {
        if (z[0] === '!') continue;

        let value = worksheet[z].v;
        if (index == 1) {
          volunteer.name = value;
        } else if (index == 2) {
          volunteer.firstName = value;
        } else if (index == 3) {
          volunteer.email = value;
        }
        if (index == 3) {
          volunteers.push(volunteer);
          index = 0;
          volunteer = new Volunteers;
        }
        index++;
      }
    });
    return volunteers;
  }

  cancel() {
    this.activeModal.dismiss();
  };

  save(listes: Volunteers[]) {
    if (listes) {
      this.registerVolunteer(listes).pipe(
        mergeMap(volunteers => this.createVolunteer(volunteers)))
        .subscribe(
          volunteers => {
            if (volunteers.length === listes.length) {
              this.errorManager.displayMessage('VOLUNTEERS_IS_ADDED', 'success');
            } else {
              this.errorManager.displayMessage('NOT_ALL_VOLUNTEERS_ADDED', 'warn');
            }
            this.activeModal.close();
          },
          error => {
              console.log(error);
              this.errorManager.catchError(error)
            }
        )
    } else {
      this.errorManager.displayMessage('DOWNLOAD_EXCEL_FILE_ERROR', "danger");
    }
  }

  createVolunteer(volunteers: Volunteers[]): Observable < Volunteers[] > {
    return this.volunteerService.createVolunteers(volunteers);
  }

  registerVolunteer(volunteers: Volunteers[]): Observable < Volunteers[] > {
    if (this.doRegister) {
      return forkJoin(volunteers.map(this.registerOneVolunteer, this)).pipe(
        mergeMap(this.filterVolunteers));
    }
    return observableOf(volunteers);
  }

  registerOneVolunteer(volunteer: Volunteers): Observable < Volunteers > {
    return this.volunteerService.register(volunteer).pipe(mergeMap(() => observableOf(volunteer)),catchError(err => observableOf(undefined)),);
  }

  filterVolunteers(volunteers: Volunteers[]): Observable < Volunteers[] > {
    return observableOf(volunteers.filter(v => v !== undefined));
  }
}
