<div class="container">
  <div class="row pull-right">
    <ng-container>
      <button id="save" class="btn btn-primary mb-4 ml-1" type="button" (click)="save(conclusionForm, false)" translate>SAVE</button>
      <button *ngIf="isCancelAvailable" id="cancel" class="btn btn-default mb-4 ml-1" type="button" (click)="onCancel()" translate>CANCEL</button>
    </ng-container>
  </div>
  <form [formGroup]="conclusionForm">
    <div class="pt-3 form-group">
      <h3 translate>CONCLUSION</h3>
        <rich-text-wrapper spellcheck="false" formControlName="conclusion" placeholder="{{ 'ENTER_TEXTAREA' | translate }}"></rich-text-wrapper>
    </div>
    <div class="pt-3 form-group">
      <h3 translate>OTHER_STRENGTHS</h3>
        <rich-text-wrapper spellcheck="false" formControlName="otherStrengths" placeholder="{{ 'ENTER_TEXTAREA' | translate }}"></rich-text-wrapper>
    </div>
    <div class="pt-3 form-group">
      <h3 translate>ONE_PAGER_VIGILANCE</h3>
        <rich-text-wrapper spellcheck="false" formControlName="vigilance" placeholder="{{ 'ENTER_TEXTAREA' | translate }}"></rich-text-wrapper>
    </div>
    <div class="pt-3 form-group custom-margin">
      <h3 translate>ONE_PAGER_RECOMMENDATION</h3>
        <rich-text-wrapper spellcheck="false" formControlName="recommendation" placeholder="{{ 'ENTER_TEXTAREA' | translate }}"></rich-text-wrapper>
    </div>
  </form>
</div>
