<div translate class="dna-sm-table--caption"> VIZUALIZATIONS.TABLE.DECISION_RULES </div>
<table class="dna-sm-table">
  <thead>
    <tr>
      <th translate> VIZUALIZATIONS.TABLE.MEDIAN </th>
      <th> R1 vs R2 </th>
    </tr>
  </thead>
  <tbody>
    <tr
      [style.display]="dr.max <= 0 ? 'none' : 'table-row'" 
      *ngFor="let dr of this.dataTable">
      <td> {{ dr.min }} - {{ dr.max }}</td>
      <td> {{ dr.value }}</td>
    </tr>
  </tbody>
</table>