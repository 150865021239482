
import {finalize} from 'rxjs/operators';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import * as _ from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { ErrorManagerService } from '../../../shared/services/errorManager.service';
import { HttpRestService } from '../../../shared/services/httpRest.service';
import {
  Hub,
  Translatable,
  User,
  Workflow
} from '../../../types';
import { ReferenceTypeService } from '../../../shared/services/reference-type.service';
import { UserService } from '../../../shared/services/user.service';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-create-workflow-modal',
  templateUrl: './create-workflow-modal.component.html',
  styleUrls: ['./create-workflow-modal.component.less']
})
export class CreateWorkflowModalComponent implements OnInit, AfterViewInit {

  hubs: Hub[] = [];
  showSpinner: boolean = true;
  submitted: boolean = false;
  subscription: Subscription;
  user: User;
  workflowForm: FormGroup;
  initTime = performance.now();

  constructor(
    private activeModal: NgbActiveModal,
    private errorManager: ErrorManagerService,
    private formBuilder: FormBuilder,
    private httpRestService: HttpRestService,
    private referenceTypeService: ReferenceTypeService,
    private aiService: ApplicationInsightsService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.hubs = this.referenceTypeService.getHubs();
    this.user = this.userService.getUser();
    this.workflowForm = this.createWorkflowForm(this.userService.getUserHub(this.user.hub));

    this.subscription = this.httpRestService.getLanguages().pipe(
      finalize(() => this.showSpinner = false))
      .subscribe(
      (languages: {list: string[]}) => this.initLanguages(languages.list),
      error => this.errorManager.catchError(error)
      );
  }

  ngAfterViewInit() {
    this.aiService.logPageView('Create Workflow Modal', '', performance.now() - this.initTime, 'Modals');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  createWorkflowForm(hub: Hub): FormGroup {
    return this.formBuilder.group({
      hub: [hub],
      languages: this.formBuilder.array([]),
    });
  }

  getWorkflowFromForm(form: FormGroup): Workflow {
    let description = new Translatable(), name = new Translatable();
    form.value.languages.forEach(l => {
      name[l.id] = l.name;
      description[l.id] = l.description;
    });
    return new Workflow(name, description, form.value.hub);
  }

  onSubmit(form: FormGroup) {
    this.submitted = true;
    const workflow = this.getWorkflowFromForm(form);
    if (!form.invalid) {
      this.activeModal.close(_.cloneDeep(workflow))
    } else {
      this.errorManager.displayMessage('ON_ERROR_FORM', 'danger');
    }
  }

  /********** LANGUAGES **********/

  get languagesArray(): FormArray {
    return this.workflowForm.get('languages') as FormArray;
  }

  getLanguageFormGroup(language: string): FormGroup {
    return this.formBuilder.group({
      id: [language],
      name: [''],
      description: ['']
    });
  }

  initLanguages(languages: string[]) {
    languages.forEach((language: string) => {
      let languageGroup = this.getLanguageFormGroup(language);
      if (language === 'english') {
        languageGroup.get('name').setValidators(Validators.required);
        languageGroup.get('description').setValidators(Validators.required);
      }
      this.languagesArray.push(languageGroup);
    });
  }

}
