import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'dna-search-text',
    templateUrl: './dnaSearchText.component.html',
    styleUrls: ['./dnaSearchText.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchTextComponent implements OnInit {

    @Input() data: any;
    @Input() isDisplayed: boolean = false;
    @Input() placeholder: string = 'SEARCH';
    @Output() onDisplay = new EventEmitter();
    @Output() onSearch = new EventEmitter();

    constructor() { }

    ngOnInit() { }

    onClickSearch(data) {
        this.onSearch.emit(data);
    }

    onClickDisplay() {
        this.onDisplay.emit();
    }

    clearInputs() {
      this.data = '';
      this.onSearch.emit(this.data);
    }
}
