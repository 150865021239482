import { XAxisOptions } from 'highcharts';
import { ColumnChart } from '..';
import {
    HistoConfortInputCategoryInterface,
    HistoConfortInputDataType,
    HistoConfortInputRoutineInterface,
    HistoConfortInputValueInterface,
    HistoConfortInputVolonteerInterface,
    PayloadInterface,
    RoutineInput,
    RoutineInputItem
} from '../models';

class HistoCategory {

    public routineValuesMapping: Map<HistoRoutine, HistoValue[]> = new Map();
    public routineMapping: Map<string, HistoRoutine> = new Map();
    constructor(public raw: HistoConfortInputCategoryInterface, public histoConfort: HistoConfort) {
    }

    public addValue(routine: HistoRoutine, value: HistoValue) {
        let routineMapping = this.routineValuesMapping.get( routine );
        if (!routineMapping) {
            routineMapping = [];
            this.routineValuesMapping.set(routine, routineMapping);
        }
        routineMapping.push(value);
    }
}

class HistoRoutine {
    constructor(public raw: HistoConfortInputRoutineInterface, public histoConfort: HistoConfort, public data: RoutineInputItem) {
    }
}


class HistoValue {
    constructor (
        public raw: HistoConfortInputValueInterface,
        public volonteer: HistoVolonteer,
        public routine: HistoRoutine,
        public category: HistoCategory ) {}
}

class HistoVolonteer {
    constructor(public raw: HistoConfortInputVolonteerInterface, public histoConfort: HistoConfort) {}
}


class HistoConfort {
    public routineMapping: Map<string, HistoRoutine> = new Map();
    public categoryMapping: Map<number, HistoCategory> = new Map();
    public volonteerMapping: Map<string, HistoVolonteer> = new Map();
    private series: Highcharts.SeriesOptionsType[];
    private options: Highcharts.Options;
    private routinesDataMapping: Map<string, RoutineInputItem> = new Map();
    constructor(public raw: HistoConfortInputDataType, public lang: string, routines: RoutineInput) {
        this.createRoutineDataMapping(routines);
        this.createGraph();
        this.createHighchartOptions();
    }

    private createRoutineDataMapping(routines: RoutineInput) {
        routines.routines.forEach(routine => {
            this.routinesDataMapping.set(routine.name, routine);
        });
    }
    private createGraph() {
        this.raw.forEach((c, cIndex) => {
            const category = this.getCategory(cIndex, c);

            c.values.forEach(r => {
                const routine = this.getRoutine( r );
                category.routineMapping.set(
                    r.attribute.label,
                    new HistoRoutine( r, this, this.routinesDataMapping.get( r.attribute.label ) ) );
                r.values.forEach(vo => {
                    const volonteer = this.getVolonteer(vo);
                    const value = new HistoValue(vo.value, volonteer, routine, category);
                    category.addValue(routine, value);
                });
            });
        });
    }

    private getCategory(index: number, c: HistoConfortInputCategoryInterface): HistoCategory {
        let category = this.categoryMapping.get(index);
        if (!category) {
            category = new HistoCategory(c, this);
            this.categoryMapping.set(index, category);
        }
        return category;
    }

    private getRoutine(r: HistoConfortInputRoutineInterface): HistoRoutine {
        let routine = this.routineMapping.get(r.attribute.label);
        if (!routine) {
            routine = new HistoRoutine(r, this, this.routinesDataMapping.get(r.attribute.label));
            this.routineMapping.set(r.attribute.label, routine);
        }
        return routine;
    }

    private getVolonteer(v: HistoConfortInputVolonteerInterface): HistoVolonteer {
        let volonteer = this.volonteerMapping.get(v.label);
        if (!volonteer) {
            volonteer = new HistoVolonteer(v, this);
            this.volonteerMapping.set(v.label, volonteer);
        }
        return volonteer;
    }

    private createHighchartOptions() {
        const series: Highcharts.SeriesOptionsType[] = [];

        const routinesArray: string[] = [];

        for ( const key of this.routineMapping.keys() ) {
            routinesArray.push(key);
        }

        const routinesOrder = routinesArray.sort((a, b) => a.localeCompare(b));

        routinesOrder.forEach( ( routineName ) => {
            const routine = this.routineMapping.get( routineName );

            const serie: Highcharts.SeriesOptionsType = {
                name: routineName,
                type: 'column',
                data: []
            };

            const SEMSerie: Highcharts.SeriesErrorbarOptions = {
                name: routineName + ' - SEM',
                type: 'errorbar',
                data: []
            };

            if (routine.data.color) { serie.color = routine.data.color; }

            this.categoryMapping.forEach((category, categoryIndex) => {
                const values = category.routineValuesMapping.get(routine);
                const median = values ? this.getMedian(values.filter(v => !isNaN(v.raw.key)).map(v => v.raw.key)) : null;

                serie.data.push({
                    x: categoryIndex,
                    y: median
                } );
                const categoryRoutine = category.routineMapping.get( routine.raw.attribute.label );
                if (categoryRoutine.raw.attribute.semMax && categoryRoutine.raw.attribute.semMin) {
                    SEMSerie.data.push( {
                        x: categoryIndex,
                        low: categoryRoutine.raw.attribute.semMin,
                        high: categoryRoutine.raw.attribute.semMax
                    } );
                }
            });
            series.push(serie, SEMSerie);
        });

        this.series = series;

        const categories: string[] = [];
        const options: Highcharts.Options = {
            xAxis: {
                categories
            }
        };
        this.categoryMapping.forEach(category => {
            categories.push(category.raw.attribute as any);
        });
        this.options = options;
    }

    private getMedian  = (numbers) => {
        let median = 0;
        const numsLen = numbers.length;
        numbers.sort((a: number, b: number) => a - b);

        if (
            numsLen % 2 === 0 // is even
        ) {
            // average of two middle numbers
            median = (numbers[numsLen / 2 - 1] + numbers[numsLen / 2]) / 2;
        } else { // is odd
            // middle number only
            median = numbers[(numsLen - 1) / 2];
        }
        return median % 1 === 0 ? median : Number(median.toFixed(2));
    }

    public getSeries = (): Highcharts.SeriesOptionsType[] => {
        return this.series;
    }

    public getOptions = (): Highcharts.Options => {
        return this.options;
    }
}



export const doWork = (
    data: HistoConfortInputDataType,
    baseKey, lang, descriptors,
    payload: PayloadInterface,
    routines: RoutineInput,
    columnChart: ColumnChart ): void => {
    const histoConfort = new HistoConfort( data, lang, routines );

    payload.series = histoConfort.getSeries();
    payload.categories = (histoConfort.getOptions().xAxis as XAxisOptions).categories;
};
