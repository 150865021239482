
import {map} from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import {
  Router
} from '@angular/router';

import * as _ from 'lodash';
import {
  Observable
} from 'rxjs';

import {
  ActionBarButton,
  CampaignStates,
  DNATypes,
  Evaluation,
  GroupedFormula,
  LightCampaign,
  User,
  UserInCampaign
} from '../../types';
import { UtilService } from '../../shared/services/util.service';

@Component({
  selector: '[dna-export-data-set-row]',
  templateUrl: './export-data-set-row.component.html',
  styleUrls: ['./export-data-set-row.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportDataSetRowComponent implements OnInit {

  @Input() campaign: LightCampaign;

  @Output() onClickSwitch: EventEmitter<boolean> = new EventEmitter();

  @Input() allSelected: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // on configure le switch individuel selon l'état de `allSelected`
    if (this.allSelected !== undefined) {
      this.campaign.isSelected = this.allSelected;
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    // Lorsque `allSelected` change, on met à jour le switch individuel
    if (changes.allSelected && !changes.allSelected.isFirstChange()) {
      this.campaign.isSelected = changes.allSelected.currentValue;
    }
    this.changeDetectorRef.markForCheck();
  }


  onSwitchChanged(switchState: boolean) {
    if (!this.allSelected) { // Si allSelected est false, alors on utilise switchState
      this.campaign.isSelected = switchState;
    }
    this.onClickSwitch.emit(switchState);
  }

}
