<div class="modal-header">
    <h4 class="modal-title" id="modal-confirm-name" translate="UPDATE_MULTI_STUDY_NAME_TITLE"></h4>
</div>
<div class="modal-body">
    <div class="form-group">
        <label translate="UPDATE_MULTI_STUDY_NAME_CONTENT"></label>
        <p><label style="font-weight: bold">{{newSynergy}} -</label> {{oldName}} </p>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close(true)" translate="YES"></button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close(false)" translate="NO"></button>
</div>
