<ngx-loading [show]="showSpinner"></ngx-loading>
<div *ngIf="!showSpinner" class="ibox float-e-margins">
  <div class="ibox-title">
    <div class="row">
      <div class="col-12 col-md-4 my-auto">
        <h5>{{ "TARGET" | translate }}</h5>
      </div>
    </div>
  </div>
  <div class="ibox-content">
    <div *ngIf="!error && targetHead.length > 0" class="table-target">
      <table class="table table-bordered" [ngStyle]="{'width': widthTable + 'px'}">
        <thead>
          <tr>
            <th class="fixed-column-head fixed-column">{{ "CAMPAIGNS" | translate }}</th>
            <th *ngFor="let head of targetHead; let index = index;" style="width:200px">
              {{ targetTraduction[head] | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="campaignTarget.length > 0">
            <tr *ngFor="let ct of campaignTarget">
              <th id="td_{{ct.id}}" class="fixed-column studies-background" title="{{ ct.name }}">{{ ct.name }}</th>
              <ng-container *ngFor="let head of targetHead; let indexHead = index;">
                <td id="td_{{ct.id}}_{{indexHead}}">
                  <ng-container *ngFor="let valueHead of getValues(ct.target[head]); let indexH = index">
                    <ng-container *ngIf="indexH > 0"> | </ng-container> {{ valueHead.Text | translate }}
                  </ng-container>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div *ngIf="!error && targetHead.length == 0">{{ "TARGETS_CONFIG_ERROR" | translate }}</div>
    <dna-error-page *ngIf="error" (onClickReload)="init()"></dna-error-page>
  </div>
</div>
