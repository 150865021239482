import { TranslatableType } from "../interfaces";

export class Filter {

    constructor(
        private _key: string,
        private _value: TranslatableType,
        private _isApplied: boolean
    ) {
        this.key = _key;
        this.value = _value;
        this.isApplied = _isApplied || false;
    };

    /*
    * get key
    * Getter to access _key private property
    */
    public get key(): string { return this._key; };

    /*
    * get value
    * Getter to access _value private property
    */
    public get value(): TranslatableType { return this._value; };

    /*
    * get isApplied
    * Getter to access _isApplied private property
    */
    public get isApplied(): boolean { return this._isApplied; };

    /*
    * set key
    * Setter to assign _key to proper private property
    */
    public set key(_key: string) { this._key = _key; };

    /*
    * set key
    * Setter to assign _key to proper private property
    */
    public set value(_value: TranslatableType) { this._value = _value; };

    /*
    * set isApplied
    * Setter to assign _key to proper private property
    */
    public set isApplied(_isApplied: boolean) { this._isApplied = _isApplied; };

};