
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';

import { Block } from '../../../../../types';
import { BlockService } from '../../../../../blocks/blocks.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'dna-block-export',
  templateUrl: './block-export.component.html',
  styleUrls: ['./block-export.component.css']
})
export class BlockExportComponent implements OnInit {
  blocks: Block[] = [];
  exportForm: FormGroup;
  isDisplayed: boolean = false;
  originalBlocks: Block[] = [];
  showSpinner: boolean = true;

  constructor(
    private blockService: BlockService,
    private formBuilder: FormBuilder
  ) {
    this.exportForm = this.formBuilder.group({
      blockToExport: ['', Validators.required],
      blockJSON: ''
    });
  }

  ngOnInit() {
    this.blockService.getBlocks().pipe(
      finalize(() => this.showSpinner = false))
      .subscribe((blocks) => {
        blocks = blocks.filter(block => block.name !== undefined);
        this.blocks = _.cloneDeep(blocks);
        this.originalBlocks = _.cloneDeep(blocks);
        this.blocks.map((block) => block.name = _.cloneDeep(block.name.english));
      });
  }

  cancel() {
    this.isDisplayed = false;
    this.exportForm.reset();
  }

  onSubmit(form: FormGroup) {
    this.showSpinner = true;
    this.isDisplayed = true;

    this.blockService.getBlock(form.get('blockToExport').value[0].id).pipe(
      finalize(() => this.showSpinner = false))
      .subscribe((block) => {
        block = _.omitBy(block, (value, key) => key[0] === '_');
        this.exportForm.patchValue({
          blockJSON: JSON.stringify(block, null, '\t')
        });
      });
  }

}
