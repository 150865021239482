<div class="filter-title">
  <div class="{{icon}}"></div>
  <div translate>{{title}}</div>
</div>
<ul>
  <li *ngFor="let data of displayedArray" [ngClass]="{ 'selected': selectedArray[data[key]] }">
    {{data[key]}}
    <label>
		<input (click)="onClick(data)">{{ data[valueToDisplay] }}
    </label>
  </li>
</ul>