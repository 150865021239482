<dna-layout>
    <ng-container>
      <!--
      <ngx-loading [show]="showSpinner">
        <span translate>LOADING</span>
      </ngx-loading>
      -->
      <h1 class="mb-4" id="banner-title">
        <span>{{ "CAMPAIGNS_MULTI" | translate }}</span>
      </h1>
      <nav aria-label="breadcrumb" id="banner-nav">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a routerLink="/dashboard" translate>DASHBOARD</a>
          </li>
          <li class="breadcrumb-item">
            <a routerLink="/campaigns">
              {{ "CAMPAIGNS" | translate }}
            </a>
          </li>
          <li class="breadcrumb-item">
            <a routerLink="/multi-campaigns">
              {{ "CAMPAIGNS_MULTI" | translate }}
            </a>
          </li>
          <li class="breadcrumb-item active">{{ campaignMultiName }}</li>
          <li class="breadcrumb-item active" aria-current="page" translate>EDIT</li>        
        </ol>
      </nav>
    </ng-container>
    <ng-container *ngIf="sideMenu">
      <div class="block">
        <div class="row">
          <div class="col-12 margin-menu" [ngClass]="{'col-md-1': isOnOnePagerImage,'col-md-3': !isOnOnePagerImage}">
            <dna-side-bar-menu [menus]="sideMenu" (updateSpinner)="updateSpinner()" (onePagerClicked)="onOnePagerClick($event)"> </dna-side-bar-menu>
          </div>
          <div class="col-12" [ngClass]="{'col-md-11': isOnOnePagerImage,'col-md-9': !isOnOnePagerImage}" >
            <router-outlet (activate)="onActivate($event)"></router-outlet>
          </div>
        </div>
      </div>
    </ng-container>
  </dna-layout>