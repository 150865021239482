<td (click)="goToDetail(evaluation)">
  {{ evaluation.campaignName }}
</td>
<td (click)="goToDetail(evaluation)">
  <dna-translate [fieldToTranslate]="evaluation.workflowName"> </dna-translate>
</td>
<td (click)="goToDetail(evaluation)">
  <span>{{ evaluation.formulaName }}</span>
</td>
<td (click)="goToDetail(evaluation)" *ngIf="hasVolunteer">
  {{ evaluation?.volunteer?.name }}
</td>
<td (click)="goToDetail(evaluation)">
  <ng-container *ngFor="let user of getUserOrPeople(evaluation); let isLast = last">
    {{ user.name }}{{ isLast ? "" : ", " }}
  </ng-container>
</td>
<td (click)="goToDetail(evaluation)">
    <div class="d-flex flex-row gap-1">
      <svg viewBox="-1 -1 2 2" height="16px" width="16px" class="mr-1" style="transform: rotate(-90deg);">
        <path [attr.d]="utilService.pathProgress(evaluation.progress)" fill="#1ab394"></path>
        <path [attr.d]="utilService.pathProgress(1, evaluation.progress)" fill="#d7d7d7"></path>
      </svg>
      <small style="vertical-align: top">{{ evaluation.progress | percent:'1.0' }}</small>
    </div>
</td>
<td (click)="goToDetail(evaluation)">
  {{ evaluation.created_on | date:'yyyy-MM-dd' }}
</td>
<td class="width-action-bar" *ngIf="linkAuthorized">
  <dna-action-bar [actionsButtons]="actionsButtons | async" (onClick)="onClickButton($event)"></dna-action-bar>
</td>
<td class="width-action-bar" *ngIf="!linkAuthorized">
</td>
