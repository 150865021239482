<div class="container">
        <div class="ibox float-e-margins">
            <ngx-loading [show]="showSpinner"></ngx-loading>
            <div class="ibox-title">
                <div class="row">
                    <div class="col-12 col-md-4 my-auto">
                        <h5>{{ "DESCRIPTOR_ASSOCIATION" | translate }}</h5>
                    </div>
                </div>
            </div>
            <div class="ibox-content">
                <div class="row item-center" style="padding: 5px">
                    <p [innerHtml]="'DESCRIPTOR_DISCLAMER' | translate"></p>
                </div>
                <div class="row">
                    <div class="col-4">
                        <button class="btn btn-primary" type="button" (click)="autoCreateGroupDescriptor()">{{'AUTO_CREATE_GROUP_DESCRIPTOR' | translate}} <i class="fa fa-magic"></i></button>
                    </div>
                    <div class="col-4">
                        <button class="btn btn-primary" type="button" (click)="addGroupDescriptor()" translate>ADD_GROUP_DESCRIPTOR</button>
                    </div>
                    <div class="col-4">
                        <button class="btn btn-danger " type="button" (click)="deletaAllDescriptors()" translate>DELETE_ALL_DESCRIPTOR</button>
                    </div>
                </div>
                <div class="row">
                    <table class="table table-bordered dataTable">
                        <thead>
                            <tr>
                                <th style="background-color: #F5F5F6;" [ngClass]="{ 'sorting' : !sorted , 'sorting_asc' : sorted && !reverse, 'sorting_desc' : sorted && reverse }" (click)="sort()">{{ "NAME" | translate }}</th>
                                <th>{{ "BLOCKS" | translate }}</th>
                                <th>{{ "DESCRIPTORS" | translate }}</th>
                                <th>{{ "SCALE" | translate }}</th>
                                <th>{{ "NUMBER_STUDIES_DESCRIPTOR" | translate }}</th>
                                <th>{{ "ACTIONS" | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let descriptorsGroup of multiCampaign.descriptorsGroups; let index = index">
                                <td class="cursor-pointer" (click)="openGroup(descriptorsGroup)">
                                    <dna-translate [fieldToTranslate]="descriptorsGroup.name"> </dna-translate>
                                </td>
                                <td class="cursor-pointer" (click)="openGroup(descriptorsGroup)">
                                    {{getName(descriptorsGroup.descriptors, 'blockName')}}
                                </td>
                                <td class="cursor-pointer" (click)="openGroup(descriptorsGroup)">
                                    <p [innerHtml]="getName(descriptorsGroup.descriptors, 'name')"></p>
                                </td>
                                <td class="cursor-pointer" (click)="openGroup(descriptorsGroup)">
                                    <span [ngbTooltip]="descriptorsGroup.descriptors[0].scaleDetail">{{descriptorsGroup.descriptors[0].scale}}</span>
                                </td>
                                <td class="cursor-pointer" (click)="openGroup(descriptorsGroup)">
                                    {{descriptorsGroup?.descriptors?.length}} / {{multiCampaign.studies.length}}
                                </td>
                                <td>
                                    <span style="cursor: pointer" class="mr-1" class="fa fa-times" placement="top"
                                      (click)="removeDescriptorsGroup(descriptorsGroup, index)" ngbTooltip="{{ 'REMOVE' | translate }}"></span>
                                  </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
