import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { ExportDataSetComponent } from './export-data-set/export-data-set.component';
import { FeedbackComponent } from './general/my-profile/feedback/feedback.component';
import { ExportComponent } from './general/my-profile/import-export/export/export.component';
import { ImportExportComponent } from './general/my-profile/import-export/import-export.component';
import { ImportComponent } from './general/my-profile/import-export/import/import.component';
import { MyProfileComponent } from './general/my-profile/my-profile.component';
import { TranslationsManagementComponent } from './general/my-profile/translations-management/translations-management.component';
import { UserProfileComponent } from './general/my-profile/user-profile/user-profile.component';
import { WorkspacesComponent } from './general/my-profile/workspaces/list/workspaces.component';
import { VolunteersManagementComponent } from './general/my-profile/volunteers-management/volunteers-management.component';
import { AuthGuardService } from './shared/services/authenticationGuard.service';
import { AuthComponent } from './general/auth/auth.component';
import { BrowserUtils } from '@azure/msal-browser';

const appRoutes: Routes = [
    {
        component: MsalRedirectComponent,
        matcher: (url) => {
            return url.length === 1 && (url[0].path.startsWith('code') || url[0].path.startsWith('state'))
                ? { consumed: url }
                : null;
        },
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'auth'
    },
    {
        path: 'auth',
        component: AuthComponent,
        canActivate: [MsalGuard],
    },
    {
        path: 'exportDataSet',
        component: ExportDataSetComponent
    },
    {
        path: 'id_token',
        redirectTo: '',
    },
    {
        path: 'connection',
        redirectTo: 'auth',
    },
    {
        path: 'myprofile',
        component: MyProfileComponent,
        canActivate: [MsalGuard],
        children: [
            { path: '', redirectTo: 'identity', pathMatch: 'full' },
            { path: 'identity', component: UserProfileComponent },
            { path: 'feedback', component: FeedbackComponent },
            { path: 'workspaces', component: WorkspacesComponent },
            { path: 'volunteers-management', component: VolunteersManagementComponent },
            {
                path: 'import-export', component: ImportExportComponent, children: [
                    { path: '', redirectTo: 'import', pathMatch: 'full' },
                    { path: 'import', component: ImportComponent },
                    { path: 'export', component: ExportComponent }
                ]
            },
            {
                path: 'translations',
                component: TranslationsManagementComponent,
                canDeactivate: [AuthGuardService]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {
        initialNavigation:
          !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
            ? 'enabledNonBlocking'
            : 'disabled', // Set to enabledBlocking to use Angular Universal
        useHash: true
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
