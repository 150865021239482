<td>
  <dna-switch [isSelected]="campaign.isSelected" (onBooleanChanged)="onSwitchChanged($event)"></dna-switch>
</td>
<td (click)="openCampaign()">
  <span class="cursor-pointer">{{ campaign.name }}</span>
</td>
<td (click)="openCampaign()">
  <span translate>{{ campaign.state }}</span>
</td>
<td (click)="openCampaign()">
  <span *ngIf="campaign.workflows?.length > 1; else single_workflow" [ngbTooltip]="workflowList">
    <dna-translate [fieldToTranslate]="campaign.workflows[0].name"></dna-translate>
    <span>(+{{ campaign.workflows.length-1 }})</span>
  </span>
  <ng-template #single_workflow>
    <span>
      <dna-translate [fieldToTranslate]="campaign.workflows[0]?.name"></dna-translate>
    </span>
  </ng-template>
</td>
<td (click)="openCampaign()">
  <span *ngIf="formulasNames?.length > 1; else single_formula" [ngbTooltip]="formulaList">
    <span>{{ formulasNames[0] }} (+{{ formulasNames.length-1 }})</span>
  </span>
  <ng-template #single_formula>
    <span>{{ formulasNames[0] }}</span>
  </ng-template>
</td>
<td (click)="openCampaign()">
  <span>{{ campaign.synergy?.projectNumber }}</span>
</td>
<td>
  <img src="/assets/pc_icon.svg" alt="Pfc icon" width="20" *ngIf="campaign.activityNumber" class="cursor-pointer icon-class" (click)="goToPerformanceCenter(campaign.activityNumber)">
</td>
<td>
  <span class="cursor-pointer" (click)="goToActiview(campaign.activityNumber)">{{ campaign.activityNumber }}</span>
</td>
<td>
  <span class="cursor-pointer" (click)="goToSynergy(campaign.synergy?.requestNumber)">{{ campaign.synergy?.requestNumber }}</span>
</td>
<td (click)="openCampaign()">
  <span *ngIf="referentsOrContributors?.length > 1; else single_referentOrContributor" [ngbTooltip]="referentOrContributorList">
    <span>{{ userName }} (+{{ referentsOrContributors.length-1 }})</span>
  </span>
  <ng-template #single_referentOrContributor>
    <span>{{ userName }}</span>
  </ng-template>
</td>
<td (click)="openCampaign()">
  <dna-progress-user [onlyStarted]="true" [evaluations]="campaign.evaluations" [realyEvaluatedUsers]="campaign?.volunteersNumber" [idWorkflows]="idWorkflows"></dna-progress-user>
</td>
<td (click)="openCampaign()">
  <dna-progress-user [onlyStarted]="false" [evaluations]="campaign.evaluations" [realyEvaluatedUsers]="campaign?.volunteersNumber" [idWorkflows]="idWorkflows"></dna-progress-user>
</td>
<td (click)="openCampaign()">
  <span>{{ campaign.updated_on | date:'yyyy-MM-dd' }}</span>
</td>
<td class="width-action-bar">
    <div class="d-flex flex-row gap-2 align-items-center">
      <dna-favorites [id]="campaign.id" type="campaign"></dna-favorites>
      <dna-action-bar [actionsButtons]="actionsButtons | async" (onClick)="onClickButton($event)" class="d-flex w-100 gap-2"></dna-action-bar>
    </div>
</td>
<ng-template #workflowList>
  <ul class="tooltip-list">
    <ng-container *ngFor="let workflow of campaign.workflows">
      <li>
        <dna-translate [fieldToTranslate]="workflow.name"></dna-translate>
      </li>
    </ng-container>
  </ul>
</ng-template>
<ng-template #formulaList>
  <ul class="tooltip-list">
    <ng-container *ngFor="let formula of formulasNames">
      <li>{{ formula }}</li>
    </ng-container>
  </ul>
</ng-template>
<ng-template #referentOrContributorList>
  <ul class="tooltip-list">
    <ng-container *ngFor="let refco of referentsOrContributors">
      <li>{{ refco.name }}</li>
    </ng-container>
  </ul>
</ng-template>
