<ngx-loading [show]="showSpinner"></ngx-loading>
<h1 class="mb-4" id="banner-title">
  <span translate>FICHE_CARACTERISATION</span> - {{ panelist?.firstName }} {{ panelist?.lastName }}
</h1>
<nav aria-label="breadcrumb" id="banner-nav">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/dashboard" translate>DASHBOARD</a>
    </li>
    <li class="breadcrumb-item">
      <a routerLink="/volunteers" translate>VOLUNTEERS</a>
    </li>
    <li class="breadcrumb-item" aria-current="page">
      <a (click)="goToGeneralInfo()">{{ panelist?.panelistNumber }}</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page" translate>
      FICHE_CARACTERISATION
    </li>
  </ol>
</nav>
<div class="actions d-flex flex-row gap-1" id="banner-actions">
  <a *ngIf="isEditable" class="btn btn-primary text-white" (click)="save()">
    <span translate>SAVE</span>
  </a>
  <a class="btn btn-primary text-white" (click)="goToGeneralInfo()">
    <span *ngIf="isEditable; else notEditable" translate>CANCEL</span>
    <ng-template #notEditable>
      <span translate>BACK</span>
    </ng-template>
  </a>
</div>

<div *ngIf="AttributeData" class="block ibox float-e-margins">
  <div class="ibox-content">
    <div class="col-12 col-md-6 ">
      <div class="row margin-bottom">
        <div class="col-2">
          <label translate>BRIDGE</label>
        </div>
        <div class="col-8">
          <input [disabled]="!isEditable" class="form-control" type="text" [(ngModel)]="bridgeFiche">
        </div>
      </div>
    </div>
    <ng-container *ngFor="let dataCategory of AttributeDataCategories">
      <div class="row border-top" *ngIf="dataCategory.data.length">
        <h1 translate>{{dataCategory.category}}</h1>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-3" *ngFor="let data of dataCategory.data" [ngSwitch]="data.AttributeType">
          <ng-container *ngSwitchCase="2">
            <h4 for="label-test">{{ data.AttributeName }}</h4>
            <select [id]="data.AttributeID" class="form-control" [ngModel]="data"
              (ngModelChange)="changeAttributeData($event, data)" [disabled]="!isEditable" [compareWith]="compareByCode"
              [name]="data.AttributeID">
              <option [ngValue]="undefined"></option>
              <option *ngFor="let AttributeDetail of data.AttributeDetails" [ngValue]="AttributeDetail">
                {{ AttributeDetail.Text }}</option>
            </select>
          </ng-container>
          <ng-container *ngSwitchCase="5">
            <h4 for="label-test">{{ data.AttributeName }}</h4>
            <tag-input [id]="data.AttributeID" inputClass="tagInputFiltersProtocol"
              [(ngModel)]="multiple[data.AttributeID]" [disable]="!isEditable || isLocked(data)" displayBy="Text" identifyBy="Code"
              onlyFromAutocomplete="true" placeholder="" secondaryPlaceholder="" [name]="data.AttributeID" (ngModelChange)="changeTags($event, data)">
              <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="data.AttributeDetails" displayBy="Text"
                identifyBy="Code" [keepOpen]="false" [showDropdownIfEmpty]="true">
              </tag-input-dropdown>
            </tag-input>
          </ng-container>
          <ng-container *ngSwitchCase="3">
            <h4 for="label-test">{{ data.AttributeName }}</h4>
            <div class="form-group row">
              <div class="col-12 input-group">
                <input class="form-control" [(ngModel)]="date[data.AttributeID]"
                  (ngModelChange)="onDateChanged($event, data.AttributeID)" [disabled]="!isEditable" name="dend"
                  #dend="ngbDatepicker" ngbDatepicker>
                <div class="input-group-append h-100">
                  <button class="input-group-addon d-flex flex-row align-items-center h-100" [disabled]="!isEditable" type="button" (click)="dend.toggle()">
                    <i class="fa fa-calendar"></i>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="4">
            <h4 for="label-test">{{ data.AttributeName }}</h4>
            <div class="form-group row">
              <div class="col-12 input-group">
                <input class="form-control" [(ngModel)]="data.Value" (ngModelChange)="onStringChanged($event, data)"
                  [disabled]="!isEditable">
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="6">
            <div class="form-group d-flex flex-row justify-content-between">
              <h4 class="col-11">{{ data.AttributeName }}</h4>
              <div class="d-flex flex-row justify-content-end input-group">
                <dna-checkbox
                  [disabled]="!isEditable"
                  [isChecked]="data.Value === 'True'" (onBooleanChanged)="onChangeBoolean(data)">
                </dna-checkbox>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
