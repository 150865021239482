<div class="modal-header">
  <h3 class="modal-title" translate>PREVIEWING</h3>
  <button class="btn-close" type="button" aria-label="Close" (click)="onCancel()"></button>
</div>

<div class="modal-body">

  <!-- Visits -->
  <div *ngFor="let visit of routines[0].visits; let indexVisit = index; let isLastVisit = last">
    <div>
      <table class="table">
        <thead>
          <tr>
            <th class="background-color-visit" *ngIf="visit && visit.name; else defaultVisit" scope="col">{{ "ROUTINE_VISIT" | translate }} : {{ visit.name }}</th>
            <ng-template #defaultVisit><th scope="col" class="background-color-visit">{{ "ROUTINE_VISIT" | translate }} {{ visit.index+1 }}</th></ng-template>
          </tr>
        </thead>
      </table>

      <!-- Steps -->
      <div *ngFor="let order of visit.orders; let indexOrder = index" class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="background-color-steps" colspan="2">{{ "ORDER" | translate }} : {{ order.name }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" translate>ROUTINE_SHADES</th>
              <td>{{ order.shade }}</td>
            </tr>
            <tr>
              <th scope="row" translate>EVALUATION</th>
              <td>{{ order.evaluation }}</td>
            </tr>
          </tbody>
        </table>

        <!-- Formulas -->
        <div *ngFor="let formula of order.steps; let indexFormula = index" class="pl-4 table-responsive">

          <!-- Routines -->
          <table class="table table-margin">
            <thead>
              <tr>
                <th class="background-color-formula" scope="col" [attr.colspan]="routines.length + 1">{{ "STEP" | translate }}: {{ formula.name }}</th>
              </tr>
              <tr>
                <th scope="col" class="color-red" translate>ROUTINES</th>
                <th *ngFor="let routine of routines" class="color-red" scope="col">{{ routine.name }} {{ routine.label ? " - " + routine.label : "" }} </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" translate>ROUTINE_PRODUCT_NAME</th>
                <td *ngFor="let routine of routines" translate>
                  {{ routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].formula.productName }}
                </td>
              </tr>
              <tr>
                <th scope="row" translate>CATEGORY</th>
                <td *ngFor="let routine of routines" translate>
                  {{ routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].class }}
                </td>
              </tr>
              <tr>
                <th scope="row" translate>FORMULA</th>
                <td *ngFor="let routine of routines" translate>
                  {{ routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].formula.formulaName }}
                </td>
              </tr>
              <tr>
                <th scope="row" translate>ROUTINE_BATCH</th>
                <td *ngFor="let routine of routines" translate>
                  {{ routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].formula.lot }}
                </td>
              </tr>
              <tr>
                <th scope="row" translate>ROUTINE_QUANTITY</th>
                <td *ngFor="let routine of routines">
                  {{ routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].formula.quantity }}
                </td>
              </tr>
              <tr>
                <th scope="row" translate>APPLICATION_MODE</th>
                <td *ngFor="let routine of routines">
                  <ng-container *ngFor="let applicator of routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].formula.applicators; let isLast = last">
                    {{ applicator.key | translate }}{{ isLast ? "" : ", " }}
                  </ng-container>
                </td>
              </tr>
              <ng-container [ngSwitch]="metierName">
                <ng-container *ngSwitchCase="metiers.Hair">
                  <tr>
                    <th scope="row" translate>ROUTINE_APPLY_TO</th>
                    <td *ngFor="let routine of routines" translate>
                      {{ routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].formula.applyTo }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" translate>DRYING_TYPES</th>
                    <td *ngFor="let routine of routines">
                      <ng-container *ngFor="let dryingType of routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].formula.dryingTypes; let isLast = last">
                        {{ dryingType.key | translate }}{{ isLast ? "" : ", " }}
                      </ng-container>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngSwitchCase="metiers.Skin">
                  <tr>
                    <th scope="row" translate>TIME_DRYING</th>
                    <td *ngFor="let routine of routines">
                      {{ routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].formula.timeDrying }}
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <tr>
                <th scope="row" translate>ROUTINE_PAUSE_TIME</th>
                <td *ngFor="let routine of routines">
                  <ng-container *ngIf="isNewFormat(routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].formula.pauseTime); else pauseTimeNumber">
                    {{ routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].formula.pauseTime?.hour || 0 }} :
                    {{ routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].formula.pauseTime?.minute || 0 }} :
                    {{ routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].formula.pauseTime?.second || 0 }}
                  </ng-container>
                  <ng-template #pauseTimeNumber>
                    {{ routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].formula.pauseTime }}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th scope="row" translate>SOCLE</th>
                <td *ngFor="let routine of routines">
                  {{ routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].formula.socle }}
                </td>
              </tr>
              <tr>
                <th scope="row" translate>FEEDBACK</th>
                <td *ngFor="let routine of routines">
                  {{ routine.visits[indexVisit].orders[indexOrder].steps[indexFormula].formula.comments }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Separating line -->
    <hr *ngIf="!isLastVisit">
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-dark" type="button" (click)="onCancel()" translate>CLOSE</button>
</div>
