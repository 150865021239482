<div class="container">
<ngx-loading [show]="showSpinner">
</ngx-loading>
  <div *ngIf="!showSpinner && !error" class="ibox float-e-margins">
    <div class="ibox-title">
      <h5 class="text-center" translate>ANALYTICS</h5>
    </div>
    <div class="ibox-content">
      <div class="form-group">
        <div class="row">
          <span class="col-12 mb-3 center font-size-no-link" translate>WORKFLOWS</span>
          <div class="col-12 center-tag">
            <select class="form-control" [(ngModel)]="chosenWorkflow" [compareWith]="compare" name="select-workflows">
              <option *ngFor="let workflow of listWorkflows" [ngValue]="workflow">
                <dna-translate [fieldToTranslate]="workflow.name"> </dna-translate>
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <span class="col-12 mb-3 center font-size-no-link" translate>REPORT</span>
        <div class="col-12 center-tag">
          <button class="btn btn-primary btn-sm margin-btn" (click)="goToPageJSCharts(chosenWorkflow)"
            translate>DISPLAY_VIEW_REPORT</button>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div
            *ngIf="!chosenWorkflow"
            class="col-12 center font-size-no-link" translate>
            NO_LINK_DATAVIZ_POWERBI
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!showSpinner && error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "ERROR" | translate }}</h5>
        </div>
      </div>
    </div>
    <dna-error-page (onClickReload)="init()"></dna-error-page>
  </div>
</div>
