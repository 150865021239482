import { Descriptor } from '../../../../../types';

import {
  Component,
  Input,
} from '@angular/core';


@Component({
  selector: '[dna-descriptors-row]',
  templateUrl: './descriptors-row.component.html',
  //styleUrls: ['./descriptors-row.component.less'],
})
export class DescriptorsRowComponent {

  @Input() descriptor: Descriptor;

  constructor(

  ) { }

  ngOnInit() {
  }

}