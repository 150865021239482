<div class="modal-header">
  <h4 class="modal-title"> {{ title }}</h4>
  <button class="close" type="button" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true" class="fa fa-times"></span>
  </button>
</div>
<div class="modal-body">
  <ngx-loading [show]="horsArcsLoading" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
  <form #addArcsForm="ngForm" name="arcsform" class="form-horizontal" novalidate>
    <div class="form-group row">
      <label class="col-md-2 my-auto form-control-label required">
        {{ "GROUP_NAME_LABEL" | translate }}
      </label>
      <div class="col-md-6 input-group">
        <div class="input-group-prepend">
          <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" name="system"
            aria-haspopup="true" aria-expanded="false">
            {{ horsArcsSystem }}
          </button>
          <div class="dropdown-menu">
            <ng-container *ngFor="let country of countries">
              <a class="dropdown-item" (click)="setCountry(country)">{{ country }}</a>
            </ng-container>
          </div>
        </div>
        <!--input type="text" [(ngModel)]="groupNameHorsArcs" class="form-control" name="groupNameHorsArcs" #arcsNumberInput="ngModel"
          required-->
          <tag-input inputClass="" class="form-control form-control-input" secondaryPlaceholder="{{'GROUP_NAME_LABEL' | translate}}"
            displayBy="groupName" identifyBy="groupName" name="groupVolunteerToAdd" [onlyFromAutocomplete]="false"
            [(ngModel)]="groupVolunteerToAdd" maxItems=1 [(inputText)]="formHorsArcs">
                <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="groupVolunteers" displayBy="groupName" identifyBy="groupName" [minimumTextLength]="1"
                  [showDropdownIfEmpty]="false" limitItemsTo=5>
                </tag-input-dropdown>
          </tag-input>
      </div>
      <div *ngIf="horsArcsExists" class="offset-4 invalid-feedback">
        <p translate>ALREADY_EXIST_GROUP_HORS_ARCS</p>
      </div>
      <div *ngIf="horsArcsIncorrect" class="offset-4 invalid-feedback">
        <p translate>INCORRECT_GROUP_HORS_ARCS</p>
      </div>
    </div>

  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button"
    (click)="updateHorsArcsInProject()">{{ "VALIDATE" | translate }}</button>
  <button class="btn btn-default" type="button"
    (click)="cancel()">{{ "CANCEL" | translate }}</button>
</div>
