<div
    [ngClass]="{'selected': routine.selected, 'selectable': routine.onClick, 'routine-container': true}"
    [style.color]="this.routine.color"
    (click)="routine.onClick ? routine.onClick() : null"
>
    <span class="routine-container__name pr-2"> {{ this.routine.name }}</span>:
    <div class="routine-container__formulas">
        <span class="pl-2 pr-3" *ngFor="let formula of this.routine.formula; let last = last;">
            <i>{{ formula.name }} </i>
            {{ !last ? ' | ' : '' }} </span>
    </div>
</div>
