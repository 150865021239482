<ngx-loading [show]="showSpinner"></ngx-loading>
<div class="ibox float-e-margins">
  <div class="ibox-title">
    <div class="row">
      <div class="col-12 col-md-4 my-auto">
        <h5>{{ "TARGET" | translate }} - {{ "METIER_SKIN" | translate }}</h5>
      </div>
      <div class="col-12 col-md-2 my-auto">
        <dna-checkbox [isChecked]="allFormChecked" cssClass="icheckbox_square-green-sm" [text]="(allFormChecked ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate"
          (onBooleanChanged)="allFormChecked ? uncheckAllForm() : checkAllForm()" [disabled]="publishedTemplate">
        </dna-checkbox>
      </div>
      <div class="col-12 col-md-6 my-auto">
        <div *ngIf="isChangedTarget()" class="float-end d-flex flex-row gap-1">
          <button class="btn btn-primary mr-2" type="button" (click)="onSubmit()" translate>SAVE</button>
          <button class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="target" class="ibox-content">
    <div class="row margin-bottom border-bottom" *ngIf="target.cheeksBlemish && target.cheeksBlemish.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.cheeksBlemish ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.cheeksBlemish" [disabled]="publishedTemplate"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.cheeksBlemish = selectUnselectLine(target.cheeksBlemish, lineSelectedObject.cheeksBlemish)">
        </dna-checkbox>
        <label translate>CHEEKS_BLEMISH</label>
      </div>
      <div class="col-1" *ngFor="let cheeksBlemish of target.cheeksBlemish; let index = index">
        <dna-checkbox [isChecked]="cheeksBlemish.IsValue" [disabled]="publishedTemplate" [text]="cheeksBlemish.Text" (onBooleanChanged)="onChangeData(target.cheeksBlemish, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.cheeksRedness && target.cheeksRedness.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.cheeksRedness ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.cheeksRedness"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.cheeksRedness = selectUnselectLine(target.cheeksRedness, lineSelectedObject.cheeksRedness)">
        </dna-checkbox>
        <label translate>CHEEKS_REDNESS</label>
      </div>
      <div class="col-1" *ngFor="let cheeksRedness of target.cheeksRedness; let index = index">
        <dna-checkbox [isChecked]="cheeksRedness.IsValue" [disabled]="publishedTemplate" [text]="cheeksRedness.Text" (onBooleanChanged)="onChangeData(target.cheeksRedness, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.dilatedPores && target.dilatedPores.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.dilatedPores ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.dilatedPores"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.dilatedPores = selectUnselectLine(target.dilatedPores, lineSelectedObject.dilatedPores)">
        </dna-checkbox>
        <label translate>DILATED_PORES</label>
      </div>
      <div class="col-1" *ngFor="let dilatedPores of target.dilatedPores; let index = index">
        <dna-checkbox [isChecked]="dilatedPores.IsValue" [disabled]="publishedTemplate" [text]="dilatedPores.Text" (onBooleanChanged)="onChangeData(target.dilatedPores, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.eyelashesCurve && target.eyelashesCurve.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.eyelashesCurve ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.eyelashesCurve"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.eyelashesCurve = selectUnselectLine(target.eyelashesCurve, lineSelectedObject.eyelashesCurve)">
        </dna-checkbox>
        <label translate>EYELASHES_CURVE</label>
      </div>
      <div class="col-1" *ngFor="let eyelashesCurve of target.eyelashesCurve; let index = index">
        <dna-checkbox [isChecked]="eyelashesCurve.IsValue" [disabled]="publishedTemplate" [text]="eyelashesCurve.Text" (onBooleanChanged)="onChangeData(target.eyelashesCurve, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.eyelashesDensity && target.eyelashesDensity.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.eyelashesDensity ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.eyelashesDensity"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.eyelashesDensity = selectUnselectLine(target.eyelashesDensity, lineSelectedObject.eyelashesDensity)">
        </dna-checkbox>
        <label translate>EYELASHES_DENSITY</label>
      </div>
      <div class="col-1" *ngFor="let eyelashesDensity of target.eyelashesDensity; let index = index">
        <dna-checkbox [isChecked]="eyelashesDensity.IsValue" [disabled]="publishedTemplate" [text]="eyelashesDensity.Text" (onBooleanChanged)="onChangeData(target.eyelashesDensity, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.eyelashesLength && target.eyelashesLength.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.eyelashesLength ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.eyelashesLength"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.eyelashesLength = selectUnselectLine(target.eyelashesLength, lineSelectedObject.eyelashesLength)">
        </dna-checkbox>
        <label translate>EYELASHES_LENGTH</label>
      </div>
      <div class="col-1" *ngFor="let eyelashesLength of target.eyelashesLength; let index = index">
        <dna-checkbox [isChecked]="eyelashesLength.IsValue" [disabled]="publishedTemplate" [text]="eyelashesLength.Text" (onBooleanChanged)="onChangeData(target.eyelashesLength, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.facialBrightness && target.facialBrightness.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.facialBrightness ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.facialBrightness"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.facialBrightness = selectUnselectLine(target.facialBrightness, lineSelectedObject.facialBrightness)">
        </dna-checkbox>
        <label translate>FACIAL_BRIGHTNESS</label>
      </div>
      <div class="col-2" *ngFor="let facialBrightness of target.facialBrightness; let index = index">
        <dna-checkbox [isChecked]="facialBrightness.IsValue" [disabled]="publishedTemplate" [text]="facialBrightness.Text" (onBooleanChanged)="onChangeData(target.facialBrightness, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.facialSkin && target.facialSkin.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.facialSkin ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.facialSkin"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.facialSkin = selectUnselectLine(target.facialSkin, lineSelectedObject.facialSkin)">
        </dna-checkbox>
        <label translate>FACIAL_SKIN</label>
      </div>
      <div class="col-1" *ngFor="let facialSkin of target.facialSkin; let index = index">
        <dna-checkbox [isChecked]="facialSkin.IsValue" [disabled]="publishedTemplate" [text]="facialSkin.Text" (onBooleanChanged)="onChangeData(target.facialSkin, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.lipsSize && target.lipsSize.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.lipsSize ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.lipsSize"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.lipsSize = selectUnselectLine(target.lipsSize, lineSelectedObject.lipsSize)">
        </dna-checkbox>
        <label translate>LIPS_SIZE</label>
      </div>
      <div class="col-1" *ngFor="let lipsSize of target.lipsSize; let index = index">
        <dna-checkbox [isChecked]="lipsSize.IsValue" [disabled]="publishedTemplate" [text]="lipsSize.Text" (onBooleanChanged)="onChangeData(target.lipsSize, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.lipsThickness && target.lipsThickness.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.lipsThickness ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.lipsThickness"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.lipsThickness = selectUnselectLine(target.lipsThickness, lineSelectedObject.lipsThickness)">
        </dna-checkbox>
        <label translate>LIPS_THICKNESS</label>
      </div>
      <div class="col-1" *ngFor="let lipsThickness of target.lipsThickness; let index = index">
        <dna-checkbox [isChecked]="lipsThickness.IsValue" [disabled]="publishedTemplate" [text]="lipsThickness.Text" (onBooleanChanged)="onChangeData(target.lipsThickness, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.phototype && target.phototype.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.phototype ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.phototype"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.phototype = selectUnselectLine(target.phototype, lineSelectedObject.phototype)">
        </dna-checkbox>
        <label translate>PHOTOTYPE</label>
      </div>
      <div class="col-1" *ngFor="let phototype of target.phototype; let index = index">
        <dna-checkbox [isChecked]="phototype.IsValue" [disabled]="publishedTemplate" [text]="phototype.Text" (onBooleanChanged)="onChangeData(target.phototype, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.foreheadTone && target.foreheadTone.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.foreheadTone ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.foreheadTone"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.foreheadTone = selectUnselectLine(target.foreheadTone, lineSelectedObject.foreheadTone)">
        </dna-checkbox>
        <label translate>FOREHEAD_TONE</label>
      </div>
      <div class="col-1" *ngFor="let foreheadTone of target.foreheadTone; let index = index">
        <dna-checkbox [isChecked]="foreheadTone.IsValue" [disabled]="publishedTemplate" sideLabel="right" [text]="foreheadTone.Text" (onBooleanChanged)="onChangeData(target.foreheadTone, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row" *ngIf="target.skinType && target.skinType.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.skinType ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.skinType"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.skinType = selectUnselectLine(target.skinType, lineSelectedObject.skinType)">
        </dna-checkbox>
        <label translate>SKIN_TYPE</label>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.skinType.length > 0">
      <div class="col-2 margin-bottom" *ngFor="let skinType of target.skinType; let index = index">
        <dna-checkbox [isChecked]="skinType.IsValue" [disabled]="publishedTemplate" [text]="skinType.Text" (onBooleanChanged)="onChangeData(target.skinType, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.upperEyelidsRelaxation && target.upperEyelidsRelaxation.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.upperEyelidsRelaxation ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.upperEyelidsRelaxation"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.upperEyelidsRelaxation = selectUnselectLine(target.upperEyelidsRelaxation, lineSelectedObject.upperEyelidsRelaxation)">
        </dna-checkbox>
        <label translate>UPPEREYELIDS_RELAXATION</label>
      </div>
      <div class="col-1" *ngFor="let upperEyelidsRelaxation of target.upperEyelidsRelaxation; let index = index">
        <dna-checkbox [isChecked]="upperEyelidsRelaxation.IsValue" [disabled]="publishedTemplate" [text]="upperEyelidsRelaxation.Text" (onBooleanChanged)="onChangeData(target.upperEyelidsRelaxation, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.upperEyelidsSurfaceArea && target.upperEyelidsSurfaceArea.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.upperEyelidsSurfaceArea ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.upperEyelidsSurfaceArea"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.upperEyelidsSurfaceArea = selectUnselectLine(target.upperEyelidsSurfaceArea, lineSelectedObject.upperEyelidsSurfaceArea)">
        </dna-checkbox>
        <label translate>UPPEREYELIDS_SURFACE_AREA</label>
      </div>
      <div class="col-1" *ngFor="let upperEyelidsSurfaceArea of target.upperEyelidsSurfaceArea; let index = index">
        <dna-checkbox [isChecked]="upperEyelidsSurfaceArea.IsValue" [disabled]="publishedTemplate" [text]="upperEyelidsSurfaceArea.Text" (onBooleanChanged)="onChangeData(target.upperEyelidsSurfaceArea, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.wrinklesUnderEye && target.wrinklesUnderEye.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.wrinklesUnderEye ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.wrinklesUnderEye"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.wrinklesUnderEye = selectUnselectLine(target.wrinklesUnderEye, lineSelectedObject.wrinklesUnderEye)">
        </dna-checkbox>
        <label translate>WRINKLES_UNDER_EYE</label>
      </div>
      <div class="col-1" *ngFor="let wrinklesUnderEye of target.wrinklesUnderEye; let index = index">
        <dna-checkbox [isChecked]="wrinklesUnderEye.IsValue" [disabled]="publishedTemplate" [text]="wrinklesUnderEye.Text" (onBooleanChanged)="onChangeData(target.wrinklesUnderEye, index)">
        </dna-checkbox>
      </div>
    </div>
    <div class="row margin-bottom border-bottom" *ngIf="target.darkCircles && target.darkCircles.length > 0">
      <div class="col-3">
        <dna-checkbox ngbTooltip="{{ (lineSelectedObject.darkCircles ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.darkCircles"
          cssClass="icheckbox_square-green-sm" [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.darkCircles = selectUnselectLine(target.darkCircles, lineSelectedObject.darkCircles)">
        </dna-checkbox>
        <label translate>DARK_CIRCLES_UNDER_EYES</label>
      </div>
      <div class="col-1" *ngFor="let darkCircle of target.darkCircles; let index = index">
        <dna-checkbox [isChecked]="darkCircle.IsValue" [disabled]="publishedTemplate" [text]="darkCircle.Text" (onBooleanChanged)="onChangeData(target.darkCircles, index)">
        </dna-checkbox>
      </div>
    </div>
  </div>
</div>
