import { TreeNode } from './node.model';

export class Tree {
  data: any;
  children: Array<TreeNode>;
  header: any;
  orderHeader: any;
  id: string = "root";
  branchId: string = "line";

  constructor(data: any) {
    this.data = data;
    this.children = [];
    data.forEach((child: any, index: number) => {
      const newChild = new TreeNode(child, this, `${index}`, `${this.branchId}-${index}`);
      this.children.push(newChild);
    });
    this.countRows();
  }

  createTable() {
    const table = {
      thead: new Map(),
      tbody: [[]]
    };
    try {
      // TEMP VARIABLES FOR HEADERS AND BODY
      let created = false;
      // CREATE TABLE
      this.children.forEach((child, index) => {
        // CREATE HEADER IF NOT CREATED
        if (!this.header) {
          if (!table.thead.has(child.data.parentLabel)) {
            // VARIABLES FOR NESTED LOOP
            let temp = child.data.parentLabel;
            let tempHeaderTree = table.thead;
            do {
              // ADD HEADER IN MAP
              if (!tempHeaderTree.has(temp.label.id)) {
                  tempHeaderTree.set(temp.label.id, {
                    id: temp.label.id,
                    label: temp.label.label,
                    children: new Map()
                  });
              }
              // CHANGE VARIABLES FOR NEXT ITERATION
              tempHeaderTree = temp.children ? (temp.children.label.id === temp.label.id ? tempHeaderTree : tempHeaderTree.get(temp.label.id).children) : null;
              temp = temp.children;
            } while (temp);
          }
        }
        if (created) table.tbody.push([]);
        created = true;
        
        // PASS TABLE TO CHILDREN AND CREATE CHILDREN TABLE
        child.createTable(table, this.header);
      });
  
      if (!this.header) {
        let headerTree = new Tree(table.thead);
        const {header, orderHeader} = headerTree.createHeader();
        this.header = header;
        this.orderHeader = orderHeader;
      }
      table.thead = this.header;
    } 
    catch( e ) { throw new Error(e) }
    finally { 
      return table; 
    };
  }

  public createHeader() {
    // CALCULATE MEW DEPTH
    const maxDepth = this.calculateDepth();
    // TEMPHEADER
    const header = [];
    // VARIABLE TO KEEP TRACK OF HEADER WITH SORT ABILITY
    const orderHeader = [];
    // VARIABLE TO KEEP TRACK OF COLUMN INDEX, OBJECT TO PASS BY REF
    const headerIndexTrack = {
      index: 0,
    };
    this.children.forEach((child) => {
      child.createHeader(header, orderHeader, 1, maxDepth, headerIndexTrack);
    });
    return {
      header,
      orderHeader,
    };
  }

  private calculateDepth() {
    let maxDepth = 0;
    this.children.forEach(child => {
      let childDepth = child.calculateDepth(1);
      if (childDepth > maxDepth) maxDepth = childDepth;
    });
    return maxDepth;
  }

  countRows() {
    this.children.forEach(child => {
      child.count();
    })
  }

  order(index, asc) {
    // CHANGE ORDER OF HEADERS
    this.checkHeaderOrder(index, asc);
    // IF INDEX 0 THEN EASY SORT THE FIRST COLUMN WICH ARE THE DIRECT CHILDREN OF THE TREE
    if (index === 0) {
      this.children = this.children.sort((a, b) => {
        if (typeof a.data.value === "string" || typeof b.data.value === "string") {
          if (a.data.value === null) return asc ? 1 : -1;
          if (b.data.value === null) return asc ? -1 : 1;
          return asc ? a.data.value.toString().localeCompare(b.data.value) : b.data.value.toString().localeCompare(a.data.value);
        } else {
          return asc ? a.data.value - b.data.value : b.data.value - a.data.value;
        }
      });
      return;
    }

    // OTHERWISE NEED TO DETERMINE THE MIN DIRECTLINE AMONG ALL LINES (SUCCESSIVE COLUNM WITH ONLY ONE CHILD)
    const directLine = this.children.reduce((minLine: null | number, child) => {
      if (minLine === null) return child.directLine;
      if (minLine > child.directLine) return child.directLine;
      return minLine;
    }, null);
    // IF NO DIRECTLINE FOUND THEN CALL CHILDREN ORDER METHOD
    if (directLine === 0) {
      this.children.forEach(child => child.order(index - 1, asc));
      return;
    }

    // IF IN SCOPE THEN ORDER CURRENT COLUMN DEPENDING ON CHILDREN VALUES
    if (directLine > index - 1) {
      this.children = this.children.sort((a, b) => {
        const aValue = a.searchDirectChildValue(index);
        const bValue = b.searchDirectChildValue(index);
        if (typeof aValue === "string" || typeof bValue === "string") {
          if (aValue === null) return asc ? 1 : -1;
          if (bValue === null) return asc ? -1 : 1;
          return asc ? aValue.toString().localeCompare(bValue) : bValue.toString().localeCompare(aValue);
        } else {
          return asc ? aValue - bValue : bValue - aValue;
        }
      });
      return;
    }

    // IF NOT IN SCOPE JUMP TO END OF DIRECTLINE AND DO SORT
    const childrenNode = this.getChildrenFrom(directLine);
    childrenNode.forEach(child => child.order(index - directLine - 1, asc));
  }

  up(directLine) { };

  getChildrenFrom(directLineOffset) {
    return this.children.reduce((array, child) => {
      return [...array, ...child.getChildrenFrom(directLineOffset - 1)]
    }, []);;
  }

  searchDirectChildValue(directLineOffset) {
    return this.children[0].searchDirectChildValue(directLineOffset - 1);
  }

  checkHeaderOrder(index, asc) {
    this.orderHeader.forEach((head, headIndex) => {
      head.order = headIndex === index ?
        asc ? 1 : -1 :
        0
    });
  }
};
