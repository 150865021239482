import {
  of as observableOf, forkJoin as observableForkJoin,
  Observable
} from 'rxjs';

import {map} from 'rxjs/operators';
import {
  Injectable
} from '@angular/core';

import * as _ from 'lodash';
import {
  TranslateService
} from '@ngx-translate/core';

import {
  ApplicationModes,
  CalculationMethod,
  DNAGraphsTypes,
  DryingTypes,
  GraphType,
  Hub,
  HubById,
  Hubs,
  HubsById,
  KeyValue,
  Metiers,
  Priority,
  Priorities,
  RoutineApplication,
  RoutineApplications,
  TimelineTypes,
  Type,
  TYPE_CAMPAIGN,
  TYPE_EVALUATION,
  TypeApplicationArea,
  TypeApplicationAreas,
  TypeApplicationMode,
  TypeApplicationTypes,
  TypeDryingType,
  TypeMethod,
  TypeMethods,
  TypeMetier,
  TypeScale,
  TypeScales,
  ProductsTypes,
  ProductsType,
  TypeSubstrate,
  TypeSubstrates,
  TypeTest,
  TypeTests,
  IndexOperator,
  OperatorType,
  Language,
  Languages,
  DNAGraphs,
  Methods,
  Method,
  StudyType,
  StudyTypes,
  FOCUS,
  VisitUnitTab
} from '../../types';
import {
  UserService
} from './user.service';

@Injectable()
export class ReferenceTypeService {

  CLINICAL: Type = TYPE_CAMPAIGN.CLINICAL;
  CONSUMERS: Type = TYPE_CAMPAIGN.CONSUMERS;
  INSTRUMENTAL: Type = TYPE_CAMPAIGN.INSTRUMENTAL;
  SENSORY_EXPERT: Type = TYPE_CAMPAIGN.SENSORY_EXPERT;
  SENSORY_CONFORT: Type = TYPE_CAMPAIGN.CONFORT;

  ADHOC: Type = TYPE_EVALUATION.ADHOC;
  PERFORMANCE: Type = TYPE_EVALUATION.PERFORMANCE;
  PILOT: Type = TYPE_EVALUATION.PILOT;
  STANDARD: Type = TYPE_EVALUATION.STANDARD;

  FORMULATION_SUPPORT: Type = FOCUS.FORMULATION_SUPPORT;
  COMMUNICATION: Type = FOCUS.COMMUNICATION;
  KNOWLEDGE: Type = FOCUS.KNOWLEDGE;
  FOLDER_CLAIM: Type = FOCUS.FOLDER_CLAIM;

  constructor(
    private translateService: TranslateService,
    private userService: UserService
  ) {
  }

  dataTranslations<T>(array: T[], key: string, value: string): Observable<T[]> {
    if (array && _.isArray(array) && array.length > 0) {
      const arrayObs = array.map(elt => this.stringToObjectTranslations(elt, key, value));
      return observableForkJoin(arrayObs);
    }
    return observableOf([]);
  }

  getApplicationAreas(metierName: TypeMetier): Observable<TypeApplicationArea[]> {
    let applicationAreaIds;
    switch (metierName) {
      case Metiers.Hair:
        applicationAreaIds = [TypeApplicationAreas.ClippedLocks_Extensions, TypeApplicationAreas.Hair,
          TypeApplicationAreas.Scalp, TypeApplicationAreas.HairRoots, TypeApplicationAreas.Beard,
          TypeApplicationAreas.Moustache, TypeApplicationAreas.HairEnds, TypeApplicationAreas.HairLength,
          TypeApplicationAreas.HairRLP, TypeApplicationAreas.MiniG, TypeApplicationAreas.MiniN, TypeApplicationAreas.MiniT
        ];
        break;
      case Metiers.Skin:
        applicationAreaIds = [
          TypeApplicationAreas.Eyebrows, TypeApplicationAreas.EyeContour, TypeApplicationAreas.Eyelashes, TypeApplicationAreas.Eyelids,
          TypeApplicationAreas.Face, TypeApplicationAreas.Lips, TypeApplicationAreas.Arms
        ];
        break;
      default:
        applicationAreaIds = [];
    }

    return <Observable<TypeApplicationArea[]>>this.stringToKeyValueTranslations(applicationAreaIds);
  }

  getApplicationModes(metierName: TypeMetier): Observable<TypeApplicationMode[]> {
    let applicationModesIds;
    switch (metierName) {
      case Metiers.Hair:
        applicationModesIds = [
          ApplicationModes.BowlBrush, ApplicationModes.Brush, ApplicationModes.Cap_Hairnet, ApplicationModes.Comb, ApplicationModes.Curlers,
          ApplicationModes.Hands, ApplicationModes.HeatingPads, ApplicationModes.OpenAir_Planchette, ApplicationModes.Pack,
          ApplicationModes.PartingByParting, ApplicationModes.Pipette, ApplicationModes.RollOn, ApplicationModes.Sponge,
          ApplicationModes.Spotting, ApplicationModes.ThreePartingOnVertex, ApplicationModes.TubeCanule,
          ApplicationModes.Vertex, ApplicationModes.Wrapped, ApplicationModes.Wrapped_Alu,
          ApplicationModes.Wrapped_Cellophane, ApplicationModes.Wrapped_Quick, ApplicationModes.Wrapped_Sweet
        ];
        break;
      case Metiers.Skin:
        applicationModesIds = [ApplicationModes.Finger, ApplicationModes.Brush, ApplicationModes.Pack,
          ApplicationModes.Sponge, ApplicationModes.Cotton];
        break;
      default:
        applicationModesIds = [];
    }

    return <Observable<TypeApplicationMode[]>>this.stringToKeyValueTranslations(applicationModesIds);
  }

  getApplicationTypes(): Observable<KeyValue[]> {
    const TypeApplicationTypeIds = [
      TypeApplicationTypes.EntireHead.key, TypeApplicationTypes.HalfHead.key, TypeApplicationTypes.LocksNecks.key,
      TypeApplicationTypes.ThreeRoutines.key, TypeApplicationTypes.TwoRoutinesNeck.key,
      TypeApplicationTypes.TwoRoutinesNeck_Strip.key, TypeApplicationTypes.ThreeRoutinesNeck.key,
      TypeApplicationTypes.ThreeQuarters_OneQuarter.key, TypeApplicationTypes.HalfHead_ClippedLock.key,
      TypeApplicationTypes.HalfHead_ClippedLockHot.key, TypeApplicationTypes.ClippedLock.key,
      TypeApplicationTypes.ThreeRoutinesFrontBack.key, TypeApplicationTypes.ThreeRoutinesLeftRight.key
    ];
    return <Observable<KeyValue[]>>this.stringToKeyValueTranslations(TypeApplicationTypeIds);
  }

  getCalculationMethod(): string[] {
    return [CalculationMethod.Mediane, CalculationMethod.Somme];
  }

  getDryingTypes(): Observable<TypeDryingType[]> {
    const TypeDryingTypeIds = [
      DryingTypes.BlowOutWithBrush_Comb, DryingTypes.BlowOutWithFingers, DryingTypes.Climazon, DryingTypes.CurlCreation,
      DryingTypes.Diffuser, DryingTypes.ElectricCurlingIron, DryingTypes.ElectricFlatIron, DryingTypes.HoodDryer,
      DryingTypes.NaturalDrying, DryingTypes.OtherThermalStylingTool, DryingTypes.Steampod
    ];
    return <Observable<TypeDryingType[]>>this.stringToKeyValueTranslations(TypeDryingTypeIds);
  }

  getGraphs(isMulti = false): GraphType[] {
    const user = this.userService.getUser();
    let graphs = DNAGraphsTypes;
    const graphsAllowed = _.get(user, 'currentWorkspace.preferences.graphs');
    if (graphsAllowed) {
      graphs = graphs.reduce((array, currentGraph) => {
        if (graphsAllowed[currentGraph.id] && graphsAllowed[currentGraph.id] === true) {
          array.push(currentGraph);
        }
        return array;
      }, []);
    }
    if (isMulti) {
      graphs = graphs.filter(g => g.id !== DNAGraphs.BarTwoSided.id &&
        g.id !== DNAGraphs.Histogram.id &&
        g.id !== DNAGraphs.HistogramMultiAttributs.id);
    } else {
      graphs = graphs.filter(g =>
        g.id !== DNAGraphs.HistogramMultiMedian.id &&
        g.id !== DNAGraphs.HistogramMultiSomme.id &&
        g.id !== DNAGraphs.Bubble.id &&
        g.id !== DNAGraphs.LineChartMulti.id);
    }
    return graphs;
  }

  getHubFromHubById(hub: HubById): Hub {
    switch (hub) {
      case HubsById.Brazil:
        return Hubs.Brazil;
      case HubsById.China:
        return Hubs.China;
      case HubsById.France:
        return Hubs.France;
      case HubsById.India:
        return Hubs.India;
      case HubsById.Japan:
        return Hubs.Japan;
      case HubsById.US:
        return Hubs.US;
      case HubsById.SouthAfrica:
        return Hubs.SouthAfrica;
      case HubsById.Mexico:
        return Hubs.Mexico;
      default:
        return Hubs.France;
    }
  }

  getHubByIdFromHub(hub: Hub): HubById {
    switch (hub) {
      case Hubs.Brazil:
        return HubsById.Brazil;
      case Hubs.China:
        return HubsById.China;
      case Hubs.France:
        return HubsById.France;
      case Hubs.India:
        return HubsById.India;
      case Hubs.Japan:
        return HubsById.Japan;
      case Hubs.US:
        return HubsById.US;
      case Hubs.SouthAfrica:
        return HubsById.SouthAfrica;
      case Hubs.Mexico:
        return HubsById.Mexico;
      default:
        return HubsById.France;
    }
  }

  getHubs(): Hub[] {
    return [Hubs.Brazil, Hubs.China, Hubs.France, Hubs.India, Hubs.Japan, Hubs.US, Hubs.SouthAfrica, Hubs.Mexico];
  }

  getHubsById(): HubById[] {
    return [HubsById.Brazil, HubsById.China, HubsById.France, HubsById.India, HubsById.Japan, HubsById.US, HubsById.SouthAfrica, HubsById.Mexico];
  }

  getLanguages(): Language[] {
    return [Languages.English, Languages.French, Languages.Japanese, Languages.Portuguese, Languages.Spanish, Languages.Chinese];
  }

  getProtocolMethods(metierName: TypeMetier): Observable<TypeMethod[]> {
    let typeMethodIds;
    switch (metierName) {
      case Metiers.Hair:
        typeMethodIds = [
          TypeMethods.Acceptability,
          TypeMethods.AntiDandruffEfficiency,
          TypeMethods.AntiOilyEfficiency,
          TypeMethods.Bonding,
          TypeMethods.Cleanliness,
          TypeMethods.Cosmetic,
          TypeMethods.CurlPerf,
          TypeMethods.Discomfort,
          TypeMethods.Dosage,
          TypeMethods.Ergoanalysis,
          TypeMethods.Fixation,
          TypeMethods.HairSampling,
          TypeMethods.Integrity,
          TypeMethods.LissagePerf,
          TypeMethods.Manageability,
          TypeMethods.Measurements,
          TypeMethods.ShadeLift,
          TypeMethods.Shine,
          TypeMethods.StylingPerformances,
          TypeMethods.Texture,
          TypeMethods.Volume,
          TypeMethods.UsageQualities
        ];
        break;
      case Metiers.Skin:
        typeMethodIds = [TypeMethods.ImmediateEffect, TypeMethods.LongLasting, TypeMethods.Noodles];
        break;
      default:
        typeMethodIds = [];
    }

    return <Observable<TypeMethod[]>>this.stringToKeyValueTranslations(typeMethodIds);
  }

  getMethods(): Observable<Method[]> {
    const methodIds = [Methods.absolute, Methods.simultaneousComparison, Methods.versusBareSkin];
    return <Observable<Method[]>>this.stringToKeyValueTranslations(methodIds);
  }

  getPriorities(): Priority[] {
    return [Priorities.Priority1, Priorities.Priority2, Priorities.Priority3];
  }

  getRoutineApplications(): RoutineApplication[] {
    return [RoutineApplications.HairDry, RoutineApplications.HairWet, RoutineApplications.Layering];
  }

  getScales(): Observable<TypeScale[]> {
    const scalesIds = [
      TypeScales.Mirror, TypeScales.MirrorQualitative, TypeScales.OpenComments, TypeScales.Qualitative,
      TypeScales.Ranking, TypeScales.Linear
    ];
    return <Observable<TypeScale[]>>this.stringToKeyValueTranslations(scalesIds);
  }

  getStudies(metierName: TypeMetier): Observable<ProductsType[]> {
    let studiesIds;
    switch (metierName) {
      case Metiers.Hair:
        studiesIds = [
          ProductsTypes.Shampoo, ProductsTypes.ComplexRoutine, ProductsTypes.Bundle, ProductsTypes.RinsedCare,
          ProductsTypes.ScalpCare, ProductsTypes.NonRinsedCare, ProductsTypes.Trinôme, ProductsTypes.PreShampoo,
          ProductsTypes.Device, ProductsTypes.DeviceProduct, ProductsTypes.OxydationDyeing, ProductsTypes.TonOnTon,
          ProductsTypes.Discolouration, ProductsTypes.DirectColouring, ProductsTypes.NaturalColouring,
          ProductsTypes.PermanentMakeUp, ProductsTypes.HairMakeUp, ProductsTypes.Pack, ProductsTypes.Defrisage,
          ProductsTypes.HairSmoothing, ProductsTypes.Permanente, ProductsTypes.StylingCream, ProductsTypes.StylingFoam,
          ProductsTypes.StylingGel, ProductsTypes.StylingLacquer, ProductsTypes.StylingPowder,
          ProductsTypes.StylingSerum, ProductsTypes.StylingSpray, ProductsTypes.StylingWax, ProductsTypes.DryShampoo,
          ProductsTypes.PreTraitement
        ];
        break;
      case Metiers.Skin:
        studiesIds = [
          ProductsTypes.BB, ProductsTypes.CleansingFoamCleanser, ProductsTypes.CleansingRinseOff,
          ProductsTypes.CleansingWipeOff, ProductsTypes.Concealer, ProductsTypes.Cream, ProductsTypes.Device,
          ProductsTypes.Eyebrow, ProductsTypes.EyeContour, ProductsTypes.EyeLiner, ProductsTypes.Facemask,
          ProductsTypes.FacemaskSupport, ProductsTypes.FAP, ProductsTypes.FDTCompact, ProductsTypes.FDTLiquid,
          ProductsTypes.Hybrid, ProductsTypes.LipPencil, ProductsTypes.Lotion, ProductsTypes.Mascara, ProductsTypes.Oil,
          ProductsTypes.Powder, ProductsTypes.RAL, ProductsTypes.Scrub, ProductsTypes.Serum, ProductsTypes.Spray, ProductsTypes.Stick
        ];
        break;
      default:
        studiesIds = [];
    }
    return <Observable<ProductsType[]>>this.stringToKeyValueTranslations(studiesIds);
  }

  getStudyTypes(): Observable<StudyType[]> {
    const studyTypeIds = [StudyTypes.EH_SHORT_HOUSE, StudyTypes.EH_SHORT_CONTRACTORS, StudyTypes.EH_MEDIUM_HOUSE,
      StudyTypes.EH_MEDIUM_CONTRACTORS, StudyTypes.EH_LONG_HOUSE, StudyTypes.EH_LONG_CONTRACTORS, StudyTypes.EC_FE,
      StudyTypes.EC_FE_CUSTOM_LG_LASTING_EFFECTS, StudyTypes.WORKSHOP, StudyTypes.WORKSHOP_CONTRACTORS,
      StudyTypes.EXPLOITING_USE_OF_DATABASE, StudyTypes.SCALP_DISCOMFORT, StudyTypes.SCALP_DISCOMFORT_CONTRACTORS,
      StudyTypes.SKIN_DISCOMFORT, StudyTypes.PROFILE_INTERNAL_RESOURCES, StudyTypes.PROFILE_CONTRACTED_RESOURCES,
      StudyTypes.SENSORY_TEST_NOVEL_TECHNIQUES_OR_NAILSUS, StudyTypes.FLASH_IN_VITRO_EXPERTS, StudyTypes.PACKAGING_STUDY,
      StudyTypes.ROUTINE_REPORTING_DATA
    ];
    return <Observable<any[]>>this.stringToKeyValueTranslations(studyTypeIds);
  }

  getSubstrates(): Observable<TypeSubstrate[]> {
    const substratesIds = [TypeSubstrates.Volunteers, TypeSubstrates.HairLocks, TypeSubstrates.MalleableHead, TypeSubstrates.Alias,
      TypeSubstrates.PonyTail, TypeSubstrates.Partials
    ];
    return <Observable<TypeSubstrate[]>>this.stringToKeyValueTranslations(substratesIds);
  }

  getTests(metierName: TypeMetier): Observable<TypeTest[]> {
    let testIds;
    switch (metierName) {
      case Metiers.Hair:
        testIds = [
          TypeTests.MonoApplication, TypeTests.MultiApplication, TypeTests.DevelopmentMethod,
          TypeTests.Knowledge, TypeTests.TrainingCourse, TypeTests.Remanence_Tenacity, TypeTests.ValidationPerfScreen
        ];
        break;
      case Metiers.Skin:
        testIds = [TypeTests.Absolute, TypeTests.SimultaneousComparison, TypeTests.VersusBareSkin];
        break;
      default:
        testIds = [];
    }

    return <Observable<TypeTest[]>>this.stringToKeyValueTranslations(testIds);
  }

  getIndexOperators(): string[] {
    return [IndexOperator.INDEX, IndexOperator.ONE_BASELINE, IndexOperator.DOUBLE_BASELINE];
  }

  getOperatorType(): string[] {
    return [OperatorType.INDEX_MOINS_SCALE_SCORE, OperatorType.INDEX_PLUS_SCALE_SCORE, OperatorType.SCALE_SCORE_MOINS_INDEX];
  }

  getTimelineTypes(): string[] {
    return [TimelineTypes.ALL_FICHE, TimelineTypes.FICHE_CARACTERISATION, TimelineTypes.FICHE_REMANENCE,
      TimelineTypes.FICHE_ENTRETIEN, TimelineTypes.STUDY, TimelineTypes.VISIT];
  }

  getTypeCampaign(): Type[] {
    return [this.CLINICAL, this.CONSUMERS, this.INSTRUMENTAL, this.SENSORY_EXPERT, this.SENSORY_CONFORT];
  }

  getTypeCampaignFilter(): Type[] {
    const user = this.userService.getUser();
    const tc = _.get(user, 'currentWorkspace.preferences.filter.campaignType', {});
    return tc.id && tc.name ? [tc] : [this.SENSORY_EXPERT];
  }

  getTypeEvaluation(): Type[] {
    return [this.ADHOC, this.PERFORMANCE, this.PILOT, this.STANDARD];
  }

  getOpticalTypes(): Type[] {
    return [this.FORMULATION_SUPPORT, this.COMMUNICATION, this.KNOWLEDGE, this.FOLDER_CLAIM];
  }

  keyValueTranslations(arrayOfkeyValueObject: KeyValue[]): Observable<KeyValue[]> {
    return this.dataTranslations(arrayOfkeyValueObject, 'key', 'value');
  }

  stringToKeyValueTranslations(idList: string[]): Observable<KeyValue[]> {
    if (idList && idList.length > 0) {
      const keyTranslationArray = [];
      return this.translateService.get(idList).pipe(
        map(resp => {
          _.forOwn(resp, ((value, key) => {
            keyTranslationArray.push({
              key: key,
              value: value
            });
          }));
          return keyTranslationArray;
        }));
    }
  }

  stringToObjectTranslations<T>(element: T, key: string, value: string): Observable<T> {
    if (element && element[key]) {
      return this.translateService.get(element[key]).pipe(
        map(traduction => {
          element[value] = traduction;
          return element;
        }));
    }
    return observableOf(element);
  }

  getVisitUnits(): VisitUnitTab[] {
    return [VisitUnitTab.none, VisitUnitTab.minute, VisitUnitTab.hour, VisitUnitTab.day, VisitUnitTab.week,
      VisitUnitTab.month, VisitUnitTab.shampooing];
  }

}
