export default {
    "type" : "linechart",
    "configs" : {
        "compute" : {
            "method" : "normalized-median",
            "key" : "label"
        },
        "drilldown": {
            "enabled": true
        },
        "title" : {
            "text" : "",
            "margin": 50
        },
        "subtitle" : {
            "text" : "",
        },
        "xAxis": {
            //"opposite": true
        },
        "yAxis" : {
            "max" : 0,
            "tickInterval" : 1,
            "plotLines": [
                {
                    "zIndex": 4,
                    "color": '#787777',
                    "dashStyle": 'shortdash',
                    "width": 1,
                    "value": 1,
                    "className": "grey_zone"
                },
                {
                    "zIndex": 4,
                    "color": '#787777',
                    "dashStyle": 'shortdash',
                    "width": 1,
                    "value": -1,
                }
            ],
            "plotBands": [
                {
                    "color": "rgba(239,236,236,0.5)",
                    "from": -1,
                    "to": 1,
                },
            ]
        },
        "plotOptions" : {
            "attributes_blocks": {
                "enabled": false
            },
            "default_language" : {
                "enabled" : false
            },
            "grey_zone": {
                "enabled": true
            }
        }
    },
}