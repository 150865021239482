<ngx-loading [show]="showSpinner">
</ngx-loading>
<h1 class="mb-4" id="banner-title" translate>PREVIEWING</h1>
<div *ngIf="block && !showSpinner">
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" translate>DASHBOARD</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/blocks" translate>LIBRARY_OF_BLOCKS</a>
      </li>
      <li class="breadcrumb-item" [ngClass]="{'active': block.id == undefined}">
          <a [routerLink]="['/blocks', block.id]">
            <dna-translate [fieldToTranslate]="block.name"> </dna-translate>
          </a>
        </li>
      <li class="breadcrumb-item active" aria-current="page" translate>PREVIEWING</li>
    </ol>
  </nav>
  <div class="ibox float-e-margins block">
    <div class="ibox-title">
      <h5 translate>COMPONENTS</h5>
      <div>
          <button class="btn btn-default" type="button" (click)="back()" translate>BACK</button>
      </div>
    </div>
    <div id="ibox-Components" class="ibox-content collapse show">
      <iframe width="100%" height="500px" [src]="url"></iframe>
    </div>
  </div>
</div>
