<ngx-loading [show]="showSpinner">
</ngx-loading>
<h1 class="mb-4" id="banner-title" translate>ADD_COMPONENT</h1>
<div *ngIf="block && !showSpinner">
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" translate>DASHBOARD</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/blocks" translate>LIBRARY_OF_BLOCKS</a>
      </li>
      <li class="breadcrumb-item" [ngClass]="{'active': block.id == undefined}">
        <a [routerLink]="['/blocks', block.id]">
          <dna-translate [fieldToTranslate]="block.name"> </dna-translate>
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>ADD_COMPONENT</li>
    </ol>
  </nav>
  <div class="ibox float-e-margins block">
    <div class="ibox-title">
      <h5 translate>COMPONENTS</h5>
      <div class="ibox-tools">
      </div>
    </div>
    <div id="ibox-Components" class="ibox-content collapse show">
      <div class=" row col-md-12">
        <div class="col-10">
          <dna-search-text [data]="searchText" (onSearch)="updateSearchText($event)">
          </dna-search-text>
        </div>
        <div class="col-2">
          <div class="right">
            <button class="btn btn-default" type="button" (click)="returnBack()" translate>BACK</button>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-borderless-th dataTable">
          <thead>
            <tr>
              <th
                [ngClass]="{'sorting' : orderedBy !== 'type', 'sorting_asc' : orderedBy === 'type' && !reverse, 'sorting_desc' : orderedBy === 'type' && reverse}"
                (click)="onSortByChanged('type')" translate> NAME </th>
              <th translate>DESCRIPTION</th>
              <th translate>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let component of componentsFiltered">
              <td>{{ component.type }}</td>
              <td translate>{{ 'tooltip_'+ component.type }}</td>
              <td>
                <button class="btn btn-primary btn-xs" (click)="selectComponent(component)" translate>ADD</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
