import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'dna-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.less']
})
export class ImageModalComponent implements OnInit {

  @Input() title: string;
  @Input() data: string;

  constructor(
    private activeModal: NgbActiveModal,
    private utilService: UtilService
    ) { }

  ngOnInit() {
    console.log("data;",this.data)
  }

  downloadImage() {
    const blob = this.utilService.dataUrlToBlob(this.data);
    const timestamp = new Date().getTime();
    FileSaver.saveAs(blob, this.title + "_" + timestamp + ".png");
  }

  cancel() {
    this.activeModal.dismiss();
  }

}