
import { Component, OnInit, Inject } from '@angular/core';
import { DNATranslateService } from '../../shared/services/translate.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'dna-header',
    templateUrl: './dnaHeader.component.html'
})

export class HeaderComponent implements OnInit {
    version: string;
    branchVersion : string;
    currentLanguage: string;
    languages: string[];
    changeLog: boolean = false;

    constructor(private dnaTranslateService: DNATranslateService ) {}

    ngOnInit() {
        this.version = 'v' + environment.version;
        this.branchVersion = '[' + environment.branchVersion + ']';
        this.currentLanguage = this.dnaTranslateService.getLanguage();
        this.dnaTranslateService.getLanguages().then(
          languages => { this.languages = languages; }
        );
    }

    changeLanguage(language: string) {
      this.currentLanguage = language;
      this.dnaTranslateService.setLanguage(language);
    };
}