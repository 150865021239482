<div class="container">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <ng-container *ngIf="!showSpinner && !error" class="ibox float-e-margins">
    <dna-table [data]="evaluations" [headers]="tableHeaders$ | async" orderedBy="ID" [reverse]="true"
      [sortFunction]="sort" title="EVALUATIONS" (buttonAction)="buttonAction($event, campaignRawData)">
      <ng-template #row let-data="data">
        <tr dna-evaluation-row class="cursor-pointer" [evaluation]="data" [hasVolunteer]="hasVolunteer" [linkAuthorized]="isAuthorized(data)" (onClickAction)="onClickActionButton($event, data.id)"></tr>
      </ng-template>
    </dna-table>
  </ng-container>
  <div *ngIf="!showSpinner && error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "ERROR" | translate }}</h5>
        </div>
      </div>
    </div>
    <dna-error-page (onClickReload)="init()"></dna-error-page>
  </div>
</div>
