import { OnePagerService } from './../../../../shared/services/one-pager.service';
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { OnePager, CampaignOnePager } from "../../../../types";
import { Observable } from "rxjs";
import { CampaignService } from "../../../campaigns.service";
import { ErrorManagerService } from '../../../../shared/services/errorManager.service';
import { UtilService } from '../../../../shared/services/util.service';
import { flatMap, filter, tap, finalize } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable()
export class OnePagerResolver implements Resolve<OnePager> {

    idCampaign: string;
    
    constructor(
        private onePagerService: OnePagerService,
        private campaignService: CampaignService,
        private errorManager: ErrorManagerService,
        private utilService: UtilService,
        private router: Router
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<OnePager> {
        return this.campaignService.getCampaignOnePager(route.parent.paramMap.get('idCampaign')).pipe(
            filter((campaign: CampaignOnePager) => this.utilService.canActivate(campaign, this.router, this.errorManager)),
            tap((campaign: CampaignOnePager) => this.onePagerService.setCampaign(campaign)),
            tap((campaign: CampaignOnePager) => this.campaignService.updateBreadCrumb(campaign.name)),
            flatMap((campaign: CampaignOnePager) => this.onePagerService.getAndBuildOnePager(campaign)),
            finalize(() => this.onePagerService.stopOnePagerSpinner()),
        );
    }
}