
import {forkJoin as observableForkJoin, of as observableOf,
  Observable,
  of
} from 'rxjs';

import {mergeMap, finalize, tap, catchError} from 'rxjs/operators';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  NgForm
} from "@angular/forms";

import * as _ from 'lodash';
import {
  DragulaService, DragulaOptions
} from "ng2-dragula";
import {
  NgbActiveModal
} from "@ng-bootstrap/ng-bootstrap";

import {
  Block,
  Campaign,
  CampaignStates,
  User,
  Workflow,
  Workspace
} from '../../../../../types';
import {
  BlockService
} from '../../../../../blocks/blocks.service';
import {
  CampaignService
} from '../../../../../campaigns/campaigns.service';
import {
  ErrorManagerService
} from '../../../../../shared/services/errorManager.service';
import {
  StateService
} from '../../../../../shared/services/state.service';
import {
  UserService
} from '../../../../../shared/services/user.service';
import {
  UtilService
} from '../../../../../shared/services/util.service';
import {
  WorkflowService
} from '../../../../../workflows/workflows.service';
import { WorkspaceService } from '../../workspaces.service';

@Component({
  selector: 'dna-workspace-modal-list',
  templateUrl: './workspaceModalList.component.html',
  styleUrls: ['./workspaceModalList.component.less']
})

export class WorkspaceModalListComponent implements OnInit {
  @Input() idWorkspace: string;
  @Input() type: string;

  showSpinner: boolean = true;
  idDragula: string;
  listObjectByWorkspace: any[] = [];
  listObjectRemoveInWorkspace: any[] = [];
  listObjectToDrag: any[] = [];
  listOriginalByWorkspace: any[] = [];
  state: StateService;
  user: User;
  userCurrentWorkspace: Workspace;
  editWorkspace: Workspace;
  error: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private blockService: BlockService,
    private campaignService: CampaignService,
    private dragulaService: DragulaService,
    private errorManagerService: ErrorManagerService,
    private stateService: StateService,
    private userService: UserService,
    private utilService: UtilService,
    private workflowService: WorkflowService,
    private workspaceService: WorkspaceService,
    private cdr: ChangeDetectorRef
  ) {
    this.idDragula = this.utilService.generateRandomID();
    const dOptions: DragulaOptions = {
      accepts: (el: Element, target: Element, source: Element, sibling: Element): boolean => {
        return target.id !== 'source';
      }
    };
    dragulaService.createGroup(this.idDragula, dOptions);
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.state = this.stateService;
    this.user = this.userService.getUser();
    if (this.user) {
      this.userCurrentWorkspace = _.cloneDeep(this.user.currentWorkspace);
    }
    this.getAllListObjects();
    this.workspaceService.getWorkspace(this.idWorkspace).pipe(
      tap(workspace => this.editWorkspace = workspace),
      catchError(err => {
        this.error = true;
        return this.catchError(err);
      }),
      finalize(() => {
        this.showSpinner = false;
        this.cdr.detectChanges();
      })
    ).subscribe();
  }

  addWorkspaceToBlock(block: Block, idWorkspace: string): boolean {
    if (!block.workspaces.find(w => w === idWorkspace)) {
      block.workspaces.push(idWorkspace);
      return true;
    }
    return false;
  }

  cancel() {
    this.activeModal.dismiss();
  }

  getAllListObjects() {
    switch (this.type) {
      // get list blocks to drag
      case this.stateService.BLOCK:
        this.user.currentWorkspace = undefined;
        this.userService.setUser(this.user);
        this.blockService.getBlocks()
          .subscribe(blocks => {
            this.listObjectToDrag = blocks;
            this.getBlocksInWorkspace();
          },
          error => this.errorManagerService.catchError(error)
          );
        break;

      // get list workflows to drag
      case this.stateService.WORKFLOW:
        this.user.currentWorkspace = undefined;
        this.userService.setUser(this.user);
        this.workflowService.getWorkflows()
          .subscribe(workflows => {
            this.listObjectToDrag = workflows;
            this.getWorkflowsInWorkspace();
          },
          error => this.errorManagerService.catchError(error)
          );
        break;

      // get list campaigns to drag
      case this.stateService.CAMPAIGN:
        this.user.currentWorkspace = undefined;
        this.userService.setUser(this.user);
        this.campaignService.getHttpCampaigns()
          .subscribe(campaigns => {
            this.listObjectToDrag = campaigns.filter(campaign => (campaign.state !== CampaignStates.Finished && campaign.state !== CampaignStates.Abandoned));
            this.getCampaignsInWorkspace();
          },
          error => this.errorManagerService.catchError(error)
          );
        break;
      default:
        break;
    }
  }

  getBlocksInWorkspace() {
    this.user.currentWorkspace = this.editWorkspace;
    this.userService.setUser(this.user);
    this.blockService.getBlocks().pipe(
      finalize(() => {
        this.showSpinner = false
      }))
      .subscribe(blocksInWorkspace => {
        this.listObjectByWorkspace = blocksInWorkspace;
        this.user.currentWorkspace = this.userCurrentWorkspace;
        this.userService.setUser(this.user);
        this.listOriginalByWorkspace = _.cloneDeep(blocksInWorkspace);
        // Remove blocks in the list to drag(also existing in the edit workspace)
        this.listObjectToDrag = _.differenceBy(this.listObjectToDrag, this.listObjectByWorkspace, 'id');
      },
      error => this.errorManagerService.catchError(error)
      )
  }

  getCampaignsInWorkspace() {
    this.user.currentWorkspace = this.editWorkspace;
    this.userService.setUser(this.user);
    this.campaignService.getHttpCampaigns().pipe(
      finalize(() => {
        this.showSpinner = false
      }))
      .subscribe(campaignsInWorkspace => {
        this.listObjectByWorkspace = campaignsInWorkspace.filter(campaign => (campaign.state !== CampaignStates.Finished && campaign.state !== CampaignStates.Abandoned));
        this.user.currentWorkspace = this.userCurrentWorkspace;
        this.userService.setUser(this.user);
        this.listOriginalByWorkspace = _.cloneDeep(this.listObjectByWorkspace);
        // Remove campaigns in the list to drag(also existing in the edit workspace)
        this.listObjectToDrag = _.differenceBy(this.listObjectToDrag, this.listObjectByWorkspace, 'id');
      },
      error => this.errorManagerService.catchError(error)
      )
  }

  getObjectsDropToUpdate() {
    let objectsToUpdateInbase: any[] = [];
    let listNewObjectDrop: any[] = [];
    listNewObjectDrop = _.differenceBy(this.listObjectByWorkspace, this.listOriginalByWorkspace, 'id');
    if (listNewObjectDrop.length > 0) {
      for (let object of listNewObjectDrop) {
        if (!object.workspaces) {
          object.workspaces = [];
        }
        if (!_.includes(object.workspaces, this.editWorkspace.id)) {
          object.workspaces.push(this.editWorkspace.id);
          objectsToUpdateInbase.push(object);
        }
      }
    }
    return objectsToUpdateInbase;
  }

  getObjectsRemoveToUpdate(): any[] {
    let objectsToUpdateInbase: any[] = [];
    if (this.listObjectRemoveInWorkspace.length > 0) {
      for (let object of this.listObjectRemoveInWorkspace) {
        let findObject = this.listObjectByWorkspace.find(objectInWorkspace => objectInWorkspace.id == object.id);
        if (_.includes(object.workspaces, this.editWorkspace.id) && !findObject) {
          object.workspaces = object.workspaces.filter(idWorkspace => idWorkspace !== this.editWorkspace.id);
          objectsToUpdateInbase.push(object);
        }
      }
    }
    return objectsToUpdateInbase;
  }

  getWorkflowsInWorkspace() {
    this.user.currentWorkspace = this.editWorkspace;
    this.userService.setUser(this.user);
    this.workflowService.getWorkflows().pipe(
      finalize(() => {
        this.showSpinner = false
      }))
      .subscribe(workflowsInWorkspace => {
        this.listObjectByWorkspace = workflowsInWorkspace;
        this.user.currentWorkspace = this.userCurrentWorkspace;
        this.userService.setUser(this.user);
        this.listOriginalByWorkspace = _.cloneDeep(workflowsInWorkspace);
        // Remove workflows in the list to drag(also existing in the edit workspace)
        this.listObjectToDrag = _.differenceBy(this.listObjectToDrag, this.listObjectByWorkspace, 'id');
      },
      error => this.errorManagerService.catchError(error)
      )
  }

  removeList(index: number) {
    let findObject = this.listObjectRemoveInWorkspace.find(object => object.id == this.listObjectByWorkspace[index].id);
    if (!findObject) {
      this.listObjectRemoveInWorkspace.push(this.listObjectByWorkspace[index]);
    }
  }

  save(form: NgForm) {
    let listObjectUpdateInBase: any[] = [];
    let listObjectRemoveToUpdate = this.getObjectsRemoveToUpdate();
    let listObjectDropToUpdate = this.getObjectsDropToUpdate();
    listObjectUpdateInBase = listObjectRemoveToUpdate.concat(listObjectDropToUpdate);
    let observable: Observable<any> = observableOf(true);;
    if (listObjectUpdateInBase.length > 0) {

      switch (this.type) {
        case this.stateService.BLOCK:
          observable = this.saveBlocks(listObjectUpdateInBase);
          break;
        case this.stateService.WORKFLOW:
          observable = this.saveWorkflows(listObjectUpdateInBase);
          break;
        case this.stateService.CAMPAIGN:
          observable = this.saveCampaigns(listObjectUpdateInBase);
          break;
        default:
          break;
      }
    }
    observable.subscribe(succes => {
      this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE');
      this.activeModal.close();
    }, err => this.errorManagerService.displayMessage('ON_ERROR_UPDATE', 'danger'))
  }

  saveBlocks(blocks: Block[]): Observable<any> {
    this.showSpinner = true;
    let observables = blocks.map(block => this.blockService.updateWorkspaceInBlock(block));
    return observableForkJoin(observables).pipe(
      finalize(() => {
        this.showSpinner = false;
      }))
  }

  saveCampaigns(campaigns: Campaign[]): Observable<any> {
    this.showSpinner = true;
    let observables = campaigns.map(campaign => this.campaignService.updateWorkspaceInCampaign(campaign));
    return observableForkJoin(observables).pipe(
      finalize(() => {
        this.showSpinner = false;
      }))
  }

  saveWorkflows(workflows: Workflow[]): Observable<any> {
    this.showSpinner = true;
    let observables = workflows.map(workflow => this.workflowService.updateWorkspaceInWorkflow(workflow))
      .map(obs => obs.pipe(mergeMap(wk => observableForkJoin(
        wk.blocks.filter(block => wk.workspaces.find(w => w === this.editWorkspace.id) && this.addWorkspaceToBlock(block, this.editWorkspace.id))
          .map(block => this.blockService.updateWorkspaceInBlock(block))))))

    return observableForkJoin(observables).pipe(
      finalize(() => {
        this.showSpinner = false;
      }))
  }

  catchError = (error) => {
    this.showSpinner = false;
    this.errorManagerService.catchError(error);
    this.errorManagerService.displayMessage("ON_ERROR_UPDATE", "danger");
    return of();
  }

}
