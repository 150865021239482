import { DNAComponent } from '../../types';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
    selector: 'dna-component-config',
    templateUrl: './componentConfiguration.component.html'
})

export class ComponentConfigurationComponent implements OnInit {
    @Input() inactive: boolean;
    @Input() submitted: boolean;
    @Input() component: DNAComponent;
    @Output() updateError: EventEmitter<boolean> = new EventEmitter();

    constructor() { }

    ngOnInit() { }

    sendError(hasError: boolean) {
        this.updateError.emit(hasError);
    }
}
