<div class="dna-scale-bar-tooltip--container" [style.border-color]="this.color">
  <table >
    <thead>
      <tr>
        <th [style.color]="this.color"> {{ this.data.label }} </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let detail of this.data.data" [style.font-style]="'italic'">
        <td [style.color]="this.color"> {{ detail.label }}</td>
        <td > {{ detail.value.label | applyTranslation:this.lang: Languages.Default }}</td>
      </tr>
    </tbody>
  </table>
</div>