<div class="modal-header">
  <button class="close" type="button" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
    <span class="sr-only">Close</span>
  </button>
  <i class="fa fa fa-calendar modal-icon"></i>
  <h4 class="modal-title">
    <span translate>CREATE </span>
    <span translate>CAMPAIGNS_MULTI</span>
  </h4>
  <small>{{ "CREATION_ITEM" | translate: { type: "CAMPAIGNS_MULTI" | translate | lowercase } }}</small>
</div>
<div class="modal-body">
  <form role="form" #campaignMultiForm="ngForm">
    <div class="ibox">
      <div class="ibox-title">
        <h5 translate>GENERAL</h5>
      </div>
      <div class="ibox-content">
        <div class="form-group">
          <label>{{ 'NAME' | translate }}
            <dna-mandatory [ngClass]="{'has-error': (submitted && name.invalid) || (name.dirty && name.invalid)}">
            </dna-mandatory>
          </label>
          <input class="form-control" #name="ngModel" [(ngModel)]="campaignMultiName" type="text" name="name" required>
          <div *ngIf="(submitted && name.invalid) || (name.dirty && name.invalid)" class="alert alert-danger" translate>REQUIRED</div>
        </div>
        <label>{{ 'ANALYSE_TYPE' | translate }}</label>
        <select class="form-control" [(ngModel)]="analyseTypeSelected" name="analyseTypeSelection">
          <option *ngFor="let analyseType of listAnalyseTypes" [ngValue]="analyseType">{{ analyseType | translate }}</option>
        </select>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="save(campaignMultiForm)" translate>VALIDATE</button>
  <button class="btn btn-default" type="button" (click)="cancel()" translate>CANCEL</button>
</div>
