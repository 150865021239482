import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: '[dna-message-banner]',
  templateUrl: './message-banner.component.html',
  styleUrls: ['./message-banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MessageBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
