<div class="row">
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>PROTOCOL_PRODUCTS_TYPES</h5>
    <tag-input inputClass="tagInputFiltersProtocol" displayBy="value" identifyBy="key" [(ngModel)]="filter.studies" [onlyFromAutocomplete]="true"
      (onAdd)="updateCampaignsList(filter)" (onRemove)="updateCampaignsList(filter)" name="tag-input-studies" placeholder="+ {{ 'PROTOCOL_PRODUCTS_TYPE' | translate }}"
      secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { object: 'PROTOCOL_PRODUCTS_TYPE' | translate | lowercase } }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="studies" displayBy="value" identifyBy="key" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>PROTOCOL_TEST_TYPES</h5>
    <tag-input inputClass="tagInputFiltersProtocol" displayBy="value" identifyBy="key" [(ngModel)]="filter.tests" [onlyFromAutocomplete]="true"
      (onAdd)="updateCampaignsList(filter)" (onRemove)="updateCampaignsList(filter)" name="tag-input-tests" placeholder="+ {{ 'PROTOCOL_TEST_TYPE' | translate }}"
      secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { object: 'PROTOCOL_TEST_TYPE' | translate | lowercase } }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="tests" displayBy="value" identifyBy="key" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>PROTOCOL_METHODS</h5>
    <tag-input inputClass="tagInputFiltersProtocol" displayBy="value" identifyBy="key" [(ngModel)]="filter.protocol_methods" [onlyFromAutocomplete]="true"
      (onAdd)="updateCampaignsList(filter)" (onRemove)="updateCampaignsList(filter)" name="tag-input-protocol_methods" placeholder="+ {{ 'PROTOCOL_METHOD' | translate }}"
      secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_FEM' | translate: { object: 'PROTOCOL_METHOD' | translate | lowercase } }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="protocol_methods" displayBy="value" identifyBy="key" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>METHOD</h5>
    <tag-input inputClass="tagInputFiltersProtocol" displayBy="value" identifyBy="key" [(ngModel)]="filter.methods" [onlyFromAutocomplete]="true"
      (onAdd)="updateCampaignsList(filter)" (onRemove)="updateCampaignsList(filter)" name="tag-input-methods" placeholder="+ {{ 'PROTOCOL_METHOD' | translate }}"
      secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_FEM' | translate: { object: 'PROTOCOL_METHOD' | translate | lowercase } }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="methods" displayBy="value" identifyBy="key" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>PROTOCOL_STUDY_TYPE</h5>
    <tag-input inputClass="tagInputFiltersProtocol" displayBy="value" identifyBy="key" [(ngModel)]="filter.studyTypes" [onlyFromAutocomplete]="true"
      (onAdd)="updateCampaignsList(filter)" (onRemove)="updateCampaignsList(filter)" name="tag-input-studyTypes" placeholder="+ {{ 'PROTOCOL_METHOD' | translate }}"
      secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_FEM' | translate: { object: 'PROTOCOL_METHOD' | translate | lowercase } }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="studyTypes" displayBy="value" identifyBy="key" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>PROTOCOL_APPLICATION_AREAS</h5>
    <tag-input inputClass="tagInputFiltersProtocol" displayBy="value" identifyBy="key" [(ngModel)]="filter.applicationAreas"
      [onlyFromAutocomplete]="true" (onAdd)="updateCampaignsList(filter)" (onRemove)="updateCampaignsList(filter)" name="tag-input-application-areas"
      placeholder="+ {{ 'PROTOCOL_APPLICATION_AREA' | translate }}" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_FEM' | translate: { object: 'PROTOCOL_APPLICATION_AREA' | translate | lowercase } }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="applicationAreas" displayBy="value" identifyBy="key" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>APPLICATION_MODES</h5>
    <tag-input inputClass="tagInputFiltersProtocol" displayBy="value" identifyBy="key" [(ngModel)]="filter.applicationModes"
      [onlyFromAutocomplete]="true" (onAdd)="updateCampaignsList(filter)" (onRemove)="updateCampaignsList(filter)" name="tag-input-applicationModes"
      placeholder="+ {{ 'APPLICATION_MODES' | translate }}" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_FEM' | translate: { object: 'APPLICATION_MODES' | translate | lowercase } }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="applicationModes" displayBy="value" identifyBy="key" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>NUMBER_TIMEPOINTS</h5>
    <input type="number" [(ngModel)]="filter.timepoints" (ngModelChange)="updateSearchData($event, 'timepoints', filter)" min="0"
      max="10" />
  </div>
  <div class="col-12 col-md-6 mb-2">
    <h5 translate>PROTOCOL_TIMEPOINT_INTERVAL</h5>
    <dna-search-text [data]="filter.timepointsInterval" (onSearch)="updateSearchData($event, 'timepointsInterval', filter)">
    </dna-search-text>
  </div>
</div>
