import { GradientColorObject, PatternObject } from "highcharts";
import { Chart } from "../../chart.model";

const doWork = (data: Array<any>, baseKey : string, lang: string, parameters: any, descriptors: Array<any>, payload: any, routinesObject: any): any => {
    let routines = routinesObject && routinesObject.hasOwnProperty('routines') && routinesObject.routines && routinesObject.routines.length ? routinesObject.routines.sort((a, b) => b.isBench - a.isBench) : [];
    return data.reduce((accumulator: any, item: any, index: number) => {
        if (item.hasOwnProperty('values') && Array.isArray(item.values)) {
            const keyLabel = [Chart.getObjectValueTranslation(item.attribute.blockName, lang), Chart.getObjectValueTranslation(item.attribute.label, lang)].join('_')
            accumulator.categories[keyLabel] = item.attribute;

            // CHEAT
            if (!routines.length) {
                item.values.reduce((acc: any, object: any, index: number) => {
                    acc[Chart.getObjectValueTranslation(object.attribute.label, lang)] = {
                        name: Chart.getObjectValueTranslation(object.attribute.label, lang),
                        isBench: index == 0,
                        color: Chart.colorScale[index]
                    }
                    return acc;
                }, {});
            }
            routines = Object.values(routines);
            //

            item.values.reduce((reducer: any, object: any, idx: number) => {
                const keySerie = Chart.getObjectValueTranslation(object.attribute.label, lang);
                let color: string | GradientColorObject | PatternObject;
                let isBench: boolean;
                let foundRoutine = routines.find((routine: any) => routine.name == keySerie);
                if (!foundRoutine || !foundRoutine.color) color = Chart.colorScale[idx];
                else color = foundRoutine.color;
                if (!foundRoutine || !foundRoutine.isBench) isBench = false;
                else isBench = foundRoutine.isBench;
                reducer[keySerie] = (reducer[keySerie] || { name: keySerie, data: [], color, isBench });
                (reducer[keySerie].data = (reducer[keySerie].data || [])).push({
                    y: median(Chart.castValuesToNumber(object.values, baseKey), baseKey),
                    x: index,
                    details: object.values
                });
                return reducer;
            }, accumulator.series)
        }
        return accumulator;
    }, payload);
};

const median = (array: Array<any>, key: string) => {
    array = array.filter(x => x.hasOwnProperty(key) && x[key] !== null && x[key] !== "");
    if (!array.length) return null;
    const mid = Math.floor(array.length / 2),
        nums = [...array].sort((a, b) => a[key] - b[key]);
    return array.length % 2 !== 0 ? nums[mid][key] : (nums[mid - 1][key] + nums[mid][key]) / 2;
};

export {
    doWork
}