<div class="ibox float-e-margins">
  <div class="ibox-title">
    <h5>
      <span translate>LINK_DATAVIZ</span>
    </h5>
  </div>
  <div class="ibox-content">
    <div class="form-group">
      <select id="datavizLinkSelection" class="form-control" [(ngModel)]="dataVizualisation.type" (ngModelChange)="onChange()"
        name="datavizLinkSelection">
        <option>{{ optionDataviz.optionBO }}</option>
        <option>{{ optionDataviz.optionPowerbi }}</option>
      </select>
    </div>
    <div *ngIf="dataVizualisation.type === optionDataviz.optionBO" class="form-group">
      <label translate>DATAVIZ_ENTER</label>
      <input class="form-control" type="text" [(ngModel)]="dataVizualisation.link" (ngModelChange)="onChange()" name="datavizLinkBO">
    </div>
    <div *ngIf="dataVizualisation.type === optionDataviz.optionPowerbi && displayPowerbiSearch" class="form-group">
      <label translate>DATAVIZ_SEARCH_REPORT</label>
      <tag-input [(ngModel)]="dataVizualisation.object" (ngModelChange)="onChange()" [identifyBy]="'id'" [displayBy]="'name'" [maxItems]="1"
        name="dataviz" [onlyFromAutocomplete]="true">
        <tag-input-dropdown [appendToBody]="false" [autocompleteObservable]="requestAutocompleteItems" [identifyBy]="'id'" [displayBy]="'name'"
          [minimumTextLength]="3" [showDropdownIfEmpty]="false">
        </tag-input-dropdown>
      </tag-input>
    </div>
    <div *ngIf="dataVizualisation.type === optionDataviz.optionPowerbi && !displayPowerbiSearch" class="form-group">
      <label class="error" translate>ERROR_NO_POWERBI_ID</label>
    </div>
  </div>
</div>