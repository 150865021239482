<div class="ibox float-e-margins">
  <div class="ibox-title">
    <h5 translate> {{ title }} </h5>
    <div     class="mb-3">
      <button *ngFor="let button of buttons" class="btn space-buttons" [(ngClass)]="button.style" type="button" [disabled]="!button.isActive"
        (click)="onButtonAction(button.id)">{{ button.name }}</button>
    </div>
  </div>
  <div class="ibox-content" *ngIf="pagedData">
    <div class="row" *ngIf="pagination">
      <div class="col-sm-5 m-b-xs">
        <label for="power" class="mb-2" translate [translateParams]="{ objectType: title | translate | lowercase }">NUMBER_OF_ENTRIES_DISPLAYED_PER_PAGE</label>
        <select id="power" class="input-sm form-control input-s-sm inline" [(ngModel)]="pager.pageSize" (ngModelChange)="setPage(1)"
          required>
          <option [ngValue]="10">10</option>
          <option [ngValue]="20">20</option>
          <option [ngValue]="50">50</option>
          <option [ngValue]="100">100</option>
        </select>
      </div>
      <div class="col-sm-7 m-b-xs">
        <label class="mb-2" translate [translateParams]="{ from: pager.startIndex, to: pager.endIndex, total: pager.totalItems }">SHOWING_TO_OF_LINES</label>
        <ul class="pagination">
          <li [ngClass]="{ disabled: pager.currentPage === 1 }">
            <a (click)="setPage(1)">
              <i class="fa fa-angle-double-left"></i>
            </a>
          </li>
          <li [ngClass]="{ disabled: pager.currentPage === 1 }">
            <a (click)="setPage(pager.currentPage - 1)">
              <i class="fa fa-angle-left"></i>
            </a>
          </li>
          <li *ngFor="let numberPage of pager.pages" [ngClass]="{ active:pager.currentPage === numberPage }">
            <a (click)="setPage(numberPage)">{{ numberPage }}</a>
          </li>
          <li [ngClass]="{ disabled: pager.currentPage === pager.totalPage }">
            <a (click)="setPage(pager.currentPage + 1)">
              <i class="fa fa-angle-right"></i>
            </a>
          </li>
          <li [ngClass]="{ disabled: pager.currentPage === pager.totalPage }">
            <a (click)="setPage(pager.totalPage)">
              <i class="fa fa-angle-double-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover table-borderless-th dataTable">
        <thead>
          <tr>
            <th *ngFor="let header of headers" [ngClass]="{ 'sorting' : header.sortable && orderedBy !== header.id, 'sorting_asc' : header.sortable && orderedBy === header.id && !reverse, 'sorting_desc' : header.sortable && orderedBy === header.id && reverse }"
              (click)="onSortByChanged(header)">
              <i *ngIf="header.icon" [ngClass]="header.icon"></i>
              {{ header.name }} </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let dataRow of pagedData">
            <ng-template [ngTemplateOutlet]="templateVariable" [ngTemplateOutletContext]="{ data: dataRow }"></ng-template>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
