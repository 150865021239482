  <td>
    <dna-switch title="{{'SELECT_EXPORT' | translate}}"
                [isSelected]="allSelected || campaign?.isSelected"
                (onBooleanChanged)="onSwitchChanged($event)">
    </dna-switch>
  </td>
  <td id="campaignRow-{{ campaign.id }}">
    <span class="cursor-pointer">{{ campaign.name }}</span>
  </td>
  <td id="campaignRow-{{ campaign.id }}">
    <span class="cursor-pointer" translate>{{ campaign.state }}</span>
  </td>

  <td id="campaignRow-{{ campaign.id }}">
    <span>{{ campaign.synergy?.projectNumber }}</span>
  </td>
  <td id="campaignRow-{{ campaign.id }}">
    <span>{{ campaign.activityNumber }}</span>
  </td>
  <td id="campaignRow-{{ campaign.id }}">
    <span class="cursor-pointer">{{ campaign.synergy?.requestNumber }}</span>
  </td>
  <td id="campaignRow-{{ campaign.id }}">
    <span class="cursor-pointer">{{ campaign.updated_on | date:'yyyy-MM-dd' }}</span>
  </td>
