<div class="list-group">
  <ng-container *ngFor="let menu of menus">
    <a *ngIf="isOnOnePagerImage" placement="top" [ngbTooltip]="menu.label" class="default-tab" [routerLink]="menu.link" routerLinkActive="active-tab"  (click)="onClick(menu)"> 
      <i class="{{ menu.icon }}" aria-hidden="true"></i>
    </a>
    <a *ngIf="!isOnOnePagerImage" [ngClass]="{'active-tab': isAnalyseGraphEdit && menu.icon === 'fa fa-line-chart'}" class="default-tab" [routerLink]="menu.link" routerLinkActive="active-tab" (click)="onClick(menu)">
      {{ menu.label }}
      <i class="{{ menu.icon }} fa-pull-right" aria-hidden="true"></i> 
    </a>
  </ng-container>
</div>
