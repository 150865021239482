<div #powerbiContainer class="ibox float-e-margins">
    <div class="ibox-content item-center">
        <h5 class="mb-4">{{ "IMAGES" | translate | uppercase }}</h5>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
                <ng-container *ngFor="let image of imagesFromOnePager">
                  <div class="col-12 col-md-4 mb-4">
                    <img class="img-fluid rounded" [src]="image.data" alt="">
                    <br>
                    <i class="fa fa-times fa-lg" (click)="removeImage(image)" aria-hidden="true"></i>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="imagesFromOnePager.length === 0" class="mb-4" translate>
                NO_IMAGES
              </div>
          </div>
          <div class="col-md-6">
            <label>
              <input class="btn btn-primary" type="file" name="file" accept="image/*" (change)="uploadFiles($event)"
                multiple>
              <span class="btn btn-primary" translate>UPLOAD_IMAGES</span>
            </label>
          </div>
          <div class="col-md-6">
            <input (paste)="pasteImg($event)" class="clipboard-zone" placeholder="{{ 'PASTE_CLIPBOARD' | translate }}">
          </div>
        </div>
      </div>
    <div class="ibox-title">
      <div class="row">
        <div class="col-4 my-auto">
          <h5 *ngIf="type === typeDataviz.typeReport" translate>REPORT</h5>
          <h5 *ngIf="type === typeDataviz.typeTile" translate>TILES_INFO</h5>
        </div>
        <div class="col-8">
          <div class="float-end">
            <button id="powerbiCaptureStart" class="btn btn-default no-margin-bottom" type="button" onclick="startCapture()" translate>
              START_CAPTURE
            </button>
            <button id="powerbiCaptureSelect" class="btn btn-default no-margin-bottom" type="button" (click)="powerbiSelect()" translate>
              START_SELECTION
            </button>
            <button id="cancel" class="btn btn-default no-margin-bottom" type="button" (click)="location.back()" translate>
                BACK_STUDY
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div class="table-responsive">
        <div *ngIf="type === typeDataviz.typeReport">
          <dna-display-powerbi-data [embedUrl]="embedUrl" [id]="idReport" [idCampaigns]="workflow.idCampaign" [isMulti]="isMulti" [idWorkflow]="workflow.id"
            [type]="type">
          </dna-display-powerbi-data>
        </div>
        <div *ngIf="type === typeDataviz.typeTile" class="text-center">
          <button id="btn-go-top" (click)="scrollToTop()">
            <i class="fa fa-arrow-circle-up fa-5x" aria-hidden="true"></i>
          </button>
          <div class="py-4 row" *ngFor="let tile of tiles">
            <dna-display-powerbi-data class="col-12" [embedUrl]="tile.embedUrl" [id]="tile.id" [idDashboard]="idDashboard" [type]="type"></dna-display-powerbi-data>
          </div>
        </div>
      </div>
    </div>
  </div>
  <canvas #canvas hidden></canvas>
  <video #capture id="powerbiCapturePanel" style="display: none; filter: contrast(1.1);" autoplay></video>
  <button id="btn-go-top" (click)="scrollToTop()">
    <i class="fa fa-arrow-circle-up fa-5x" aria-hidden="true"></i>
  </button>
  <div class="selector" #powerbiSelector style="display: none"></div>
