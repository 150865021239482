import { ErrorManagerService } from '../../services/errorManager.service';
import { Feedback } from '../../../types';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from "@angular/forms";
import { HttpRestService } from "../../services/httpRest.service";

@Component({
    selector: 'dna-feedback-modal',
    templateUrl: './feedbackModal.component.html',
    styleUrls: ['../modals.component.less']
})

export class FeedbackModalComponent implements OnInit {
    showSpinner: boolean;
    model: Feedback = new Feedback();

    constructor(
        private activeModal: NgbActiveModal,
        private httpRestService: HttpRestService,
        private errorManager: ErrorManagerService
    ) { }

    ngOnInit() { }

    ok(form: NgForm, model: Feedback) {
        if (!form.invalid) {
            this.showSpinner = true;
            this.httpRestService.createFeedBack(model).subscribe(
                (success) => {
                    this.activeModal.close();
                },
                (err) => {
                    this.errorManager.displayMessage('ERROR', 'danger');
                },
                () => {
                    this.showSpinner = false;
                });
        }
        else {
            this.errorManager.displayMessage('EMPTY_NAME', 'danger');
        }
    }

    cancel() {
        this.activeModal.dismiss();
    };
}

