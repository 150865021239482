import { Component, OnInit, Input } from '@angular/core';

import * as _ from 'lodash';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  Campaign,
  Metiers,
  Routine2,
  TypeMetier
} from '../../types';

@Component({
  selector: 'dna-routine-preview-modal',
  templateUrl: './routine-preview-modal.component.html',
  styleUrls: ['./routine-preview-modal.component.less']
})
export class RoutinePreviewModalComponent implements OnInit {

  @Input() campaign: Campaign;
  @Input() routine: Routine2[];

  metierName: TypeMetier;
  metiers: typeof Metiers = Metiers;
  routines: Routine2[] = [];

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.metierName = _.get(this.campaign, 'metier.name', undefined);
    this.routines = this.routine ? this.routine : _.cloneDeep(this.campaign.formula.routines);
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  isNewFormat(pauseTime: any) {
    return _.has(pauseTime, 'hour');
  }

}
