<dna-layout>
  <ngx-loading [show]="showSpinner">
    <span translate>LOADING</span>
  </ngx-loading>
  <ng-container>
    <h1 class="mb-4" id="banner-title">
      <span>{{ "CAMPAIGN" | translate }}</span>
    </h1>
    <nav aria-label="breadcrumb" id="banner-nav">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/dashboard" translate>DASHBOARD</a>
        </li>
        <li class="breadcrumb-item">
          <a routerLink="/campaigns">
            {{ "CAMPAIGNS" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item active">{{ campaign.name }}</li>
        <li class="breadcrumb-item active" aria-current="page" translate>EDIT</li>
      </ol>
    </nav>
  </ng-container>
  <ng-container *ngIf="sideMenu">
    <div class="block">
      <div class="row">
        <div class="col-12 margin-menu" [ngClass]="calculateMenuClasses()">
          <dna-side-bar-menu [menus]="sideMenu" (onePagerClicked)="onOnePagerClick($event)"> </dna-side-bar-menu>
        </div>
        <div class="col-12" [ngClass]="calculateClasses()" *ngIf="!showSpinner">
          <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>
      </div>
    </div>
  </ng-container>
</dna-layout>
