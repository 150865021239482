import {CampaignService} from '../../campaigns/campaigns.service';

import {FilterService} from '../services/filter.service';
import {ErrorManagerService} from '../services/errorManager.service';
import {UtilService} from '../services/util.service';
import {DNATranslateService} from '../services/translate.service';

import { LightCampaign, FilterSchedule, StudyGroupVolunteers} from '../../types';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {tap, take, map, finalize} from 'rxjs/operators';


import * as _ from 'lodash';


interface groupPanelistHorsArcs {
  studies:StudyGroupVolunteers[]
}


@Component({
  selector: 'dna-associateStudies',
  templateUrl: './associateStudies.component.html',
  styleUrls: ['./associateStudies.component.less']
})
export class AssociateStudiesComponent implements OnInit {
  showSpinner: boolean;
  showSpinnerCampaigns: boolean;
  error: boolean;
  campaigns: StudyGroupVolunteers[] = [];
  filter: FilterSchedule;
  @Input() groupPanelistHorsArcs:groupPanelistHorsArcs;
  @Output() updateGroup = new EventEmitter();
  
  constructor(
    private campaignService: CampaignService,
    private filterService: FilterService,
    private errorManager: ErrorManagerService,
  ) {
  }

  ngOnInit() {
    this.filter = _.cloneDeep(this.filterService.getFilterMulti());
    if (_.isUndefined(this.groupPanelistHorsArcs)) {
      this.groupPanelistHorsArcs = {
        studies:[]
      };
    }
  }
  searchCampaign(): void {
    this.campaigns = [];
    this.showSpinnerCampaigns = true;
    this.campaignService.getHttpCampaignsWithFilter(this.filter).pipe(
      map(result => result.list.filter(c => c.isTemplate !== true)),
      map(campaigns => this.fromLightCampaignToStudy(campaigns)),
      tap(campaigns => this.campaigns = campaigns),
      finalize(() => this.showSpinnerCampaigns = false),
      take(1)
    ).subscribe();
  }

  fromLightCampaignToStudy(campaigns: LightCampaign[]): StudyGroupVolunteers[] {
    return campaigns.map(c => {
      return {
        studyId: c.id,
        studyName: c.name,
      };
    });
  };

  addStudyToGroupPanelist(study: StudyGroupVolunteers){
    const studyId = study.studyId;
    this.groupPanelistHorsArcs.studies.push(study);
    this.campaigns = this.campaigns.filter(study=>study.studyId !== studyId);
    this.updateGroup.emit(this.groupPanelistHorsArcs.studies);
  };

  removeStudy(study: StudyGroupVolunteers): void {
    if (study.hasStartedEvals)
    {
      this.errorManager.displayMessage('CANT_UNLINK_GROUP_HORSARCS_EVALUATIONS_STARTED', "danger");
    }
    else
    {
      const studyId = study.studyId
      const studies = this.groupPanelistHorsArcs.studies
      const studiesFiltered = studies.filter(study=>study.studyId !== studyId)
      this.groupPanelistHorsArcs.studies = studiesFiltered
      this.campaigns.push(study)
      this.updateGroup.emit(studiesFiltered)
    }
  };

  resetSearch(): void {
    this.campaigns = [];
    this.filter.name = '';
  }
}
