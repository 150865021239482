import { Languages } from "../../enums";

const getObjectValueTranslation = (object: any, lang: string): string => {
  switch (typeof object) {
    case "object":
      if (object.hasOwnProperty("custom")) return object;
      else if (object.hasOwnProperty("user") && object.user.length)
        return object.user;
      else if (object.hasOwnProperty(lang) && object[lang].length)
        return object[lang];
      else return object[Languages.Default];
    default:
      return object;
  }
};

const getMedianFromArray = (array: Array<number>): number => {
  const half = Math.floor(array.length / 2);
  if (array.length % 2) return array[half];
  else return (array[half - 1] + array[half]) / 2.0;
};

const getMedianFromUnsortedArray = (array: Array<number>): number => {
  const sortedArray = array.sort((a, b) => a - b);
  return getMedianFromArray(sortedArray);
};

const formatter = (element: any, lang: string, showBlock: boolean): string => {
  const displayLanguages: Array<string> = [Languages.Default];
  if (lang && displayLanguages.indexOf(lang) === -1)
    displayLanguages.push(lang);

  const attribute = element.x;
  const footer = "</table>";
  let header: string;
  let body: string;
  switch (typeof attribute) {
    case "object":
      if (showBlock && attribute.hasOwnProperty("blockName")) {
        if (attribute.hasOwnProperty("drilled"))
          header = `<span style="font-size:12px; font-weight: bold">${getObjectValueTranslation(
            attribute.blockName,
            lang
          )}<br /> ${getObjectValueTranslation(
            attribute.label,
            lang
          )} - ${getObjectValueTranslation(
            attribute.drilled.label,
            lang
          )} </span><table>`;
        else
          header = `<span style="font-size:12px; font-weight: bold">${getObjectValueTranslation(
            attribute.blockName,
            lang
          )}<br /> ${getObjectValueTranslation(
            attribute.label,
            lang
          )}</span><table>`;
      } else {
        if (attribute.hasOwnProperty("drilled"))
          header = `<span style="font-size:12px; font-weight: bold">${getObjectValueTranslation(
            attribute.label,
            lang
          )} - ${getObjectValueTranslation(
            attribute.drilled.label,
            lang
          )} </span><table>`;
        else
          header = `<span style="font-size:12px; font-weight: bold">${getObjectValueTranslation(
            attribute.label,
            lang
          )}</span><table>`;
      }
      break;
    default:
      header = `<span style="font-size:12px; font-weight: bold">${attribute}</span><table>`;
  }
  if (element.hasOwnProperty("points")) {
    body = element.points
      .map(({ point, series }) => {
        let pointMedian: number | "NA" = "NA";

        const details: string[] = [];
        const valuesArray: Array<number> = [];
        if (point.details) {
          point.details.serie.forEach((item) => {
            const label: string | number = getObjectValueTranslation(
              item.label,
              lang
            );
            const value: string | number = getObjectValueTranslation(
              item.value.label,
              lang
            );
            if (
              label !== undefined &&
              label !== null &&
              value !== undefined &&
              value !== null
            ) {
              valuesArray.push(parseFloat(value));
              details.push(
                `<tr style="font-size: 11px">
                    <td></td>
                    <td style="color:${series.color};padding:0;font-style:italic;">
                        ${label}
                    </td>
                    <td></td>
                    <td style="font-style:italic;" >
                    ${value}
                    </td>
                </tr>`
              );
            }
          });
        }

        if (valuesArray.length) {
          pointMedian = getMedianFromUnsortedArray(valuesArray);
        }

        const mainLine = `<tr style="font-size: 12px"><td style="color:${series.color};padding:0; font-weight: bold;" colspan="2">${series.name}: </td>
            <td style="padding:0"><b>${pointMedian}</b></td></tr>`;

        const innerBodyArray: string[] = [];

        innerBodyArray.push(mainLine);

        details.forEach((d) => {
          if (d) innerBodyArray.push(d);
        });

        const innerBody = innerBodyArray.join("");

        return innerBody;
      })
      .join("");
  } else {
    let pointMedian: number | "NA" = "NA";
    const valuesArray: Array<number> = [];

    try {
      const tempBody: string[] = [];
      element.point.details.serie.forEach((detail) => {
        const value: string | number = getObjectValueTranslation(
          detail.value.label,
          lang
        );
        if (value !== undefined && value !== null) {
          valuesArray.push(parseFloat(value));
          tempBody.push(
            `<tr style="font-size: 11px" colspan="2">
                <td></td>
                <td style="padding:0;font-style:italic;">${detail.label}</td>
                <td></td>
                <td style="font-style:italic"> ${value} </td>
            </tr>`
          );
        }
      });

      body = tempBody.join("");
    } catch (e) {
      console.log(e);
      body = "";
    }

    if (valuesArray.length) {
      pointMedian = getMedianFromUnsortedArray(valuesArray);
    }

    const mainLine = `
        <tr style="font-size: 12px">
            <td style="color:${element.color};padding:0; font-weight: bold;" colspan="2">${element.series.name}: </td>
            <td style="padding:0"><b>${pointMedian}</b></td>
        </tr>`;

    body = [mainLine, body].join("");
  }

  return [header, body, footer].join("");
};

export { formatter };
