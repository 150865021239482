<ngx-loading [show]="showSpinner">
</ngx-loading>
<h1 class="mb-4" id="banner-title">
  <span translate>BLOCK</span> -
  <dna-translate [fieldToTranslate]="block.name"> </dna-translate>
</h1>
<nav aria-label="breadcrumb" id="banner-nav">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/dashboard" translate>DASHBOARD</a>
    </li>
    <li class="breadcrumb-item">
      <a routerLink="/blocks" translate>LIBRARY_OF_BLOCKS</a>
    </li>
    <li class="breadcrumb-item active">
      <dna-translate [fieldToTranslate]="block.name"> </dna-translate>
    </li>
    <li class="breadcrumb-item active" aria-current="page" translate>EDIT</li>
  </ol>
</nav>
<div class="actions d-flex flex-row gap-1 justify-content-end" id="banner-actions">
  <button *ngIf="block.state === blockStates.Published && isAuthorized('DNA_DEV')" class="btn btn-primary mb-4" (click)="forceRemoveUnusedComponents()">
    <span translate>DB Force remove unused components</span>
  </button>
  <button *ngIf="!hasBlockChanged()" class="btn btn-secondary mb-4" id="CREATE" (click)="createNewBlock()">
    <span translate>CREATE_BLOCK</span>
  </button>
  <button *ngIf="hasBlockChanged()" class="btn btn-primary mb-4" id="SAVE"
    (click)="saveBlock()">
    <span translate>SAVE</span>
  </button>
  <button *ngIf="hasBlockChanged()" class="btn btn-primary mb-4"
    (click)="cancelEditBlock()">
    <span translate>CANCEL</span>
  </button>
  <button *ngIf="!hasBlockChanged() && block.state !== blockStates.Published" class="btn btn-primary mb-4" id="PUBLISH"
    (click)="publishBlock()">
    <span translate>PUBLISH</span>
  </button>
  <button *ngIf="block.state === blockStates.Published" class="btn btn-primary mb-4" (click)="suspendBlock()"
    id="SUSPEND">
    <span translate>SUSPEND</span>
  </button>
</div>
<div class="block">
  <div *ngIf="block.state === blockStates.Published" style="text-align:center; color:red;" translate> BLOCK_IS_PUBLISHED
  </div>
  <div class="ibox float-e-margins">
    <div class="ibox-title row noMarginSide">
      <h5 class="col-md-6">
        <span translate>BLOCK</span>
        <span>
          <dna-translate [fieldToTranslate]="block.name"> </dna-translate>
        </span>
        <span *ngIf="!block.name[currentLanguage] && block.name.english">
          <i>(english version)</i>
        </span>
      </h5>
      <div class="ibox-tools col-md-6">
        <a aria-controls="collapseBlockName" (click)="isCollapsedBlockName = !isCollapsedBlockName">
          <i *ngIf="isCollapsedBlockName; else isNotCollapsedBlockName" class="fa fa-pencil"></i>
          <ng-template #isNotCollapsedBlockName><i class="fa fa-times"></i></ng-template>
        </a>
      </div>
    </div>
    <div class="ibox-content" id="collapseBlockName" [ngbCollapse]="isCollapsedBlockName">
      <dna-edit-name-and-hub *ngIf="languages.length > 0" [data]="block" [languages]="languages" (onChanges)="onChange($event)"></dna-edit-name-and-hub>
    </div>
  </div>

  <div class="ibox float-e-margins">
    <div class="ibox-title">
      <h5 translate>BLOCK</h5>
      <div class="ibox-tools d-flex flex-row gap-1 justify-content-end">
        <button class="btn btn-xs btn-primary" (click)="preview()" translate>PREVIEWING</button>
        <ng-container *ngIf="block.state !== blockStates.Published">
          <button class="btn btn-xs btn-primary" (click)="selectComponent()" translate>ADD_COMPONENT</button>
        </ng-container>
      </div>
    </div>
    <div class="ibox-content" id="ibox-Block">
      <div class="table-responsive">
        <table class="table table-hover table-borderless-th">
          <thead>
            <tr>
              <th></th>
              <th>#</th>
              <th translate>LABEL</th>
              <th translate>TYPE</th>
              <th translate>DESCRIPTION</th>
              <th translate>ACTIONS</th>
            </tr>
          </thead>
          <tbody id="target" [dragula]='idDragula' [dragulaModel]='block.components' (dragulaModelChange)="changeIndexComponents($event)">
            <tr class="cursor-pointer" *ngFor="let component of block.components; let key = index">
              <td>
                <i class="fa fa-sort"></i>
              </td>
              <td>{{key + 1}}</td>
              <td (click)="openModalEditComponentName(component)">
                <span
                  ngbTooltip="{{ 'EDIT_COMPONENT_NAME' | translate }}">{{component.args.label[currentLanguage] || component.args.label.english || '-'}}</span>
                <span *ngIf="!component.args.label[currentLanguage] && component.args.label.english">
                  <i>(english version)</i>
                </span>
              </td>
              <td>
                <span class="label">{{component.type}}</span>
              </td>
              <td>{{ 'tooltip_'+ component.type | translate }}</td>
              <td>
                  <div class="d-flex flex-row gap-1 align-items-center">
                    <button class="btn btn-xs btn-primary" (click)="editComponentConfiguration(key, component);">
                      <i class="fa" [ngClass]="block.state !== blockStates.Published ? 'fa-cog' : 'fa-eye'"
                        aria-hidden="true"></i>
                    </button>
                    <button *ngIf="block.state !== blockStates.Published" class="btn btn-xs btn-primary"
                      (click)="duplicateComponent(component);">
                      <i class="fa fa-files-o" aria-hidden="true"></i>
                    </button>
                    <button *ngIf="block.state !== blockStates.Published" class="btn btn-xs btn-danger"
                      (click)="removeFromList(component.index)">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-xs btn-default" (click)="addOrRemoveMandatory(component.index)"
                      [ngbTooltip]="(component.mandatory) ? ('MANDATORY' | translate) : ('NON_MANDATORY' | translate)">
                      <i class="fa fa-asterisk" aria-hidden="true"
                        [ngClass]="{'asterisk-mandatory' : component.mandatory}"></i>
                    </button>
                  </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
