<form #dnaInputTextForm="ngForm" class='form-horizontal d-flex flex-column gap-3'>
  <div class="form-group">
    <div class="col-sm-2">
      <label class="control-label" translate>CONFORT_CHOOSE_COMPONENT</label>
    </div>
    <div class="col-sm-10">
      <select class="form-control" [(ngModel)]="component.args.confortComponent" (ngModelChange)="changeConfortComponent($event)" name="typeConfort">
        <option *ngFor="let comp of confortComponent" [ngValue]="comp.id">
          {{ comp.name | translate }}
        </option>
      </select>
    </div>
  </div>
  <div class="form-group">
    <div class="col-sm-2">
      <label class="control-label" translate>CONFORT_CHOOSE_TIME</label>
    </div>
    <div class="col-sm-10">
      <select class="form-control" [(ngModel)]="component.args.confortCode" (ngModelChange)="changeLabel($event)"
        name="code">
        <option *ngIf="component.args.confortCode?.length === 0" [ngValue]=""></option>
        <option *ngFor="let confortCode of confortCodes" [ngValue]="confortCode.id">
          {{confortCode.name | translate}}
        </option>
      </select>
    </div>
  </div>
</form>
