<div class="modal-scroll">
  <div class="modal-header">
    <i class="fa {{faIcon}} modal-icon"></i>
    <h4 class="modal-title">{{ title | translate }}</h4>
    <small>{{ "CREATION_ITEM" | translate: { type: type | uppercase | translate | lowercase } }}</small>
  </div>
  <div class="modal-body">
    <form role="form" #blockForm="ngForm">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>
            <span translate>NAME</span> &amp;
            <span translate>DESCRIPTION</span>
          </h5>
        </div>
        <div class="ibox-content table-responsive">
          <table class="table table-borderless-th">
            <thead>
              <tr>
                <th translate>LANGUAGE</th>
                <th translate>NAME</th>
                <th translate>DESCRIPTION</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label class="required" translate>english</label>
                </td>
                <td>
                  <input type="text" class="form-control" [ngClass]="{'is-invalid':(submitted || name.touched) && !name.valid}" [(ngModel)]="data.name.english"
                    #name="ngModel" name="nameEnglish" required>
                  <div class="invalid-feedback">
                    <small>{{ "ENTER_NAME" | translate }}</small>
                  </div>
                </td>
                <td>
                  <textarea class="form-control" [ngClass]="{'is-invalid':(submitted || description.touched) && !description.valid}" [(ngModel)]="data.description.english"
                    #description="ngModel" name="descriptionEnglish" required></textarea>
                  <div class="invalid-feedback">
                    <small>{{ "ENTER_DESCRIPTION" | translate }}</small>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label translate>french</label>
                </td>
                <td>
                  <input type="text" class="form-control" [(ngModel)]="data.name.french" name="name">
                </td>
                <td>
                  <textarea class="form-control" name="description" [(ngModel)]="data.description.french"></textarea>
                </td>
              </tr>
              <tr>
                <td>
                  <label translate>chinese</label>
                </td>
                <td>
                  <input type="text" class="form-control" [(ngModel)]="data.name.chinese" name="name">
                </td>
                <td>
                  <textarea class="form-control" name="description" [(ngModel)]="data.description.chinese"></textarea>
                </td>
              </tr>
              <tr>
                <td>
                  <label translate>japanese</label>
                </td>
                <td>
                  <input type="text" class="form-control" [(ngModel)]="data.name.japanese" name="name">
                </td>
                <td>
                  <textarea class="form-control" name="description" [(ngModel)]="data.description.japanese"></textarea>
                </td>
              </tr>
              <tr>
                <td>
                  <label translate>portuguese</label>
                </td>
                <td>
                  <input type="text" class="form-control" [(ngModel)]="data.name.portuguese" name="name">
                </td>
                <td>
                  <textarea class="form-control" name="description" [(ngModel)]="data.description.portuguese"></textarea>
                </td>
              </tr>
              <tr>
                <td>
                  <label translate>spanish</label>
                </td>
                <td>
                  <input type="text" class="form-control" [(ngModel)]="data.name.spanish" name="name">
                </td>
                <td>
                  <textarea class="form-control" name="description" [(ngModel)]="data.description.spanish"></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
    <!-- <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5 translate>WORKSPACE</h5>
      </div>
      <div class="ibox-content">
        <dna-workspace-selection (onWorkspace)="changeCurrentWorkspace($event)"></dna-workspace-selection>
      </div>
    </div> -->
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="save(blockForm)" translate>VALIDATE</button>
  <button type="button" class="btn btn-default" (click)="cancel()" translate>CANCEL</button>
</div>
<input id="test1" type="hidden">
