import { Chart } from '../chart.model';
import { BarChartOptions } from './barchart-options.model';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as BarChartLabelFormatter from './barchart.label.formatter'
import * as BarChartTooltipFormatter from './barchart.tooltip.formatter';
import { Languages, COMPUTING_METHODS } from "../../enums";

import * as BarchartDrilledCompute from './methods/drilled.method';
import * as BarchartMedianMergedCompute from './methods/merged-median.method';
import { Descriptor } from '../../../../../../../../types';
import { Table } from '../table';


const mapSettingsKey: Map<string, string> = new Map();
mapSettingsKey.set('scale', 'yAxis');
mapSettingsKey.set('plotBands', 'yAxis');

export class BarChart extends Chart {
    protected _baseParameters: any = {
        chart: {
            type: 'bar',
            events: {
                load: function (el) {
                    this.parameters.series.forEach((serie: any) => {
                        serie.color = el.target.series.find((x: any) => x.name === serie.name).color;
                    });
                    this.updateChartTranslations(el.target, this.parameters, this.lang);
                    this.buildGraphOptions(this.parameters);
                    this.onOptionChangeAfterLoadSubject.next(this.options);
                }.bind(this)
            }
        },
        xAxis: {
            type: 'category',
            crosshair: true,
            useHTML: true,
            labels: {
                formatter: function (el: any) {
                    const { userOptions } = el.chart;
                    let langs: Array<string> = [this.lang];
                    if (userOptions.hasOwnProperty('plotOptions') && userOptions.plotOptions.hasOwnProperty('default_language')) {
                        if (userOptions.plotOptions.default_language.enabled && langs.indexOf(Languages.Default) === -1) langs.push(Languages.Default);
                    }
                    if (userOptions.hasOwnProperty('plotOptions') && userOptions.plotOptions.hasOwnProperty('attributes_blocks')) return BarChartLabelFormatter.formatter(el, langs, userOptions.plotOptions.attributes_blocks.enabled);
                    else return BarChartLabelFormatter.formatter(el, langs, false);

                }.bind(this),
            }
        },
        yAxis: {
            tickInterval: 1,
            title: {
                enabled: true,
                text: ''
            }
        },
        tooltip: {
            enabled: false,
            //shared: true,
            useHTML: true,
            formatter: function (el: any) {
                const { userOptions } = el.chart;
                if (userOptions.hasOwnProperty('plotOptions') && userOptions.plotOptions.hasOwnProperty('attributes_blocks')) return BarChartTooltipFormatter.formatter(this, userOptions.language, userOptions.plotOptions.attributes_blocks.enabled);
                else return BarChartTooltipFormatter.formatter(this, userOptions.language, false);
            },
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false,
                    inside: false,
                    color: 'black'
                },
                minPointLength: 3
            }
        }
    };

    private initialData: any;
    public onDrillSubject = new Subject<string>();
    private drilledState: boolean = false;
    public static mapSettings: Map<string, string> = mapSettingsKey;

    constructor(
        _parameters?: any,
        _data?: any,
        _lang?: string,
        _filters?: any,
        private _translateService?: TranslateService
    ) {
        super(_parameters, _data, _lang, _filters);
        this.tableRawData = this.formatRawData(_data, _lang);
        this.generateParametersAssignment(this.parameters);
        if(Array.isArray(_data.pDescriptors) && _data.pDescriptors.length <= 4) this.setSmallDimension();
        this.generateDataAssignment(this.data, this.lang);
        this.loadChartTitles(this.parameters, _lang);
    };

    setSmallDimension() {
        this.parameters.yAxis.min = -3;
        this.parameters.yAxis.max = 3;
    }

    private generateParametersAssignment(parameters) {
        Object.assign({ ...this._baseParameters, parameters });
        Object.keys({ ...this._baseParameters }).forEach((k) => {
            this.parameters[k] = Object.assign({ ...this._baseParameters[k] }, parameters[k])
        });
        Object.keys({ ...parameters }).forEach((k) => {
            this.parameters[k] = Object.assign({ ...this.parameters[k] }, parameters[k])
        });
    };

    protected generateDataAssignment = (data: any, lang: string) => {
        this.data = data;
        if (this.initialData != data) this.initialData = data;
        const method = this.parameters.compute ? this.parameters.compute.method : null;
        const baseKey = this.parameters.compute ? this.parameters.compute.key : 'key';
        const payload = this.formatData(
            this.initialData,
            this.drilledState ? 'drilled' : method,
            baseKey,
            lang,
            this.parameters,
            this.descriptors,
            this.routines
        );
        this.parameters.series = payload.series;
        this.parameters.xAxis.categories = payload.categories;
        if (method && method === 'median-merged') {
            for (let routine of payload.series) {
                (this.parameters.yAxis.plotBands =
                    (this.parameters.yAxis.plotBands || [])).push({
                        "color": "rgba(0,0,0,0)",
                        "from": routine.isBench ? this.parameters.yAxis.min : 0,
                        "to": routine.isBench ? 0 : this.parameters.yAxis.max,
                        "label": {
                            "text": routine.name,
                            "align": "center",
                            y: -10
                        }
                    })
            }
        }
        if (this.parameters.hasOwnProperty('drilldown') && this.parameters.drilldown.enabled) {
            this.parameters.plotOptions.series.cursor = 'pointer';
            this.parameters.plotOptions.series.events = {
                click: (() => this.handleDrill(this.drilledState)).bind(this)
            };
        } else {
            this.parameters.tooltip.enabled = true;
        }

        if (this.chart) this.updateChartTranslations(this.chart, this.parameters, this.lang);
    };

    /**
     * buildGraphOptions
     */
    public buildGraphOptions = (options: any) => {
        const { title, subtitle, xAxis, yAxis, series, plotOptions, ...rest } = options;
        this.options = new BarChartOptions({
            general: { title, subtitle },
            xAxis,
            yAxis,
            series,
            plotOptions
        }, this.onOptionsChange);
    };

    protected setParamsToAxis = (params: any, axis: any) => Object.assign(axis, params);

    protected formatData = (
        data: Array<any>,
        method: string,
        baseKey: string,
        lang: string,
        parameters: any,
        descriptors: Array<Descriptor>,
        routines: Array<any>
    ): { categories: Array<any>, series: Array<any> } => {
        const payload = {
            categories: {},
            series: {},
        };
        const drilledMethod = parameters.compute ? parameters.compute.method : null;
        switch (method) {
            case null:
                break;
            case COMPUTING_METHODS.DRILLED:
                BarchartDrilledCompute.doWork(data, baseKey, lang, descriptors, payload, drilledMethod, routines);
                break;
            case COMPUTING_METHODS.MEDIAN_MERGED:
                BarchartMedianMergedCompute.doWork(data, baseKey, lang, payload, routines);
        };
        return {
            categories: Object.values(payload.categories),
            series: Object.values(payload.series)
        }
    };

    private onOptionsChange = ( options: any ) => {
        const routinesMapping: Map<string, any> = new Map();
        let gotColorChange = false;

        if( this.routines.routines ) {
            this.routines.routines.forEach( r => {
                routinesMapping.set( r.name, r );
            } );
        }

        let fontOptions: any;
        
        for (let o in options) {
            if (o === 'general') {
                const {font, ...rest} = options[ o ];
                Object.assign( this.parameters, rest );
                fontOptions = font;
            } else {
                for (let e in options[o]) {
                    if (Array.isArray(options[o][e])) {
                        options[o][e].forEach(x => {
                            Object.assign( this.parameters[ e ].find( y => y.name === x.name ), x );

                            if( e === 'series' && routinesMapping.has( x.name ) ) {
                                gotColorChange = true;
                                routinesMapping.get(x.name).color = x.color;
                            }
                        })
                    } else {
                        Object.assign(this.parameters[e], options[o][e]);
                    }
                }
            }
        }
        const { yAxis, xAxis, ...parameters } = this.parameters;
        Object.assign(this.parameters, { ...parameters });
        Object.assign(this.parameters.yAxis, yAxis);
        Object.assign( this.parameters.xAxis, xAxis );
        
        if( gotColorChange ) this.custom.updateRoutines( this.routines.routines.map(x => x) );

        const chart = this.build();
        if (fontOptions) this.updateChartFont(chart, fontOptions);
        this.updateChartTranslations( chart, this.parameters, this.lang );

    };

    /**
     * updateChartTranslations
     * Method to manage languages changes
     * @param chart : Highcharts.Chart : Rendered chart
     * @param parameters : any : Chart's parameters
     * @param lang : string
    */
    private updateChartTranslations = (chart: Highcharts.Chart, parameters: any, lang: string): void => {
        this.updateTitleTranslations(chart, lang, parameters);
        this.updateSubTitleTranslations(chart, lang, parameters);
        this.updateCategoriesTranslations(chart, parameters);
        this.updateSeriesTranslations(chart, lang);
        chart.redraw();
        this.upadtePlotLinesAndBandVisibilty(chart, parameters);
    };

    /**
     * updateCategoriesTranslations
     * Method to update categories' translations
     * @param chart : Highcharts.Chart : Rendered chart
     * @param parameters : any : Chart's parameters
    */
    private updateCategoriesTranslations = (chart: Highcharts.Chart, parameters: any): void => {
        chart.xAxis[0].update({
            categories: parameters.xAxis.categories
        });
    };

    /**
     * updateSeriesTranslations
     * Method to update series' translations
     * @param chart : Highcharts.Chart : Rendered chart
     * @param lang : string
    */
    private updateSeriesTranslations = (chart: Highcharts.Chart, lang: string): void => {
        try {
            chart.series
                .forEach((renderedSerie: Highcharts.Series, index: number) => {
                    const userOptions: any = renderedSerie['userOptions'];
                    chart.series[index].update({
                        name: userOptions.translations[lang],
                        type: userOptions.type
                    }, false);
                })
        } catch (e) { }
    };

    /**
     * upadtePlotLinesAndBandVisibilty
     * Method to hide/show grey zone feature for both xAxis & yAxis
     * @param chart : Highcharts.Chart : Rendered chart
     * @param parameters : any : Chart's parameters
     */
    private upadtePlotLinesAndBandVisibilty = (chart: Highcharts.Chart, parameters: any) => {

        for (let axis of chart.xAxis) {
            for (let plotBand of axis['plotLinesAndBands']) {
                if (parameters.plotOptions.grey_zone.enabled) {
                    plotBand.hidden = false;
                    plotBand.svgElem.show();
                } else {
                    plotBand.hidden = true;
                    plotBand.svgElem.hide();
                }
            }
        }

        for (let axis of chart.yAxis) {

            for (let plotBand of axis['plotLinesAndBands']) {
                if (parameters.plotOptions.grey_zone.enabled) {
                    plotBand.hidden = false;
                    plotBand.svgElem.show();
                } else {
                    plotBand.hidden = true;
                    plotBand.svgElem.hide();
                }
            }
        }
    };

    /**
    * handleDrill
    * Method to manage drill up / down on chart data;
    * It delegates logical part to other functions
    * @param isDrilled : boolean : Drilled status
   */
    private handleDrill = (isDrilled: boolean): void => {
        if (isDrilled) this._handleDrillUp();
        else this._handleDrillDown();
        this.upadtePlotLinesAndBandVisibilty(this.chart, this.parameters)
    };

    /**
    * _handleDrillDown
    */
    private _handleDrillDown = (): void => {
        while (this.chart.series.length) {
            this.chart.series[0].remove();
        };
        this.parameters.series = [];
        const payload = this.formatData(
            this.initialData,
            'drilled',
            this.parameters.compute ? this.parameters.compute.key : 'key',
            this.lang,
            this.parameters,
            this.descriptors,
            this.routines);
        this.parameters.xAxis.categories = payload.categories;
        this.chart.xAxis[0].update({
            categories: this.parameters.xAxis.categories
        });
        if (this.parameters.hasOwnProperty('plotOptions') && this.parameters.plotOptions.hasOwnProperty('bar') && this.parameters.plotOptions.bar.hasOwnProperty('stacking')) this.parameters.plotOptions.bar.stacking = null;
        payload.series.forEach(element => {
            this.chart.addSeries(element);
            (this.parameters.series = (this.parameters.series || [])).push(element);
        });
        this.drilledState = true;
        this.onDrillSubject.next('drill');
    };

    /**
    * _handleDrillUp
    */
    private _handleDrillUp = (): void => {
        while (this.chart.series.length) {
            this.chart.series[0].remove(false);
        };
        this.parameters.series = [];
        const payload = this.formatData(
            this.initialData,
            this.parameters.compute ? this.parameters.compute.method : null,
            this.parameters.compute ? this.parameters.compute.key : 'key',
            this.lang,
            this.parameters,
            this.descriptors,
            this.routines);
        this._parameters.xAxis.categories = payload.categories;
        this.chart.xAxis[0].update({
            categories: this.parameters.xAxis.categories
        });
        if (this.parameters.hasOwnProperty('plotOptions') && this.parameters.plotOptions.hasOwnProperty('bar') && this.parameters.plotOptions.bar.hasOwnProperty('stacking')) this.parameters.plotOptions.bar.stacking = 'value';
        payload.series.forEach(element => {
            this.chart.addSeries(element);
            (this.parameters.series = (this.parameters.series || [])).push(element);
        });
        this.drilledState = false;
        this.onDrillSubject.next('drill');
    };

    protected formatRawData = (data: Array<any>, lang: string): { header: Array<any>, body: Array<any> } => {
        try {
            const payload = JSON.parse(JSON.stringify(data['data'])).reduce((accumulator: any, object: any, itemNumber: number) => {
                const payload = object.values.reduce((reducer: any, item: any, index: number) => {
                    item.values.reduce((redacc: any, value: any, idx: number) => {
                        redacc[value.label] = value;
                        return redacc;
                    }, reducer);
                    return reducer;
                }, {});

                const keyList = Object.keys(payload);
                const oPayload = object.values.reduce((reducer: any, item: any, index: number) => {
                    if (item.values.length !== keyList.length) {
                        for (let obj of keyList) {
                            const foundObject = item.values.find((x: any) => { return x.label === obj });
                            if (!foundObject) {
                                item.values.push({
                                    label: payload[obj].label,
                                    value: { label: "", key: "" }
                                });
                            }
                        }
                    }
                    
                    item.values.sort((a, b) => {
                        const labelA = Chart.getObjectValueTranslation(a.label, lang)
                        const labelB = Chart.getObjectValueTranslation(b.label, lang)
                        return labelA.localeCompare(labelB);
                    })

                    return reducer;
                }, object);
                accumulator.push(oPayload);
                return accumulator;
            }, []);

            data['data'] = Object.values(payload.reduce((accumulator: any, object: any, itemNumber: number) => {
                if (object.attribute.blockName) {
                    const key = object.attribute.blockName.english;
                    accumulator[key] = accumulator[key] || { "attribute": { "label": object.attribute.blockName }, "values": [] };
                    accumulator[key].values.push(object);
                }
                return accumulator;
            }, {}));

            const tableTemp = new Table({}, data, lang);
            return tableTemp.parameters.transformedHTMLData;
        } catch (e) {
            return { header: [], body: [] };
        }
    };

    private updateChartFont = ( chart: Highcharts.Chart, option: {size: number, bold: boolean;} ): void => {
        try {
            chart.yAxis[ 0 ].update( {
            labels: {
                ...chart.yAxis[0].userOptions.labels,
                style: {
                    fontSize: option.size + 'px',
                    fontWeight: option.bold ? 'bold' : 'normal',
                }
            }
            }, false );
    
            chart.xAxis[ 0 ].update( {
            labels: {
                ...chart.xAxis[ 0 ].userOptions.labels,
                style: {
                    fontSize: option.size + 'px',
                    fontWeight: option.bold ? 'bold' : 'normal'
                }
            }
            }, false );
        } catch(e) {
            console.error( 'error in updating chart font, barchart' );
        }
    }
};