import { DNATranslateService } from './../../../../../../shared/services/translate.service';
import { Descriptor, DescriptorGroup, Translatable } from './../../../../../../types';
import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ReferenceTypeService } from './../../../../../../shared/services/reference-type.service';
import { ErrorManagerService } from './../../../../../../shared/services/errorManager.service';
import { UtilService } from './../../../../../../shared/services/util.service';

@Component({
  selector: 'dna-add-descriptor-multi',
  templateUrl: './add-descriptor-multi.component.html',
  styleUrls: ['./add-descriptor-multi.component.less']
})
export class MultiGraphAddDescriptorModal implements OnInit {

  @Input() descriptorGroupInGraph: DescriptorGroup[] = [];
  @Input() descriptorGroup: DescriptorGroup[] = [];
  @Input() isGroup: boolean = false;

  calculationMethods: string[] = [];
  calculationMethodSelected: string = "";
  groupName: string = "";
  listDescriptors: DescriptorGroup[];

  descriptorsGrouped: { [blockName: string]: DescriptorGroup[] };
  blockList: string[] = [];
  collapsedBlocksTab = {};

  selectAll: {
    [key: string]: boolean;
  } = {};

  constructor(
    private activeModal: NgbActiveModal,
    private translateService: DNATranslateService,
    private referenceTypeService: ReferenceTypeService,
    private errorManager: ErrorManagerService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    if(this.isGroup) {
      this.calculationMethods = this.referenceTypeService.getCalculationMethod();
      this.listDescriptors = _.cloneDeep(this.descriptorGroupInGraph);
      this.descriptorGroupInGraph = [];
    }
    const lang = this.translateService.getLanguage();
    this.descriptorsGrouped = _.groupBy(this.descriptorGroup, (d: DescriptorGroup) => this.translateService.getTranslation(d.descriptors[0].blockName, lang));
    this.blockList = Object.keys(this.descriptorsGrouped);
    this.blockList.map((b, i) => {
      this.collapsedBlocksTab[i] = true;
      this.selectAll[i] = this.descriptorsGrouped[b].filter(d => this.isDescriptorSelected(d)).length === this.descriptorsGrouped[b].length;
    });
  }

  selectAllDescriptors(descriptorGroup: DescriptorGroup[],indexBlock: number, event: any) {
    event.stopPropagation();
    if (this.selectAll[indexBlock]) {
      descriptorGroup.filter(d => this.isDescriptorSelected(d)).map(d => this.addOrRemoveDescriptors(d));
    } else {
      descriptorGroup.filter(d => !this.isDescriptorSelected(d)).map(d => this.addOrRemoveDescriptors(d));
    }
    this.selectAll[indexBlock] = !this.selectAll[indexBlock];
  }

  getNumberSelected(descriptorGroup: DescriptorGroup[]): string {
    return `${descriptorGroup.filter(d => this.isDescriptorSelected(d)).length} / ${descriptorGroup.length}`;
  }

  isDescriptorSelected(d: DescriptorGroup): boolean {
    return this.descriptorGroupInGraph.find(de => de.id === d.id) !== undefined;
  }

  getName(descriptors: Descriptor[], key: string): string {
    const lang = this.translateService.getLanguage();
    return descriptors.reduce((s, d, i) => {
      if (i > 0) s += ' | ';
      s += this.translateService.getTranslation(d[key], lang);
      return s;
    }, "")
  }

  addOrRemoveDescriptors(descriptorGroup: DescriptorGroup) {
    if (this.isDescriptorSelected(descriptorGroup)) {
      _.remove(this.descriptorGroupInGraph, { id: descriptorGroup.id });
    }
    else {
      this.descriptorGroupInGraph.push(descriptorGroup);
    }
  }

  submit(descriptors: DescriptorGroup[]) {
    if (this.isGroup) {
      if (_.isEmpty(this.calculationMethodSelected) || _.isEmpty(this.groupName)) {
        this.errorManager.displayMessage("ON_ERROR_FORM", "danger");
      } else {
        let groupDescriptor = new DescriptorGroup(this.utilService.generateRandomID(7));
        groupDescriptor.name = new Translatable(this.groupName);
        groupDescriptor.isGroup = true;
        groupDescriptor.descriptorsGroupIds = descriptors.map(d => _.omit(new DescriptorGroup(d.id), ['name', 'descriptorGroup', 'descriptors', 'descriptorsGroupIds']));
        groupDescriptor.calculationMethod = this.calculationMethodSelected;
        this.activeModal.close([groupDescriptor]);
      }
    } else {
      this.activeModal.close(descriptors);
    }
  }

  cancel() {
    this.activeModal.dismiss();
  }

  openCloseDescriptors(openOrClose: boolean) {
    Object.keys(this.collapsedBlocksTab).forEach(key => this.collapsedBlocksTab[key] = openOrClose);
  }

}
