import { environment } from '../../../../environments/environment';
import { Workflow } from '../../../types';
import {
  WorkflowService
} from '../../workflows.service';
import {
  DNATranslateService
} from '../../../shared/services/translate.service';
import {
  DomSanitizer,
  SafeResourceUrl
} from '@angular/platform-browser';
import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import { UserService } from '../../../shared/services/user.service';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-preview-workflow',
  templateUrl: './preview-workflow.html',
})

export class PreviewWorkflow implements OnInit, AfterViewInit {
  workflow: Workflow = new Workflow();
  showSpinner: boolean;
  url: SafeResourceUrl;
  initTime = performance.now();

  constructor(
    private DNATranslate: DNATranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private aiService: ApplicationInsightsService,
    private workflowService: WorkflowService,
    private sanitizer: DomSanitizer,
    private userService : UserService
  ) {}

  ngOnInit() {
    this.workflow = this.workflowService.workflow;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.mobile_url()}/#/workflowPreview/${this.workflow.id}/${this.userService.getUser().currentWorkspace.id}`);
  }

  back() {
    this.router.navigate(['workflows', this.workflow.id]);
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Library Preview Workflow', '', performance.now() - this.initTime, templateUrl);
  }

}
