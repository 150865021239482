import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter
} from '@angular/core';
import { Router } from '@angular/router';

import { CampaignService } from '../../../../../campaigns.service';
import { EvaluationWrapper, ActionBarButton, DNATypes } from './../../../../../../types';
import { UtilService } from '../../../../../../shared/services/util.service';

import {
  Observable
} from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: '[dna-evaluation-row]',
  templateUrl: './evaluation-row.component.html',
  styleUrls: ['./evaluation-row.component.less']
})
export class EvaluationRowComponent implements OnInit {

  @Input() evaluation: EvaluationWrapper;
  @Input() hasVolunteer: boolean;
  @Input() linkAuthorized: boolean;
  @Output() onClickAction: EventEmitter<ActionBarButton> = new EventEmitter();

  actionsButtons: Observable<ActionBarButton[]>;
  
  constructor(
    public campaignService: CampaignService,
    public utilService: UtilService,
    private router: Router
  ) { }

  ngOnInit() {
    this.evaluation.users = this.utilService.addNamesToUsers(this.evaluation.users, this.campaignService.users);
    this.evaluation.peopleKeys = this.utilService.addNamesToUsers(_.get(this.evaluation, 'peopleKeys', []), this.campaignService.users);
    this.actionsButtons = this.utilService.createActionsButtons(DNATypes.Evaluation);
  }

  goToDetail(evaluation: EvaluationWrapper) {
    this.router.navigate(['/campaigns', evaluation.studyId, 'edit', 'rawdata', evaluation.id, 'detail']);
  }

  onClickButton(actionsButton: ActionBarButton) {
    this.onClickAction.emit(actionsButton);
  }

  getUserOrPeople(ev: EvaluationWrapper) {
    return _.isEmpty(_.get(ev, 'peopleKeys', [])) ? ev.users : ev.peopleKeys;
  }
}
