<form #volunteerForm="ngForm" novalidate>
  <div class="modal-header">
    <h3 class="text-center" translate>CREATE_VOLUNTEER</h3>
  </div>
  <label class="col-sm-12"></label>

  <div class="form-group" [ngClass]="{'has-error' : (volunteerForm.submitted && firstName.invalid) || (firstName.dirty && firstName.invalid) }">
    <label class="control-label col-sm-2">{{'FIRST_NAME' |translate}}
      <dna-mandatory></dna-mandatory>
    </label>
    <div class="col-sm-10">
      <input type="text" class="form-control" [(ngModel)]="volunteerCopy.firstName" #firstName="ngModel" name="firstName" required>
      <div *ngIf="(volunteerForm.submitted && firstName.invalid) || (firstName.dirty && firstName.invalid)" class="alert alert-danger"
        translate>
        REQUIRED
      </div>
    </div>
  </div>

  <div class="form-group" [ngClass]="{'has-error' : (volunteerForm.submitted && name.invalid) || (name.dirty && name.invalid) }">
    <label class="control-label col-sm-2">{{'NAME' |translate}}
      <dna-mandatory></dna-mandatory>
    </label>
    <div class="col-sm-10">
      <input type="text" class="form-control" [(ngModel)]="volunteerCopy.name" #name="ngModel" name="name" required>
      <div *ngIf="(volunteerForm.submitted && name.invalid) || (name.dirty && name.invalid)" class="alert alert-danger" translate>
        REQUIRED
      </div>
    </div>
  </div>

  <div class="form-group" [ngClass]="{'has-error' : (volunteerForm.submitted && email.invalid) || (email.dirty && email.invalid) }">
    <label class="control-label col-sm-2">{{'EMAIL' |translate}}
      <dna-mandatory></dna-mandatory>
    </label>
    <div class="col-sm-10">
      <input type="email" [email]="true" class="form-control" [(ngModel)]="volunteerCopy.email" #email="ngModel" name="email" required>
      <div *ngIf="(volunteerForm.submitted && email.errors && email.errors.required) || (email.dirty && email.errors && email.errors.required)"
        class="alert alert-danger" translate>
        REQUIRED
      </div>
      <div *ngIf="(volunteerForm.submitted && email.errors && email.errors.email) || (email.dirty && email.errors && email.errors.email)"
        class="alert alert-danger" translate>
        ERROR_MAIL_FIELD
      </div>
    </div>
  </div>
  <div class="form-group col-sm-10">
    <dna-checkbox [isChecked]="doRegister" (onBooleanChanged)="doRegister = !doRegister"></dna-checkbox>
    {{'REGISTER' | translate}}
  </div>

  <label class="col-sm-12"></label>

  <div class="modal-footer">
    <button id="save" class="btn btn-primary mb-4" (click)="save(volunteerForm)" translate>SAVE</button>
    <button id="cancel" class="btn btn-default mb-4" (click)="cancel()" translate>CANCEL</button>
  </div>
</form>
