<div class="container">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <div class="ibox float-e-margins" *ngIf="workflowsOk && !showSpinner">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-6 my-auto">
          <h5 class="text-center">{{ "WORKFLOWS" | translate }}</h5>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>{{ "CAMPAIGN" | translate }}</th>
            <th colspan="3">{{ "WORKFLOW" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let cw of campaignMultiWorkflows">
            <tr>
              <td [attr.rowspan]="cw.workflows.length + 1">{{ cw.campaignName }}</td>
            </tr>
            <tr *ngFor="let workflow of cw.workflows">
              <td>{{ workflow.name[language] || workflow.name.english }}</td>
              <td class="text-center clickable" (click)="getWorkflowConfiguration(cw.campaignId, workflow?.id)"><span class="fa fa-cog"></span></td>
              <td class="text-center clickable" (click)="getWorkflowGraphs(cw.campaignId, workflow?.id)"><span class="fa fa-bar-chart"></span></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="!workflowsOk && !showSpinner">
    <dna-error-page (onClickReload)="init()"></dna-error-page>
  </div>
</div>
