import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isExistingSymbol'
})
export class IsExistingSymbolPipe implements PipeTransform {

  transform(b: boolean, value: any , array : any): any {
    if ( !array || !array.length ) return b || false; 
    return b ? b : array.map(x => x.value).indexOf(value) !== -1;
  }

}
