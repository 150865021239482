import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpRestService } from '../services/httpRest.service';
import { DNACollection, DNACollections } from '../../types'

@Component({
    selector: 'dna-datalake',
    templateUrl: './dnaDatalake.component.html',
    styleUrls: ['./dnaDatalake.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DatalakeComponent implements OnInit {

    @Input() id: string;
    @Input() datalakeStatus: boolean;
    @Input() collection: DNACollection;
    @Input() subCollection: DNACollection;
    @Input() subIds: string[];

    @Output() onSend: EventEmitter<any> = new EventEmitter();

    constructor(
        private httpRestService: HttpRestService
    ) { }

    ngOnInit() {
    }

    sendDatalakeData() {
        this.httpRestService.sendDatalakeData(this.id, this.collection).subscribe();
        if (this.subCollection) {
            for (let subId of this.subIds) {
                setTimeout(() => {
                    this.httpRestService.sendDatalakeData(subId, this.subCollection).subscribe();
                }, 200);
            }
        }
        setTimeout(() => {
            this.onSend.emit();
        }, 2000);
    }
}