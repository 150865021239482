import {
  DNATranslateService
} from '../../../shared/services/translate.service';
import {
  DragulaService, DragulaOptions
} from 'ng2-dragula';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit
} from '@angular/core';

import {
  NgbModal,
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import { Block, DNAComponent, Language, LogicJump, Question, Workflow, Languages, LogicJumpNext } from '../../../types';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-logic-jumps',
  templateUrl: './logicJumpsModal.component.html',
  styleUrls: ['./logicJumpsModal.component.css']
})

export class LogicJumpsModalComponent implements OnInit, AfterViewInit {
  @Input() workflow: Workflow;
  @Input() currentBlock: Block;
  logicJumps: LogicJump[] = [];
  currentLanguage: Language;
  previousQuestions: Question[];
  nextQuestions: Question[];
  blockName: string;
  selectedQuestion: Question;
  initTime = performance.now();

  constructor(
    public activeModal: NgbActiveModal,
    private dragulaService: DragulaService,
    private aiService: ApplicationInsightsService,
    private DNATranslate: DNATranslateService
  ) {
    const dragulaOption: DragulaOptions = {
      moves: function (el, container, handle) {
        return handle.className === 'fa fa-sort';
      }
    }
    dragulaService.createGroup('logic-jump-bag', dragulaOption);
  }

  public ngOnInit(): void {
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.blockName = this.DNATranslate.getTranslation(this.currentBlock.name, this.currentLanguage);
    let indexBlock = this.workflow.blocks.findIndex(block => block.idInQuestionnaire === this.currentBlock.idInQuestionnaire);
    this.previousQuestions = this.workflow.blocks.slice(0, indexBlock + 1)
      .reduce((questions, block) => this.addBlockToQuestionList(questions, block, this.currentLanguage), [])

    this.nextQuestions = this.workflow.blocks.slice(indexBlock + 1)
      .map(block => this.createQuestion(block, this.currentLanguage))
      .map((question, index) => this.addIndex(question, indexBlock + index + 1));
    this.nextQuestions.push(this.addSubmitQuestion(this.workflow.blocks.length + 1));

    if (!this.currentBlock.parameters.logicJumpNext) {
      this.currentBlock.parameters.logicJumpNext = new LogicJumpNext(this.nextQuestions[0].idInQuestionnaire);
    }

    this.selectedQuestion = this.nextQuestions.find(q => q.idInQuestionnaire === this.currentBlock.parameters.logicJumpNext.idInQuestionnaire);
  }

  ngAfterViewInit() {
    this.aiService.logPageView('Workflow Logic Jump Modal', '', performance.now() - this.initTime, 'Modals');
  }

  addIndex(question: Question = new Question('', ''), index: number = 0): Question {
    question.libelle = index + 1 + ' - ' + question.libelle;
    return question;
  }

  addBlockToQuestionList(questionList: Question[] = [], block: Block = new Block(), language: Language = Languages.English): Question[] {
    let newQuestions = block.components.filter(this.getComponentForLogicJump).map(component => this.createQuestion(block, language, component));
    questionList = questionList.concat(newQuestions);
    return questionList;
  }

  addSubmitQuestion(index: number): Question {
    return new Question(`${index} - Submit`, 'submit');
  }

  getComponentForLogicJump(component: DNAComponent = new DNAComponent()): boolean {
    if (!['dna-input-text', 'dna-multiple-choice', 'dna-checkbox', 'dna-radio-button'].find(e => e === component.type)) return false;
    if (component.args.leftRightMode) return false;
    return true;
  }

  createQuestion(block: Block = new Block(), language: Language = Languages.English, component?: DNAComponent): Question {
    let question = new Question(this.DNATranslate.getTranslation(block.name, language), block.idInQuestionnaire);

    if (component) {
      question.idInBlock = component.idInBlock;
      question.answer.values = this.DNATranslate.getTranslation(component.args.values, language);
      question.answer.keys = component.args.keys;
      question.libelle += ', ' + this.DNATranslate.getTranslation(component.args.label, language);
    };
    return question;
  }

  dismiss() {
    this.dragulaService.destroy('logic-jump-bag');
    this.activeModal.dismiss();
  }

  close() {
    this.dragulaService.destroy('logic-jump-bag');
    this.activeModal.close(this.logicJumps);
  }

  compareQuestion(a: Question, b: Question) {
    return a && b && (a.idInBlock === b.idInBlock || (!a.idInBlock && !b.idInBlock)) && a.idInQuestionnaire === b.idInQuestionnaire;
  }

  onChange(question: Question) {
    this.currentBlock.parameters.logicJumpNext = new LogicJumpNext(question.idInQuestionnaire);
    this.selectedQuestion = question;
  }

  addLogicJump() {
    this.logicJumps.push(new LogicJump());
  }

}
