
import {map, flatMap, tap, finalize} from 'rxjs/operators';
import * as _ from 'lodash';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router} from '@angular/router';
import { WorkspaceService } from '../../workspaces.service';
import { ErrorManagerService } from '../../../../../shared/services/errorManager.service';
import {
  AllGraph,
  ChartSettings,
  DNAGraphsTypes,
  MinMaxValueSettings,
  Workspace,
  ChartPreferences
} from '../../../../../types';
import { ApplicationInsightsService } from '../../../../../shared/services/applicationInsights.service';


@Component({
  selector: 'dna-chartSettings',
  templateUrl: 'chartsSettings.component.html',
  styleUrls: ['./chartsSettings.component.less']
})
export class ChartsSettingsComponent implements OnInit, AfterViewInit {

  workspace: Workspace;
  originalWorkspace: Workspace;
  showSpinner: boolean = true;
  showSpinnerSave: boolean = false;
  chartsPreferences: string[];
  allGraph: any;
  initTime = performance.now();

  constructor(
    private errorManager: ErrorManagerService,
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private router: Router,
    private workspaceService: WorkspaceService,
  ) {}

  ngOnInit() {
    this.allGraph = AllGraph;
    this.route.paramMap.pipe(
      map((params: ParamMap) => params.get("workspace")),
      flatMap((workspace: string) => this.workspaceService.getWorkspace(workspace)),
      tap((workspace: Workspace) => this.originalWorkspace = _.cloneDeep(workspace)),
      tap((workspace: Workspace) => this.workspace = this.initSettings(workspace)),
      tap(() => this.chartsPreferences = this.initPreferences()),
      tap(() => {
        if(!_.isEqual(this.originalWorkspace, this.workspace)) {
          this.save(false)
        }
      }),
      tap(() => this.showSpinner = false)
    ).subscribe();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Workspaces Charts Settings', '', performance.now() - this.initTime, templateUrl);
  }

  addNewDecisionRule(id: string) {
    this.workspace.chartsSettings.find(cs => cs.id === id).decisionRules.push(new MinMaxValueSettings(-1, 1, ''));
  }

  cancel() {
    this.workspace = _.cloneDeep(this.originalWorkspace);
  }

  canSave() {
    return !_.isEqual(this.originalWorkspace, this.workspace)
  }

  changeAllGraphValue(preference: string) {
    this.workspace.chartsPreferences[preference].value = !this.workspace.chartsPreferences[preference].value;
    this.workspace.chartsSettings.forEach(graph => {
      if (!_.isUndefined(graph[preference])) {
        graph[preference] = this.workspace.chartsPreferences[preference].value;
      }
    })
  }

  goToWorkspaces() {
    this.router.navigate(['myprofile/workspaces']);
  }

  /**
   * Cette fonction permet d'ajouter à chaque chargement de la page les champs manquants dans
   * les paramètres des graphes
   * @param workspace
   * @return workspace mis à jour
   */
  initSettings(workspace: Workspace) : Workspace {
    //chartspreference: les settings pour tous les graphes n'existent pas
    if (_.isUndefined(workspace.chartsPreferences)) {
      workspace.chartsPreferences = new ChartPreferences();
    } else if (_.keys(workspace.chartsPreferences).length < _.keys(new ChartPreferences()).length) {
      const newPreferences = new ChartPreferences();
      _.keys(newPreferences).forEach(key => {
        if (_.isUndefined(workspace.chartsPreferences[key]))  {
          workspace.chartsPreferences[key] = newPreferences[key];
        }
      });
    }
    //chartsSettings: les setings pour chaque grahpes n'existent pas
    if (_.isUndefined(workspace.chartsSettings)) {
      let chartsSettings: ChartSettings[] = [];
      _.forEach(DNAGraphsTypes, graph => {
        chartsSettings.push(new ChartSettings(graph.id, graph.name));
      });
      workspace.chartsSettings = chartsSettings;
    } else {
      //certains éléments de chartsSettings n'existent pas
      if (!_.isEqual(DNAGraphsTypes.length, workspace.chartsSettings.length)) {
        DNAGraphsTypes.filter((graph) => _.isUndefined(workspace.chartsSettings.find(chart => chart.id == graph.id)))
          .forEach(graph => workspace.chartsSettings.push(new ChartSettings(graph.id, graph.name)));
      }
      const newGraph = new ChartSettings("", "");
      workspace.chartsSettings.filter(setting => _.keys(setting).length < _.keys(newGraph).length)
      .forEach(graph => {
        _.keys(newGraph).forEach(key => {
          if (_.isUndefined(graph[key]))  {
            graph[key] = newGraph[key];
          }
        });
      });
    }
    return workspace;
  }

  initPreferences(): string[] {
    return _.keys(new ChartPreferences());
  }

  isGraphSetting(chartSettingId: string, chart: string) : boolean {
    return _.get(DNAGraphsTypes.find(graph => graph.id === chartSettingId), chart, false);
  }

  isAllGraphSetting( chart: string) : boolean {
    return _.get(AllGraph, chart, false);
  }

  containsParam(graphID) {
    const graphType = DNAGraphsTypes.find((graph) => graph.id == graphID);
    return _.has(graphType, "scale") || _.has(graphType, "plotBands") || _.has(graphType, "visibilityThreshold") || _.has(graphType, "axis") || _.has(graphType, "decisionRules");
  }

  isValid(chartSettings: ChartSettings[]) : boolean {
    let isValid = true;
    _.forEach (chartSettings, c => {
      if (c.scale.min >= c.scale.max || c.plotBands.min >= c.plotBands.max || c.axis.x.min >= c.axis.x.max || c.axis.y.min >= c.axis.y.max) {
        isValid = false;
      }
      _.forEach (c.decisionRules, dr => {
        if (dr.min >= dr.max) isValid = false;
      });
    });
    return isValid;
  }

  removeDecisionRule(idCharts: string, idDecisionRule: number) {
    this.workspace.chartsSettings.find(cs => cs.id === idCharts).decisionRules.splice(idDecisionRule, 1);
  }

  save(showAlert:boolean = true) {
    if (this.isValid(this.workspace.chartsSettings)) {
      this.showSpinnerSave = true;
      this.workspaceService.putWorkspace(this.workspace)
        .pipe(
          finalize(() => this.showSpinnerSave = false)
        )
        .subscribe(
          () => {
            showAlert && this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
          },
          error => {
            showAlert && this.errorManager.displayMessage("UNKNOW_ERROR", "danger");
          }
        );
      this.originalWorkspace = _.cloneDeep(this.workspace);
    } else {
      showAlert && this.errorManager.displayMessage("ERROR_MIN", "danger");
    }
  }

}
