
import {of as observableOf,
  Observable
} from 'rxjs';

import {tap, mergeMap} from 'rxjs/operators';
import {
  HttpClient
} from '@angular/common/http';
import {
  Injectable
} from '@angular/core';

import {
  environment
} from '../../../../environments/environment';
import {
  Metier,
  Metiers,
  User,
  Workspace,
  WorkspaceList
} from '../../../types';
import * as _ from 'lodash';

@Injectable()
export class WorkspaceService {

  private storageKeys = {
    Workspaces: 'DNA_WORKSPACES'
  }

  serverUrlStudio: string;
  workspaces: Workspace[] = [];
  workspacesUser: Workspace[] = [];

  constructor(
    private http: HttpClient
  ) {
    this.serverUrlStudio = environment.server_url_studio();
  }

  createWorkspace(workspace: Workspace): Observable < Workspace > {
    return this.http.post<Workspace>( `${environment.apiSharedUrl}/v1/workspaces`, workspace);
  }

  deleteWorkspace(id: string): Observable < Workspace > {
    return this.http.delete<Workspace>( `${environment.apiSharedUrl}/v1/workspaces/${id}`);
  }

  getMetiers = (): Metier[] => {
    return [{
        id: "hair",
        name: Metiers.Hair
      },
      {
        id: "skin",
        name: Metiers.Skin
      },
    ];
  }

  getWorkspaces(): Observable < Workspace[] > {
    return this.http.get<any>(`${environment.apiSharedUrl}/v1/workspaces`).pipe(
      mergeMap(response => {
        // Nouveau Map pour stocker les workspaces uniques filtrés par id (pour éviter les doublons de workspace avec 2 id identiques)
        const workspaceMap = new Map();

        response.list.forEach(workspace => {
          workspaceMap.set(workspace.id, workspace);
        });

        const uniqueWorkspaces = Array.from(workspaceMap.values());
        this.setWorkspaces(uniqueWorkspaces);
        return observableOf(uniqueWorkspaces);
      })
    );
  }


  getWorkspace(id: string, peopleKey?: string): Observable < Workspace > {
    if (peopleKey) {
      return this.http.get<any>( `${environment.apiSharedUrl}/v1/workspaces/id/${id}/${peopleKey}`);
    }
    return this.http.get<any>( `${environment.apiSharedUrl}/v1/workspaces/id/${id}`);
  }

  getWorkspacesByAdminUser(peopleId: string): Observable < WorkspaceList > {
    return this.http.get<any>( `${environment.apiSharedUrl}/v1/users/${peopleId}/workspaces/administrators`);
  }

  getWorkspacesByUser(peopleId: string): Observable < Workspace[] > {
    let http = this.http.get < Workspace[] > (this.serverUrlStudio + '/users/' + peopleId + '/workspaces');
    // let local = this.getWorkspacesUserLocal();
    // if(local && local.length > 0) {
    //     return observableOf(local);
    // }
    return http.pipe(tap(workspaces => this.setWorkspacesUserLocal(workspaces)));
  }

  private getWorkspacesUserLocal = (): Workspace[] => {
      return this.workspacesUser;
  }

  private setWorkspacesUserLocal(workspaces: Workspace[]) {
    this.workspacesUser = workspaces;
  }

  getWorkspacesInLocal(): Observable < Workspace[] > {
    if (this.workspaces.length > 0) {
      return observableOf(this.workspaces);
    } else {
      return this.getWorkspaces();
    }
  }

  putWorkspace(workspace: Workspace): Observable < Workspace > {
    return this.http.patch<any>( `${environment.apiSharedUrl}/v1/workspaces/${workspace.id}`, _.omit(workspace, ['id', 'applicationCode', 'class', '_rid', '_self', '_etag', '_attachments', '_ts', '__links']));
  }

  selectWorkspace(user: User, workspaces: Workspace[]): any {
    return user.currentWorkspace.name ? workspaces.find((workspace: Workspace) => workspace.id === user.currentWorkspace.id) : workspaces[0];
  }

  setWorkspaces(workspacesNew: Workspace[]) {
    this.workspaces = workspacesNew;
  }

}
