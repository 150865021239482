export default {
    "type": "barchart",
    "configs": {
        "chart" : {
            "backgroundColor": 'rgba(0,0,0,0)'
        },
        "compute": {
            "method" : "median-merged",
            "key" : "label"
        },
        "drilldown" : {
            "enabled" : true
        },
        "title": {
            "text" : "",
            "margin": 50
        },
        "subtitle" : {
            "text" : ""
        },
        "yAxis": {
            "min": -6,
            "max": 6,
            "labels": {
                formatter: function(el) {
                return Math.abs(parseFloat(el.value))
                }
            },
            "plotBands": [
                {
                    "color": "rgba(239,236,236,0.5)",
                    "from": -0.5,
                    "to": 0.5,
                },
            ],
            "plotLines": [
                {
                    "zIndex": 4,
                    "color": '#787777',
                    "dashStyle": 'shortdash',
                    "width": 1,
                    "value": 0.5
                },
                {
                    "zIndex": 4,
                    "color": '#787777',
                    "dashStyle": 'shortdash',
                    "width": 1,
                    "value": -0.5,
                }
            ]
        },
        "plotOptions": {
            "bar": {
                "stacking": "normal",
                "dataLabels": {
                    "color": 'black',
                    formatter : function(el) {
                        if( this.y ) return Math.abs(this.y)
                    }
                }
            },
            "grey_zone" : {
                "enabled" : false
            },
            "attributes_blocks": {
                "enabled": false
            },
            "default_language" : {
                "enabled" : false
            }
        }
    }
}