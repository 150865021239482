import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'dna-save-cancel',
    templateUrl: './saveCancel.component.html'
})

export class SaveCancelComponent implements OnInit {

    @Input() type : string;
    @Output() onClickSave = new EventEmitter();
    @Output() onClickCancel = new EventEmitter();

    constructor() { }

    ngOnInit() {}

    clickSave() {
        this.onClickSave.emit();
    }

    clickCancel() {
        this.onClickCancel.emit();
    }
}
