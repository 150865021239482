<dna-layout>
  <h1 class="mb-4" id="banner-title">
    <span>{{user.displayName}}</span>
  </h1>
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/myprofile" translate>MY_PROFILE</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" *ngIf="route === '/myprofile/identity'" translate>USER</li>
      <li class="breadcrumb-item active" aria-current="page"
        *ngIf="route === '/myprofile/feedback' && userIsAdministrator" translate>FEEDBACK</li>
      <li class="breadcrumb-item active" aria-current="page"
        *ngIf="route === '/myprofile/workspaces' && userIsWorkspaceAdministrator" translate>WORKSPACES</li>
        <li class="breadcrumb-item active" aria-current="page"
        *ngIf="route === ('/myprofile/volunteers-management') && userIsAdministrator"
        translate>VOLUNTEERS_MANAGEMENT</li>
      <li class="breadcrumb-item active" aria-current="page"
        *ngIf="route === ('/myprofile/import-export/import' || '/myprofile/import-export/export') && userIsAdministrator"
        translate>IMPORT_EXPORT</li>
      <li class="breadcrumb-item active" aria-current="page"
        *ngIf="route === '/myprofile/translations' && (userIsAdministrator || userIsBlocks)" translate>TRANSLATIONS_MANAGEMENT</li>
    </ol>
  </nav>
  <div class="block">
    <div class="row">
      <div class="col-sm-3 sideBarMenu">
        <dna-side-bar-menu [menus]="sideMenu" (click)="updateRoute()"></dna-side-bar-menu>
      </div>
      <div class="col-sm-9">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</dna-layout>