
import { DNAObject } from '../../types';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dna-components-detail',
    templateUrl: './dnaComponentsDetail.component.html',
    styleUrls: ['./dnaComponentsDetail.css']
})

export class ComponentsDetail implements OnInit {
    @Input() components: DNAObject[];

    constructor() {}

    ngOnInit() {}

}
