<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button class="btn-close float-end" type="button" aria-label="Close" (click)="cancel()"></button>
</div>
<div class="modal-body">
  <img data-src={{data}} class="img-fluid" alt="Responsive image">
</div>
<div class="modal-footer">
  <button id="save" class="btn btn-primary mb-4" type="button" (click)="downloadImage()" translate>DOWNLOAD</button>
</div>
