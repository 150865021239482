  <!--
  <ngx-loading [show]="showSpinner">
</ngx-loading>
-->
<h1 class="mb-4" id="banner-title" translate>ADD_BLOCKS</h1>
<div *ngIf="workflow">
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" translate>DASHBOARD</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/workflows" translate>LIBRARY_OF_WORKFLOWS</a>
      </li>
      <li class="breadcrumb-item" [ngClass]="{'active': workflow.id == undefined}">
        <a [routerLink]="['/workflows', workflow.id]">
          <dna-translate [fieldToTranslate]="workflow.name"> </dna-translate>
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>ADD_BLOCKS</li>
    </ol>
  </nav>
  <div class="block">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5 translate>BLOCKS</h5>
      </div>
      <div class="ibox-content">
        <dna-search-text [data]="searchText" (onSearch)="updateSearchText($event)">
        </dna-search-text>
        <dna-button-display-only logo="fa fa-star" [value]="displayFavoriteOnly" (onClick)="updateDisplayFavorites()">
        </dna-button-display-only>
        <div class="d-flex flex-row justify-content-end gap-1 mb-3">
          <button class="btn btn-primary" [disabled]="idBlocksCheck.length == 0" type="submit"
            (click)="addBlocksInWorkflow()" translate>ADD_BLOCKS</button>
          <button class="btn btn-default" type="button" (click)="returnBack()" translate>CANCEL</button>
        </div>

        <div class="table-responsive">
          <table class="table table-borderless-th dataTable">
            <thead>
              <tr>
                <th
                  [ngClass]="{'sorting' : orderedBy !== 'name', 'sorting_asc' : orderedBy === 'name' && !reverse, 'sorting_desc' : orderedBy === 'name' && reverse}"
                  (click)="onSortByChanged('name')" translate> NAME </th>
                <th translate>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let block of myBlocks">
                <td>
                  <dna-translate [fieldToTranslate]="block.name"> </dna-translate>
                </td>
                <td>
                  <dna-favorites *ngIf="state.BLOCKS" id="itemfav" class="pull-right" [type]='state.BLOCKS'
                    [id]='block.id'></dna-favorites>
                  <dna-switch [isSelected]="checkIfBlockSelected(block)" (onBooleanChanged)="updateListIdBlocks(block)"></dna-switch>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
