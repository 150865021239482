import { OptionType } from "../../enums";
import { ChartOption } from "../../interfaces/chart-options.interface";
import { Chart } from "../chart.model";
import { KineticOptions } from "./models";

export class KineticChartOptions {

    public options: KineticOptions = {
        general: {
            label: null,
            options: []
        },
        xAxis: {
            label: null,
            options: []
        },
        yAxis: {
            label: null,
            options: []
        },
        series: {
            label: null,
            options: []
        },
        plotOptions: {
            label: null,
            options: []
        }
    }

    public callback: Function;
    constructor(options, callback) {
        this.callback = callback;
        this.setGeneralOptions(options);
        this.setXAxisOptions(options)
        this.setYAxisOptions(options);
        this.setSeriesOptions(options);
        this.setPlotOptionOptions(options);
    }

    public getParametersFromOptions = () => {
        const payload: any = {
            general : {},
            specifics : {}
        };

        Object.assign(payload.general,      this.getGeneralParameterFromOptions());
        Object.assign(payload.specifics,    this.getAxisParameterFromOptions('xAxis'));
        Object.assign(payload.specifics,    this.getAxisParameterFromOptions('yAxis'));
        Object.assign(payload.specifics,    this.getSeriesParametersFromOptions());
        Object.assign(payload.specifics,    this.getPlotOptionsParametersFromOptions());

        return payload;
    }

    private setGeneralOptions(options) {
        this.options.general.label = (options.label || 'VIZUALIZATIONS.GENERAL');
        try { 
            this.options.general.options.push(
                { 
                    label : 'VIZUALIZATIONS.CHART_TITLE', 
                    key: "chart_title", 
                    value : (options.title.text || null), 
                    type : OptionType.Text,  
                    originalObject : options.title 
                }
            );
        } catch(e) {
            console.warn('error in title option creation, fallback to default');
            this.options.general.options.push(
                { 
                    label : 'VIZUALIZATIONS.CHART_TITLE', 
                    key: 'chart_title', 
                    value : null, 
                    type : OptionType.Text,  
                    originalObject : options.title || {}
                }
            );
        }

        try { 
            this.options.general.options.push(
                { 
                    label : 'VIZUALIZATIONS.CHART_SUBTITLE',
                    key: 'chart_subtitle', 
                    value : (options.subtitle.text || null), 
                    type : OptionType.Text,  
                    originalObject : options.subtitle 
                }
            );
        } catch(e) {
            console.warn('error in subtitle option creation, fallback to default');
            this.options.general.options.push(
                { 
                    label : 'VIZUALIZATIONS.CHART_SUBTITLE', 
                    key: 'chart_subtitle', 
                    value : null, 
                    type : OptionType.Text,  
                    originalObject : options.subtitle 
                }
            );
        }

        try {
            this.options[ 'general' ].options.push( { 
                label: 'Chart text size',
                key: 'chart_font_size',
                value: 11,
                type: OptionType.Number,
            })
        } catch( e ) {
            console.error( 'error when creating font size option' );
        }
        try {
            this.options[ 'general' ].options.push( {
                label: 'Chart text bold',
                key: 'chart_font_bold',
                value: false,
                type: OptionType.Checkbox,
            })
        } catch( e ) {
            console.error( 'error when creating font bold option' );
        }
    }

    private setXAxisOptions(options) {
        this.options.xAxis.label = (options.xAxis.label || 'VIZUALIZATIONS.X_AXIS');
        try { this.options.xAxis.options.push({ label : (options.xAxis.title.label || 'VIZUALIZATIONS.X_AXIS_LABEL'), key: 'x_axis_label', value : (options.xAxis.title.text || null), type : OptionType.Text }); }
        catch(e) { this.options.xAxis.options.push({ label : 'VIZUALIZATIONS.X_AXIS_LABEL', key: 'x_axis_label', value : null, type : OptionType.Text }); }
    }

    private setYAxisOptions(options) {
        this.options.yAxis.label = ((options.xAxis && options.xAxis.label) || 'VIZUALIZATIONS.Y_AXIS');
        try { 
            this.options.yAxis.options.push(
            { 
                label : ((options.yAxis && options.yAxis.title && options.yAxis.title.label )|| 'VIZUALIZATIONS.Y_AXIS_LABEL'), 
                key: 'y_axis_label', 
                value : ((options.yAxis && options.yAxis.title && options.yAxis.title.text) || null), 
                type : OptionType.Text,
            },
            {
                label : 'CHARTS.MIN_SCALE_LABEL', 
                key: 'y_scale_min', 
                value : ((options.yAxis && options.yAxis.min) || 0), 
                type : OptionType.Number 
            },{
                label : 'CHARTS.MAX_SCALE_LABEL', 
                key: 'y_scale_max', 
                value : ((options.yAxis && options.yAxis.max) || 0), 
                type : OptionType.Number 
            }); 
        }
        catch(e) { 
            this.options.yAxis.options.push({ 
                label : 'VIZUALIZATIONS.Y_AXIS_LABEL', 
                key: 'y_axis_label', 
                value : null, 
                type : OptionType.Text 
            },
            {
                label : 'CHARTS.MIN_SCALE_LABEL', 
                key: 'y_scale_min', 
                value :null, 
                type : OptionType.Number 
            },{
                label : 'CHARTS.MAX_SCALE_LABEL', 
                key: 'y_scale_max', 
                value : null, 
                type : OptionType.Number 
            }
            );}
    }

    private setSeriesOptions(options) {
        this.options.series.label = (options.series && options.series.label || 'VIZUALIZATIONS.SERIES');
        try {
            this.options.series.options = options.series.reduce(( accumulator : Array<ChartOption>, serie : any, index : number) => {
                accumulator.push({ label : (serie.name || index.toString()), value : (serie.color || Chart.colorScale[index % Chart.colorScale.length]), type : OptionType.Color, key: `color_${ index.toString() }`});
                return accumulator;
            }, []);
        } catch(e) {}
    }

    private setPlotOptionOptions(options) {
        this.options.plotOptions.label = (options.plotOptions && options.plotOptions.label || 'VIZUALIZATIONS.PLOT_OPTIONS');
        if( options.plotOptions && options.plotOptions.hasOwnProperty('attributes_blocks') ) {
            try {
                this.options.plotOptions.options.push({ label : 'VIZUALIZATIONS.SHOW_ATTRIBUTES_BLOCKS', key: 'show_attributes_blocks', value: (options.plotOptions.attributes_blocks.enabled || false), type: OptionType.Checkbox});
            }
            catch (e) { 
                this.options.plotOptions.options.push({ label: 'VIZUALIZATIONS.SHOW_ATTRIBUTES_BLOCKS', key: 'show_attributes_blocks', value: false, type: OptionType.Checkbox }); 
            }
        }

        if (options.plotOptions && options.plotOptions.hasOwnProperty('default_language')) {
            this.options.plotOptions.options.push(
                {
                    label: 'VIZUALIZATIONS.SHOW_DEFAULT_LANGUAGE',
                    key: 'show_default_language',
                    value: (options.plotOptions.default_language.enabled || false),
                    type: OptionType.Checkbox
                }
            );
        };
    }

    private getGeneralParameterFromOptions = () => {
        return {
            title : { 
                translations: {
                    ...this.options.general.options[0].originalObject.translations,
                    user: this.options.general.options[0].value
                }
            },
            subtitle : {
                translations: {
                    ...this.options.general.options[1].originalObject.translations,
                    user: this.options.general.options[1].value
                }
            },
            font: {
                size: this.options.general.options[ 2 ].value,
                bold: this.options.general.options[ 3 ].value,
            }
        };
    }

    private getAxisParameterFromOptions = (axis : string) : any => {
        let payload = {};
        payload[axis] = {title : { text : this.options[axis].options[0].value }};
        if(axis === "yAxis"){
            payload[axis] = {
                min: Number(this.options[axis].options[1].value),
                max: Number(this.options[axis].options[2].value)
            }
        }
        return payload
    };

    private getSeriesParametersFromOptions = () : any => {
        return {
            series : this.options.series.options.map(( serie : any ) => {
                return {
                    name : serie.label,
                    color : serie.value
                };
            })
        }
    };

    private getPlotOptionsParametersFromOptions = () : any => {
        const payload = {
            plotOptions : {
                series : {
                    dataLabels : {
                        enabled : (this.options.plotOptions.options.find(x => x.key === 'show_data_label') ? this.options.plotOptions.options.find(x => x.key === 'show_data_label').value : false)
                    }
                }
            }
        }

        if( this.options.hasOwnProperty('plotOptions') && this.options.plotOptions.options.find(x => x.key === 'show_grey_zone')) {
            payload.plotOptions['grey_zone'] = { enabled : (this.options.plotOptions.options.find(x => x.key === 'show_grey_zone').value || false) };
        }
        if( this.options.hasOwnProperty('plotOptions') && this.options.plotOptions.options.find(x => x.key === 'show_attributes_blocks')) {
            payload.plotOptions['attributes_blocks'] = { enabled : (this.options.plotOptions.options.find(x => x.key === 'show_attributes_blocks').value || false) };
        }
        if (this.options.hasOwnProperty('plotOptions') && this.options.plotOptions.options.find(x => x.key === 'show_default_language')) {
            payload.plotOptions['default_language'] = { enabled: (this.options.plotOptions.options.find(x => x.key === 'show_default_language').value || false) };
        }
        if (this.options.hasOwnProperty('plotOptions') && this.options.plotOptions.options.find(x => x.key === 'show_column_zero')) {
            payload.plotOptions['show_column_zero'] = { enabled: (this.options.plotOptions.options.find(x => x.key === 'show_column_zero').value) };
        }
        return payload;
    };
}