<td *ngIf="panelist.subclass === 'study' || panelist.subclass === 'visit'; else notStudy"> 
    <span (click)="onClickTypeFiche()" ngbTooltip="{{ panelist.text }}">{{ truncateStudyName(panelist.text, panelist.visitId) }}</span>
</td>
<ng-template #notStudy>
    <td><span (click)="onClickTypeFiche()" translate>{{ volunteerService.getTradFiche(panelist.subclass) }}</span></td>
</ng-template>
<td>{{ panelist.created_on | date:'yyyy-MM-dd'}}</td>
<td>{{ panelist.requestNumber}}</td>
<td>{{ panelist.studyNumber }}</td>
<td><span (click)="onClickPl()">{{panelist.panelistNumber}}</span></td>
<td>{{ panelist.firstName}}</td>
<td>{{ panelist.lastName}}</td>
<td>{{ panelist.orchestra}}</td>
<td>{{ panelist.laboratory}}</td>
