import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HubsById, HubById } from '../../../../../../app/types';
import { ReferenceTypeService } from '../../../../../../app/shared/services/reference-type.service';
import { UserService } from '../../../../../../app/shared/services/user.service';
import { HttpRestService } from '../../../../../../app/shared/services/httpRest.service';
import { catchError, tap, takeUntil } from 'rxjs/operators';
import { throwError as observableThrowError, Subject } from 'rxjs';
import { ErrorManagerService } from '../../../../../../app/shared/services/errorManager.service';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-add-arcs-modal',
  templateUrl: './add-arcs-modal.component.html',
  styleUrls: ['./add-arcs-modal.component.less']
})
export class AddArcsModalComponent implements OnInit, AfterViewInit {

  @Input() title: string;
  @Input() arcsNumbers: string[];
  arcsNumber: string;
  submitted: boolean = false;
  countries: HubsById[];
  arcsSystem: HubById;
  arcsIncorrect: boolean = false;
  arcsLoading: boolean = false;
  isUpdatingArcs: boolean = false;
  arcsExists: boolean = false;
  initTime = performance.now();

  constructor(
    private activeModal: NgbActiveModal,
    private referenceType: ReferenceTypeService,
    private userService: UserService,
    private httpRestService: HttpRestService,
    private errorManager: ErrorManagerService,
    private aiService: ApplicationInsightsService
  ) { }

  destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnInit() {
    this.countries = this.referenceType.getHubsById();
    this.arcsSystem = this.initArcsSystem();
  }

  ngAfterViewInit() {
    this.aiService.logPageView('Add ARCS Modal', '', performance.now() - this.initTime, 'Modals');
  }

  initArcsSystem(): HubsById {
    const hub = this.userService.getUserHubById();
    return hub ? hub : HubsById.France;
  }

  cancel() {
      this.activeModal.dismiss();
  }

  updateArcsInProject(arcsNumber: string, arcsSystem: HubById) {
    if (arcsNumber) {
      if (this.arcsNumbers.includes(arcsNumber)) {
        this.arcsIncorrect = false;
        this.arcsExists = true;
      } else {
        this.arcsIncorrect = false;
        this.arcsLoading = true;
        this.isUpdatingArcs = true;

        this.httpRestService.getArcsInformation(arcsSystem, arcsNumber).pipe(
          catchError(err => {
            this.arcsIncorrect = true;
            this.arcsLoading = false;
            this.isUpdatingArcs = false;
            return observableThrowError(err);
          }),
          tap(users => {
            this.activeModal.close({arcsNumber: this.arcsNumber, users: users.list});
            this.arcsLoading = false;
          }),
          tap(() => this.errorManager.displayMessage('ON_SUCCESS_UPDATE_ACTIVIEW_ARCS_BRIDGE')),
          takeUntil(this.destroy$),)
          .subscribe();
      }
    } else {
      this.arcsExists = false;
      this.arcsIncorrect = true;
    }
  }

}
