import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { DNAObject } from '../../types';
import { HttpRestService } from '../services/httpRest.service';
import { UserService } from '../services/user.service';

@Component({
    selector: 'dna-favorites',
    templateUrl: './dnaFavorites.component.html',
    styleUrls: ['./dnaFavorites.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class FavoritesComponent implements OnInit {

    @Input() id: string;
    @Input() isButton: boolean = false;
    @Input() type: string;

    isFavorite: boolean;

    constructor(
        private httpRestService: HttpRestService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.isFavorite = this.checkIsFavorite(this.id);
    }

    addOrRemoveFavorite(id: string, typeState: string) {
        let user = this.userService.getUser();
        this.isFavorite = !this.isFavorite;
        this.userService.updateFavorite(id, typeState);
        this.httpRestService.addOrRemoveFavorite(user, id, typeState).subscribe();
    }

    checkIsFavorite(id: string) {
        return this.userService.isFavorite(id);
    }

}