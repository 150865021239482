import { Component, Input, Output, EventEmitter} from "@angular/core";
import { VolunteerService } from "../../volunteers.service";

@Component({
    selector: '[dna-panelist-row]',
    templateUrl: './panelist-row.component.html',
    styleUrls: ['./panelist-row.component.less']
  })
  export class PanelistRowComponent {

    @Input() panelist: any;
  
    @Output() onClickPanelist: EventEmitter<any> = new EventEmitter();

    @Output() onClickType: EventEmitter<any> = new EventEmitter();

    constructor(private volunteerService: VolunteerService) { }
    
    onClickPl() {
      this.onClickPanelist.emit();
    }

    onClickTypeFiche() {
      this.onClickType.emit();
    }

    truncateStudyName(studyName: string, isVisit: boolean) {
      const nbChar = isVisit ? 18 : 23
      if (studyName.length > 25) {
        return studyName.substring(0,nbChar) + '...';
      }
      return studyName;
    }
  }