import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dna-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent implements OnInit {
  objectArray: Array<string> = ['BLOCK', 'WORKFLOW'];
  objectType: string = 'BLOCK';

  constructor() { }

  ngOnInit() {
  }

}
