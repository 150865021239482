import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef, AfterViewInit, OnInit } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'dna-chart-sm-table',
  templateUrl: './chart-sm-table.component.html',
  styleUrls: ['./chart-sm-table.component.scss']
})
export class ChartSmTableComponent implements OnInit, AfterViewInit {
  @Input() dataTable: Array<object>;

  constructor(private ref: ChangeDetectorRef ) { };

  ngOnInit() { this.ref.detectChanges() };
  ngAfterViewInit() { this.ref.detectChanges() };

};
