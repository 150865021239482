import { Component, OnInit } from '@angular/core';

import { User } from '../../../types';
import { UserService } from '../../../shared/services/user.service';

@Component({
    selector: 'dna-user-profile',
    templateUrl: './user-profile.component.html'
})

export class UserProfileComponent implements OnInit {

    user: User;

    constructor(
        private userService: UserService
    ) { 
        this.user = this.userService.getUser();
    }

    ngOnInit() {}
}
