import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import * as _ from 'lodash';

import { GroupedFormula, SimComp } from '../../../../../types';

@Component({
  selector: 'dna-simultaneous-comparison',
  templateUrl: './simultaneous-comparison.component.html',
  styleUrls: ['./simultaneous-comparison.component.less']
})
export class SimultaneousComparisonComponent implements OnInit {

  @Input() couples: SimComp[] = [];
  @Input() campaignPublished: boolean;

  @Output() onCoupleChange: EventEmitter<SimComp[]> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  compareFormula(formulaA: GroupedFormula, formulaB: GroupedFormula): boolean {
    return formulaA && formulaB && formulaA.id === formulaB.id;
  }

  onChangeBench(index: number, bench: GroupedFormula) {
    this.couples[index].bench = bench;
    this.couples[index].lab = _.cloneDeep(this.couples[index].formula).splice(this.couples[index].formula.findIndex(f => f.id !== bench.id) ,1);
    this.couples[index].name = this.sortCouplesNames(this.couples[index].formula, bench);
    this.onCoupleChange.emit(this.couples);
  }

  private sortCouplesNames(couplesFormula: GroupedFormula[], bench: GroupedFormula) {
    const tabNames = [];
    couplesFormula.forEach(cf => {
      if(cf.name !== bench.name) tabNames.push(cf.name);
    });
    couplesFormula.length === 2 ? tabNames.unshift(bench.name) : tabNames.splice(1, 0, bench.name);
    return tabNames.join(' - ');
  }

  onChangeIsActive(index: number, isActive: boolean) {
    this.couples[index].isActive = isActive;
    this.onCoupleChange.emit(this.couples);
  }

}
