import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'dna-anonymous-formula',
  templateUrl: './anonymous-formula.component.html',
  styleUrls: ['./anonymous-formula.component.less']
})
export class AnonymousFormulaComponent implements OnInit {

  @Input() anonymousCode = {};
  @Input() campaignPublished: boolean = false;

  @Output() onAnonymousCodeChange: EventEmitter<object> = new EventEmitter();

  subject: Subject<{key: string, value: string}> = new Subject();

  constructor() { }

  ngOnInit() {
    this.subject
      .pipe(
        debounceTime(1000),
        tap(data => this.anonymousCode[data.key] = data.value),
        tap(() => this.onAnonymousCodeChange.emit(this.anonymousCode))
      ).subscribe()
  }

  onChangeCode(key: string, value: string) {
    this.subject.next({key, value})
  }
}
