import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit, Output,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { CallbackModalEnum } from './callbackModalEnum';
import {ICustomButtons} from './ICustomButtons';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-dna-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['custom-modal.component.less']
})

/**
 * A component that provide ready-made method to create a customized modal from the bootstrap modal service
 *
 * Creating a modal by providing the elements you want to have:
 *
 *   A title -> title?:string;
 *   A message -> message?: string;
 *   A set of customButtons -> customButtons?: ICustomButtons[];
 *   A component instance -> bodyComponent?: TypeCore<any>;
 *   A set of inputs for your component -> bodyComponentInputs?: {};
 *   A set of outputs for your component -> bodyComponentOutputs?: {};
 *
 */
export class CustomModalComponent implements OnInit {

  @ViewChild('contentPlaceholder', { static: true, read: ViewContainerRef }) contentPlaceholder: ViewContainerRef;
  @Input() private _message = '';
  @Input() private _title = '';
  @Input() private _customButtons: ICustomButtons[];
  @Input() private _bodyComponent: Type<any>;
  @Input() private _bodyComponentInputs: Record<string, any> = {};
  @Input() private _bodyComponentOutputs: Record<string, any> = [];

  @Output() private _outputEvent$: EventEmitter<{ eventName: string, value?: any }> = new EventEmitter<{ eventName: string, value: any }>();

  constructor(private _activeModal: NgbActiveModal, private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this._bodyComponent && this.contentPlaceholder) {
      this.loadBodyComponent();
    }
  }

  /**
   * Dismisses the modal.
   *
   * The promise will send back the keyword 'dismissed'
   */
  public cancel(): void {
    this._activeModal.close(CallbackModalEnum.dismissed);
  }

  /**
   * Closes the modal and send back the optional provided event string, optionally you can set closeOnValidate to false,
   * it won't close it and will emit an event through the outputEvent property
   *
   * The promise will be resolved with the provided value.
   */
  public validate(event: string, closeOnValidate: boolean = true): void {
    if (closeOnValidate) {
      this._activeModal.close(event);
    } else {
      this._outputEvent$.emit({eventName: event});
    }
  }

  /**
   * Return the list of custom button provided which include : name, event, style
   */
  public get customButtons(): ICustomButtons[] {
    return this._customButtons;
  }

  /**
   * Set the list of custom button provided which include : name, event, style
   */
  public set customButtons(value: ICustomButtons[]) {
    this._customButtons = value;
  }

  /**
   * Return an optional message which will be displayed in the body, before the optional component
   */
  public get message(): string {
    return this._message;
  }

  /**
   * Set an optional message which will be displayed in the body, before the optional component
   */
  public set message(value: string) {
    this._message = value;
  }

  /**
   * Return an optional title for the modal
   */
  public get title(): string {
    return this._title;
  }

  /**
   * Set an optional title for the modal
   */
  public set title(value: string) {
    this._title = value;
  }

  /**
   * Return a list of component key /value inputs which will feed the provided component instance
   */
  public get bodyComponentInputs(): Record<string, any> {
    return this._bodyComponentInputs;
  }

  /**
   * Set a key / value list of component inputs which will feed the provided component instance
   */
  public set bodyComponentInputs(value: Record<string, any>) {
    this._bodyComponentInputs = value;
  }

  /**
   * Set a list of component outputs which will be subscribed and forwarded to an output event emitter
   */
  public get bodyComponentOutputs(): Record<string, any> {
    return this._bodyComponentOutputs;
  }

  /**
   * Get a list of component outputs which will be subscribed and forwarded to an output event emitter
   */
  public set bodyComponentOutputs(value: Record<string, any>) {
    this._bodyComponentOutputs = value;
  }

  /**
   * Return the provided component instance
   */
  public get bodyComponent(): Type<any> {
    return this._bodyComponent;
  }

  /**
   * Set the provided component instance
   */
  public set bodyComponent(value: Type<any>) {
    this._bodyComponent = value;
  }

  /**
   * Get the output event which will subscribe to any output your inner component has
   */
  public get outputEvent$(): EventEmitter<{ eventName: string; value?: any }> {
    return this._outputEvent$;
  }

  /**
   * Load the provided component and optionally :
   *  - Dynamically feed him with provided inputs
   *  - Dynamically subscribe to any output even in the provided outputs and forward this event through the outputEvent property
   */
  public loadBodyComponent() {
    this.contentPlaceholder.clear();
    const newComponent = this.contentPlaceholder.createComponent(this._bodyComponent);
    if (this._bodyComponentInputs) {
      Object.entries(this._bodyComponentInputs).forEach(([key, value]: [string, any]) => {
        newComponent.instance[key] = value;
      });
    }
    if (this._bodyComponentOutputs) {
      this._bodyComponentOutputs.forEach((key: string) => {
        newComponent.instance[key].subscribe((value) => {
          this._outputEvent$.emit({eventName: key, value});
        });
      });
    }
  }
}
