import { tap, finalize, catchError } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as _ from 'lodash';
import {
  DragulaService, DragulaOptions
} from 'ng2-dragula';
import {
  NgbModal, NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import {
  Block,
  DataVizualisation,
  Descriptor,
  DNAComponent,
  Graph,
  LogicJump,
  States,
  User,
  Workflow,
  JsonPatch,
  Etags
} from '../../../types';
import {
  BlockService
} from '../../../blocks/blocks.service';
import {
  CampaignService
} from '../../../campaigns/campaigns.service';
import {
  DNATranslateService
} from '../../../shared/services/translate.service';
import { environment } from '../../../../environments/environment';
import {
  ErrorManagerService
} from '../../../shared/services/errorManager.service';
import {
  LogicJumpsModalComponent
} from '../logicJumps/logicJumpsModal.component';
import {
  UserService
} from '../../../shared/services/user.service';
import {
  UtilService
} from '../../../shared/services/util.service';
import {
  WorkflowService
} from '../../workflows.service';
import { CreateWorkflowModalComponent } from '../../list/create-workflow/create-workflow-modal.component';
import { of } from 'rxjs';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-workflow-detail',
  templateUrl: './workflow-detail.component.html',
  styleUrls: ['./workflow-detail.component.less']
})
export class WorkflowDetailComponent implements OnInit, AfterViewInit {

  blocks: Block[] = [];
  blockState: any = {};
  currentLanguage: string;
  errorApply: string;
  errorApplyAll: string;
  idDragula: string;
  isDatavizChange: boolean = false;
  isWkChanged: boolean = false;
  languages: string[] = [];
  mobileUrl: string;
  showSpinner: boolean = true;
  user: User;
  workflow: Workflow = new Workflow();
  workflowStates: typeof States = States;
  isCollapsedWorkflowName: boolean = true;
  isPageBlocks: boolean = false;
  initTime = performance.now();

  constructor(
    private blockService: BlockService,
    private campaignService: CampaignService,
    private DNATranslate: DNATranslateService,
    private dragulaService: DragulaService,
    private errorManager: ErrorManagerService,
    private modalService: NgbModal,
    private aiService: ApplicationInsightsService,
    private route: ActivatedRoute,
    private router: Router,
    private errorManagerService: ErrorManagerService,
    private userService: UserService,
    private utilService: UtilService,
    private workflowService: WorkflowService
  ) {
    dragulaService.dropModel(this.idDragula).subscribe(() => {
      this.onDropModel();
    });

    this.idDragula = this.utilService.generateRandomID();
    const dragulaOptions: DragulaOptions = {
      copy: (el: Element, source: Element) => {
        return el.className.indexOf('copy-allowed') !== -1;
      },
      copyItem: (item: Block) => _.cloneDeep(item),
      accepts: (el: Element, target: Element, source: Element, sibling: Element) => {
        return target.id !== 'source' && !this.isPublished();
      }
    };
    dragulaService.createGroup(this.idDragula, dragulaOptions);
    //Below line enables angular router to navigate again on the same page
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Workflow Details', '', performance.now() - this.initTime, templateUrl);
  }

  ngOnInit() {
    this.showSpinner = true;
    this.mobileUrl = environment.mobile_url() + '/#';
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.user = this.userService.getUser();

    this.DNATranslate.getLanguages().then(
      request => this.languages = request
    );

    this.DNATranslate.onLangChange().subscribe((translation: any) => {
      this.currentLanguage = translation.lang;
    });

    this.userService.onUserChanged().subscribe((user: User) => {
      this.user = this.userService.getUser();
    });

    this.workflow = this.workflowService.workflow;
    this.isWkChanged = !_.isEqual(this.workflow, this.workflowService.getWorkflowUntouched());

    this.initWK(this.workflow);


    if (this.workflow.state === States.Published) {
      this.errorManager.displayMessage('WORKFLOW_IS_PUBLISHED', 'info');
    }

    this.blocks = this.workflowService.blocks;

    this.showSpinner = false;
  }

  onChange(data) {
    this.isWkChanged = true;
    const updatedWorkflow = {
      ...this.workflowService.workflow,
      hub: data.hub,
      name: data.name,
      description: data.description
    };
    this.workflow = updatedWorkflow;
    this.workflowService.setWorkflowLocal(updatedWorkflow);
  }

  addOrRemoveMandatory(block: Block) {
    if (this.workflow.state !== States.Published) {
      this.isWkChanged = true;
      this.workflowService.toggleMandatoryBlock(block.index);
      this.workflow = this.workflowService.workflow;
    } else {
      this.errorManager.displayMessage('WORKFLOW_IS_PUBLISHED', 'info');
    }
  }

  cancelEditWF() {
    const workflowUntouched = _.cloneDeep(this.workflowService.getWorkflowUntouched());
    this.workflow = _.cloneDeep(this.workflowService.getWorkflowUntouched());
    this.workflowService.setWorkflowLocal(workflowUntouched);
    this.workflowService.updateName(workflowUntouched);
    this.workflowService.clearBlocksToDelete();
    this.isWkChanged = false;
  }

  cancelPublish() {
    for (let block of this.workflow.blocks) {
      if (block.outOfDate) {
        this.errorManager.displayMessage('BLOCKS_NOT_UPDATED', 'danger');
        return false;
      }
    }
    return true;
  }

  checkidInQuestionnaire(blocks: Block[], block: Block): string {
    if (!block.idInQuestionnaire) {
      let id;
      do {
        id = this.utilService.generateRandomID();
      }
      while (!this.utilService.checkUnicityData(blocks, 'idInQuestionnaire', id))
      block.idInQuestionnaire = id;
      return id;
    } else {
      return block.idInQuestionnaire;
    }
  }

  editBlockConfiguration(block: Block) {
    this.isPageBlocks = true;
    this.workflowService.setBlockToEdit(block);
    this.router.navigate(['workflows', this.workflow.id, 'editBlock', block.id]);
  }

  editGraph() {
    this.router.navigate(['workflows', this.workflow.id, 'graph']);
  }

  getIDsFromWorkflow(workflow: Workflow): string[][] {
    let idsBlock: string[] = [], idsInBlock: string[] = [], idsInWorkflow: string[] = []
    this.workflow.blocks.forEach(
      (block: Block) => {
        idsBlock.push(block.id);
        idsInWorkflow.push(block.idInQuestionnaire);
        block.components.forEach((component: DNAComponent) => idsInBlock.push(component.idInBlock));
      }
    );

    return [idsBlock, idsInBlock, idsInWorkflow];
  }

  idsAreSynchronised(workflow: Workflow): boolean {
    const ids = this.getIDsFromWorkflow(workflow);
    return this.idsFromDescriptorAndWorkflowMatch(workflow, ids[0], ids[1], ids[2]);
  }

  idsFromDescriptorAndWorkflowMatch(workflow: Workflow, idsBlock: string[], idsInBlock: string[], idsInWorkflow: string[]): boolean {
    if (!workflow.graphs) return true;
    return workflow.graphs.every(
      (graph: Graph) =>
        graph.descriptors.filter(d => !d.isGroup).every(
          (descriptor: Descriptor) =>
            idsBlock.some((id: string) => id === descriptor.idBlock) &&
            idsInBlock.some((id: string) => id === descriptor.idInBlock) &&
            idsInWorkflow.some((id: string) => id === descriptor.idInQuestionnaire)
        )
        && graph.descriptors.filter(d => d.isGroup).every((dg: Descriptor) =>
          dg.listDescriptors.every(
            (descriptor: Descriptor) =>
              idsBlock.some((id: string) => id === descriptor.idBlock) &&
              idsInBlock.some((id: string) => id === descriptor.idInBlock) &&
              idsInWorkflow.some((id: string) => id === descriptor.idInQuestionnaire)
          )
        )
    );
  }

  initWK(workflow: Workflow) {
    workflow.blocks.map((b: Block) => this.checkidInQuestionnaire(this.workflow.blocks, b));
  }

  isPublished() {
    return this.workflow && this.workflow.state === States.Published;
  }

  onDropModel() {
    this.isWkChanged = true;
    this.workflow.blocks = this.workflow.blocks.map((b, i) => {
      b = <Block>_.omitBy(b, (value, key) => key[0] === '_');
      this.checkidInQuestionnaire(this.workflow.blocks, b);
      b.index = i;
      return b;
    });
  }

  openLogicJumpModal(block: Block) {
    const modalRef = this.modalService.open(LogicJumpsModalComponent, {
      backdrop: 'static',
      size: 'lg'
    });
    modalRef.componentInstance.workflow = this.workflow;
    modalRef.componentInstance.currentBlock = block;
    let logicJumps = _.cloneDeep(block.parameters.logicJumps);
    modalRef.componentInstance.logicJumps = logicJumps ? logicJumps : [];

    modalRef.result.then(
      (logicJumps: LogicJump[]) => {
        block.parameters.logicJumps = logicJumps;
        this.isWkChanged = true;
      },
      (reason) => { }
    );
  }

  preview() {
    if (this.isWkChanged) {
      this.errorManager.displayMessage('SAVE_MODIFICATION_BEFORE_CONTINUING', "default");
    } else {
      this.router.navigate(['workflows', this.workflow.id, 'preview']);
    }

  }
  publishWF() {
    this.showSpinner = true;
    return this.workflowService.publishWorkflow(this.workflow.id).pipe(
      catchError(this.catchError),
      tap((workflow: Workflow) => {
        const updatedWorkflow = {
          ...workflow,
          blocks: this.workflow.blocks
        };
        this.workflow = updatedWorkflow;
        this.workflowService.setWorkflowLocal(updatedWorkflow);
        this.workflowService.setWorkflowUntouched(updatedWorkflow);
      }),
      tap(() => this.errorManager.displayMessage('ON_SUCCESS_UPDATE')),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  removeFromList(block: Block) {
    if (this.workflow.state !== States.Published) {
      let updatedWorkflow: Workflow;
      this.isWkChanged = true;
      if (block.id) {
        updatedWorkflow = {
          ...this.workflow,
          blocks: this.workflow.blocks
              .sort((a, b) => a.index - b.index)
              .filter(wfBlock => wfBlock.id !== block.id)
              .map((currentBlock, currentIndex) => {
                  return {
                      ...currentBlock,
                      index: currentIndex
                  };
          })
        };
        this.workflowService.addBlockToDelete(block.id);
      } else {
        updatedWorkflow = {
          ...this.workflow,
          blocks: this.workflow.blocks
              .sort((a, b) => a.index - b.index)
              .filter(wfBlock => wfBlock.id !== block.id)
              .filter(wfBlock => wfBlock.index !== block.index).map((currentBlock, currentIndex) => {
              return {
                  ...currentBlock,
                  index: currentIndex
              };
          })
        };
      }
      this.workflowService.setWorkflowLocal(updatedWorkflow);
      this.workflow = updatedWorkflow;
    }
  }

  saveWorkflow() {
    if (this.workflow.graphs) {
      this.workflow = this.updateGraphsDescriptors(_.cloneDeep(this.workflow));
    } else {
      this.workflow.graphs = [];
    }
    if (this.verifData(this.workflow) && this.cancelPublish()) {
      this.updateWF();
    }
  }

  updateGraphsDescriptors(workflow) {
    const allComponents = workflow.blocks.reduce((acc1, curr1: Block) => {
      return acc1.concat(
        curr1.components.reduce((acc2, curr2: DNAComponent) => {
          return acc2.concat({
            name: curr1.name,
            idInBlock: curr2.idInBlock,
            idInQuestionnaire: curr1.idInQuestionnaire
          });
        }, [])
      );
    }, []);

    workflow.graphs.forEach(graph => {
      let listDescriptors = [];
      graph.descriptors.filter(d => !d.isGroup).forEach(d => {
        if (!_.isUndefined(allComponents.find(c => c.idInBlock === d.idInBlock && c.idInQuestionnaire === d.idInQuestionnaire))) {
          listDescriptors.push(d);
        }
      });
      graph.descriptors.filter(d => d.isGroup).forEach((dg: Descriptor) => {
        dg.listDescriptors = dg.listDescriptors.filter(d => {
          return !_.isUndefined(allComponents.find(c => c.idInBlock === d.idInBlock && c.idInQuestionnaire === d.idInQuestionnaire))
        });
        listDescriptors.push(dg);
      });
      graph.descriptors = _.cloneDeep(listDescriptors);
      graph.descriptors.forEach((descriptor: Descriptor) => {
        if (descriptor.isGroup) {
          descriptor.listDescriptors.forEach((dg: Descriptor) => {
            dg.blockName = allComponents.find(c => c.idInBlock === dg.idInBlock && c.idInQuestionnaire === dg.idInQuestionnaire).name;
          });
        } else {
          descriptor.blockName = allComponents.find(c => c.idInBlock === descriptor.idInBlock && c.idInQuestionnaire === descriptor.idInQuestionnaire).name;
        }
      });
    });
    return workflow;
  }

  selectBlock() {
    this.isPageBlocks = true;
    this.router.navigate(['workflows', this.workflow.id, 'addBlocks']);
  }

  suspendWF() {
    this.showSpinner = true;
    return this.workflowService.suspendWorkflow(this.workflow.id).pipe(
      catchError(this.catchError),
      tap((workflow: Workflow) => {
        const updatedWorkflow = {
          ...workflow,
          blocks: this.workflow.blocks
        };
        this.workflow = updatedWorkflow;
        this.workflowService.setWorkflowLocal(updatedWorkflow);
        this.workflowService.setWorkflowUntouched(updatedWorkflow);
      }),
      tap(() => this.errorManager.displayMessage('ON_SUCCESS_UPDATE')),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  updateBlock(item: Block, keyBlock: number) {
    this.showSpinner = true;
    this.workflowService.updateBlockInWorkflow(this.workflow.id, item.id, item.referenceId).pipe(
      catchError(this.catchError),
      tap((result: Block) => {
        this.workflow.blocks[keyBlock] = result;
        this.workflowService.setWorkflowLocal(this.workflow);
        this.isWkChanged = true;
        this.updateGraphs(this.workflow.graphs, this.workflow.blocks[keyBlock]);
      }),
      finalize(() => {
        this.showSpinner = false;
      }))
      .subscribe();
  }

  updateGraphs(graphs: Graph[], block: Block) {
    if (graphs) {
      graphs.forEach(g => {
        g.descriptors.forEach(d => {
          if (d.isGroup) {
            d.listDescriptors.forEach(gd => {
              this.checkDescriptorName(gd, block);
            });
          } else {
            this.checkDescriptorName(d, block);
          }
        });
      });
    }
  }

  checkDescriptorName(descriptor: Descriptor, block: Block) {
    const component = block.components.find(c => block.id === descriptor.idBlock && block.idInQuestionnaire === descriptor.idInQuestionnaire && c.idInBlock === descriptor.idInBlock);
    if (component && !_.isEqual(component.args.label, descriptor.name)) {
      descriptor.name = component.args.label;
    }
  }

  updateWF() {
    this.showSpinner = true;
    this.workflowService.saveWorkflow(this.workflowService.workflow, this.workflowService.blocksToDelete).pipe(
      catchError((err) => {
        if (409 === _.get(err, 'status', 0)) {
          this.errorManager.displayMessage("ON_ERROR_ETAG", "danger");
          return of();
        } else {
          return this.catchError(err);
        }
      }),
      tap((workflow: Workflow) => this.workflow = workflow),
      tap((workflow: Workflow) => this.workflowService.setWorkflowLocal(workflow)),
      tap(() => this.workflowService.setWorkflowUntouched(this.workflow)),
      tap(() => this.workflowService.clearBlocksToDelete()),
      tap(() => {
        this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
        this.isWkChanged = false;
      }),
      finalize(() => {
        this.showSpinner = false;
      })
    ).subscribe();
  }

  verifData(workflow: Workflow) {
    if (!this.idsAreSynchronised(workflow)) {
      this.errorManager.displayMessage("ON_ERROR_IDS_NOT_SYNCHRONISED", "danger");
      return false;
    }
    if (_.isEmpty(workflow.blocks)) {
      this.errorManager.displayMessage('ON_ERROR_EMPTY_WORKFLOW', 'danger');
      return false;
    }
    if (!workflow.name.english) {
      this.errorManager.displayMessage('ON_ERROR_EMPTY_NAME', 'danger');
      return false;
    }
    return true;
  }

  catchError = (error) => {
    this.showSpinner = false;
    if (error.error == "name") {
      this.errorManager.displayMessage('NAME_ALREADY_TAKEN', 'danger')
    } else {
      this.errorManagerService.displayMessage("ON_ERROR_UPDATE", "danger");
    }
    return of();
  }

  changeIndexBlocks(event) {
    this.workflow.blocks = event;
    this.isWkChanged = true;
    const updatedBlocksOrder = event.map((block: Block, index: number) => {
      return {
        ...block,
        index
      };
    });
    this.workflowService.setWorkflowBlocks(updatedBlocksOrder);
    this.workflow = this.workflowService.workflow;
  }

  editBlocks() {
    this.isPageBlocks = true;
    this.router.navigate(['workflows', this.workflow.id, 'editBlocks']);
  }
}
