import { CampaignMultiDescriptor, Workflow } from './../../../../../types';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

@Component({
    selector: 'dna-modal-auto-complete-descriptor',
    templateUrl: './modalAutoCompleteDescriptor.component.html',
  })
  export class ModalAutoCompleteDescriptor implements OnInit {
    @Input() campaignMultiDescriptor: CampaignMultiDescriptor;
    workflows: (Pick<Workflow, "id" | "name"> & {campaignName: string, campaignId: string, idMulti: string})[];
    selectedWorkflow: Pick<Workflow, "id" | "name"> & {campaignName: string, campaignId: string, idMulti: string};
    pertinence: number = 80;
    
    constructor(public modal: NgbActiveModal) {
    }
    ngOnInit(): void {
        this.workflows = []; 
        this.campaignMultiDescriptor.studies.map(c => {
            c.workflows.map(wk => {
                this.workflows.push({
                    id: wk.id,
                    name: wk.name,
                    campaignId: c.id,
                    campaignName: c.name,
                    idMulti: c.id + '-' + wk.id
                })
            })
        })
        this.workflows = _.sortBy(this.workflows, "id");
        this.selectedWorkflow = this.workflows[0]; 
    }
  }