import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'dna-mandatory',
    template: '<span class="fa fa-asterisk mandatory"></span>'
})

export class MandatoryComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}
