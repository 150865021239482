
<form #activiewDataForm="ngForm" name="activiewDataForm" novalidate>
  <div class="modal-scroll">
    <div class="modal-header">
      <h3 class="text-center" translate>ACTIVIEW_DATA</h3>
    </div>
    <div class="modal-body">
    <ngx-loading [show]="showSpinner"></ngx-loading>
      <div class="ibox float-e-margins" *ngIf="!showSpinner || submitted">
        <div *ngIf="error">
          <dna-error-page (onClickReload)="ngOnInit()"></dna-error-page>
        </div>
        <div *ngIf="!error" class="ibox-content d-flex flex-column gap-3">
          <div class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label required">
                {{ "PROJECTS.PROJECT_NUMBER" | translate }}
              </label>
              <div class="col-12 col-md-9">
                <h4><span class="cursor-pointer link" (click)="goToActiview(activiewCampaign.code)">{{activiewCampaign.code}}</span></h4>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label required" [ngClass]="{ 'is-invalid': focusForm.invalid && (focusForm.touched || submitted) }">
                {{ "FOCUS" | translate }}
              </label>
              <div class="col-12 col-md-9">
                <select id="focus" class="form-control" #focusForm="ngModel" [(ngModel)]="activiewCampaign.focus"  name="focusForm" [compareWith]="comparebyId"
                [ngClass]="{ 'isinvalid': focusForm.invalid && (focusForm.touched || submitted) }" required>
                  <option *ngFor="let focus of opticalCodes" [ngValue]="focus" translate>{{ focus.name }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label required" [ngClass]="{ 'is-invalid': bridge.invalid && (bridge.touched || submitted) }">
                {{ "PROJECTS.PROJECT_BRIDGE" | translate }}
              </label>
              <div class="col-12 col-md-9">
                <input class="form-control" [ngClass]="{ 'is-invalid': bridge.invalid && (bridge.touched || submitted) }"
                  #bridge="ngModel" [(ngModel)]="activiewCampaign.synergy" type="text" name="bridge" [disabled]="true" required>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label required" [ngClass]="{ 'is-invalid': orchestra.invalid && (orchestra.touched || submitted) }">
                {{ "ORCHESTRA_NUMBER" | translate }}
              </label>
              <div class="col-12 col-md-9">
                <input class="form-control" [ngClass]="{ 'is-invalid': orchestra.invalid && (orchestra.touched || submitted) }"
                  #orchestra="ngModel" [(ngModel)]="activiewCampaign.orchestra_sub_project_code" type="text" name="orchestra" [disabled]="true" required>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label required" [ngClass]="{ 'is-invalid': studyType.invalid && (studyType.touched || submitted) }">
                {{ "PROTOCOL_STUDY_TYPE" | translate }}
              </label>
              <div class="col-12 col-md-9">
                <select id="studyType" class="form-control" #studyType="ngModel" 
              [(ngModel)]="activiewCampaign.study_type" name="studyType" 
              [compareWith]="compareCode" 
              [ngClass]="{ 'is-invalid': studyType.invalid && (studyType.touched || submitted) }" required>
                  <option *ngFor="let type of studyTypes" [ngValue]="type" translate>
                    {{ type.name[languageMap[currentLanguage]] || type.name['en'] }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label required" [ngClass]="{ 'is-invalid': estimatedContribution.invalid && (estimatedContribution.touched || submitted) }">
                {{ "ESTIMATED_CONTRIBUTION" | translate }}
              </label>
              <div class="col-12 col-md-9">
                <input class="form-control" type="number" min="0" step="0.5" [ngClass]="{ 'is-invalid': estimatedContribution.invalid && (estimatedContribution.touched || submitted) }"
                  #estimatedContribution="ngModel" [(ngModel)]="activiewCampaign.estimated_contribution"
                  placeholder="{{ 'ENTER_ESTIMATED_CONTRIBUTION' | translate }}" name="estimatedContribution" required>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label" [ngClass]="{ 'is-invalid': estimatedContribution.invalid && (estimatedContribution.touched || submitted) }">
                {{ "METHOD" | translate }}
              </label>
              <div class="col-12 col-md-9">
                <tag-input inputClass="tagInputMethod" #method="ngModel" [(ngModel)]="activiewCampaign.methods" [onlyFromAutocomplete]="true" name="tag-input-methods"
                  displayBy="value" identifyBy="key" placeholder="+ {{ 'METHOD' | translate }}"
                  secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { object: 'METHOD' | translate | lowercase } }}">
                  <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="methods" displayBy="value" identifyBy="key" [keepOpen]="false"
                    [showDropdownIfEmpty]="true"></tag-input-dropdown>
                </tag-input>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="isVolunteer">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label">
                {{ "ACTIVIEW_VOLUNTEER" | translate }}
              </label>
              <div class="col-12 col-md-9">
                <input class="form-control" type="number" disabled [ngModel]="activiewCampaign.number_volunteer"  name="volunteersNumberDNA">
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label" [ngClass]="{ 'is-invalid': researchAxeForm.invalid && (researchAxeForm.touched || submitted) }">
                {{ "RESEARCH_AXES" | translate }}
              </label>
              <div class="col-12 col-md-9">
                <select id="researchAxe" class="form-control" #researchAxeForm="ngModel" [(ngModel)]="activiewCampaign.research_axes"  name="researchAxe" [compareWith]="compareCode"
                [ngClass]="{ 'isinvalid': researchAxeForm.invalid && (researchAxeForm.touched || submitted) }">
                  <option *ngFor="let axe of researchAxes" [ngValue]="axe" translate>{{ axe.name[languageMap[currentLanguage]] || axe.name['en'] }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label required" [ngClass]="{ 'is-invalid': fieldworkForm.invalid && (fieldworkForm.touched || submitted) }">
                {{ "FIELDWORK" | translate }}
              </label>
              <div class="col-12 col-md-9">
                <select id="fieldwork" class="form-control" #fieldworkForm="ngModel" [(ngModel)]="activiewCampaign.fieldwork"  name="fieldwork" [compareWith]="comparebyId"
                [ngClass]="{ 'isinvalid': fieldworkForm.invalid && (fieldworkForm.touched || submitted) }" required>
                  <option *ngFor="let country of allCountries" [ngValue]="country" translate>{{ country.value }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label">
                {{ "COMMENT" | translate }}
              </label>
              <div class="col-12 col-md-9">
                <textarea class="form-control" #comment="ngModel" [(ngModel)]="activiewCampaign.comments" type="text" name="comment" maxlength="250" rows="4"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button id="save" class="btn btn-primary mb-4" (click)="save(activiewCampaign)" translate>SAVE_SEND_ACTIVIEW</button>
    <button id="cancel" class="btn btn-default mb-4" (click)="cancel()" translate>CANCEL</button>
  </div>
</form>
