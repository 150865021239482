<form [formGroup]="changeValueForm">
  <div class="table-responsive" formArrayName="records">
    <table class="table table-hover table-borderless-th dataTable">
      <thead>
        <tr>
          <th translate>INDEX</th>
          <th translate>LABEL</th>
          <th translate>ANSWER</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let recordOfForm of getFormData().controls ; let recordIndex = index" [formGroupName]="recordIndex">
          <td>To add</td>
          <td>{{changeValueForm.get('label').value}}</td>
          <td>
            <div class="form-group">
              <input class="form-control" type="text" formControlName="newValue">
            </div>
            <div class="form-group">
              <button type="button" class="btn btn-primary" [disabled]="changeValueForm.pristine || changeValueForm.invalid" (click)="save(changeValueForm, recordIndex)"
                translate>SAVE</button>
              <button type="button" class="btn btn-default" (click)="cancel()" translate>CANCEL</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</form>
