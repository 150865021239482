import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dna-display-information',
    templateUrl: './dnaDisplayInformation.component.html'
})

export class DisplayInformationComponent implements OnInit {
    @Input() textLeft: string;
    @Input() textRight: string;
    @Input() textSeparator: string;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() subtitleEmpty: string;

    constructor() { }

    ngOnInit() { }
}
