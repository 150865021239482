<ngx-loading [show]="showSpinner"></ngx-loading>
<nav aria-label="breadcrumb" id="banner-nav">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/dashboard" translate>DASHBOARD</a>
    </li>
    <li class="breadcrumb-item">
      <a routerLink="/workflow" translate>LIBRARY_OF_WORKFLOWS</a>
    </li>
    <li class="breadcrumb-item">
      <a [routerLink]="['/workflows', workflow.id]">
        <dna-translate [fieldToTranslate]="workflow.name"> </dna-translate>
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page" translate>GRAPH</li>
  </ol>
  <div class="text-right" id="banner-actions">
    <button class="btn btn-primary" type="button" (click)="goBack()" translate>BACK</button>
  </div>
</nav>
<div class="block">
  <div *ngIf="state !== workflowStates.Published && isWorkflowChanged" class="button-group py-4">
    <dna-save-cancel (onClickCancel)="onCancel()" (onClickSave)="save()"></dna-save-cancel>
  </div>
  <dna-graphs-page [graphs]="graphs" [workflow]="workflow" [descriptorsInWorkflow]="descriptorsInWorkflow" [editMode]="inCampaign || (!inCampaign && workflow.state !== workflowStates.Published)" (isWorkflowChanged)="onWkChanged($event)"></dna-graphs-page>
</div>
