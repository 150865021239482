import { TableChartOptionsType } from '../../interfaces';
import { OptionType } from '../../enums';

export class TableChartOptions {

    public options: TableChartOptionsType = {
        general: { label: null, options: [] },
        plotOptions: { label: null, options: [] },
        columnsRefinement: { label : null, options : []}
    };

    public callback: Function;

    constructor(
        {
            general,
            plotOptions,
            columns
        }, callback: Function) {
        this.callback = callback;
        // GENERAL PART
        this.options['general'].label = (general.label || 'VIZUALIZATIONS.GENERAL');
        try { this.options['general'].options.push({ label: 'VIZUALIZATIONS.CHART_TITLE', key: "chart_title", value: (general.title.text || null), type: OptionType.Text, originalObject : general.title  }); }
        catch (e) { this.options['general'].options.push({ label: 'VIZUALIZATIONS.CHART_TITLE', key: 'chart_title', value: null, type: OptionType.Text, originalObject : general.title  }); }
        try { this.options['general'].options.push({ label: 'VIZUALIZATIONS.CHART_SUBTITLE', key: 'chart_subtitle', value: (general.subtitle.text || null), type: OptionType.Text, originalObject : general.subtitle }); }
        catch (e) { this.options['general'].options.push({ label: 'VIZUALIZATIONS.CHART_SUBTITLE', key: 'chart_subtitle', value: null, type: OptionType.Text, originalObject : general.subtitle }); }

        // PLOT OPTIONS
        if (plotOptions) {
            this.options.plotOptions.label = (plotOptions && plotOptions.label || 'VIZUALIZATIONS.PLOT_OPTIONS');
            // Show/Hide labels
            if (plotOptions && plotOptions.hasOwnProperty('column') && plotOptions.column.hasOwnProperty('dataLabels')) {
                try { 
                    this.options.plotOptions.options.push({ 
                        label: (plotOptions.column.dataLabels.label || 'VIZUALIZATIONS.SHOW_DATA_LABELS'), 
                        key: 'show_data_label', 
                        value: (plotOptions.column.dataLabels.enabled || false), 
                        type: OptionType.Checkbox 
                    }); 
                }
                catch (e) { 
                    this.options.plotOptions.options.push({ 
                        label: 'VIZUALIZATIONS.SHOW_DATA_LABELS', 
                        value: false, 
                        key: 'show_data_label', 
                        type: OptionType.Checkbox 
                    }); 
                }
            }
            // Show/Hide grey zone (if exists)
            if (plotOptions && plotOptions.hasOwnProperty('grey_zone')) {
                try {
                    this.options.plotOptions.options.push(
                        {
                            label: ('VIZUALIZATIONS.SHOW_GREY_ZONE'),
                            key: 'show_grey_zone',
                            value: (plotOptions.grey_zone.enabled || false),
                            type: OptionType.Checkbox
                        });
                }
                catch (e) {
                    this.options.plotOptions.options.push({
                        label: 'VIZUALIZATIONS.SHOW_GREY_ZONE',
                        value: false,
                        key: 'show_grey_zone',
                        type: OptionType.Checkbox
                    });
                }
            }

            if (plotOptions && plotOptions.hasOwnProperty('default_language')) {
                this.options.plotOptions.options.push(
                    {
                        label: 'VIZUALIZATIONS.SHOW_DEFAULT_LANGUAGE',
                        key: 'show_default_language',
                        value: (plotOptions.default_language.enabled || false),
                        type: OptionType.Checkbox
                    }
                );
            }

            // Show/Hide attributes' blocks
            if (plotOptions && plotOptions.hasOwnProperty('attributes_blocks')) {
                try {
                    this.options.plotOptions.options.push({ label: 'VIZUALIZATIONS.SHOW_ATTRIBUTES_BLOCKS', key: 'show_attributes_blocks', value: (plotOptions.attributes_blocks.enabled || false), type: OptionType.Checkbox });
                }
                catch (e) {
                    this.options.plotOptions.options.push({ label: 'VIZUALIZATIONS.SHOW_ATTRIBUTES_BLOCKS', key: 'show_attributes_blocks', value: false, type: OptionType.Checkbox });
                }
            }
        }
        // COLUMN SELECTION PART
        if (columns && columns.length) {
            this.options['columnsRefinement'].label = 'VIZUALIZATIONS.COLUMNS_TITLE';
            columns.reduce(( reducer : any , column : any ) => {
                reducer.push({ 
                    label: column.label,
                    key: column.id,
                    value: !column.hidden, 
                    type: OptionType.Checkbox
                });
                return reducer;
            }, this.options['columnsRefinement'].options);
        }
    }

    public getParametersFromOptions = (): any => {
        let payload = {
            general: {},
            specifics: {},
            columns: []
        };
        Object.assign(payload.general,      this.getGeneralParameterFromOptions());
        Object.assign(payload.specifics,    this.getPlotOptionsParametersFromOptions());
        payload.columns                =    this.getColumnRefinmentOptions();
        return payload;
    };

    private getGeneralParameterFromOptions      = (): any => {
        return {
            title : { 
                translations: {
                    ...this.options.general.options[0].originalObject.translations,
                    user: this.options.general.options[0].value
                }
            },
            subtitle : {
                translations: {
                    ...this.options.general.options[1].originalObject.translations,
                    user: this.options.general.options[1].value
                }
            }
        };
    };

    private getPlotOptionsParametersFromOptions = () : any => {
        const payload = {
            plotOptions : {
                column : {
                    dataLabels : {
                        enabled : (this.options.plotOptions.options.find(x => x.key === 'show_data_label') ? this.options.plotOptions.options.find(x => x.key === 'show_data_label').value : false)
                    }
                }
            }
        };

        if( this.options.hasOwnProperty('plotOptions') && this.options.plotOptions.options.find(x => x.key === 'show_grey_zone')) {
            payload.plotOptions['grey_zone'] = { enabled : (this.options.plotOptions.options.find(x => x.key === 'show_grey_zone').value || false) };
        }
        if( this.options.hasOwnProperty('plotOptions') && this.options.plotOptions.options.find(x => x.key === 'show_attributes_blocks')) {
            payload.plotOptions['attributes_blocks'] = { enabled : (this.options.plotOptions.options.find(x => x.key === 'show_attributes_blocks').value || false) };
        }
        if (this.options.hasOwnProperty('plotOptions') && this.options.plotOptions.options.find(x => x.key === 'show_default_language')) {
            payload.plotOptions['default_language'] = { enabled: (this.options.plotOptions.options.find(x => x.key === 'show_default_language').value || false) };
        }
        return payload;
    };

    private getColumnRefinmentOptions           = () : any => {
        if(this.options.hasOwnProperty('columnsRefinement')) {
            return this.options.columnsRefinement.options.map((option : any ) => {
                return { id : option.key, hidden : !option.value }
            });
        }
    }
};