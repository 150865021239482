import { SankeyOptionsType, ChartOption } from '../../interfaces';
import { Chart } from '../chart.model';
import { OptionType } from '../../enums';

export class SankeyOptions {

    public options: SankeyOptionsType = {
        general: { label: null, options: [] },
        series: { label: null, options: [] },
        plotOptions: { label: null, options: [] }

    };

    public callback: Function;

    constructor(
        {
            general,
            series,
            plotOptions
        }, callback: Function) {
        this.callback = callback;
        // GENERAL PART
        this.options['general'].label = (general.label || 'VIZUALIZATIONS.GENERAL');
        try { this.options['general'].options.push({ label: 'VIZUALIZATIONS.CHART_TITLE', key: "chart_title", value: (general.title.text || null), type: OptionType.Text, originalObject: general.title }); }
        catch (e) { this.options['general'].options.push({ label: 'VIZUALIZATIONS.CHART_TITLE', key: 'chart_title', value: null, type: OptionType.Text, originalObject: general.title }); }
        try { this.options['general'].options.push({ label: 'VIZUALIZATIONS.CHART_SUBTITLE', key: 'chart_subtitle', value: (general.subtitle.text || null), type: OptionType.Text, originalObject: general.subtitle }); }
        catch (e) { this.options['general'].options.push({ label: 'VIZUALIZATIONS.CHART_SUBTITLE', key: 'chart_subtitle', value: null, type: OptionType.Text, originalObject: general.subtitle }); }

        try {
            this.options[ 'general' ].options.push( { 
                label: 'Chart text size',
                key: 'chart_font_size',
                value: 11,
                type: OptionType.Number,
            })
        } catch( e ) {
            console.error( 'error when creating font size option' );
        }
        try {
            this.options[ 'general' ].options.push( {
                label: 'Chart text bold',
                key: 'chart_font_bold',
                value: false,
                type: OptionType.Checkbox,
            })
        } catch( e ) {
            console.error( 'error when creating font bold option' );
        }

        // SERIES PART
        this.options.series.label = (series && series.label || 'VIZUALIZATIONS.SERIES');
        try {
            this.options.series.options = series.filter(s => s.type === 'spline').reduce((accumulator: Array<ChartOption>, serie: any, index: number) => {
                accumulator.push({ label: (serie.name || index.toString()), value: (serie.color || Chart.colorScale[index % Chart.colorScale.length]), type: OptionType.Color, key: `color_${index.toString()}` });
                return accumulator;
            }, []);
        } catch (e) { }

        // PLOT OPTIONS
        this.options.plotOptions.label = (plotOptions && plotOptions.label || 'VIZUALIZATIONS.PLOT_OPTIONS');

        
        // Show/Hide attributes' blocks
        if (plotOptions && plotOptions.hasOwnProperty('attributes_blocks')) {
            try {
                this.options.plotOptions.options.push({ label: 'VIZUALIZATIONS.SHOW_ATTRIBUTES_BLOCKS', key: 'show_attributes_blocks', value: (plotOptions.attributes_blocks.enabled || false), type: OptionType.Checkbox });
            }
            catch (e) {
                this.options.plotOptions.options.push({ label: 'VIZUALIZATIONS.SHOW_ATTRIBUTES_BLOCKS', key: 'show_attributes_blocks', value: false, type: OptionType.Checkbox });
            }
        }

        if (plotOptions && plotOptions.hasOwnProperty('default_language')) {
            this.options.plotOptions.options.push(
                {
                    label: 'VIZUALIZATIONS.SHOW_DEFAULT_LANGUAGE',
                    key: 'show_default_language',
                    value: (plotOptions.default_language.enabled || false),
                    type: OptionType.Checkbox
                }
            );
        };
    }

    public getParametersFromOptions = (): any => {
        let payload = {
            general: {},
            specifics: {}
        };
        Object.assign(payload.general, this.getGeneralParameterFromOptions());
        Object.assign(payload.specifics, this.getSeriesParametersFromOptions());
        Object.assign(payload.specifics, this.getPlotOptionsParametersFromOptions());

        return payload;
    };

    private getGeneralParameterFromOptions = (): any => {
        return {
            title: {
                translations: {
                    ...this.options.general.options[0].originalObject.translations,
                    user: this.options.general.options[0].value
                }
            },
            subtitle: {
                translations: {
                    ...this.options.general.options[1].originalObject.translations,
                    user: this.options.general.options[1].value
                }
            },
            font: {
                size: this.options.general.options[ 2 ].value,
                bold: this.options.general.options[ 3 ].value,
            }
        };
    };

    private getSeriesParametersFromOptions = (): any => {
        return {
            series: this.options.series.options.map((serie: any) => {
                return {
                    name: serie.label,
                    color: `${serie.value}99`
                };
            })
        }
    };

    private getPlotOptionsParametersFromOptions = (): any => {
        const payload = {
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: (this.options.plotOptions.options.find(x => x.key === 'show_data_label') ? this.options.plotOptions.options.find(x => x.key === 'show_data_label').value : false)
                    }
                }
            }
        }

        if (this.options.hasOwnProperty('plotOptions') && this.options.plotOptions.options.find(x => x.key === 'show_grey_zone')) {
            payload.plotOptions['grey_zone'] = { enabled: (this.options.plotOptions.options.find(x => x.key === 'show_grey_zone').value || false) };
        }
        if (this.options.hasOwnProperty('plotOptions') && this.options.plotOptions.options.find(x => x.key === 'show_attributes_blocks')) {
            payload.plotOptions['attributes_blocks'] = { enabled: (this.options.plotOptions.options.find(x => x.key === 'show_attributes_blocks').value || false) };
        }
        if (this.options.hasOwnProperty('plotOptions') && this.options.plotOptions.options.find(x => x.key === 'show_default_language')) {
            payload.plotOptions['default_language'] = { enabled: (this.options.plotOptions.options.find(x => x.key === 'show_default_language').value || false) };
        }
        return payload;
    };
};