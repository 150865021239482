<dna-layout>
  <ng-container>
  <ngx-loading [show]="showSpinner">
</ngx-loading>
    <h1 class="mb-4" id="banner-title">
      <span>{{ "CAMPAIGNS_MULTI" | translate }}</span>
    </h1>
    <nav aria-label="breadcrumb" id="banner-nav">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/dashboard" translate>DASHBOARD</a>
        </li>
        <li class="breadcrumb-item">
          <a routerLink="/campaigns">
            {{ "CAMPAIGNS" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item">
          <a routerLink="/multi-campaigns">
            {{ "CAMPAIGNS_MULTI" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/multi-campaigns', campaignMultiId, 'generalInformation']"
             translate>{{ campaignMultiName }}</a>
        </li>
        <li class="breadcrumb-item active" translate>ANALYTICS</li>
      </ol>
    </nav>
    <div class="block">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
        </div>
        <div class="col-12 col-md-8">
          <div class="float-end d-flex flex-row gap-1">
            <button class="btn btn-primary margin-btn" type="button" (click)="takeCaptures()"
                    translate>TAKE_CAPTURES
              <i class="fa fa-camera" aria-hidden="true"></i>
            </button>
            <button id="back" class="btn btn-primary no-margin-bottom" type="button" (click)="editChart()"
                    translate>EDIT_CHART
            </button>
          </div>
        </div>
      </div>
  <ngx-loading [show]="showSpinner">
</ngx-loading>
      <div class="dna-container dna-container-row">
        <div *ngIf="this.report && !this.report.length" [class.dna-message-banner--error]="this.reportError"
             dna-message-banner translate>
          {{ this.reportError ? this.reportError.error.message : 'VIZUALIZATIONS.BOARD.EMPTY' }}
        </div>
        <div *ngFor="let elt of this.report; trackBy: this.trackByFunction">
          <div class="dna-dashboard--chart-identifier" *ngIf=" elt?.identifier ">
            <span translate>VIZUALIZATIONS.BOARD.CHART</span> #{{ elt.identifier }}
            <dna-checkbox class="float-end" [isChecked]="elt.toCapture" [disabled]="false" [text]="capture"
                          ngbTooltip="{{ ( elt.toCapture ? 'REMOVE_TO_CAPTURES' : 'ADD_TO_CAPTURES') | translate }}"
                          placement="left" (onBooleanChanged)="onChangeGraphToCapture(elt)"></dna-checkbox>
          </div>
          <div dna-chart-tile
               class="w-100"
               *ngIf="elt && elt.chart"
               [element]="elt"
               [onImageLoad]="this.onImageLoad"
               [lang]="this.lang"
               (_onExcelDownload)="this._onExcelDownload($event)"
               (_onExporting)="this._onExporting"
               (_onMultipleCaptures)="this.onMultipleCaptures($event)"
               (saveChartOptions)    = "saveChartOptions($event)">
          </div>
          <div dna-chart-tile-skeleton [error]="elt?.error || elt?.chart?.error"
               *ngIf="(!elt || !elt.chart) && (!elt?.error || !elt?.chart?.error)">
          </div>
        </div>
        <div *ngIf="!this.reportError" [ngClass]="{
            'dna-filters-container--collapsed' : this.filterCollapsed,
            'dna-filters-container--expanded'  : !this.filterCollapsed
        }" dna-filters-multi [filters]="this.filters" [loading]="this.filtersLoading"
             (onSubmit)="this.onFiltersUpdate($event)" (onCollapse)="this._onCollapse($event)"></div>
        <dna-menu
          [ngClass]="{
            'dna-menu-container--collapsed' : this.menuCollapsed,
            'dna-menu-container--expanded'  : !this.menuCollapsed
        }"
          [isMulti]="true"
          (onCollapse)="this._onCollapseMenu($event)"
        ></dna-menu>
      </div>
    </div>
  </ng-container>
</dna-layout>
