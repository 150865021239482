import { MultipleChoiceConfigurationComponent } from './multipleChoice/multipleChoiceConfiguration.component';
import { ComponentConfigurationComponent } from './componentConfiguration.component';
import { TimerConfigurationComponent } from './timer/timerConfiguration.component';
import { RankingConfigurationComponent } from './ranking/rankingConfiguration.component';
import { PhotosConfigurationComponent } from './photos/photosConfiguration.component';
import { InputTextConfigurationComponent } from './inputText/inputTextConfiguration.component';
import { InfoTextConfigurationComponent } from './infoText/infoTextConfiguration.component';
import { CounterSideConfigurationComponent } from './counterSide/counterSideConfiguration.component';
import { SliderConfigurationComponent } from './slider/sliderConfiguration.component';

import { SharedModule } from '@app/shared/shared.module';
import { NgModule } from '@angular/core';
import { TagInputModule } from 'ngx-chips';
import { MultipleChoiceLinkConfigurationComponent } from './multipleChoice/multipleChoiceLinkConfiguration.component';
import { RichTextEditorConfigurationComponent } from './richTextEditor/richTextEditorConfiguration.component';
import { QuillModule } from 'ngx-quill';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@NgModule({
  imports: [
    SharedModule,
    TagInputModule,
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          [{'size': ['small', false, 'large', 'huge']}],
          ['bold', 'italic', 'underline'],
          [{'header': 1}, {'header': 2}],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          [{'color': []}, {'background': []}],
          [{'align': []}],
          ['link']
        ]
      }
    }),
    MatButtonToggleModule
  ],
    exports: [
        ComponentConfigurationComponent,
        MultipleChoiceLinkConfigurationComponent
    ],
    declarations: [
        SliderConfigurationComponent,
        CounterSideConfigurationComponent,
        InfoTextConfigurationComponent,
        InputTextConfigurationComponent,
        PhotosConfigurationComponent,
        RankingConfigurationComponent,
        TimerConfigurationComponent,
        ComponentConfigurationComponent,
        MultipleChoiceConfigurationComponent,
        MultipleChoiceLinkConfigurationComponent,
        RichTextEditorConfigurationComponent
    ],
    providers: []
})
export class ComponentConfigurationModule { }
