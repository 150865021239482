import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNumber'
})
export class IsNumberPipe implements PipeTransform {
  /**
     * isNumber
     * Check if value is a number or not.
     * It's useful within DOM/UI to center the current value.
     * @param value : any;
     * @return boolean // True if value is a number;
    */
  transform(value: any, args?: any): boolean {
    try {
      return !isNaN(parseInt(JSON.parse(JSON.stringify(value))));
    } catch (e) {
      // Value is certainly an object or undefined
      return false
    }
  }

}
