import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ngbd-modal-confirm-multi-study-name',
    templateUrl: './confirm-name-modal.component.html',
  })
  export class NgbdModalConfirmMultiStudyName {
    @Input() oldName;
    @Input() newSynergy;
  
    constructor(public modal: NgbActiveModal) {}
  }